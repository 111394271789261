
import React from 'react';
import styled from 'styled-components';
import utils from 'helpers/utils';
import Grid from '@material-ui/core/Grid';
import InfoLink from '../../InfoLink';
import Tooltip from '../../MaterialUI/Tooltip';
import { H6ExtraBoldGrey } from 'styles/Kit/H6ExtraBold';
import { Translation, Trans } from 'react-i18next';
import { parseRegion } from '../../../helpers/regions';
import { SHOP_ID_WOOCOMMERCE } from '../../../helpers/const';

const Image = styled.div.attrs(props => ({
  style: {
    backgroundImage: `url(${props.url})`,
  }
}))`
  margin-right: 10px;
  border-radius: 3px;
  width: 100%;
  height: auto;
  padding-bottom: 80%;
  background-clip: padding-box;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  flex: 0 0 auto;
`;

const Infos = styled.div`

  width: 100%;

  > div {
    padding: 0 0 10px;
    display: flex;
    flex-direction: column;

    + div {
      padding: 10px 0;
      border-top: solid 1px #e1e2ef;
    }

    > label {
      ${H6ExtraBoldGrey};
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
`

const Layout = styled.div`
  border-top: solid 1px #e1e2ef;
  padding: 20px;
  width: 100%;
`


const Details = ({ product, host_info }) => <Translation>{t => (<Layout><Grid container spacing={3}>
  <Grid item xs={12} sm={6}><Image url={product && product.images && product.images[0] ? utils.resolveImageAsset(product.images[0]) : utils.getRandomProductImageFallback()} /></Grid>
  <Grid item xs={12} sm={6}><Infos>
    <div>
      <label><span>{t("Products/ExpandableProductBox/Details.label.regions")}</span><Tooltip title={t("Products/ExpandableProductBox/Details.tooltip.regions")}><InfoLink>{t("Products/ExpandableProductBox/Details.info")}</InfoLink></Tooltip></label>
      <span>{parseRegion(product.region).join(', ')}</span>
    </div>
    <div>
      <label><span>{t("Products/ExpandableProductBox/Details.label.delivery")}</span><Tooltip title={<Trans i18nKey="Products/ExpandableProductBox/Details.tooltip.delivery"><b>Via e-mail:</b> The winner just has to enter a valid e-mail address to receive the prize.
<br /><b>Postal:</b> The winner has to enter a valid shipping address to receive the prize.</Trans>}><InfoLink>Info</InfoLink></Tooltip></label>
      <span>{product.virtual ? t("Products/ExpandableProductBox/Details.email") : t("Products/ExpandableProductBox/Details.postal")}</span>
    </div>
    <div>
      <label><span>{t("Products/ExpandableProductBox/Details.label.deliveredby")} </span><Tooltip title={t("Products/ExpandableProductBox/Details.tooltip.deliveryby")}><InfoLink>Info</InfoLink></Tooltip></label>
      <span>{product.shop_id == SHOP_ID_WOOCOMMERCE || product.shop == "Woocommerce" ? 'GHEED' : host_info ? host_info.username : '...'}</span>
    </div>
    {(product.sponsor_product || (product.sponsor && product.sponsor_link)) && <div>
      {product.sponsor_product && <a href={product.sponsor_product} target="_blank"
        rel="noopener noreferrer">{t("Products/ExpandableProductBox/Details.link.moreproductinfo")}&nbsp;<span className="icon-Ico16-Login" /></a>}
      {product.sponsor && product.sponsor_link && <a href={product.sponsor_link} target="_blank"
        rel="noopener noreferrer">{t("Products/ExpandableProductBox/Details.link.sponsor", { sponsor: product.sponsor })}&nbsp;<span className="icon-Ico16-Login" /></a>}
    </div>}
  </Infos></Grid>
</Grid></Layout>)}</Translation>


export default Details;