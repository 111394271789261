import { EVENTBACKGROUND_META_MAP } from 'helpers/const';
let lastTimers = {};
const timerListeners = [];
const contestTimerListeners = {};


export const addTimerListener = listener => {
  timerListeners.push(listener);
};

export const removeTimerListener = listener => {
  let i = timerListeners.indexOf(listener);
  if (i != -1) {
    timerListeners.splice(i, 1);
  }
};

export const addContestTimerListener = (EVENTID, listener) => {
  contestTimerListeners[EVENTID]
    ? contestTimerListeners[EVENTID].push(listener)
    : (contestTimerListeners[EVENTID] = [listener]);

  if (lastTimers[EVENTID]) {
    listener.apply(null, [lastTimers[EVENTID]]);
  }
};

export const removeContestTimerListener = (EVENTID, listener) => {
  let i = contestTimerListeners[EVENTID]
    ? contestTimerListeners[EVENTID].indexOf(listener)
    : -1;
  if (i != -1) {
    contestTimerListeners[EVENTID].splice(i, 1);
  }
};

export const updateContestTimers = timers => {
  Object.keys(timers).forEach(EVENTID => {
    if (
      contestTimerListeners[EVENTID] &&
      contestTimerListeners[EVENTID].length > 0
    ) {
      contestTimerListeners[EVENTID].forEach(callback => {
        callback.apply(null, [timers[EVENTID]]);
      });
    }
  });

  if (timerListeners.length > 0) {
    timerListeners.forEach(callback => {
      callback.apply(null, [timers]);
    });
  }

  lastTimers = timers;
};


export const getEventBackground = raffle => {
  if (!raffle) {
    return;
  }

  try {
    const backgroundImage =
      raffle && raffle.eventbackground.uuid
        ? `url(${raffle.eventbackground.variants[0].url});`
        : null;
    const meta = JSON.parse(raffle.eventbackground_meta);

    const parsedMeta = Object.keys(meta).reduce((prev, prop) => {
      return EVENTBACKGROUND_META_MAP[prop]
        ? {
          ...prev,
          [EVENTBACKGROUND_META_MAP[prop]]: meta[prop],
        }
        : prev;
    }, {});

    return backgroundImage
      ? { ...parsedMeta, backgroundImage: backgroundImage }
      : parsedMeta;
  } catch (error) {
    console.log('background invalid');
  }
};