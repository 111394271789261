import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const CONTINENT_LOCALE_MAP = {
  'AF!': '002',
  'NA!': '003',
  'OC!': '009',
  'AN!': 'AQ',
  'AS!': '142',
  'EU!': '150',
  'SA!': '005',
};

const config = {
  fallbackLng: 'en',
  preload: ['en', 'de'],

  // have a common namespace used around the full app
  ns: ['resource'],
  defaultNS: 'resource',

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  react: {
    wait: false,
  },

  resources: {
    en: {
      resource: {
        ...require('./translations/en/resource.json')
      },
    },
    de: {
      resource: {
        ...require('./translations/de/resource.json')
      },
    },
  },
};

if (typeof window != 'undefined') {
  const LanguageDetector = require('i18next-browser-languagedetector');

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(config);
} else {
  i18n.use(initReactI18next).init(config);
}

export default i18n;
