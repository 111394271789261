import React from 'react';
import styled, { css } from 'styled-components';
import { H5ExtraBoldWhite, H5ExtraBoldBlue } from '../../styles/Kit/H5ExtraBold';
import { Button } from '@material-ui/core';

import { FillButtonWhiteDefault, FillButtonWhiteHover, FillButtonWhiteActive, FillButtonPrimaryDefault, FillButtonPrimaryHover, FillButtonPrimaryActive, FillButtonSecondaryDefault, FillButtonSecondaryHover, FillButtonSecondaryActive, FillButtonTertiaryDefault, FillButtonTertiaryHover, FillButtonTertiaryActive, FillButtonOutline2Default, FillButtonOutline2Hover, FillButtonOutline2Active, FillButtonOutlineDefault, FillButtonOutlineHover, FillButtonOutlineActive } from '../../styles/Kit/Fills';

const StyledButton = styled(({ nopadding, nomargin, small, large, white, primary, secondary, outline, outline2, ...other }) => (
  <Button {...other} variant="contained" classes={{ "root": "button-root", "contained": "contained", "label": "label" }} />
))`


.label {
  height: ${({ small }) => small ? 22 : 32}px !important;
}


&.button-root {
  min-width: ${({ small }) => small ? 24 : 34}px !important;
  height: ${({ small, large }) => small ? 24 : large ? 42 : 34}px !important;
  max-height: ${({ small, large }) => small ? 24 : large ? 42 : 34}px !important;
  padding: ${({ nopadding, small }) => nopadding ? '0' : small ? '0 10px' : '0 15px'} !important;
  display: flex;
  justify-content: center;
  align-items: center;

  

  white-space: nowrap;


  span[class^='icon-']:first-child {
    margin: 0 5px 0 0;
  }

  span[class^='icon-']:last-child {
    margin: 0 0 0 5px;
  }

  span[class^='icon-']:only-child {
    margin: 0;
    padding: 0;
  }

  ${({ white }) => white && css`
    ${H5ExtraBoldBlue}
    ${FillButtonWhiteDefault}
    :hover {
      ${FillButtonWhiteHover}
    }
    :active {
      ${FillButtonWhiteActive}
    }
    :disabled {
      ${H5ExtraBoldBlue}
      ${FillButtonWhiteDefault}
      opacity: .5;
    }
  `}

  ${({ primary }) => primary && css`
    ${H5ExtraBoldWhite}
    ${FillButtonPrimaryDefault}

    

    :hover {
      ${FillButtonPrimaryHover};
    }
    :active {
      ${FillButtonPrimaryActive};
    }

    :disabled {
      ${H5ExtraBoldWhite}
      ${FillButtonPrimaryDefault};
      opacity: .5;
    }
  `}

  ${({ secondary }) => secondary && css`
    ${H5ExtraBoldWhite}
    ${FillButtonSecondaryDefault}
    :hover {
      ${FillButtonSecondaryHover};
    }
    :active {
      ${FillButtonSecondaryActive};
    }

    :disabled {
      ${H5ExtraBoldWhite}
      ${FillButtonSecondaryDefault};
      opacity: .5;
    }

  `}

  ${({ tertiary }) => tertiary && css`
    ${H5ExtraBoldWhite}
    ${FillButtonTertiaryDefault}
    :hover {
      ${FillButtonTertiaryHover};
    }
    :active {
      ${FillButtonTertiaryActive};
    }

    :disabled {
      ${H5ExtraBoldWhite}
      ${FillButtonTertiaryDefault};
      opacity: .5;
    }

  `}

  ${({ outline }) => outline && css`
    ${H5ExtraBoldBlue}
    ${FillButtonOutlineDefault}
    :hover {
      ${FillButtonOutlineHover};
    }
    :active {
      ${FillButtonOutlineActive};
    }
    :disabled {
      ${H5ExtraBoldBlue}
      ${FillButtonOutlineDefault};
      opacity: .5;
    }
  `}

  ${({ outline2 }) => outline2 && css`
    ${H5ExtraBoldWhite}
    ${FillButtonOutline2Default}
    :hover {
      ${FillButtonOutline2Hover};
    }
    :active {
      ${FillButtonOutline2Active};
    }
  `}
}

    `;



export default StyledButton;