import { css } from 'styled-components';

export const EVENT_BACKGROUND_TEMPLATE_FIXED = css`
  overflow: hidden;
  position: relative;

  &::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${props => props.backgroundColor};
    background-image: ${props => props.backgroundImage};
    background-repeat: ${props => props.backgroundRepeat};
    background-position: ${props => props.backgroundPosition};
    background-size: ${props => props.backgroundSize};
    will-change: transform;
  }
  > * {
    z-index: 1;
  }
`;

export const EVENT_BACKGROUND_TEMPLATE_SCROLL = css`
  background-attachment: scroll;
  background-color: ${props => props.backgroundColor};
  background-image: ${props => props.backgroundImage};
  background-repeat: ${props => props.backgroundRepeat};
  background-position: ${props => props.backgroundPosition};
  background-size: ${props => props.backgroundSize};
`;
