import React from 'react';
import styled from 'styled-components';
import { GridBoxMixin } from 'styles';
import RafflePreview from 'common/RafflePreview';
import BigArrows from 'common/Slider16/BigArrows';
import { Slider16 } from 'common/Slider16';
import ThereIsMore from 'common/ThereIsMore';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const Inner = styled.div`
  ${GridBoxMixin};
  width: 100%;
  display: flex;
  align-self: center;
`;

class View extends React.PureComponent {
  state = {
    arrowsActive: false,
  };

  renderInner = () => {
    const { raffles } = this.props;
    const { arrowsActive } = this.state;
    return (
      <React.Fragment>
        <BigArrows active={arrowsActive} />
        <Inner>
          {raffles &&
            raffles.length > 0 && (
              <Slider16.Items>
                {raffles.map(raffle => (
                  <RafflePreview key={raffle.EVENTID} {...{ raffle }} />
                ))}
                <ThereIsMore />
              </Slider16.Items>
            )}
        </Inner>
      </React.Fragment>
    );
  };

  render() {
    const { renderInner } = this;
    const { wrapper, wrapperProps, id, raffles } = this.props;

    const Wrapper = wrapper;

    return (
      <Layout
        id={id}
        onMouseOver={() => this.setState({ arrowsActive: true })}
        onMouseOut={() => this.setState({ arrowsActive: false })}
      >
        <Slider16.Controller>
          {Wrapper && raffles && raffles.length > 0 ? (
            <Wrapper {...wrapperProps}>{renderInner()}</Wrapper>
          ) : (
            renderInner()
          )}
        </Slider16.Controller>
      </Layout>
    );
  }
}

export default View;
