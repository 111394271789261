import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';
import moment from 'moment-timezone';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';


const Content = styled.div`
  text-align: center;
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;

const TooLate = props => {
  const { timezone } = props
  return (
    <Modal {...props}>
      <Translation>
        {t => (
          <ContentBox modal centerTitle title={t('modals.toolate.title')}>
            <Content>
              {t('modals.toolate.body')}
              <b>
                {props.raffle
                  ? moment
                    .utc(props.raffle.active_to)
                    .tz(timezone)
                    .format(t('date_full'))
                  : null}
              </b>
              .
            </Content>
            <Controls>
              <Button outline
                onClick={props.onRequestClose}
              >{t('modals.toolate.ok')}</Button>
            </Controls>
          </ContentBox>
        )}
      </Translation>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    timezone: state.auth.user && state.auth.user.preferences && state.auth.user.preferences.timezone ? state.auth.user.preferences.timezone : moment.tz.guess()
  }
}


export default connect(mapStateToProps)(TooLate)
