import React from 'react';
import styled from 'styled-components';
import { LaunchedGiveaways } from '../LaunchedGiveaways';
import { PageLayout } from '../../../common/PageLayout';
import Banner from '../../Partnership/Banner';

const TopLayout = styled.div`
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  :last-child {
    padding-bottom: 46px;
  }
`;



const View = ({ raffles, user, loading, pathname }) => {
  if (!user || !user.USERID) {
    return <PageLayout />
  }

  return <PageLayout centered stretched noPadding>
    <TopLayout>
      <LaunchedGiveaways />
      <Banner marginTop={45} />
    </TopLayout>

  </PageLayout>
}
export default View;
