import { connect } from 'react-redux';
import View from './View';
import { PLATFORMID_TWITCH } from 'helpers/const';

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    showCoins: state.auth.user && state.auth.user.socials && state.auth.user.socials.find(s => s.PLATFORMID == PLATFORMID_TWITCH),
    pathname: state.location.pathname,
  };
};

export default connect(mapStateToProps)(View);
