import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import View from './View';



export const sortMethod = (a, b) => (a.value != b.value ? parseFloat(b.value) - parseFloat(a.value) : a.shop_id != b.shop_id ? a.shop_id - b.shop_id : a.shopproduct_id != b.shopproduct_id ? a.shopproduct_id - b.shopproduct_id : a.shopvariation_id - b.shopvariation_id)


const convertPrizeStructure = (rawPrizes, state) => {
  if (rawPrizes) {
    const prizesPerRank = rawPrizes.reduce((prev, curr) => {
      const crank = curr.rank.toString();
      if (!prev[crank]) {
        return { ...prev, [crank]: [{ ...curr.product, amount: 1 }] };
      } else {
        const existingProduct = prev[crank].find(p => {
          return p.shop_id == curr.product.shop_id && p.shopproduct_id == curr.product.shopproduct_id && p.shopvariation_id == curr.product.shopvariation_id
        })
        if (existingProduct) {
          existingProduct.amount = existingProduct.amount + 1;
          return prev;
        } else {

          const nextProducts = [...prev[crank], { ...curr.product, amount: 1 }]
          nextProducts.sort(sortMethod)
          return { ...prev, [crank]: nextProducts };
        }

      }
    }, {});

    let prizes = [];

    Object.values(prizesPerRank).forEach((products, i) => {
      const prevIndex = i > 0 ? Math.min(i - 1, prizes.length - 1) : null;
      const previous = i > 0 ? prizes[prevIndex] : null;


      const mapCompare = ({ shop_id, shopproduct_id, shopvariation_id, amount }) => {
        return { shop_id, shopproduct_id, shopvariation_id, amount }
      }

      const productsForRank = [];
      products.forEach(product => {
        const productData = product.shop_id == 2 ? state.products.products && state.products.products[product.shopproduct_id] : state.privateProducts.data[product.shopproduct_id];
        productData && productsForRank.push({ PRODUCTID: productData.PRODUCTID || productData.PRIVATEPRODUCTID, shop_id: product.shop_id, shopproduct_id: product.shopproduct_id, shopvariation_id: product.shopvariation_id, ...productData, amount: product.amount })
      });
      productsForRank.sort(sortMethod)


      const isRange = previous
        ? JSON.stringify(productsForRank.map(mapCompare)) == JSON.stringify(previous.products.map(mapCompare))
        : false;

      if (!isRange) {

        prizes.push({
          products: productsForRank,
          first_rank: i + 1,
          last_rank: i + 1,
        });
      } else {
        prizes[prevIndex] = {
          ...previous,
          last_rank: previous.last_rank + 1,
        };
      }
    });


    return prizes;
  }
};


const getPrizes = (state, props) =>
  props.raffle.prizes ? convertPrizeStructure(props.raffle.prizes, state) : state.raffles.prizes[props.raffle.EVENTID];

export const getSelectedState = createSelector([getPrizes], prizes => {
  return {
    prizes,
    loading: false,
  };
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...getSelectedState(state, ownProps),
  };
};

export default connect(mapStateToProps)(View);
