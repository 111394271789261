import React from 'react';
import Link from 'redux-first-router-link';
import Button from 'common/MaterialUI/Button';
import { Translation } from 'react-i18next';
import styled from 'styled-components';


const ButtonHolder = styled.div`
  justify-content: center;
  display: flex;

  margin-bottom: 10px;
`


const Intro = () => <Translation>{t => (<React.Fragment>
  <div className="ccc-introbox-wrapper">
    <div className="ccc-introbox-container">
      <img className="ccc-hide-on-desktop" src="/static/assets/images/quicktour/Quicktour-01-Intro-Banner-Mobile.png" alt=" " />
      <h2 className="ccc-h2">{t("QuickTour/View.welcome")}</h2>
      <h4 className="ccc-h4">{t("QuickTour/View.desc")}</h4>
      <img className="ccc-hide-on-mobile" src="/static/assets/images/quicktour/Quicktour-01-Intro-Banner.png" alt=" " />

    </div>
  </div>


  <div className="ccc-3col-wrapper">
    <div className="ccc-3col-container">
      <div className="ccc-contentbox ccc-3col-box">
        <img src="/static/assets/images/quicktour/Quicktour-01-Intro-GHEED-for-Viewers.png" width="220" height="110" alt=" " />
        <h4 className="ccc-h4">{t("QuickTour/View.viewers.label")}</h4>
        <p className="ccc-p">{t("QuickTour/View.viewers.desc")}</p>
        <ButtonHolder><Button primary component={Link} to="/quicktour/viewers"><span>{t("QuickTour/View.learnmore")}</span><span className="icon-Ico16-ArrowRight-Medium" /></Button></ButtonHolder>
      </div>
      <div className="ccc-contentbox ccc-3col-box">
        <img src="/static/assets/images/quicktour/Quicktour-01-Intro-GHEED-for-Streamers.png" width="220" height="110" alt=" " />
        <h4 className="ccc-h4">{t("QuickTour/View.streamers.label")}</h4>
        <p className="ccc-p">{t("QuickTour/View.streamers.desc")}</p>
        <ButtonHolder><Button primary component={Link} to="/quicktour/streamers"><span>{t("QuickTour/View.learnmore")}</span><span className="icon-Ico16-ArrowRight-Medium" /></Button></ButtonHolder>
      </div>
      <div className="ccc-contentbox ccc-3col-box">
        <img src="/static/assets/images/quicktour/Quicktour-01-Intro-GHEED-for-Businesses.png" width="220" height="110" alt=" " />
        <h4 className="ccc-h4">{t("QuickTour/View.businesses.label")}</h4>
        <p className="ccc-p">{t("QuickTour/View.businesses.desc")}</p>
        <ButtonHolder><Button primary component={Link} to="/quicktour/businesses"><span>{t("QuickTour/View.learnmore")}</span><span className="icon-Ico16-ArrowRight-Medium" /></Button></ButtonHolder>
      </div>
    </div>
  </div>
</React.Fragment>)}</Translation>

export default Intro