import View from './View';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  return {
    page: state.location.payload.page
  }
}


export default connect(mapStateToProps)(View);
