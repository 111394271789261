import React from 'react';
import { connect } from 'react-redux';
import { addIdleStateListener, removeIdleStateListener } from '../../helpers/idle';
import { createSelector } from 'reselect';
import { getNotificationsRequestAction } from '../../store/modules/api';

import { TIME_TO_NOTIFICATIONS_UPDATE } from 'helpers/const';


class Notifications extends React.PureComponent {

  state = { idle: false }

  handleIdleStateChange = (data) => {

    const { idle, idleTime } = data;

    if (this.state.idle && !idle && idleTime && idleTime > TIME_TO_NOTIFICATIONS_UPDATE) {
      const { updateTimer } = this;
      const { updateNotifications } = this.props;

      updateTimer();
      updateNotifications();

    }

    this.setState(data);

  }



  updateTimer = () => {

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      if (!this.state.idle) {
        const { updateNotifications } = this.props;
        updateNotifications();
      }
      const { updateTimer } = this;
      updateTimer();
    }, TIME_TO_NOTIFICATIONS_UPDATE);
  }

  componentDidMount() {
    const { updateTimer, handleIdleStateChange } = this;
    const { updateNotifications } = this.props;
    updateNotifications();
    updateTimer();
    addIdleStateListener(handleIdleStateChange)
  }

  componentWillUnmount() {
    const { timer, handleIdleStateChange } = this;
    removeIdleStateListener(handleIdleStateChange)

    if (timer) {
      clearTimeout(timer)
    }
  }

  render() {
    return null;
  }
}

const user = state => state.auth.user;
const language = state => state.ui.language;
const selectedState = createSelector([user, language], (user, language) => ({ user, language }))


const mapStateToProps = (state, ownProps) => {
  return selectedState(state)
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateNotifications: (user, language) => () => {
      if (user) {
        dispatch(getNotificationsRequestAction({ language }))
      }
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {

  const { user, language } = stateProps;
  return {
    ...stateProps,
    ...ownProps,
    updateNotifications: dispatchProps.updateNotifications(user, language)
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Notifications)