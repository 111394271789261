import React, { useState } from 'react';
import Box from '../_Box';
import Button from 'common/MaterialUI/Button';
import styled from 'styled-components';
import { SpendButton } from './SpendButton';
import { SpendInput } from './SpendInput';
import { Entries } from './Entries';
import { media } from 'styles';
import numeral from 'i18n/numeral';
import CoinsInfoButton from 'common/CoinsInfoButton';
import { Trans, Translation } from 'react-i18next';
import { BodySmallRegularBlack } from 'styles/Kit/BodySmallRegular';


const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0;

  :last-child {
    padding: 20px 0;
  }

  ${media.tabletMax`
    > * + * {
        margin-top: 10px;
    }

    flex-direction: column;
  `};
`;

const Minimum = styled.div`
padding: 10px 0 5px;
${BodySmallRegularBlack}

`


const SpendCoins = ({ way, toggle, handleContinue, ...other }) => {


  const [spending, setSpending] = useState(0);

  const { available_amount, earned_amount, bonus_amount } = way


  const handleReset = () => {
    setSpending(0)
  };

  const handleSetCoins = tempAmount => {
    const parsedAmount = parseInt(tempAmount);

    setSpending(tempAmount
      ? parsedAmount
        ? Math.min(available_amount, parsedAmount)
        : 0
      : 0)
  };

  const handleContinueClick = (event) => {
    if (spending > 0) {
      handleContinue(way, spending, toggle(way))(event)
    }
  };





  return <Translation>{t => (<Box {...{ way, toggle, ...other }}
    color={'#6441A4'}
    icon={'icon-Ico20-Social-Twitch'}
    amount={earned_amount || '?'}
    complete={earned_amount && !available_amount}
    titleParams={{ amount: available_amount ? numeral(available_amount).format('0,0') : '0' }}
    titleAdornments={() => <CoinsInfoButton />}
    controls={() => <React.Fragment>
      <Button outline small onClick={handleReset}>{t("Raffles/Detail/MainBox/Entryways/Methods/SpendCoins/index.reset")}</Button>
      <Button secondary small disabled={!spending} onClick={handleContinueClick}>{t("Raffles/Detail/MainBox/Entryways/Methods/SpendCoins/index.spend")}</Button>
    </React.Fragment>}
  >
    {(way.min_amount > 1) && <Minimum><Trans i18nKey="creator.entrymethods_coins_min" values={{ amount: way.min_amount }}>You need to spend at least <b>1 Loyalty Coin</b> to unlock this entry method.</Trans></Minimum>}

    <Content>
      <SpendInput
        value={spending}
        onChange={event => handleSetCoins(event.target.value)}
      />
      <Entries
        earned={earned_amount}
        bonus={bonus_amount}
        spending={spending}
      />
    </Content>
    <Content>
      <SpendButton
        amount={1}
        bonus={null}
        onClick={event => handleSetCoins(spending + 1)}
        disabled={available_amount < spending + 1}
      />
      <SpendButton
        amount={10}
        bonus={1}
        onClick={event => handleSetCoins(spending + 10)}
        disabled={available_amount < spending + 10}
      />
      <SpendButton
        amount={50}
        bonus={10}
        onClick={event => handleSetCoins(spending + 50)}
        disabled={available_amount < spending + 50}
      />
    </Content>


  </Box>)}</Translation>
}


export default SpendCoins