import { handleActions, createAction } from 'redux-actions';
import { INFINITE_SCROLL_LIMIT, ROUTE_TYPE_PRODUCTS } from 'helpers/const';


export const DYNAMIC_LIST_REQUEST = 'productLists/DYNAMIC_LIST_REQUEST';
export const DYNAMIC_LIST_RESPONSE = 'productLists/DYNAMIC_LIST_RESPONSE';
export const CATEGORY_LIST_REQUEST = 'productLists/CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_RESPONSE = 'productLists/CATEGORY_LIST_RESPONSE';
export const CATEGORY_SETTINGS = 'productLists/CATEGORY_SETTINGS';
export const PRODUCTLIST_SETTINGS = 'productLists/SETTINGS';
export const SET_CATEGORY = 'productLists/CATEGORY';


export const giveawayListCategorySettingsAction = createAction(CATEGORY_SETTINGS);
export const productListsettingsAction = createAction(PRODUCTLIST_SETTINGS);
export const productListSetCategoryAction = createAction(SET_CATEGORY);


export const GIVEAWAYLIST_MYLAUNCHED = "myLaunched"
export const GIVEAWAYLIST_ENTERED = "myEntered"


export const productCategoryListRequestAction = createAction(
  CATEGORY_LIST_REQUEST,
);

export const productCategoryListResponseAction = createAction(
  CATEGORY_LIST_RESPONSE,
);

export const productDynamicListRequestAction = createAction(
  DYNAMIC_LIST_REQUEST,
);

export const productDynamicListResponseAction = createAction(
  DYNAMIC_LIST_RESPONSE,
);

export const initialState = {

  filtered: {
    region: 'DE',
    category: null,
    orderby: 'total_sales',
    order: 'desc',
    nextPage: 0,
    limit: INFINITE_SCROLL_LIMIT,
    totalPages: 999,
    total_products: 0,
    data: [],
    loading: false,
    loaded: false,
  },

  popular: {
    orderby: 'total_sales',
    order: 'desc',
    nextPage: 0,
    limit: INFINITE_SCROLL_LIMIT,
    totalPages: 999,
    total_products: 0,
    data: [],
    loading: false,
    loaded: false,
  },


};

const productListsReducer = handleActions(
  {
    [SET_CATEGORY]: (state, action) => {
      return { ...state, filtered: { ...state.filtered, category: action.payload, loaded: false, loading: true, nextPage: 1, totalPages: 999 } }
    },


    [ROUTE_TYPE_PRODUCTS]: (state, action) => {
      const newState = { ...state };
      newState.filtered = { ...newState.filtered, loaded: false, loading: true, nextPage: 1, totalPages: 999 };
      newState.popular.loaded = { ...newState.popular, loaded: false, nextPage: 1, totalPages: 999 };
      return newState;
    },

    [PRODUCTLIST_SETTINGS]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },



    [CATEGORY_LIST_REQUEST]: (state, action) => {
      const listName = action.payload[0];
      const settings = action.payload && action.payload[1] ? action.payload[1] : {};
      const reset = action.payload[2] || false;
      const currentList = state[listName];
      const nextPage = currentList && !reset
        ? currentList.nextPage + 1
        : 1
      const totalPages = !reset ? currentList.totalPages : 999
      return {
        ...state,
        region: Object.prototype.hasOwnProperty.call(settings, "region") ? settings.region : state.region,
        [listName]: {
          ...currentList,
          loading: true,
          loaded: reset ? false : currentList.loaded,
          nextPage: nextPage,
          totalPages: totalPages,
          ...settings,
        },
      };
    },

    [CATEGORY_LIST_RESPONSE]: (state, action) => {
      const category = action.payload[1];
      const existingIds = state[category].data.map(r => r.EVENTID);
      const current_page = action.payload[0].current_page;
      const additionalData = action.payload[0].products.filter(
        r => existingIds.indexOf(r.PRODUCTID) == -1,
      );

      return {
        ...state,
        [category]: {
          ...state[category],
          loading: false,
          loaded: true,
          totalPages: action.payload[0].total_pages,

          total_products: action.payload[0].total_products,
          data: current_page == 1 ? action.payload[0].products : [...state[category].data, ...additionalData],
        },
      };
    },

    [DYNAMIC_LIST_REQUEST]: (state, action) => {
      return {
        ...state,
        [action.payload[1]]: {
          ...(state[action.payload[1]] || []),
          loading: true,
        },
      };
    },

    [DYNAMIC_LIST_RESPONSE]: (state, action) => {
      return {
        ...state,
        [action.payload[1]]: {
          ...(state[action.payload[1]] || []),
          loading: false,
          loaded: true,
          total_pages: action.payload[0].total_pages,
          current_page: action.payload[0].current_page,
          data: action.payload[0].products
        },
      };
    },
  },
  initialState,
);

export default productListsReducer;
