import { getPrivateProductByEventidRequestAction } from "../modules/api";
import { ROUTE_TYPE_CREATE, ROUTE_TYPE_GIVEAWAY_EDIT } from 'helpers/const';

const fetchPrivateProducts = (dispatch, getState) => {

  const state = getState();

  let eventId;

  if (state.location.type == ROUTE_TYPE_CREATE) {
    eventId = state.creator.temp && state.creator.temp.EVENTID
  }

  if (state.location.type == ROUTE_TYPE_GIVEAWAY_EDIT) {
    eventId = state.creator.edit && state.creator.edit.EVENTID
  }

  if (eventId) {
    dispatch(getPrivateProductByEventidRequestAction({ id: eventId }))
  }


}

export default fetchPrivateProducts;