import styled from 'styled-components';
import { colors } from 'styles';
import React from 'react';

export const Layout = styled.div`
  max-width: 1290px;
  font-size: small;

  h1 {
    font-size: 32px;
    font-weight: 800;
  }

  h2 {
    font-size: 20px;
    margin: 20px 0;
  }

  *.green {
    color: #5bcb8f;
  }

  a {
    color: ${colors.coolblue};
  }

  p,
  ul,
  .text,
  .list {
    margin-top: 10px;
  }

  .text {
    margin-top: 10px;
  }

  li {
    font-weight: bold;
    margin-bottom: 10px;
  }

  table {
    th {
      font-weight: bold;
      text-transform: uppercase;
      padding-right: 15px;

      vertical-align: top;
      white-space: nowrap;
    }

    td {
      padding-bottom: 15px;
    }
  }

  ol {
    margin: 20px;
    li {
      margin-left: 30px;
    }
  }
`;

export const Html = props => {
  let data = {
    ...props,
  };

  if (props.string) {
    data = {
      ...props,
      dangerouslySetInnerHTML: {
        __html: props.string,
      },
      children: null,
    };
  }

  return <Layout {...data}>{props.children}</Layout>;
};

export default Html;
