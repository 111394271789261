export const CONTEST_ACTIVE_SORT_OPTIONS = {
  MOST_POPULAR: {
    label: 'Most Popular',
    value: 'mostpopular'
  },
  HIDDEN_GEMS: {
    label: 'Hidden Gems ',
    value: 'hiddengems'
  },
  SOON_ENDING: {
    label: 'Soon Ending',
    value: 'soonending'
  },
  MOST_RECENT: {
    label: 'Most Recent',
    value: 'mostrecent'
  },
  VALUE: {
    label: 'Prize Value',
    value: 'prizevalue'
  },
};

export const CONTEST_ANNOUNCED_SORT_OPTIONS = {
  SOON_LAUNCHING: {
    label: 'Soon Launching',
    value: 'soonlaunching'
  },
  MOST_RECENT: {
    label: 'Most Recent',
    value: 'mostrecent'
  },
  VALUE: {
    label: 'Prize Value',
    value: 'prizevalue'
  },
};

export const CONTEST_FINISHED_SORT_OPTIONS = {
  JUST_FINISHED: {
    label: 'Just Finished',
    value: 'justfinished'
  },
  MOST_POPULAR: {
    label: 'Most Popular',
    value: 'mostpopular'
  },
  VALUE: {
    label: 'Prize Value',
    value: 'prizevalue'
  },
};

export const MAP_CONTEST_SORT_OPTIONS = {
  active: CONTEST_ACTIVE_SORT_OPTIONS,
  announced: CONTEST_ANNOUNCED_SORT_OPTIONS,
  finished: CONTEST_FINISHED_SORT_OPTIONS,
  popular: CONTEST_ACTIVE_SORT_OPTIONS,
};


export const getContestSortOptionQuery = (category, key) => {
  return MAP_CONTEST_SORT_OPTIONS[category][key].value
};
