import React from 'react';
import styled from 'styled-components';
import Section from 'common/Section';
import { PageLayout } from 'common/PageLayout';
import Masonry from 'common/Masonry';
import { ScrollToTopButton } from 'common/ScrollToTopButton';
import { media } from '../../styles';
import Banner from './Banner';

const Styles = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
  width: 100%;

	> * + * {
		margin-top: 30px;
	}

	min-width: 300px;

	${media.tablet`
min-width: 630px;
`};
	${media.desktop`
  min-width: 960px;
`};
	${media.widescreen`
  min-width: 1290px;
`};
`;

export class Layout extends React.Component {

  render() {
    const {
      loading,
      raffles,
    } = this.props;

    return (
      <PageLayout list="true" loading={loading && (!raffles || raffles.length == 0)}>
        <Styles>
          <Section>
            <Banner disabled marginTop={15} marginBottom={35} />
          </Section>
          {raffles &&
            raffles.length > 0 && (
              <Masonry
                items={raffles}
                hasMore={false}
                isLoading={loading}
                onInfiniteLoad={() => { }}
                getState={() => this.state}
              />
            )}
          {raffles && <ScrollToTopButton />}
        </Styles>
      </PageLayout>
    );
  }
}

export default Layout;
