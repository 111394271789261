import React from 'react';
import styled from 'styled-components';
import Intro from './Intro';
import Viewers from './Viewers';
import Streamers from './Streamers';
import Businesses from './Businesses';

const Layout = styled.div`
  width: 100%;
  position: relative;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 66px;

}
`;


const View = ({ page }) => <Layout>
  {!page && <Intro />}
  {page == "viewers" && <Viewers />}
  {page == "streamers" && <Streamers />}
  {page == "businesses" && <Businesses />}
</Layout>

export default View;
