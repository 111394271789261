import React from 'react';
import styled, { css } from 'styled-components';
import Banner from './Banner';
import { media } from 'styles';

const Layout = styled.div`
  

  display: flex;
  width: 100%;
  max-width: 300px;

  ${({ marginTop }) => css`margin-top:${marginTop ? marginTop : 0}px !important;`}
  ${({ marginBottom }) => css`margin-bottom:${marginBottom ? marginBottom : 0}px !important;`}


${
  media.tablet`
    max-width: 630px;
  `}

${
  media.desktop`
    max-width: 960px;
  `}

${
  media.widescreen`
    max-width: 1290px;
  `}
`

const CalendarBanner = ({ marginTop, marginBottom, disabled }) => <Layout marginTop={marginTop} marginBottom={marginBottom}><Banner disabled={disabled} /></Layout>


export default CalendarBanner;