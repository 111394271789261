import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { Translation, Trans } from 'react-i18next';
import { H6ExtraBoldBlack } from 'styles/Kit/H6ExtraBold';
import { connect } from 'react-redux';
import { SHOP_ID_PRIVATE, SHOP_ID_WOOCOMMERCE } from '../../../../helpers/const';

const Layout = styled.div`
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 1px 0 rgba(29, 29, 29, 0.3);

  width: 300px;
  background: url(/static/assets/images/creator/Giveaway-Claim-Banner-Mobile.png) no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 145px 20px 20px 20px;

  ${media.tablet`
    width: 630px;
  height: 175px;
  background: url(/static/assets/images/creator/Giveaway-Winner-Banner.png)  no-repeat;
  background-size: contain;
  background-position: center top;
  padding: 20px 145px;
  `} 
  
  p {
    font-size: 14px;
  }

  h2 {
    font-size: 20px;
  }

  > * {
    margin: 8px;
  }
`;

const Links = styled.p`

  word-break: break-word;

  a {
    ${H6ExtraBoldBlack}
    color: #fff15a;
  
    + a {

      margin-left: 20px;

      :before {
        position: absolute;
        transform: translateX(-11px);
        content: "|";

      }
    }

  }
`

const View = ({ user, raffle, hasPrivate, hasShopped, hasContactmail }) => {
  return (
    <Translation>
      {t => (
        <Layout>
          <h2>{t("Raffles/Detail/ClaimBox/ClaimSuccessful.success", { name: user.username })}</h2>
          <p>
            <Trans i18nKey="Raffles/Detail/ClaimBox/ClaimSuccessful.description">Your prize(s) will arrive in 2 to 3 weeks at the latest. If not, just get in touch.</Trans>
          </p>
          <Links>
            {hasShopped && <a href={`mailto:hello@gheed.com?subject=${raffle && encodeURIComponent(raffle.eventname)}`}>{t("Raffles/Detail/ClaimBox/ClaimSuccessful.contactGheed")}</a>}
            {hasPrivate && hasContactmail && <a href={`mailto:${raffle && raffle.host_info.contact_mail}?subject=${raffle && encodeURIComponent(raffle.eventname)}`}>{t("Raffles/Detail/ClaimBox/ClaimSuccessful.contactHost", { name: raffle && raffle.host_info.username })}</a>}
          </Links>
        </Layout>
      )}
    </Translation>
  );
};


const mapStateToProps = (state, ownProps) => {

  const prizes = state.raffles.prizes[ownProps.raffle.EVENTID];
  const hasPrivate = prizes && prizes.filter(prz => prz.products.filter(p => p.shop_id == SHOP_ID_PRIVATE).length > 0)
  const hasShopped = prizes && prizes.filter(prz => prz.products.filter(p => p.shop_id == SHOP_ID_WOOCOMMERCE).length > 0)
  const hasContactmail = (ownProps.raffle.host_info.contact_mail != null)

  return {
    hasPrivate,
    hasShopped,
    hasContactmail
  }
}

export default connect(mapStateToProps)(View)