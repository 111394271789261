import QuickTour from './';
import { ROUTE_TYPE_QUICKTOUR } from '../../helpers/const';

const Route = {
  [ROUTE_TYPE_QUICKTOUR]: {
    path: '/quicktour/:page*',
    title: 'Quick Tour',
    page: QuickTour,
  },
};

export default Route;
