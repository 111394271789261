import { createMuiTheme } from "@material-ui/core/styles";

const breakpointValues = {
  xs: 0,
  sm: 660,
  md: 768,
  lg: 1000,
  xl: 1330,
};

const theme = createMuiTheme({
  breakpoints: { values: breakpointValues },
  overrides: {
    MuiTableCell: {
      head: {
        color: '#9697a1',
        paddingBottom: '5px !important'
      }
    },
    MuiTableRow: {
      head: {
        height: 'inherit'
      }
    },
    MuiTab: {
      textColorPrimary: {
        color: '#36a6fc',
        '&$selected': {
          color: '#5bcb8f',
        },
        '&$disabled': {
          color: '#2083fb',
        },
      },
    },
    MuiTooltip: {
      popper: {
        opacity: 1,
      },
      tooltip: {
        backgroundColor: '#41a967',
        fontSize: '13px',
        fontWeight: 300,
        boxShadow: '0 2px 4px 2px rgba(29,29,29,0.05), 0 1px 1px 0 rgba(29,29,29,0.1)',
      }
    },
    MuiInput: {

    },
    MuiButton: {
      root: {
        borderRadius: 25,
        textTransform: 'none'
      },
    },
  },

  palette: {
    primary: {
      main: '#36a6fc',
      dark: '#2083fb',
      contrastText: 'white',
    },
    secondary: { main: '#5bcb8f' }
  },
  typography: {
    fontFamily: "Nunito, Helvetica Neue, Arial, sans-serif",
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 800,
    fontWeightBold: 900,
    useNextVariants: true,
  },
  shape: {
    borderRadius: 6,
  },
});

export default theme;
