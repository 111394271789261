import React from 'react';
import numeral from 'i18n/numeral';
import classNames from 'classnames';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import {
  RAFFLE_STATE_ACTIVE,
  RAFFLE_STATE_ANNOUNCED,
  RAFFLE_STATE_FINISHED,
} from 'helpers/const';
import { helpers } from '../../../../../helpers';
import { media } from '../../../../../styles';
import i18n from '../../../../../i18n';

const Layout = styled.div`
  font-family: Nunito;
  font-size: 11px;
  font-weight: 800;
  white-space: nowrap;

  .orange {
    color: #ff8432;
  }

  .green {
    color: #5bcb8f;
  }

  .blue {
    color: #4ccbfd;
  }

  .finished {
    color: #9c9c9c;
  }

  ${media.tablet`
  font-size: 20px;
  `};

  span {
    display: flex;
  }
`;

const getTimer = (timer, giveaway, t) => {
  if (
    timer &&
    (timer.mode == RAFFLE_STATE_ACTIVE ||
      timer.mode == RAFFLE_STATE_ANNOUNCED) &&
    timer.time.days > 0
  ) {
    return t(timer.mode == RAFFLE_STATE_ANNOUNCED ? 'ga_days_to_detailed' : 'ga_days_left_detailed', {
      count: timer.time.days,
    });
  }

  if (
    timer &&
    (timer.mode == RAFFLE_STATE_ACTIVE ||
      timer.mode == RAFFLE_STATE_ANNOUNCED) &&
    timer.time.days == 0
  ) {
    return `${numeral(timer.time.hours).format('00')}:${numeral(
      timer.time.minutes,
    ).format('00')}:${numeral(timer.time.seconds).format('00')}`;
  }

  if (timer && timer.mode == RAFFLE_STATE_FINISHED) {
    return i18n.t('finished');
  }
};

export class TimerDisplay extends React.PureComponent {
  state = {
    ttActive: false,
  };

  render() {
    const { timer, giveaway } = this.props;
    const { ttActive } = this.state;
    return (
      <Translation>
        {t => (
          <Layout
            onMouseOver={() => this.setState({ ttActive: true })}
            onMouseOut={() => this.setState({ ttActive: false })}
            onClick={() => this.setState({ ttActive: !ttActive })}
          >
            <span className={classNames(helpers.getTimerColorClasses(timer))}>
              {getTimer(timer, giveaway, t)}
            </span>
          </Layout>
        )}
      </Translation>
    );
  }
}
export default TimerDisplay;
