import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';
import { withForm } from 'hoc/withForm';
import { compose } from 'redux';
import InputField from 'common/InputField';
import { media } from 'styles';
import { CheckboxField } from 'common/CheckboxField';
import { Trans, Translation } from 'react-i18next';
import { postSupportRequestAction } from 'rdx/modules/api';
import MenuItem from 'common/MaterialUI/MenuItem';
import i18n from '../../i18n';
import { REGEX_EMAIL } from 'helpers/const';

const Content = styled.div`
  > p {
    margin-bottom: 20px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;

const FormArea = styled.div`
  margin-top: 20px;
  border-top: solid 2px #f0f0f0;
  padding-top: 30px;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 10px;
  }

  > * + * {
    margin-top: 10px;
  }

  ${media.tablet`
    flex-direction: row;
    > * + * {
      margin-top: 0;
      margin-left: 20px;
    }
  `};
`;

const Terms = styled.div`
  display: flex;

  > *:first-child {
    margin-right: 10px;
  }

  align-items: flex-start;
`;

class SupportModal extends React.PureComponent {
  handleClick = e => {
    e.stopPropagation();
  };

  render() {
    const { handleSubmit } = this.props;
    const { handleClick } = this;
    return (
      <Modal {...this.props}>
        <Translation>
          {t => (
            <ContentBox
              bigmodal
              title={t('modals.support.title')}
              onClick={handleClick}
            >
              <form onSubmit={handleSubmit}>
                <Content>
                  <p>
                    <Trans i18nKey="modals.contact.body">
                      Complete the form below to send us a message or drop us a
                      line directly at
                      <a href="mailto:hello@gheed.com">hello@gheed.com</a>. You
                      can also follow us on
                      <a
                        href="https://discord.gg/CCTuaCQ"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Discord
                      </a>
                      or
                      <a
                        href="https://twitter.com/gheed_com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Twitter
                      </a>

                      for updates on GHEEDs launch and development.
                    </Trans>
                  </p>

                  <FormArea>
                    <Fields>
                      <InputField name="name" title={t('forms.full_name')} />
                      <InputField
                        name="email"
                        title={t('forms.emailaddress')}
                      />
                    </Fields>
                    <Fields>
                      <InputField select name="category" title={t('category')}>
                        <MenuItem value={t('modals.support.category_tech')}>{t('modals.support.category_tech')}</MenuItem>
                        <MenuItem value={t('modals.support.category_changes')}>{t('modals.support.category_changes')}</MenuItem>
                        <MenuItem value={t('modals.support.category_report')}>{t('modals.support.category_report')}</MenuItem>
                      </InputField>
                    </Fields>
                    <Fields>
                      <InputField multiline
                        rows={4}
                        rowsMax={10}
                        name="message"
                        title={t('forms.your_message')}
                      />
                    </Fields>
                    <Fields>
                      <Terms>
                        <CheckboxField name="consent">
                          <Trans i18nKey="forms.confirm_storedata">
                            Please tick to consent to your data being stored in
                            line with our guidelines set out in our
                            <a
                              href="https://gheed.com/privacy"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              privacy policy
                            </a>
                            .
                          </Trans>
                        </CheckboxField>
                      </Terms>
                    </Fields>
                  </FormArea>
                </Content>
                <Controls>
                  <Button primary type="submit">{t('modals.support.ok')}</Button>
                </Controls>
              </form>
            </ContentBox>
          )}
        </Translation>
      </Modal>
    );
  }
}

const validate = values => {
  const errors = {};

  if (!values.email || !REGEX_EMAIL.test(values.email)) {
    errors.email = 'forms.validation_email';
  }

  if (!values.name) {
    errors.name = 'forms.validation_name';
  }

  if (!values.message) {
    errors.message = 'forms.validation_message';
  }

  if (!values.consent) {
    errors.consent = 'forms.validation_terms';
  }

  return errors;
};

const onSubmit = (values, dispatch) => {
  let sendValues = { ...values };
  if (sendValues.consent) {
    delete sendValues.consent;
  }

  dispatch(postSupportRequestAction({ payload: sendValues }))
};

export const onSubmitFail = (errors, dispatch, submitError, form) => {
  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input.error, textarea.error, select.error',
    );
    if (firstErrorNode) {
      firstErrorNode.focus();
    }
  }, 50);
};

const initialValues = {
  email: null,
  name: null,
  message: null,
  consent: false,
  captcha_response: null,
  category: i18n.t('modals.support.category_tech'),
};

const enhance = compose(
  withForm({
    form: 'contactus',
    captcha: "support",
    validate,
    onSubmit,
    initialValues,
    onSubmitFail,
    destroyOnUnmount: true,
  }),
);

export default enhance(SupportModal);
