import { createLogic } from 'redux-logic';
import { change } from 'redux-form';
import _getCreatorImages from './_getCreatorImages';
import { RESTAPI } from '../modules/api/rest';
import { getImagesProductsResponseWithFormValue, getImagesProductsErrorWithFormValue , GET_IMAGES_PRODUCTS_WITHFORM_REQUEST } from '../modules/assets';



const getImagesProductsRequestWithFormValueLogic = createLogic({
  type: GET_IMAGES_PRODUCTS_WITHFORM_REQUEST,
  latest: true,
  async process({ getState, action, cancelled$ }, dispatch, done) {
    try {

      const source = RESTAPI.getSource();
      cancelled$.subscribe(() => {
        source.cancel('Request cancelled');
      });

      const response = await RESTAPI.getImagesProducts(action.payload[0], source.token);
      dispatch(getImagesProductsResponseWithFormValue());
      dispatch(change(action.payload[1], action.payload[2], response))
      dispatch(_getCreatorImages(false));
    } catch (error) {
      dispatch(getImagesProductsErrorWithFormValue(error))
      console.log(error);
    }
    done();
  },
});

export default getImagesProductsRequestWithFormValueLogic;
