import { css } from 'styled-components';
import { blue, red, green, grey, white, black } from './colors';

const H3ExtraBold = css`
  font-family: Nunito;
  font-size: 25px;
  font-weight: 800;
`;
export const H3ExtraBoldBlue = css`
  ${H3ExtraBold}
  color: ${blue};
`;

export const H3ExtraBoldRed = css`
  ${H3ExtraBold}
  color: ${red};
`;

export const H3ExtraBoldGreen = css`
  ${H3ExtraBold}
  color: ${green};
`;

export const H3ExtraBoldGrey = css`
  ${H3ExtraBold}
  color: ${grey};
`;

export const H3ExtraBoldWhite = css`
  ${H3ExtraBold}
  color: ${white};
`;

export const H3ExtraBoldBlack = css`
  ${H3ExtraBold}
  color: ${black};
`;
