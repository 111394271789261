import React from 'react';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { Translation, Trans } from 'react-i18next';
import { media } from '../../../styles';
import Button from 'common/MaterialUI/Button';

const Layout = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Notmuch = styled.h2`
  font-size: 20px;
  color: #ff8432;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
`;

const Box = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: #f8f9fb;
  border: solid 2px #f0f0f0;

  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  font-size: 12px;
  ${media.tablet`
  font-size: 14px;
    `} min-height: 85px;

  :after {
    content: '1';
    position: absolute;
    left: 50%;
    top: -17px;
    transform: translateX(-50%);

    width: 34px;
    height: 34px;
    border-radius: 23.5px;
    box-shadow: inset 0 2px 0 0 rgba(76, 203, 253, 0.3);
    background-color: #ffffff;
    border: solid 1px #36a6fc;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Nunito;
    font-size: 20px;
    font-weight: 800;
    color: #36a6fc;
  }

  :nth-child(2) {
    :after {
      content: '2';
    }
  }

  :nth-child(3) {
    :after {
      content: '3';
    }
  }

  & + & {
    :before {
      display: none;
      content: ' ';
      background: url(/static/assets/images/ico-arrow.svg) no-repeat;
      position: absolute;
      width: 100px;
      height: 30px;
      pointer-events: none;

      top: 50%;
      transform: translateY(-50%);

      ${media.tablet`
      display: inherit;

      left: -53px;
        background-size: 75%;

      right: auto;
      
    `};

      ${media.desktop`
      left: -53px;
      background-size: auto auto;
        
      `};
    }

    ${media.tabletMax`
    margin-top: 20px;
  `};
    ${media.tablet`
    margin-left: 30px;
    `};
  }

  a {
    display: contents;
  }

  padding: 20px 30px 20px;

  ${media.desktop`
  padding: 20px 50px 20px;
  `};
`;

const BoxesLayout = styled.div`
  display: flex;

  ${media.tabletMax`
    flex-direction: column;
  `};
`;

const ButtonLayout = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
`;

export const Empty = () => (
  <Translation>
    {t => (
      <Layout>
        <Notmuch>{t('dashboard_empty_time_to_launch')}</Notmuch>

        <BoxesLayout>
          <Trans i18nKey="dashboard_empty_time_to_launch_box">
            <Box>
              Select <Link to="/create/prizes">your prizes</Link> and add them to
              your cart.
            </Box>
            <Box>Setup your giveaway and choose your entry methods.</Box>
            <Box>Proceed to checkout and launch your giveaway.</Box>
          </Trans>
        </BoxesLayout>

        <ButtonLayout>
          <Link to={`/create/prizes`}>
            <Button primary
            ><span className="icon-Ico16-Plus-Medium" /><span>{t('dashboard_empty_time_to_launch_button')}</span></Button>
          </Link>
        </ButtonLayout>
      </Layout>
    )}
  </Translation>
);
