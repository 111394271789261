import { handleActions, createAction } from 'redux-actions';
import { INFINITE_SCROLL_LIMIT, ROUTE_TYPE_GIVEAWAYS, ROUTE_TYPE_STREAMER } from 'helpers/const';
import { GET_RAFFLELIST_SEARCH_RESPONSE, GET_RAFFLELIST_SEARCH_REQUEST } from '../api/index';



export const DYNAMIC_LIST_REQUEST = 'giveawayLists/DYNAMIC_LIST_REQUEST';
export const DYNAMIC_LIST_RESPONSE = 'giveawayLists/DYNAMIC_LIST_RESPONSE';
export const CATEGORY_LIST_REQUEST = 'giveawayLists/CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_RESPONSE = 'giveawayLists/CATEGORY_LIST_RESPONSE';
export const CATEGORY_SETTINGS = 'giveawayLists/CATEGORY_SETTINGS';
export const GIVEAWAYLIST_SETTINGS = 'giveawayLists/SETTINGS';


export const giveawayListCategorySettingsAction = createAction(CATEGORY_SETTINGS);
export const giveawayListSettingsAction = createAction(GIVEAWAYLIST_SETTINGS);

export const GIVEAWAYLIST_MYLAUNCHED = "myLaunched"
export const GIVEAWAYLIST_ENTERED = "myEntered"


export const raffleCategoryListRequestAction = createAction(
  CATEGORY_LIST_REQUEST,
);

export const raffleCategoryListResponseAction = createAction(
  CATEGORY_LIST_RESPONSE,
);

export const raffleDynamicListRequestAction = createAction(
  DYNAMIC_LIST_REQUEST,
);

export const raffleDynamicListResponseAction = createAction(
  DYNAMIC_LIST_RESPONSE,
);

export const initialState = {
  category: 'active',

  searchKeyword: '',
  searchResults: [],
  searchLoading: false,
  region: 'DE',
  regionActive: true,
  regionMode: 'some',



  active: {
    query: 'MOST_POPULAR',
    nextPage: 0,
    limit: INFINITE_SCROLL_LIMIT,
    totalPages: 999,
    data: [],
    productCategory: '',
    loading: false,
    loaded: false
  },

  announced: {
    query: 'SOON_LAUNCHING',
    nextPage: 0,
    limit: INFINITE_SCROLL_LIMIT,
    totalPages: 999,
    total_raffles: 0,
    data: [],
    productCategory: '',
    loading: false,
    loaded: false
  },

  popular: {
    query: 'MOST_POPULAR',
    nextPage: 0,
    limit: INFINITE_SCROLL_LIMIT,
    totalPages: 999,
    total_raffles: 0,
    data: [],
    productCategory: '',
    loading: false,
    loaded: false,
    regionActive: false,

  },

  finished: {
    query: 'JUST_FINISHED',
    nextPage: 0,
    limit: INFINITE_SCROLL_LIMIT,
    totalPages: 999,
    total_raffles: 0,
    data: [],
    productCategory: '',
    loading: false,
    loaded: false
  },

  [GIVEAWAYLIST_MYLAUNCHED]: {
    top: 0,
    data: [],
    current_page: 0,
    total_pages: 1,
    total_raffles: 0,
    limit: 8,
    loading: false,
    loaded: false,
  },

  [GIVEAWAYLIST_ENTERED]: {
    top: 0,
    data: [],
    current_page: 0,
    total_pages: 1,
    total_raffles: 0,
    limit: 8,
    loading: false,
    loaded: false,
  },
};

const giveawayListsReducer = handleActions(
  {
    [ROUTE_TYPE_STREAMER]: (state, action) => {
      const newState = { ...state, streamer: undefined };
      return newState;
    },

    [ROUTE_TYPE_GIVEAWAYS]: (state, action) => {
      const newState = { ...state };
      newState[state.category] = { ...state[state.category], loaded: false, loading: true, nextPage: 1, totalPages: 999, data: [] };
      newState.popular = { ...state.popular, loaded: false, nextPage: 0, totalPages: 999, data: [] };
      return newState;
    },

    [GET_RAFFLELIST_SEARCH_REQUEST]: (state, action) => {
      return { ...state, searchLoading: true }
    },

    [GET_RAFFLELIST_SEARCH_RESPONSE]: (state, action) => {
      return { ...state, searchResults: action.payload.raffles, searchLoading: false }
    },

    [GIVEAWAYLIST_SETTINGS]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },

    [CATEGORY_SETTINGS]: (state, action) => {
      const category = action.payload[1] || state.category;
      return {
        ...state,
        category: category,
        [category]: {
          ...state[category],
          ...action.payload[0],
        },
      };
    },

    [CATEGORY_LIST_REQUEST]: (state, action) => {

      const listName = action.payload && action.payload[0] ? action.payload[0] : state.category;
      const settings = action.payload && action.payload[1] ? action.payload[1] : {};
      const reset = action.payload && action.payload[2];
      const persist = action.payload && action.payload[3] ? true : false;
      const currentList = state[listName];

      const nextPage = currentList && !reset
        ? currentList.nextPage + 1
        : 1
      const totalPages = !reset ? currentList.totalPages : 999

      return {
        ...state,
        category: persist ? listName : state.category,
        region: Object.prototype.hasOwnProperty.call(settings, "region") ? settings.region : state.region,
        regionActive: Object.prototype.hasOwnProperty.call(settings, "regionActive") ? settings.regionActive : state.regionActive,
        regionMode: Object.prototype.hasOwnProperty.call(settings, "regionMode") ? settings.regionMode : state.regionMode,
        [listName]: {
          ...currentList,
          loading: true,
          loaded: reset ? false : currentList.loaded,
          nextPage: nextPage,
          totalPages: totalPages,
          ...settings,
        },
      };
    },

    [CATEGORY_LIST_RESPONSE]: (state, action) => {
      const category = action.payload[1];
      const existingIds = state[category].data.map(r => r.EVENTID);
      const current_page = action.payload[0].current_page;
      const additionalData = action.payload[0].raffles.filter(
        r => existingIds.indexOf(r.EVENTID) == -1,
      );

      return {
        ...state,
        [category]: {
          ...state[category],
          loading: false,
          loaded: true,
          totalPages: action.payload[0].total_pages,

          total_raffles: action.payload[0].total_raffles,
          data: current_page == 1 ? action.payload[0].raffles : [...state[category].data, ...additionalData],
        },
      };
    },

    [DYNAMIC_LIST_REQUEST]: (state, action) => {
      return {
        ...state,
        [action.payload[1]]: {
          ...(state[action.payload[1]] || {}),
          loading: true,
        },
      };
    },

    [DYNAMIC_LIST_RESPONSE]: (state, action) => {
      const existingIds = state[action.payload[1]].data ? state[action.payload[1]].data.map(r => r.EVENTID) : [];
      const current_page = action.payload[0].current_page;
      const additionalData = action.payload[0].raffles.filter(
        r => existingIds.indexOf(r.EVENTID) == -1,
      );
      const isInfinite = action.payload[2] && action.payload[2].hasOwnProperty('isInfinite') ? action.payload[2].isInfinite : false;
      const data = isInfinite ? ( current_page == 1 ? action.payload[0].raffles : [...state[action.payload[1]].data, ...additionalData] ) : action.payload[0].raffles;
      //action.payload[0].current_page > 1 && state[action.payload[1]].data && state[action.payload[1]].data.length ? [...state[action.payload[1]].data, ...action.payload[0].raffles] : action.payload[0].raffles

      return {
        ...state,
        [action.payload[1]]: {
          ...(state[action.payload[1]] || {}),
          loading: false,
          loaded: true,
          total_pages: action.payload[0].total_pages,
          current_page: action.payload[0].current_page,
          data: data
        },
      };
    },


  },
  initialState,
);

export default giveawayListsReducer;
