import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../../styles';
import { connect } from 'react-redux';
import { Analytics } from '../../../helpers/analytics';
import { runtimeConfig } from '../../../runtimeConfig';
import { withBreakpoint } from 'hoc/withBreakpoint';
import { compose } from 'redux';

const LayoutBase = css`
  
  display: flex;
  
  background: black;
  color: white;

  width: 100%;
  
  border-radius: 6px;
  box-shadow: 0 2px 4px 2px rgba(32, 32, 34, 0.05), 0 1px 1px 0 rgba(32, 32, 34, 0.1), 0 7px 12px -10px rgba(32, 32, 34, 0.5);

  height: 120px;
  
  background-size: cover;
  background-position: center;

${({ banner }) => banner && banner.mobile && css`background-image: url(${runtimeConfig.S3}campaigns/banners/${banner.mobile.src}); width: ${banner.mobile.width}; height: ${banner.mobile.height};`}

  ${({ banner }) => banner && banner.tablet && css`${media.tablet`background-image: url(${runtimeConfig.S3}campaigns/banners/${banner.tablet.src}); width: ${banner.tablet.width}; height: ${banner.tablet.height};`}`}

  ${({ banner }) => banner && banner.desktop && css`${media.desktop`background-image: url(${runtimeConfig.S3}campaigns/banners/${banner.desktop.src}); width: ${banner.desktop.width}; height: ${banner.desktop.height};`}`}

  ${({ banner }) => banner && banner.widescreen && css`${media.widescreen`background-image: url(${runtimeConfig.S3}campaigns/banners/${banner.widescreen.src}); width: ${banner.widescreen.width}; height: ${banner.widescreen.height};`}`}
`


const LinkLayout = styled.a`

  ${LayoutBase}
  
  :hover {
    box-shadow: 0 4px 12px 0 rgba(29, 29, 29, 0.3);
  }
`

const DivLayout = styled.div`
  ${LayoutBase}
`

const Banner = ({ handleClick, disabled, campaign }) => disabled ? <DivLayout banner={campaign && campaign.banner} /> : <LinkLayout banner={campaign && campaign.banner} href={campaign && campaign.banner && campaign.link ? campaign.link : `/${campaign.path}`} onClick={handleClick} target={campaign.blank ? "_blank" : undefined} rel={campaign.blank ? "noopener noreferrer" : undefined} />



const mapStateToProps = (state, ownProps) => {
  return {
    disableAnalytics: state.ui.disableAnalytics
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  const { name, campaign, breakpoint } = ownProps
  let campaignLink = campaign.link;

  if (breakpoint == 'phone' && campaign.link_mobile) {
    campaignLink = campaign.link_mobile;
  }
  else if (breakpoint == 'tablet' && campaign.link_tablet) {
    campaignLink = campaign.link_tablet;
  }
  else if (breakpoint == 'desktop' && campaign.link_desktop) {
    campaignLink = campaign.link_desktop;
  }

  return {
    handleClick: (disableAnalytics) => (event) => {
      if (!disableAnalytics) {
        Analytics.event(
          "BannerClick", name
        );

        if (!campaign.blank) {
          event.preventDefault();
          setTimeout(() => {
            window.location = campaignLink || `/${campaign.path}`;
          }, 250);
        }
      }
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    handleClick: dispatchProps.handleClick(stateProps.disableAnalytics)
  }
}

const enhance = compose(withBreakpoint, connect(mapStateToProps, mapDispatchToProps, mergeProps))

export default enhance(Banner)