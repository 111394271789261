import { createLogic } from 'redux-logic';
import {
  GET_RAFFLE_BY_ID_EDIT_RESPONSE, getProductsByIdRequestAction,
} from '../modules/api';
import fetchPrivateProducts from './_fetchPrivateProducts';
import { SHOP_ID_WOOCOMMERCE } from '../../helpers/const';


const getRaffleByIdEditResponseLogic = createLogic({
  type: GET_RAFFLE_BY_ID_EDIT_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      const productIds = action.payload.prizes.filter(pr => pr.product.shop_id == SHOP_ID_WOOCOMMERCE).map(pr => pr.product.shopproduct_id);

      if (productIds.length) {
        dispatch(getProductsByIdRequestAction({ id: productIds }))
      }

      fetchPrivateProducts(dispatch, getState)
    } catch (error) {
      console.log(error);
    }

    done();
  },
});

export default getRaffleByIdEditResponseLogic;
