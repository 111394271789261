import React from 'react';
import Masonry from 'common/Masonry';
import { Translation } from 'react-i18next';
import styled from 'styled-components';
import { BaseGridBoxMixin } from 'styles';

const Title = styled.div`
  ${BaseGridBoxMixin}
`


const View = ({ raffles, scrollComplete, handleScrolledToBottom, loading }) => {
  return <Translation>{t => <React.Fragment>
    {raffles &&
      raffles.length > 0 && (<Title>{t('more_popular_giveaways_on_gheed')}</Title>)}

    {raffles &&
      raffles.length > 0 && (
        <Masonry
          items={raffles}
          hasMore={!scrollComplete}
          isLoading={loading}
          onInfiniteLoad={handleScrolledToBottom}
          getState={() => this.state}
        />
      )}
  </React.Fragment>}</Translation>


}


export default View;