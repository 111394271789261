import React from 'react';
import { BreakPoints } from 'styles';
import {
  addResizeListener,
  removeResizeListener,
  getViewport,
} from 'helpers/resize';

export const withBreakpoint = Component =>
  class Wrapped extends React.Component {
    constructor(props) {
      super(props);
      const vp = getViewport();
      this.state = {
        breakpoint: this.getBreakpoint(vp),
        breakpointH: this.getBreakpoint(vp, 'height'),
      };
    }

    getBreakpoint = (viewport, direction = 'width') => {
      let breakpoint = null;

      let bpKeys = Object.keys(BreakPoints);
      bpKeys.sort((a, b) => BreakPoints[a] - BreakPoints[b]);
      bpKeys.forEach(key => {
        if (BreakPoints[key] <= viewport[direction]) {
          breakpoint = key;
        }
      });

      if (!breakpoint) {
        breakpoint = bpKeys[0];
      }
      return breakpoint;
    };

    setBreakPoint = viewport => {
      let breakpoint = this.getBreakpoint(viewport);
      let breakpointH = this.getBreakpoint(viewport, 'height');

      if (
        breakpoint != this.state.breakpoint ||
        breakpointH != this.state.breakpointH
      ) {
        this.setState({ breakpoint, breakpointH });
      }
    };

    componentDidMount() {
      addResizeListener(this.setBreakPoint);
    }

    componentWillUnmount() {
      removeResizeListener(this.setBreakPoint);
    }

    render() {
      return <Component {...this.props} {...this.state} />;
    }
  };

export default withBreakpoint;
