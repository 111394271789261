import { handleActions, createAction } from 'redux-actions';
import { INFINITE_SCROLL_LIMIT } from 'helpers/const';

import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_RESPONSE,
  GET_CATEGORIES_RESPONSE,
  GET_PRODUCTS_BY_ID_RESPONSE,
  GET_PRODUCTS_BY_ID_REQUEST,
  GET_PRODUCTS_BY_ID_STATUS_RESPONSE,

} from './../api';

export const STORE_SORT = 'store/SORT';
export const STORE_SELECT_VARIATION = 'store/SELECT_VARIATION';
export const STORE_FILTERED_LIST_REQUEST = 'store/FILTERED_LIST_REQUEST';
export const STORE_FILTERED_LIST_RESPONSE = 'store/FILTERED_LIST_RESPONSE';

export const GET_PRODUCTS_LIST_REQUEST = 'store/PRODUCTS_LIST_REQUEST';
export const GET_PRODUCTS_LIST_RESPONSE = 'store/PRODUCTS_LIST_RESPONSE';

export const PRODUCTS_SEARCH_REQUEST = 'store/PRODUCTS_SEARCH_REQUEST';
export const PRODUCTS_SEARCH_RESPONSE = 'store/PRODUCTS_SEARCH_RESPONSE';
export const PRODUCTS_SETTINGS = 'store/PRODUCTS_SETTINGS';

export const getProductsSearchRequestAction = createAction(
  PRODUCTS_SEARCH_REQUEST
);

export const getProductsSearchResponseAction = createAction(
  PRODUCTS_SEARCH_RESPONSE
);

export const productsSettingsAction = createAction(
  PRODUCTS_SETTINGS
);

export const getProductsListRequestAction = createAction(
  GET_PRODUCTS_LIST_REQUEST,
);

export const getProductsListResponseAction = createAction(
  GET_PRODUCTS_LIST_RESPONSE,
);

export const storeSortAction = createAction(STORE_SORT);
export const selectProductVariationAction = createAction(
  STORE_SELECT_VARIATION,
);

export const storeFilteredListRequestAction = createAction(
  STORE_FILTERED_LIST_REQUEST,
);

export const storeFilteredListResponseAction = createAction(
  STORE_FILTERED_LIST_RESPONSE,
);

const resetState = {
  nextPage: 1,
  limit: INFINITE_SCROLL_LIMIT,
  totalPages: 999,
  totalProducts: undefined,
  sorted: [],
};

export const initialState = {
  products: null,
  stock: {},
  previews: {},
  selectedVariations: {},
  variations: {},
  category: null,
  categories: null,
  orderby: 'total_sales',
  order: 'desc',
  loading: false,
  ...resetState,
  lists: {},

  searchKeyword: '',
  searchResults: [],
  searchLoading: false,
  region: 'DE',
};

export const productsReducer = handleActions(
  {

    [PRODUCTS_SETTINGS]: (state, action) => {
      return { ...state, ...action.payload }
    },

    [PRODUCTS_SEARCH_REQUEST]: (state, action) => {
      return { ...state, searchLoading: true }
    },

    [PRODUCTS_SEARCH_RESPONSE]: (state, action) => {
      return { ...state, searchResults: action.payload.products, searchLoading: false }
    },

    [STORE_SELECT_VARIATION]: (state, action) => {
      return {
        ...state,
        selectedVariations: {
          ...state.selectedVariations,
          [action.payload.productId]: action.payload.variationId,
        },
      };
    },
    [STORE_SORT]: (state, action) => {
      const reset =
        action.payload.region != state.region ||
        action.payload.category != state.category ||
        action.payload.order != state.order ||
        action.payload.orderby != state.orderby;
      return {
        ...state,
        ...(reset ? resetState : {}),
        region: typeof (action.payload.region) != "undefined" ? action.payload.region : state.region,
        category: action.payload.category,
        order: action.payload.order || state.order,
        orderby: action.payload.orderby || state.orderby,
      };
    },
    [GET_CATEGORIES_RESPONSE]: (state, action) => {
      return {
        ...state,
        categories: action.payload.data,
      };
    },
    [GET_PRODUCTS_REQUEST]: (state, action) => {
      return {
        ...state,
        loading: true,
        nextPage: state.nextPage + 1,
      };
    },
    [GET_PRODUCTS_BY_ID_REQUEST]: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GET_PRODUCTS_RESPONSE]: (state, action) => {
      const selVariations = {};
      const productsById = action.payload.products.reduce((p, c, i, a) => {
        p[c.PRODUCTID] = c;
        if (c.variations && c.variations.length > 0) {
          selVariations[c.PRODUCTID] = c.variations[0]
        }
        return p;
      }, {});

      return {
        ...state,
        selectedVariations: { ...selVariations, ...state.selectedVariations },
        previews: state.previews
          ? { ...state.previews, ...productsById }
          : productsById,
        loading: false,
        sorted: [
          ...state.sorted,
          ...action.payload.products.map(p => p.PRODUCTID),
        ],
        totalProducts: action.payload.total_products,
        totalPages: action.payload.total_pages,
      };
    },
    [GET_PRODUCTS_BY_ID_RESPONSE]: (state, action) => {
      const selVariations = {};
      const productsById = action.payload.products.reduce((p, c, i, a) => {
        p[c.PRODUCTID] = c;
        if (c.variations && c.variations.length > 0) {
          selVariations[c.PRODUCTID] = c.variations[0].PRODUCTID
        }
        return p;
      }, {});

      return {
        ...state,
        selectedVariations: { ...selVariations, ...state.selectedVariations },
        products: state.products
          ? { ...state.products, ...productsById }
          : productsById,
        loading: false,
      };
    },

    [STORE_FILTERED_LIST_RESPONSE]: (state, action) => {
      let newLists = { ...state.lists };

      Object.keys(action.payload).forEach(k => {
        newLists = {
          ...newLists,
          [k]: action.payload[k] ? action.payload[k].products : null,
        };
      });
      return { ...state, lists: newLists };
    },

    [GET_PRODUCTS_BY_ID_STATUS_RESPONSE]: (state, action) => {
      const result = action.payload.reduce((p, c) => {
        const { PRODUCTID, ...info } = c
        return { ...p, [PRODUCTID]: info }
      }, {})
      return { ...state, stock: { ...state.stock, ...result } }
    }
  },
  initialState,
);

export default productsReducer;
