import { css } from 'styled-components';
import { blue, red, green, grey, white, black } from './colors';

const H6ExtraBold = css`
  font-family: Nunito;
  font-size: 13px;
  font-weight: 800;
`;
export const H6ExtraBoldBlue = css`
  ${H6ExtraBold}
  color: ${blue};
`;

export const H6ExtraBoldRed = css`
  ${H6ExtraBold}
  color: ${red};
`;

export const H6ExtraBoldGreen = css`
  ${H6ExtraBold}
  color: ${green};
`;

export const H6ExtraBoldGrey = css`
  ${H6ExtraBold}
  color: ${grey};
`;

export const H6ExtraBoldWhite = css`
  ${H6ExtraBold}
  color: ${white};
`;

export const H6ExtraBoldBlack = css`
  ${H6ExtraBold}
  color: ${black};
`;
