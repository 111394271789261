import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Switch from 'common/MaterialUI/Switch';
import TextField from 'common/MaterialUI/TextField';
import MenuItem from 'common/MaterialUI/MenuItem';
import { runtimeConfig } from '../../../runtimeConfig';
import numeral from 'i18n/numeral';
import { Translation, Trans } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import styled, { css } from 'styled-components';
import ClipBox from './ClipBox';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import InfoButton from 'common/InfoButton';

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    borderRadius: '3px',
    boxShadow: 'none',
    '& + &': {
      marginTop: '5px'
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,

    },
  },
  expanded: {
    margin: '0',
    border: 'none'
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    cursor: 'auto !important',
    minHeight: '40px',
    '&$expanded': {
      minHeight: '40px',

    },
  },


  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,

    },
    '& > :last-child': {
      paddingRight: 0,
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  expanded: {
    margin: 0
  }

})(MuiExpansionPanelSummary);


const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,

  }
})(MuiExpansionPanelDetails);


const PreviewWidget = styled(({ large, url, className }) => <div className={className}><iframe title="widget-small" seamless src={url} /></div>)`
  
display: flex;
justify-content: center;
margin: 0 0 15px;
border-radius: 3px;
border: solid 1px #e1e2ef;

iframe {
  width: ${({ large }) => large ? '470px' : '240px'};
  height: ${({ large }) => large ? '187px' : '196px'};
}
  
`


const Setting = styled(({ className, children, label }) => <div className={className}><div>{label}</div><div>{children}</div></div>)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    :first-child {
      margin-right: 10px;
    }

    :last-child {
      display: flex;
      justify-content: flex-end;
      ${({ even }) => even && css`width: 50%;`}
    }
  }
  margin-bottom: 5px;
`

const CustomSlider = styled(({ className, displayValue, ...other }) => <div className={className}><span>{displayValue}</span><Slider {...other} /></div>)`

display: flex;
justify-content: space-between;

> span {
  white-space: nowrap;
    min-width: 65px;
}


min-width: 100px;
  width: 100%;
  transform: translateY(3px);
`

const DetailContent = styled.div`
overflow: hidden;
max-width: 100%;

`

const Description = styled.p`
  margin: 0 0 15px;
`

const Configure = styled(({ label, expanded, onClick, className }) => <div onClick={onClick} className={className}>{label}<span className={expanded ? "icon-Ico16-ArrowUp-Small" : "icon-Ico16-ArrowDown-Small"} /></div>)`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ expanded }) => !expanded ? '#36a6fc' : '#9697a1'};
`


const WidgetConfigurator = ({ EVENTID, giveaway, language }) => {

  const [widgetLarge, setWidgetLarge] = useState(false);
  const [widgetInterval, setWidgetInvertal] = useState(2000);
  const [widgetDuration, setWidgetDuration] = useState(10000);
  const [widgetLanguage, setWidgetLanguage] = useState(language);
  const [widgetStreamer, setWidgetStreamer] = useState(null);

  const [expanded, setExpanded] = useState(false);


  const widgetURL = `${runtimeConfig.SERVER_FULLADDRESS}/widget/?style=${widgetLarge ? 'bigbox' : ''}&${widgetStreamer ? `streamer=${widgetStreamer}` : `giveaway=${EVENTID}`}&interval=${widgetInterval}&duration=${widgetDuration}&language=${widgetLanguage}`

  const previewURL = `${runtimeConfig.SERVER_FULLADDRESS}/widget/?style=${widgetLarge ? 'bigbox' : ''}&${widgetStreamer ? `streamer=${widgetStreamer}` : `giveaway=${EVENTID}`}&interval=2000&duration=${widgetDuration}&language=${widgetLanguage}`


  return <Translation>{t => (<React.Fragment>
    <ClipBox title={t("Modals/PostLaunch/index.widget")} details={() => {

      return <ExpansionPanel expanded={expanded}>
        <ExpansionPanelSummary><Configure onClick={() => setExpanded(!expanded)} expanded={expanded} label={t("Modals/PostLaunch/Widget.configureandpreview")} /></ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <DetailContent>
            <Description><Trans i18nKey="Modals/PostLaunch/Widget.bspdesc">This <b>browser source widget</b> can be added to your OBS stream overlay and will then display your giveaway in specific intervals. Check out <a href="https://obsproject.com/wiki/Sources-Guide#browsersource" target="_blank"
              rel="noopener noreferrer">this tutorial</a>, if you need help setting it up in OBS.</Trans></Description>
            <PreviewWidget url={previewURL} large={widgetLarge} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>{giveaway && <Setting label={<span>{t("Modals/PostLaunch/index.mostrecent")} <InfoButton inline title={t("Modals/PostLaunch/Widget.mostrecentexplanation")} /></span>}><Switch checked={widgetStreamer} onChange={(event) => setWidgetStreamer(event.target.checked ? giveaway.host_info.USERID : null)} /></Setting>}<Setting label={t("Modals/PostLaunch/index.large")}><Switch checked={widgetLarge} onChange={(event) => setWidgetLarge(event.target.checked)} /></Setting></Grid>
              <Grid item xs={12} sm={6}>


                <Setting even label={t("Modals/PostLaunch/index.interval")}> <CustomSlider displayValue={t("Modals/PostLaunch/Widget.value.min", { value: numeral(widgetInterval / 60000).format('0.0') })} step={30000} min={0} max={600000} value={widgetInterval} onChange={(event, newValue) => setWidgetInvertal(newValue)} aria-labelledby="interval-slider" /></Setting>
                <Setting even label={t("Modals/PostLaunch/index.duration")}><CustomSlider displayValue={t("Modals/PostLaunch/Widget.value.sec", { value: widgetDuration / 1000 })} step={1000} min={0} max={60000} value={widgetDuration} onChange={(event, newValue) => setWidgetDuration(newValue)} aria-labelledby="duration-slider" /></Setting>
                <Setting even label={t("Modals/PostLaunch/index.language")}>
                  <TextField select nomargin value={widgetLanguage} onChange={(event, newValue) => setWidgetLanguage(event.target.value)}>
                    <MenuItem key={"de"} value={"de"} >Deutsch</MenuItem>
                    <MenuItem key={"en"} value={"en"} >English</MenuItem>
                  </TextField>
                </Setting>
              </Grid>
            </Grid>


          </DetailContent>
        </ExpansionPanelDetails>
      </ExpansionPanel>


    }}>{widgetURL}</ClipBox>





  </React.Fragment >)}</Translation >




}


const mapStateToProps = (state, ownProps) => {
  return {
    language: state.ui.language,
    giveaway: { ...state.raffles.preview, ...state.raffles.raffles }[ownProps.EVENTID]
  }
}

export default connect(mapStateToProps)(WidgetConfigurator)
