import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { media } from 'styles';

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  

  50% {
    opacity: 1;
  }

  90% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
`;

const animationRule = css`${animation} ${props => props.duration}ms both`;

const Layout = styled.div.attrs(props => ({ style: { animationPlayState: props.paused ? 'paused' : 'running' } }))`
  animation: ${animationRule};
  color: white;
  flex: 0 0 auto;

  font-family: Nunito;

  font-weight: 800;

  font-size: 16px;

  ${media.tablet`
font-size: 24px;
`};

  ${media.widescreen`
font-size: 40px;
`};
`;

const Shared = css`
  font-color: white;
  text-shadow: 0 2px 0 rgba(29, 29, 29, 0.8);
  position: absolute;
  left: 50%;
  top: 50%;
`;

const And = styled.div`
  ${Shared};
  font-size: 1.5em;
  transform: translate(-103%, -129%);
`;

const The = styled.div`
  ${Shared};
  transform: translate(5%, -190%);
  font-size: 0.7em;
`;

const Winners = styled.div`
  ${Shared};
  font-size: 2em;
  transform: translate(-50%, -50%);
`;

const Are = styled.div`
  ${Shared};
  transform: translate(-42%, 52%);
  font-size: 1em;
  white-space: nowrap;
`;

export class AndTheWinnersAre extends React.PureComponent {
  render() {
    const { paused, duration } = this.props;

    return (
      <Layout {...{ paused, duration }}>
        <And {...{ paused, duration }}>AND</And>
        <The {...{ paused, duration }}>THE</The>
        <Winners {...{ paused, duration }}>WINNERS</Winners>
        <Are {...{ paused, duration }}>ARE ...</Are>
      </Layout>
    );
  }
}
