import { connect } from 'react-redux';
import { raffleCategoryListRequestAction } from 'rdx/modules/giveawayLists';
import { push } from 'redux-first-router';
import {
  INFINITE_SCOLL_MORE_BUTTON_LIMIT,
  MASONRYITEM_TYPE_CONTEST,
} from 'helpers/const';
import { RESTAPI } from 'rdx/modules/api/rest';
import { createSelector } from 'reselect';
import { MAP_CONTEST_SORT_OPTIONS } from 'helpers/contest_sort_options';
import Layout from './Layout';

const productCategories = state => state.products.categories;
const giveawayLists = state => state.giveawayLists;
const filteredList = state => state.giveawayLists[state.giveawayLists.category];
const language = state => state.ui.language;

const mapStateToProps = state =>
  createSelector(
    [giveawayLists, filteredList, productCategories, language],
    (giveawayLists, filteredList, productCategories, language) => {
      const { category, region,
        regionMode, regionActive } = giveawayLists;

      const {
        data,
        query,
        nextPage,
        totalPages,
        productCategory,
        loading,
        loaded
      } = filteredList;


      return {
        loading,
        loaded,
        raffles: data
          ? data.map(raffle => ({
            type: MASONRYITEM_TYPE_CONTEST,
            data: raffle,
            id: `r${raffle.EVENTID}`
          }))
          : [],

        sortKey: query,
        sortOptions: category ? MAP_CONTEST_SORT_OPTIONS[category] : [],
        showMoreButton: data
          ? data.length >= INFINITE_SCOLL_MORE_BUTTON_LIMIT
          : true,
        scrollComplete: nextPage > totalPages,
        productCategory: productCategory,
        productCategories: productCategories,
        contestCategory: category,
        region,
        regionMode,
        regionActive,
      };
    },
  );

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleRaffleSearch: str => {
      return str.length >= 3
        ? RESTAPI.getRafflelistSearch({ page: 1, limit: 10, string: str })
        : Promise.resolve(null);
    },
    handleSelect: raffle => {
      push(`/giveaways/${raffle.EVENTID}`);
    },
    handleScrolledToBottom: (loading, loaded) => settings => {
      if (loaded && !loading) {
        dispatch(raffleCategoryListRequestAction());
      }

    },
    handleMoreButtonClick: settings =>
      dispatch(raffleCategoryListRequestAction()),
    handleChangeRegionSettings: (payload) => {
      dispatch((dispatch, getState) => {
        dispatch(raffleCategoryListRequestAction([
          null,
          {
            ...payload,
          },
          true
        ]))
      });
    },
    handleSortDropDownConfirm: value => {
      dispatch((dispatch, getState) => {
        dispatch(
          raffleCategoryListRequestAction([
            null,
            {
              query: value,
            },
            true
          ]),
        );
      });
    },
    handleCategoryDropDownConfirm: async productCategory => {
      dispatch((dispatch, getState) => {
        dispatch(
          raffleCategoryListRequestAction([
            null,
            {
              productCategory: productCategory,
            },
            true
          ]),
        );
      });
    },
    handleStatusDropDownConfirm: async category => {
      dispatch((dispatch, getState) => {
        dispatch(
          raffleCategoryListRequestAction([
            category,
            {},
            true,
            true
          ]),
        );
      });
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    handleScrolledToBottom: dispatchProps.handleScrolledToBottom(stateProps.loading, stateProps.loaded),
    ...ownProps
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Layout);
