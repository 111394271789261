import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { Translation } from 'react-i18next';
import { H4ExtraBoldWhite } from 'styles/Kit/H4ExtraBold';
import { H5ExtraBoldWhite } from 'styles/Kit/H5ExtraBold';



const Layout = styled.div`
background: url(/static/assets/images/creator/Giveaway-Claim-Banner.png) no-repeat;
background-size: cover;
background-position: center top;
padding: 20px;
min-height: 85px;

border-top-left-radius: 6px;
border-top-right-radius: 6px;


display: flex;
justify-content: center;
align-items: center;
word-break: break-word;

${H5ExtraBoldWhite};
  ${media.tablet`
  ${H4ExtraBoldWhite};
  `};
`;


const ClaimBanner = props => {
  return (
    <Translation>
      {t => (
        <Layout>
          {t("ClaimPrizes/Banner.claimnow")}
        </Layout>
      )}
    </Translation>
  );
};

export default ClaimBanner;
