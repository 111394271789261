import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';
import { Translation, Trans } from 'react-i18next';

const Content = styled.div`
  text-align: center;
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;

const CheckoutModal = props => {
  return (
    <Modal {...props}>
      <Translation>
        {t => (
          <ContentBox modal centerTitle title={t('modals.checkout.title')}>
            <Content>
              <Trans i18nKey="modals.checkout.body">
                Please complete the checkout process shown in the popup window.
                In case you encounter any problems, please contact our
                <a href="mailto:support@gheed.com">support</a>.
              </Trans>
            </Content>
            <Controls>
              <Button outline small
                onClick={props.onRequestClose}
              >{t('modals.checkout.ok')}</Button>
            </Controls>
          </ContentBox>
        )}
      </Translation>
    </Modal>
  );
};

export default CheckoutModal;
