import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { runtimeConfig } from '../runtimeConfig';



export const generateCaptcha = (action) => {

  return new Promise((resolve, reject) => {

    if (!window.grecaptcha) {
      reject("no captcha library found")
      return;
    }

    window.grecaptcha.ready(() => window.grecaptcha.execute(runtimeConfig.RECAPTCHA_SITE_KEY, {
      action,
    }).then(token => {
      resolve(token)
    }))

  })



}


const submitWithCaptcha = (action, callback) => (values, dispatch, props) => {

  return generateCaptcha(action).then(token => {
    return callback({ ...values, captcha_response: token }, dispatch, props)
  });
}




export const withForm = ({ captcha, onSubmit, ...options }) => {
  return WrappedComponent => {
    class Wrapped extends Component {
      constructor(props) {
        super(props);
      }

      render() {
        return <WrappedComponent {...this.props} Field={Field} />
      }
    }

    let ConnectedWrapper = connect((state, ownProps) => {
      return {
        posting: state.form.posting,
        postRejected: state.form.postRejected,
        postFulfilled: state.form.postFulfilled,
      };
    })(Wrapped);

    return reduxForm({ enableReinitialize: true, keepDirtyOnReinitialize: true, destroyOnUnmount: true, ...options, onSubmit: captcha ? submitWithCaptcha(captcha, onSubmit) : onSubmit })(ConnectedWrapper);
  };
};

export default withForm;
