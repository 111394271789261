import { connect } from 'react-redux';
import View from './View';
import { authRoleChangeRequestAction } from '../../../store/modules/auth';
import { modalClearAction } from 'rdx/modules/modals';
import { PRIMARY_ROLETYPEID_STREAMER } from '../../../helpers/const';


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleRoleSave: () => {
      dispatch(authRoleChangeRequestAction(PRIMARY_ROLETYPEID_STREAMER))
      dispatch(modalClearAction())
    }

  };
};

export default connect(
  null,
  mapDispatchToProps,
)(View);
