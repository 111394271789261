
import { connect } from 'react-redux';
import View from './View';
import isStreamer from 'rdx/selectors/isStreamer';
import storeSetupRequired from 'rdx/selectors/storeSetupRequired';
import { PRIMARY_ROLETYPEID_STREAMER } from '../../../helpers/const';
import { putUsersSettingsResponseAction } from '../../../store/modules/api';
import RESTAPI from '../../../store/modules/api/rest';
import { showNotificationModal } from '../../../store/modules/modals';
import { push } from 'redux-first-router';

const mapStateToProps = (state, ownProps) => {
  return {
    detailed: typeof ownProps.detailed != 'undefined' ? ownProps.detailed : false,
    storeSetupRequired: storeSetupRequired(state),
    isStreamer: isStreamer(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleContinue: async () => {
      try {



        const resp = await RESTAPI.putUsersSettings({ payload: { primary_roletypeid: PRIMARY_ROLETYPEID_STREAMER} });
        dispatch(putUsersSettingsResponseAction(resp))
        //push('/create/prizes')
      } catch (e) {
        dispatch(
          showNotificationModal({
            title: 'modals.notifications.oh_snap',
            translate: false,
            content:
              e.response && e.response.data && e.response.data.message
          }),
        );
      }




    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(View);