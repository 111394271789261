import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'redux-first-router-link';
import { media } from '../../../styles';
import { connect } from 'react-redux';
import { Analytics } from '../../../helpers/analytics';

const LayoutBase = css`
  background: black;
  color: white;

  width: 100%;
  
  border-radius: 6px;
  box-shadow: 0 2px 4px 2px rgba(32, 32, 34, 0.05), 0 1px 1px 0 rgba(32, 32, 34, 0.1), 0 7px 12px -10px rgba(32, 32, 34, 0.5);

  height: 120px;
  
  background-size: cover;
  background-image: url("/static/assets/images/partnership/partner-dashboard-banner-${({ language }) => language || 'en'}-mobile.png");

  ${media.tablet`
    background-image: url("/static/assets/images/partnership/partner-dashboard-banner-${({ language }) => language || 'en'}-tablet.png");
  `}

  ${media.desktop`
    background-image: url("/static/assets/images/partnership/partner-dashboard-banner-${({ language }) => language || 'en'}-desktop.png");
    height: 89px;
  `}

  ${media.widescreen`
    height: 120px;
  `}
`


const LinkLayout = styled(Link)`

  ${LayoutBase}
  
  :hover {
    box-shadow: 0 4px 12px 0 rgba(29, 29, 29, 0.3);
  }
`


const Banner = ({ handleClick, language }) => <LinkLayout to="/partnership" language={language} onClick={handleClick} />

const mapStateToProps = (state, ownProps) => {
  return {
    disableAnalytics: state.ui.disableAnalytics,
    language: state.ui.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleClick: (disableAnalytics) => () => {
      if (!disableAnalytics) {
        Analytics.event(
          "BannerClick", 'Partnership'
        );
      }
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    handleClick: dispatchProps.handleClick(stateProps.disableAnalytics)
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Banner)