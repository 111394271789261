import { createLogic } from 'redux-logic';
import { PUT_CLAIM_PRIZE_RESPONSE, getDrawStatusByRaffleIdRequestAction } from '../modules/api/index';

const putClaimPrizeResponseLogic = createLogic({
  type: PUT_CLAIM_PRIZE_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      dispatch(getDrawStatusByRaffleIdRequestAction({ id: action.payload.eventid }));
    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default putClaimPrizeResponseLogic;
