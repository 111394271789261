import { createLogic } from 'redux-logic';
import { change } from 'redux-form';
import _getCreatorImages from './_getCreatorImages';
import { RESTAPI } from '../modules/api/rest';
import { POST_IMAGES_WITHFORM_REQUEST, postImagesResponseWithFormValue, postImagesErrorWithFormValue } from '../modules/assets';



const postImagesRequestWithFormValueLogic = createLogic({
  type: POST_IMAGES_WITHFORM_REQUEST,
  latest: true,
  async process({ getState, action, cancelled$ }, dispatch, done) {
    try {

      const source = RESTAPI.getSource();
      cancelled$.subscribe(() => {
        source.cancel('Request cancelled');
      });

      const response = await RESTAPI.postImages(action.payload[0], source.token);
      dispatch(postImagesResponseWithFormValue());
      dispatch(change(action.payload[1], action.payload[2], response))
      dispatch(_getCreatorImages(false));
    } catch (error) {
      dispatch(postImagesErrorWithFormValue(error));
      console.log(error);
    }
    done();
  },
});

export default postImagesRequestWithFormValueLogic;
