import React, { useState } from 'react';
import Modal from 'common/Modal';
import { ContentBox } from 'common/ContentBox';
import { Translation } from 'react-i18next';

import EmailFlow from './EmailFlow';
import Overview from './Overview'

import ReCAPTCHA from 'react-google-recaptcha';
import { runtimeConfig } from '../../../runtimeConfig';
import { CaptchaMargin, LoginMargin } from '../mixins';


const Inner = (props) => {

  const { data, onRequestClose } = props;
  const [tab, setTab] = useState(data && data.view ? data.view : "overview");

  const [emailView, setEmailView] = useState(data && data.emailView ? data.emailView : "login")
  const [visibleCaptcha, setVisibleCaptcha] = useState(null);

  return <Translation>
    {t => (
      <ContentBox modal nomargin centerTitle title={t('modals.connect.title')}>
        <CaptchaMargin>
        <ReCAPTCHA
            sitekey={runtimeConfig.RECAPTCHA_VISIBLE_SITE_KEY}
            onChange={(event) => setVisibleCaptcha(event)} 
        />
        </CaptchaMargin>
        <LoginMargin>
        {tab == "overview" && <Overview visibleCaptcha={visibleCaptcha} showEmailFlow={() => {
          setEmailView("login")
          setTab("email")
        }} onRequestClose={onRequestClose} />}
        {tab == "email" && <EmailFlow visibleCaptcha={visibleCaptcha} view={emailView} showOverview={() => setTab("overview")} onRequestClose={onRequestClose} />}
        </LoginMargin>
      </ContentBox>
    )}
  </Translation>
}


const ConnectModal = (props) => {

  return (
    <Modal {...props}>
      <Inner {...props} />
    </Modal>
  );
}

export default ConnectModal