import { PUT_CART_ERROR, getCartRequestAction } from '../modules/api';
import { createLogic } from 'redux-logic';
import isStreamer from '../selectors/isStreamer';

const putCartErrorLogic = createLogic({
  type: PUT_CART_ERROR,

  process({ getState, action }, dispatch, done) {
    try {
      const state = getState();
      if (state.auth.user && isStreamer(state) && state.auth.user.email_verified) {
        dispatch(getCartRequestAction());
      }
    } catch (error) {
      console.log(error)
    }
    done();
  },
});

export default putCartErrorLogic;