import styled from 'styled-components';
import React from 'react';
import { mediaMixin, media } from '../../../../styles/index';
import i18n from 'i18n';


const Layout = styled.div`
  ${mediaMixin};
  border: solid 1px #e1e2ef;
  border-radius: 4px;
  position: relative;
  width: 100%;
  flex: 1 1 auto;
  display: flex;
`;

const Input = styled.input`
  ${mediaMixin}
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  user-select: text;

border-radius: 3px;
border: solid 1px #e1e2ef;
background-color: #f8f9fb;
width: 100%;
text-align: center;
padding: 2px 5px;
min-height: 26px;
font-family: "Courier New", Courier, monospace;

font-weight: bold;
color: #9697a1;

font-size: 13px;
${media.tablet`
font-size: 20px;
`}

`



const ClipBoard = styled.a`
  flex: 0 0 auto;
  cursor: pointer;
  color: #36a6fc;
  right: 7px;
  bottom: calc(50% - 8px);
  position: absolute;
`



class Code extends React.PureComponent {

  copyToClipBoard = () => {

    this.input.select();
    document.execCommand("copy");
    alert(i18n.t('ga_code_copied') + this.input.value);
  }

  render() {
    const { couponstatus, ...other } = this.props;
    const { copyToClipBoard } = this;
    return <Layout {...other}><Input type="text" ref={node => this.input = node} value={couponstatus} /><ClipBoard onClick={event => copyToClipBoard()} className="icon-Ico20-Clipboard" /></Layout>;
  }
}

export default Code;

