import { createLogic } from 'redux-logic';
import { AUTH_LOGOUT_REQUEST } from 'rdx/modules/auth';
import { push } from 'redux-first-router';
import { JWT } from 'helpers/jwt';

const logoutRequestActionLogic = createLogic({
  type: AUTH_LOGOUT_REQUEST,

  process({ getState, action }, dispatch, done) {
    try {
      JWT.removeAccessToken()
      JWT.removeRefreshToken()
      try {
        push('/');
      } catch (error) {
        console.log(error)
      }

      if (typeof window != 'undefined') {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default logoutRequestActionLogic;