import styled from 'styled-components';
import React from 'react';
import { colors, media } from 'styles';
import Logo from '../../../common/Logo';
import MyOrder from '../../Raffles/Creator/MyOrder';
import EditHeader from '../../Raffles/Creator/EditHeader';
import { connect } from 'react-redux';
import { ROUTE_TYPE_GIVEAWAY_EDIT } from '../../../helpers/const';

const Layout = styled.div`
  width: 100%;
  justify-content: space-between;

  height: 66px;
  background: ${colors.black};
  display: flex;
  align-items: center;

  

  max-width: 300px;
  min-width: 300px;

  ${media.tablet`
max-width: 630px;
`};
  ${media.desktop`
max-width: 960px;
`};
  ${media.widescreen`
max-width: 1290px;
`};

`;

const LogoOnly = ({ isEdit }) => (
  <Layout>
    <Logo to="/giveaways" />
    {!isEdit ? <MyOrder /> : <EditHeader />}
  </Layout>
)

const mapStateToProps = (state, ownProps) => {
  return {
    isEdit: state.location.type == ROUTE_TYPE_GIVEAWAY_EDIT
  }
}


export default connect(mapStateToProps)(LogoOnly);
