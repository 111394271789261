import React from 'react';
import Box from './_Box';
import Button from 'common/MaterialUI/Button';
import Explanation from './_Explanation';
import Hint from './_Hint';
import { WAYTYPEID_GHEED_COMPLETE_ALL_OTHER_ENTRYWAYS, WAYTYPEID_GHEED_JOIN_GIVEAWAY } from 'helpers/const';
import Description from './_Description';
import i18n from 'i18n';

const Simple = ({ way, waytype, handleContinue, toggle, ...other }) => {

  let icon;

  if ([WAYTYPEID_GHEED_COMPLETE_ALL_OTHER_ENTRYWAYS].indexOf(waytype.WAYTYPEID) != -1) {
    icon = 'icon-Ico16-Star'
  }
  else if ([WAYTYPEID_GHEED_JOIN_GIVEAWAY].indexOf(waytype.WAYTYPEID) != -1) {
    icon = 'icon-Ico16-Star'
  }

  const hasHint = !(i18n.t(`waytypes.${waytype.WAYTYPEID}.hint`) == `waytypes.${waytype.WAYTYPEID}.hint` || i18n.t(`waytypes.${waytype.WAYTYPEID}.hint`) == "");

  return <Box {...{ way, waytype, toggle, ...other }} icon={icon}
    controls={(complete, loading, t) => <React.Fragment>
      <Button outline small onClick={toggle(way)}>{t("cancel")}</Button>
      <Button secondary small disabled={complete || loading || way.disabled} onClick={handleContinue(way, null, toggle(way))}>{t("continue")}</Button>
    </React.Fragment>}
  ><Description way={way} /><Explanation way={way} waytype={waytype} />{hasHint && <Hint way={way} waytype={waytype} />}</Box>
}


export default Simple