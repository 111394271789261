import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';
import { Translation } from 'react-i18next';
import { media } from '../../styles';

const Content = styled.div`
  padding: 33px 20px 20px;

  ${media.tablet`
  padding: 33px 40px 40px;
  `} 
`;

const Controls = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;

  > * {
    flex: 0 1 auto;

    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;


class Inner extends React.PureComponent {
  render() {
    const { data, big, ref, handleCallback, onRequestClose } = this.props;

    return <div ref={ref}><Translation>

      {t => (
        <ContentBox nomargin modal={!big && (!data || !data.big)} bigmodal={big || (data && data.big)} centerTitle title={data && t(data.title)} footer={() => <Controls>
          {data &&
            data.cancel && (
              <Button outline small
                onClick={onRequestClose}
              >{t(data.cancel)}</Button>
            )}
          {!data ||
            (!data.button && (
              <Button small primary
                onClick={handleCallback}
              >{t(data.ok) || 'Ok'}</Button>
            ))}

          {data &&
            data.button &&
            data.callback && (
              <Button primary small
                onClick={handleCallback}
              >{data.button}</Button>
            )}
        </Controls>}>
          <Content> {data &&
            (data.content || data.htmlContent) && (
              <div
                dangerouslySetInnerHTML={{
                  __html: data.translate === false ? (data.content || data.htmlContent) : t(data.content || data.htmlContent),
                }}
              ></div>
            )}
          </Content>
        </ContentBox>
      )}
    </Translation></div>

  }
}

class NotificationModal extends React.PureComponent {


  handleRequestClose = () => {
    const { data, onRequestClose } = this.props;

    if (data.cancelCallback) {
      data.cancelCallback()
    }

    onRequestClose();
  };


  handleCallback = () => {
    const { data, onRequestClose } = this.props;

    if (data.callback) {
      data
        .callback()
        .then(() => onRequestClose())
        .catch(() => onRequestClose());
    } else {
      onRequestClose();
    }
  };

  render() {
    const { handleCallback, handleRequestClose } = this;

    return (
      <Modal {...this.props} onRequestClose={handleRequestClose} >
        <Inner {...this.props} handleCallback={handleCallback} onRequestClose={handleRequestClose} />
      </Modal>
    );
  }
}

export default NotificationModal;
