import styled from 'styled-components';
import React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { putCouponRequestAction } from 'rdx/modules/api';
import TextField from 'common/MaterialUI/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from 'common/MaterialUI/Button';

/* const Circle = styled.div`
  position: absolute;
  position: absolute;
  right: 20px;
  top: 17px;
  background: url(/static/assets/images/loading.gif) no-repeat center;
  background-size: contain;
  width: 25px;
  height: 25px;
`; */

const Layout = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
`;


class ApplyPromo extends React.PureComponent {
  state = {
    inputError: null,
  };

  handleClick = event => {
    event.stopPropagation();
  };

  handleKeyDown = event => {
    if (event.keyCode == 13) {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const { addCoupon } = this.props;
    const { inputElem } = this;

    if (inputElem.value) {
      addCoupon(inputElem.value)
    } else {
      this.setState({ inputError: 'Please enter a code first.' }, () => {
        if (inputElem) {
          inputElem.focus();
        }
      });
    }
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.error && !prevProps.error) {
      this.inputElem.focus();
    }
  }

  render() {
    const { handleClick, handleKeyDown, handleSubmit } = this;
    const { inputError } = this.state;
    const { error, sending } = this.props;

    return (
      <Translation>
        {t => (
          <Layout onClick={handleClick}>
            <TextField disabled={sending} fullWidth placeholder={t('apply_a_promocode')} large inputRef={n => (this.inputElem = n)} onChange={() => this.setState({ error: null })} onKeyDown={handleKeyDown} error={inputError || error ? 'Sorry, this is not a valid code' : false} InputProps={{
              endAdornment: <InputAdornment position="start"><Button  disabled={sending} onClick={handleSubmit} outline small nopadding>+</Button></InputAdornment>
            }} />
          </Layout>
        )}
      </Translation>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCoupon: code =>
      dispatch(putCouponRequestAction({ coupon: code }))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    error: state.creator.cartError,
    loading: state.creator.updatingCart,
    sending: state.rest.putCoupon.sending
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplyPromo);
