import React from 'react';
import styled from 'styled-components';
import { Translation, Trans } from 'react-i18next';
import { RegionNote } from './RegionNote';
import { PrivateNote } from './PrivateNote';
import numeral from 'i18n/numeral';
import ExpandableProductBox from '../../common/Products/ExpandableProductBox';
import { media } from '../../styles';

const Layout = styled.div`
  background-color: rgba(234, 237, 243, 0.25);
  padding: 25px 40px;

  ${media.tabletMax`
  padding: 25px 20px;
  `}

  h2 {
    color: #5bcb8f;
    text-transform: uppercase;
    margin-bottom: 14px;
    font-size: 20px;
  }
`;

const Desc = styled.p`
  padding-bottom: 10px !important;
`




const YourPrizes = props => {
  const { claim } = props;

  const products =
    claim && claim.prizes
      ? Object.values(
        claim.prizes.reduce(
          (prev, prz) => ({ ...prev, [prz.product.PRODUCTID]: prz.product }),
          {},
        ),
      )
      : [];

  let counts = {};
  if (claim && claim.prizes) {
    claim.prizes.forEach(prz => {
      counts[prz.product.PRODUCTID] = (counts[prz.product.PRODUCTID] || 0) + 1;
    });
  }

  const rank = claim && claim.prizes && claim.prizes.reduce((acc, curr) => (Math.min(curr.rank, acc)), 99999)
  const formattedRank = numeral(rank).format('0o');

  return (
    <Translation>
      {t => (
        <Layout>
          <Desc><Trans i18nKey="ClaimPrizes/YourPrizes.desc" values={{ rank: formattedRank }}>You&apos;ve made <b>1st</b> place and will receive the following prize(s):</Trans></Desc>
          {claim &&
            claim.prizes &&
            products.map(product => (
              <ExpandableProductBox
                host_info={claim && claim.host_info}
                key={product.PRODUCTID}
                {...{ product, amount: counts[product.PRODUCTID] }}
              />
            ))}
          <Desc />
          <RegionNote prizes={claim.prizes} />
          <PrivateNote prizes={claim.prizes} />
        </Layout>
      )}
    </Translation>
  );
};

export default YourPrizes;
