import Cookies from 'js-cookie';
import { Base64 } from 'js-base64';

const parseToken = token => {
  try {
    return JSON.parse(Base64.decode(token.split('.')[1]));
  } catch (error) {
    //console.log(error)
  }
  return false;
}


export const JWT = {

  setAccessToken: token => {
    const { exp } = parseToken(token)
    Cookies.set("Authorization", token, { expires: new Date(exp * 1000), path: '/' });
  },
  setRefreshToken: token => {
    const { exp } = parseToken(token)
    Cookies.set("Refresh", token, { expires: new Date(exp * 1000), path: '/' });
  },
  getAccessToken: () => Cookies.getJSON("Authorization"),
  getRefreshToken: () => Cookies.getJSON("Refresh"),
  removeAccessToken: () => Cookies.remove("Authorization"),
  removeRefreshToken: () => Cookies.remove("Refresh"),
};