import styled from 'styled-components';

const Footer = styled.div`
  height: 44px;
  max-width: 300px;
	max-height: 44px;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	border-top: solid 2px #f0f0f0;
	background-color: rgba(237, 239, 245, 0.25);
	padding: 0 20px;
  
  
  display: flex;
	justify-content: space-between;
  align-items: center;
  
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;


export default Footer;
