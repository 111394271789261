import { css } from 'styled-components';
import { blue, red, green, grey, white, black } from './colors';

const H4ExtraBold = css`
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
`;
export const H4ExtraBoldBlue = css`
  ${H4ExtraBold}
  color: ${blue};
`;

export const H4ExtraBoldRed = css`
  ${H4ExtraBold}
  color: ${red};
`;

export const H4ExtraBoldGreen = css`
  ${H4ExtraBold}
  color: ${green};
`;

export const H4ExtraBoldGrey = css`
  ${H4ExtraBold}
  color: ${grey};
`;

export const H4ExtraBoldWhite = css`
  ${H4ExtraBold}
  color: ${white};
`;

export const H4ExtraBoldBlack = css`
  ${H4ExtraBold}
  color: ${black};
`;
