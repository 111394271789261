import { createLogic } from 'redux-logic';
import { RESTAPI } from 'rdx/modules/api/rest';
import { CATEGORY_LIST_REQUEST , productCategoryListResponseAction } from 'rdx/modules/productLists/index';



const productCategoryListRequestLogic = createLogic({
  type: [CATEGORY_LIST_REQUEST],
  debounce: 500,
  async process({ getState, cancelled$, action }, dispatch, done) {
    try {
      const state = getState();
      const listName = action.payload[0];

      if (listName) {

        const list = state.productLists[listName]

        const { category, limit, orderby, order, nextPage, totalPages, region } = list;

        if (nextPage <= totalPages) {



          const source = RESTAPI.getSource();
          cancelled$.subscribe(() => {
            source.cancel('Request cancelled');
          });


          const resp = await RESTAPI.getProducts({
            orderby,
            order,
            categories: category,
            page: nextPage,
            limit,
            region: region ? region : undefined
          },
            source.token,
          );
          dispatch(productCategoryListResponseAction([resp, listName]));
        }
      }
    } catch (error) {
      console.log(error);
    }

    done();
  },
});

export default productCategoryListRequestLogic;
