import RafflesCreate from 'components/Raffles/Creator/module';
import { ROUTE_TYPE_CREATE, ROUTE_TYPE_GIVEAWAY_EDIT } from '../helpers/const';
import SubmenuCreator from '../components/Raffles/Creator/Submenu';
import i18n from 'i18n';
import { getFormNames, isDirty, isSubmitting, hasSubmitSucceeded } from 'redux-form'

export const confirmLeaveOnDirtyForms = (state, action) => {

  let isAnyDirty = false;

  const formNames = getFormNames()(state);
  formNames.forEach(name => {

    if (name == "private-products" || name.indexOf("creator-") == 0) {
      const dirty = isDirty(name)(state);
      const submitting = isSubmitting(name)(state);
      const submitSucceeded = hasSubmitSucceeded(name)(state)
      const sendingForm = state.ui.sendingForm

      if (dirty && !submitting && !sendingForm && !submitSucceeded) { isAnyDirty = true };
    }

  });

  if (isAnyDirty) {
    return i18n.t('creator.confirm_unsaved')
  }
}


export const CreatorRoutes = {
  [ROUTE_TYPE_CREATE]: {
    path: '/create/:mode/:PRODUCTID*',
    title: 'Create a Giveaway',
    page: RafflesCreate,
    submenu: SubmenuCreator,
    confirmLeave: confirmLeaveOnDirtyForms

  },
  [ROUTE_TYPE_GIVEAWAY_EDIT]: {
    path: '/giveaways/:EVENTID/edit/:mode*',
    title: 'Edit Giveaway',
    page: RafflesCreate,
    submenu: SubmenuCreator,
    confirmLeave: confirmLeaveOnDirtyForms
  }
};

export default CreatorRoutes;



