import { animateScroll } from 'react-scroll';

export const scrollToQuery = (query, callback = null, offset = 0) => {
  if (typeof window != 'undefined') {

    var node = document.querySelector(query);

    if (node) {
      scrollToDOMNode(node, callback, offset)
    }
  }
};

export const scrollToDOMNode = (node, callback = null, offset = -300) => {
  if (typeof window != 'undefined') {
    var rect = node.getBoundingClientRect();
    animateScroll.scrollTo(rect.top + window.scrollY + offset, {
      duration: 600,
      delay: 50,
      smooth: 'easeInOutQuint',
    });

    if (callback) {
      setTimeout(() => callback(), 650);
    }
  }
};

export const scrollToTop = (callback = null) => {
  if (typeof window != 'undefined') {
    animateScroll.scrollToTop({
      duration: 600,
      delay: 50,
      smooth: 'easeInOutQuint',
    });

    if (callback && typeof callback == 'function') {
      setTimeout(() => callback(), 650);
    }
  }
};
