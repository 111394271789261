import React from 'react';
import Tooltip from 'common/MaterialUI/Tooltip';
import Link from 'redux-first-router-link';
import Button from 'common/MaterialUI/Button';
import styled from 'styled-components';

const Icon = styled.span`
font-size: 14px;

`

const ActionButtonWithToolTip = ({ icon, onClick, content, to, disabled, active }) => {

  return (
    <Tooltip placement="top" title={<span>{content}</span>}>
      <Button nopadding outline={!active} primary={active} small disabled={disabled} component={to && !disabled ? Link : undefined} to={to} onClick={onClick}>{icon && <Icon className={icon} />}</Button>
    </Tooltip>
  );

}

export default ActionButtonWithToolTip;
