import { createLogic } from 'redux-logic';
import { GET_RECENTWINNERS_REQUEST, getRecentWinnersResponseAction, getRecentWinnersErrorAction } from '../modules/stats';
import { getProductsByIdResponseAction, getRafflelistFilterOrderbyResponseAction } from '../modules/api';
import { utils } from '../../helpers/utils';
import { RESTAPI } from '../modules/api/rest';

const getProductsFromPrizes = (eventid, rank, prizes) => {
  const entry = prizes.find(p => p.eventid == eventid);
  if (entry) {
    const result = entry.prizes.find(
      e => e.first_rank <= rank && e.last_rank >= rank,
    );
    if (result) {
      return result.products;
    }
  }
};

const getRecentWinnersRequestLogic = createLogic({
  type: GET_RECENTWINNERS_REQUEST,
  latest: true,
  async process({ getState, action, cancelled$ }, dispatch, done) {
    try {

      const source = RESTAPI.getSource();
      cancelled$.subscribe(() => {
        source.cancel('Request cancelled');
      });
      const resp0 = await RESTAPI.getRafflelistFilterOrderby({ filterPath: 'finished', orderbyPath: 'justfinished', filter: `OWNERID eq ${action.payload.toString()}` }, source.token)

      dispatch(getRafflelistFilterOrderbyResponseAction(resp0))

      const ids = resp0.raffles.map(r => r.EVENTID);

      let finalized = null;

      if (ids.length > 0) {


        const resp1 = await RESTAPI.getWinnersByRaffleId({ id: ids }, source.token)
        const resp2 = await RESTAPI.getPrizesByRaffleId({ id: ids }, source.token)

        let allProducts = [];
        finalized = resp1.eventwinners
          .filter(ew => ew.winners && ew.winners.filter(w => w.winnerid).length > 0)
          .map(ew => {
            return {
              ...ew,
              winners: ew.winners.map(w => {
                const products = getProductsFromPrizes(
                  ew.eventid,
                  w.rank,
                  resp2.eventprizes,
                );



                allProducts = [...allProducts, ...products]

                return {
                  ...w,
                  products,
                };
              }),
            };
          });

        if (allProducts.length) {
          await RESTAPI.getProductsById({ id: utils.unique(allProducts.map(p => p.PRODUCTID)) }, source.token).then(response => dispatch(getProductsByIdResponseAction(response)))
        }




      }

      dispatch(getRecentWinnersResponseAction([finalized, action.payload]));

    } catch (error) {
      dispatch(getRecentWinnersErrorAction(error));
    }
    done();
  },
});

export default getRecentWinnersRequestLogic;
