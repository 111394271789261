import View from './View';
import { connect } from 'react-redux';
import { MASONRYITEM_TYPE_CONTEST, ROUTE_PREFIX } from '../../helpers/const';
import { createSelector } from 'reselect';
import { runtimeConfig } from '../../runtimeConfig';

const { campaigns } = runtimeConfig.CAMPAIGNS


const calendarList = state => {
  const campaign = Object.values(campaigns).filter(c => c.path).find(c => `${ROUTE_PREFIX}${c.path}` == state.location.type)
  if (campaign.redirectonly == true) { window.location.assign(campaign.link) }

  return campaign && state.giveawayLists[campaign.path]
};

const mapStateToProps = (state, ownProps) =>

  createSelector(
    [calendarList],
    (calendarList) => {
      const loading = calendarList ? calendarList.loading : true
      const giveaways = calendarList && calendarList.data ? calendarList.data : undefined;
      const raffles = giveaways ? giveaways.map(raffle => ({
        type: MASONRYITEM_TYPE_CONTEST,
        data: raffle,
        id: `r${raffle.EVENTID}`
      })) : undefined
      return { loading, raffles }
    })



export default connect(mapStateToProps)(View)