import { createLogic } from 'redux-logic';
import {
  CATEGORY_SETTINGS,
  raffleCategoryListRequestAction,
} from 'rdx/modules/giveawayLists';

const getCategorySettingsLogic = createLogic({
  type: CATEGORY_SETTINGS,

  process({ getState, action }, dispatch, done) {
    try {
      dispatch(raffleCategoryListRequestAction());
    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default getCategorySettingsLogic;
