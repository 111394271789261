import React from 'react';
import styled from 'styled-components';

import { Translation } from 'react-i18next';


const Layout = styled.div`

  
height: 24px;
border-radius: 12px;
background-color: #ffffff;
border: solid 1px #e1e2ef;

padding: 5px 10px 3px;
display: flex;
align-items: center;
white-space: nowrap;
`


const Quantity = ({ quantity, ...other }) => {

  return <Translation>{t => <Layout>{`${t("products_qty")} ${quantity || 0}`}</Layout>
  }</Translation>
}

export default Quantity