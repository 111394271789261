import React from 'react';
import { DrawingController } from './DrawingController';
import { ThisContestIsOver } from './ThisContestIsOver';
import { AndTheWinnersAre } from './AndTheWinnersAre';
import { Banner } from './Banner';
import { Place } from './Place';
import { utils } from '../../../../helpers/utils';
import { ASSET_VARIANT_ORIGINAL } from '../../../../helpers/const';


class Drawing extends React.PureComponent {
  componentDidMount() {
    this.preloadedBanner = new Image();
    this.preloadedBanner.src = utils.resolveImageAsset(this.props.raffle.eventpic, ASSET_VARIANT_ORIGINAL);
    this.preloadedCup = new Image();
    this.preloadedBanner.src = '/static/assets/images/Slide-Cup.png';
  }

  render() {
    const { winners, raffle } = this.props;

    const reverseWinners = winners ? winners.filter(w => w.rank <= 3) : null;

    if (reverseWinners) {
      reverseWinners.reverse();
    }

    return (
      <DrawingController>
        <ThisContestIsOver />
        <AndTheWinnersAre />

        {reverseWinners &&
          reverseWinners
            .filter(w => w.rank <= 3)
            .map(w => <Place key={w.rank} name={w.winnername} rank={w.rank} />)}
        <Banner source={utils.resolveImageAsset(raffle.eventpic, ASSET_VARIANT_ORIGINAL)} />
      </DrawingController>
    );
  }
}

export default Drawing;
