import React from 'react';
import { Translation, Trans } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import language from '../../../store/selectors/language';

import styled from 'styled-components'
import Button from 'common/MaterialUI/Button';
import classNames from 'classnames';
import { MODAL_CONTACT_US, modalAction } from '../../../store/modules/modals';
import { media } from '../../../styles';
import Link from 'redux-first-router-link';

const ButtonHolder = styled.div`
display: flex;
justify-content: ${({ justify }) => justify ? justify : 'center'};

> *:first-child {
  margin-right: 10px;
}

`

const Layout = styled.div`

overflow: hidden;
transition: max-height 0.50s ease-in-out;


max-height: 550px;

${media.tablet`
max-height: 505px;
`}
${media.desktop`
max-height: 350px;
`}

${media.widescreen`
max-height: 255px;
`}

&.expanded{
  max-height: 5000px;
}


`

class LaunchUnlimited extends React.PureComponent {

  state = {
    expanded: false
  }

  render() {

    const { expanded } = this.state;
    const { language } = this.props;

    return <Translation>{t => (<React.Fragment>
      <Layout className={classNames("ccc-service-box ccc-contentbox", { expanded })}>
        <div className="ccc-service-box-header">
          <div className="ccc-service-box-header-image"><img src={`/static/assets/images/quicktour/Quicktour-04-Business-Section5-02-${language}.png`} width="390" alt=" " /></div>
          <div className="ccc-service-box-header-text">
            <h4 className="ccc-h4">{t("QuickTour/Businesses/LaunchUnlimited.launchunlimited")}</h4>
            <p className="ccc-p"><Trans i18nKey="QuickTour/Businesses/LaunchUnlimited.createasmany">Create as many giveaways as you want and get unlimited access to all entry methods and features. There are no hidden payment barriers and no subscription needed. Maximize the reach of your giveaways with <span style={{ fontWeight: 800 }}>platform-wide banner campaigns</span> and <span style={{ fontWeight: 800 }}>social media promotion</span>.</Trans></p>
            <ButtonHolder justify={"flex-start"}><Button onClick={() => this.setState({ expanded: !expanded })} primary={!expanded} outline={expanded}><span>{!expanded ? t("QuickTour/Businesses/FullService.learnmore") : t("QuickTour/Businesses/FullService.minimize")}</span>&nbsp;<span>{expanded ? "-" : "+"}</span></Button></ButtonHolder>
          </div>
        </div>
        <div className="ccc-service-box-content">
          <h4 className="ccc-h4">{t("QuickTour/Businesses/LaunchUnlimited.reachentire")}</h4>
          <div className="ccc-service-box-content-3col">
            <div>
              <img src="/static/assets/images/quicktour/Quicktour-04-Business-Section5-07.png" width="300" alt=" " />
              <h5 className="ccc-h5">{t("QuickTour/Businesses/LaunchUnlimited.bannerads")}</h5>
              <p className="ccc-p">{t("QuickTour/Businesses/LaunchUnlimited.weoffer")}</p>
            </div>
            <div>
              <img src="/static/assets/images/quicktour/Quicktour-04-Business-Section5-08.png" width="300" alt=" " />
              <h5 className="ccc-h5">{t("QuickTour/Businesses/LaunchUnlimited.social")}</h5>
              <p className="ccc-p">{t("QuickTour/Businesses/LaunchUnlimited.usethefullreach")}</p>
            </div>
            <div>
              <img src="/static/assets/images/quicktour/Quicktour-04-Business-Section5-09.png" width="300" alt=" " />
              <h5 className="ccc-h5">{t("QuickTour/Businesses/LaunchUnlimited.individuallanding")}</h5>
              <p className="ccc-p">{t("QuickTour/Businesses/LaunchUnlimited.forspecial")}</p>
            </div>
          </div>






          <ButtonHolder><Button primary component={Link} to="/contact">{t("QuickTour/Businesses/FullService.sendinquiry")}</Button></ButtonHolder>

        </div>
      </Layout>
    </React.Fragment>)}</Translation>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: language(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleContact: () => {
      dispatch(modalAction({ modal: MODAL_CONTACT_US }));
    },
  }
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(LaunchUnlimited)



