import React from 'react';
import { connect } from 'react-redux';
import View from './View';
import { raffleCategoryListRequestAction } from '../../../../store/modules/giveawayLists/index';
import {
  MASONRYITEM_TYPE_CONTEST,
} from 'helpers/const';

const mapStateToProps = (state) => {


  const { data, nextPage, totalPages, loading } = state.giveawayLists.popular;

  return {
    raffles: data
      ? data.map(raffle => ({
        type: MASONRYITEM_TYPE_CONTEST,
        data: raffle,
        id: `r${raffle.EVENTID}`
      }))
      : [],
    scrollComplete: nextPage > totalPages,
    loading: loading
  }




}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {

    handleScrolledToBottom: settings => {
      dispatch(raffleCategoryListRequestAction(["popular"], {}, true));
    },

  };
}

class StateContainer extends React.PureComponent {

  componentDidMount() {
    const { handleScrolledToBottom } = this.props;
    handleScrolledToBottom();
  }

  render() {
    return <View {...this.props} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateContainer)
