import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from 'common/MaterialUI/Tab';
import styled from 'styled-components';
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Verification from './Verification';


import { BoxMarginMixin } from '../../mixins';

const Layout = styled.div`
  
`

const Content = styled.div`
  ${BoxMarginMixin}

  border-top: solid 1px #e1e2ef;

`

const EmailFlow = ({ showOverview, view, onRequestClose, visibleCaptcha }) => {

  const [tab, setTab] = useState(view);


  return <Translation>{t => (<Layout>{['register', 'login'].indexOf(tab) != -1 && <Tabs variant="fullWidth" onChange={(event, newValue) => setTab(newValue)} value={tab}
    indicatorColor="secondary"
    textColor="primary"
  >
    <Tab label={t("Modals/ConnectModal/EmailFlow/index.login")} value={'login'} />
    <Tab label={t("Modals/ConnectModal/EmailFlow/index.register")} value={'register'} />
  </Tabs>}
    <Content>
      {tab == 'login' && <Login visibleCaptcha={visibleCaptcha} showOverview={showOverview} showForgotPassword={() => setTab('forgot')} />}
      {tab == 'register' && <Register visibleCaptcha={visibleCaptcha} showOverview={showOverview} />}
      {tab == 'forgot' && <ForgotPassword showOverview={showOverview} />}
      {tab == 'reset' && <ResetPassword showOverview={showOverview} />}
      {tab == 'verification' && <Verification showOverview={showOverview} onRequestClose={onRequestClose} />}

    </Content>
  </Layout>)}</Translation>;
}


export default EmailFlow