import { connect } from 'react-redux';
import View from './View';
import { EVENTTYPEID_PRIVATE } from '../../../../../helpers/const';
import { modalAction , MODAL_REPORT_GIVEAWAY } from '../../../../../store/modules/modals';


const mapStateToProps = (state, ownProps) => {
  return {
    isPrivate: ownProps.raffle.EVENTTYPEID == EVENTTYPEID_PRIVATE
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showReportModal: () => {
      dispatch(modalAction({ modal: MODAL_REPORT_GIVEAWAY, data: ownProps }))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(View);
