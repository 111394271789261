import { connect } from 'react-redux';
import View from './View';

const mapStateToProps = (state, ownProps) => {
  return {
    winners: state.raffles.winners[ownProps.raffle.EVENTID],
  };
};

export default connect(mapStateToProps)(View);
