import React from 'react';
import styled from 'styled-components';
import numeral from 'i18n/numeral';
import { Translation } from 'react-i18next';
import ExpandableProductBox from '../../../../../common/Products/ExpandableProductBox/index';
import { H6ExtraBoldBlack } from 'styles/Kit/H6ExtraBold';

const Layout = styled.div`
margin: 6px 0 5px;
& + & {
  margin: 30px 0 5px;
}
`;

const RankLayout = styled.div`
    ${H6ExtraBoldBlack};
    width: 100%;
    text-align: center;
    border-top: solid 1px #e1e2ef;
    
    
    span {
      transform: translateY(-8px);
      display: inline-block;
      background: #f8f9fb;
      padding: 0 10px;
    }


`;


const sortMethod = (a, b) => (a.value != b.value ? parseFloat(b.value) - parseFloat(a.value) : a.name.localeCompare(b.name))

const RankDisplay = ({ t, first, last }) => (
  <RankLayout>
    <span>{numeral(first).format('0o')}
      {first != last && `${'\u00A0'}-${'\u00A0'}${numeral(last).format('0o')}`}
      &nbsp;{t("Raffles/Detail/MainBox/PrizePool/PrizeItem.place")}</span>
  </RankLayout>
)

const PrizeItem = ({ prize, host_info, preview }) => {
  const { first_rank, last_rank, products } = prize;

  if (products) {
    products.sort(sortMethod)
  }

  return (
    <Layout><Translation>{t => (<React.Fragment>
      <RankDisplay t={t} first={first_rank} last={last_rank} />
      {products && products.map(p => (
        <ExpandableProductBox
          preview={preview}
          key={p.PRODUCTID}
          product={p}
          host_info={host_info}
          amount={p.amount}
        />
      ))}
    </React.Fragment>
    )}</Translation></Layout>
  );
};


export default PrizeItem;
