import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { Translation } from 'react-i18next';
import { H4ExtraBoldBlack } from 'styles/Kit/H4ExtraBold';
import { H5ExtraBoldBlack } from 'styles/Kit/H5ExtraBold';



const Layout = styled.div`
background: url(/static/assets/images/creator/Giveaway-Drawing-Banner.png) no-repeat;
background-size: cover;
background-position: center top;
padding: 20px;
min-height: 85px;

display: flex;
justify-content: center;
align-items: center;
word-break: break-word;

${H5ExtraBoldBlack};
  ${media.tablet`
  ${H4ExtraBoldBlack};
  `};
`;

const DrawingInProgress = ({ won_prizes, prize_count }) => (
  <Translation>
    {t => (
      <Layout>
        {t("Raffles/Detail/ClaimBox/DrawingInProgress.status", { count: won_prizes, total: prize_count })}
      </Layout>
    )}
  </Translation>
);

export default DrawingInProgress