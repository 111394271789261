import { createLogic } from 'redux-logic';
import { AUTH_ROLE_CHANGE_SUCCESS } from '../modules/auth';
import { ROUTE_TYPE_GIVEAWAY } from '../../helpers/const';
import { push } from 'redux-first-router';
import isStreamer from '../selectors/isStreamer';

const authRoleChangeResponseLogic = createLogic({
  type: [AUTH_ROLE_CHANGE_SUCCESS],

  process({ getState, action }, dispatch, done) {
    try {

      const state = getState();
      const routeType = state.location.type;


      if (routeType != ROUTE_TYPE_GIVEAWAY) {
        push(`/dashboard/${isStreamer(state) ? 'streamer' : 'viewer'}`);
      } else {
        if (typeof window != 'undefined') {
          window.location.reload();
        }
      }

    } catch (error) {
      console.log(error)
    }
    done();
  },
});

export default authRoleChangeResponseLogic;