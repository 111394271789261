import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';

import { Translation, Trans } from 'react-i18next';
import Notification from 'common/Notification';


const Controls = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;

  > * {
    flex: 0 1 auto;
  }
`;

const Content = styled.div`

  h4 {
    font-size: 12px !important;
    font-weight: 800;
    color: #9c9c9c;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px !important;
    margin-bottom: 10px;
  }

  .green {
    color: #5bcb8f;
    font-weight: bold;
  }

  ul {
    list-style-type: disc;

    li {
      margin: 10px 15px;
    }
  }
`;

const Description = styled.p`
  a {
    font-weight: bold;
  }
`
class AccountDeletionModal extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = { response: false, streamer: false };
  }

  handleClose = () => {
    const { handleConfirm, onRequestClose } = this.props;
    if (handleConfirm) {
      handleConfirm(this.state.confirmed)
    }
    onRequestClose()
  }

  state = {
    confirmed: false
  }

  render() {

    const { ...other } = this.props;
    const { handleUserDelete } = this.props;
    const { handleClose } = this;

    return (
      <Modal {...other} onRequestClose={handleClose}>
        <Translation>
          {t => (
            <ContentBox bigmodal title={t("Modals/AccountDeletionModal.title")} maxHeight={"75vh"} footer={() => <Controls>

              <Button outline small
                onClick={handleClose}
              >{t("Modals/AccountDeletionModal.decline")}</Button>

              &nbsp;&nbsp;
              <Button primary small 
                //onClick={() => { this.setState({ confirmed: true }, () => handleClose()) }}
                onClick={() => handleUserDelete()}
              >{t("Modals/AccountDeletionModal.confirm")}</Button>
            </Controls>}>
              <Content>
                <Trans i18nKey="Modals/AccountDeletionModal.info"><Description>It is unfortunate to hear that you want to leave us. If your account is deleted, your data will be <b>permanently and irrevocably</b> deleted. Please also note that the deletion can take several days. Here are the most important data to be deleted:</Description></Trans>
                  <Notification warning icon="icon-Ico16-Tooltip-Attention">{t("Modals/AccountDeletionModal.giveawayrelated")}</Notification>
                  <Notification warning icon="icon-Ico16-Tooltip-Attention">{t("Modals/AccountDeletionModal.loyaltycoins")}</Notification>
                  <Notification warning icon="icon-Ico16-Tooltip-Attention">{t("Modals/AccountDeletionModal.uploads")}</Notification>
                  <Notification warning icon="icon-Ico16-Tooltip-Attention">{t("Modals/AccountDeletionModal.personal")}</Notification>
              </Content>
            </ContentBox>
          )}
        </Translation>
      </Modal>
    );
  }
}


export default AccountDeletionModal;
