import { createLogic } from 'redux-logic';
import { PUT_RAFFLES_RESPONSE } from 'rdx/modules/api';
import fetchPrivateProducts from './_fetchPrivateProducts';

const putRafflesResponseLogic = createLogic({
  type: PUT_RAFFLES_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      fetchPrivateProducts(dispatch, getState)
    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default putRafflesResponseLogic;
