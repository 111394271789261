import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';
import { withForm } from 'hoc/withForm';
import { compose } from 'redux';
import InputField from 'common/InputField';
import { media } from 'styles';
import { Translation } from 'react-i18next';
import MenuItem from 'common/MaterialUI/MenuItem';
import { postRaffleByIdReportRequestAction } from '../../store/modules/api';

const Content = styled.div`
  > p {
    margin-bottom: 20px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 10px;
  }

  > * + * {
    margin-top: 10px;
  }

  ${media.tablet`
    flex-direction: row;
    > * + * {
      margin-top: 0;
      margin-left: 20px;
    }
  `};
`;

class ReportGiveawayModal extends React.PureComponent {
  handleClick = e => {
    e.stopPropagation();
  };

  render() {
    const { handleSubmit } = this.props;
    const { handleClick } = this;
    return (
      <Modal {...this.props}>
        <Translation>
          {t => (
            <ContentBox
              bigmodal
              title={t("Modals/ReportGiveawayModal.title")}
              onClick={handleClick}
            >
              <form onSubmit={handleSubmit}>
                <Content>


                  <Fields>
                    <InputField select name="category" title={t("Modals/ReportGiveawayModal.labels.reason")}>
                      <MenuItem value={"Modals/ReportGiveawayModal.reason.copyright"}>{t("Modals/ReportGiveawayModal.reason.copyright")}</MenuItem>
                      <MenuItem value={"Modals/ReportGiveawayModal.reason.racist"}>{t("Modals/ReportGiveawayModal.reason.racist")}</MenuItem>
                      <MenuItem value={"Modals/ReportGiveawayModal.reason.fraud"}>{t("Modals/ReportGiveawayModal.reason.fraud")}</MenuItem>
                      <MenuItem value={"Modals/ReportGiveawayModal.reason.other"}>{t("Modals/ReportGiveawayModal.reason.other")}</MenuItem>
                    </InputField>
                  </Fields>
                  <Fields>

                  </Fields>
                  <Fields>
                    <InputField multiline
                      rows={4}
                      rowsMax={10}
                      name="message"
                      title={t("Modals/ReportGiveawayModal.remarks")}
                    />
                  </Fields>

                </Content>
                <Controls>
                  <Button primary type="submit">{t("Modals/ReportGiveawayModal.send")}</Button>
                </Controls>
              </form>
            </ContentBox>
          )}
        </Translation>
      </Modal>
    );
  }
}

const validate = values => {
  const errors = {};



  if (!values.message) {
    errors.message = 'forms.validation_message';
  }

  if (!values.category || (values.category == "Modals/ReportGiveawayModal.reason.other" && !values.message)) {
    errors.category = 'forms.validation_message';
  }


  return errors;
};

const onSubmit = (values, dispatch) => {
  let sendValues = { ...values };

  dispatch((dispatch, getState) => {

    const state = getState();
    dispatch(postRaffleByIdReportRequestAction({ id: state.location.payload.EVENTID, payload: sendValues }))

  })

};

export const onSubmitFail = (errors, dispatch, submitError, form) => {
  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input.error, textarea.error, select.error',
    );
    if (firstErrorNode) {
      firstErrorNode.focus();
    }
  }, 50);
};

const initialValues = {
  category: "Modals/ReportGiveawayModal.reason.copyright",
  message: null,
  captcha_response: null,
};

const enhance = compose(
  withForm({
    form: 'reportgiveaway',
    captcha: "reportgiveaway",
    validate,
    onSubmit,
    initialValues,
    onSubmitFail,
    destroyOnUnmount: true,
  }),
);

export default enhance(ReportGiveawayModal);
