import React from 'react';
import { ContentBox } from 'common/ContentBox';
import styled from 'styled-components';
import CollapsingTable from 'common/CollapsingTable';
import Link from 'redux-first-router-link';
import { Translation } from 'react-i18next';
import { RAFFLE_STATE_FINISHED } from 'helpers/const';
import Status from '../columns/Status';
import { Title } from '../columns/Title';
import { Numeric } from '../columns/Numeric';
import Button from 'common/MaterialUI/Button';
import Checkbox from 'common/MaterialUI/Checkbox';
import { Pagination } from '../../../common/Pagination';
import { media } from '../../../styles';
import EmptyBox from '../../../common/EmptyBox';
import { Empty } from './Empty';
import { InfoButton } from 'common/InfoButton';

const Layout = styled.div`
  table {
    ${media.desktop`
    
    th,
    td{
      width: 20%;
    }


    th:nth-last-child(2),
    td:nth-last-child(2){
      width: 7%;
    }
    
    
    th:first-child,
    td:first-child {
      width: 50%;
    }
    `};
  }
  width: 100%;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TabCheckBox = styled(Checkbox)`
  > label > div > span {
    text-transform: none;
    font-size: 11px;
    font-weight: 300;

    color: #1d1d1d;
    ${media.tablet`
    font-size: 14px;
    `};
  }
`;


const Boxes = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 10px;
  }
`;

const ButtonArea = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: space-between;

  ${media.tabletMax`
    flex-direction: column-reverse;
    justify-content: center;

    > * + * {
      margin-bottom: 20px;
    }
  `};

  a {
    display: flex;
    justify-content: center;
  }
`;

export class View extends React.PureComponent {
  state = {
    disableCurrent: false,
    disableFinished: false,
    showCooldownOnly: false,
    showCoinsOnly: false,
  };

  componentDidMount() {
    const { load } = this.props;
    const { limit } = this.props;
    load(1, limit)
  }

  componentWillUnmount() {
    if (this.cancelLoad) {
      this.cancelLoad();
    }
  }

  handleLoad = page => {
    const { load, limit } = this.props;
    const { showCooldownOnly, showCoinsOnly } = this.state;

    const hasCooldown = showCooldownOnly ? true : false;
    const hasCoins = showCoinsOnly ? true : false;
 
    load(parseInt(page), limit, hasCooldown, hasCoins)
  };

  handleCooldownCheckBoxChange = () => {
    const { showCooldownOnly } = this.state;

    this.setState({ showCooldownOnly: !showCooldownOnly }, () => {
      this.handleLoad(1);
    });
  };

  handleCoinsCheckBoxChange = () => {
    const { showCoinsOnly } = this.state;

    this.setState({ showCoinsOnly: !showCoinsOnly }, () => {
      this.handleLoad(1);
    });
  };

  renderCheckboxes = t => {
    const { showCooldownOnly, showCoinsOnly } = this.state;
    const { disableCooldownOnly, disableCoinsOnly } = this.props;

    return <React.Fragment>

            {disableCooldownOnly ? null : (
      <Boxes>
        {t('dashboard_entered_giveways_show_cooldown_only')} <InfoButton title={t('dashboard_entered_giveaways_show_cooldown_only_info')} /> <TabCheckBox
          checked={showCooldownOnly}
          onChange={this.handleCooldownCheckBoxChange} />
      </Boxes>
    )}

            {disableCoinsOnly ? null : (
      <Boxes>
        {t('dashboard_entered_giveways_show_coins_only')} <InfoButton title={t('dashboard_entered_giveaways_show_coins_only_info')} /> <TabCheckBox
          checked={showCoinsOnly}
          onChange={this.handleCoinsCheckBoxChange} />
      </Boxes>
    )}

        </React.Fragment>

  };

  render() {
    const { data, total_pages, current_page, loading, loaded } = this.props;
    const { disableCurrent, disableFinished } = this.state;
    const { handleLoad, renderCheckboxes } = this;

    const columns = [
      'giveaway',
      'ga_title_status',
      'ga_title_competitors',
      'ga_title_entries',
    ];

    const filterCurrent = g =>
      disableCurrent ? g.timer.mode == RAFFLE_STATE_FINISHED : true;
    const filterFinished = g =>
      disableFinished ? g.timer.mode != RAFFLE_STATE_FINISHED : true;

    const filteredRaffles = data.filter(
      g => filterCurrent(g) && filterFinished(g),
    );

    return (
      <Translation>
        {t => (
          <ContentBox
            keepMaxHeight
            vcenter={loading}
            title={t('dashboard_entered_giveaways')}
            right={renderCheckboxes(t)}
          >
            <Layout>
              {loaded && (!filteredRaffles || filteredRaffles.length == 0) ? (
                <Empty />
              ) : loading ? (
                <EmptyBox noBorder loading />
              ) : (
                    <CollapsingTable.Table {...{ columns }}>
                      {filteredRaffles.map(giveaway => (
                        <CollapsingTable.Row key={giveaway.EVENTID}>
                          <CollapsingTable.Cell>
                            <Title {...{ giveaway }} showHost />
                          </CollapsingTable.Cell>
                          <CollapsingTable.Cell>
                            <Status {...{ giveaway }} />
                          </CollapsingTable.Cell>
                          <CollapsingTable.Cell>
                            <Numeric
                              {...{ giveaway }}
                              value={giveaway.participants}
                            />
                          </CollapsingTable.Cell>
                          <CollapsingTable.Cell>
                            <Numeric
                              {...{ giveaway }}
                              value={giveaway.total_entries}
                            />
                          </CollapsingTable.Cell>
                        </CollapsingTable.Row>
                      ))}
                    </CollapsingTable.Table>
                  )}
              {loaded &&
                filteredRaffles &&
                filteredRaffles.length > 0 && (
                  <ButtonArea>
                    <Button to={`/giveaways`} primary component={Link}>{t('browse_giveaways')}</Button>
                    <Pagination
                      total={total_pages}
                      active={current_page}
                      onChange={handleLoad}
                    />
                  </ButtonArea>
                )}
            </Layout>
          </ContentBox>
        )}
      </Translation>
    );
  }
}

export default View;
