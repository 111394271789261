import React from 'react';
import Box from './_Box';
import Explanation from './_Explanation';
import Description from './_Description';
import styled, { css } from 'styled-components';

const StoreButton = styled.div`
width: 153px;
height: 53px;
cursor: pointer;
  ${({ android }) => android && css`
  background-image: url(/static/assets/images/download-google-play.png);
  `}  

  ${({ apple }) => apple && css`
    background-image: url(/static/assets/images/download-app-store.png);
  `}
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

`


const DownloadApp = ({ way, waytype, handleContinue, handlePostEntrywaysEntity, toggle, ...other }) => {

  let meta = {};

  try {
    meta = JSON.parse(way.BENEFICIARY);
  } catch (error) {
    console.log(error)
  }



  return <Box {...{ way, waytype, toggle, ...other }}
    controls={(complete, loading) => <React.Fragment>
      {meta.android_link && <StoreButton disabled={loading} onClick={handleContinue(way, null, toggle(way), meta.android_link)} android />}
      {meta.apple_link && <StoreButton disabled={loading} onClick={handleContinue(way, null, toggle(way), meta.apple_link)} apple />}
    </React.Fragment>}

  ><Description way={way} /><Explanation way={way} waytype={waytype} noLink displayName={meta.app_name} /></Box>
}


export default DownloadApp