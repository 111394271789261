import React from 'react';
import { Translation, Trans } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import language from '../../../store/selectors/language';

import styled from 'styled-components'
import Button from 'common/MaterialUI/Button';
import classNames from 'classnames';
import { MODAL_CONTACT_US, modalAction } from '../../../store/modules/modals';
import { media } from '../../../styles';
import Tooltip from '../../../common/MaterialUI/Tooltip';
import Link from 'redux-first-router-link';

const ButtonHolder = styled.div`
display: flex;
justify-content: ${({ justify }) => justify ? justify : 'center'};

> *:first-child {
  margin-right: 10px;
}

`

const Layout = styled.div`

overflow: hidden;
transition: max-height 0.50s ease-in-out;


max-height: 555px;

${media.tablet`
max-height: 480px;
`}
${media.desktop`
max-height: 325px;
`}

${media.widescreen`
max-height: 255px;
`}

&.expanded{
  max-height: 5000px;
}


`



class FullService extends React.PureComponent {

  state = {
    expanded: false
  }

  render() {

    const { expanded } = this.state;
    const { language } = this.props;

    return <Translation>{t => (<React.Fragment>
      <Layout className={classNames("ccc-service-box ccc-contentbox", { expanded })}>
        <div className="ccc-service-box-header">
          <div className="ccc-service-box-header-image"><img src={`/static/assets/images/quicktour/Quicktour-04-Business-Section5-01-${language}.png`} width="390" alt=" " /></div>
          <div className="ccc-service-box-header-text">
            <h4 className="ccc-h4">{t("QuickTour/Businesses/FullService.fullservicecampaigns")}</h4>
            <p className="ccc-p"><Trans i18nKey="QuickTour/Businesses/FullService.wecarryout">We carry out extensive giveaway campaigns for you and take care of everything from planning to the final evaluation. We use our steadily growing <span style={{ fontWeight: 800 }}>partner network of currently 125 established influencers</span> to conduct the giveaways.</Trans></p>
            <ButtonHolder justify={"flex-start"}><Button onClick={() => this.setState({ expanded: !expanded })} primary={!expanded} outline={expanded}><span>{!expanded ? t("QuickTour/Businesses/FullService.learnmore") : t("QuickTour/Businesses/FullService.minimize")}</span>&nbsp;<span>{expanded ? "-" : "+"}</span></Button></ButtonHolder>
          </div>
        </div>
        <div className="ccc-service-box-content">
          <h4 className="ccc-h4">{t("QuickTour/Businesses/FullService.everycampaigntailored")}</h4>

          <div className="ccc-service-box-content-3col">
            <div>
              <h5 className="ccc-h5">{t("QuickTour/Businesses/FullService.conceptplanning")}</h5>
              <p className="ccc-p">{t("QuickTour/Businesses/FullService.inafirst")}</p>
            </div>
            <div>
              <h5 className="ccc-h5">{t("QuickTour/Businesses/FullService.launching")}</h5>
              <p className="ccc-p">{t("QuickTour/Businesses/FullService.wetakecare")}</p>
            </div>
            <div>
              <h5 className="ccc-h5">{t("QuickTour/Businesses/FullService.evaluation")}</h5>
              <p className="ccc-p">{t("QuickTour/Businesses/FullService.weevaluate")}</p>
            </div>
          </div>

          <div className="ccc-service-box-content-pricetable">
            <div>
              <h4 className="ccc-h4">Basic</h4>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.reach")}><span><Trans i18nKey="QuickTour/Businesses/FullService.list.reach" values={{ reach: "10.000+" }}>Reach: <span style={{ fontWeight: 800 }}>5.000+ interactions</span></Trans></span></Tooltip></p>
              <p className="ccc-p">{t("QuickTour/Businesses/FullService.list.planning")}</p>
              <p className="ccc-p">{t("QuickTour/Businesses/FullService.list.flexible")}</p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.final")}><span>{t("QuickTour/Businesses/FullService.list.final")}</span></Tooltip></p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.unlimited")}><span>{t("QuickTour/Businesses/FullService.list.unlimited")}</span></Tooltip></p>
              <p className="ccc-p ccc-service-not-included"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.socialmedia")}><span>{t("QuickTour/Businesses/FullService.list.socialmedia")}</span></Tooltip></p>
              <p className="ccc-p ccc-service-not-included"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.coupon")}><span>{t("QuickTour/Businesses/FullService.list.coupon")}</span></Tooltip></p>
              <p className="ccc-p ccc-service-not-included"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.platformwide")}><span>{t("QuickTour/Businesses/FullService.list.platformwide")}</span></Tooltip></p>
              <p className="ccc-p ccc-service-not-included"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.individuallanding")}><span>{t("QuickTour/Businesses/FullService.list.individuallanding")}</span></Tooltip></p>
              <p className="ccc-p ccc-service-not-included"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.graphicdesign")}><span>{t("QuickTour/Businesses/FullService.list.graphicdesign")}</span></Tooltip></p>
            </div>
            <div>
              <h4 className="ccc-h4">Professional</h4>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.reach")}><span><Trans i18nKey="QuickTour/Businesses/FullService.list.reach" values={{ reach: "25.000+" }}>Reach: <span style={{ fontWeight: 800 }}>5.000+ interactions</span></Trans></span></Tooltip></p>
              <p className="ccc-p">{t("QuickTour/Businesses/FullService.list.planning")}</p>
              <p className="ccc-p">{t("QuickTour/Businesses/FullService.list.flexible")}</p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.final")}><span>{t("QuickTour/Businesses/FullService.list.final")}</span></Tooltip></p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.unlimited")}><span>{t("QuickTour/Businesses/FullService.list.unlimited")}</span></Tooltip></p>
              <p className="ccc-p "><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.socialmedia")}><span>{t("QuickTour/Businesses/FullService.list.socialmedia")}</span></Tooltip></p>
              <p className="ccc-p "><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.coupon")}><span>{t("QuickTour/Businesses/FullService.list.coupon")}</span></Tooltip></p>
              <p className="ccc-p ccc-service-not-included"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.platformwide")}><span>{t("QuickTour/Businesses/FullService.list.platformwide")}</span></Tooltip></p>
              <p className="ccc-p ccc-service-not-included"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.individuallanding")}><span>{t("QuickTour/Businesses/FullService.list.individuallanding")}</span></Tooltip></p>
              <p className="ccc-p ccc-service-not-included"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.graphicdesign")}><span>{t("QuickTour/Businesses/FullService.list.graphicdesign")}</span></Tooltip></p>
            </div>
            <div>
              <h4 className="ccc-h4">Premium</h4>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.reach")}><span><Trans i18nKey="QuickTour/Businesses/FullService.list.reach" values={{ reach: "50.000+" }}>Reach: <span style={{ fontWeight: 800 }}>5.000+ interactions</span></Trans></span></Tooltip></p>
              <p className="ccc-p">{t("QuickTour/Businesses/FullService.list.planning")}</p>
              <p className="ccc-p">{t("QuickTour/Businesses/FullService.list.flexible")}</p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.final")}><span>{t("QuickTour/Businesses/FullService.list.final")}</span></Tooltip></p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.unlimited")}><span>{t("QuickTour/Businesses/FullService.list.unlimited")}</span></Tooltip></p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.socialmedia")}><span>{t("QuickTour/Businesses/FullService.list.socialmedia")}</span></Tooltip></p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.coupon")}><span>{t("QuickTour/Businesses/FullService.list.coupon")}</span></Tooltip></p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.platformwide")}><span>{t("QuickTour/Businesses/FullService.list.platformwide")}</span></Tooltip></p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.individuallanding")}><span>{t("QuickTour/Businesses/FullService.list.individuallanding")}</span></Tooltip></p>
              <p className="ccc-p"><Tooltip title={t("QuickTour/Businesses/FullService.tooltip.graphicdesign")}><span>{t("QuickTour/Businesses/FullService.list.graphicdesign")}</span></Tooltip></p>
            </div>
          </div>






          <ButtonHolder><Button primary component={Link} to="/contact">{t("QuickTour/Businesses/FullService.sendinquiry")}</Button></ButtonHolder>

        </div>
      </Layout>
    </React.Fragment>)}</Translation>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: language(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleContact: () => {
      dispatch(modalAction({ modal: MODAL_CONTACT_US }));
    },
  }
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(FullService)



