import { compose } from 'redux';
import View from './View';

import withForm from 'hoc/withForm';
import { connect } from 'react-redux';
import { RESTAPI } from 'rdx/modules/api/rest';
import { SubmissionError } from 'redux-form';
import { REGEX_EMAIL } from 'helpers/const';

const validate = values => {
  const errors = {};

  if (!values.email || !REGEX_EMAIL.test(values.email)) {
    errors.email = 'forms.validation_email';
  }



  return errors;
};


const onSubmit = (values, dispatch) => {

  return RESTAPI.postEmailPwReset({ payload: values }).then((r) => {
    return r
  }).catch(error => {
    throw new SubmissionError(error && error.response && error.response.data)
  })

}

export const onSubmitFail = (errors, dispatch, submitError, form) => {
  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input[type=text].invalid, textarea.invalid, select.invalid, input[type=checkbox].invalid, div.invalid',
    );
    if (firstErrorNode) {
      firstErrorNode.focus();
    }
  }, 50);
};

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      email: null,
      account: state.auth.user && state.auth.user.username,
      name: null,
      message: null,
      subject: "",
      captcha_response: null,
    },
    subject: state.form.contactus && state.form.contactus.values && state.form.contactus.values.subject
  }
}


const enhance = compose(
  connect(mapStateToProps),
  withForm({
    form: 'email-forgot',
    captcha: "forgotpassword",
    validate,
    onSubmit,
    onSubmitFail,
    destroyOnUnmount: true,
  }),
);



export default enhance(View);

