import { createLogic } from 'redux-logic';
import { POST_RAFFLE_BY_ID_REPORT_RESPONSE } from '../modules/api/index';
import { showNotificationModal } from 'rdx/modules/modals';

const postRaffleByIdReportResponseLogic = createLogic({
  type: POST_RAFFLE_BY_ID_REPORT_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      dispatch(
        showNotificationModal({
          title: 'modals.report.success_title',
          content: 'modals.report.success_body',
        }),
      );
    } catch (error) {
      console.log(error);
    }

    done();


  },
});

export default postRaffleByIdReportResponseLogic;