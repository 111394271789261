import { connect } from 'react-redux';
import View from './View';

const mapStateToProps = (state, ownProps) => {
  return {
    giveaway: state.creator.edit,
  };
};


export default connect(mapStateToProps)(View);