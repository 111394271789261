import styled from 'styled-components';


const Social = styled.a`
background-color: ${({ color }) => color};
position: relative;
box-shadow: inset 0 3px 0 0 rgba(255, 255, 255, 0.2);

:hover {
  opacity: .9;
}

width: 36px;
height: 36px;
border-radius: 25px;

display: flex;
justify-content: center;
align-items: center;

&[class^='icon-'] {
  color: white;
}

cursor: pointer;

& + & {
  margin-left: 10px;
}
`;

export default Social