import React from 'react';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { Translation } from 'react-i18next';
import Button from 'common/MaterialUI/Button';

const Layout = styled.div`
  width: 100%;

  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Notmuch = styled.h2`
  font-size: 20px;
  color: #ff8432;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
`;

const ButtonLayout = styled.div`
  display: flex;
  justify-content: center;
`;

export const Empty = () => (
  <Translation>
    {t => (
      <Layout>
        <Notmuch>{t('dashboard_empty_time_to_enter')}</Notmuch>
        <ButtonLayout>
          <Link >
            <Button component={Link} to={`/giveaways`} primary>{t('browse_giveaways')}</Button>
          </Link>
        </ButtonLayout>
      </Layout>
    )}
  </Translation>
);
