import { compose } from 'redux';
import View from './View';
import withForm from 'hoc/withForm';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { RESTAPI } from 'rdx/modules/api/rest';
import { PLATFORMID_GHEED, REGEX_EMAIL } from 'helpers/const';

const validate = values => {
  const errors = {};

  if (!values.new_email || !REGEX_EMAIL.test(values.new_email)) {
    errors.new_email = 'forms.validation_email';
  }


  if (!values.password) {
    errors.password = 'forms.validation_password';
  }

  return errors;
};



const onSubmit = (values, dispatch) => {

  const subValues = { ...values, passwordconfirm: undefined }

  return RESTAPI.postEmailChange({ payload: subValues }).then((r) => {
    return Promise.resolve(r);
  }).catch(error => {

    throw new SubmissionError(error && error.response && error.response.data)

  })




};

export const onSubmitFail = (errors, dispatch, submitError, form) => {
  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input.error'
    );
    if (firstErrorNode) {
      firstErrorNode.focus();
    }
  }, 50);
};

const mapStateToProps = (state, ownProps) => {

  const social = state.auth.user && state.auth.user.socials && state.auth.user.socials.find(s => s.PLATFORMID == PLATFORMID_GHEED);

  return {
    email: state.form["email-edit"] && state.form["email-edit"].values && state.form["email-edit"].values.new_email,
    initialValues: {
      new_email: social && social.socialusername
    },
  }
}


const enhance = compose(
  connect(mapStateToProps),
  withForm({
    form: 'email-edit',
    validate,
    onSubmit,
    onSubmitFail,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false
  }),
);



export default enhance(View);

