import React from 'react';
import styled from 'styled-components';
import { Translation, Trans } from 'react-i18next';
import { SHOP_ID_PRIVATE } from '../../helpers/const';

const Layout = styled.div`
  font-size: 11px;
  color: #fc521e;

  display: flex;
  align-items: center;

  > span:first-child {
    margin-right: 5px;
  }
`;

export const PrivateNote = ({ prizes }) => {
  const hasPrivateProducts = prizes.filter(prz => prz.product.shop_id == SHOP_ID_PRIVATE).length > 0

  return hasPrivateProducts ? (
    <Translation>{t => (<Layout>
      <span className="icon-Ico16-Globe" />
      <div>

        <Trans i18nKey="ClaimPrizes/PrivateNote.note"><b>NOTE:</b> Some of your prizes will be shipped directly by the giveaway creator.</Trans>
        {' '}
      </div>
    </Layout>)}</Translation>
  ) : null;
};

export default PrivateNote;
