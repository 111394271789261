import React from 'react';
import Items from './Items';

export const SliderContext = React.createContext({});

class Controller extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      prevEnabled: false,
      nextEnabled: false,
      current: 0,
      elements: 0,
      swiping: 0,
      mouseUpDisabled: false,
    };
  }

  componentWillUnmount() {
    if (this.disabledTimeout) {
      clearTimeout(this.disabledTimeout);
    }
  }

  render() {
    return (
      <SliderContext.Provider
        value={{
          ...this.state,
          next: this.next,
          prev: this.prev,
          goTo: this.goTo,
          onSwiping: this.onSwiping,
          onSwiped: this.onSwiped,
          updateSlides: this.updateSlides,
        }}
      >
        {this.props.children}
      </SliderContext.Provider>
    );
  }

  next = () => {
    const { current, elements } = this.state;
    let next = current + 1;

    if (next < elements) {
      this.setState({
        prevEnabled: next > 0,
        nextEnabled: next < elements - 1,
        current: next,
        swiping: 0,
      });
    }
    this.setState({ swiping: 0 });
  };

  prev = () => {
    const { current, elements } = this.state;
    let next = current - 1;
    if (next > -1) {
      this.setState({
        prevEnabled: next > 0,
        nextEnabled: next < elements - 1,
        current: next,
        swiping: 0,
      });
    }
    this.setState({ swiping: 0 });
  };

  goTo = i => {
    this.setState({
      prevEnabled: i > 0,
      nextEnabled: i < this.state.elements - 1,
      current: i,
      swiping: 0,
    });
  };

  onSwiping = ({ deltaX }) => {
    let valid = true;

    if (deltaX < 0 && this.state.current == 0) {
      valid = false;
    }

    if (deltaX > 0 && this.state.current == this.state.elements - 1) {
      valid = false;
    }

    this.setState({
      swiping: valid ? deltaX : 0,
      mouseUpDisabled: true,
    });
  };

  onSwiped = ({ deltaX, deltaY, velocity }) => {


    const isFlick = velocity > 0.6
    if (isFlick) {
      deltaX < 0 ? this.prev() : this.next();
    } else {
      this.setState({ swiping: 0 });
    }

    if (this.disabledTimeout) {
      clearTimeout(this.disabledTimeout);
    }
    this.disabledTimeout = setTimeout(() => {
      this.setState({ mouseUpDisabled: false });
    }, 250);
  };

  updateSlides = newSlides => {
    const { slides, current } = this.state;

    if (newSlides != slides) {
      const currentRatio = slides ? current / slides : 0;

      const nextCurrent =
        newSlides && newSlides
          ? Math.min(newSlides - 1, Math.ceil(currentRatio * newSlides))
          : 0;

      this.setState({
        prevEnabled: nextCurrent > 0 ? true : false,
        nextEnabled: newSlides && nextCurrent < newSlides - 1 ? true : false,
        current: nextCurrent,
        elements: newSlides ? newSlides : 0,
        slides: newSlides,
      });
    }
  };
}

export const Slider16 = {
  Controller: Controller,
  Items: Items,
};
export default Slider16;
