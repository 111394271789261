import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';


const Circle = styled.div`
  color: white;
  background-color: #5bcb8f;
  border-radius: 25px;
  padding: 10px;

  position: absolute;
  right: -10px;
  top: -10px;
`;

export const Check = ({ user_entries }) => (
  user_entries ? <Circle className="icon-Ico16-Check-Big" /> : null
);

const mapStateToProps = (state, ownProps) => {
  const { raffle } = ownProps;
  return {
    user_entries: state.raffles.user_entries[raffle.EVENTID]
  }
}

export default connect(mapStateToProps)(Check)