import React, { useEffect, useState, useRef } from 'react';
import Box from './_Box';
import styled from 'styled-components';
import Button from 'common/MaterialUI/Button';
import openPopup from 'helpers/popup';
import Explanation from './_Explanation';
import Description from './_Description';
import Hint from './_Hint';
import hasTwitch from 'rdx/selectors/hasTwitch';
import { connect } from 'react-redux';
import { media } from 'styles/index';
import i18n from 'i18n';

const TwitchContainer = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  ${media.tabletMax`
    display: none;
  `}
`

const MobileHint = styled.div`
  display: none;

  ${media.tabletMax`
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
  `}
`

const Twitch = ({ hasTwitch, way, platform, waytype, handleContinue, toggle, ...other }) => {

  const [popup, setPopup] = useState();
  const container = useRef(null);

  useEffect(() => {

    let loop;

    if (popup) {
      loop = setInterval(() => {
        if ( popup.closed ) {
          clearInterval(loop)
        }
      }, 500);
    }

    return () => {
	
	
      if (loop) {
        clearInterval(loop)
      }

      if (popup) {
        popup.close()
      }
    }
  }, [popup]);

 
  const error = null;
  
  const handleContinueClick = async (event) => {
    if ( waytype.connect_required && !hasTwitch ) {
      handleContinue(way, undefined, toggle(way))(event)
      return;
    }

    handlePopup();
  }

  const handleCheckClick = async (event) => {
    if (!waytype.connect_required) {
      handleContinue(way, undefined, toggle(way))(event)
      return;
    }

 
      try {
        await handleContinue(way, undefined, toggle(way))(event);
      } catch (error) {
        setPopup(undefined)
      }
   
  }


  const hasHint = !(i18n.t(`waytypes.${waytype.WAYTYPEID}.hint`) == `waytypes.${waytype.WAYTYPEID}.hint` || i18n.t(`waytypes.${waytype.WAYTYPEID}.hint`) == "");
 
  const handlePopup = () => {

    let url = null;

    url =  `https://www.twitch.tv/${way.beneficiary_display_name}`;   

    const win = openPopup(
      platform.path,
      url,
      `${platform.PLATFORMID}_${waytype.WAYTYPEID}`)
    setPopup(win)
  }
  
  return <Box {...{ way, waytype, platform, toggle, ...other }}
    error={error}
    controls={(complete, loading, t) => <React.Fragment>
      <Button outline small onClick={toggle(way)}>{t("cancel")}</Button>
      <Button outline small disabled={popup || complete || loading } onClick={handleContinueClick}>{t("open")}</Button>
      <Button secondary small disabled={complete} onClick={handleCheckClick}>{t("verify")}</Button></React.Fragment>}
  ><Description way={way} /><TwitchContainer ref={container} /><Explanation way={way} waytype={waytype} />
  <MobileHint>{hasHint && <Hint way={way} waytype={waytype} />}</MobileHint></Box>
}


const mapStateToProps = (state, ownProps) => {
  return {
    hasTwitch: hasTwitch(state)
  }
}


export default connect(mapStateToProps)(Twitch)