import moment from 'moment-timezone';
import i18n from '.';
import numeral from 'i18n/numeral';
import { LOCALES } from '../helpers/const';

const i18nextReduxMiddleware = store => next => action => {

  next(action);

  try {

    const state = store.getState();
    const { language } = state.ui;

    if (LOCALES.indexOf(language) != -1) {
      if (i18n.language != language) {
        i18n.changeLanguage(language);
      }

      if (moment.locale() != language) {
        moment.locale(language);
      }

      if (numeral.locale() != language) {
        numeral.locale(language);
      }
    }

  } catch (error) {
    console.log(error);
  }


};

export default i18nextReduxMiddleware;
