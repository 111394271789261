import { GET_USERS_SETTINGS_RESPONSE } from '../modules/api';
import { createLogic } from 'redux-logic';
import addRegionInfoToUser from './_addRegionInfoToUser';


const getUsersSettingsResponseLogic = createLogic({
  type: [GET_USERS_SETTINGS_RESPONSE],

  process({ getState, action }, dispatch, done) {
    try {

      addRegionInfoToUser(dispatch, getState)


    } catch (error) {
      console.log(error)
    }
    done();
  },
});

export default getUsersSettingsResponseLogic;