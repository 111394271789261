
import { compose } from 'redux';
import View from './View';
import withForm from 'hoc/withForm';
import { connect } from 'react-redux';
import { ROUTE_TYPE_GIVEAWAY } from 'helpers/const';
import { push } from 'redux-first-router';
import { getUsersSettingsResponseAction, postEmailLoginSendingAction, postEmailLoginResponseAction, getUsersSettingsRequestAction, postEmailLoginErrorAction } from 'rdx/modules/api';
import { modalClearAction } from 'rdx/modules/modals';

import { SubmissionError } from 'redux-form';
import { RESTAPI } from 'rdx/modules/api/rest';
import { postEmailVerifyPromise } from 'rdx/modules/api/logics';
import { REGEX_EMAIL } from 'helpers/const';

const validate = values => {
  const errors = {};

  if (!values.email || !REGEX_EMAIL.test(values.email)) {
    errors.email = 'forms.validation_email';
  }

  if (!values.password) {
    errors.password = 'forms.validation_password';
  }

  return errors;
};



const onSubmit = (values, dispatch, { routeType }) => {

  dispatch(postEmailLoginSendingAction())

  return RESTAPI.postEmailLogin({ payload: values }).then((connectResponse) => {
    if (connectResponse) {
      dispatch(postEmailLoginResponseAction(connectResponse))
           dispatch(getUsersSettingsRequestAction());
      return RESTAPI.getUsersSettings().then((response) => {

        dispatch(getUsersSettingsResponseAction(response));
        dispatch(modalClearAction())

        //redirect or reload
        if (typeof window != 'undefined') {

          if (ROUTE_TYPE_GIVEAWAY != routeType) {

            push(response.is_streamer ? '/dashboard/streamer' : '/dashboard/viewer')
          } else {
            window.location.reload();
          }
        }


      })

    }
  }).catch(error => {
    dispatch(postEmailLoginErrorAction(error))

    const errorMessages = error && error.response && error.response.data;

    throw new SubmissionError(errorMessages)

  })




};

export const onSubmitFail = (errors, dispatch, submitError, form) => {
  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input.error'
    );
    if (firstErrorNode) {
      firstErrorNode.focus();
    }
  }, 50);
};

const mapStateToProps = (state, ownProps) => {
  return {
    routeType: state.location.type,
    resending: state.rest.postEmailVerify.sending,
    email: state.form["email-login"] && state.form["email-login"].values && state.form["email-login"].values.email,
    verificationError: state.form['email-login'] && state.form['email-login'].submitErrors && state.form['email-login'].submitErrors.email == "The entered email is not verified yet.",
    captchaError: state.form['email-login'] && state.form['email-login'].submitErrors && state.form['email-login'].submitErrors.visibleCaptcha == "Invalid captcha response",
    initialValues: {
      email: null,
      password: null,
      captcha_response: null,
      visibleCaptcha: ownProps.visibleCaptcha,
    },
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resendConfirmation: (email) => () => {
      postEmailVerifyPromise({ payload: { email } }, dispatch)
    }
  }
}


const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    resendConfirmation: dispatchProps.resendConfirmation(stateProps.email),
    ...ownProps
  }
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withForm({
    form: 'email-login',
    captcha: "emaillogin",
    validate,
    onSubmit,
    onSubmitFail,
    destroyOnUnmount: true,
  }),
);



export default enhance(View);

