import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import MaterialModal from '@material-ui/core/Modal';
import { helpers } from '../helpers';


const Close = styled.div`
  position: absolute;
  right: -13px;
  top: -13px;
  cursor: pointer;
  z-index: 2;

  ${media.tabletMax`
    right: 15px;
    top: 9px;
  `} width: 34px;
  height: 34px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);
  border-radius: 17px;

  [class^='icon-Ico16-'],
  [class*=' icon-Ico16-'] {
    position: absolute;
    top: 9px;
    left: 9px;
    color: #36a6fc;
    cursor: pointer;
  }
`;

export const DefaultModalStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: none;
  outline: none;
  box-shadow: rgba(29, 29, 29, 0.3) 0px 4px 12px 0px;
  max-width: 630px;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: visible;
  min-width: 300px;
  display: flex;
  flex-direction: column;
`;


const Content = React.forwardRef((props, ref) => {
  const Inner = style ? style : DefaultModalStyle;
  const { style, preventClose, noClose, onRequestClose, children } = props
  return open ?
    <Inner ref={ref} onClick={helpers.stopPropagation}>
      {(!noClose || preventClose) && (
        <Close onClick={onRequestClose}>
          <span className="icon-Ico16-X-Medium" />
        </Close>
      )}
      {children}
    </Inner> : null
})
Content.displayName = "Content"

const Modal = ({ open,
  ...other }) => <MaterialModal open={open ? true : false} onClose={other.onRequestClose}>
    <Content {...other} />
  </MaterialModal>


export default Modal;
