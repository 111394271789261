import { compose } from 'redux';
import withForm from '../../hoc/withForm';
import { connect } from 'react-redux';
import { postContactPromise } from 'rdx/modules/api/logics';
import { reset } from 'redux-form';
import View, { FORM_BUSINESS, FORM_INFLUENCER, FORM_VIEWER } from './View';
import i18n from '../../i18n';
import { REGEX_EMAIL } from 'helpers/const';


const validate = values => {
  const errors = {};

  if (!values.email || !REGEX_EMAIL.test(values.email)) {
    errors.email = 'forms.validation_email';
  }

  if (!values.name) {
    errors.name = 'forms.validation_name';
  }

  if (!values.subject) {
    errors.subject = 'forms.validation_subject';
  }

  if (!values.message) {
    errors.message = 'forms.validation_message';
  }


  return errors;
};



const onSubmit = (values, dispatch) => {

  if (!values.account && typeof values.account != 'undefined') {
    delete values.account
  }

  return postContactPromise({ payload: values }, dispatch).then(() => {
    return Promise.resolve(setTimeout(() => {
      dispatch(reset('contactus'))
    }, 250))
  })




};

export const onSubmitFail = (errors, dispatch, submitError, form) => {
  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input[type=text].invalid, textarea.invalid, select.invalid, input[type=checkbox].invalid, div.invalid',
    );
    if (firstErrorNode) {
      firstErrorNode.focus();
    }
  }, 50);
};

const mapStateToProps = (state, ownProps) => {

  const initialView = state.location.query && [FORM_BUSINESS, FORM_INFLUENCER, FORM_VIEWER].indexOf(state.location.query.view) != -1 ? state.location.query.view : FORM_VIEWER;
  const validTopic = state.location.query && state.location.query.topic && i18n.t(`Contact/View.subject.${state.location.query.topic}`)

  return {
    initialView,
    initialValues: {
      email: null,
      account: state.auth.user && state.auth.user.username,
      name: null,
      message: null,
      subject: (initialView == FORM_BUSINESS ? (initialView == FORM_BUSINESS && validTopic) : (initialView == FORM_INFLUENCER && validTopic)) ? validTopic : "",
      captcha_response: null,
    },
    subject: state.form.contactus && state.form.contactus.values && state.form.contactus.values.subject
  }
}


const enhance = compose(
  connect(mapStateToProps),
  withForm({
    captcha: 'contact',
    form: 'contactus',
    validate,
    onSubmit,
    onSubmitFail,
    destroyOnUnmount: true,
  }),
);



export default enhance(View);

