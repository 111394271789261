import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { WALLET_STATUS_HIDDEN } from 'helpers/const';
import { View } from './View';
import { putWalletsRequestAction } from 'rdx/modules/api';
import { getWalletsListRequestAction, WALLETS_LIST_MYWALLETS, WALLETS_LIST_MYHIDDENWALLETS } from 'rdx/modules/stats';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (page, limit, include) => {
      dispatch(getWalletsListRequestAction([{ walletstatusInclude: include, page, limit }, WALLETS_LIST_MYWALLETS]))
      dispatch(getWalletsListRequestAction([{ walletstatusInclude: [WALLET_STATUS_HIDDEN], page: 1, limit: 1 }, WALLETS_LIST_MYHIDDENWALLETS]))
    },
    updateWalletStatus: (walletid, walletstatusid) =>
      dispatch(putWalletsRequestAction({ payload: { wallets: [{ walletid, walletstatusid }] } }))
  };
};

const myWalletsList = state => state.stats[WALLETS_LIST_MYWALLETS];
const myHiddenWalletsList = state => state.stats[WALLETS_LIST_MYHIDDENWALLETS];

const selectedState = createSelector([myWalletsList, myHiddenWalletsList], (myWallets, myHiddenWallets) => {
  return { ...myWallets, disableHidden: !myHiddenWallets || !myHiddenWallets.wallets || myHiddenWallets.wallets.length == 0 }
});

const mapStateToProps = (state, ownProps) => {
  return selectedState(state);
};

export const MyLoyaltyCoins = connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);

export default MyLoyaltyCoins;
