import React from 'react';
import View from "./View"
import { connect } from 'react-redux';
import { runtimeConfig } from "../../../runtimeConfig";
import { ROUTE_PREFIX } from "helpers/const";
import locationType from 'rdx/selectors/locationType';


const ViewWithConfig = ({ locationType, placement, ...other }) => {

  const { settings, campaigns } = runtimeConfig.CAMPAIGNS

  const displayCampaigns = {};

  if (settings && campaigns) {

    const placementConfig = settings && settings.placements[placement];

    if (placementConfig) {
      for (const entry of placementConfig) {
        if (campaigns && campaigns[entry]) {
          displayCampaigns[entry] = campaigns[entry];
        }
      }

    } else {

      for (const key of Object.keys(campaigns)) {
        const campaign = campaigns[key]
        if (`${ROUTE_PREFIX}${campaign.path}` == locationType) {
          displayCampaigns[key] = campaign
          break;
        }

      }

    }
  }

  return <View {...other} campaigns={displayCampaigns} interval={settings && settings.interval ? settings.interval : 10000} />
}


const mapStateToProps = (state, ownProps) => {
  return {
    locationType: locationType(state)
  }
}


export default connect(mapStateToProps)(ViewWithConfig);