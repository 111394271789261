import { connect } from 'react-redux';
import { putUsersSettingsRequestAction } from 'rdx/modules/api';
import View from './View';
import { uiChangeAttributeAction } from 'rdx/modules/ui';
import { ROUTE_TYPES_CREATOR, ROUTE_TYPE_GIVEAWAY_EDIT } from '../../../helpers/const';
import isAuthed from '../../../store/selectors/isAuthed';

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.ui.language,
    hasUser: isAuthed(state),
    disabled: [...ROUTE_TYPES_CREATOR, ROUTE_TYPE_GIVEAWAY_EDIT].indexOf(state.location.type) != -1
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (hasUser) => (value) => {
      dispatch(hasUser ? putUsersSettingsRequestAction({ payload: { preferences: { ui_language: value } } }) : uiChangeAttributeAction({ language: value }))
    }
  }
}

const mergeProps = ({ hasUser, ...state }, dispatchProps, ownProps) => {
  return {
    ...state,
    ...ownProps,
    handleChange: dispatchProps.handleChange(hasUser)
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View);
