import { handleActions, combineActions } from 'redux-actions';
import {
  PUT_CART_REQUEST,
  PUT_CART_ERROR,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_RESPONSE,
  PUT_COUPON_REQUEST,
  PUT_COUPON_RESPONSE,
  PUT_COUPON_ERROR,
  GET_CART_REQUEST,
  GET_CART_RESPONSE,
  PUT_CART_RESPONSE,
  GET_CART_ERROR,
  GET_RAFFLES_RESPONSE,
  PUT_RAFFLES_RESPONSE,
  GET_PAYMENT_REQUEST,
  GET_PAYMENT_RESPONSE,
  GET_RAFFLE_BY_ID_EDIT_RESPONSE,
  PUT_RAFFLE_BY_ID_EDIT_RESPONSE,
  GET_PRIVATE_PRODUCT_BY_EVENTID_RESPONSE,
  GET_PRIVATE_PRODUCT_BY_EVENTID_REQUEST,
  PUT_PRIVATE_PRODUCT_BY_EVENTID_RESPONSE
  , POST_BILLING_REQUEST, GET_WAYTYPES_VALIDATE_ERROR, GET_WAYTYPES_VALIDATE_RESPONSE
} from '../api';
import { utils } from 'helpers/utils';
import { ROUTE_TYPE_GIVEAWAY } from '../../../helpers/const';



export const RAFFLES_INFO = 'raffles/RAFFLES_INFO';

const initialState = {
  activeIndex: 0,
  cart: null,
  cartError: null,
  updatingCart: null,
  temp: null,
  edit: null,
  paymentUrl: null,
  paymentMode: 'sandbox',
  paymentDefaultCountry: 'DE',
  paymentLoading: true,
  privateProducts: {},
  privateProductsLoading: false,
  waytypeValidationErrors: {}
};

export const creatorReducer = handleActions(
  {

    [GET_WAYTYPES_VALIDATE_RESPONSE]: (state, action) => ({
      ...state,
      waytypeValidationErrors: { ...state.waytypeValidationErrors, [action.payload.id]: undefined },
    }),


    [GET_WAYTYPES_VALIDATE_ERROR]: (state, action) => ({
      ...state,
      waytypeValidationErrors: { ...state.waytypeValidationErrors, [action.payload.id]: action.payload.errors },
    }),


    [POST_BILLING_REQUEST]: (state, action) => ({
      ...state,
      paymentUrl: null,
    }),

    [ROUTE_TYPE_GIVEAWAY]: (state, action) => ({
      ...state,
      edit: null,
      temp: null,
      cart: null,
    }),

    [GET_PRIVATE_PRODUCT_BY_EVENTID_REQUEST]: (state, action) => ({
      ...state,
      privateProductsLoading: true
    }),

    [combineActions(PUT_PRIVATE_PRODUCT_BY_EVENTID_RESPONSE, GET_PRIVATE_PRODUCT_BY_EVENTID_RESPONSE)]: (state, action) => ({
      ...state,
      privateProductsLoading: false,
      privateProducts: { ...state.privateProducts, [action.payload.eventid]: action.payload.private_products },
    }),

    [GET_CART_REQUEST]: (state, action) => ({
      ...state,
      updatingCart: true,
    }),

    [PUT_COUPON_REQUEST]: (state, action) => ({
      ...state,
      updatingCart: true,
      error: false,
      openDropDown: true,
    }),
    [PUT_COUPON_ERROR]: (state, action) => ({
      ...state,
      updatingCart: false,
      error: utils.extractMessageFromRESTError(action.payload),
    }),

    [PUT_COUPON_RESPONSE]: (state, action) => ({
      ...state,
      cart: action.payload,
      updatingCart: false,
    }),

    [DELETE_COUPON_REQUEST]: (state, action) => ({
      ...state,
      updatingCart: true,
    }),

    [DELETE_COUPON_RESPONSE]: (state, action) => ({
      ...state,
      cart: action.payload,
      updatingCart: false,
    }),

    [PUT_CART_REQUEST]: (state, action) => ({
      ...state,
      updatingCart: true,
      openDropDown: true,
    }),

    [PUT_CART_RESPONSE]: (state, action) => ({
      ...state,
      cart: action.payload,
      updatingCart: false,
    }),

    [GET_CART_RESPONSE]: (state, action) => ({
      ...state,
      cart: action.payload,
      updatingCart: false,
    }),
    [GET_CART_ERROR]: (state, action) => ({
      ...state,
      updatingCart: false,
      cartError: utils.extractMessageFromRESTError(action.payload),
    }),
    [PUT_CART_ERROR]: (state, action) => ({
      ...state,
      updatingCart: false,
      cartError: utils.extractMessageFromRESTError(action.payload),
    }),



    [PUT_RAFFLE_BY_ID_EDIT_RESPONSE]: (state, action) => {
      return {
        ...state,
        edit: action.payload
      };
    },

    [GET_PAYMENT_REQUEST]: (state, action) => ({
      ...state,
      loadingPayment: true,
    }),
    [GET_PAYMENT_RESPONSE]: (state, action) => ({
      ...state,
      loadingPayment: false,
      paymentUrl: action.payload.approval_URL,
      paymentMode: action.payload.mode
        ? action.payload.mode
        : state.paymentMode,
      paymentDefaultCountry: action.payload.default_country
        ? action.payload.default_country
        : state.paymentDefaultCountry,
    }),


    [GET_RAFFLES_RESPONSE]: (state, action) => {
      if (action.payload.ways) {
        action.payload.ways.sort((a, b) => a.rank - b.rank);
      }
      return {
        ...state,
        temp: {
          ...action.payload,
          eventpic: action.payload.eventpic.uuid
            ? action.payload.eventpic
            : null,
        },
      };
    },





    [GET_RAFFLE_BY_ID_EDIT_RESPONSE]: (state, action) => {

      if (action.payload.ways) {
        action.payload.ways.sort((a, b) => a.rank - b.rank);
      }
      return {
        ...state,
        edit: {
          ...action.payload,
          eventpic: action.payload.eventpic.uuid
            ? action.payload.eventpic
            : null,
        },
      };
    },

    [PUT_RAFFLES_RESPONSE]: (state, action) => {
      if (action.payload.ways) {
        action.payload.ways.sort((a, b) => a.rank - b.rank);
      }
      return {
        ...state,
        temp: {
          ...action.payload,
          eventpic: action.payload.eventpic.uuid
            ? action.payload.eventpic
            : null,
        },
      };
    },
  },
  initialState,
);

export default creatorReducer;
