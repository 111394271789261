import React from 'react';
import Button from 'common/MaterialUI/Button'
import Grid from '@material-ui/core/Grid';
import InputField from 'common/InputField';
import styled from 'styled-components';
import { Translation, Trans } from 'react-i18next';

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;



const View = ({ handleSubmit, submitSucceeded, dirty, submitting }) => <Translation>{t => (<form onSubmit={handleSubmit}>


  {submitSucceeded ? <Trans i18nKey="Modals/EmailLoginSetup/EditPassword/View.success"><p><strong>Passwort-Änderung erfolreich</strong><br />Du kannst dich nun mit diesen Daten einloggen.</p></Trans> :

    <Grid container spacing={3}>
      <Grid item xs={12}><InputField disableValidState name="old_password" type="password" title={t("Modals/EmailLoginSetup/EditPassword/View.oldpassword")} /></Grid>
      <Grid item xs={12}><InputField name="new_password" type="password" title={t("Modals/EmailLoginSetup/EditPassword/View.newpassword")} /></Grid>
      <Grid item xs={12}><InputField name="new_passwordconfirm" type="password" title={t("Modals/EmailLoginSetup/EditPassword/View.newpasswordconfirm")} /></Grid>
    </Grid>}





  <Controls>
    {!submitSucceeded && <Button disabled={!dirty || submitting} primary type="submit">{t("Modals/EmailLoginSetup/EditPassword/View.submit")}</Button>}
  </Controls>
</form>)}</Translation>

export default View