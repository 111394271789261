import styled from 'styled-components';
import React from 'react';
import { addScrollListener, removeScrollListener } from 'helpers/scroll';
import MainNavigation from './Main';
import LogoOnly from './LogoOnly';
import classNames from 'classnames';
import SubmenuDashboard from './SubmenuDashboard';
import SubmenuGiveaway from './SubmenuGiveaway';


import { ROUTE_TYPE_DASHBOARD_STREAMER, ROUTE_TYPE_DASHBOARD_VIEWER, ROUTE_TYPE_QUICKTOUR } from '../../helpers/const';
import SubmenuQuicktour from './SubmenuQuicktour';

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 70;
  margin-top: 0;
  transition: transform 0.2s ease-in-out;

  left: -17px;
  right: -17px;

  align-items: center;

  &.scrolling {
    transform: translateY(-100%);
  }



`;

const MainNavWrapper = styled.div`
  background: #1d1d1d;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;



export class Layout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { scrolling: false };
  }

  componentDidMount() {
    addScrollListener(this.scrollListener, true);
  }

  componentWillUnmount() {
    removeScrollListener(this.scrollListener);
  }

  scrollListener = scrollInfo => {
    this.setState({
      scrolling:
        scrollInfo.scrollTop > 116 && scrollInfo.scrollDirection != 'up',
    });
  };

  render() {
    const {
      logoOnly,
      routeType,
      user,
      showGiveawayEditing,
      children,
      isStreamer,
    } = this.props;
    const { scrolling } = this.state;

    return (
      <Styles className={classNames("mui-fixed", { scrolling })}>
        <MainNavWrapper>
          {!logoOnly ? (
            <MainNavigation {...this.props} scrolling={scrolling} />
          ) : (
              <LogoOnly routeType={routeType} />
            )}
        </MainNavWrapper>

        {[ROUTE_TYPE_DASHBOARD_STREAMER, ROUTE_TYPE_DASHBOARD_VIEWER].indexOf(routeType) != -1 && user && isStreamer && <SubmenuDashboard routeType={routeType} />}
        {children && children}
        {showGiveawayEditing && <SubmenuGiveaway routeType={routeType} />}
        {routeType == ROUTE_TYPE_QUICKTOUR && <SubmenuQuicktour />}
      </Styles>
    );
  }
}

Layout.defaultProps = {
  logoOnly: false,
};

export default Layout;
