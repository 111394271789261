import React from 'react';
import styled from 'styled-components';
import CoinsInfoButton from 'common/CoinsInfoButton';
import Button from 'common/MaterialUI/Button';
import { Translation } from 'react-i18next';

const Layout = styled.div`
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Notmuch = styled.h2`
  font-size: 20px;
  color: #ff8432;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonLayout = styled.div`
  display: flex;
  justify-content: center;
`;

export const Empty = () => (
  <Translation>{t => <Layout>
    <Notmuch>{t('dashboard_coins_empty')} <CoinsInfoButton />
    </Notmuch>
    <ButtonLayout>
      <Button outline
        href="https://www.twitch.tv/directory/all"
        target="_blank"
        rel="noopener noreferrer"

      ><span className="icon-Ico16-Login" /><span>{t('dashboard_coins_empty_browse')}</span></Button>
    </ButtonLayout>
  </Layout>}</Translation>
);
