import React from 'react';
import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { H6ExtraBoldGrey } from '../../styles/Kit/H6ExtraBold';
import { grey } from 'styles/Kit/colors';
import { BodySmallRegularGrey } from 'styles/Kit/BodySmallRegular';
import Tooltip from './Tooltip';
import classNames from 'classnames';
import Scrollbar from 'common/MaterialUI/Scrollbar';


const Large = css`
  height: 44px;
  max-height: 44px;
  padding: 0 10px;
`

const Medium = css`
  height: 34px;
  max-height: 34px;
  padding:  0 10px ;
`

const Small = css`
  height: 24px;
  max-height: 24px;
  padding:  0 10px ;
`

const Multiline = css`
  padding: 10px 10px;
`

const ValidAdornment = <InputAdornment><span className="validIcon icon-Ico16-Check-Medium" /></InputAdornment>
const InvalidAdornment = <InputAdornment><span className="errorIcon icon-Ico16-X-Medium" /></InputAdornment>

const StyledTextField = styled(({ ref, inputProps, maxlength, hint, large, small, multiline, valid, error, SelectProps, InputProps, keepCharcounter, ...props }) => {

  const { onChange, helperText, value, defaultValue } = props;
  let chars = value ? value.length : defaultValue ? defaultValue.length : 0;
  
  const handleMaxLengthAndChange = (event) => {
    chars = event.target.value.length;

    onChange && onChange(event);
  }

  return <TextField


    SelectProps={{ ...(SelectProps || {}), MenuProps: { MenuListProps: { component: Scrollbar } } }}
    inputProps={{ ...(inputProps || {}), maxLength: maxlength, className: classNames({ error }) }}
    fullWidth={true} multiline={multiline} {...props} error={error ? true : false}
    FormHelperTextProps={{ classes: { "root": "fhtroot" } }}
    InputLabelProps={{ shrink: true, classes: { "root": "lroot", "shrink": "lshrink", "formControl": "lformControl" } }}
    InputProps={{ ...InputProps, endAdornment: multiline ? undefined : error ? InvalidAdornment : valid ? ValidAdornment : InputProps && InputProps.endAdornment, classes: { "root": "root", "error": "error", "focused": "focused", "notchedOutline": "notchedOutline", "input": "input" } }}  /* eslint-disable-line react/jsx-no-duplicate-props */
    variant="outlined"
    maxlength={maxlength}
    onChange={maxlength ? handleMaxLengthAndChange : onChange}
    helperText={maxlength ? (keepCharcounter ? `${helperText} (${chars}/${maxlength})` : helperText) || `${chars}/${maxlength}` : helperText}
  />
})` 

.fhtroot {
  ${BodySmallRegularGrey}
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  transform: translate(0px,-20px);
}

.lroot {
  ${H6ExtraBoldGrey}
  color: ${grey} !important;
  display: flex;
}

.lshrink {
  transform: translate(0px, -20px) !important;
}
    

.root {
  background: white;
  border-radius: 3px;
  ${({ multiline, large, small }) => multiline ? Multiline : large ? Large : small ? Small : Medium}
  font-size:  ${({ small }) => small ? '13px' : '16px'}
  ${(props) => (props.type == "number" && css`padding-right: 20px; `)}

  .notchedOutline {
      border-radius: 3px;
      border-width: 1px !important;
      border-color: ${({ valid }) => valid ? '#41a967' : '#9697a1'};
      box-shadow: inset 0 2px 0 0 #eaedf3;
      legend {
        width: 0 !important;
      }
      min-height: 39px;
  }

  &.focused {
    .notchedOutline {
      border-color: #36a6fc;
    }
  }

  &.error {
    .notchedOutline {
      border-color: #fc521e;
    }
  }

  [class^= 'icon-'],
    [class*= ' icon-'] {
    color: ${({ disabled }) => disabled ? '#9697a1' : '#36a6fc'}  ;
    transform: translateY(1px);

    display: flex;
    align-items: center;
  }

  .validIcon {
    color: #41a967;
    ${(props) => (props.select && css`margin-right: 10px; `)}
  }

  .errorIcon {
    color: #fc521e;
    ${(props) => (props.select && css`margin-right: 10px; `)}
  }   

  .input {
    padding: 15px 10px;
  }


}
`;


const ArrowsLayout = styled.div`
  
  position: relative;
  
  > div:last-child {
      position:absolute; 
      top: 2px;
      right: 1px;
      bottom: 1px;
      left: auto;

      display: flex;
      flex-direction: column;

      > button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        width: 20px;
        max-height: 15px;
        flex: 0 0 50%;
        color: #36a6fc;
        background: #F4F5F9;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        
        :first-child {
          border-top-right-radius: 3px;
        }

        :last-child {
          border-bottom-right-radius: 3px;
        }
      }

      > button.disabled {
        opacity: .5;
      }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

`


class ArrowsWrapper extends React.PureComponent {

  handleClick = (mod) => () => {
    if (this.container) {
      const input = this.container.querySelector('input[type=number]');

      if (input) {

        input.focus();

        const nextValue = parseInt(input.value) + mod;

        if (typeof input.min != 'undefined' && nextValue < parseInt(input.min)) { return }
        if (typeof input.max != 'undefined' && nextValue > parseInt(input.max)) { return }

        let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(input, nextValue);

        var ev2 = new Event('input', { bubbles: true });
        input.dispatchEvent(ev2);


      }
    }
  }

  render() {

    const { invert, style, type, children, disabled, onMouseDown, onMouseUp } = this.props;
    const { handleClick } = this;

    return type == "number" ? <ArrowsLayout style={style} ref={node => this.container = node}>
      {children}
      <div>
        <button tabIndex="0" className={classNames("arrow", { disabled })} type="button" onMouseDown={onMouseDown} onMouseUp={onMouseUp} onClick={!disabled ? handleClick(invert ? -1 : 1) : undefined}><span className="arrow icon-Ico16-ArrowUp-Small" /></button>
        <button tabIndex="0" className={classNames("arrow", { disabled })} type="button" onMouseDown={onMouseDown} onMouseUp={onMouseUp} onClick={!disabled ? handleClick(invert ? 1 : -1) : undefined}><span className="arrow icon-Ico16-ArrowDown-Small" /></button>
      </div>
    </ArrowsLayout> : children;
  }
}

const Layout = styled.div`
  position: relative;
  flex: 1 1 auto;
  ${({ nomargin }) => !nomargin && css`margin-top: 20px;`}
`


class Wrapper extends React.PureComponent {

  handleBlur = (event) => {

    if (this.preventBlur) {
      return;
    }

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  handleMouseDown = () => {
    this.preventBlur = true;
  }

  handleMouseUp = () => {
    this.preventBlur = false;
  }


  render() {

    const { nomargin, active, disableTooltip, invert, ...props } = this.props
    const { handleBlur, handleMouseDown, handleMouseUp } = this;

    return <Layout ref={n => this.layout = n} nomargin={nomargin}>
      <Tooltip warning disableHoverListener={true} disableFocusListener={disableTooltip} placement="bottom-start" open={!disableTooltip && props.error && active ? true : false} title={<span>{props.error || '!error not found!'}</span>}>
        <ArrowsWrapper onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} invert={invert ? true : false} {...props}><StyledTextField {...props} onBlur={handleBlur} /></ArrowsWrapper>
      </Tooltip>
    </Layout>
  }

}

export default Wrapper;