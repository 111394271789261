import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import View from './View';
import { raffleDynamicListRequestAction, GIVEAWAYLIST_MYLAUNCHED } from 'rdx/modules/giveawayLists';
import { modalAction, MODAL_POSTLAUNCH } from '../../../store/modules/modals';

const auth = state => state.auth;
const list = state => state.giveawayLists[GIVEAWAYLIST_MYLAUNCHED];

const selectedState = createSelector([auth, list], (auth, list) => {
  return { user: auth.user, ...list };
});

const mapStateToProps = (state, ownProps) => {
  return selectedState(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openShareModal: (EVENTID) => () => {
      dispatch(modalAction({
        modal: MODAL_POSTLAUNCH, data: { EVENTID }
      }))
    },
    load: (page, limit) => {
      return dispatch(raffleDynamicListRequestAction([{
        own: true,
        limit,
        page,
        orderby: 'active_to desc'
      }, GIVEAWAYLIST_MYLAUNCHED]),
      );
    },
  };
};



export const LaunchedGiveaways = connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);

export default LaunchedGiveaways;
