import { uiChangeAttributeAction } from 'rdx/modules/ui';
import { connect } from 'react-redux';
import { helpers } from '../../helpers';
import { CookieSettings } from './Layout';

const mapStateToProps = (state, ownProps) => {
  return {
    cookieNotice:
      typeof state.ui.cookieNotice == 'string'
        ? helpers.stringToBoolean(state.ui.cookieNotice)
        : state.ui.cookieNotice,
    disableAnalytics: state.ui.disableAnalytics,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeDisableAnalyticsSetting: value => {
      dispatch(uiChangeAttributeAction({ disableAnalytics: value }));
    },
    changeVisibilitySetting: value => {
      dispatch(uiChangeAttributeAction({ cookieNotice: value }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CookieSettings);
