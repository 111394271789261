import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from 'styles';

const SectionStyle = styled.section.attrs(props => ({
  style: {
    flex: props.fullheight ? '1 0 auto' : '0 1 auto',
  }
}))`
  width: 300px;
  max-width: 300px;

  ${media.tablet`
    width: 630px;
    max-width: 630px;
  `};

  ${media.desktop`
    width: 960px;
    max-width: 960px;
  `};

  ${media.widescreen`
    width: 1290px;
    max-width: 1290px;
  `};

  text-align: left;
  display: flex;
  flex-direction: column;
`;

const Section = props => {
  return (
    <SectionStyle
      ref={props.ref}
      fullheight={props.fullheight}
      className={props.slim ? 'slim' : ''}
    >
      {props.title && (
        <h1>
          {props.title} {props.append && <span>{props.append}</span>}
        </h1>
      )}
      {props.children}
    </SectionStyle>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Section;
