import React from 'react';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { Translation } from 'react-i18next';
import Button from 'common/MaterialUI/Button';

const Layout = styled.div`
  background: white;
  min-width: 300px;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);
  :hover {
    box-shadow: 0 4px 12px 0 rgba(29, 29, 29, 0.3);
  }

  > :nth-child(2) {
    height: 50px;
  }

  display: flex;
  flex-direction: column;

  justify-content: center;
  border-radius: 6px;
  cursor: pointer;

  width: 300px;
  max-width: 300px;
  height: 375px;
  position: relative;

  padding: 40px;
  align-items: center;

  text-align: center;

  background-image: url(/static/assets/images/splash-dark.png);
  background-repeat: no-repeat;
  background-size: 191px 155px;
  background-position: center;

  h3 {
    font-size: 20px;
  }

  p {
    margin: 10px 0;
  }

  a {
    white-space: nowrap;
    height: 36px;
    border-radius: 25px;
    box-shadow: inset 0 1px 0 0 #cdcdcd;

    flex: 0 1 auto;

    display: flex;

    justify-content: center;

    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    color: white;
    background: #36a6fc;
    text-shadow: 0 1px 0 rgba(29, 29, 29, 0.2);
    box-shadow: inset 0 3px 0 0 #4ccbfd;

    text-transform: uppercase;
    font-weight: 800;
    font-family: Nunito;
  }
`;


const ThereIsMore = () => {
  return (
    <Translation>
      {t => (
        <Layout>
          <h3>{t('dashboard_theresmore')}</h3>
          <p>{t('dashboard_theresmore_txt')}</p>
          <Button component={Link} primary
            to={'/giveaways'}
            icon="icon-Ico16-ArrowRight-Medium"
          >{t('lets_go')}</Button>
        </Layout>
      )}
    </Translation>
  );
};

export default ThereIsMore;
