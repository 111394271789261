import React from 'react';
import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Button from 'common/MaterialUI/Button';
import { Translation } from 'react-i18next';

export const ModalStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: none;
  outline: none;
  box-shadow: rgba(29, 29, 29, 0.3) 0px 4px 12px 0px;
  max-width: 95vw;
  transform: translate(-50%, -50%) scale(0.8);
  max-height: 95vh;
  overflow: visible;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;



const Controls = styled.div`
  background: #1d1d1d;
  box-shadow: 0 2px 4px 2px rgba(29,29,29,0.05), 0 1px 1px 0 rgba(29,29,29,0.1);
  padding: 0 20px;
  width: 100%;
  height: 66px;
  min-height: 66px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;

  > *:last-child {
    margin-left: 10px;
  }
`

const Layout = styled.div`

width: 100%;
height: 100vh;
max-height: 100vh;
overflow: hidden;

iframe {
  border: 0;
  width: 100%;
  height: 100%;
}
`

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = "Transition"


class PreviewModal extends React.PureComponent {

  render() {
    const targetURL = `/giveaways/preview`;

    const { onRequestClose } = this.props;
    return (
      <Translation>{t => (<Dialog
        fullScreen
        open={this.props.open}
        onClose={onRequestClose}
        TransitionComponent={Transition}
      ><div>
          <Controls>
            <Button href={targetURL} target="_blank"
              rel="noopener noreferrer" outline><span className="icon-Ico16-Login" /><span>{t("Modals/PreviewModal/index.newtab")}</span></Button>
            <Button onClick={onRequestClose} primary><span>{t("Modals/PreviewModal/index.close")}</span><span className="icon-Ico16-X-Medium" /></Button>

          </Controls>
          <Layout><iframe title="GHEED Preview" src={targetURL} frameBorder="0" sandbox="allow-same-origin allow-scripts allow-popups">Browser not compatible.</iframe></Layout>
        </div></Dialog>)}</Translation>
    );
  }
}

export default PreviewModal;
