import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import numeral from 'i18n/numeral';
import ContestTimer from 'common/ContestTimer';

import {
  RAFFLE_STATE_ACTIVE,
  RAFFLE_STATE_ANNOUNCED,
  RAFFLE_STATE_FINISHED,
} from 'helpers/const';
import classNames from 'classnames';
import { helpers } from 'helpers';
import { media } from 'styles';
import Tooltip from 'common/MaterialUI/Tooltip';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';

const Layout = styled.span`
  background: white;
  display: block;

  font-family: Nunito;

  .orange {
    color: #ff8432;
    font-weight: 800;
  }

  .green {
    color: #5bcb8f;
    font-weight: 800;
  }

  .blue {
    color: #4ccbfd;
    font-weight: 800;
  }

  .finished {
    color: #9c9c9c;
  }

  ${media.tabletMax`
    font-size: 11px;
  `};

  [class^='icon-'] {
    margin-right: 10px;
  }
`;

const TimeBasedStatus = ({ t, timer, giveaway, timezone }) => {
  if (timer && timer.mode == RAFFLE_STATE_ACTIVE && timer.time.days > 0) {
    return (
      <span className={classNames(helpers.getTimerColorClasses(timer))}>
        <span className="icon-Ico20-Clock" />
        {t('ga_days_left', { count: timer.time.days })}
      </span>
    );
  }

  if (timer && timer.mode == RAFFLE_STATE_ACTIVE && timer.time.days == 0) {
    return (
      <span className={classNames(helpers.getTimerColorClasses(timer))}>
        <span className="icon-Ico20-Clock" />
        {`${numeral(timer.time.hours).format('00')}:${numeral(
          timer.time.minutes,
        ).format('00')}:${numeral(timer.time.seconds).format('00')}`}
      </span>
    );
  }

  if (timer && timer.mode == RAFFLE_STATE_ANNOUNCED) {
    return (
      <span className={classNames(helpers.getTimerColorClasses(timer))}>
        <span className="icon-Ico16-Start" />

        {moment
          .utc(giveaway.active_from)
          .tz(timezone)
          .format(t("Dashboard/columns/Status.dateFormat"))}
      </span>
    );
  }

  if (timer && timer.mode == RAFFLE_STATE_FINISHED) {
    return (
      <span className={classNames(helpers.getTimerColorClasses(timer))}>
        <span className="icon-Ico16-Check-Medium" />
        {moment
          .utc(giveaway.active_to)
          .tz(timezone)
          .format(t("Dashboard/columns/Status.dateFormat"))}
      </span>
    );
  }

  return <span />;
};

const ToolTipContent = styled.table`
  th {
    padding-right: 5px;
  }
`;

class Status extends React.PureComponent {
  state = { Tooltip: false };

  render() {
    const { giveaway, timezone } = this.props;
    const { ttActive } = this.state;
    return (
      <Translation>{t => <Layout
        onMouseOver={() => this.setState({ ttActive: true })}
        onMouseOut={() => this.setState({ ttActive: false })}
        onClick={() => this.setState({ ttActive: !ttActive })}
      >
        <ContestTimer contest={giveaway}>{(timer) => <Tooltip
          
          title={<ToolTipContent>
            <tbody>
              <tr>
                <th>{t('ga_launch')}:</th>
                <td>
                  {moment
                    .utc(giveaway.active_from)
                    .tz(timezone)
                    .format(t("Dashboard/columns/Status.dateFormat"))}
                </td>
              </tr>
              <tr>
                <th>{t('ga_end')}:</th>
                <td>
                  {moment
                    .utc(giveaway.active_to)
                    .tz(timezone)
                    .format(t("Dashboard/columns/Status.dateFormat"))}
                </td>
              </tr>
            </tbody>
          </ToolTipContent>}>
          <div><TimeBasedStatus t={t} giveaway={giveaway} timezone={timezone} timer={timer} /></div>
        </Tooltip>}
        </ContestTimer>
      </Layout>}</Translation>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    timezone: state.auth.user && state.auth.user.preferences && state.auth.user.preferences.timezone ? state.auth.user.preferences.timezone : moment.tz.guess()
  }
}

export default connect(mapStateToProps)(Status)