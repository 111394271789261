
import { getImagesListRequestAction, IMAGES_LIST_BANNERTEMPLATES, IMAGES_LIST_BANNERS, IMAGES_LIST_BACKGROUNDTEMPLATES, IMAGES_LIST_BACKGROUNDS } from 'rdx/modules/assets';
import { IMAGEASSETTYPE_BANNER, IMAGEASSETTYPE_BACKGROUND } from 'helpers/const';

const _getCreatorImages = (includeTemplates = true) => (dispatch, getState) => {

  dispatch(getImagesListRequestAction([{ isTemplate: false, type: IMAGEASSETTYPE_BANNER }, IMAGES_LIST_BANNERS]))
  dispatch(getImagesListRequestAction([{ isTemplate: false, type: IMAGEASSETTYPE_BACKGROUND }, IMAGES_LIST_BACKGROUNDS]))

  if (includeTemplates) {
    dispatch(getImagesListRequestAction([{ isTemplate: true, type: IMAGEASSETTYPE_BANNER }, IMAGES_LIST_BANNERTEMPLATES]))
    dispatch(getImagesListRequestAction([{ isTemplate: true, type: IMAGEASSETTYPE_BACKGROUND }, IMAGES_LIST_BACKGROUNDTEMPLATES]))
  }

}

export default _getCreatorImages