import React from 'react';
import styled from 'styled-components';
import Section from 'common/Section';
import { media } from 'styles';
import { PageLayout } from 'common/PageLayout';
import { Trans } from 'react-i18next';
import ClaimForm from './ClaimForm';
import YourPrizes from './YourPrizes';
import ClaimBanner from './Banner';

const Styles = styled.div`
  background: #fff;
  box-shadow: 0 4px 12px 0 rgba(29, 29, 29, 0.3);

  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;

  justify-content: flex-start;
  border-radius: 6px;
  max-width: 300px;

  ${media.tablet`
max-width: 630px;
`};
`;

const Notice = styled.div`
  border-top: solid 2px #f0f0f0;
  padding: 20px 40px;
  background: white;
  font-size: 14px;
  text-align: center;

  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const Layout = props => {
  const { claim, error } = props;

  const physical = claim
    ? claim.prizes.filter(prz => !prz.product.virtual).length > 0
    : null;
  const virtual = claim
    ? claim.prizes.filter(prz => prz.product.virtual).length > 0
    : null;

  const claimable = claim ? claim.claimable : false;

  return (
    <PageLayout>
      <Section>
        <Styles>
          <ClaimBanner {...props} />
          {claim && <YourPrizes {...props} />}
          {claim &&
            claim.claiming_mail && (
              <Notice>
                <Trans i18nKey="claims.successfully_claimed_by">
                  <b>
                    Congratulations!
                    <br />
                    These prizes were successfully claimed by{' '}
                    {claim.claiming_mail}
                  </b>
                  <p>
                    If you have any questions, just contact our
                    <a href="mailto:hello@gheed.com">support</a>.
                  </p>
                </Trans>
              </Notice>
            )}
          {claim &&
            !claim.claiming_mail && <ClaimForm {...{ physical, virtual, claimable }} />}
          {error && (
            <Notice>
              <Trans i18nKey="claims.nothing_to_claim">
                <b>Sorry, nothing to claim here. </b>
                <p>
                  If you have any questions, just contact our
                  <a href="mailto:hello@gheed.com">support</a>.
                </p>
              </Trans>
            </Notice>
          )}
        </Styles>
      </Section>
    </PageLayout>
  );
};

export default Layout;
