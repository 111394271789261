import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import View from './View';
import { GIVEAWAYLIST_ENTERED, raffleDynamicListRequestAction } from 'rdx/modules/giveawayLists';

const auth = state => state.auth;
const list = state => state.giveawayLists[GIVEAWAYLIST_ENTERED];

const selectedState = createSelector([auth, list], (auth, list) => {
  return { user: auth.user, ...list };
});

const mapStateToProps = (state, ownProps) => {
  return selectedState(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: user => (page, limit, hasCooldown, hasCoins) => {
      return dispatch(
        raffleDynamicListRequestAction([{
          limit,
          page,
          orderby: 'active_to desc',
          participated: true,
          hasCooldown: hasCooldown,
          hasCoins: hasCoins,
        }, GIVEAWAYLIST_ENTERED]),
      );
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    load: dispatchProps.load(stateProps.user),
  };
};

export const EnteredGiveaways = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(View);

export default EnteredGiveaways;
