import { connectRoutes } from 'redux-first-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth';
import { productsReducer } from './products';
import { modalsReducer } from './modals';
import { rafflesReducer } from './raffles';
import { newsReducer } from './news';
import { statsReducer } from './stats/index';
import { notificationsReducer } from './notifications';
import { claimReducer } from './claim';
import uiReducer from './ui';
import giveawayListsReducer from './giveawayLists';
import assetsReducer from 'rdx/modules/assets';
import productListsReducer from 'rdx/modules/productLists/index';
import couponReducer from './coupons/index';
import creatorReducer from './creator';
import privateProductsReducer from './private_products';
import blogPostsReducer from './blogPosts';
import apiReducer from './api';
import queryString from 'query-string'


export const createRootReducerConfig = (
  routesMap,
  onBeforeRouteChange,
  onAfterRouteChange,
  initialEntries
) => {

  const ConnectRoutes = connectRoutes(routesMap, {
    querySerializer: queryString,
    onBeforeChange: onBeforeRouteChange,
    onAfterChange: onAfterRouteChange,
    initialEntries
  });

  return {
    routeConfig: ConnectRoutes,
    rootReducer: combineReducers({
      rest: apiReducer,
      auth: authReducer,
      news: newsReducer,
      notifications: notificationsReducer,
      raffles: rafflesReducer,
      creator: creatorReducer,
      coupons: couponReducer,
      giveawayLists: giveawayListsReducer,
      productLists: productListsReducer,
      products: productsReducer,
      privateProducts: privateProductsReducer,
      modals: modalsReducer,
      ui: uiReducer,
      form: formReducer,
      location: ConnectRoutes.reducer,
      stats: statsReducer,
      claim: claimReducer,
      assets: assetsReducer,
      blogPosts: blogPostsReducer
    }),
  };
};
