import { createLogic } from 'redux-logic';
import { RESTAPI } from 'rdx/modules/api/rest';
import { GET_WALLETS_LIST_REQUEST, getWalletsListResponseAction } from 'rdx/modules/stats';

const getWalletsListRequestLogic = createLogic({
    type: GET_WALLETS_LIST_REQUEST,

    async process({ getState, action }, dispatch, done) {
        try {
            const resp = await RESTAPI.getWallets(action.payload[0]);
            dispatch(getWalletsListResponseAction([resp, action.payload[1]]));
        } catch (error) {
            console.log(error);
        }
        done();
    },
});

export default getWalletsListRequestLogic;
