import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import numeral from 'i18n/numeral';

import { helpers } from 'helpers';
import ContestTimer from 'common/ContestTimer';

const Layout = styled.span`
  .finished {
    color: #9c9c9c;
  }
`;

const TimerBased = props => {
  const { timer, value } = props;

  return (
    <span className={classNames(helpers.getTimerColorClasses(timer))}>
      {numeral(value).format('0,0')}
    </span>
  );
};

export const Numeric = ({ giveaway, value }) => {
  return (
    <Layout>
      <ContestTimer contest={giveaway} onlyState>{(timer) => <TimerBased {...{ value }} timer={timer} /> }

        
      </ContestTimer>
    </Layout>
  );
};
