import React from 'react';
import { Translation } from 'react-i18next';
import { PageLayout } from 'common/PageLayout';
import VerifyContact from './VerifyContact';
import ContentBox from 'common/ContentBox';
import styled from 'styled-components';
import Button from 'common/MaterialUI/Button';

const Controls = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`

const Layout = ({ handleContinue, isStreamer, loading, detailed, storeSetupRequired, ...props }) => {
  return (
    <Translation>
      {t =>
        <PageLayout centered stretched logoOnly loading={loading}>
          <ContentBox sixthirty title={t("Settings/Setup/View.pleaseverify")}>
            <p>{t("Settings/Setup/View.description")}</p>
            <VerifyContact />
            <Controls><Button onClick={handleContinue} disabled={storeSetupRequired} primary large><span>{t("Settings/Setup/View.savecontinue")}</span><span className="icon-Ico16-ArrowRight-Medium" /></Button></Controls>
          </ContentBox>
        </PageLayout>
      }
    </Translation>
  );
};

export default Layout;
