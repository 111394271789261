import React from 'react';
import { Translation } from 'react-i18next';
import Winners from '../Winners';
import PrizePool from '../PrizePool';
import Entryways from '../Entryways';

import Tabs from '@material-ui/core/Tabs';
import Tab from 'common/MaterialUI/Tab';


class View extends React.PureComponent {
  render() {
    const { setActiveTab, giveawayActiveTab, prizeStats, raffle, preview } = this.props;
    const isComplete =
      prizeStats && prizeStats.prize_count == prizeStats.won_prizes;

    return (
      <Translation>
        {t => (
          <React.Fragment>

            <Tabs variant="fullWidth" textColor="primary"
              value={giveawayActiveTab}
              indicatorColor="secondary"
              onClick={(event, value) => setActiveTab(giveawayActiveTab == 1 ? 2 : 1)}
            >
              <Tab big upper label={t('ga_prizelist')} value={1} />
              <Tab big upper label={t(isComplete ? 'ga_winners' : 'ga_enter_now')} value={2} />
            </Tabs>
            {giveawayActiveTab == 1 && <PrizePool raffle={raffle} preview={preview}  />}
            {giveawayActiveTab == 2 &&
              !isComplete && <Entryways raffle={raffle} />}
            {giveawayActiveTab == 2 &&
              isComplete && <Winners raffle={raffle} />}
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

export default View;
