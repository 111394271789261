import React from 'react';
import {
  MASONRYITEM_TYPE_CONTEST,
  MASONRYITEM_TYPE_BANNER,
  MASONRYITEM_TYPE_PRODUCT,
} from 'helpers/const';
import RafflePreview from 'common/RafflePreview';
import ProductPreview from 'common/ProductPreview';

class Item extends React.Component {
  static getColumnSpanFromProps = (getState, { type }) => {
    return type == MASONRYITEM_TYPE_BANNER ? 2 : 1;
  };

  static getHeightFromProps = (getState, props, columnSpan, columnGutter) => {
    const { type, data } = props;
    if (data.suggestedHeight) {
      return data.suggestedHeight;
    }

    return type == MASONRYITEM_TYPE_PRODUCT ? 375 : 375;
  };

  render() {
    const { type, data } = this.props;

    const InjectableComponent =
      type == MASONRYITEM_TYPE_BANNER ? data.class : null;

    return (
      <div style={this.props.style}>
        {type == MASONRYITEM_TYPE_CONTEST && <RafflePreview raffle={data} />}
        {type == MASONRYITEM_TYPE_PRODUCT && <ProductPreview product={data} />}
        {InjectableComponent && (
          <InjectableComponent suggestedHeight={data.suggestedHeight} />
        )}
      </div>
    );
  }
}

export default Item;
