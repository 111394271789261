import axios from 'axios';
import { JWT } from '../../../helpers/jwt';
import { push } from 'redux-first-router';
import { runtimeConfig } from '../../../runtimeConfig';
import RESTAPI from './rest';

let isRefreshing = false;
let refreshSubscribers = [];
export let COUNTRY_GUESS = 'DE';
export let SERVER_TIME = runtimeConfig.SERVER_TIMESTAMP && new Date(runtimeConfig.SERVER_TIMESTAMP);
export let SERVER_DATE_DIFFERENCE = SERVER_TIME ? new Date().getTime() - SERVER_TIME.getTime() : 0;

const handleSuccess = response => {
  if (response.headers && response.headers['x-country']) {
    COUNTRY_GUESS = response.headers['x-country'];
  }
  if (response.headers && response.headers['date']) {
    const now = new Date()
    const server = new Date(new Date(response.headers['date']).getTime() - ((response.headers['age'] || 0) * 1000))

    if (!SERVER_TIME || (server.getTime() > SERVER_TIME.getTime())) {
      SERVER_TIME = server
      SERVER_DATE_DIFFERENCE = now.getTime() - SERVER_TIME.getTime();
    }
  }
  return Promise.resolve(response);
}

const handleLogout = error => {

  JWT.removeAccessToken()
  JWT.removeRefreshToken()
  push('/');
  if (typeof window != 'undefined') {
    window.location.reload();
  }

  return Promise.reject(error);
};


const handleAccessTokenExpired = (error, refreshToken) => {
  const originalRequest = error.config;

  if (!isRefreshing) {
    isRefreshing = true;

    RESTAPI.getJwtRefresh({ accessToken: refreshToken }).then((response) => {
      while (refreshSubscribers.length) {
        const cb = refreshSubscribers.shift();
        cb.resolve(response.access_token);
      }
      isRefreshing = false;
    }).catch((rError) => {
      while (refreshSubscribers.length) {
        const cb = refreshSubscribers.shift();
        cb.reject(rError);
      }
      isRefreshing = false;
      return handleLogout(rError)
    })
  }

  const retryOriginal = new Promise((resolve, reject) => {
    refreshSubscribers.push({
      resolve: token => {
        originalRequest.headers['Access-Token'] = token;
        return resolve(axios.request(originalRequest));
      }, reject: (error) => reject(error)
    });
  });
  return retryOriginal;
};



const handleError = error => {
  if (
    error.response &&
    error.response.status == 401
  ) {

    JWT.removeAccessToken()

    const refreshToken = JWT.getRefreshToken()

    if (error.response.data.message == 'Access-Token has expired' && refreshToken) {
      return handleAccessTokenExpired(error, refreshToken);
    }
    return handleLogout(error);
  }

  return Promise.reject(error);
}


axios.interceptors.response.use(handleSuccess, handleError)

