import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Layout from './Layout';

const loading = state => state.claim.loading;
const error = state => state.claim.error;
const claim = state => state.claim.data;
const user = state => state.auth.user;

export const getMemoized = createSelector(
  [loading, error, claim, user],
  (loading, error, claim, user) => {
    return {
      loading,
      error,
      claim,
      user,
    };
  },
);

const mapStateToProps = (state, ownProps) => {
  return { ...getMemoized(state) };
};

export const ClaimPrizesContainer = connect(mapStateToProps)(Layout);
export default ClaimPrizesContainer;
