import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Analytics } from 'helpers/analytics';

const disableAnalytics = state => state.ui.disableAnalytics;
const location = state => state.location;
const selectedState = createSelector(
  [disableAnalytics, location],
  (disableAnalytics, location) => ({
    disableAnalytics,
    location,
  }),
);

let lastLocationPath = null;

export const withPageView = WrappedComponent => {
  class Wrapped extends React.PureComponent {
    componentDidMount() {
      const { disableAnalytics, location } = this.props;

      if (!disableAnalytics && location.pathname != lastLocationPath) {
        setTimeout(() => {
          // Wait 0.1 sec for helmet meta updates
          Analytics.pageview(window.location.pathname + window.location.search);
        }, 100);
        lastLocationPath = location.pathname;
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(state => selectedState(state))(Wrapped);
};

export default withPageView;
