import _get from 'lodash/get';
import _set from 'lodash/set';
import Cookies from 'js-cookie';
import { REDUX_COOKIES } from 'helpers/const';

const nullCheck = item => item != null && typeof item != 'undefined'




export const addCookiesToInitialState = (initialState) => {

  const result = { ...initialState }

  Object.keys(REDUX_COOKIES).forEach(path => {

    const cookieName = REDUX_COOKIES[path].name
    const cookieValue = Cookies.getJSON(cookieName)

    if (nullCheck(cookieValue)) {
      _set(result, path, cookieValue)
    }
  })

  return result;
}


const cookieMiddleware = store => next => action => {

  const prevState = store.getState();
  const result = next(action);
  const nextState = store.getState();


  Object.keys(REDUX_COOKIES).forEach(path => {

    const prevValue = _get(prevState, path)
    const nextValue = _get(nextState, path)

    if (prevValue != nextValue) {

      const { name, ...config } = REDUX_COOKIES[path]

      if (nullCheck(nextValue)) {
        Cookies.set(name, nextValue, config)
      } else {
        Cookies.remove(name)
      }
    }

  })


  return result;
};

export default cookieMiddleware;
