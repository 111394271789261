import React, { useEffect } from 'react';
import Button from 'common/MaterialUI/Button'
import Grid from '@material-ui/core/Grid';
import InputField from 'common/InputField';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import { change } from 'redux-form';

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;

const SomeLink = styled.a`
  cursor: pointer;
`

const GeneralError = styled.div`
  margin-top:30px;
  background: rgb(252, 82, 30);
  color: white;
  font-size: 14px;
  padding: 20px 40px;
  border-radius: 6px;
  align-self: center;
  flex: 1 1 auto;
  text-align: center;
  width: 100%;
`;


const View = ({ submitting, handleSubmit, showOverview, showForgotPassword, verificationError, captchaError, resendConfirmation, visibleCaptcha, dispatch }) => {

  useEffect(() => {
    if(visibleCaptcha) {
      dispatch(change('email-login', 'visibleCaptcha', visibleCaptcha));
    }
  }, [visibleCaptcha])

  return <Translation>{t => (<form onSubmit={handleSubmit}>

  <Grid container spacing={3}>
    <Grid item xs={12}><InputField name="email" title={t("Modals/ConnectModal/EmailFlow/Login/View.email")} helperText={verificationError && <SomeLink onClick={resendConfirmation}>{t("Modals/ConnectModal/EmailFlow/Register/View.resend")}</SomeLink>} /></Grid>
    <Grid item xs={12}><InputField name="password" helperText={<SomeLink onClick={showForgotPassword}>{t("Modals/ConnectModal/EmailFlow/Login/View.forgot")}</SomeLink>} type="password" title={t("Modals/ConnectModal/EmailFlow/Login/View.password")} /></Grid>
  </Grid>
  <Controls>
    <Button outline onClick={showOverview}><span className="icon-Ico16-ArrowLeft-Medium" /><span>{t("Modals/ConnectModal/EmailFlow/Login/View.back")}</span></Button>
    <Button primary type="submit" disabled={submitting}>{t("Modals/ConnectModal/EmailFlow/Login/View.submit")}</Button>
  </Controls>
  {captchaError && (<GeneralError className="error">
                {t("Modals/ConnectModal/EmailFlow/Register/View.captchaError")}
              </GeneralError>)}
  </form>)}</Translation>

}
export default View