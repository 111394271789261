import Link from 'redux-first-router-link';
import styled from 'styled-components';
import React from 'react';
import { H5ExtraBoldBlack } from 'styles/Kit/H5ExtraBold';
import { BodySmallRegularBlack } from 'styles/Kit/BodySmallRegular';
import { Translation } from 'react-i18next';


const Container = styled.div`
  max-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 257px;
  max-height: 65px;

  position: absolute;
  top: 250px;
  left: 21px;
  z-index: 2;

  ${H5ExtraBoldBlack}
`


const Title = styled(Link)`
  ${H5ExtraBoldBlack}
`;

const By = styled.p`
  ${BodySmallRegularBlack}
  a {
    ${BodySmallRegularBlack}
  }
`;



const View = ({ product, handleLinkEvent, ...other }) => <Container {...other}>
  <Title draggable="false"
    to={`/create/shop/${product.PRODUCTID}`}
    onMouseUp={handleLinkEvent}
    onClick={handleLinkEvent}
  > {product && product.name}</Title> <By><Translation>{t => t('by')}</Translation> <a href={product.sponsor_link}
    alt={product.sponsor}
    target="_blank"
    rel="noopener noreferrer">{product.sponsor}</a></By></Container>



export default View;
