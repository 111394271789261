import { handleActions, combineActions } from 'redux-actions';
import {
  GET_CLAIM_PRIZE_REQUEST,
  GET_CLAIM_PRIZE_RESPONSE,
  PUT_CLAIM_PRIZE_RESPONSE,
  GET_CLAIM_PRIZE_ERROR,
} from '../api';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const claimReducer = handleActions(
  {
    [GET_CLAIM_PRIZE_REQUEST]: (state, action) => {
      return { ...state, loading: true, error: null };
    },
    [GET_CLAIM_PRIZE_ERROR]: (state, action) => {
      return { ...state, loading: true, error: action.payload };
    },
    [combineActions(GET_CLAIM_PRIZE_RESPONSE, PUT_CLAIM_PRIZE_RESPONSE)]: (
      state,
      action,
    ) => {
      return { ...state, data: action.payload, loading: false };
    },
  },
  initialState,
);

export default claimReducer;
