import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { media } from 'styles';
import numeral from 'i18n/numeral';

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  50% {
    opacity: 1;
  }

  90% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
`;

const animationRule = css`${animation} ${props => props.duration}ms both`;

const Layout = styled.div.attrs(props => ({
  style: {
    animationPlayState: props.paused ? 'paused' : 'running',
  }
}))`
animation: ${animationRule},
  flex: 0 0 auto;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  bottom: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: Nunito;
  font-weight: 800;

  &.paused {
    animation-play-state: paused;
  }
`;

const PlaceNo = styled.div`
  color: white;
  text-shadow: 0 1px 0 rgba(29, 29, 29, 0.8);

  font-size: 16px;

  ${media.tablet`
font-size: 24px;
`};
`;

const Name = styled.div`
  font-size: 20px;
  text-shadow: 0 1px 0 rgba(29, 29, 29, 0.8);
  color: white;
  margin-top: 0;

  ${media.tablet`
  margin-top: 50px;
font-size: 50px;
`};

  &.full {
    font-size: 150px;
  }
`;

const Cup = styled.div`
  position: absolute;
  transform: translateY(-80%);
  left: 0;
  right: auto;
  background: url(/static/assets/images/Slide-Cup.png) center no-repeat;
  background-size: contain;
  width: 100%;
  height: 200px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  padding-top: 31px;
  padding-right: 11px;
  font-size: 2.2em;
  color: #fff050;
  text-shadow: 0 2px 0 #fe531c;

  ${media.tabletMax`
    height: auto;
    min-height: 0;
    background: none;
    padding: 0;
  `};
`;

export class Place extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { rank, name, className, duration, paused } = this.props;

    return (
      <Layout {...{ duration, paused }} className={className}>
        <Cup className={className}>{rank}</Cup>

        <Name className={className}>{name}</Name>
        <PlaceNo className={className}>
          {numeral(rank).format('0o')} PLACE
        </PlaceNo>
      </Layout>
    );
  }
}
