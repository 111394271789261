import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import numeral from 'i18n/numeral';
import { Translation } from 'react-i18next';
import ProductColumn from 'common/Products/ProductColumn'
import Quantity from '../../../../common/Products/Quantity';

const Layout = styled.div`
  padding: 8px 0;

  & + & {
    border-top: solid 2px #f0f0f0;
    margin-top: 0;
  }

  display: flex;
  align-items: center;

  > *:first-child {
    width: 65%;
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1 0 auto;
  ${media.tabletMax`
    flex-direction: column;
    font-size: 11px;
  `};
`;

const Info = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;

  > * {
    margin-left: 10px;

    :first-child {
      margin-left: 20px;

    }
  }
`;
const Price = styled.div`
  display: flex;
  justify-content: flex-end;

  white-space: nowrap;
  color: #36a6fc;
  font-weight: 800;
`;


export const OrderItem = props => {
  const { item } = props;

  return (
    <Translation>
      {t => (
        <Layout>
          <ProductColumn product={item.product} />          <Right>

            <Info>
              <Quantity quantity={item.quantity} />
              <Price>
                €{' '}
                {numeral(
                  parseFloat(item.total) + parseFloat(item.total_tax),
                ).format('0.00')}
              </Price>
            </Info>
          </Right>
        </Layout>
      )}
    </Translation>
  );
};

export default OrderItem;
