import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';

const Layout = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;

  font-weight: 800;
  color: #fabe19;

  img {
    margin-right: 10px;
  }

  img:not(.mobile) {
    display: none;
    max-width: 80%;
  }

  ${media.widescreen`
    img {
      :not(.mobile) {
        display: block;
      }

      &.mobile {
        display: none;
      }
    }
  `};

  .balance {
    min-width: 25px;
    display: inline-block;
    text-align: center;
  }
`;

export const Coin = styled.div`
  width: 100%;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
`;

export class Coins extends React.PureComponent {
  /* componentDidMount() {
    const { balance } = this.props;

    let context = this.canvas.getContext('2d');
    let img = new Image();
    img.src = '/static/assets/images/ico-coins.svg';
    img.onload = () => {
      for (let i = Math.min(10, parseInt(balance)); i > -1; i--) {
        context.drawImage(img, 8 * i, 0, 40, 30);
      }
    };
  } */

  render() {
    const { balance } = this.props;
    const intBalance = Math.floor(parseFloat(balance));

    return (
      <Layout>
        <img alt=""
          src={`/static/assets/images/coins/coin_${
            intBalance < 51 ? intBalance || 1 : '50+'
            }@1x.png`}
          border="0"
        />
        <img alt=""
          className="mobile"
          src={`/static/assets/images/coins/coin_1@1x.png`}
          border="0"
        />
        <span className="balance">{intBalance}</span>
      </Layout>
    );
  }
}
