import { createLogic } from 'redux-logic';
import {
  GET_PRODUCTS_BY_ID_RESPONSE,
  getProductsByIdRequestAction,
 getProductsByIdStatusRequestAction } from '../modules/api';
import { ROUTE_TYPE_PRODUCT } from '../../helpers/const';



const getProductsByIdResponseLogic = createLogic({
  type: GET_PRODUCTS_BY_ID_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      const state = getState();

      // handle stock data fetching for products and variations
      const productsAndVariationIdsWithStock = action.payload.products.reduce((p, c) => {
        let ids = c.manage_stock ? [c.PRODUCTID] : [];
        let vids = c.variations ? c.variations.filter(v => v.manage_stock).map(v => v.PRODUCTID) : [];

        return [...p, ...ids, ...vids];
      }, [])

      if (productsAndVariationIdsWithStock.length) {
        dispatch(getProductsByIdStatusRequestAction({ id: productsAndVariationIdsWithStock }))
      }


      // handle product detail page data fetching for crosssells etc.
      const routeType = state.location.type;
      const mainProductId = getState().location.payload.PRODUCTID;

      if (ROUTE_TYPE_PRODUCT == routeType && mainProductId) {
        const mainProduct = action.payload.products.find(
          p => p.PRODUCTID == mainProductId,
        );

        if (mainProduct) {
          // crosssell products
          const subProductIds = [
            ...mainProduct.crosssellids,
            ...mainProduct.upsellids,
          ];

          if (subProductIds.length) {
            dispatch(getProductsByIdRequestAction({ id: subProductIds }));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    done();
  },
});

export default getProductsByIdResponseLogic;
