import React from 'react';
import styled, { css } from 'styled-components';
import Banner from './Banner';
import { media } from 'styles';
import Animation from './Animation';
import { promotion } from 'helpers/promotion';

const Layout = styled.div`
  
  display: flex;

  width: 100%;
  max-width: 300px;

  ${({ marginTop }) => css`margin-top:${marginTop ? marginTop : 0}px;`}
  ${({ marginBottom }) => css`margin-bottom:${marginBottom ? marginBottom : 0}px;`}


${
  media.tablet`
    max-width: 630px;
  `}

${
  media.desktop`
    max-width: 960px;
  `}

${
  media.widescreen`
    max-width: 1290px;
  `}
`

const CalendarBanner = ({ marginTop, marginBottom, disabled, campaigns, interval }) => {

  if (!campaigns || !Object.keys(campaigns).length) {
    return null
  }
  const BannerRotationStart = promotion.getBannerRotationStart();
  var startbanner = 0;
  if (typeof BannerRotationStart != 'undefined') {
     startbanner = BannerRotationStart < Object.keys(campaigns).length ? BannerRotationStart : 0;
     promotion.setBannerRotationStart(BannerRotationStart + 1 < Object.keys(campaigns).length ? BannerRotationStart + 1 : 0);
  } 
  else {
     promotion.setBannerRotationStart(1 < Object.keys(campaigns).length ? 1 : 0);
  }

  const banners = Object.keys(campaigns).map((key, i) => {
    const campaign = campaigns[key]
    return <Banner key={key} disabled={disabled} name={key} campaign={campaign} />
  })

  return <Layout marginTop={marginTop} marginBottom={marginBottom}>{Object.keys(campaigns).length > 1 ? <Animation interval={interval} startbanner={startbanner}>{banners}</Animation> : banners}</Layout>
}


export default CalendarBanner;