import { connect } from 'react-redux';
import View from './View';
import { modalClearAction } from '../../../store/modules/modals';
import { authRoleChangeRequestAction } from '../../../store/modules/auth';


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleRoleSave: (role) => {
      dispatch(authRoleChangeRequestAction(role))
      dispatch(modalClearAction());
    }

  };
};

export default connect(
  null,
  mapDispatchToProps,
)(View);
