import React from 'react';
import styled from 'styled-components';
import { media, BaseGridBoxMixin } from 'styles';
import { Translation } from 'react-i18next';
import Masonry from 'common/Masonry';
import Banner from './Banner';
import { H2ExtraBoldWhite } from 'styles/Kit/H2ExtraBold';
import utils from 'helpers/utils';
import { ASSET_VARIANT_ORIGINAL, PLATFORMS } from 'helpers/const';
import Button from 'common/MaterialUI/Button';
import EmptyBox from 'common/EmptyBox';

const Image = styled.figure`

background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  height: 80px;
  min-width: 80px;
  min-height: 80px;

  border-radius: 3px;
  box-shadow: 0 15px 25px 5px rgba(0, 0, 0, 0.5);
  border: solid 4px #fff;

  flex: 0 0 auto;

  ${media.desktop`
    display: flex;
  `};
`;


const Name = styled.div`

  ${H2ExtraBoldWhite}
  
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3), 1px -1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.3), -1px -1px 1px rgba(0,0,0,0.3);

  font-size: 25px;
  margin-top: 20px;
`


const Layout = styled.div`
  width: 100%;
  position: relative;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 66px;
  align-items: center;
}
`;

const List = styled.div`

  margin: 60px 0;
  ${BaseGridBoxMixin}

`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(-50%);
   
  > * {
    box-shadow: 0 2px 4px 2px rgba(32, 32, 34, 0.05), 0 1px 1px 0 rgba(32, 32, 34, 0.1);
  
    :not(:first-child) {
      margin-left: 10px;
    }
  }
`



class View extends React.Component {

  render() {
    const { user, raffles, scrollComplete, loading, handleScrolledToBottom, loaded } = this.props;

    const imgUrl =
      user && user.pic ? user.pic : "/static/assets/images/platzhalter-avatar.jpg";

    const bannerUrl =
      user && user.publicprofile && user.publicprofile.banner && user.publicprofile.banner.uuid ? utils.resolveImageAsset(user.publicprofile.banner, ASSET_VARIANT_ORIGINAL) : "/static/assets/images/platzhalter-publicprofile.jpg";



    return (
      <Translation>
        {t => (
          <Layout>
            <Banner url={bannerUrl}><Image img={imgUrl} /><Name>{user && user.username}</Name></Banner>
            <Buttons>{user && user.publicprofile && user.publicprofile && Object.keys(user.publicprofile).map((key) => {

              const val = user.publicprofile[key];
              const platform = Object.values(PLATFORMS).find(p => p.link == key)

              if (key.indexOf("_link") != -1 && val && platform) {
                return <Button target="_blank"
                  rel="noopener noreferrer" key={key} href={val} outline nomargin nopadding ><span style={{ color: platform.color }} className={platform.icon} /></Button>
              }
              return null;
            })}

            </Buttons>
            {loaded && raffles && raffles.length == 0 ? <EmptyBox noBorder transparent dark>{t("Streamers/Detail/View.empty")}</EmptyBox> : <List>
              <Masonry
                items={raffles}
                hasMore={!scrollComplete}
                isLoading={loading}
                onInfiniteLoad={handleScrolledToBottom}
                getState={() => this.state}
              />
            </List>}
          </Layout>
        )}
      </Translation>
    );
  }
}

export default View