import React, { useRef, useState, useEffect } from 'react';
import { H6ExtraBoldBlack } from '../../../styles/Kit/H6ExtraBold';
import styled from 'styled-components';
import { BodySmallRegularBlue } from '../../../styles/Kit/BodySmallRegular';

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 20px;
  }
`

const Upper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
`

const Copy = styled.div`
  ${BodySmallRegularBlue}
  cursor: pointer;
  margin-left: 10px;

  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
`


const Title = styled.div`
${H6ExtraBoldBlack}
`

const Grey = styled.div`
  border-radius: 3px;
  padding: 10px;
  border: solid 1px ${({ active }) => active ? '#36a6fc' : '#e1e2ef'};
  background-color: #f8f9fb;
  word-break: break-all;

`


const ClipBox = ({ title, details, children, disabled }) => {

  const [clicked, setClicked] = useState(false);
  useEffect(() => {

    let timeout;

    if (clicked) {
      timeout = setTimeout(() => {
        setClicked(false)
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [clicked])

  const copyable = useRef(undefined);

  const copyToClipBoard = () => {

    let range = document.createRange();
    range.selectNode(copyable.current);
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges();// to deselect

    setClicked(true)
  }

  return <Layout><Upper><Title>{title}</Title>{!disabled && <Copy onClick={copyToClipBoard}>Copy link <span className="icon-Ico20-Clipboard"></span></Copy>}</Upper><Grey active={clicked} ref={copyable}>{children}</Grey>{details && details()}</Layout>
}

export default ClipBox;