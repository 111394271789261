import React from 'react';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import Button from 'common/MaterialUI/Button';
import { Translation } from 'react-i18next';
import { media } from '../../styles';

const Layout = styled.div.attrs(props => {
  return {
    style: {
      background: props.background
    }
  }
})`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 20px;
`;

const SliderRowTitle = styled.div`
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  margin-right: 20px;

  ${media.tabletMax`
  font-size: 16px;
  `};
`;

const Top = styled.div`
  display: flex;
  align-items: center;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  position: relative;
`;

const Inner = styled.div`
  width: 300px;
  max-width: 300px;

  ${media.tablet`
width: 630px;
max-width: 630px;
`};

  ${media.desktop`
width: 960px;
max-width: 960px;
`};

  ${media.widescreen`
width: 1290px;
max-width: 1290px;
`};
`;


const Wrapper = ({ title, to, children, background }) => {
  return (
    <Translation>
      {t => (
        <Layout background={background}>
          <Inner>
            {title && (
              <Top>
                <SliderRowTitle>{t(title)}</SliderRowTitle>
                {to && <Button outline small component={Link} to={to}>
                  <span>{t('see_all')}</span>
                  <span className="icon-Ico16-ArrowRight-Small" />
                </Button>}
              </Top>
            )}{' '}
          </Inner>
          <Bottom>{children}</Bottom>
        </Layout>
      )}
    </Translation>
  );
};

export default Wrapper;
