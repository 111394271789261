import { compose } from 'redux';
import View from './View';
import { connect } from 'react-redux';
import isAuthed from 'rdx/selectors/isAuthed';



const mapStateToProps = (state, ownProps) => {
  return {
    isAuthed: isAuthed(state),
    error: state.location.query && state.location.query.error,
  }
}


const enhance = compose(
  connect(mapStateToProps),
);



export default enhance(View);

