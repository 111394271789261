import React from 'react';
import styled from 'styled-components';
import { H6ExtraBoldRed } from 'styles/Kit/H6ExtraBold';
import classNames from 'classnames';
import { green, red } from '../../styles/Kit/colors';
import Tooltip from 'common/MaterialUI/Tooltip';
import { connect } from 'react-redux';

import { Translation } from 'react-i18next';
import { parseRegion } from 'helpers/regions';
import { REGION_SEPARATOR, REGION_NEGATION_SEPARATOR, REGION_DEFINITION_EU } from 'helpers/const';



const mapRegion = r => {

  let mapped = r.split(REGION_NEGATION_SEPARATOR)
  const label = mapped[0].replace("!", "") + (mapped.length > 1 && mapped[1].length ? '*' : '')
  return label
}


const Regions = styled(({ product, className }) => {

  if (!product) {
    return null;
  }

  const regions = product && product.region ? product.region.split(REGION_SEPARATOR).filter(r => r.indexOf("WW!") == -1) : null;
  const visibleRegions = regions && regions.length ? [regions[0]] : null;

  const isPartiallyWorldWide = product.region && product.region.match(/WW!.+/)
  const isWorldWide = product.region && product.region.match(/WW!$/)



  return <Translation>{t => <Tooltip title={`${t('products_conditions_regional_txt')} ${REGION_DEFINITION_EU == product.region ? t("common.Products.Regions.stateseuropeanunion") : (product.region && parseRegion(product.region).join(', '))}`}>
    <div className={className}>
      <span className={classNames("icon-Ico16-Globe", { green: isWorldWide })} />
      {'  '}
      {(isWorldWide || isPartiallyWorldWide) && <React.Fragment><span className={classNames("icon-Ico16-Check-Medium", { green: isWorldWide })} />{isPartiallyWorldWide && '*'}</React.Fragment>}
      {(!isWorldWide && !isPartiallyWorldWide) && visibleRegions.map(mapRegion).join(", ")}
      {visibleRegions && regions.length > 1 && ` + ${regions.length - 1}`}
    </div></Tooltip>
  }</Translation>
})`

[class^='icon-'] {
  color: ${red} !important;
  &.green {
    color: ${green} !important;
  }

}

.icon-Ico16-Globe {
  margin-right: 5px;
  margin-bottom: 2px;
}
  
  height: 24px;
  border-radius: 12px;
  background-color: #ffffff;
  border: solid 1px #e1e2ef;

  padding: 0 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${H6ExtraBoldRed}
`;


const mapStateToProps = (state, ownProps) => {

  const { shop_id, shopproduct_id, shopvariation_id, product, variation } = ownProps;

  const getProduct = product ? product : shop_id == 2 ? state.products.products && state.products.products[shopproduct_id] : state.privateProducts.data[shopproduct_id];
  const getVariation = variation ? variation : shop_id == 2 && shopvariation_id && product && product.variations.find(v => v.PRODUCTID == shopvariation_id)

  return {
    product: getProduct,
    variation: getVariation
  }
}

export default connect(mapStateToProps)(Regions)