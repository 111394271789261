import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';

export const Layout = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background-color: #ffffff;
  border: solid 2px #f0f0f0;
  position: relative;

  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;

  :before {
    content: ' ';
    background: url(/static/assets/images/ico-arrow.svg) no-repeat;
    position: absolute;
    width: 100px;
    height: 30px;
    pointer-events: none;

    right: -25px;
    top: 10px;
    transform: rotate(90deg);
    background-size: 50%;

    ${media.tablet`
      transform: rotate(0);
      right: auto;
      left: -53px;
      top: 15px;
      background-size: auto auto;
    `};
  }

  ${media.tablet`
    width: 306px;
    height: 60px;
    justify-content: center;
    padding-left: 0px;
  `};
`;

export const Label = styled.h2`
  color: #4ccbfd;
  font-size: 16px;

  ${media.tablet`
    font-size: 20px;
  `};
`;

export const Entries = props => {
  const { earned, bonus, spending } = props;

  const totalAmount = earned + spending;

  const bigBonusAmount = Math.floor(spending / 50) * 10;
  const smallBonusAmount = Math.floor((spending % 50) / 10);

  const bonusAmount = bonus + bigBonusAmount + smallBonusAmount;

  return (
    <Layout>
      <Label>
        {totalAmount}
        {bonusAmount ? ` (+${bonusAmount})` : ''} Entries
      </Label>
    </Layout>
  );
};
