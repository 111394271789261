import throttle from 'lodash/throttle';

let LAST_SCROLL_TOP = 0;

export const getDocumentScrollInfo = () => {
  var html = document.documentElement;
  const scrollTop =
    html.scrollTop || (document.body ? document.body.scrollTop : 0);
  const scrollDirection =
    scrollTop > LAST_SCROLL_TOP
      ? 'down'
      : scrollTop < LAST_SCROLL_TOP
        ? 'up'
        : '';
  LAST_SCROLL_TOP = scrollTop;
  return {
    element: html,
    clientHeight: html.clientHeight,
    scrollHeight: html.scrollHeight,
    scrollTop: scrollTop,
    scrollDirection: scrollDirection,
  };
};

const scrollListeners = [];
const onScroll = throttle(event => {
  const info = getDocumentScrollInfo();

  scrollListeners.forEach(listener => {
    try {
      listener(info);
    } catch (error) {
      console.log(error);
    }
  });
}, 200);

if (typeof window != 'undefined') {
  window.addEventListener('scroll', onScroll, { passive: true });
}

export const addScrollListener = (listener, runInitially) => {
  scrollListeners.push(listener);
  if (runInitially) {
    try {
      listener(getDocumentScrollInfo());
    } catch (error) {
      console.log(error);
    }
  }
};

export const removeScrollListener = listener => {
  let i = scrollListeners.indexOf(listener);
  if (i != -1) {
    scrollListeners.splice(i, 1);
  }
};
