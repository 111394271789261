import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';

import Stepper from 'common/MaterialUI/Stepper';
import Step from '@material-ui/core/Step';
import { Translation } from 'react-i18next';
import Button from 'common/MaterialUI/Button';
import StepLabel from 'common/MaterialUI/StepLabel';
import { CircularProgress } from '@material-ui/core';

const Wrapper = styled.div`
  background: white;
  
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);
  }

  padding: 0 20px;


${media.widescreen`
padding: 0;

`}
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  width: 300px;
  max-width: 300px;

  ${media.tablet`
  
  width: 630px;
  max-width: 630px;
`};

  ${media.tablet`
  max-width: 630px;
  `};

  ${media.desktop`
  max-height: 66px;
  flex-direction: row;
  justify-content: space-between;
  width: 960px;
  max-width: 960px;
`};

${media.widescreen`
  width: 1290px;
  max-width: 1290px;
`};



  
`;


const Buttons = styled.div`
  display: flex;
  > button + button {
    margin-left: 10px;
  }

  ${media.desktopMax`
  margin-bottom: 20px;
`};
`
class SubmenuCreator extends React.PureComponent {
  render() {

    const { isLastEditStep, hasPrizes, handleCheckout, handleNext, handlePrevious, handlePreview, activeStep, busy, edit, storeSetupRequired, mode, blockCheckout } = this.props;

    return <Translation>{(t) => (<Wrapper><InnerWrapper>
      <Stepper activeStep={activeStep || 0}>
        {<Step>
          <StepLabel>{t('creator.Prizes')}</StepLabel>
        </Step>}
        <Step>
          <StepLabel>{t('creator.Details')}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t('creator.Entry Ways')}</StepLabel>
        </Step>
        {!edit && <Step>
          <StepLabel>{t('creator.Promotion')}</StepLabel>
        </Step>}
        {!edit && <Step>
          <StepLabel>{t('creator.Summary')}</StepLabel>
        </Step>}
      </Stepper>
      <Buttons>
        {handlePrevious && <Button outline
          onClick={handlePrevious}
        ><span className="icon-Ico16-ArrowLeft-Medium" /><span>{t('previous')}</span></Button>}
        {mode != "setup" && !storeSetupRequired && handlePreview && <Button outline
          onClick={handlePreview} disabled={!hasPrizes}
        ><span className="icon-Ico16-Views" /><span>{t('preview')}</span></Button>}
        {handleNext && <Button primary={!isLastEditStep} secondary={isLastEditStep} disabled={!hasPrizes || storeSetupRequired || busy}
          onClick={handleNext}
        ><span>{isLastEditStep ? t('save_exit') : t('next')}</span>{busy ? <CircularProgress style={{ marginLeft: '5px', color: 'white' }} size={16} /> : isLastEditStep ? undefined : <span className="icon-Ico16-ArrowRight-Medium" />}</Button>}
        {handleCheckout && <Button secondary disabled={storeSetupRequired || busy || blockCheckout} id="doCheckout"
          onClick={handleCheckout}
        ><span>{t('checkout')}</span>{busy && <CircularProgress style={{ marginLeft: '5px', color: 'white' }} size={16} />}</Button>}
      </Buttons>
    </InnerWrapper>
    </Wrapper>)}</Translation>
  }
}

export default SubmenuCreator