import { compose } from 'redux';
import View from './View';
import withForm from 'hoc/withForm';
import { connect } from 'react-redux';
import { RESTAPI } from 'rdx/modules/api/rest';
import { SubmissionError } from 'redux-form';

const validate = values => {
  const errors = {};

  if (!values.new_password) {
    errors.new_password = 'forms.validation_password';
  }

  if (!values.new_passwordconfirm) {
    errors.new_passwordconfirm = 'forms.validation_password';
  }

  if (values.new_password != values.new_passwordconfirm) {
    errors.new_passwordconfirm = 'forms.validation_password_differs';
  }

  return errors;
};



const onSubmit = (values, dispatch) => {

  return RESTAPI.putEmailPwReset({ payload: values }).then((r) => {
    return r
  }).catch(error => {
    throw new SubmissionError(error && error.response && error.response.data)
  })

}


export const onSubmitFail = (errors, dispatch, submitError, form) => {
  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input[type=text].invalid, textarea.invalid, select.invalid, input[type=checkbox].invalid, div.invalid',
    );
    if (firstErrorNode) {
      firstErrorNode.focus();
    }
  }, 50);
};

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      new_password: null,
      verification_token: state.location.query && state.location.query.verification_token,
    },
    subject: state.form.contactus && state.form.contactus.values && state.form.contactus.values.subject
  }
}


const enhance = compose(
  connect(mapStateToProps),
  withForm({
    form: 'email-forgot',
    validate,
    onSubmit,
    onSubmitFail,
    destroyOnUnmount: true,
  }),
);



export default enhance(View);

