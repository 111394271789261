import React from 'react';
import styled from 'styled-components';
import { PageLayout } from 'common/PageLayout';
import { Helmet } from 'react-helmet';
import { media } from '../styles';
import { Translation } from 'react-i18next';

const Layout = styled.div`
  flex: 1 0 auto;
  max-width: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30%;
`;

const InnerBox = styled.div`
  width: 410px;

  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);
  padding: 20px 35px 35px;
  text-align: center;

  img {
    width: 60%;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  ${media.tabletMax`
    width: 300px;
    font-size: 11px;
  `};
`;

const NotFound = () => (
  <Translation>{t => <PageLayout>
    <Helmet title={`404 ${t('404_title')} @ GHEED`} />
    <Layout>
      <InnerBox>
        <img alt="" src="/static/assets/images/GIF-2.gif" border="0" />
        <h3>{t('404_title')}</h3>
        {t('404_desc')}
      </InnerBox>
    </Layout>
  </PageLayout>}</Translation>
);

NotFound.displayName = 'NotFound';

export default NotFound;
