import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Layout = styled.td`
  :first-child {
    word-break: break-word;
  }

  :not(:first-child) {
    white-space: nowrap;
  }

  :last-child {
    text-align: right;
  }

  :not(:last-child) {
    padding-right: 10px;
  }

  :first-child {
    max-width: 33%;
  }
  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom: solid 2px #f0f0f0;

  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
`;

class CollapsingTd extends React.PureComponent {
  render() {
    const { children, active } = this.props;
    return <Layout className={classNames({ active })}>{children}</Layout>;
  }
}
export default CollapsingTd;
