import React from 'react';
import styled from 'styled-components';
import RaffleSlider from 'common/RaffleSlider';
import { Translation } from 'react-i18next';
import Button from 'common/MaterialUI/Button';
import Link from 'redux-first-router-link';
import CampaignBanner from 'components/Campaign/Banner';

const Layout = styled.div`
  width: 100%;
  position: relative;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background-size: cover;

  justify-content: flex-start;
  align-items: center;
  
  margin-bottom: 60px;
  padding-top: 66px;
`;




const SliderLayout = styled.div`
  margin-top: 20px;
  width: 100%;

  .slider {
    height: 420px;
  }
`;


const ButtonHolder = styled.div`
margin-bottom: 20px;

`

const LearnMore = styled(Link)`
  display: flex;
  align-items: center;
`


class View extends React.PureComponent {
  render() {

    return (
      <Translation>
        {t => (
          <Layout>
            <div className="ccc-homepage-banner">
              <div className="ccc-homepage-container">
                <h1 className="ccc-h1">{t("Home/View.desc")}</h1>
                <h4 className="ccc-h4"><LearnMore to="/quicktour"><span>{t("Home/View.learn")}</span>&nbsp;<span className="icon-Ico16-ArrowRight2" /></LearnMore></h4>
              </div>
            </div>
            <CampaignBanner placement="home" marginTop={40} marginBottom={20} />
            <SliderLayout>
              <RaffleSlider
                list="home"
                config={{
                  limit: 20,
                  page: 1,
                  mode: 'preview',
                  filterPath: 'active',
                  orderbyPath: 'mostpopular',
                  featuredIncluded: true
                }}
              />
            </SliderLayout>
            <ButtonHolder> <Button primary large component={Link} to="/giveaways">{t("Home/View.moregiveaways")} </Button></ButtonHolder>
          </Layout>
        )}
      </Translation>
    );
  }
}

export default View;
