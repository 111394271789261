import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Tooltip from 'common/MaterialUI/Tooltip';
import { media } from 'styles';
import { helpers } from 'helpers';
import Link from 'redux-first-router-link';
import { Translation } from 'react-i18next';
import ContestTimer from '../../../common/ContestTimer';
import { EVENTTYPEID_PRIVATE } from '../../../helpers/const';
import { BodySmallRegularGrey } from 'styles/Kit/BodySmallRegular';

const Layout = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;

const ContestTitle = styled(Link)`
  display: block;

  text-overflow: ellipsis;
  overflow: hidden;
  color: #1d1d1d;

  &.orange {
    color: #ff8432;
  }

  &.green {
    color: #5bcb8f;
  }

  &.blue {
    color: #4ccbfd;
  }

  font-family: Nunito;
  font-weight: 800;
  font-size: 14px;
  padding-right: 10px;

  ${media.desktopMax`
  font-size: 11px;
  `};
`;

const Thumb = styled.div.attrs(props => {
  return { style: { backgroundImage: `url(${props.url})` } }
})`
  width: 35px;
  height: 28px;
  border-radius: 2px;

  margin-right: 8px;
  padding-left: 15px;
  background-clip: padding-box;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  flex: 0 0 auto;

  ${media.tabletMax`
    display: none;
  `};
`;

const Host = styled.div`
  font-size: 11px;
`;

const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  overflow: hidden;
`;


const PrivateLayout = styled.span`
  ${BodySmallRegularGrey}
  margin-left: 8px;
`

const Private = ({ t }) => (<Tooltip title={t("Raffles/Detail/MainBox/Footer/View.tooltip")}><PrivateLayout>[{t("Dashboard/columns/Title.privateLabel")}]</PrivateLayout></Tooltip>)

const TimerBasedTitle = props => {
  const { giveaway, timer, showHost, t } = props;

  return (
    <Wrapper>
      <ContestTitle
        to={`/giveaways/${giveaway.EVENTID}`}
        className={classNames(helpers.getTimerColorClasses(timer))}
      >
        {giveaway.eventname}
        {giveaway.EVENTTYPEID == EVENTTYPEID_PRIVATE && <Private t={t} />}
      </ContestTitle>
      {showHost && (
        <Host>
          {t('by')}{' '}
          {giveaway.host_info.website ? (
            <a
              href={giveaway.host_info.website}
              draggable="false"
              target="_blank"
              rel="noopener noreferrer"
            >
              {giveaway.host_info.username}
            </a>
          ) : (
              <Link to={`/streamers/${giveaway.host_info.USERID}`}>
                {giveaway.host_info.username}
              </Link>
            )}
        </Host>
      )}
    </Wrapper>
  );
};

export const Title = ({ giveaway, showHost }) => {
  const rafflePic =
    giveaway.eventpic &&
      giveaway.eventpic.variants &&
      giveaway.eventpic.variants.length
      ? giveaway.eventpic.variants[0].url
      : `http://via.placeholder.com/260x208`;

  return (
    <Translation>
      {t => (
        <Layout>
          <Thumb url={rafflePic} />

          <ContestTimer contest={giveaway} onlyState>{(timer) =>  <TimerBasedTitle {...{ giveaway, showHost, t, timer }} />}

           
          </ContestTimer>
        </Layout>
      )}
    </Translation>
  );
};
