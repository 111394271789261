import { RAFFLE_STATE_FINISHED } from 'helpers/const';
import { getDrawStatusByRaffleIdRequestAction, getWinnersByRaffleIdRequestAction } from 'rdx/modules/api';

let blocked = 0;
const BLOCKINTERVAL = 300; //Seconds

export const updateOpenContestOnFinishedThunk = data => async (
  dispatch,
  getState,
) => {
  const state = getState();
  //const user = state.auth.user;

  const openRaffleEventId = state.location.payload.EVENTID;

  if (openRaffleEventId) {
    const currentTimer = data[openRaffleEventId];

    if (currentTimer) {
      if (currentTimer.mode == RAFFLE_STATE_FINISHED && blocked <= 0) {
        const prizeStats = state.raffles.prizeStats[openRaffleEventId];
        const winners = state.raffles.winners[openRaffleEventId];
        const ranks = state.raffles.prizes[openRaffleEventId] ? state.raffles.prizes[openRaffleEventId].reduce((acc, curr) => {
          return Math.max(curr.last_rank, acc)
        }, 0) : 0

        if (
          prizeStats &&
          prizeStats.won &&
          (!winners || winners.length < ranks)
        ) {
          dispatch(getWinnersByRaffleIdRequestAction({ id: openRaffleEventId }));
          blocked = BLOCKINTERVAL;
          return;
        }

        if (
          !prizeStats ||
          (prizeStats && prizeStats.won_prizes < prizeStats.prize_count)
        ) {
          dispatch(getDrawStatusByRaffleIdRequestAction({ id: openRaffleEventId }));
          dispatch(getWinnersByRaffleIdRequestAction({ id: openRaffleEventId }));
          blocked = BLOCKINTERVAL;
        }
      }
      blocked--;
    }
  }
};
