import { handleActions } from 'redux-actions';
import { GET_RAFFLE_BY_ID_COUPONS_RESPONSE, GET_RAFFLE_BY_ID_COUPONS_STATUS_RESPONSE } from '../api';

const initialState = {
  coupons: {},
  couponstatus: {}
};

const couponReducer = handleActions(
  {
    [GET_RAFFLE_BY_ID_COUPONS_RESPONSE]: (state, action) => {
      if (!action.payload.coupons.length) {
        return { ...state };
      }
      return { ...state, coupons: { ...state.coupons, [action.payload.EVENTID]: action.payload.coupons } }
    },
    [GET_RAFFLE_BY_ID_COUPONS_STATUS_RESPONSE]: (state, action) => {
      const mapped = action.payload && action.payload.coupons ? action.payload.coupons.reduce((p, c) => {
        return { ...p, [c.COUPONINFOID]: c.couponvalue }
      }, {}) : {}
      return { ...state, couponstatus: { ...state.couponstatus, ...mapped } }
    },
  },
  initialState,
);

export default couponReducer;
