import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';
import { withForm } from 'hoc/withForm';
import { compose } from 'redux';
import InputField from 'common/InputField';
import { CheckboxField } from 'common/CheckboxField';
import { media } from 'styles';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';
import { Trans, Translation } from 'react-i18next';
import { postBetaApplyRequestAction } from 'rdx/modules/api';
import { REGEX_EMAIL } from 'helpers/const';

const Content = styled.div`
  > p {
    margin-bottom: 20px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;

const Blue = styled.h2`
  color: #4ccbfd;

  & + & {
    margin-top: 10px;
  }

  p {
    font-size: 11px;
    margin-left: 5px;
    margin-bottom: 0;
    ${media.tablet`
    font-size: 16px;
    
  `};
  }

  display: flex;
  align-items: center;
`;

const FormArea = styled.div`
  margin-top: 20px;
  border-top: solid 2px #f0f0f0;
  padding-top: 30px;
`;

const Terms = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 10px;
  }

  ${media.tablet`
    flex-direction: row;
    > * + * {
      margin-top: 0;
      margin-left: 20px;
    }
  `};
`;

class PartnerApplyModal extends React.PureComponent {
  render() {
    const { handleSubmit } = this.props;
    return (
      <Modal {...this.props}>
        <Translation>
          {t => (
            <ContentBox bigmodal title={t('modals.signup.title')}>
              <form onSubmit={handleSubmit}>
                <Content>
                  <Trans i18nKey="modals.signup.body">
                    <p>
                      We are looking for active streamers with at least 50 average viewers who are interested in a partnership. Advantages:
                    </p>
                    <Blue>
                      <span className="icon-Ico16-Check-Big" />
                      <p>Regularly sponsored giveaways that you can choose yourself</p>
                    </Blue>
                    <Blue>
                      <span className="icon-Ico16-Check-Big" />
                      <p>
                        Discount in our price shop
                      </p>
                    </Blue>
                    <Blue>
                      <span className="icon-Ico16-Check-Big" />
                      <p>Special promotions with our product sponsors</p>
                    </Blue>
                  </Trans>
                  <FormArea>
                    <Fields>
                      <InputField
                        name="twitch_username"
                        title={t('forms.your_twitch_account')}
                      />
                      <InputField name="email" title={t('forms.your_email')} />
                    </Fields>

                    <Terms>
                      <CheckboxField name="accept_terms">
                        <Trans i18nKey="forms.confirm_storedata">
                          Please tick to consent to your data being stored in
                          line with our guidelines set out in our
                          <Link to="privacy">privacy policy</Link>.
                        </Trans>
                      </CheckboxField>
                    </Terms>
                  </FormArea>
                </Content>
                <Controls>
                  <Button primary type="submit">OK, Sign me up!</Button>
                </Controls>
              </form>
            </ContentBox>
          )}
        </Translation>
      </Modal>
    );
  }
}

const validate = values => {
  const errors = {};

  if (!values.twitch_username) {
    errors.twitch_username = 'forms.validation_twitch_accname';
  }

  if (!values.email || !REGEX_EMAIL.test(values.email)) {
    errors.email = 'forms.validation_email';
  }

  if (!values.accept_terms) {
    errors.accept_terms = 'forms.validation_terms';
  }



  return errors;
};

const onSubmit = (values, dispatch) => {
  dispatch(postBetaApplyRequestAction({ payload: values }))

};

export const onSubmitFail = (errors, dispatch, submitError, form) => {
  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input[type=text].error, textarea.error, select.error, input[type=checkbox].error, div.validationError',
    );
    if (firstErrorNode) {
      firstErrorNode.focus();
    }
  }, 50);
};

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      email:
        state.modals && state.modals.data ? state.modals.data.useremail : null,
      twitch_username:
        state.modals && state.modals.data ? state.modals.data.username : null,
      accept_terms: false,
      captcha_response: null,
    },
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withForm({
    form: 'partnerapply',
    captcha: "apply",
    validate,
    onSubmit,
    onSubmitFail,
    destroyOnUnmount: true
  }),
);

export default enhance(PartnerApplyModal);
