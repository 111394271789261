import { createLogic } from 'redux-logic';
import { push } from 'redux-first-router';
import {
  GET_RAFFLE_BY_ID_EDIT_ERROR,
} from '../modules/api';



const getRaffleByIdEditErrorLogic = createLogic({
  type: GET_RAFFLE_BY_ID_EDIT_ERROR,

  process({ getState, action }, dispatch, done) {
    try {
      const state = getState();
      if (state.location.payload.EVENTID) {
        push(`/giveaways/${state.location.payload.EVENTID}`)
      }
    } catch (error) {
      console.log(error);
    }

    done();
  },
});

export default getRaffleByIdEditErrorLogic;
