import { createAction, handleActions, combineActions } from 'redux-actions';
import {
  GET_USERS_SETTINGS_REQUEST, PUT_USERS_SETTINGS_REQUEST, PUT_USERS_SETTINGS_REFRESH_REQUEST, PUT_USERS_SETTINGS_REFRESH_RESPONSE,
  PUT_USERS_SETTINGS_RESPONSE,
  GET_USERS_SETTINGS_RESPONSE,
  POST_BILLING_RESPONSE
} from '../api';

export const AUTH_LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
export const AUTH_LOGIN_ERROR = 'auth/LOGIN_ERROR';
export const AUTH_LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const AUTH_LOGOUT_ERROR = 'auth/LOGOUT_ERROR';
export const AUTH_LOGIN_WITH_OAUTH = 'auth/LOGIN_WITH_OAUTH';

export const loginRequestAction = createAction(AUTH_LOGIN_REQUEST);
export const loginSuccessAction = createAction(AUTH_LOGIN_SUCCESS);
export const loginErrorAction = createAction(AUTH_LOGIN_ERROR);
export const logoutRequestAction = createAction(AUTH_LOGOUT_REQUEST);
export const loginWithOAuthAction = createAction(AUTH_LOGIN_WITH_OAUTH);

export const AUTH_ROLE_CHANGE_REQUEST = 'auth/ROLE_CHANGE_REQUEST';
export const AUTH_ROLE_CHANGE_SUCCESS = 'auth/ROLE_CHANGE_SUCCESS'
export const AUTH_ROLE_CHANGE_ERROR = 'auth/ROLE_CHANGE_ERROR';
export const authRoleChangeRequestAction = createAction(AUTH_ROLE_CHANGE_REQUEST);
export const authRoleChangeResponseAction = createAction(AUTH_ROLE_CHANGE_SUCCESS);
export const authRoleChangeErrorAction = createAction(AUTH_ROLE_CHANGE_ERROR);



export const initialState = {
  user: null,
  loading: false,
};

const authReducer = handleActions(
  {
    [POST_BILLING_RESPONSE]: (state, action) => {
      const { user } = state;

      return {
        ...state,
        user: user ? { ...user, billing_address: action.payload } : null
      }
    },

    [combineActions(GET_USERS_SETTINGS_REQUEST, PUT_USERS_SETTINGS_REQUEST, PUT_USERS_SETTINGS_REFRESH_REQUEST)]: (state, action) => ({
      ...state,
      loading: true
    }),
    [combineActions(PUT_USERS_SETTINGS_RESPONSE, GET_USERS_SETTINGS_RESPONSE, PUT_USERS_SETTINGS_REFRESH_RESPONSE)]: (state, action) => ({
      ...state,
      user: action.payload,
      loading: false
    }),
  },
  initialState,
);

export default authReducer;
