import { RaffleDetailContainer } from 'components/Raffles/Detail';
import RafflesCategoryContainer from 'components/Raffles/Category';
import { ClaimPrizesContainer } from 'components/ClaimPrizes';
import { ROUTE_TYPE_GIVEAWAYS, ROUTE_TYPE_GIVEAWAY, ROUTE_TYPE_CLAIM, ROUTE_TYPE_GIVEAWAY_PREVIEW, ROUTE_TYPE_GIVEAWAY_EMBED } from '../helpers/const';
import Preview from '../components/Raffles/Preview/';

export const RaffleRoutes = {
  [ROUTE_TYPE_GIVEAWAYS]: {
    path: '/giveaways',
    title: 'Browse Giveaways',
    page: RafflesCategoryContainer,
  },

  [ROUTE_TYPE_GIVEAWAY_PREVIEW]: {
    title: 'Giveaway Preview',
    path: '/giveaways/preview',
    page: Preview,
  },

  [ROUTE_TYPE_GIVEAWAY]: {
    path: '/giveaways/:EVENTID',
    title: 'Giveaways: Detail',
    page: RaffleDetailContainer,
  },
  [ROUTE_TYPE_CLAIM]: {
    title: 'Claim Giveaway Prizes',
    path: '/giveaways/:EVENTID/claim',
    page: ClaimPrizesContainer,
  },

  [ROUTE_TYPE_GIVEAWAY_EMBED]: {
    path: '/giveaways/:EVENTID/embed',
    title: 'Giveaways: Detail (embed)',
    page: RaffleDetailContainer,
  },
};

export default RaffleRoutes;
