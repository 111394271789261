import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import Grid from '@material-ui/core/Grid';
import Select from 'common/MaterialUI/Select';
import { RAFFLE_STATE_ACTIVE, RAFFLE_STATE_ANNOUNCED, RAFFLE_STATE_FINISHED } from 'helpers/const';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from 'common/MaterialUI/InputLabel';
import MenuItem from 'common/MaterialUI/MenuItem';
import Regions from './Regions';
import Search from './Search';
import { Translation } from 'react-i18next';
export const CONTEST_CATEGORIES = [RAFFLE_STATE_ACTIVE, RAFFLE_STATE_ANNOUNCED, RAFFLE_STATE_FINISHED];

const DropdownWithSearch = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	.searchHolder {
		margin-left: 24px;
	}

	div {
		${media.desktop`

    max-width: 193px;

    `};
	}
`;

class View extends React.PureComponent {
  render() {
    const {
      sortOptions,
      sortKey,
      contestCategory,
      handleStatusDropDownConfirm,
      handleSortDropDownConfirm,
      handleChangeRegionSettings,
      region,
      regionMode,
      regionActive,
    } = this.props;

    return (
      <Translation>
        {(t) => (
          <Grid container spacing={3}>
            <Grid item xs sm={6} lg={2}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="giveawayStatus">
                  {t('giveaway_status')}
                </InputLabel>
                <Select
                  disableUnderline={true}
                  onChange={(event) => {
                    handleStatusDropDownConfirm(event.target.value);
                  }}
                  value={contestCategory}
                  inputProps={{
                    name: 'giveawayStatus',
                    id: 'giveawayStatus'
                  }}
                >
                  {CONTEST_CATEGORIES.map((c) => (
                    <MenuItem key={`gs_${c}`} value={c}>
                      {t(c)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs sm={6} lg={2}>
              <Regions
                handleChangeRegionSettings={handleChangeRegionSettings}
                region={region}
                regionMode={regionMode}
                regionActive={regionActive}
              />
            </Grid>

            <Grid item xs sm={6} lg={8}>
              <DropdownWithSearch>
                <FormControl fullWidth>
                  <InputLabel shrink htmlFor="sortKey">
                    {t('sort_by')}
                  </InputLabel>
                  <Select
                    disableUnderline={true}
                    onChange={(event) => {
                      handleSortDropDownConfirm(event.target.value);
                    }}
                    value={sortKey}
                    inputProps={{
                      name: 'sortKey',
                      id: 'sortKey'
                    }}
                  >
                    {Object.keys(sortOptions).map((key) => (
                      <MenuItem key={`pc_${key}`} value={key}>
                        {t(`filter${key}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="searchHolder">
                  <Search />
                </div>
              </DropdownWithSearch>
            </Grid>
          </Grid>
        )}
      </Translation>
    );
  }
}

export default View;
