import React from 'react';
import { Translation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import InputField from 'common/InputField';
import Button from 'common/MaterialUI/Button';
import styled from 'styled-components';

const SomeLink = styled.a`
  cursor: pointer;
`

const Spacer = styled.div`
height:10px;
`

class View extends React.PureComponent {

  componentDidMount() {
    const { queryParams, showNotification } = this.props;

    if (queryParams.error) {
      showNotification({ htmlContent: queryParams.error });
    }

    if (queryParams.success == "true") {
      showNotification({ htmlContent: "modals.notifications.changes_saved", translate: true, title: 'modals.notifications.you_made_it' });
    }
  }

  render() {

    const {
      handleSubmit, verified, empty, alreadySent, contact_mail
    } = this.props;

    return (
      <Translation>
        {t => (<form onSubmit={handleSubmit}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={12} sm={12} lg={7} >{alreadySent && <Spacer />}<InputField large placeholder={contact_mail} valid={verified} stateless={!verified}
              name="contact_mail" helperText={alreadySent && <SomeLink onClick={handleSubmit}>{t("Modals/ConnectModal/EmailFlow/Register/View.resend")}</SomeLink>}
            /></Grid>
            <Grid item xs={12} sm={12} lg={5}>{verified ? <Button disabled={true} fullWidth secondary large type="submit"><span className="icon-Ico16-Check-Medium" /><span>{t("Settings/Setup/ContactEmail/View.verified")}</span></Button> : alreadySent ? <Button disabled={true} fullWidth outline large type="submit">{t("Settings/Setup/ContactEmail/View.checkinbox")}</Button> : <Button disabled={empty} fullWidth outline large type="submit">{t("Settings/Setup/ContactEmail/View.sendverification")}</Button>}</Grid>
          </Grid>
        </form>)}
      </Translation>
    );
  }
}






({
  handleSubmit, verified, empty, alreadySent
}) => {

};

export default View;


