const mouseUpListeners = [];
const onMouseUp = event => {
  mouseUpListeners.forEach(listener => {
    listener(event);
  });
};

if (typeof window != 'undefined') {
  document.addEventListener('mouseup', onMouseUp);
}

export const addMouseUpListener = listener => {
  mouseUpListeners.push(listener);
};

export const removeMouseUpListener = listener => {
  let i = mouseUpListeners.indexOf(listener);
  if (i != -1) {
    mouseUpListeners.splice(i, 1);
  }
};
