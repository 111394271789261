import React from 'react';
import { Helmet } from 'react-helmet';
import { utils } from 'helpers/utils';

const Meta = ({ raffle }) => {
  if (!raffle || !raffle.host_info) {
    return null;
  }

  const title = `${raffle.eventname} by ${raffle.host_info.username} @ GHEED`;
  const image =
    raffle &&
      raffle.eventpic &&
      raffle.eventpic.variants &&
      raffle.eventpic.variants.length
      ? raffle.eventpic.variants[0].url
      : '';

  const description = utils.removeTags(raffle.eventdescription) || raffle.eventname;
  const url = `https://gheed.com/giveaways/${raffle.EVENTID}`;

  const meta = [
    { name: 'author', content: 'gheed_com' },

    { name: 'twitter:site', content: '@gheed_com' },
    { name: 'twitter:creator', content: '@gheed_com' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:image', content: image },

    { property: 'og:title', content: title },
    { property: 'og:site_name', content: 'gheed.com' },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:url',
      content: url,
    },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
  ];

  return <Helmet title={title} meta={meta} />;
};

export default Meta;
