import Kit from './';
import { ROUTE_TYPE_DEV_KIT } from '../../helpers/const';

const Route = {
  [ROUTE_TYPE_DEV_KIT]: {
    path: '/kit',
    title: 'Component Kit',
    page: Kit,
  },
};

export default Route;
