import React, { useEffect, useRef } from 'react';
import Modal from 'common/Modal';
import { ContentBox } from 'common/ContentBox';
import { Translation } from 'react-i18next';
import ClipBox from './ClipBox';
import styled from 'styled-components';
import { media } from 'styles';
import Button from 'common/MaterialUI/Button';
import { openPopup } from 'helpers/popup';

import WidgetConfigurator from './Widget';
import EmbedConfigurator from './Embed';
import { runtimeConfig } from '../../../runtimeConfig';
import { connect } from 'react-redux';





const Content = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 75vh;
  padding: 33px 20px 20px;

  ${media.tablet`
  padding: 33px 40px 40px;
  `} 
`;

const Controls = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;

  > * {
    flex: 0 1 auto;

    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;

const FBButton = styled.div`
  color: white;
  background: #2e3c99;

  flex: 0 1 auto;
  padding: 0 5px;
  height: 20px;
  font-size: 11px;
  font-weight: 800;
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Buttons = styled.div`
  display: flex;
 
  > *:not(:first-child) {
    margin-left: 10px;
  }
`


const Inner = ({ giveaway, EVENTID, onRequestClose }) => {

  const tweet = useRef();



  useEffect(() => {
    if (EVENTID) {
      twttr.widgets.createShareButton(
        `${runtimeConfig.SERVER_FULLADDRESS}/giveaways/${EVENTID}`,
        tweet.current,
        {
          text: 'Hey, check out my new giveaway:'
        }
      );
    }


  }, [EVENTID])



  const openFBShare = (url) => () => {
    openPopup(null, `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, "fbshare")
  }
  return <Translation>
    {t => (
      <ContentBox bigmodal nomargin centerTitle title={giveaway ? t("Modals/PostLaunch/index.titlewithgiveaway", { giveaway: giveaway.eventname }) : t("Modals/PostLaunch/index.title")} footer={() => <Controls>

        <Button primary small
          onClick={onRequestClose}
        >{t("Modals/PostLaunch/index.continue")}</Button>

      </Controls>}>
        <Content>
          <ClipBox title={t("Modals/PostLaunch/index.url")}>{`${runtimeConfig.SERVER_FULLADDRESS}/giveaways/${EVENTID}`}</ClipBox>
          <ClipBox title={t("Modals/PostLaunch/index.share")} disabled><Buttons><div ref={tweet} /><FBButton onClick={openFBShare(`${runtimeConfig.SERVER_FULLADDRESS}/giveaways/${EVENTID}`)}><span className="icon-Ico20-Social-Facebook" />Share</FBButton></Buttons></ClipBox>
          <WidgetConfigurator EVENTID={EVENTID} />
          <EmbedConfigurator EVENTID={EVENTID} />
        </Content>



      </ContentBox>
    )}
  </Translation>
}


const PostLaunchModal = (props) => {

  return (
    <Modal {...props}>
      <Inner {...props} />
    </Modal>
  );
}


const mapStateToProps = (state, ownProps) => {
  return {
    giveaway: { ...state.raffles.preview, ...state.raffles.raffles }[ownProps.EVENTID]
  }
}

export default connect(mapStateToProps)(PostLaunchModal)