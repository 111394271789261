import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';
import Rule from 'common/Rule';

import { Translation, Trans } from 'react-i18next';
import Notification from '../../common/Notification';
import Grid from '@material-ui/core/Grid';

const Controls = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;

  > * {
    flex: 0 1 auto;
  }
`;

const Content = styled.div`

  h4 {
    font-size: 12px !important;
    font-weight: 800;
    color: #9c9c9c;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px !important;
    margin-bottom: 10px;
  }

  .green {
    color: #5bcb8f;
    font-weight: bold;
  }

  ul {
    list-style-type: disc;

    li {
      margin: 10px 15px;
    }
  }
`;

const Description = styled.p`
  a {
    font-weight: bold;
  }
`
class PublicRulesModal extends React.PureComponent {

  handleClose = () => {
    const { handleConfirm, onRequestClose } = this.props;
    if (handleConfirm) {
      handleConfirm(this.state.confirmed)
    }
    onRequestClose()
  }

  state = {
    confirmed: false
  }

  render() {

    const { ...other } = this.props;
    const { handleClose } = this;

    return (
      <Modal {...other} onRequestClose={handleClose}>
        <Translation>
          {t => (
            <ContentBox bigmodal title={t("Modals/PublicRulesModal.title")} maxHeight={"75vh"} footer={() => <Controls>

              <Button outline small
                onClick={handleClose}
              >{t("Modals/PublicRulesModal.decline")}</Button>

              &nbsp;&nbsp;
              <Button primary small
                onClick={() => { this.setState({ confirmed: true }, () => handleClose()) }}
              >{t("Modals/PublicRulesModal.confirm")}</Button>
            </Controls>}>
              <Content>
                <Trans i18nKey="Modals/PublicRulesModal.info"><Description>If you are unsure whether you can promote certain prices on our platform, just ask us directly via <a href="mailto:hello@gheed.com">email</a> or <a href="https://discord.gg/CCTuaCQ" target="_blank"
                  rel="noopener noreferrer">discord</a>. Once you have received the contact details of the winner, you have to ship the prize(s) <b>within two weeks</b>.</Description>
                  <Notification warning icon="icon-Ico16-Tooltip-Attention">GHEED reserves the right to ask you for proof that the prize has been shipped or, if it is a particularly valuable one, for proof of its existence.</Notification>
                  <Notification warning icon="icon-Ico16-Tooltip-Attention">Failure to comply with the rules may result in deletion of your giveaway and, in particularly critical cases, an account downgrade.</Notification></Trans>
                <Grid container spacing={3} justify="space-evenly" alignItems="stretch">
                  <Grid item xs sm={6} lg={4}><Rule>{t("Modals/PublicRulesModal.list_general")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule>{t("Modals/PublicRulesModal.hardware")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule>{t("Modals/PublicRulesModal.games")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule>{t("Modals/PublicRulesModal.giftcards")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule>{t("Modals/PublicRulesModal.beauty")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule>{t("Modals/PublicRulesModal.merch")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule warning>{t("Modals/PublicRulesModal.no_fake")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule warning>{t("Modals/PublicRulesModal.no_violence")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule warning>{t("Modals/PublicRulesModal.no_racist")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule warning>{t("Modals/PublicRulesModal.no_pornographic")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule warning>{t("Modals/PublicRulesModal.no_living")}</Rule></Grid>
                  <Grid item xs sm={6} lg={4}><Rule warning>{t("Modals/PublicRulesModal.no_copyright")}</Rule></Grid>

                </Grid>
              </Content>
            </ContentBox>
          )}
        </Translation>
      </Modal>
    );
  }
}


export default PublicRulesModal;
