import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import classNames from 'classnames';
import { media } from '../../../../styles';

const animation = keyframes`
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
    
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const animationRule = css`${animation} ${props => props.duration}ms both`;



const Layout = styled.div.attrs(props => ({ style: { animationPlayState: props.paused ? 'paused' : 'running' } }))`
animation: ${animationRule};
  flex: 1 0 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: Nunito;
  font-weight: 800;

  .banner {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const ImageBox = styled.div`
  overflow: hidden;

  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;

  min-width: 300px;
  min-height: 208px;

  ${media.tablet`
  min-width: 630px;
  min-height: 504px;
  `}

  &.full {
    width: 25vw;
    height: auto;
    transform: translate(-50%, -50%);

    left: 50%;
    top: 50%;
  }
`;

export class Banner extends React.PureComponent {
  render() {
    const { source, duration, paused, fullscreen } = this.props;

    return (
      <Layout {...{ duration, paused }}>
        <ImageBox className={classNames({ full: fullscreen })}>
          <img alt="Banner" className="banner" border={0} src={source} />
        </ImageBox>
      </Layout>
    );
  }
}
