import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'redux-first-router';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { OAuth } from 'rdx/modules/api/OAuth';
import {
  modalAction,
  MODAL_OWN_RAFFLE,
  MODAL_TOO_EARLY,
  MODAL_TOO_LATE,
  showNotificationModal
} from 'rdx/modules/modals';
import { giveawayListCategorySettingsAction } from 'rdx/modules/giveawayLists';
import { WAYTYPES, PLATFORMS } from 'helpers/const';
import { Analytics } from 'helpers/analytics';
import { postEntrywaysEntityPromise } from 'rdx/modules/api/logics';
import View from './View';
import { loginRequestAction } from 'rdx/modules/auth';
import { SERVER_DATE_DIFFERENCE } from 'rdx/modules/api/response-interceptor';
import { utils } from 'helpers/utils';
import i18n from 'i18n';
import { postEntrywaysEntityErrorAction } from 'rdx/modules/api';


const auth = state => state.auth;
const ui = state => state.ui;

const getRaffle = (state, props) => props.raffle;
const ways = (state, props) =>
  props.raffle.ways || state.raffles.entryways[props.raffle.EVENTID]; // important for preview
const processing = (state, props) => state.raffles.processingEntryway;
const user = state => state.auth.user;
const entries = state => state.raffles.user_entries
const loading = state => state.rest.postEntrywaysEntity.sending

export const getRaffleDetails = createSelector(
  [getRaffle, user, ways, auth, ui, processing, entries, loading],
  (raffle, user, ways, auth, ui, processing, entries, loading) => {
    const formattedWays =
      raffle && ways
        ? ways.sort((a, b) => {
          if (a.mandatory != b.mandatory) {
            return a.mandatory ? -1 : 1;
          }

          return a.rank - b.rank;
        })
        : null;
    const openMandatory = formattedWays
      ? formattedWays.filter(w => w.mandatory && !w.earned_amount).length > 0
      : false;

    return {
      ways: formattedWays,
      openMandatory: openMandatory,
      user_entries: raffle && entries[raffle.EVENTID] ? entries[raffle.EVENTID] : 0,
      user: user,
      raffle,
      socials: user ? user.socials : [],
      isOwnRaffle:
        raffle && user ? user.USERID == raffle.host_info.USERID : false,
      loading,
      authenticated: auth.user ? true : false,
      disableAnalytics: ui.disableAnalytics,
      processing
    };
  },
);

const mapStateToProps = (state, ownProps) => {
  return {
    ...getRaffleDetails(state, ownProps),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    login: () => {
      dispatch(loginRequestAction());
    },
    handleContinue: (user, disableAnalytics) => (way, amount, callback, tabLink, input) => (event) => {

      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }

      if (!user) {
        dispatch(loginRequestAction());
        return false;
      }

      const raffle = ownProps.raffle;

      if (user.USERID == raffle.host_info.USERID) {
        dispatch(modalAction({ modal: MODAL_OWN_RAFFLE }));
        return false;
      }

      let now = moment().subtract(SERVER_DATE_DIFFERENCE, 'ms')
        .utc()
        .toDate();
      let fromDate = moment.utc(raffle.active_from).toDate();
      let toDate = moment.utc(raffle.active_to).toDate();

      if (now <= fromDate) {
        dispatch(
          modalAction({ modal: MODAL_TOO_EARLY, data: { raffle: raffle } }),
        );
        return false;
      }

      if (now >= toDate) {
        dispatch(
          modalAction({ modal: MODAL_TOO_LATE, data: { raffle: raffle } }),
        );
        return false;
      }

      const waytype = WAYTYPES[way.WAYTYPEID]
      const platform = PLATFORMS[waytype.PLATFORMID]

      if (waytype.connect_required) {
        if (!user.socials || user.socials.filter(s => s.PLATFORMID == waytype.PLATFORMID).length == 0) {
          OAuth.connect(
            platform,
            null,
            null,
            typeof window != "undefined" ? window.location.pathname : '/',
            typeof window != "undefined" ? window.location.pathname : '/',
            i18n.t("Raffles/Detail/MainBox/Entryways/index.connected", { name: platform.name })
          )
          return false;
        }
      } else {
        const socialLink = utils.socialLink(way)
        const tabWindowLink = tabLink || (socialLink.indexOf("http") == 0 && socialLink)

        if (tabWindowLink) {
          const win = window.open(tabWindowLink, '_blank');
          win && win.focus()
        }
      }

      if (!disableAnalytics) {
        Analytics.event(
          document.title,
          utils.socialLink(way),
        );
      }

      const earnedAll = way.earned_amount && !way.available_amount
      const isComplete = earnedAll ? way.cooldown && way.entry_date ? utils.isOnDailyCooldown(way.entry_date) : way.earned_amount : false

      if (isComplete) {
        return Promise.resolve(callback())
      }

      return new Promise((resolve, reject) => postEntrywaysEntityPromise({ id: way.WAYID, amount, input }, dispatch, undefined, true).
        then(() => {
          resolve(callback())
        }).
        catch(error => {

          const message = error && error.response && (error.response.data && (error.response.data.message.message || error.response.data.message))

          dispatch(postEntrywaysEntityErrorAction(error))
          dispatch(showNotificationModal({
            title: 'modals.notifications.oh_snap',
            translate: false,
            htmlContent: message
          }))
          reject(message)
        })
      )
    },

    handleMostPopularAllClick: () => {
      dispatch(
        giveawayListCategorySettingsAction([
          {
            nextPage: 0,
            totalPages: 999,
            data: [],
            query: 'MOST_POPULAR',
          },
          'active',
        ]),
      );
      push('/giveaways');
    },
    handlePostEntrywaysEntity: disableAnalytics => (way, amount = null, input = null) => {
      if (!disableAnalytics) {
        Analytics.event(
          document.title,
          utils.socialLink(way),
        );
      }

      return postEntrywaysEntityPromise({ id: way.WAYID, amount, input}, dispatch, undefined, true)
    },

    handleEntryWayClick: way => {
      push('/giveaways');
    },
    handleSelect: raffle => {
      push(`/giveaways/${raffle.EVENTID}`);
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    handleContinue: dispatchProps.handleContinue(
      stateProps.user, stateProps.disableAnalytics
    )
  };
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
);

export const RaffleDetailContainer = enhance(View);
export default RaffleDetailContainer;
