import React from 'react';
import propTypes from 'prop-types';

import styled from 'styled-components';

const Layout = styled.div`
  position: absolute;
  flex: 0 1 auto;

  &:not(.open) {
    display: none;
  }

  &.open {
    z-index: 50;
  }
`;

class DropDownList extends React.Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextContext) {
      const { dropDownState } = nextContext;
      const { onChange } = nextProps;
      if (onChange) {
        onChange(dropDownState);
      }
    }
  }

  render() {
    const { children } = this.props;
    const {
      dropDownState: { open },
    } = this.context;
    return (
      <Layout {...this.props} className={`dropdownlist${open ? ' open' : ''}`}>
        {children}
      </Layout>
    );
  }

  static contextTypes = {
    dropDownState: propTypes.any.isRequired,
    dropDownMethods: propTypes.any.isRequired,
  };
}

export default DropDownList;
