import React from 'react';
import Tab from '@material-ui/core/Tab';
import styled, { css } from 'styled-components';
import { media } from 'styles/index';

const StyledTab = styled(({ big, upper, ...props }) => (
  <Tab {...props} classes={{
    "root": "root",
    "wrapper": "wrapper"
  }} />
))`
  .wrapper {
    font-size: 16px;
    text-transform: ${({ upper }) => upper ? 'uppercase' : 'none'};
    
    ${({ big }) => big && css`
      ${media.tablet`
      font-size: 20px;
      `}

    `}
    
  }    
`

export default StyledTab;

