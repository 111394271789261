import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Switch from 'common/MaterialUI/Switch';
import TextField from 'common/MaterialUI/TextField';
import MenuItem from 'common/MaterialUI/MenuItem';
import ColorPicker from 'common/ColorPicker';
import { runtimeConfig } from '../../../runtimeConfig';
import { Translation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import styled, { css } from 'styled-components';
import ClipBox from './ClipBox';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

const BorderLayout = styled.div`
  border-radius: 3px;
  border: solid 1px #e1e2ef;
  padding: 10px;
`;

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    borderRadius: '3px',
    boxShadow: 'none',
    '& + &': {
      marginTop: '5px'
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,

    },
  },
  expanded: {
    margin: '0',
    border: 'none'
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    cursor: 'auto !important',
    minHeight: '40px',
    '&$expanded': {
      minHeight: '40px',

    },
  },


  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,

    },
    '& > :last-child': {
      paddingRight: 0,
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  expanded: {
    margin: 0
  }

})(MuiExpansionPanelSummary);


const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,

  }
})(MuiExpansionPanelDetails);


const Setting = styled(({ className, children, label }) => <div className={className}><div>{label}</div><div>{children}</div></div>)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    :first-child {
      margin-right: 10px;
    }

    :last-child {
      display: flex;
      justify-content: flex-end;
      ${({ even }) => even && css`width: 50%;`}
    }
  }
  margin-bottom: 5px;
`

const CustomSlider = styled(({ className, displayValue, ...other }) => <div className={className}><span>{displayValue}</span><Slider {...other} /></div>)`

display: flex;
justify-content: space-between;

> span {
  white-space: nowrap;
    min-width: 65px;
}


min-width: 100px;
  width: 100%;
  transform: translateY(3px);
`

const DetailContent = styled.div`
overflow: hidden;
max-width: 100%;

`

const Description = styled.p`
  margin: 0 0 15px;
`

const Configure = styled(({ label, expanded, onClick, className }) => <div onClick={onClick} className={className}>{label}<span className={expanded ? "icon-Ico16-ArrowUp-Small" : "icon-Ico16-ArrowDown-Small"} /></div>)`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ expanded }) => !expanded ? '#36a6fc' : '#9697a1'};
`


const EmbedConfigurator = ({ EVENTID, giveaway, language }) => {

  const [borderSize, setBorderSize] = useState(0);
  const [borderColor, setBorderColor] = useState('#ffffff');
  const [borderType, setBorderType] = useState('none');
  const [iframeName, setIframeName] = useState(`Giveaway ${EVENTID}`);
  const [scrollbar, setScrollbar] = useState(false);
  const [frameborder, setFrameborder] = useState(true);
  const [marginheight, setMarginheight] = useState(0);
  const [marginwidth, setMarginwidth] = useState(0);
  const [iframeHeight, setIframeHeight] = useState(800);
  const [iframeWidth, setIframeWidth] = useState(560);
  const [allowfullscreen, setAllowfullscreen] = useState(true);

  const [expanded, setExpanded] = useState(false);

  const embedURL = `${runtimeConfig.SERVER_FULLADDRESS}/giveaways/${EVENTID}/embed`;

  const embedHTMLCode = `<iframe src="${embedURL}" ${frameborder ? `style="border:${borderSize}px ${borderColor} ${borderType};"` : ``} name="${iframeName}" scrolling="${scrollbar ? 'yes' : 'no'}" marginheight="${marginheight}px" marginwidth="${marginwidth}px" height="${iframeHeight}px" width="${iframeWidth}px" ${allowfullscreen ? 'allowfullscreen' : ''}></iframe>`;

  return <Translation>{t => (<React.Fragment>
    <ClipBox title={t("Modals/PostLaunch/index.embed")} details={() => {

      return <ExpansionPanel expanded={expanded}>
        <ExpansionPanelSummary><Configure onClick={() => setExpanded(!expanded)} expanded={expanded} label={t("Modals/PostLaunch/Embed.configureandpreview")} /></ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <DetailContent>
            <Description><Setting even label={t("Modals/PostLaunch/Embed.iframeName")}><TextField nomargin value={iframeName} onChange={(event, newValue) => setIframeName(event.target.value)} /> </Setting></Description>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Setting even label={t("Modals/PostLaunch/Embed.iframeHeight")}> <TextField nomargin value={iframeHeight} onChange={(event, newValue) => setIframeHeight(event.target.value)} /> </Setting>
                <Setting even label={t("Modals/PostLaunch/Embed.marginheight")}> <TextField nomargin value={marginheight} onChange={(event, newValue) => setMarginheight(event.target.value)} /> </Setting>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Setting even label={t("Modals/PostLaunch/Embed.iframeWidth")}> <TextField nomargin value={iframeWidth} onChange={(event, newValue) => setIframeWidth(event.target.value)} /> </Setting>
                <Setting even label={t("Modals/PostLaunch/Embed.marginwidth")}> <TextField nomargin value={marginwidth} onChange={(event, newValue) => setMarginwidth(event.target.value)} /> </Setting>
              </Grid>
            </Grid>
            <Grid container spacing={1}>  
              <Grid item xs={12} sm={6}>
                <Setting label={t("Modals/PostLaunch/Embed.scrollbar")}><Switch checked={scrollbar} onChange={(event) => setScrollbar(event.target.checked)} /></Setting>
                <Setting label={t("Modals/PostLaunch/Embed.allowfullscreen")}><Switch checked={allowfullscreen} onChange={(event) => setAllowfullscreen(event.target.checked)} /></Setting>
              </Grid>
              <Grid item xs={12} sm={6}>
                <BorderLayout>
                <Setting label={t("Modals/PostLaunch/Embed.frameborder")}><Switch checked={frameborder} onChange={(event) => setFrameborder(event.target.checked)} /></Setting>
                <Setting even label={t("Modals/PostLaunch/Embed.borderColor")}>
                  <ColorPicker
                    disabled={!frameborder}
                    value={borderColor}
                    onChange={value => setBorderColor(value)}
                  />
                </Setting>              
                <Setting even label={t("Modals/PostLaunch/Embed.borderSize")}><CustomSlider disabled={!frameborder} displayValue={t("Modals/PostLaunch/Embed.value.px", { value: borderSize })} step={1} min={0} max={10} value={borderSize} onChange={(event, newValue) => setBorderSize(newValue)} aria-labelledby="bordersize-slider" /></Setting>
                <Setting even label={t("Modals/PostLaunch/Embed.borderType")}>
                  <TextField select nomargin value={borderType} onChange={(event, newValue) => setBorderType(event.target.value)} disabled={!frameborder}>
                    <MenuItem key={"none"} value={"none"} >none</MenuItem>
                    <MenuItem key={"hidden"} value={"hidden"} >hidden</MenuItem>
                    <MenuItem key={"solid"} value={"solid"} >solid</MenuItem>
                    <MenuItem key={"dotted"} value={"dotted"} >dotted</MenuItem>
                    <MenuItem key={"dashed"} value={"dashed"} >dashed</MenuItem>
                  </TextField>
                </Setting>
                </BorderLayout>
              </Grid>
            </Grid>


          </DetailContent>
        </ExpansionPanelDetails>
      </ExpansionPanel>


    }}>{embedHTMLCode}</ClipBox>





  </React.Fragment >)}</Translation >




}


const mapStateToProps = (state, ownProps) => {
  return {
    language: state.ui.language,
    giveaway: { ...state.raffles.preview, ...state.raffles.raffles }[ownProps.EVENTID]
  }
}

export default connect(mapStateToProps)(EmbedConfigurator)
