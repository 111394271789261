import { createLogic } from 'redux-logic';
import { GET_USERS_SETTINGS_ERROR } from 'rdx/modules/api';
import { logoutRequestAction } from 'rdx/modules/auth';


const getUsersSettingsErrorLogic = createLogic({
  type: [GET_USERS_SETTINGS_ERROR],

  process({ getState, action }, dispatch, done) {
    try {
      dispatch(logoutRequestAction());
    } catch (error) {
      console.log(error)
    }
    done();
  },
});

export default getUsersSettingsErrorLogic;