import React from 'react';
import styled from 'styled-components';
import { H6ExtraBoldGrey } from '../../styles/Kit/H6ExtraBold';
import { InputLabel } from '@material-ui/core';
import { grey } from 'styles/Kit/colors';




const StyledInputLabel = styled(({ color, ...other }) => (
  <InputLabel {...other} classes={{ "root": "root", "shrink": "shrink", "formControl": "formControl" }} />
))`
    &.root {
      ${H6ExtraBoldGrey}
      color: ${grey} !important;
      display: flex;
    }

    &.shrink {
      transform: translate(0, 1.5px);
    }
`;



export default StyledInputLabel;