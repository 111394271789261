import styled from 'styled-components';
import React from 'react';
import { WAYTYPES, PLATFORMS } from 'helpers/const';
import { Translation, Trans } from 'react-i18next';
import { media } from 'styles';
import Coupons from '../../Coupons';
import Methods from './Methods';
import { InfoButton } from 'common/InfoButton';

const Layout = styled.div`
  border-top: solid 2px #f0f0f0;
  background-color: #f8f9fb;

  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  flex: 1 0 auto;
  padding: 40px 0 20px;

  ${media.tabletMax`
  padding: 20px 0 20px;
  `};

`;

const Top = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  margin-bottom: 20px;

  ${media.tabletMax`
  font-size: 11px;
  justify-content: center;
  span:first-child {
    display: none;
  }
  `};
`;

const Content = styled.div`
  padding: 0 40px 40px;

  ${media.tabletMax`
  padding: 0 20px 20px;
  `};

  > * + * {
    margin-top: 10px;
  }
`;

const SponsoredTitle = styled.div`
  font-size: 11px;
  font-weight: 800;
  color: #9c9c9c;
  text-transform: uppercase;
  margin-top: 15px;
`;

const LoginLink = styled.a`
  cursor: pointer;
  font-weight: bold;
`


export class Entryways extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: null };
  }

  handleOpen = w => {
    const { open } = this.state;

    if (open != w) {
      const waytype = WAYTYPES[w.WAYTYPEID];
      const platform = PLATFORMS[waytype.PLATFORMID];

      if (waytype && platform) {
        this.setState({ open: w });
      }
    }
  };



  handleClose = () => {
    this.setState({ open: null });
  };

  toggle = (way) => () => {
    const { open } = this.state;
    open != way ? this.handleOpen(way) : this.handleClose()
  };

  render() {
    const { ways, raffle, user_entries, socials,
      processing,
      handlePostEntrywaysEntity,
      handleSocialConnect,
      handleContinue,
      handleError,
      openMandatory, loading, user, login } = this.props;

    const { open } = this.state;

    const { handleOpen, handleClose, toggle } = this;


    const { earned, regular, sponsored } = ways
      ? ways.reduce(
        (l, c) => {
          let regular = c.earned_amount ? c.earned_amount : 0;
          let bonus = c.bonus_amount ? c.bonus_amount : 0;

          return {
            earned: l.earned + regular + bonus,
            total: l.total + c.amount,
            regular: !c.sponsored ? [...l.regular, c] : l.regular,
            sponsored: c.sponsored ? [...l.sponsored, c] : l.sponsored,
          };
        },
        { earned: 0, total: 0, regular: [], sponsored: [] },
      )
      : { earned: 0, total: 0, regular: [], sponsored: [] };



    return (
      <Translation>
        {t => (
          <Layout className="sixthirty">
            <Coupons raffle={raffle} />
            <Top>

              <span>
                {!user &&
                  <Trans i18nKey="Raffles/Detail/MainBox/Entryways/View.login_to_enter"><LoginLink onClick={login}>Login</LoginLink> to enter this giveaway.</Trans>
                }
                {user &&
                  <React.Fragment><Trans i18nKey="Raffles/Detail/MainBox/Entryways/View.loggedin_as" values={{ username: user.username }}>
                    Logged in as <b>Username</b>
                  </Trans>
                    <InfoButton inline title={
                      <span><Trans i18nKey="Raffles/Detail/MainBox/Entryways/View.tooltip_notifications" values={{ email: user.contact_mail }}>
                        Winner notifications will be sent to <b>winner</b>
                      </Trans>
                      </span>} />
                  </React.Fragment>
                }
              </span>
              <span>
                <Trans i18nKey="ga_entryways_your_entries" values={{ earned: user_entries || earned }}>
                  Your entries: <b>x</b>
                </Trans>
              </span>
            </Top>
            <Content>
              {regular && regular.map((way, i) => <Methods key={way.WAYID || i} open={open && open.WAYID == way.WAYID}
                processing={processing == way.WAYID} {...{
                  loading,
                  socials,
                  way,
                  handleOpen,
                  handleContinue,
                  handleError,
                  handleClose,
                  handlePostEntrywaysEntity,
                  handleSocialConnect,
                  openMandatory,
                  toggle
                }} />)}
              {sponsored &&
                sponsored.length > 0 && (
                  <SponsoredTitle>{t('ga_sponsored_bonus')}</SponsoredTitle>
                )}
              {sponsored && sponsored.map((way, i) => <Methods key={way.WAYID || i} open={open && open.WAYID == way.WAYID}
                processing={processing == way.WAYID} {...{
                  loading,
                  socials,
                  way,
                  handleOpen,
                  handleContinue,
                  handleError,
                  handleClose,
                  handlePostEntrywaysEntity,
                  handleSocialConnect,
                  openMandatory,
                  toggle
                }} />)}
            </Content>
          </Layout>
        )}
      </Translation>
    );
  }
}

export default Entryways;
