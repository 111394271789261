import React from 'react';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import ArrowDropDown from './ArrowDropDown';
import Scrollbar from './Scrollbar';
import { BodyRegularBlack } from '../../styles/Kit/BodyRegular';
import { FillDropShadow } from '../../styles/Kit/Fills';

const MenuProps = {
  MenuListProps: { component: Scrollbar }
}
const StyledSelect = styled(({ color, ...other }) => (
  <Select displayEmpty={true} {...other} disableUnderline={true} MenuProps={MenuProps} IconComponent={ArrowDropDown} classes={{ root: 'root', select: 'select', selectMenu: 'selectMenu', disabled: 'disabled', icon: 'icon' }} />
))`
  
${FillDropShadow}
${BodyRegularBlack}
  
  label + & {
    margin-top: 24px !important;
  }

  border-radius: 3px;
  min-width: 150px;
  height: 34px;

  .select {
    padding-left: 20px;
  }

`;


/* ${ DropShadow }

  & .label {
  color: ${ props => props.color };
} */

export default StyledSelect;