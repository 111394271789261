import styled from 'styled-components';
import React from 'react';
import { EVENTDESCRIPTIONTEXTTYPE_HTML } from '../../../../helpers/const';
import { BodyRegularBlack } from 'styles/Kit/BodyRegular';
import { BodySmallRegularBlack } from 'styles/Kit/BodySmallRegular';
import { media } from '../../../../styles';

const View = styled.div`
word-break: break-word;  
margin: 0 20px 20px 24px;
${BodySmallRegularBlack}

${media.tablet`
  margin: 0 40px 22px 40px;
  ${BodyRegularBlack}
`}  
 

`;
export const Description = ({ raffle }) => {
  const { EVENTDESCRIPTIONTEXTTYPEID, eventdescription } = raffle;

  if (!eventdescription) {
    return null
  }


  return EVENTDESCRIPTIONTEXTTYPEID == EVENTDESCRIPTIONTEXTTYPE_HTML ? <View dangerouslySetInnerHTML={{ __html: eventdescription }} /> : <View>{eventdescription}</View>
}



export default Description;
