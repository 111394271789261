
import { ROUTE_PREFIX } from '../helpers/const';
import Campaign from '../components/Campaign';
import { runtimeConfig } from '../runtimeConfig';

const { campaigns } = runtimeConfig.CAMPAIGNS

const CampaignRoutes = campaigns ? Object.values(campaigns).filter(c => c.path).reduce((prev, campaign) => ({
  ...prev, [`${ROUTE_PREFIX}${campaign.path}`]: {
    title: campaign.title,
    path: `/${campaign.path}`,
    page: Campaign
  }
}), {}) : {}

export default CampaignRoutes;
