import { createLogic } from 'redux-logic';
import { showNotificationModal } from 'rdx/modules/modals';
import { POST_EMAIL_LOGIN_ERROR, GET_WAYTYPES_VALIDATE_ERROR } from '../modules/api';

const errorNotificationsLogic = createLogic({
  type: /ERROR/,

  async process({ getState, action }, dispatch, done) {

    try {

      const error = action.payload;
      let message = "Unknown error";

      if (error && error.message) {
        message = error.message
      }

      if (error && error.response && error.response.data) {
        message = error.response.data.message || error.response.data
      }

      if (action.type != "@@redux-form/UPDATE_SYNC_ERRORS" && [POST_EMAIL_LOGIN_ERROR, GET_WAYTYPES_VALIDATE_ERROR].indexOf(action.type) == -1) {

        dispatch(
          showNotificationModal({
            title: 'modals.notifications.oh_snap',
            translate: false,
            htmlContent:
              message
          }),
        );
      }


    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default errorNotificationsLogic;
