import React from 'react';
import Button from 'common/MaterialUI/Button'
import Grid from '@material-ui/core/Grid';
import InputField from 'common/InputField';
import styled from 'styled-components';
import { Translation, Trans } from 'react-i18next';

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;



const View = ({ handleSubmit, submitting, submitSucceeded, showOverview }) => <Translation>{t => (

  <form onSubmit={handleSubmit}>

    {submitSucceeded ? <p><Trans i18nKey="Modals/ConnectModal/EmailFlow/ChangePassword/View.success">Dein Passwort wurde erfolgreich aktualisiert. Du kannst dich nun ab sofort mit diesem einloggen.</Trans></p> : <React.Fragment><p><Trans i18nKey="Modals/ConnectModal/EmailFlow/ResetPassword/View.createnew"><strong>Neues Passwort anlegen</strong></Trans><br /><br /></p> <Grid container spacing={3}>
      <Grid item xs={12}><InputField name="new_password" type="password" title={t("Modals/ConnectModal/EmailFlow/ChangePassword/View.password")} /></Grid>
      <Grid item xs={12}><InputField name="new_passwordconfirm" type="password" title={t("Modals/ConnectModal/EmailFlow/ChangePassword/View.passwordconfirm")} /></Grid>
    </Grid></React.Fragment>}
    <Controls>
      <Button outline onClick={showOverview}><span className="icon-Ico16-ArrowLeft-Medium" /><span>{t("Modals/ConnectModal/EmailFlow/Login/View.back")}</span></Button>
      {!submitSucceeded && <Button primary disabled={submitting} type="submit">{t("Modals/ConnectModal/EmailFlow/ForgotPassword/View.submit")}</Button>}
    </Controls>
  </form>)}</Translation>

export default View