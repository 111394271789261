import styled from 'styled-components';
import React from 'react';

const Inside = styled.figure.attrs(props => {
  return {
    style: {
      backgroundImage: `url(${props.src})`
    }
  }
})`
  background-repeat: no-repeat;
  background-position: 50% 50%;
  
  background-size: cover;
  border-radius: 3px;


  height: 50px;
  width: 50px;
  max-height: 50px;
`;

const Outside = styled.div`
	padding: 4px;
	background-color: white;
	border-radius: 6px;
	flex: 0 0 58px;
	width: 58px;
	height: 58px;
	left: 18px;
	top: 210px;
	position: absolute;
`;

export const SmallImage = ({ src }) => {
  return (
    <Outside>
      <Inside src={src} />
    </Outside>
  );
};

export default SmallImage;
