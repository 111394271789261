import View from './View';
import { connect } from 'react-redux';
import { submit } from 'redux-form'
import { push } from 'redux-first-router';
import { CREATOR_MODE_MAP, CREATOR_PATHS, CREATOR_PATHS_EDIT , ROUTE_TYPE_GIVEAWAY_EDIT } from 'helpers/const';

import { modalAction, MODAL_PREVIEW } from 'rdx/modules/modals';
import storeSetupRequired from '../../../../store/selectors/storeSetupRequired';
import paymentRequired from '../../../../store/selectors/paymentRequired';
import paymentConfig from '../../../../store/selectors/paymentConfig';


const mapStateToProps = (state, ownProps) => {

  const activeStep = CREATOR_MODE_MAP[state.location.payload.mode] || 0;
  const isEdit = state.location.type == ROUTE_TYPE_GIVEAWAY_EDIT
  const eventId = isEdit && state.location.payload.EVENTID
  const source = isEdit ? state.creator.edit : state.creator.temp

  return {
    hasPrizes: source && source.prizes.length > 0,
    storeSetupRequired: storeSetupRequired(state),
    blockCheckout: !isEdit && paymentRequired(state) && paymentConfig(state).loading,
    activeStep,
    isLastEditStep: isEdit && state.location.payload.mode == "entries",
    handlePrevious: activeStep > 0 ? () => { push(eventId ? CREATOR_PATHS_EDIT[activeStep ? activeStep - 1 : 0].replace('<id>', eventId) : CREATOR_PATHS[activeStep ? activeStep - 1 : 0]) } : undefined,
    busy: state.ui.sendingForm,
    mode: state.location.payload.mode,
    edit: eventId
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleNext: (activeStep, eventId) => activeStep < CREATOR_PATHS.length - 1 ? () => {
      const form = document.querySelector('form[id^=creator]');

      form ? dispatch(submit(form.id)) : push(eventId ? CREATOR_PATHS_EDIT[activeStep + 1].replace('<id>', eventId) : CREATOR_PATHS[activeStep + 1])
    } : undefined,
    handleCheckout: (activeStep) => activeStep == CREATOR_PATHS.length - 1 ? () => {

      const form = document.querySelector('form[id^=creator]');
      if (form) {
        dispatch(submit(form.id))
      }

    } : undefined,
    handlePreview: (activeStep) => {

      dispatch(async (dispatch, getState) => {

        return dispatch(
          modalAction({
            modal: MODAL_PREVIEW,
          }),
        );
      });
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {

  const { activeStep, edit } = stateProps;
  const { handleNext, handleCheckout } = dispatchProps;


  return {
    ...stateProps,
    ...dispatchProps,
    handleNext: handleNext(activeStep, edit),
    handleCheckout: handleCheckout(activeStep),
    ...ownProps
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View);