import { createSelector } from "reselect";

const cart = state => state.creator.cart;

const paymentRequired = createSelector([cart], (cart) => {

  return cart
    ? parseFloat(cart.total) != 0
    : false
})
export default paymentRequired