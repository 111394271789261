import React from 'react';
import styled from 'styled-components';
import { Radio } from '@material-ui/core';
import { black, blue } from '../../styles/Kit/colors';

const StyledRadio = styled(({ color, ...other }) => (
	<Radio {...other} classes={{ root: 'root', checked: 'checked', disabled: 'disabled' }} />
))`
    &.root {
      color: ${black} !important;
      height:  36px !important;
    }

    &.checked {
      color: ${blue} !important;
    }

    
`;

export default StyledRadio;
