import DashboardBroadcaster from 'components/Dashboard/Broadcaster';
import DashboardViewer from 'components/Dashboard/Viewer';
import { GO_HOME } from 'routes/events';
import { ROUTE_TYPE_DASHBOARD, ROUTE_TYPE_DASHBOARD_STREAMER, ROUTE_TYPE_DASHBOARD_VIEWER } from '../../helpers/const';
import isAuthed from '../../store/selectors/isAuthed';
import isStreamer from '../../store/selectors/isStreamer';

export const broadcaster = async (dispatch, getState) => {
  const state = getState();

  if (!isAuthed(state) || !isStreamer(state)) {
    GO_HOME(dispatch);
  }
};

export const viewer = async (dispatch, getState) => {
  const state = getState();

  if (!state.auth.user) {
    GO_HOME(dispatch);
  }
};

const Routes = {
  [ROUTE_TYPE_DASHBOARD]: {
    path: '/dashboard',
    title: 'Dashboard',
    page: () => {
   
      return null
    
    },
  },
  [ROUTE_TYPE_DASHBOARD_STREAMER]: {
    path: '/dashboard/streamer',
    title: 'Streamer Dashboard',
    page: DashboardBroadcaster,
  },
  [ROUTE_TYPE_DASHBOARD_VIEWER]: {
    path: '/dashboard/viewer',
    title: 'Viewer Dashboard',
    page: DashboardViewer,
  },
};

export default Routes;
