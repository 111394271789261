import Cookies from 'js-cookie';

export const promotion = {

  setBannerRotationStart: value => {
    Cookies.set("BannerRotationStart", value, { path: '/' });
  },
  setFeaturedRotationStart: value => {
    Cookies.set("FeaturedRotationStart", value, { path: '/' });
  },
  getBannerRotationStart: () => Cookies.getJSON("BannerRotationStart"),
  getFeaturedRotationStart: () => Cookies.getJSON("FeaturedRotationStart"),
  removeBannerRotationStart: () => Cookies.remove("BannerRotationStart"),
  removeFeaturedRotationStart: () => Cookies.remove("FeaturedRotationStart"),
};