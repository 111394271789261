import { compose } from 'redux';
import { withAuth } from 'hoc/withAuth';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { MODAL_PARTNER_APPLY, modalAction , MODAL_SUPPORT } from 'rdx/modules/modals';

import { Layout } from './Layout';
import { ROUTE_TYPE_GIVEAWAY, ROUTE_TYPE_CREATE, ROUTE_TYPE_GIVEAWAY_EDIT } from 'helpers/const';
import isStreamer from '../../store/selectors/isStreamer';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    support: () => {
      dispatch(modalAction({ modal: MODAL_SUPPORT }));
    },
    openBetaSignup: () => {
      dispatch(modalAction({ modal: MODAL_PARTNER_APPLY }));
    },
  };
};

const location = state => state.location;
const editRaffle = state => state.location.payload.EVENTID && state.raffles.raffles[state.location.payload.EVENTID]
const auth = state => state.auth


const stateSelector = createSelector(
  [location, editRaffle, auth],
  (location, editRaffle, auth) => {
    return {
      pathname: location.pathname,
      routeType: location.type,
      logoOnly: [ROUTE_TYPE_CREATE, ROUTE_TYPE_GIVEAWAY_EDIT].indexOf(location.type) != -1,
      showGiveawayEditing: [ROUTE_TYPE_GIVEAWAY].indexOf(location.type) != -1 && auth.user && editRaffle && auth.user.USERID == editRaffle.host_info.USERID
    };
  },
);

const mapStateToProps = state => {
  return { ...stateSelector(state), isStreamer: isStreamer(state) };
};

const enhance = compose(
  withAuth,
  connect(mapStateToProps),
  connect(
    null,
    mapDispatchToProps,
  ),
);
export default enhance(Layout);
