import styled from 'styled-components';
import React from 'react';
import { H3ExtraBoldBlack } from 'styles/Kit/H3ExtraBold';
import { H4ExtraBoldBlack } from 'styles/Kit/H4ExtraBold';

import { media } from '../../../../styles';

const View = styled.div`
  word-break: break-word; 
  margin: 42px 20px 15px 24px;
  ${H4ExtraBoldBlack}

  ${media.tablet`
    margin: 62px 40px 22px 40px;
    ${H3ExtraBoldBlack}
  `}

  

`;
export const Title = ({ raffle, handleLinkEvent }) => <View
  draggable="false"
  to={`/giveaways/${raffle.EVENTID}`}
  onMouseUp={handleLinkEvent}
  onClick={handleLinkEvent}
>
  {raffle && raffle.eventname ? raffle.eventname : '[no title]'}
</View>

export default Title;
