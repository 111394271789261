import React from 'react';
import MaterialTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';

const ErrorStyle = {
  tooltip: {
    borderRadius: '3px',
    boxShadow: '0 2px 4px 2px rgba(32, 32, 34, 0.05), 0 1px 1px 0 rgba(32, 32, 34, 0.1)',
    backgroundColor: '#fc521e',
    transform: 'translate(20px, -20px) !important'
  }
}

const SuccessStyle = {
  tooltip: {
    borderRadius: '3px',
    boxShadow: '0 2px 4px 2px rgba(32, 32, 34, 0.05), 0 1px 1px 0 rgba(32, 32, 34, 0.1)',
    backgroundColor: '#fc521e',
    transform: 'translate(20px, -20px) !important'
  }
}

const DefaultStyle = {
  tooltip: {
    borderRadius: '3px',
    boxShadow: '0 2px 4px 2px rgba(32, 32, 34, 0.05), 0 1px 1px 0 rgba(32, 32, 34, 0.1)',
    border: 'solid 1px #4ccbfd',
    backgroundColor: 'white',
    color: '#9697a1',
    transform: 'translate(20px, -20px) !important'
  }
}


const ErrorTooltip = withStyles(ErrorStyle)(MaterialTooltip)
const SuccessTooltip = withStyles(SuccessStyle)(MaterialTooltip)
const DefaultTooltip = withStyles(DefaultStyle)(MaterialTooltip)


const Tooltip = ({ warning, success, ...other }) => warning ? <ErrorTooltip enterTouchDelay={5} leaveTouchDelay={3000} {...other} /> : success ? <SuccessTooltip enterTouchDelay={5} leaveTouchDelay={3000} {...other} /> : <DefaultTooltip enterTouchDelay={5} leaveTouchDelay={3000} {...other} />
export default Tooltip