
export const PLATFORMID_TWITCH = '1';
export const PLATFORMID_TWITTER = '4';
export const PLATFORMID_FACEBOOK = '5';
export const PLATFORMID_YOUTUBE = '6';
export const PLATFORMID_OTHER = '7';
export const PLATFORMID_GHEED = '8';
export const PLATFORMID_INSTAGRAM = '11';
export const PLATFORMID_MIXER = '12';
export const PLATFORMID_DISCORD = '13';
export const PLATFORMID_STEAM = '14';
export const PLATFORMID_SNAPCHAT = '15';
export const PLATFORMID_TIKTOK = '16';


export const PLATFORMS = {
  [PLATFORMID_TWITCH]: {
    PLATFORMID: PLATFORMID_TWITCH,
    path: 'twitch',
    name: 'Twitch.tv',
    color: '#6441A4',
    icon: 'icon-Ico20-Social-Twitch',
    socialLink: w => `https://www.twitch.tv/${w.socialusername}`,
    link: "twitch_link",
  },
  [PLATFORMID_TWITTER]: {
    PLATFORMID: PLATFORMID_TWITTER,
    name: 'Twitter',
    path: 'twitter',
    color: '#4099ff',
    icon: 'icon-Ico20-Social-Twitter',
    socialLink: w => `https://twitter.com/${w.socialusername}`,
    link: "twitter_link",
  },

  [PLATFORMID_YOUTUBE]: {
    PLATFORMID: PLATFORMID_YOUTUBE,
    name: 'YouTube',
    path: 'youtube',
    color: '#FE0002',
    buttoncolor: '#272727',
    icon: 'icon-Ico20-Social-Youtube',
    socialLink: w => `https://www.youtube.com/user/${w.socialusername}`,
    link: "youtube_link",
  },

  [PLATFORMID_INSTAGRAM]: {
    PLATFORMID: PLATFORMID_INSTAGRAM,
    name: 'Instagram',
    path: 'gheed',
    color: '#ff8432',
    icon: 'icon-Ico20-Social-Instagram',
    link: "instagram_link",
  },
  [PLATFORMID_FACEBOOK]: {
    PLATFORMID: PLATFORMID_FACEBOOK,
    name: 'Facebook',
    path: 'gheed',
    color: '#2e3c99',
    icon: 'icon-Ico20-Social-Facebook',
    link: "facebook_link",

  },
  [PLATFORMID_GHEED]: {
    PLATFORMID: PLATFORMID_GHEED,
    name: 'Loyalty Coins',
    path: 'gheed',
    color: '#fabe19',
    icon: 'icon-Ico16-Coins',

  },
  [PLATFORMID_MIXER]: {
    PLATFORMID: PLATFORMID_MIXER,
    name: 'Mixer',
    path: 'mixer',
    color: '#00BCF2',
    icon: 'icon-Ico20-Social-Mixer',
    socialLink: w => `https://mixer.com/${w.socialusername}`,
    link: "mixer_link",

  },
  [PLATFORMID_DISCORD]: {
    PLATFORMID: PLATFORMID_DISCORD,
    name: 'Discord',
    path: 'discord',
    color: '#7289DA',
    icon: 'icon-Ico20-Social-Discord',
    link: "discord_link",
  },
  [PLATFORMID_STEAM]: {
    PLATFORMID: PLATFORMID_STEAM,
    name: 'Steam',
    path: 'steam',
    color: '#000000',
    icon: 'icon-Ico20-Social-Steam',
    link: "steam_link",
  },
  [PLATFORMID_SNAPCHAT]: {
    PLATFORMID: PLATFORMID_SNAPCHAT,
    name: 'Snapchat',
    path: 'snapchat',
    color: '#fed532',
    icon: 'icon-Ico20-Social-Snapchat',
    link: "snapchat_link",
  },
  [PLATFORMID_TIKTOK]: {
    PLATFORMID: PLATFORMID_TIKTOK,
    name: 'TikTok',
    path: 'tiktok',
    color: '#000000',
    icon: 'icon-tiktok',
    link: "tiktok_link",
  },
  [PLATFORMID_OTHER]: {
    PLATFORMID: PLATFORMID_OTHER,
    name: 'Website',
    path: 'website',
    color: '#5bcb8f',
    icon: 'icon-Ico16-Globe',
    disabled: true,
    socialLink: w => w.BENEFICIARY,
    link: "website_link",
  },
};