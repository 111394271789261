import React from 'react';
import styled from 'styled-components';
import { COIN_INTERVAL } from 'helpers/const';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;

  font-weight: 800;
  color: #fabe19;
`;

export const Coin = styled.div`
  width: 40px;
  height: 30px;
  background: url(/static/assets/images/ico-coins.svg);
  background-repeat: no-repeat;
`;

export const ProgressOuter = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #f8f9fb;
`;

export const ProgressInner = styled.div.attrs(props => ({style: {width: `${Math.floor(props.value * 100)}%`}}))`
  height: 8px;
  border-radius: 4px;
  background-color: #648acd;
`;

const Labels = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  font-size: 11px;
  font-weight: 300;

  span {
    color: #648acd;
  }
`;

export class NextCoin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { progress: 0 };
  }

  static getDerivedStateFromProps(props, state) {
    const { balance } = props;
    const floor = Math.floor(balance);

    return { progress: balance % (floor || 1) };
  }

  render() {
    const { progress } = this.state;

    return (
      <Layout>
        <Labels>
          <span>{`${Math.ceil(
            COIN_INTERVAL - progress * COIN_INTERVAL,
          )} min`}</span>
        </Labels>
        <ProgressOuter>
          <ProgressInner value={progress} />
        </ProgressOuter>
      </Layout>
    );
  }
}
