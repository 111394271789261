import { createLogic } from 'redux-logic';
import { getContestSortOptionQuery } from 'helpers/contest_sort_options';
import { RESTAPI } from '../modules/api/rest';
import { raffleCategoryListResponseAction, CATEGORY_LIST_REQUEST } from 'rdx/modules/giveawayLists';

const getRaffleCategoryListRequestLogic = createLogic({
  type: [CATEGORY_LIST_REQUEST],
  debounce: 500,
  async process({ getState, cancelled$, action }, dispatch, done) {
    try {
      const state = getState();

      const { region, regionMode } = state.giveawayLists
      const regionActiveGeneral = state.giveawayLists.regionActive;

      const category = action.payload && action.payload[0] ? action.payload[0] : state.giveawayLists.category

      const source = RESTAPI.getSource();
      cancelled$.subscribe(() => {
        source.cancel('Request cancelled');
      });

      if (category && state.giveawayLists[category]) {
        const {
          giveawayLists: {
            [category]: { regionActive, nextPage, totalPages, query, limit, productCategory },
          },
        } = state;

        if (nextPage <= totalPages) {
          let config = {
            filterPath: category == "popular" ? "active" : category, // there is no backend main category for popular
            orderbyPath: getContestSortOptionQuery(category, query),
            limit,
            page: nextPage,
            mode: 'preview',
          };

          if (productCategory) {
            config = {
              ...config,
              filter: 'category eq ${productCategory}'
            };
          }

          if ((regionActive == undefined || regionActive == true) && regionActiveGeneral && regionMode && region) {
            config = { ...config, regionMode, region }
          }

          const resp = await RESTAPI.getRafflelistFilterOrderby(config, source.token)
          dispatch(raffleCategoryListResponseAction([resp, category]));
        }
      }
    } catch (error) {
      console.log(error);
    }

    done();
  },
});

export default getRaffleCategoryListRequestLogic;
