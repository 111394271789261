import styled from 'styled-components';

export default styled.button`
  font-size: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: #36a6fc;
`