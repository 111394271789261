import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';
import { Trans, Translation } from 'react-i18next';

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;

const Content = styled.div`
  font-size: 12px !important;

  h4 {
    font-size: 12px !important;
    font-weight: 800;
    color: #9c9c9c;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px !important;
    margin-bottom: 10px;
  }

  .green {
    color: #5bcb8f;
    font-weight: bold;
  }

  ul {
    list-style-type: disc;

    li {
      margin: 10px 15px;
    }
  }
`;

class LoyaltyCoinInfoModal extends React.PureComponent {
  handleCallback = () => {
    const { data, onRequestClose } = this.props;

    if (data && data.callback) {
      data
        .callback()
        .then(() => onRequestClose())
        .catch(() => onRequestClose());
    } else {
      onRequestClose();
    }
  };

  render() {
    return (
      <Modal {...this.props}>
        <Translation>
          {t => (
            <ContentBox modal centerTitle title="Loyalty Coins">
              <Content>
                <Trans i18nKey="modals.coins.body">
                  <p>
                    <b>What are GHEEDs loyalty coins?</b>
                  </p>
                  <p>
                    Loyalty coins are earned by watching streamers on Twitch.
                    These coins can be spent for higher winning chances in their
                    giveaways.
                  </p>
                  <b>Some key facts to remember:</b>
                  <ul>
                    <li>
                      The golden rule is:
                      <span className="green">
                        1 Hour watched = 1 Coin = 1 Entry
                      </span>
                      .
                    </li>
                    <li>
                      <span className="green">
                        To earn coins you have to connect your Twitch account
                        with GHEED and join the streamers chatroom
                      </span>
                      while he is live (No VOD, no host).
                    </li>
                    <li>
                      <span className="green">
                        Every streamer has his/her own coins
                      </span>
                      . You cant spend them in other streamers giveaways.
                    </li>
                    <li>
                      You can
                      <span className="green">
                        spend additional coins as often as you like
                      </span>
                      in an active giveaway. Coin bonuses apply to the total
                      amount.
                    </li>
                    <li>
                      Changing your username does not affect your coins on
                      GHEED.
                    </li>
                  </ul>
                  <p>
                    <b>How does the coin tracking work?</b>
                  </p>
                  <p>
                    We track your presence every 15 minutes. That means, if we
                    track you 4 times (it doesnt have to be consecutive), a coin
                    will be added to your wallet. That also means, that there
                    can be some inaccuracies depending on the moment you join or
                    leave the chat.
                  </p>

                </Trans>
              </Content>

              <Controls>
                <Button outline
                  onClick={this.handleCallback}
                >{t('modals.coins.ok')}</Button>
              </Controls>
            </ContentBox>
          )}
        </Translation>
      </Modal>
    );
  }
}

export default LoyaltyCoinInfoModal;
