import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import View from './View';

const getPrizeStats = (state, props) =>
  state.raffles.prizeStats[props.raffle.EVENTID];

export const getSelectedState = createSelector([getPrizeStats], prizeStats => {
  return {
    prizeStats: prizeStats,
  };
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...getSelectedState(state, ownProps),
  };
};

export default connect(mapStateToProps)(View);
