import { runtimeConfig } from '../../../runtimeConfig';


const RUNNING_AUTHS = {};

class API {
  connect(platform, scopes = null, captcha, visibleCaptcha, redirect_path, error_path, message) {
    if (!platform) {
      return Promise.reject('No platform provided');
    }

    const { path } = platform;

    const identifier = `__${platform.PLATFORMID}_${scopes}`;
    RUNNING_AUTHS[identifier] =
      RUNNING_AUTHS[identifier] ||
      new Promise((resolve, reject) => {
        let url = `${runtimeConfig.API_DIR}/${path}/connect`;

        const params = []


        if (scopes) {
          params.push(`scope=${scopes}`)
        }
        if (captcha) {
          params.push(`captcha_response=${captcha}`)
        }

        if (visibleCaptcha) {
          params.push(`visibleCaptcha=${visibleCaptcha}`)
        }

        if (redirect_path) {
          params.push(`redirect_path=${redirect_path}`)
        }

        if (error_path) {
          params.push(`error_path=${error_path}`)
        }

        if (message) {
          params.push(`message=${message}`)
        }

        const urlWithParams = params.length ? `${url}?${params.join('&')}` : url

        if (typeof window != undefined) {
          window.location = urlWithParams;
        }

      });
  }
}

export const OAuth = new API();
export default OAuth;
