import styled from 'styled-components';

const Image = styled.figure`
  background-image: url(${({ product }) => product.images.length > 0 ? product.images[0].source : 'http://via.placeholder.com/300x240'});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-size: cover;
  
  width: 300px;
  height: 240px;
  position: absolute;
  top: 0;
`;


export default Image;