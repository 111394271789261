import { PLATFORMS } from 'helpers/const';
import axios from 'axios';
import { showNotificationModal } from '../modals';
import { OAuth } from './OAuth';

let dispatch = null;


const handleSocialError = error => {

  const { platformid, scopes } = error.response.data.data;
  const platform = PLATFORMS[platformid];

  return new Promise((resolve, reject) => {

    if (platform && dispatch) {


      dispatch(
        showNotificationModal({
          title: 'modals.notifications.notice',
          content: 'modals.notifications.try_to_connect_social',
          callback: () => {
            return OAuth.connect(
              platform,
              scopes ? scopes.join(',') : null,
              null,
              typeof window != "undefined" ? window.location.pathname : '/',
              typeof window != "undefined" ? window.location.pathname : '/'
            )
          },
        }),
      );
    } else {
      reject(error)
    }

  })



};


export const axiosJWTMiddleWare = store => next => action => {
  const result = next(action);
  if (!dispatch) {

    dispatch = store.dispatch;


    // Refresh Access Tokens if necessary or notify Redux of error
    axios.interceptors.response.use(
      null,
      error => {
        if (
          error.response &&
          error.response.status == 403 &&
          error.response.data &&
          error.response.data.message &&
          (error.response.data.message == 'Scope is missing' ||
            error.response.data.message == 'Social is missing')
        ) {
          return handleSocialError(error);
        }

        return Promise.reject(error);
      },
    );
  }

  return result;
};
