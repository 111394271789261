import React from 'react';
import { connect } from 'react-redux';
import { utils } from 'helpers/utils';
import { Translation } from 'react-i18next';
import Link from 'redux-first-router-link';
import { H5ExtraBoldBlack } from 'styles/Kit/H5ExtraBold';
import { BodySmallRegularBlack } from 'styles/Kit/BodySmallRegular';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  > * {

    ${BodySmallRegularBlack}

    :first-child {
      ${H5ExtraBoldBlack}
    }
  }
`

const Thumb = styled.div.attrs(props => ({
  style: {
    backgroundImage: `url(${props.url})`,
  }
}))`
  margin-right: 10px;
  border-radius: 2px;
  width: 48px;
  height: 38px;
  background-clip: padding-box;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  flex: 0 0 auto;
`;




const Sponsor = ({ product, t }) => {
  return product.sponsor ? <span>
    {t('by')}{' '}
    {product.sponsor_link ? <Link
      to={product.sponsor_link}
      alt={product.sponsor}
      target="_blank"
      rel="noopener noreferrer"
    >
      {product.sponsor}
    </Link> : <span>{product.sponsor}</span>}

  </span> : null
}

const ProductColumn = ({ product, variation }) => {

  const isPromotion = product && product.categories && product.categories[0] && product.categories[0].slug == 'promotion'

  return <Translation>{t => (<Layout>
    <Thumb url={product && product.images && product.images[0] ? utils.resolveImageAsset(product.images[0]) : utils.getRandomProductImageFallback()} />
    <Content>
      <div>{product && (product.name || product.productname)} {variation ? `${'\u00A0'}(${variation.attributes.map(a => `${a.options[0]}`).join(', ')})` : undefined}</div>
      {product && <Sponsor product={product} t={t} />}
      {isPromotion && <div>Feature</div>}
    </Content>
  </Layout>)}</Translation>
}

const mapStateToProps = (state, ownProps) => {

  const { shop_id, shopproduct_id, shopvariation_id, product, variation } = ownProps;

  const getProduct = product ? product : shop_id == 2 ? state.products.products && state.products.products[shopproduct_id] : state.privateProducts.data[shopproduct_id];
  const getVariation = variation ? variation : shop_id == 2 && shopvariation_id && product && product.variations.find(v => v.PRODUCTID == shopvariation_id)

  return {
    product: getProduct,
    variation: getVariation
  }
}

export default connect(mapStateToProps)(ProductColumn)
