import { handleActions } from 'redux-actions';
import { GET_NOTIFICATIONS_RESPONSE } from '../api';

const initialState = {
  notifications: [],
  error: null,
  loading: false,
};

export const notificationsReducer = handleActions(
  {
    [GET_NOTIFICATIONS_RESPONSE]: (state, action) => ({
      ...state,
      notifications: action.payload.notifications,
    }),
  },
  initialState,
);

export default notificationsReducer;
