import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import View from './View';

const getUser = state => state.auth.user;

export const getSelectedState = createSelector([getUser], user => {
  return {
    user: user,
  };
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...getSelectedState(state, ownProps),
  };
};

export default connect(mapStateToProps)(View);
