import React, { Component } from 'react';
import styled from 'styled-components';
import numeral from 'i18n/numeral';

import { TimerDisplay } from 'components/Raffles/Detail/MainBox/TimeAndEntries/TimerDisplay';
import { Translation } from 'react-i18next';
import { media } from '../../../../../styles';
import { RAFFLE_STATE_ACTIVE, RAFFLE_STATE_FINISHED, RAFFLE_STATE_ANNOUNCED } from 'helpers/const';
import { time } from '../../../../../helpers/time';
import { Label } from './Label';
import { SERVER_DATE_DIFFERENCE } from 'rdx/modules/api/response-interceptor';

const Layout = styled.div`
  display: flex;
  width: 100%;

  ${media.tabletMax`
    flex-direction: column;
    margin: 20px 0;
  `};

  > * {
    flex: 1 1 auto;

    ${media.tabletMax`
    flex: 0 0 15px;
    `};
  }
`;

const Num = styled.h3`
  font-size: 11px;

  ${media.tablet`
font-size: 20px;
`};
  font-weight: 800;
`;

const getLabel = timer => {
  if (!timer || (timer && timer.mode == RAFFLE_STATE_ANNOUNCED)) {
    return 'ga_title_launch_in';
  }

  if (timer && timer.mode == RAFFLE_STATE_ACTIVE) {
    return 'ga_title_time_left';
  }

  if (timer && timer.mode == RAFFLE_STATE_FINISHED) {
    return 'ga_title_status';
  }
};


export class TimeAndEntries extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { raffle, timer, ways, user_entries, preview } = this.props;

    const timeInfo = preview ? time.getRaffleTimeInfo(raffle, SERVER_DATE_DIFFERENCE) : timer ? timer : time.getRaffleTimeInfo(raffle, SERVER_DATE_DIFFERENCE);
    const waysentries = ways ? ways.reduce((p, c) => p + c.earned_amount + c.bonus_amount, 0) : 0;
    const myentries = user_entries ? user_entries : waysentries;

    return (
      <Translation>
        {t => (
          <Layout>
            <Label title={t(getLabel(timeInfo))}>
              {timeInfo ? <TimerDisplay {...{ timer: timeInfo, giveaway: raffle }} /> : <Num>[no time set]</Num>}
            </Label>
            <Label title={t('ga_title_competitors')}>
              <Num>{numeral(raffle.participants).format('0,0') || '0'}</Num>
            </Label>
            <Label title={t('ga_title_entries')}>
              <Num>{numeral(raffle.total_entries).format('0,0') || '0'}</Num>
            </Label>

            <Label title={t('ga_title_your_entries')}>
              <Num>{numeral(myentries).format('0,0')}</Num>
            </Label>
          </Layout>
        )}
      </Translation>
    );
  }
}


export default TimeAndEntries;
