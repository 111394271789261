
    import { createLogic } from 'redux-logic';
    import { AUTH_LOGOUT_REQUEST } from '../auth';
    import { PUT_CLAIM_PRIZE_REQUEST, 
PUT_CLAIM_PRIZE_CANCELED, 
GET_CLAIM_PRIZE_REQUEST, 
GET_CLAIM_PRIZE_CANCELED, 
GET_CONTESTGENERATION_REQUEST, 
GET_CONTESTGENERATION_CANCELED, 
PUT_PRIVATE_PRODUCT_BY_EVENTID_REQUEST, 
PUT_PRIVATE_PRODUCT_BY_EVENTID_CANCELED, 
GET_PRIVATE_PRODUCT_BY_EVENTID_REQUEST, 
GET_PRIVATE_PRODUCT_BY_EVENTID_CANCELED, 
GET_PRIVATE_PRODUCT_REQUEST, 
GET_PRIVATE_PRODUCT_CANCELED, 
GET_AUTH_STATE_REQUEST, 
GET_AUTH_STATE_CANCELED, 
GET_AUTH_STATE_READ_REQUEST, 
GET_AUTH_STATE_READ_CANCELED, 
POST_BETA_APPLY_REQUEST, 
POST_BETA_APPLY_CANCELED, 
GET_BOT_SCORE_REQUEST, 
GET_BOT_SCORE_CANCELED, 
GET_TWITTER_BOT_SCORE_REQUEST, 
GET_TWITTER_BOT_SCORE_CANCELED, 
PUT_CART_REQUEST, 
PUT_CART_CANCELED, 
GET_CART_REQUEST, 
GET_CART_CANCELED, 
PUT_COUPON_REQUEST, 
PUT_COUPON_CANCELED, 
DELETE_COUPON_REQUEST, 
DELETE_COUPON_CANCELED, 
GET_CATEGORIES_REQUEST, 
GET_CATEGORIES_CANCELED, 
POST_CONTACT_REQUEST, 
POST_CONTACT_CANCELED, 
GET_DISCORDCONFIRM_REQUEST, 
GET_DISCORDCONFIRM_CANCELED, 
GET_DISCORDCONNECT_REQUEST, 
GET_DISCORDCONNECT_CANCELED, 
GET_DISCORDLANDING_REQUEST, 
GET_DISCORDLANDING_CANCELED, 
GET_WINNER_DRAW_REQUEST, 
GET_WINNER_DRAW_CANCELED, 
GET_WINNER_DRAW_MAX_REQUEST, 
GET_WINNER_DRAW_MAX_CANCELED, 
GET_WINNER_DRAW_MED_REQUEST, 
GET_WINNER_DRAW_MED_CANCELED, 
GET_PRIVATE_GIVEAWAY_INFOS_REQUEST, 
GET_PRIVATE_GIVEAWAY_INFOS_CANCELED, 
GET_WINNER_DRAW_MANUAL_REQUEST, 
GET_WINNER_DRAW_MANUAL_CANCELED, 
GET_EXT_REDIRECT_BY_ID_REQUEST, 
GET_EXT_REDIRECT_BY_ID_CANCELED, 
GET_EXT_REDIRECT_CREATE_REQUEST, 
GET_EXT_REDIRECT_CREATE_CANCELED, 
POST_EMAIL_PW_CHANGE_REQUEST, 
POST_EMAIL_PW_CHANGE_CANCELED, 
POST_EMAIL_CHANGE_REQUEST, 
POST_EMAIL_CHANGE_CANCELED, 
GET_EMAIL_CHANGE_REQUEST, 
GET_EMAIL_CHANGE_CANCELED, 
POST_EMAIL_LOGIN_REQUEST, 
POST_EMAIL_LOGIN_CANCELED, 
POST_EMAIL_REGISTER_REQUEST, 
POST_EMAIL_REGISTER_CANCELED, 
PUT_EMAIL_PW_RESET_REQUEST, 
PUT_EMAIL_PW_RESET_CANCELED, 
POST_EMAIL_PW_RESET_REQUEST, 
POST_EMAIL_PW_RESET_CANCELED, 
POST_EMAIL_VERIFY_REQUEST, 
POST_EMAIL_VERIFY_CANCELED, 
GET_EMAIL_VERIFY_REQUEST, 
GET_EMAIL_VERIFY_CANCELED, 
POST_ENTRYWAYS_ENTITY_REQUEST, 
POST_ENTRYWAYS_ENTITY_CANCELED, 
GET_ENTRYWAYS_ENTITY_REQUEST, 
GET_ENTRYWAYS_ENTITY_CANCELED, 
GET_ENTRYWAYS_ENTITY_URL_REQUEST, 
GET_ENTRYWAYS_ENTITY_URL_CANCELED, 
GET_FACEBOOKCONFIRM_REQUEST, 
GET_FACEBOOKCONFIRM_CANCELED, 
GET_FACEBOOK_CONNECT_REQUEST, 
GET_FACEBOOK_CONNECT_CANCELED, 
GET_FACEBOOKLANDING_REQUEST, 
GET_FACEBOOKLANDING_CANCELED, 
POST_IMAGES_REQUEST, 
POST_IMAGES_CANCELED, 
GET_IMAGES_REQUEST, 
GET_IMAGES_CANCELED, 
GET_IMAGES_PRODUCTS_REQUEST, 
GET_IMAGES_PRODUCTS_CANCELED, 
GET_IMAGES_BY_UUID_REQUEST, 
GET_IMAGES_BY_UUID_CANCELED, 
DELETE_JWT_COOKIE_REQUEST, 
DELETE_JWT_COOKIE_CANCELED, 
GET_JWT_COOKIE_REQUEST, 
GET_JWT_COOKIE_CANCELED, 
DELETE_JWT_PROTECTED_REQUEST, 
DELETE_JWT_PROTECTED_CANCELED, 
GET_JWT_OPTIONAL_REQUEST, 
GET_JWT_OPTIONAL_CANCELED, 
GET_JWT_PROTECTED_REQUEST, 
GET_JWT_PROTECTED_CANCELED, 
DELETE_JWT_REFRESH_REQUEST, 
DELETE_JWT_REFRESH_CANCELED, 
GET_JWT_REFRESH_REQUEST, 
GET_JWT_REFRESH_CANCELED, 
DELETE_JWT_TOKEN_REQUEST, 
DELETE_JWT_TOKEN_CANCELED, 
GET_JWT_TOKEN_REQUEST, 
GET_JWT_TOKEN_CANCELED, 
GET_MIXERCONFIRM_REQUEST, 
GET_MIXERCONFIRM_CANCELED, 
GET_MIXERCONNECT_REQUEST, 
GET_MIXERCONNECT_CANCELED, 
GET_MIXERLANDING_REQUEST, 
GET_MIXERLANDING_CANCELED, 
PUT_NOTIFICATIONS_REQUEST, 
PUT_NOTIFICATIONS_CANCELED, 
GET_NOTIFICATIONS_REQUEST, 
GET_NOTIFICATIONS_CANCELED, 
PUT_PAYMENT_REQUEST, 
PUT_PAYMENT_CANCELED, 
GET_PAYMENT_REQUEST, 
GET_PAYMENT_CANCELED, 
GET_PRODUCTS_REQUEST, 
GET_PRODUCTS_CANCELED, 
GET_PRODUCTS_BY_ID_REQUEST, 
GET_PRODUCTS_BY_ID_CANCELED, 
GET_PRODUCTS_BY_ID_STATUS_REQUEST, 
GET_PRODUCTS_BY_ID_STATUS_CANCELED, 
GET_VARIATIONS_REQUEST, 
GET_VARIATIONS_CANCELED, 
GET_VERIFICATION_GENERATE_REQUEST, 
GET_VERIFICATION_GENERATE_CANCELED, 
POST_VERIFICATION_GENERATE_REQUEST, 
POST_VERIFICATION_GENERATE_CANCELED, 
GET_PUBLIC_USER_ID_REQUEST, 
GET_PUBLIC_USER_ID_CANCELED, 
GET_PUBLIC_USER_COINS_REQUEST, 
GET_PUBLIC_USER_COINS_CANCELED, 
GET_REDIRECT_BY_ID_REQUEST, 
GET_REDIRECT_BY_ID_CANCELED, 
GET_REDIRECT_CREATE_REQUEST, 
GET_REDIRECT_CREATE_CANCELED, 
POST_RAFFLELIST_REQUEST, 
POST_RAFFLELIST_CANCELED, 
GET_RAFFLELIST_REQUEST, 
GET_RAFFLELIST_CANCELED, 
GET_RAFFLELIST_ENTRIES_REQUEST, 
GET_RAFFLELIST_ENTRIES_CANCELED, 
GET_RAFFLES_OWN_REQUEST, 
GET_RAFFLES_OWN_CANCELED, 
GET_RAFFLELIST_PARTICIPATED_REQUEST, 
GET_RAFFLELIST_PARTICIPATED_CANCELED, 
GET_PRIZES_BY_RAFFLE_ID_REQUEST, 
GET_PRIZES_BY_RAFFLE_ID_CANCELED, 
GET_RAFFLE_PROMOTION_REQUEST, 
GET_RAFFLE_PROMOTION_CANCELED, 
GET_RAFFLELIST_SEARCH_REQUEST, 
GET_RAFFLELIST_SEARCH_CANCELED, 
PUT_RAFFLES_REQUEST, 
PUT_RAFFLES_CANCELED, 
GET_RAFFLES_REQUEST, 
GET_RAFFLES_CANCELED, 
PUT_RAFFLES_FINALIZE_REQUEST, 
PUT_RAFFLES_FINALIZE_CANCELED, 
GET_WINNERS_BY_RAFFLE_ID_REQUEST, 
GET_WINNERS_BY_RAFFLE_ID_CANCELED, 
GET_RAFFLELIST_FILTER_ORDERBY_REQUEST, 
GET_RAFFLELIST_FILTER_ORDERBY_CANCELED, 
GET_RAFFLE_BY_ID_REQUEST, 
GET_RAFFLE_BY_ID_CANCELED, 
GET_RAFFLE_BY_ID_COUPONS_REQUEST, 
GET_RAFFLE_BY_ID_COUPONS_CANCELED, 
GET_RAFFLE_BY_ID_COUPONS_STATUS_REQUEST, 
GET_RAFFLE_BY_ID_COUPONS_STATUS_CANCELED, 
GET_DRAW_STATUS_BY_RAFFLE_ID_REQUEST, 
GET_DRAW_STATUS_BY_RAFFLE_ID_CANCELED, 
PUT_RAFFLE_BY_ID_EDIT_REQUEST, 
PUT_RAFFLE_BY_ID_EDIT_CANCELED, 
GET_RAFFLE_BY_ID_EDIT_REQUEST, 
GET_RAFFLE_BY_ID_EDIT_CANCELED, 
GET_RAFFLE_BY_ID_ENTRIES_REQUEST, 
GET_RAFFLE_BY_ID_ENTRIES_CANCELED, 
POST_RAFFLES_ENTRYWAYS_REQUEST, 
POST_RAFFLES_ENTRYWAYS_CANCELED, 
GET_RAFFLES_ENTRYWAYS_REQUEST, 
GET_RAFFLES_ENTRYWAYS_CANCELED, 
POST_RAFFLE_BY_ID_REPORT_REQUEST, 
POST_RAFFLE_BY_ID_REPORT_CANCELED, 
GET_WAYS_BY_RAFFLE_ID_REQUEST, 
GET_WAYS_BY_RAFFLE_ID_CANCELED, 
PUT_WAYS_BY_RAFFLE_I_DCOPY_REQUEST, 
PUT_WAYS_BY_RAFFLE_I_DCOPY_CANCELED, 
GET_WAYS_BY_RAFFLE_ID_STATUS_REQUEST, 
GET_WAYS_BY_RAFFLE_ID_STATUS_CANCELED, 
GET_RECAPTCHA_REQUEST, 
GET_RECAPTCHA_CANCELED, 
PUT_SALE_REQUEST, 
PUT_SALE_CANCELED, 
GET_SALE_REQUEST, 
GET_SALE_CANCELED, 
GET_SALECANCEL_REQUEST, 
GET_SALECANCEL_CANCELED, 
GET_SALECONFIRM_REQUEST, 
GET_SALECONFIRM_CANCELED, 
GET_USERCOUNT_REQUEST, 
GET_USERCOUNT_CANCELED, 
DELETE_SOCIALS_ENTITY_REQUEST, 
DELETE_SOCIALS_ENTITY_CANCELED, 
GET_SOCIALS_SCOPES_REQUEST, 
GET_SOCIALS_SCOPES_CANCELED, 
GET_COINLEADERS_REQUEST, 
GET_COINLEADERS_CANCELED, 
POST_SUPPORT_REQUEST, 
POST_SUPPORT_CANCELED, 
GET_TWITCHCONFIRM_REQUEST, 
GET_TWITCHCONFIRM_CANCELED, 
GET_TWITCHCONNECT_REQUEST, 
GET_TWITCHCONNECT_CANCELED, 
GET_TWITCHLANDING_REQUEST, 
GET_TWITCHLANDING_CANCELED, 
GET_TWITTERCALLBACK_REQUEST, 
GET_TWITTERCALLBACK_CANCELED, 
GET_TWITTERCONFIRM_REQUEST, 
GET_TWITTERCONFIRM_CANCELED, 
GET_TWITTER_CONNECT_REQUEST, 
GET_TWITTER_CONNECT_CANCELED, 
POST_BILLING_REQUEST, 
POST_BILLING_CANCELED, 
GET_BILLING_REQUEST, 
GET_BILLING_CANCELED, 
PUT_BUSINESS_USER_REQUEST, 
PUT_BUSINESS_USER_CANCELED, 
POST_USER_DELETE_REQUEST, 
POST_USER_DELETE_CANCELED, 
GET_USER_DELETE_REQUEST, 
GET_USER_DELETE_CANCELED, 
GET_USERS_REGIONS_GUESS_REQUEST, 
GET_USERS_REGIONS_GUESS_CANCELED, 
PUT_USERS_SETTINGS_REQUEST, 
PUT_USERS_SETTINGS_CANCELED, 
GET_USERS_SETTINGS_REQUEST, 
GET_USERS_SETTINGS_CANCELED, 
PUT_USERS_SETTINGS_REFRESH_REQUEST, 
PUT_USERS_SETTINGS_REFRESH_CANCELED, 
PUT_WALLETS_REQUEST, 
PUT_WALLETS_CANCELED, 
GET_WALLETS_REQUEST, 
GET_WALLETS_CANCELED, 
GET_WAYTYPES_REQUEST, 
GET_WAYTYPES_CANCELED, 
GET_GHEED_APP_DOWNLOAD_ENTRYWAY_META_ENDPOINT_REQUEST, 
GET_GHEED_APP_DOWNLOAD_ENTRYWAY_META_ENDPOINT_CANCELED, 
GET_GHEED_MULTIPLE_CHOICE_ENTRYWAY_META_ENDPOINT_REQUEST, 
GET_GHEED_MULTIPLE_CHOICE_ENTRYWAY_META_ENDPOINT_CANCELED, 
GET_GHEED_SECRET_CODE_ENTRYWAY_META_ENDPOINT_REQUEST, 
GET_GHEED_SECRET_CODE_ENTRYWAY_META_ENDPOINT_CANCELED, 
GET_GHEED_IMAGE_CHOICE_ENTRYWAY_META_ENDPOINT_REQUEST, 
GET_GHEED_IMAGE_CHOICE_ENTRYWAY_META_ENDPOINT_CANCELED, 
GET_GHEED_QUESTION_ANSWER_ENTRYWAY_META_ENDPOINT_REQUEST, 
GET_GHEED_QUESTION_ANSWER_ENTRYWAY_META_ENDPOINT_CANCELED, 
GET_GHEED_SINGLE_CHOICE_ENTRYWAY_META_ENDPOINT_REQUEST, 
GET_GHEED_SINGLE_CHOICE_ENTRYWAY_META_ENDPOINT_CANCELED, 
GET_WAYTYPES_LOOKUP_REQUEST, 
GET_WAYTYPES_LOOKUP_CANCELED, 
GET_WAYTYPES_QUERY_REQUEST, 
GET_WAYTYPES_QUERY_CANCELED, 
GET_WAYTYPES_VALIDATE_REQUEST, 
GET_WAYTYPES_VALIDATE_CANCELED, 
GET_BLOGPOST_BY_SLUG_REQUEST, 
GET_BLOGPOST_BY_SLUG_CANCELED, 
GET_CSS_REQUEST, 
GET_CSS_CANCELED, 
GET_YOUTUBECONFIRM_REQUEST, 
GET_YOUTUBECONFIRM_CANCELED, 
GET_YOUTUBECONNECT_REQUEST, 
GET_YOUTUBECONNECT_CANCELED, 
GET_YOUTUBELANDING_REQUEST, 
GET_YOUTUBELANDING_CANCELED, putClaimPrizeSendingAction, 
putClaimPrizeResponseAction, 
putClaimPrizeErrorAction, 
getClaimPrizeSendingAction, 
getClaimPrizeResponseAction, 
getClaimPrizeErrorAction, 
getContestgenerationSendingAction, 
getContestgenerationResponseAction, 
getContestgenerationErrorAction, 
putPrivateProductByEventidSendingAction, 
putPrivateProductByEventidResponseAction, 
putPrivateProductByEventidErrorAction, 
getPrivateProductByEventidSendingAction, 
getPrivateProductByEventidResponseAction, 
getPrivateProductByEventidErrorAction, 
getPrivateProductSendingAction, 
getPrivateProductResponseAction, 
getPrivateProductErrorAction, 
getAuthStateSendingAction, 
getAuthStateResponseAction, 
getAuthStateErrorAction, 
getAuthStateReadSendingAction, 
getAuthStateReadResponseAction, 
getAuthStateReadErrorAction, 
postBetaApplySendingAction, 
postBetaApplyResponseAction, 
postBetaApplyErrorAction, 
getBotScoreSendingAction, 
getBotScoreResponseAction, 
getBotScoreErrorAction, 
getTwitterBotScoreSendingAction, 
getTwitterBotScoreResponseAction, 
getTwitterBotScoreErrorAction, 
putCartSendingAction, 
putCartResponseAction, 
putCartErrorAction, 
getCartSendingAction, 
getCartResponseAction, 
getCartErrorAction, 
putCouponSendingAction, 
putCouponResponseAction, 
putCouponErrorAction, 
deleteCouponSendingAction, 
deleteCouponResponseAction, 
deleteCouponErrorAction, 
getCategoriesSendingAction, 
getCategoriesResponseAction, 
getCategoriesErrorAction, 
postContactSendingAction, 
postContactResponseAction, 
postContactErrorAction, 
getDiscordconfirmSendingAction, 
getDiscordconfirmResponseAction, 
getDiscordconfirmErrorAction, 
getDiscordconnectSendingAction, 
getDiscordconnectResponseAction, 
getDiscordconnectErrorAction, 
getDiscordlandingSendingAction, 
getDiscordlandingResponseAction, 
getDiscordlandingErrorAction, 
getWinnerDrawSendingAction, 
getWinnerDrawResponseAction, 
getWinnerDrawErrorAction, 
getWinnerDrawMaxSendingAction, 
getWinnerDrawMaxResponseAction, 
getWinnerDrawMaxErrorAction, 
getWinnerDrawMedSendingAction, 
getWinnerDrawMedResponseAction, 
getWinnerDrawMedErrorAction, 
getPrivateGiveawayInfosSendingAction, 
getPrivateGiveawayInfosResponseAction, 
getPrivateGiveawayInfosErrorAction, 
getWinnerDrawManualSendingAction, 
getWinnerDrawManualResponseAction, 
getWinnerDrawManualErrorAction, 
getExtRedirectByIdSendingAction, 
getExtRedirectByIdResponseAction, 
getExtRedirectByIdErrorAction, 
getExtRedirectCreateSendingAction, 
getExtRedirectCreateResponseAction, 
getExtRedirectCreateErrorAction, 
postEmailPwChangeSendingAction, 
postEmailPwChangeResponseAction, 
postEmailPwChangeErrorAction, 
postEmailChangeSendingAction, 
postEmailChangeResponseAction, 
postEmailChangeErrorAction, 
getEmailChangeSendingAction, 
getEmailChangeResponseAction, 
getEmailChangeErrorAction, 
postEmailLoginSendingAction, 
postEmailLoginResponseAction, 
postEmailLoginErrorAction, 
postEmailRegisterSendingAction, 
postEmailRegisterResponseAction, 
postEmailRegisterErrorAction, 
putEmailPwResetSendingAction, 
putEmailPwResetResponseAction, 
putEmailPwResetErrorAction, 
postEmailPwResetSendingAction, 
postEmailPwResetResponseAction, 
postEmailPwResetErrorAction, 
postEmailVerifySendingAction, 
postEmailVerifyResponseAction, 
postEmailVerifyErrorAction, 
getEmailVerifySendingAction, 
getEmailVerifyResponseAction, 
getEmailVerifyErrorAction, 
postEntrywaysEntitySendingAction, 
postEntrywaysEntityResponseAction, 
postEntrywaysEntityErrorAction, 
getEntrywaysEntitySendingAction, 
getEntrywaysEntityResponseAction, 
getEntrywaysEntityErrorAction, 
getEntrywaysEntityUrlSendingAction, 
getEntrywaysEntityUrlResponseAction, 
getEntrywaysEntityUrlErrorAction, 
getFacebookconfirmSendingAction, 
getFacebookconfirmResponseAction, 
getFacebookconfirmErrorAction, 
getFacebookConnectSendingAction, 
getFacebookConnectResponseAction, 
getFacebookConnectErrorAction, 
getFacebooklandingSendingAction, 
getFacebooklandingResponseAction, 
getFacebooklandingErrorAction, 
postImagesSendingAction, 
postImagesResponseAction, 
postImagesErrorAction, 
getImagesSendingAction, 
getImagesResponseAction, 
getImagesErrorAction, 
getImagesProductsSendingAction, 
getImagesProductsResponseAction, 
getImagesProductsErrorAction, 
getImagesByUuidSendingAction, 
getImagesByUuidResponseAction, 
getImagesByUuidErrorAction, 
deleteJwtCookieSendingAction, 
deleteJwtCookieResponseAction, 
deleteJwtCookieErrorAction, 
getJwtCookieSendingAction, 
getJwtCookieResponseAction, 
getJwtCookieErrorAction, 
deleteJwtProtectedSendingAction, 
deleteJwtProtectedResponseAction, 
deleteJwtProtectedErrorAction, 
getJwtOptionalSendingAction, 
getJwtOptionalResponseAction, 
getJwtOptionalErrorAction, 
getJwtProtectedSendingAction, 
getJwtProtectedResponseAction, 
getJwtProtectedErrorAction, 
deleteJwtRefreshSendingAction, 
deleteJwtRefreshResponseAction, 
deleteJwtRefreshErrorAction, 
getJwtRefreshSendingAction, 
getJwtRefreshResponseAction, 
getJwtRefreshErrorAction, 
deleteJwtTokenSendingAction, 
deleteJwtTokenResponseAction, 
deleteJwtTokenErrorAction, 
getJwtTokenSendingAction, 
getJwtTokenResponseAction, 
getJwtTokenErrorAction, 
getMixerconfirmSendingAction, 
getMixerconfirmResponseAction, 
getMixerconfirmErrorAction, 
getMixerconnectSendingAction, 
getMixerconnectResponseAction, 
getMixerconnectErrorAction, 
getMixerlandingSendingAction, 
getMixerlandingResponseAction, 
getMixerlandingErrorAction, 
putNotificationsSendingAction, 
putNotificationsResponseAction, 
putNotificationsErrorAction, 
getNotificationsSendingAction, 
getNotificationsResponseAction, 
getNotificationsErrorAction, 
putPaymentSendingAction, 
putPaymentResponseAction, 
putPaymentErrorAction, 
getPaymentSendingAction, 
getPaymentResponseAction, 
getPaymentErrorAction, 
getProductsSendingAction, 
getProductsResponseAction, 
getProductsErrorAction, 
getProductsByIdSendingAction, 
getProductsByIdResponseAction, 
getProductsByIdErrorAction, 
getProductsByIdStatusSendingAction, 
getProductsByIdStatusResponseAction, 
getProductsByIdStatusErrorAction, 
getVariationsSendingAction, 
getVariationsResponseAction, 
getVariationsErrorAction, 
getVerificationGenerateSendingAction, 
getVerificationGenerateResponseAction, 
getVerificationGenerateErrorAction, 
postVerificationGenerateSendingAction, 
postVerificationGenerateResponseAction, 
postVerificationGenerateErrorAction, 
getPublicUserIdSendingAction, 
getPublicUserIdResponseAction, 
getPublicUserIdErrorAction, 
getPublicUserCoinsSendingAction, 
getPublicUserCoinsResponseAction, 
getPublicUserCoinsErrorAction, 
getRedirectByIdSendingAction, 
getRedirectByIdResponseAction, 
getRedirectByIdErrorAction, 
getRedirectCreateSendingAction, 
getRedirectCreateResponseAction, 
getRedirectCreateErrorAction, 
postRafflelistSendingAction, 
postRafflelistResponseAction, 
postRafflelistErrorAction, 
getRafflelistSendingAction, 
getRafflelistResponseAction, 
getRafflelistErrorAction, 
getRafflelistEntriesSendingAction, 
getRafflelistEntriesResponseAction, 
getRafflelistEntriesErrorAction, 
getRafflesOwnSendingAction, 
getRafflesOwnResponseAction, 
getRafflesOwnErrorAction, 
getRafflelistParticipatedSendingAction, 
getRafflelistParticipatedResponseAction, 
getRafflelistParticipatedErrorAction, 
getPrizesByRaffleIdSendingAction, 
getPrizesByRaffleIdResponseAction, 
getPrizesByRaffleIdErrorAction, 
getRafflePromotionSendingAction, 
getRafflePromotionResponseAction, 
getRafflePromotionErrorAction, 
getRafflelistSearchSendingAction, 
getRafflelistSearchResponseAction, 
getRafflelistSearchErrorAction, 
putRafflesSendingAction, 
putRafflesResponseAction, 
putRafflesErrorAction, 
getRafflesSendingAction, 
getRafflesResponseAction, 
getRafflesErrorAction, 
putRafflesFinalizeSendingAction, 
putRafflesFinalizeResponseAction, 
putRafflesFinalizeErrorAction, 
getWinnersByRaffleIdSendingAction, 
getWinnersByRaffleIdResponseAction, 
getWinnersByRaffleIdErrorAction, 
getRafflelistFilterOrderbySendingAction, 
getRafflelistFilterOrderbyResponseAction, 
getRafflelistFilterOrderbyErrorAction, 
getRaffleByIdSendingAction, 
getRaffleByIdResponseAction, 
getRaffleByIdErrorAction, 
getRaffleByIdCouponsSendingAction, 
getRaffleByIdCouponsResponseAction, 
getRaffleByIdCouponsErrorAction, 
getRaffleByIdCouponsStatusSendingAction, 
getRaffleByIdCouponsStatusResponseAction, 
getRaffleByIdCouponsStatusErrorAction, 
getDrawStatusByRaffleIdSendingAction, 
getDrawStatusByRaffleIdResponseAction, 
getDrawStatusByRaffleIdErrorAction, 
putRaffleByIdEditSendingAction, 
putRaffleByIdEditResponseAction, 
putRaffleByIdEditErrorAction, 
getRaffleByIdEditSendingAction, 
getRaffleByIdEditResponseAction, 
getRaffleByIdEditErrorAction, 
getRaffleByIdEntriesSendingAction, 
getRaffleByIdEntriesResponseAction, 
getRaffleByIdEntriesErrorAction, 
postRafflesEntrywaysSendingAction, 
postRafflesEntrywaysResponseAction, 
postRafflesEntrywaysErrorAction, 
getRafflesEntrywaysSendingAction, 
getRafflesEntrywaysResponseAction, 
getRafflesEntrywaysErrorAction, 
postRaffleByIdReportSendingAction, 
postRaffleByIdReportResponseAction, 
postRaffleByIdReportErrorAction, 
getWaysByRaffleIdSendingAction, 
getWaysByRaffleIdResponseAction, 
getWaysByRaffleIdErrorAction, 
putWaysByRaffleIDcopySendingAction, 
putWaysByRaffleIDcopyResponseAction, 
putWaysByRaffleIDcopyErrorAction, 
getWaysByRaffleIdStatusSendingAction, 
getWaysByRaffleIdStatusResponseAction, 
getWaysByRaffleIdStatusErrorAction, 
getRecaptchaSendingAction, 
getRecaptchaResponseAction, 
getRecaptchaErrorAction, 
putSaleSendingAction, 
putSaleResponseAction, 
putSaleErrorAction, 
getSaleSendingAction, 
getSaleResponseAction, 
getSaleErrorAction, 
getSalecancelSendingAction, 
getSalecancelResponseAction, 
getSalecancelErrorAction, 
getSaleconfirmSendingAction, 
getSaleconfirmResponseAction, 
getSaleconfirmErrorAction, 
getUsercountSendingAction, 
getUsercountResponseAction, 
getUsercountErrorAction, 
deleteSocialsEntitySendingAction, 
deleteSocialsEntityResponseAction, 
deleteSocialsEntityErrorAction, 
getSocialsScopesSendingAction, 
getSocialsScopesResponseAction, 
getSocialsScopesErrorAction, 
getCoinleadersSendingAction, 
getCoinleadersResponseAction, 
getCoinleadersErrorAction, 
postSupportSendingAction, 
postSupportResponseAction, 
postSupportErrorAction, 
getTwitchconfirmSendingAction, 
getTwitchconfirmResponseAction, 
getTwitchconfirmErrorAction, 
getTwitchconnectSendingAction, 
getTwitchconnectResponseAction, 
getTwitchconnectErrorAction, 
getTwitchlandingSendingAction, 
getTwitchlandingResponseAction, 
getTwitchlandingErrorAction, 
getTwittercallbackSendingAction, 
getTwittercallbackResponseAction, 
getTwittercallbackErrorAction, 
getTwitterconfirmSendingAction, 
getTwitterconfirmResponseAction, 
getTwitterconfirmErrorAction, 
getTwitterConnectSendingAction, 
getTwitterConnectResponseAction, 
getTwitterConnectErrorAction, 
postBillingSendingAction, 
postBillingResponseAction, 
postBillingErrorAction, 
getBillingSendingAction, 
getBillingResponseAction, 
getBillingErrorAction, 
putBusinessUserSendingAction, 
putBusinessUserResponseAction, 
putBusinessUserErrorAction, 
postUserDeleteSendingAction, 
postUserDeleteResponseAction, 
postUserDeleteErrorAction, 
getUserDeleteSendingAction, 
getUserDeleteResponseAction, 
getUserDeleteErrorAction, 
getUsersRegionsGuessSendingAction, 
getUsersRegionsGuessResponseAction, 
getUsersRegionsGuessErrorAction, 
putUsersSettingsSendingAction, 
putUsersSettingsResponseAction, 
putUsersSettingsErrorAction, 
getUsersSettingsSendingAction, 
getUsersSettingsResponseAction, 
getUsersSettingsErrorAction, 
putUsersSettingsRefreshSendingAction, 
putUsersSettingsRefreshResponseAction, 
putUsersSettingsRefreshErrorAction, 
putWalletsSendingAction, 
putWalletsResponseAction, 
putWalletsErrorAction, 
getWalletsSendingAction, 
getWalletsResponseAction, 
getWalletsErrorAction, 
getWaytypesSendingAction, 
getWaytypesResponseAction, 
getWaytypesErrorAction, 
getGheedAppDownloadEntrywayMetaEndpointSendingAction, 
getGheedAppDownloadEntrywayMetaEndpointResponseAction, 
getGheedAppDownloadEntrywayMetaEndpointErrorAction, 
getGheedMultipleChoiceEntrywayMetaEndpointSendingAction, 
getGheedMultipleChoiceEntrywayMetaEndpointResponseAction, 
getGheedMultipleChoiceEntrywayMetaEndpointErrorAction, 
getGheedSecretCodeEntrywayMetaEndpointSendingAction, 
getGheedSecretCodeEntrywayMetaEndpointResponseAction, 
getGheedSecretCodeEntrywayMetaEndpointErrorAction, 
getGheedImageChoiceEntrywayMetaEndpointSendingAction, 
getGheedImageChoiceEntrywayMetaEndpointResponseAction, 
getGheedImageChoiceEntrywayMetaEndpointErrorAction, 
getGheedQuestionAnswerEntrywayMetaEndpointSendingAction, 
getGheedQuestionAnswerEntrywayMetaEndpointResponseAction, 
getGheedQuestionAnswerEntrywayMetaEndpointErrorAction, 
getGheedSingleChoiceEntrywayMetaEndpointSendingAction, 
getGheedSingleChoiceEntrywayMetaEndpointResponseAction, 
getGheedSingleChoiceEntrywayMetaEndpointErrorAction, 
getWaytypesLookupSendingAction, 
getWaytypesLookupResponseAction, 
getWaytypesLookupErrorAction, 
getWaytypesQuerySendingAction, 
getWaytypesQueryResponseAction, 
getWaytypesQueryErrorAction, 
getWaytypesValidateSendingAction, 
getWaytypesValidateResponseAction, 
getWaytypesValidateErrorAction, 
getBlogpostBySlugSendingAction, 
getBlogpostBySlugResponseAction, 
getBlogpostBySlugErrorAction, 
getCssSendingAction, 
getCssResponseAction, 
getCssErrorAction, 
getYoutubeconfirmSendingAction, 
getYoutubeconfirmResponseAction, 
getYoutubeconfirmErrorAction, 
getYoutubeconnectSendingAction, 
getYoutubeconnectResponseAction, 
getYoutubeconnectErrorAction, 
getYoutubelandingSendingAction, 
getYoutubelandingResponseAction, 
getYoutubelandingErrorAction } from './index';
    import RESTAPI from './rest';

    
      
      export const putClaimPrizePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putClaimPrizeSendingAction());

        const prom = RESTAPI.putClaimPrize(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putClaimPrizeResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putClaimPrizeErrorAction(error))
        })
      }

      export const generatePutClaimPrizeRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_CLAIM_PRIZE_REQUEST,
          cancelType: [PUT_CLAIM_PRIZE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putClaimPrizePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getClaimPrizePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getClaimPrizeSendingAction());

        const prom = RESTAPI.getClaimPrize(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getClaimPrizeResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getClaimPrizeErrorAction(error))
        })
      }

      export const generateGetClaimPrizeRequestLogic = (options) => {
      
        return createLogic({
          type: GET_CLAIM_PRIZE_REQUEST,
          cancelType: [GET_CLAIM_PRIZE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getClaimPrizePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getContestgenerationPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getContestgenerationSendingAction());

        const prom = RESTAPI.getContestgeneration(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getContestgenerationResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getContestgenerationErrorAction(error))
        })
      }

      export const generateGetContestgenerationRequestLogic = (options) => {
      
        return createLogic({
          type: GET_CONTESTGENERATION_REQUEST,
          cancelType: [GET_CONTESTGENERATION_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getContestgenerationPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putPrivateProductByEventidPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putPrivateProductByEventidSendingAction());

        const prom = RESTAPI.putPrivateProductByEventid(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putPrivateProductByEventidResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putPrivateProductByEventidErrorAction(error))
        })
      }

      export const generatePutPrivateProductByEventidRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_PRIVATE_PRODUCT_BY_EVENTID_REQUEST,
          cancelType: [PUT_PRIVATE_PRODUCT_BY_EVENTID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putPrivateProductByEventidPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getPrivateProductByEventidPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getPrivateProductByEventidSendingAction());

        const prom = RESTAPI.getPrivateProductByEventid(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getPrivateProductByEventidResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getPrivateProductByEventidErrorAction(error))
        })
      }

      export const generateGetPrivateProductByEventidRequestLogic = (options) => {
      
        return createLogic({
          type: GET_PRIVATE_PRODUCT_BY_EVENTID_REQUEST,
          cancelType: [GET_PRIVATE_PRODUCT_BY_EVENTID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getPrivateProductByEventidPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getPrivateProductPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getPrivateProductSendingAction());

        const prom = RESTAPI.getPrivateProduct(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getPrivateProductResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getPrivateProductErrorAction(error))
        })
      }

      export const generateGetPrivateProductRequestLogic = (options) => {
      
        return createLogic({
          type: GET_PRIVATE_PRODUCT_REQUEST,
          cancelType: [GET_PRIVATE_PRODUCT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getPrivateProductPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getAuthStatePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getAuthStateSendingAction());

        const prom = RESTAPI.getAuthState(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getAuthStateResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getAuthStateErrorAction(error))
        })
      }

      export const generateGetAuthStateRequestLogic = (options) => {
      
        return createLogic({
          type: GET_AUTH_STATE_REQUEST,
          cancelType: [GET_AUTH_STATE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getAuthStatePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getAuthStateReadPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getAuthStateReadSendingAction());

        const prom = RESTAPI.getAuthStateRead(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getAuthStateReadResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getAuthStateReadErrorAction(error))
        })
      }

      export const generateGetAuthStateReadRequestLogic = (options) => {
      
        return createLogic({
          type: GET_AUTH_STATE_READ_REQUEST,
          cancelType: [GET_AUTH_STATE_READ_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getAuthStateReadPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postBetaApplyPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postBetaApplySendingAction());

        const prom = RESTAPI.postBetaApply(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postBetaApplyResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postBetaApplyErrorAction(error))
        })
      }

      export const generatePostBetaApplyRequestLogic = (options) => {
      
        return createLogic({
          type: POST_BETA_APPLY_REQUEST,
          cancelType: [POST_BETA_APPLY_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postBetaApplyPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getBotScorePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getBotScoreSendingAction());

        const prom = RESTAPI.getBotScore(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getBotScoreResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getBotScoreErrorAction(error))
        })
      }

      export const generateGetBotScoreRequestLogic = (options) => {
      
        return createLogic({
          type: GET_BOT_SCORE_REQUEST,
          cancelType: [GET_BOT_SCORE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getBotScorePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getTwitterBotScorePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getTwitterBotScoreSendingAction());

        const prom = RESTAPI.getTwitterBotScore(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getTwitterBotScoreResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getTwitterBotScoreErrorAction(error))
        })
      }

      export const generateGetTwitterBotScoreRequestLogic = (options) => {
      
        return createLogic({
          type: GET_TWITTER_BOT_SCORE_REQUEST,
          cancelType: [GET_TWITTER_BOT_SCORE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getTwitterBotScorePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putCartPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putCartSendingAction());

        const prom = RESTAPI.putCart(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putCartResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putCartErrorAction(error))
        })
      }

      export const generatePutCartRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_CART_REQUEST,
          cancelType: [PUT_CART_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putCartPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getCartPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getCartSendingAction());

        const prom = RESTAPI.getCart(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getCartResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getCartErrorAction(error))
        })
      }

      export const generateGetCartRequestLogic = (options) => {
      
        return createLogic({
          type: GET_CART_REQUEST,
          cancelType: [GET_CART_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getCartPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putCouponPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putCouponSendingAction());

        const prom = RESTAPI.putCoupon(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putCouponResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putCouponErrorAction(error))
        })
      }

      export const generatePutCouponRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_COUPON_REQUEST,
          cancelType: [PUT_COUPON_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putCouponPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const deleteCouponPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(deleteCouponSendingAction());

        const prom = RESTAPI.deleteCoupon(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(deleteCouponResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(deleteCouponErrorAction(error))
        })
      }

      export const generateDeleteCouponRequestLogic = (options) => {
      
        return createLogic({
          type: DELETE_COUPON_REQUEST,
          cancelType: [DELETE_COUPON_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return deleteCouponPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getCategoriesPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getCategoriesSendingAction());

        const prom = RESTAPI.getCategories(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getCategoriesResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getCategoriesErrorAction(error))
        })
      }

      export const generateGetCategoriesRequestLogic = (options) => {
      
        return createLogic({
          type: GET_CATEGORIES_REQUEST,
          cancelType: [GET_CATEGORIES_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getCategoriesPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postContactPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postContactSendingAction());

        const prom = RESTAPI.postContact(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postContactResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postContactErrorAction(error))
        })
      }

      export const generatePostContactRequestLogic = (options) => {
      
        return createLogic({
          type: POST_CONTACT_REQUEST,
          cancelType: [POST_CONTACT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postContactPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getDiscordconfirmPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getDiscordconfirmSendingAction());

        const prom = RESTAPI.getDiscordconfirm(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getDiscordconfirmResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getDiscordconfirmErrorAction(error))
        })
      }

      export const generateGetDiscordconfirmRequestLogic = (options) => {
      
        return createLogic({
          type: GET_DISCORDCONFIRM_REQUEST,
          cancelType: [GET_DISCORDCONFIRM_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getDiscordconfirmPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getDiscordconnectPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getDiscordconnectSendingAction());

        const prom = RESTAPI.getDiscordconnect(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getDiscordconnectResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getDiscordconnectErrorAction(error))
        })
      }

      export const generateGetDiscordconnectRequestLogic = (options) => {
      
        return createLogic({
          type: GET_DISCORDCONNECT_REQUEST,
          cancelType: [GET_DISCORDCONNECT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getDiscordconnectPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getDiscordlandingPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getDiscordlandingSendingAction());

        const prom = RESTAPI.getDiscordlanding(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getDiscordlandingResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getDiscordlandingErrorAction(error))
        })
      }

      export const generateGetDiscordlandingRequestLogic = (options) => {
      
        return createLogic({
          type: GET_DISCORDLANDING_REQUEST,
          cancelType: [GET_DISCORDLANDING_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getDiscordlandingPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWinnerDrawPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWinnerDrawSendingAction());

        const prom = RESTAPI.getWinnerDraw(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWinnerDrawResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWinnerDrawErrorAction(error))
        })
      }

      export const generateGetWinnerDrawRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WINNER_DRAW_REQUEST,
          cancelType: [GET_WINNER_DRAW_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWinnerDrawPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWinnerDrawMaxPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWinnerDrawMaxSendingAction());

        const prom = RESTAPI.getWinnerDrawMax(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWinnerDrawMaxResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWinnerDrawMaxErrorAction(error))
        })
      }

      export const generateGetWinnerDrawMaxRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WINNER_DRAW_MAX_REQUEST,
          cancelType: [GET_WINNER_DRAW_MAX_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWinnerDrawMaxPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWinnerDrawMedPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWinnerDrawMedSendingAction());

        const prom = RESTAPI.getWinnerDrawMed(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWinnerDrawMedResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWinnerDrawMedErrorAction(error))
        })
      }

      export const generateGetWinnerDrawMedRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WINNER_DRAW_MED_REQUEST,
          cancelType: [GET_WINNER_DRAW_MED_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWinnerDrawMedPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getPrivateGiveawayInfosPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getPrivateGiveawayInfosSendingAction());

        const prom = RESTAPI.getPrivateGiveawayInfos(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getPrivateGiveawayInfosResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getPrivateGiveawayInfosErrorAction(error))
        })
      }

      export const generateGetPrivateGiveawayInfosRequestLogic = (options) => {
      
        return createLogic({
          type: GET_PRIVATE_GIVEAWAY_INFOS_REQUEST,
          cancelType: [GET_PRIVATE_GIVEAWAY_INFOS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getPrivateGiveawayInfosPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWinnerDrawManualPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWinnerDrawManualSendingAction());

        const prom = RESTAPI.getWinnerDrawManual(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWinnerDrawManualResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWinnerDrawManualErrorAction(error))
        })
      }

      export const generateGetWinnerDrawManualRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WINNER_DRAW_MANUAL_REQUEST,
          cancelType: [GET_WINNER_DRAW_MANUAL_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWinnerDrawManualPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getExtRedirectByIdPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getExtRedirectByIdSendingAction());

        const prom = RESTAPI.getExtRedirectById(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getExtRedirectByIdResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getExtRedirectByIdErrorAction(error))
        })
      }

      export const generateGetExtRedirectByIdRequestLogic = (options) => {
      
        return createLogic({
          type: GET_EXT_REDIRECT_BY_ID_REQUEST,
          cancelType: [GET_EXT_REDIRECT_BY_ID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getExtRedirectByIdPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getExtRedirectCreatePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getExtRedirectCreateSendingAction());

        const prom = RESTAPI.getExtRedirectCreate(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getExtRedirectCreateResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getExtRedirectCreateErrorAction(error))
        })
      }

      export const generateGetExtRedirectCreateRequestLogic = (options) => {
      
        return createLogic({
          type: GET_EXT_REDIRECT_CREATE_REQUEST,
          cancelType: [GET_EXT_REDIRECT_CREATE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getExtRedirectCreatePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postEmailPwChangePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postEmailPwChangeSendingAction());

        const prom = RESTAPI.postEmailPwChange(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postEmailPwChangeResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postEmailPwChangeErrorAction(error))
        })
      }

      export const generatePostEmailPwChangeRequestLogic = (options) => {
      
        return createLogic({
          type: POST_EMAIL_PW_CHANGE_REQUEST,
          cancelType: [POST_EMAIL_PW_CHANGE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postEmailPwChangePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postEmailChangePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postEmailChangeSendingAction());

        const prom = RESTAPI.postEmailChange(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postEmailChangeResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postEmailChangeErrorAction(error))
        })
      }

      export const generatePostEmailChangeRequestLogic = (options) => {
      
        return createLogic({
          type: POST_EMAIL_CHANGE_REQUEST,
          cancelType: [POST_EMAIL_CHANGE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postEmailChangePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getEmailChangePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getEmailChangeSendingAction());

        const prom = RESTAPI.getEmailChange(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getEmailChangeResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getEmailChangeErrorAction(error))
        })
      }

      export const generateGetEmailChangeRequestLogic = (options) => {
      
        return createLogic({
          type: GET_EMAIL_CHANGE_REQUEST,
          cancelType: [GET_EMAIL_CHANGE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getEmailChangePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postEmailLoginPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postEmailLoginSendingAction());

        const prom = RESTAPI.postEmailLogin(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postEmailLoginResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postEmailLoginErrorAction(error))
        })
      }

      export const generatePostEmailLoginRequestLogic = (options) => {
      
        return createLogic({
          type: POST_EMAIL_LOGIN_REQUEST,
          cancelType: [POST_EMAIL_LOGIN_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postEmailLoginPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postEmailRegisterPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postEmailRegisterSendingAction());

        const prom = RESTAPI.postEmailRegister(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postEmailRegisterResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postEmailRegisterErrorAction(error))
        })
      }

      export const generatePostEmailRegisterRequestLogic = (options) => {
      
        return createLogic({
          type: POST_EMAIL_REGISTER_REQUEST,
          cancelType: [POST_EMAIL_REGISTER_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postEmailRegisterPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putEmailPwResetPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putEmailPwResetSendingAction());

        const prom = RESTAPI.putEmailPwReset(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putEmailPwResetResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putEmailPwResetErrorAction(error))
        })
      }

      export const generatePutEmailPwResetRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_EMAIL_PW_RESET_REQUEST,
          cancelType: [PUT_EMAIL_PW_RESET_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putEmailPwResetPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postEmailPwResetPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postEmailPwResetSendingAction());

        const prom = RESTAPI.postEmailPwReset(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postEmailPwResetResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postEmailPwResetErrorAction(error))
        })
      }

      export const generatePostEmailPwResetRequestLogic = (options) => {
      
        return createLogic({
          type: POST_EMAIL_PW_RESET_REQUEST,
          cancelType: [POST_EMAIL_PW_RESET_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postEmailPwResetPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postEmailVerifyPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postEmailVerifySendingAction());

        const prom = RESTAPI.postEmailVerify(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postEmailVerifyResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postEmailVerifyErrorAction(error))
        })
      }

      export const generatePostEmailVerifyRequestLogic = (options) => {
      
        return createLogic({
          type: POST_EMAIL_VERIFY_REQUEST,
          cancelType: [POST_EMAIL_VERIFY_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postEmailVerifyPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getEmailVerifyPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getEmailVerifySendingAction());

        const prom = RESTAPI.getEmailVerify(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getEmailVerifyResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getEmailVerifyErrorAction(error))
        })
      }

      export const generateGetEmailVerifyRequestLogic = (options) => {
      
        return createLogic({
          type: GET_EMAIL_VERIFY_REQUEST,
          cancelType: [GET_EMAIL_VERIFY_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getEmailVerifyPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postEntrywaysEntityPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postEntrywaysEntitySendingAction());

        const prom = RESTAPI.postEntrywaysEntity(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postEntrywaysEntityResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postEntrywaysEntityErrorAction(error))
        })
      }

      export const generatePostEntrywaysEntityRequestLogic = (options) => {
      
        return createLogic({
          type: POST_ENTRYWAYS_ENTITY_REQUEST,
          cancelType: [POST_ENTRYWAYS_ENTITY_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postEntrywaysEntityPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getEntrywaysEntityPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getEntrywaysEntitySendingAction());

        const prom = RESTAPI.getEntrywaysEntity(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getEntrywaysEntityResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getEntrywaysEntityErrorAction(error))
        })
      }

      export const generateGetEntrywaysEntityRequestLogic = (options) => {
      
        return createLogic({
          type: GET_ENTRYWAYS_ENTITY_REQUEST,
          cancelType: [GET_ENTRYWAYS_ENTITY_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getEntrywaysEntityPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getEntrywaysEntityUrlPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getEntrywaysEntityUrlSendingAction());

        const prom = RESTAPI.getEntrywaysEntityUrl(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getEntrywaysEntityUrlResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getEntrywaysEntityUrlErrorAction(error))
        })
      }

      export const generateGetEntrywaysEntityUrlRequestLogic = (options) => {
      
        return createLogic({
          type: GET_ENTRYWAYS_ENTITY_URL_REQUEST,
          cancelType: [GET_ENTRYWAYS_ENTITY_URL_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getEntrywaysEntityUrlPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getFacebookconfirmPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getFacebookconfirmSendingAction());

        const prom = RESTAPI.getFacebookconfirm(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getFacebookconfirmResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getFacebookconfirmErrorAction(error))
        })
      }

      export const generateGetFacebookconfirmRequestLogic = (options) => {
      
        return createLogic({
          type: GET_FACEBOOKCONFIRM_REQUEST,
          cancelType: [GET_FACEBOOKCONFIRM_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getFacebookconfirmPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getFacebookConnectPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getFacebookConnectSendingAction());

        const prom = RESTAPI.getFacebookConnect(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getFacebookConnectResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getFacebookConnectErrorAction(error))
        })
      }

      export const generateGetFacebookConnectRequestLogic = (options) => {
      
        return createLogic({
          type: GET_FACEBOOK_CONNECT_REQUEST,
          cancelType: [GET_FACEBOOK_CONNECT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getFacebookConnectPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getFacebooklandingPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getFacebooklandingSendingAction());

        const prom = RESTAPI.getFacebooklanding(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getFacebooklandingResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getFacebooklandingErrorAction(error))
        })
      }

      export const generateGetFacebooklandingRequestLogic = (options) => {
      
        return createLogic({
          type: GET_FACEBOOKLANDING_REQUEST,
          cancelType: [GET_FACEBOOKLANDING_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getFacebooklandingPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postImagesPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postImagesSendingAction());

        const prom = RESTAPI.postImages(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postImagesResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postImagesErrorAction(error))
        })
      }

      export const generatePostImagesRequestLogic = (options) => {
      
        return createLogic({
          type: POST_IMAGES_REQUEST,
          cancelType: [POST_IMAGES_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postImagesPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getImagesPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getImagesSendingAction());

        const prom = RESTAPI.getImages(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getImagesResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getImagesErrorAction(error))
        })
      }

      export const generateGetImagesRequestLogic = (options) => {
      
        return createLogic({
          type: GET_IMAGES_REQUEST,
          cancelType: [GET_IMAGES_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getImagesPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getImagesProductsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getImagesProductsSendingAction());

        const prom = RESTAPI.getImagesProducts(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getImagesProductsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getImagesProductsErrorAction(error))
        })
      }

      export const generateGetImagesProductsRequestLogic = (options) => {
      
        return createLogic({
          type: GET_IMAGES_PRODUCTS_REQUEST,
          cancelType: [GET_IMAGES_PRODUCTS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getImagesProductsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getImagesByUuidPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getImagesByUuidSendingAction());

        const prom = RESTAPI.getImagesByUuid(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getImagesByUuidResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getImagesByUuidErrorAction(error))
        })
      }

      export const generateGetImagesByUuidRequestLogic = (options) => {
      
        return createLogic({
          type: GET_IMAGES_BY_UUID_REQUEST,
          cancelType: [GET_IMAGES_BY_UUID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getImagesByUuidPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const deleteJwtCookiePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(deleteJwtCookieSendingAction());

        const prom = RESTAPI.deleteJwtCookie(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(deleteJwtCookieResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(deleteJwtCookieErrorAction(error))
        })
      }

      export const generateDeleteJwtCookieRequestLogic = (options) => {
      
        return createLogic({
          type: DELETE_JWT_COOKIE_REQUEST,
          cancelType: [DELETE_JWT_COOKIE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return deleteJwtCookiePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getJwtCookiePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getJwtCookieSendingAction());

        const prom = RESTAPI.getJwtCookie(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getJwtCookieResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getJwtCookieErrorAction(error))
        })
      }

      export const generateGetJwtCookieRequestLogic = (options) => {
      
        return createLogic({
          type: GET_JWT_COOKIE_REQUEST,
          cancelType: [GET_JWT_COOKIE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getJwtCookiePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const deleteJwtProtectedPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(deleteJwtProtectedSendingAction());

        const prom = RESTAPI.deleteJwtProtected(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(deleteJwtProtectedResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(deleteJwtProtectedErrorAction(error))
        })
      }

      export const generateDeleteJwtProtectedRequestLogic = (options) => {
      
        return createLogic({
          type: DELETE_JWT_PROTECTED_REQUEST,
          cancelType: [DELETE_JWT_PROTECTED_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return deleteJwtProtectedPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getJwtOptionalPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getJwtOptionalSendingAction());

        const prom = RESTAPI.getJwtOptional(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getJwtOptionalResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getJwtOptionalErrorAction(error))
        })
      }

      export const generateGetJwtOptionalRequestLogic = (options) => {
      
        return createLogic({
          type: GET_JWT_OPTIONAL_REQUEST,
          cancelType: [GET_JWT_OPTIONAL_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getJwtOptionalPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getJwtProtectedPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getJwtProtectedSendingAction());

        const prom = RESTAPI.getJwtProtected(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getJwtProtectedResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getJwtProtectedErrorAction(error))
        })
      }

      export const generateGetJwtProtectedRequestLogic = (options) => {
      
        return createLogic({
          type: GET_JWT_PROTECTED_REQUEST,
          cancelType: [GET_JWT_PROTECTED_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getJwtProtectedPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const deleteJwtRefreshPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(deleteJwtRefreshSendingAction());

        const prom = RESTAPI.deleteJwtRefresh(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(deleteJwtRefreshResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(deleteJwtRefreshErrorAction(error))
        })
      }

      export const generateDeleteJwtRefreshRequestLogic = (options) => {
      
        return createLogic({
          type: DELETE_JWT_REFRESH_REQUEST,
          cancelType: [DELETE_JWT_REFRESH_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return deleteJwtRefreshPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getJwtRefreshPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getJwtRefreshSendingAction());

        const prom = RESTAPI.getJwtRefresh(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getJwtRefreshResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getJwtRefreshErrorAction(error))
        })
      }

      export const generateGetJwtRefreshRequestLogic = (options) => {
      
        return createLogic({
          type: GET_JWT_REFRESH_REQUEST,
          cancelType: [GET_JWT_REFRESH_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getJwtRefreshPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const deleteJwtTokenPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(deleteJwtTokenSendingAction());

        const prom = RESTAPI.deleteJwtToken(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(deleteJwtTokenResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(deleteJwtTokenErrorAction(error))
        })
      }

      export const generateDeleteJwtTokenRequestLogic = (options) => {
      
        return createLogic({
          type: DELETE_JWT_TOKEN_REQUEST,
          cancelType: [DELETE_JWT_TOKEN_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return deleteJwtTokenPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getJwtTokenPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getJwtTokenSendingAction());

        const prom = RESTAPI.getJwtToken(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getJwtTokenResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getJwtTokenErrorAction(error))
        })
      }

      export const generateGetJwtTokenRequestLogic = (options) => {
      
        return createLogic({
          type: GET_JWT_TOKEN_REQUEST,
          cancelType: [GET_JWT_TOKEN_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getJwtTokenPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getMixerconfirmPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getMixerconfirmSendingAction());

        const prom = RESTAPI.getMixerconfirm(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getMixerconfirmResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getMixerconfirmErrorAction(error))
        })
      }

      export const generateGetMixerconfirmRequestLogic = (options) => {
      
        return createLogic({
          type: GET_MIXERCONFIRM_REQUEST,
          cancelType: [GET_MIXERCONFIRM_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getMixerconfirmPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getMixerconnectPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getMixerconnectSendingAction());

        const prom = RESTAPI.getMixerconnect(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getMixerconnectResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getMixerconnectErrorAction(error))
        })
      }

      export const generateGetMixerconnectRequestLogic = (options) => {
      
        return createLogic({
          type: GET_MIXERCONNECT_REQUEST,
          cancelType: [GET_MIXERCONNECT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getMixerconnectPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getMixerlandingPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getMixerlandingSendingAction());

        const prom = RESTAPI.getMixerlanding(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getMixerlandingResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getMixerlandingErrorAction(error))
        })
      }

      export const generateGetMixerlandingRequestLogic = (options) => {
      
        return createLogic({
          type: GET_MIXERLANDING_REQUEST,
          cancelType: [GET_MIXERLANDING_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getMixerlandingPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putNotificationsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putNotificationsSendingAction());

        const prom = RESTAPI.putNotifications(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putNotificationsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putNotificationsErrorAction(error))
        })
      }

      export const generatePutNotificationsRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_NOTIFICATIONS_REQUEST,
          cancelType: [PUT_NOTIFICATIONS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putNotificationsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getNotificationsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getNotificationsSendingAction());

        const prom = RESTAPI.getNotifications(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getNotificationsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getNotificationsErrorAction(error))
        })
      }

      export const generateGetNotificationsRequestLogic = (options) => {
      
        return createLogic({
          type: GET_NOTIFICATIONS_REQUEST,
          cancelType: [GET_NOTIFICATIONS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getNotificationsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putPaymentPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putPaymentSendingAction());

        const prom = RESTAPI.putPayment(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putPaymentResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putPaymentErrorAction(error))
        })
      }

      export const generatePutPaymentRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_PAYMENT_REQUEST,
          cancelType: [PUT_PAYMENT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putPaymentPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getPaymentPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getPaymentSendingAction());

        const prom = RESTAPI.getPayment(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getPaymentResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getPaymentErrorAction(error))
        })
      }

      export const generateGetPaymentRequestLogic = (options) => {
      
        return createLogic({
          type: GET_PAYMENT_REQUEST,
          cancelType: [GET_PAYMENT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getPaymentPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getProductsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getProductsSendingAction());

        const prom = RESTAPI.getProducts(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getProductsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getProductsErrorAction(error))
        })
      }

      export const generateGetProductsRequestLogic = (options) => {
      
        return createLogic({
          type: GET_PRODUCTS_REQUEST,
          cancelType: [GET_PRODUCTS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getProductsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getProductsByIdPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getProductsByIdSendingAction());

        const prom = RESTAPI.getProductsById(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getProductsByIdResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getProductsByIdErrorAction(error))
        })
      }

      export const generateGetProductsByIdRequestLogic = (options) => {
      
        return createLogic({
          type: GET_PRODUCTS_BY_ID_REQUEST,
          cancelType: [GET_PRODUCTS_BY_ID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getProductsByIdPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getProductsByIdStatusPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getProductsByIdStatusSendingAction());

        const prom = RESTAPI.getProductsByIdStatus(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getProductsByIdStatusResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getProductsByIdStatusErrorAction(error))
        })
      }

      export const generateGetProductsByIdStatusRequestLogic = (options) => {
      
        return createLogic({
          type: GET_PRODUCTS_BY_ID_STATUS_REQUEST,
          cancelType: [GET_PRODUCTS_BY_ID_STATUS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getProductsByIdStatusPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getVariationsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getVariationsSendingAction());

        const prom = RESTAPI.getVariations(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getVariationsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getVariationsErrorAction(error))
        })
      }

      export const generateGetVariationsRequestLogic = (options) => {
      
        return createLogic({
          type: GET_VARIATIONS_REQUEST,
          cancelType: [GET_VARIATIONS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getVariationsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getVerificationGeneratePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getVerificationGenerateSendingAction());

        const prom = RESTAPI.getVerificationGenerate(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getVerificationGenerateResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getVerificationGenerateErrorAction(error))
        })
      }

      export const generateGetVerificationGenerateRequestLogic = (options) => {
      
        return createLogic({
          type: GET_VERIFICATION_GENERATE_REQUEST,
          cancelType: [GET_VERIFICATION_GENERATE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getVerificationGeneratePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postVerificationGeneratePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postVerificationGenerateSendingAction());

        const prom = RESTAPI.postVerificationGenerate(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postVerificationGenerateResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postVerificationGenerateErrorAction(error))
        })
      }

      export const generatePostVerificationGenerateRequestLogic = (options) => {
      
        return createLogic({
          type: POST_VERIFICATION_GENERATE_REQUEST,
          cancelType: [POST_VERIFICATION_GENERATE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postVerificationGeneratePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getPublicUserIdPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getPublicUserIdSendingAction());

        const prom = RESTAPI.getPublicUserId(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getPublicUserIdResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getPublicUserIdErrorAction(error))
        })
      }

      export const generateGetPublicUserIdRequestLogic = (options) => {
      
        return createLogic({
          type: GET_PUBLIC_USER_ID_REQUEST,
          cancelType: [GET_PUBLIC_USER_ID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getPublicUserIdPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getPublicUserCoinsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getPublicUserCoinsSendingAction());

        const prom = RESTAPI.getPublicUserCoins(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getPublicUserCoinsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getPublicUserCoinsErrorAction(error))
        })
      }

      export const generateGetPublicUserCoinsRequestLogic = (options) => {
      
        return createLogic({
          type: GET_PUBLIC_USER_COINS_REQUEST,
          cancelType: [GET_PUBLIC_USER_COINS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getPublicUserCoinsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRedirectByIdPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRedirectByIdSendingAction());

        const prom = RESTAPI.getRedirectById(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRedirectByIdResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRedirectByIdErrorAction(error))
        })
      }

      export const generateGetRedirectByIdRequestLogic = (options) => {
      
        return createLogic({
          type: GET_REDIRECT_BY_ID_REQUEST,
          cancelType: [GET_REDIRECT_BY_ID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRedirectByIdPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRedirectCreatePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRedirectCreateSendingAction());

        const prom = RESTAPI.getRedirectCreate(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRedirectCreateResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRedirectCreateErrorAction(error))
        })
      }

      export const generateGetRedirectCreateRequestLogic = (options) => {
      
        return createLogic({
          type: GET_REDIRECT_CREATE_REQUEST,
          cancelType: [GET_REDIRECT_CREATE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRedirectCreatePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postRafflelistPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postRafflelistSendingAction());

        const prom = RESTAPI.postRafflelist(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postRafflelistResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postRafflelistErrorAction(error))
        })
      }

      export const generatePostRafflelistRequestLogic = (options) => {
      
        return createLogic({
          type: POST_RAFFLELIST_REQUEST,
          cancelType: [POST_RAFFLELIST_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postRafflelistPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRafflelistPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRafflelistSendingAction());

        const prom = RESTAPI.getRafflelist(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRafflelistResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRafflelistErrorAction(error))
        })
      }

      export const generateGetRafflelistRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLELIST_REQUEST,
          cancelType: [GET_RAFFLELIST_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRafflelistPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRafflelistEntriesPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRafflelistEntriesSendingAction());

        const prom = RESTAPI.getRafflelistEntries(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRafflelistEntriesResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRafflelistEntriesErrorAction(error))
        })
      }

      export const generateGetRafflelistEntriesRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLELIST_ENTRIES_REQUEST,
          cancelType: [GET_RAFFLELIST_ENTRIES_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRafflelistEntriesPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRafflesOwnPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRafflesOwnSendingAction());

        const prom = RESTAPI.getRafflesOwn(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRafflesOwnResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRafflesOwnErrorAction(error))
        })
      }

      export const generateGetRafflesOwnRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLES_OWN_REQUEST,
          cancelType: [GET_RAFFLES_OWN_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRafflesOwnPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRafflelistParticipatedPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRafflelistParticipatedSendingAction());

        const prom = RESTAPI.getRafflelistParticipated(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRafflelistParticipatedResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRafflelistParticipatedErrorAction(error))
        })
      }

      export const generateGetRafflelistParticipatedRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLELIST_PARTICIPATED_REQUEST,
          cancelType: [GET_RAFFLELIST_PARTICIPATED_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRafflelistParticipatedPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getPrizesByRaffleIdPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getPrizesByRaffleIdSendingAction());

        const prom = RESTAPI.getPrizesByRaffleId(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getPrizesByRaffleIdResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getPrizesByRaffleIdErrorAction(error))
        })
      }

      export const generateGetPrizesByRaffleIdRequestLogic = (options) => {
      
        return createLogic({
          type: GET_PRIZES_BY_RAFFLE_ID_REQUEST,
          cancelType: [GET_PRIZES_BY_RAFFLE_ID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getPrizesByRaffleIdPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRafflePromotionPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRafflePromotionSendingAction());

        const prom = RESTAPI.getRafflePromotion(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRafflePromotionResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRafflePromotionErrorAction(error))
        })
      }

      export const generateGetRafflePromotionRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLE_PROMOTION_REQUEST,
          cancelType: [GET_RAFFLE_PROMOTION_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRafflePromotionPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRafflelistSearchPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRafflelistSearchSendingAction());

        const prom = RESTAPI.getRafflelistSearch(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRafflelistSearchResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRafflelistSearchErrorAction(error))
        })
      }

      export const generateGetRafflelistSearchRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLELIST_SEARCH_REQUEST,
          cancelType: [GET_RAFFLELIST_SEARCH_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRafflelistSearchPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putRafflesPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putRafflesSendingAction());

        const prom = RESTAPI.putRaffles(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putRafflesResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putRafflesErrorAction(error))
        })
      }

      export const generatePutRafflesRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_RAFFLES_REQUEST,
          cancelType: [PUT_RAFFLES_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putRafflesPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRafflesPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRafflesSendingAction());

        const prom = RESTAPI.getRaffles(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRafflesResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRafflesErrorAction(error))
        })
      }

      export const generateGetRafflesRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLES_REQUEST,
          cancelType: [GET_RAFFLES_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRafflesPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putRafflesFinalizePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putRafflesFinalizeSendingAction());

        const prom = RESTAPI.putRafflesFinalize(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putRafflesFinalizeResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putRafflesFinalizeErrorAction(error))
        })
      }

      export const generatePutRafflesFinalizeRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_RAFFLES_FINALIZE_REQUEST,
          cancelType: [PUT_RAFFLES_FINALIZE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putRafflesFinalizePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWinnersByRaffleIdPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWinnersByRaffleIdSendingAction());

        const prom = RESTAPI.getWinnersByRaffleId(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWinnersByRaffleIdResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWinnersByRaffleIdErrorAction(error))
        })
      }

      export const generateGetWinnersByRaffleIdRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WINNERS_BY_RAFFLE_ID_REQUEST,
          cancelType: [GET_WINNERS_BY_RAFFLE_ID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWinnersByRaffleIdPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRafflelistFilterOrderbyPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRafflelistFilterOrderbySendingAction());

        const prom = RESTAPI.getRafflelistFilterOrderby(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRafflelistFilterOrderbyResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRafflelistFilterOrderbyErrorAction(error))
        })
      }

      export const generateGetRafflelistFilterOrderbyRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLELIST_FILTER_ORDERBY_REQUEST,
          cancelType: [GET_RAFFLELIST_FILTER_ORDERBY_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRafflelistFilterOrderbyPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRaffleByIdPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRaffleByIdSendingAction());

        const prom = RESTAPI.getRaffleById(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRaffleByIdResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRaffleByIdErrorAction(error))
        })
      }

      export const generateGetRaffleByIdRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLE_BY_ID_REQUEST,
          cancelType: [GET_RAFFLE_BY_ID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRaffleByIdPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRaffleByIdCouponsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRaffleByIdCouponsSendingAction());

        const prom = RESTAPI.getRaffleByIdCoupons(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRaffleByIdCouponsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRaffleByIdCouponsErrorAction(error))
        })
      }

      export const generateGetRaffleByIdCouponsRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLE_BY_ID_COUPONS_REQUEST,
          cancelType: [GET_RAFFLE_BY_ID_COUPONS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRaffleByIdCouponsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRaffleByIdCouponsStatusPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRaffleByIdCouponsStatusSendingAction());

        const prom = RESTAPI.getRaffleByIdCouponsStatus(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRaffleByIdCouponsStatusResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRaffleByIdCouponsStatusErrorAction(error))
        })
      }

      export const generateGetRaffleByIdCouponsStatusRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLE_BY_ID_COUPONS_STATUS_REQUEST,
          cancelType: [GET_RAFFLE_BY_ID_COUPONS_STATUS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRaffleByIdCouponsStatusPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getDrawStatusByRaffleIdPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getDrawStatusByRaffleIdSendingAction());

        const prom = RESTAPI.getDrawStatusByRaffleId(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getDrawStatusByRaffleIdResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getDrawStatusByRaffleIdErrorAction(error))
        })
      }

      export const generateGetDrawStatusByRaffleIdRequestLogic = (options) => {
      
        return createLogic({
          type: GET_DRAW_STATUS_BY_RAFFLE_ID_REQUEST,
          cancelType: [GET_DRAW_STATUS_BY_RAFFLE_ID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getDrawStatusByRaffleIdPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putRaffleByIdEditPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putRaffleByIdEditSendingAction());

        const prom = RESTAPI.putRaffleByIdEdit(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putRaffleByIdEditResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putRaffleByIdEditErrorAction(error))
        })
      }

      export const generatePutRaffleByIdEditRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_RAFFLE_BY_ID_EDIT_REQUEST,
          cancelType: [PUT_RAFFLE_BY_ID_EDIT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putRaffleByIdEditPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRaffleByIdEditPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRaffleByIdEditSendingAction());

        const prom = RESTAPI.getRaffleByIdEdit(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRaffleByIdEditResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRaffleByIdEditErrorAction(error))
        })
      }

      export const generateGetRaffleByIdEditRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLE_BY_ID_EDIT_REQUEST,
          cancelType: [GET_RAFFLE_BY_ID_EDIT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRaffleByIdEditPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRaffleByIdEntriesPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRaffleByIdEntriesSendingAction());

        const prom = RESTAPI.getRaffleByIdEntries(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRaffleByIdEntriesResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRaffleByIdEntriesErrorAction(error))
        })
      }

      export const generateGetRaffleByIdEntriesRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLE_BY_ID_ENTRIES_REQUEST,
          cancelType: [GET_RAFFLE_BY_ID_ENTRIES_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRaffleByIdEntriesPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postRafflesEntrywaysPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postRafflesEntrywaysSendingAction());

        const prom = RESTAPI.postRafflesEntryways(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postRafflesEntrywaysResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postRafflesEntrywaysErrorAction(error))
        })
      }

      export const generatePostRafflesEntrywaysRequestLogic = (options) => {
      
        return createLogic({
          type: POST_RAFFLES_ENTRYWAYS_REQUEST,
          cancelType: [POST_RAFFLES_ENTRYWAYS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postRafflesEntrywaysPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRafflesEntrywaysPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRafflesEntrywaysSendingAction());

        const prom = RESTAPI.getRafflesEntryways(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRafflesEntrywaysResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRafflesEntrywaysErrorAction(error))
        })
      }

      export const generateGetRafflesEntrywaysRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RAFFLES_ENTRYWAYS_REQUEST,
          cancelType: [GET_RAFFLES_ENTRYWAYS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRafflesEntrywaysPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postRaffleByIdReportPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postRaffleByIdReportSendingAction());

        const prom = RESTAPI.postRaffleByIdReport(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postRaffleByIdReportResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postRaffleByIdReportErrorAction(error))
        })
      }

      export const generatePostRaffleByIdReportRequestLogic = (options) => {
      
        return createLogic({
          type: POST_RAFFLE_BY_ID_REPORT_REQUEST,
          cancelType: [POST_RAFFLE_BY_ID_REPORT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postRaffleByIdReportPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWaysByRaffleIdPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWaysByRaffleIdSendingAction());

        const prom = RESTAPI.getWaysByRaffleId(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWaysByRaffleIdResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWaysByRaffleIdErrorAction(error))
        })
      }

      export const generateGetWaysByRaffleIdRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WAYS_BY_RAFFLE_ID_REQUEST,
          cancelType: [GET_WAYS_BY_RAFFLE_ID_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWaysByRaffleIdPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putWaysByRaffleIDcopyPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putWaysByRaffleIDcopySendingAction());

        const prom = RESTAPI.putWaysByRaffleIDcopy(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putWaysByRaffleIDcopyResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putWaysByRaffleIDcopyErrorAction(error))
        })
      }

      export const generatePutWaysByRaffleIDcopyRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_WAYS_BY_RAFFLE_I_DCOPY_REQUEST,
          cancelType: [PUT_WAYS_BY_RAFFLE_I_DCOPY_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putWaysByRaffleIDcopyPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWaysByRaffleIdStatusPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWaysByRaffleIdStatusSendingAction());

        const prom = RESTAPI.getWaysByRaffleIdStatus(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWaysByRaffleIdStatusResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWaysByRaffleIdStatusErrorAction(error))
        })
      }

      export const generateGetWaysByRaffleIdStatusRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WAYS_BY_RAFFLE_ID_STATUS_REQUEST,
          cancelType: [GET_WAYS_BY_RAFFLE_ID_STATUS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWaysByRaffleIdStatusPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getRecaptchaPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getRecaptchaSendingAction());

        const prom = RESTAPI.getRecaptcha(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getRecaptchaResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getRecaptchaErrorAction(error))
        })
      }

      export const generateGetRecaptchaRequestLogic = (options) => {
      
        return createLogic({
          type: GET_RECAPTCHA_REQUEST,
          cancelType: [GET_RECAPTCHA_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getRecaptchaPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putSalePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putSaleSendingAction());

        const prom = RESTAPI.putSale(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putSaleResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putSaleErrorAction(error))
        })
      }

      export const generatePutSaleRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_SALE_REQUEST,
          cancelType: [PUT_SALE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putSalePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getSalePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getSaleSendingAction());

        const prom = RESTAPI.getSale(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getSaleResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getSaleErrorAction(error))
        })
      }

      export const generateGetSaleRequestLogic = (options) => {
      
        return createLogic({
          type: GET_SALE_REQUEST,
          cancelType: [GET_SALE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getSalePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getSalecancelPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getSalecancelSendingAction());

        const prom = RESTAPI.getSalecancel(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getSalecancelResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getSalecancelErrorAction(error))
        })
      }

      export const generateGetSalecancelRequestLogic = (options) => {
      
        return createLogic({
          type: GET_SALECANCEL_REQUEST,
          cancelType: [GET_SALECANCEL_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getSalecancelPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getSaleconfirmPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getSaleconfirmSendingAction());

        const prom = RESTAPI.getSaleconfirm(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getSaleconfirmResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getSaleconfirmErrorAction(error))
        })
      }

      export const generateGetSaleconfirmRequestLogic = (options) => {
      
        return createLogic({
          type: GET_SALECONFIRM_REQUEST,
          cancelType: [GET_SALECONFIRM_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getSaleconfirmPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getUsercountPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getUsercountSendingAction());

        const prom = RESTAPI.getUsercount(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getUsercountResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getUsercountErrorAction(error))
        })
      }

      export const generateGetUsercountRequestLogic = (options) => {
      
        return createLogic({
          type: GET_USERCOUNT_REQUEST,
          cancelType: [GET_USERCOUNT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getUsercountPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const deleteSocialsEntityPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(deleteSocialsEntitySendingAction());

        const prom = RESTAPI.deleteSocialsEntity(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(deleteSocialsEntityResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(deleteSocialsEntityErrorAction(error))
        })
      }

      export const generateDeleteSocialsEntityRequestLogic = (options) => {
      
        return createLogic({
          type: DELETE_SOCIALS_ENTITY_REQUEST,
          cancelType: [DELETE_SOCIALS_ENTITY_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return deleteSocialsEntityPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getSocialsScopesPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getSocialsScopesSendingAction());

        const prom = RESTAPI.getSocialsScopes(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getSocialsScopesResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getSocialsScopesErrorAction(error))
        })
      }

      export const generateGetSocialsScopesRequestLogic = (options) => {
      
        return createLogic({
          type: GET_SOCIALS_SCOPES_REQUEST,
          cancelType: [GET_SOCIALS_SCOPES_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getSocialsScopesPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getCoinleadersPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getCoinleadersSendingAction());

        const prom = RESTAPI.getCoinleaders(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getCoinleadersResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getCoinleadersErrorAction(error))
        })
      }

      export const generateGetCoinleadersRequestLogic = (options) => {
      
        return createLogic({
          type: GET_COINLEADERS_REQUEST,
          cancelType: [GET_COINLEADERS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getCoinleadersPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postSupportPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postSupportSendingAction());

        const prom = RESTAPI.postSupport(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postSupportResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postSupportErrorAction(error))
        })
      }

      export const generatePostSupportRequestLogic = (options) => {
      
        return createLogic({
          type: POST_SUPPORT_REQUEST,
          cancelType: [POST_SUPPORT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postSupportPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getTwitchconfirmPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getTwitchconfirmSendingAction());

        const prom = RESTAPI.getTwitchconfirm(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getTwitchconfirmResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getTwitchconfirmErrorAction(error))
        })
      }

      export const generateGetTwitchconfirmRequestLogic = (options) => {
      
        return createLogic({
          type: GET_TWITCHCONFIRM_REQUEST,
          cancelType: [GET_TWITCHCONFIRM_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getTwitchconfirmPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getTwitchconnectPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getTwitchconnectSendingAction());

        const prom = RESTAPI.getTwitchconnect(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getTwitchconnectResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getTwitchconnectErrorAction(error))
        })
      }

      export const generateGetTwitchconnectRequestLogic = (options) => {
      
        return createLogic({
          type: GET_TWITCHCONNECT_REQUEST,
          cancelType: [GET_TWITCHCONNECT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getTwitchconnectPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getTwitchlandingPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getTwitchlandingSendingAction());

        const prom = RESTAPI.getTwitchlanding(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getTwitchlandingResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getTwitchlandingErrorAction(error))
        })
      }

      export const generateGetTwitchlandingRequestLogic = (options) => {
      
        return createLogic({
          type: GET_TWITCHLANDING_REQUEST,
          cancelType: [GET_TWITCHLANDING_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getTwitchlandingPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getTwittercallbackPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getTwittercallbackSendingAction());

        const prom = RESTAPI.getTwittercallback(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getTwittercallbackResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getTwittercallbackErrorAction(error))
        })
      }

      export const generateGetTwittercallbackRequestLogic = (options) => {
      
        return createLogic({
          type: GET_TWITTERCALLBACK_REQUEST,
          cancelType: [GET_TWITTERCALLBACK_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getTwittercallbackPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getTwitterconfirmPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getTwitterconfirmSendingAction());

        const prom = RESTAPI.getTwitterconfirm(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getTwitterconfirmResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getTwitterconfirmErrorAction(error))
        })
      }

      export const generateGetTwitterconfirmRequestLogic = (options) => {
      
        return createLogic({
          type: GET_TWITTERCONFIRM_REQUEST,
          cancelType: [GET_TWITTERCONFIRM_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getTwitterconfirmPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getTwitterConnectPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getTwitterConnectSendingAction());

        const prom = RESTAPI.getTwitterConnect(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getTwitterConnectResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getTwitterConnectErrorAction(error))
        })
      }

      export const generateGetTwitterConnectRequestLogic = (options) => {
      
        return createLogic({
          type: GET_TWITTER_CONNECT_REQUEST,
          cancelType: [GET_TWITTER_CONNECT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getTwitterConnectPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postBillingPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postBillingSendingAction());

        const prom = RESTAPI.postBilling(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postBillingResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postBillingErrorAction(error))
        })
      }

      export const generatePostBillingRequestLogic = (options) => {
      
        return createLogic({
          type: POST_BILLING_REQUEST,
          cancelType: [POST_BILLING_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postBillingPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getBillingPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getBillingSendingAction());

        const prom = RESTAPI.getBilling(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getBillingResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getBillingErrorAction(error))
        })
      }

      export const generateGetBillingRequestLogic = (options) => {
      
        return createLogic({
          type: GET_BILLING_REQUEST,
          cancelType: [GET_BILLING_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getBillingPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putBusinessUserPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putBusinessUserSendingAction());

        const prom = RESTAPI.putBusinessUser(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putBusinessUserResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putBusinessUserErrorAction(error))
        })
      }

      export const generatePutBusinessUserRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_BUSINESS_USER_REQUEST,
          cancelType: [PUT_BUSINESS_USER_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putBusinessUserPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const postUserDeletePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(postUserDeleteSendingAction());

        const prom = RESTAPI.postUserDelete(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(postUserDeleteResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(postUserDeleteErrorAction(error))
        })
      }

      export const generatePostUserDeleteRequestLogic = (options) => {
      
        return createLogic({
          type: POST_USER_DELETE_REQUEST,
          cancelType: [POST_USER_DELETE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return postUserDeletePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getUserDeletePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getUserDeleteSendingAction());

        const prom = RESTAPI.getUserDelete(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getUserDeleteResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getUserDeleteErrorAction(error))
        })
      }

      export const generateGetUserDeleteRequestLogic = (options) => {
      
        return createLogic({
          type: GET_USER_DELETE_REQUEST,
          cancelType: [GET_USER_DELETE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getUserDeletePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getUsersRegionsGuessPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getUsersRegionsGuessSendingAction());

        const prom = RESTAPI.getUsersRegionsGuess(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getUsersRegionsGuessResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getUsersRegionsGuessErrorAction(error))
        })
      }

      export const generateGetUsersRegionsGuessRequestLogic = (options) => {
      
        return createLogic({
          type: GET_USERS_REGIONS_GUESS_REQUEST,
          cancelType: [GET_USERS_REGIONS_GUESS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getUsersRegionsGuessPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putUsersSettingsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putUsersSettingsSendingAction());

        const prom = RESTAPI.putUsersSettings(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putUsersSettingsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putUsersSettingsErrorAction(error))
        })
      }

      export const generatePutUsersSettingsRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_USERS_SETTINGS_REQUEST,
          cancelType: [PUT_USERS_SETTINGS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putUsersSettingsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getUsersSettingsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getUsersSettingsSendingAction());

        const prom = RESTAPI.getUsersSettings(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getUsersSettingsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getUsersSettingsErrorAction(error))
        })
      }

      export const generateGetUsersSettingsRequestLogic = (options) => {
      
        return createLogic({
          type: GET_USERS_SETTINGS_REQUEST,
          cancelType: [GET_USERS_SETTINGS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getUsersSettingsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putUsersSettingsRefreshPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putUsersSettingsRefreshSendingAction());

        const prom = RESTAPI.putUsersSettingsRefresh(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putUsersSettingsRefreshResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putUsersSettingsRefreshErrorAction(error))
        })
      }

      export const generatePutUsersSettingsRefreshRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_USERS_SETTINGS_REFRESH_REQUEST,
          cancelType: [PUT_USERS_SETTINGS_REFRESH_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putUsersSettingsRefreshPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const putWalletsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(putWalletsSendingAction());

        const prom = RESTAPI.putWallets(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(putWalletsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(putWalletsErrorAction(error))
        })
      }

      export const generatePutWalletsRequestLogic = (options) => {
      
        return createLogic({
          type: PUT_WALLETS_REQUEST,
          cancelType: [PUT_WALLETS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return putWalletsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWalletsPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWalletsSendingAction());

        const prom = RESTAPI.getWallets(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWalletsResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWalletsErrorAction(error))
        })
      }

      export const generateGetWalletsRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WALLETS_REQUEST,
          cancelType: [GET_WALLETS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWalletsPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWaytypesPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWaytypesSendingAction());

        const prom = RESTAPI.getWaytypes(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWaytypesResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWaytypesErrorAction(error))
        })
      }

      export const generateGetWaytypesRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WAYTYPES_REQUEST,
          cancelType: [GET_WAYTYPES_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWaytypesPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getGheedAppDownloadEntrywayMetaEndpointPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getGheedAppDownloadEntrywayMetaEndpointSendingAction());

        const prom = RESTAPI.getGheedAppDownloadEntrywayMetaEndpoint(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getGheedAppDownloadEntrywayMetaEndpointResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getGheedAppDownloadEntrywayMetaEndpointErrorAction(error))
        })
      }

      export const generateGetGheedAppDownloadEntrywayMetaEndpointRequestLogic = (options) => {
      
        return createLogic({
          type: GET_GHEED_APP_DOWNLOAD_ENTRYWAY_META_ENDPOINT_REQUEST,
          cancelType: [GET_GHEED_APP_DOWNLOAD_ENTRYWAY_META_ENDPOINT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getGheedAppDownloadEntrywayMetaEndpointPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getGheedMultipleChoiceEntrywayMetaEndpointPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getGheedMultipleChoiceEntrywayMetaEndpointSendingAction());

        const prom = RESTAPI.getGheedMultipleChoiceEntrywayMetaEndpoint(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getGheedMultipleChoiceEntrywayMetaEndpointResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getGheedMultipleChoiceEntrywayMetaEndpointErrorAction(error))
        })
      }

      export const generateGetGheedMultipleChoiceEntrywayMetaEndpointRequestLogic = (options) => {
      
        return createLogic({
          type: GET_GHEED_MULTIPLE_CHOICE_ENTRYWAY_META_ENDPOINT_REQUEST,
          cancelType: [GET_GHEED_MULTIPLE_CHOICE_ENTRYWAY_META_ENDPOINT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getGheedMultipleChoiceEntrywayMetaEndpointPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getGheedSecretCodeEntrywayMetaEndpointPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getGheedSecretCodeEntrywayMetaEndpointSendingAction());

        const prom = RESTAPI.getGheedSecretCodeEntrywayMetaEndpoint(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getGheedSecretCodeEntrywayMetaEndpointResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getGheedSecretCodeEntrywayMetaEndpointErrorAction(error))
        })
      }

      export const generateGetGheedSecretCodeEntrywayMetaEndpointRequestLogic = (options) => {
      
        return createLogic({
          type: GET_GHEED_SECRET_CODE_ENTRYWAY_META_ENDPOINT_REQUEST,
          cancelType: [GET_GHEED_SECRET_CODE_ENTRYWAY_META_ENDPOINT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getGheedSecretCodeEntrywayMetaEndpointPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getGheedImageChoiceEntrywayMetaEndpointPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getGheedImageChoiceEntrywayMetaEndpointSendingAction());

        const prom = RESTAPI.getGheedImageChoiceEntrywayMetaEndpoint(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getGheedImageChoiceEntrywayMetaEndpointResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getGheedImageChoiceEntrywayMetaEndpointErrorAction(error))
        })
      }

      export const generateGetGheedImageChoiceEntrywayMetaEndpointRequestLogic = (options) => {
      
        return createLogic({
          type: GET_GHEED_IMAGE_CHOICE_ENTRYWAY_META_ENDPOINT_REQUEST,
          cancelType: [GET_GHEED_IMAGE_CHOICE_ENTRYWAY_META_ENDPOINT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getGheedImageChoiceEntrywayMetaEndpointPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getGheedQuestionAnswerEntrywayMetaEndpointPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getGheedQuestionAnswerEntrywayMetaEndpointSendingAction());

        const prom = RESTAPI.getGheedQuestionAnswerEntrywayMetaEndpoint(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getGheedQuestionAnswerEntrywayMetaEndpointResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getGheedQuestionAnswerEntrywayMetaEndpointErrorAction(error))
        })
      }

      export const generateGetGheedQuestionAnswerEntrywayMetaEndpointRequestLogic = (options) => {
      
        return createLogic({
          type: GET_GHEED_QUESTION_ANSWER_ENTRYWAY_META_ENDPOINT_REQUEST,
          cancelType: [GET_GHEED_QUESTION_ANSWER_ENTRYWAY_META_ENDPOINT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getGheedQuestionAnswerEntrywayMetaEndpointPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getGheedSingleChoiceEntrywayMetaEndpointPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getGheedSingleChoiceEntrywayMetaEndpointSendingAction());

        const prom = RESTAPI.getGheedSingleChoiceEntrywayMetaEndpoint(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getGheedSingleChoiceEntrywayMetaEndpointResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getGheedSingleChoiceEntrywayMetaEndpointErrorAction(error))
        })
      }

      export const generateGetGheedSingleChoiceEntrywayMetaEndpointRequestLogic = (options) => {
      
        return createLogic({
          type: GET_GHEED_SINGLE_CHOICE_ENTRYWAY_META_ENDPOINT_REQUEST,
          cancelType: [GET_GHEED_SINGLE_CHOICE_ENTRYWAY_META_ENDPOINT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getGheedSingleChoiceEntrywayMetaEndpointPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWaytypesLookupPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWaytypesLookupSendingAction());

        const prom = RESTAPI.getWaytypesLookup(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWaytypesLookupResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWaytypesLookupErrorAction(error))
        })
      }

      export const generateGetWaytypesLookupRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WAYTYPES_LOOKUP_REQUEST,
          cancelType: [GET_WAYTYPES_LOOKUP_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWaytypesLookupPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWaytypesQueryPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWaytypesQuerySendingAction());

        const prom = RESTAPI.getWaytypesQuery(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWaytypesQueryResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWaytypesQueryErrorAction(error))
        })
      }

      export const generateGetWaytypesQueryRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WAYTYPES_QUERY_REQUEST,
          cancelType: [GET_WAYTYPES_QUERY_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWaytypesQueryPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getWaytypesValidatePromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getWaytypesValidateSendingAction());

        const prom = RESTAPI.getWaytypesValidate(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getWaytypesValidateResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getWaytypesValidateErrorAction(error))
        })
      }

      export const generateGetWaytypesValidateRequestLogic = (options) => {
      
        return createLogic({
          type: GET_WAYTYPES_VALIDATE_REQUEST,
          cancelType: [GET_WAYTYPES_VALIDATE_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getWaytypesValidatePromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getBlogpostBySlugPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getBlogpostBySlugSendingAction());

        const prom = RESTAPI.getBlogpostBySlug(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getBlogpostBySlugResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getBlogpostBySlugErrorAction(error))
        })
      }

      export const generateGetBlogpostBySlugRequestLogic = (options) => {
      
        return createLogic({
          type: GET_BLOGPOST_BY_SLUG_REQUEST,
          cancelType: [GET_BLOGPOST_BY_SLUG_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getBlogpostBySlugPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getCssPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getCssSendingAction());

        const prom = RESTAPI.getCss(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getCssResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getCssErrorAction(error))
        })
      }

      export const generateGetCssRequestLogic = (options) => {
      
        return createLogic({
          type: GET_CSS_REQUEST,
          cancelType: [GET_CSS_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getCssPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getYoutubeconfirmPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getYoutubeconfirmSendingAction());

        const prom = RESTAPI.getYoutubeconfirm(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getYoutubeconfirmResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getYoutubeconfirmErrorAction(error))
        })
      }

      export const generateGetYoutubeconfirmRequestLogic = (options) => {
      
        return createLogic({
          type: GET_YOUTUBECONFIRM_REQUEST,
          cancelType: [GET_YOUTUBECONFIRM_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getYoutubeconfirmPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getYoutubeconnectPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getYoutubeconnectSendingAction());

        const prom = RESTAPI.getYoutubeconnect(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getYoutubeconnectResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getYoutubeconnectErrorAction(error))
        })
      }

      export const generateGetYoutubeconnectRequestLogic = (options) => {
      
        return createLogic({
          type: GET_YOUTUBECONNECT_REQUEST,
          cancelType: [GET_YOUTUBECONNECT_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getYoutubeconnectPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    
      
      export const getYoutubelandingPromise = (payload, dispatch, token, nocatch) => {
        dispatch && dispatch(getYoutubelandingSendingAction());

        const prom = RESTAPI.getYoutubelanding(payload, token)
        .then((response) => {
          if (response) {
            const result = {...(response.constructor === Array ? {data: response} : response), requestParams: payload}
            return dispatch ? dispatch(getYoutubelandingResponseAction(result)) : result
          }
        })

        return nocatch ? prom : prom.catch((error) => {
          dispatch && dispatch(getYoutubelandingErrorAction(error))
        })
      }

      export const generateGetYoutubelandingRequestLogic = (options) => {
      
        return createLogic({
          type: GET_YOUTUBELANDING_REQUEST,
          cancelType: [GET_YOUTUBELANDING_CANCELED, AUTH_LOGOUT_REQUEST],
          process({ cancelled$, action }, dispatch, done) {
            const source = RESTAPI.getSource();
            cancelled$.subscribe(() => {
              source.cancel('Request cancelled')
            });
            return getYoutubelandingPromise(action.payload, dispatch, source.token).then(() => done())
            
          },
          ...(typeof window != "undefined" ? options : {})
        })
        

      }
    

    export const RESTLogics = {
      putClaimPrizeRequestLogic: generatePutClaimPrizeRequestLogic(),
getClaimPrizeRequestLogic: generateGetClaimPrizeRequestLogic(),
getContestgenerationRequestLogic: generateGetContestgenerationRequestLogic(),
putPrivateProductByEventidRequestLogic: generatePutPrivateProductByEventidRequestLogic(),
getPrivateProductByEventidRequestLogic: generateGetPrivateProductByEventidRequestLogic(),
getPrivateProductRequestLogic: generateGetPrivateProductRequestLogic(),
getAuthStateRequestLogic: generateGetAuthStateRequestLogic(),
getAuthStateReadRequestLogic: generateGetAuthStateReadRequestLogic(),
postBetaApplyRequestLogic: generatePostBetaApplyRequestLogic(),
getBotScoreRequestLogic: generateGetBotScoreRequestLogic(),
getTwitterBotScoreRequestLogic: generateGetTwitterBotScoreRequestLogic(),
putCartRequestLogic: generatePutCartRequestLogic(),
getCartRequestLogic: generateGetCartRequestLogic(),
putCouponRequestLogic: generatePutCouponRequestLogic(),
deleteCouponRequestLogic: generateDeleteCouponRequestLogic(),
getCategoriesRequestLogic: generateGetCategoriesRequestLogic(),
postContactRequestLogic: generatePostContactRequestLogic(),
getDiscordconfirmRequestLogic: generateGetDiscordconfirmRequestLogic(),
getDiscordconnectRequestLogic: generateGetDiscordconnectRequestLogic(),
getDiscordlandingRequestLogic: generateGetDiscordlandingRequestLogic(),
getWinnerDrawRequestLogic: generateGetWinnerDrawRequestLogic(),
getWinnerDrawMaxRequestLogic: generateGetWinnerDrawMaxRequestLogic(),
getWinnerDrawMedRequestLogic: generateGetWinnerDrawMedRequestLogic(),
getPrivateGiveawayInfosRequestLogic: generateGetPrivateGiveawayInfosRequestLogic(),
getWinnerDrawManualRequestLogic: generateGetWinnerDrawManualRequestLogic(),
getExtRedirectByIdRequestLogic: generateGetExtRedirectByIdRequestLogic(),
getExtRedirectCreateRequestLogic: generateGetExtRedirectCreateRequestLogic(),
postEmailPwChangeRequestLogic: generatePostEmailPwChangeRequestLogic(),
postEmailChangeRequestLogic: generatePostEmailChangeRequestLogic(),
getEmailChangeRequestLogic: generateGetEmailChangeRequestLogic(),
postEmailLoginRequestLogic: generatePostEmailLoginRequestLogic(),
postEmailRegisterRequestLogic: generatePostEmailRegisterRequestLogic(),
putEmailPwResetRequestLogic: generatePutEmailPwResetRequestLogic(),
postEmailPwResetRequestLogic: generatePostEmailPwResetRequestLogic(),
postEmailVerifyRequestLogic: generatePostEmailVerifyRequestLogic(),
getEmailVerifyRequestLogic: generateGetEmailVerifyRequestLogic(),
postEntrywaysEntityRequestLogic: generatePostEntrywaysEntityRequestLogic(),
getEntrywaysEntityRequestLogic: generateGetEntrywaysEntityRequestLogic(),
getEntrywaysEntityUrlRequestLogic: generateGetEntrywaysEntityUrlRequestLogic(),
getFacebookconfirmRequestLogic: generateGetFacebookconfirmRequestLogic(),
getFacebookConnectRequestLogic: generateGetFacebookConnectRequestLogic(),
getFacebooklandingRequestLogic: generateGetFacebooklandingRequestLogic(),
postImagesRequestLogic: generatePostImagesRequestLogic(),
getImagesRequestLogic: generateGetImagesRequestLogic(),
getImagesProductsRequestLogic: generateGetImagesProductsRequestLogic(),
getImagesByUuidRequestLogic: generateGetImagesByUuidRequestLogic(),
deleteJwtCookieRequestLogic: generateDeleteJwtCookieRequestLogic(),
getJwtCookieRequestLogic: generateGetJwtCookieRequestLogic(),
deleteJwtProtectedRequestLogic: generateDeleteJwtProtectedRequestLogic(),
getJwtOptionalRequestLogic: generateGetJwtOptionalRequestLogic(),
getJwtProtectedRequestLogic: generateGetJwtProtectedRequestLogic(),
deleteJwtRefreshRequestLogic: generateDeleteJwtRefreshRequestLogic(),
getJwtRefreshRequestLogic: generateGetJwtRefreshRequestLogic(),
deleteJwtTokenRequestLogic: generateDeleteJwtTokenRequestLogic(),
getJwtTokenRequestLogic: generateGetJwtTokenRequestLogic(),
getMixerconfirmRequestLogic: generateGetMixerconfirmRequestLogic(),
getMixerconnectRequestLogic: generateGetMixerconnectRequestLogic(),
getMixerlandingRequestLogic: generateGetMixerlandingRequestLogic(),
putNotificationsRequestLogic: generatePutNotificationsRequestLogic(),
getNotificationsRequestLogic: generateGetNotificationsRequestLogic(),
putPaymentRequestLogic: generatePutPaymentRequestLogic(),
getPaymentRequestLogic: generateGetPaymentRequestLogic(),
getProductsRequestLogic: generateGetProductsRequestLogic(),
getProductsByIdRequestLogic: generateGetProductsByIdRequestLogic(),
getProductsByIdStatusRequestLogic: generateGetProductsByIdStatusRequestLogic(),
getVariationsRequestLogic: generateGetVariationsRequestLogic(),
getVerificationGenerateRequestLogic: generateGetVerificationGenerateRequestLogic(),
postVerificationGenerateRequestLogic: generatePostVerificationGenerateRequestLogic(),
getPublicUserIdRequestLogic: generateGetPublicUserIdRequestLogic(),
getPublicUserCoinsRequestLogic: generateGetPublicUserCoinsRequestLogic(),
getRedirectByIdRequestLogic: generateGetRedirectByIdRequestLogic(),
getRedirectCreateRequestLogic: generateGetRedirectCreateRequestLogic(),
postRafflelistRequestLogic: generatePostRafflelistRequestLogic(),
getRafflelistRequestLogic: generateGetRafflelistRequestLogic(),
getRafflelistEntriesRequestLogic: generateGetRafflelistEntriesRequestLogic(),
getRafflesOwnRequestLogic: generateGetRafflesOwnRequestLogic(),
getRafflelistParticipatedRequestLogic: generateGetRafflelistParticipatedRequestLogic(),
getPrizesByRaffleIdRequestLogic: generateGetPrizesByRaffleIdRequestLogic(),
getRafflePromotionRequestLogic: generateGetRafflePromotionRequestLogic(),
getRafflelistSearchRequestLogic: generateGetRafflelistSearchRequestLogic(),
putRafflesRequestLogic: generatePutRafflesRequestLogic(),
getRafflesRequestLogic: generateGetRafflesRequestLogic(),
putRafflesFinalizeRequestLogic: generatePutRafflesFinalizeRequestLogic(),
getWinnersByRaffleIdRequestLogic: generateGetWinnersByRaffleIdRequestLogic(),
getRafflelistFilterOrderbyRequestLogic: generateGetRafflelistFilterOrderbyRequestLogic(),
getRaffleByIdRequestLogic: generateGetRaffleByIdRequestLogic(),
getRaffleByIdCouponsRequestLogic: generateGetRaffleByIdCouponsRequestLogic(),
getRaffleByIdCouponsStatusRequestLogic: generateGetRaffleByIdCouponsStatusRequestLogic(),
getDrawStatusByRaffleIdRequestLogic: generateGetDrawStatusByRaffleIdRequestLogic(),
putRaffleByIdEditRequestLogic: generatePutRaffleByIdEditRequestLogic(),
getRaffleByIdEditRequestLogic: generateGetRaffleByIdEditRequestLogic(),
getRaffleByIdEntriesRequestLogic: generateGetRaffleByIdEntriesRequestLogic(),
postRafflesEntrywaysRequestLogic: generatePostRafflesEntrywaysRequestLogic(),
getRafflesEntrywaysRequestLogic: generateGetRafflesEntrywaysRequestLogic(),
postRaffleByIdReportRequestLogic: generatePostRaffleByIdReportRequestLogic(),
getWaysByRaffleIdRequestLogic: generateGetWaysByRaffleIdRequestLogic(),
putWaysByRaffleIDcopyRequestLogic: generatePutWaysByRaffleIDcopyRequestLogic(),
getWaysByRaffleIdStatusRequestLogic: generateGetWaysByRaffleIdStatusRequestLogic(),
getRecaptchaRequestLogic: generateGetRecaptchaRequestLogic(),
putSaleRequestLogic: generatePutSaleRequestLogic(),
getSaleRequestLogic: generateGetSaleRequestLogic(),
getSalecancelRequestLogic: generateGetSalecancelRequestLogic(),
getSaleconfirmRequestLogic: generateGetSaleconfirmRequestLogic(),
getUsercountRequestLogic: generateGetUsercountRequestLogic(),
deleteSocialsEntityRequestLogic: generateDeleteSocialsEntityRequestLogic(),
getSocialsScopesRequestLogic: generateGetSocialsScopesRequestLogic(),
getCoinleadersRequestLogic: generateGetCoinleadersRequestLogic(),
postSupportRequestLogic: generatePostSupportRequestLogic(),
getTwitchconfirmRequestLogic: generateGetTwitchconfirmRequestLogic(),
getTwitchconnectRequestLogic: generateGetTwitchconnectRequestLogic(),
getTwitchlandingRequestLogic: generateGetTwitchlandingRequestLogic(),
getTwittercallbackRequestLogic: generateGetTwittercallbackRequestLogic(),
getTwitterconfirmRequestLogic: generateGetTwitterconfirmRequestLogic(),
getTwitterConnectRequestLogic: generateGetTwitterConnectRequestLogic(),
postBillingRequestLogic: generatePostBillingRequestLogic(),
getBillingRequestLogic: generateGetBillingRequestLogic(),
putBusinessUserRequestLogic: generatePutBusinessUserRequestLogic(),
postUserDeleteRequestLogic: generatePostUserDeleteRequestLogic(),
getUserDeleteRequestLogic: generateGetUserDeleteRequestLogic(),
getUsersRegionsGuessRequestLogic: generateGetUsersRegionsGuessRequestLogic(),
putUsersSettingsRequestLogic: generatePutUsersSettingsRequestLogic(),
getUsersSettingsRequestLogic: generateGetUsersSettingsRequestLogic(),
putUsersSettingsRefreshRequestLogic: generatePutUsersSettingsRefreshRequestLogic(),
putWalletsRequestLogic: generatePutWalletsRequestLogic(),
getWalletsRequestLogic: generateGetWalletsRequestLogic(),
getWaytypesRequestLogic: generateGetWaytypesRequestLogic(),
getGheedAppDownloadEntrywayMetaEndpointRequestLogic: generateGetGheedAppDownloadEntrywayMetaEndpointRequestLogic(),
getGheedMultipleChoiceEntrywayMetaEndpointRequestLogic: generateGetGheedMultipleChoiceEntrywayMetaEndpointRequestLogic(),
getGheedSecretCodeEntrywayMetaEndpointRequestLogic: generateGetGheedSecretCodeEntrywayMetaEndpointRequestLogic(),
getGheedImageChoiceEntrywayMetaEndpointRequestLogic: generateGetGheedImageChoiceEntrywayMetaEndpointRequestLogic(),
getGheedQuestionAnswerEntrywayMetaEndpointRequestLogic: generateGetGheedQuestionAnswerEntrywayMetaEndpointRequestLogic(),
getGheedSingleChoiceEntrywayMetaEndpointRequestLogic: generateGetGheedSingleChoiceEntrywayMetaEndpointRequestLogic(),
getWaytypesLookupRequestLogic: generateGetWaytypesLookupRequestLogic(),
getWaytypesQueryRequestLogic: generateGetWaytypesQueryRequestLogic(),
getWaytypesValidateRequestLogic: generateGetWaytypesValidateRequestLogic(),
getBlogpostBySlugRequestLogic: generateGetBlogpostBySlugRequestLogic(),
getCssRequestLogic: generateGetCssRequestLogic(),
getYoutubeconfirmRequestLogic: generateGetYoutubeconfirmRequestLogic(),
getYoutubeconnectRequestLogic: generateGetYoutubeconnectRequestLogic(),
getYoutubelandingRequestLogic: generateGetYoutubelandingRequestLogic()
    }
  