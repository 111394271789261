const focusUpListeners = [];
const onFocusUp = event => {
  focusUpListeners.forEach(listener => {
    listener(event);
  });
};

if (typeof window != 'undefined') {
  document.addEventListener('focusin', onFocusUp);
}

export const addFocusInListener = listener => {
  focusUpListeners.push(listener);
};

export const removeFocusInListener = listener => {
  let i = focusUpListeners.indexOf(listener);
  if (i != -1) {
    focusUpListeners.splice(i, 1);
  }
};
