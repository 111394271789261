import React from 'react';
import styled from 'styled-components';
import Tooltip from 'common/MaterialUI/Tooltip';

import classNames from 'classnames';
import { media } from 'styles';


const Button = styled.a`
  cursor: pointer;
  width: 14px;
  height: 14px;
  min-width: 14px;
  border-radius: 12.5px;
  background-color: #fc521e;

  font-family: Nunito;
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;

  text-align: center;
  display: inline-block;
  padding-top: 1px;

  
`;

const Wrapper = styled.div`
  display: inline-block;
  padding-top: 2px;
  width: 25px;
  height: 20px;

  position: relative;
  left: 5px;
  top: -2px;

  ${media.tabletMax`
    margin-right: 5px;
  `}
`

export class MandatoryIcon extends React.PureComponent {
  state = { active: false };

  handleMouseOver = () => {

    if (!this.props.title) {
      return;
    }


    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.setState({ active: true });
    }, 300);
  };

  handleMouseOut = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.setState({ active: false });
  };

  renderAsHtml = (title) => {
    return <div dangerouslySetInnerHTML={{ __html: title }} />
  }

  render() {
    const { onClick, title, placement, inText } = this.props;
    const { active } = this.state;

    return (
      <Tooltip disableHoverListener={title ? false : true} disableFocusListener title={this.renderAsHtml(title)} placement={placement} open={title && active}>
        <Wrapper className={classNames({ inText })} onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          onClick={(event) =>
            onClick ? onClick(event) : this.setState({ active: !active })
          }><Button>
            !
        </Button></Wrapper>
      </Tooltip>
    );
  }
}

export default MandatoryIcon;


