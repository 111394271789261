import propTypes from 'prop-types';
import React from 'react';
import { addResizeListener, removeResizeListener } from 'helpers/resize';
import { addScrollListener, removeScrollListener } from 'helpers/scroll';
import { scrollToDOMNode } from 'helpers/scrollTo';

const REGISTERED = [];

export class AnchorController extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: null,
      viewport: null,
      scrollInfo: null,
    };
  }

  checkVisibility = () => {
    const { viewport } = this.state;

    if (viewport) {
      const menuRect = this.menuNode.firstChild.firstChild.getBoundingClientRect();
      const distances = {};

      REGISTERED.forEach((id, i) => {
        const node = document.getElementById(id);

        if (node && this.menuNode) {
          const rect = node.getBoundingClientRect();
          distances[id] = Math.abs(Math.abs(rect.top) - menuRect.top);
        }
      });

      const active = REGISTERED.length
        ? REGISTERED.reduce(
          (prev, key) =>
            distances[key] < distances[prev] || !prev ? key : prev,
          null,
        )
        : null;

      this.setState({
        active: active,
      });
    }
  };

  resizeListener = data => {
    this.setState({ viewport: data }, this.checkVisibility);
  };

  scrollListener = data => {
    this.setState(
      {
        scrollInfo: {
          clientHeight: data.clientHeight,
          scrollHeight: data.scrollHeight,
          scrollTop: data.scrollTop,
        },
      },
      this.checkVisibility,
    );
  };

  scrollTo = (target, offset) => {

    const node = document.getElementById(target);

    if (node) {
      scrollToDOMNode(node, null, offset);
    }
  };

  componentDidMount() {
    addResizeListener(this.resizeListener);
    addScrollListener(this.scrollListener);
  }

  componentWillUnmount() {
    removeResizeListener(this.resizeListener);
    removeScrollListener(this.scrollListener);
  }

  getChildContext = () => {
    return {
      registerElement: this.registerElement,
      unregisterElement: this.unregisterElement,
      active: this.state.active,
      scrollTo: this.scrollTo,
    };
  };

  static childContextTypes = {
    registerElement: propTypes.any,
    unregisterElement: propTypes.any,
    active: propTypes.any,
    scrollTo: propTypes.any,
  };

  registerElement = id => {
    if (REGISTERED.indexOf(id) == -1) {
      REGISTERED.push(id);
    }
  };

  unregisterElement = id => {
    if (REGISTERED.indexOf(id) != -1) {
      REGISTERED.splice(REGISTERED.indexOf(id), 1);
    }
  };

  render() {
    const { children } = this.props;

    return <div ref={n => (this.menuNode = n)}>{children}</div>;
  }
}

export default AnchorController;
