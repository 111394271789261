import React from 'react';
import styled from 'styled-components';
import Section from 'common/Section';
import { PageLayout } from 'common/PageLayout';
import Masonry from 'common/Masonry';
import { ScrollToTopButton } from 'common/ScrollToTopButton';
import { media } from '../../../styles';
import Controls from './Controls';
import Popular from './Popular';
import EmptyBox from 'common/EmptyBox';
import CampaignBanner from '../../../components/Campaign/Banner';




const Styles = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	width: 100%;

	> * + * {
		margin-top: 30px;
	}

	min-width: 300px;

	${media.tablet`
min-width: 630px;
`};
	${media.desktop`
  min-width: 960px;
`};
	${media.widescreen`
  min-width: 1290px;
`};
`;


const Spacer = styled.div`
height: 20px
`

export class Layout extends React.PureComponent {
  render() {
    const {
      loading,
      loaded,
      raffles,
      scrollComplete,

      handleCategoryDropDownConfirm,
      handleScrolledToBottom,

      sortOptions,
      sortKey,

      productCategory,
      productCategories,

      handleSortDropDownConfirm,
      handleStatusDropDownConfirm,
      contestCategory,
      handleChangeRegionSettings,
      region,
      regionMode,
      regionActive,
      handleRaffleSearch,
    } = this.props;
    return (
      <PageLayout list="true">
        <Styles>
          <Section>
            <CampaignBanner placement="giveaways" marginTop={15} marginBottom={35} />
            <Controls
              {...{
                productCategories,
                handleSortDropDownConfirm,
                sortOptions,
                sortKey,
                productCategory,
                handleCategoryDropDownConfirm,
                handleStatusDropDownConfirm,
                handleRaffleSearch,
                handleChangeRegionSettings,
                contestCategory,
                region,
                regionMode,
                regionActive
              }}
            />
            <Spacer />
          </Section>
          {loading && (!raffles || raffles.length == 0) && <EmptyBox dark noBorder loading transparent />}
          {raffles &&
            raffles.length > 0 && (
              <Masonry
                items={raffles}
                hasMore={!scrollComplete}
                isLoading={loading}
                onInfiniteLoad={handleScrolledToBottom}
                getState={() => this.state}
              />
            )}
          {loaded && raffles && raffles.length == 0 && <Popular />}
          {raffles && <ScrollToTopButton />}
        </Styles>
      </PageLayout>
    );
  }
}

export default Layout;
