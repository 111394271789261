import { connect } from 'react-redux';
import View from './View';

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    pathname: state.location.pathname,
  };
};

export default connect(mapStateToProps)(View);
