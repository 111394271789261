import { handleActions, createAction } from 'redux-actions';
import { GET_PUBLIC_USER_ID_RESPONSE, GET_COINLEADERS_RESPONSE, GET_PUBLIC_USER_COINS_RESPONSE } from '../api';


export const GET_WALLETS_LIST_REQUEST = 'stats/GET_WALLETS_LIST_REQUEST';
export const GET_WALLETS_LIST_RESPONSE = 'stats/GET_WALLETS_LIST_RESPONSE';
export const GET_WALLETS_LIST_ERROR = 'stats/GET_WALLETS_LIST_ERROR';

export const getWalletsListRequestAction = createAction(GET_WALLETS_LIST_REQUEST);
export const getWalletsListResponseAction = createAction(GET_WALLETS_LIST_RESPONSE);
export const getWalletsListErrorAction = createAction(GET_WALLETS_LIST_ERROR);

export const GET_RECENTWINNERS_REQUEST = 'stats/GET_RECENTWINNERS_REQUEST';
export const GET_RECENTWINNERS_RESPONSE = 'stats/GET_RECENTWINNERS_RESPONSE';
export const GET_RECENTWINNERS_ERROR = 'stats/GET_RECENTWINNERS_ERROR';

export const getRecentWinnersRequestAction = createAction(GET_RECENTWINNERS_REQUEST);
export const getRecentWinnersResponseAction = createAction(GET_RECENTWINNERS_RESPONSE);
export const getRecentWinnersErrorAction = createAction(GET_RECENTWINNERS_ERROR);

export const WALLETS_LIST_MYWALLETS = "myWallets";
export const WALLETS_LIST_MYHIDDENWALLETS = "myHiddenWallets";

const initialState = {
  users: {},
  coins: {},
  coinleaders: {},
  recentWinners: {},

  [WALLETS_LIST_MYWALLETS]: {
    wallets: [],
    current_page: 0,
    total_pages: 1,
    limit: 8,
    disableHidden: false,
    loading: false,
    loaded: false,
  },

  [WALLETS_LIST_MYHIDDENWALLETS]: {
    wallets: [],
    current_page: 0,
    total_pages: 1,
    limit: 8,
    disableHidden: false,
    loading: false,
    loaded: false,
  }

};

export const statsReducer = handleActions(
  {
    [GET_RECENTWINNERS_RESPONSE]: (state, action) => {
      return {
        ...state,
        recentWinners: { ...state.recentWinners, [action.payload[1]]: action.payload[0] }

      };
    },


    [GET_WALLETS_LIST_REQUEST]: (state, action) => {
      return {
        ...state,
        [action.payload[1]]: {
          ...(state[action.payload[1]] || []),
          loading: true,
        },
      };
    },

    [GET_WALLETS_LIST_RESPONSE]: (state, action) => {
      return {
        ...state,
        [action.payload[1]]: {
          ...(state[action.payload[1]] || []),
          loading: false,
          loaded: true,
          total_pages: action.payload[0].total_pages,
          current_page: action.payload[0].current_page,
          wallets: action.payload[0].wallets,
        },
      };
    },


    [GET_PUBLIC_USER_ID_RESPONSE]: (state, action) => {
      return {
        ...state,
        users: {
          ...state.users,
          ...(action.payload && action.payload.public_users
            ? action.payload.public_users.reduce(
              (p, user) => ({ ...p, [user.USERID]: user }),
              {},
            )
            : {}),
        },
      };
    },

    [GET_COINLEADERS_RESPONSE]: (state, action) => {
      return {
        ...state,
        coinleaders:
          action.payload && action.payload.coinleaders
            ? {
              [action.payload.streamerid]: [
                ...action.payload.coinleaders,
              ].sort((a, b) => a.coins - b.coins),
            }
            : state.coinleaders,
      };
    },

    [GET_PUBLIC_USER_COINS_RESPONSE]: (state, action) => {
      const result = action.payload.data.reduce((p, c) => ({ ...p, [c.USERID]: c.coins }), {})
      return { ...state, coins: { ...state.coins, ...result } }
    }
  },
  initialState,
);

export default statsReducer;
