import Link from 'redux-first-router-link';
import styled from 'styled-components';
import React from 'react';
import { H5ExtraBoldBlack } from 'styles/Kit/H5ExtraBold';
import ClampLines from 'react-clamp-lines';



const View = styled(Link)`
  max-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 257px;
  max-height: 45px;

  position: absolute;
  top: 277px;
  left: 21px;
  z-index: 2;

  ${H5ExtraBoldBlack}

`;
export const Title = ({ raffle, handleLinkEvent }) => <View
  draggable="false"
  to={`/giveaways/${raffle.EVENTID}`}
  onMouseUp={handleLinkEvent}
  onClick={handleLinkEvent}
  title={raffle && raffle.eventname}
>
  {raffle && raffle.eventname && <ClampLines
    text={raffle.eventname}
    lines={2} ellipsis=" ..." buttons={false} />}


</View>

export default Title;
