import React from 'react';
import styled from 'styled-components';
import numeral from 'i18n/numeral';
import { Translation } from 'react-i18next';
import { H6ExtraBoldBlue } from '../../../styles/Kit/H6ExtraBold';

const Layout = styled.div`
  ${H6ExtraBoldBlue}
`;


export const LaunchIn = ({ time }) => (
  <Translation>
    {t => (
      <Layout>
        {t('ga_launch_in')}{' '}
        {time.days > 0
          ? t('ga_launch_in_days', { count: time.days })
          : `${numeral(time.hours).format('00')}:${numeral(
            time.minutes,
          ).format('00')}:${numeral(time.seconds).format('00')}`}
      </Layout>
    )}
  </Translation>
);
