import React from "react";
import { Scrollbars } from 'react-custom-scrollbars';


class CustomScrollBar extends React.PureComponent {

  render() {
    const { children } = this.props
    return <Scrollbars autoHeight
      autoHeightMin={24} autoHeightMax={"80vh"}>{children}</Scrollbars>
  }
}

export default CustomScrollBar
