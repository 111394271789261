import React from 'react';
import propTypes from 'prop-types';

export class AnchorLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { target } = this.props;
    const { active } = nextContext;

    this.setState({
      active: active == target,
    });
  }

  render() {
    const { target, children } = this.props;
    const { active } = this.state;

    return (
      <a
        className={active ? 'active' : ''}
        href={`#${target}`}
        onClick={this.handleClick}
      >
        {active && <span className="icon-Ico16-ArrowLeft-Medium" />}
        {children}
      </a>
    );
  }

  componentDidMount() {
    const { target } = this.props;
    const { registerElement } = this.context;
    registerElement(target);
  }

  componentWillUnmount() {
    const { target } = this.props;
    const { unregisterElement } = this.context;
    unregisterElement(target);
  }

  handleClick = event => {
    event.preventDefault();

    const { target, offset } = this.props;
    const { scrollTo } = this.context;
    scrollTo(target, offset);
  };

  static contextTypes = {
    registerElement: propTypes.any,
    unregisterElement: propTypes.any,
    active: propTypes.any,
    scrollTo: propTypes.any,
  };
}
