import React from 'react';
import styled from 'styled-components';

const Layout = styled.a`
  position: relative;
  background: transparent;
  width: 34px;
  height: 34px;
  border-radius: 25px;
  box-shadow: inset 0 2px 0 0 rgba(76, 203, 253, 0.3);
  border: solid 1px #36a6fc;
  display: flex;
  justify-content: center;
  align-items: center;

  &[class^='icon-'] {
    color: white;

    &.blue {
      color: #36a6fc;
    }

    &.disabled {
      color: #686868;
    }

    :before {
      display: flex;
    }
  }

  cursor: pointer;
`;

const Num = styled.div`
  height: 16px;
  min-width: 16px;
  border-radius: 8px;
  background-color: #36a6fc;
  box-shadow: -2px 3px 0 0 #1d1d1d;

  position: absolute;
  top: -5px;
  right: 0;
  transform: translateX(33%);

  font-family: Nunito;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;

  color: white;
  padding: 3px 5px;

  display: flex;
  justify-content: center;
`;

export const IconButton = props => {
  return (
    <Layout {...props}>
      {typeof props.number != 'undefined' &&
        props.number > 0 && <Num>{props.number}</Num>}
    </Layout>
  );
};
