import { createLogic } from 'redux-logic';
import { CATEGORY_LIST_RESPONSE } from 'rdx/modules/giveawayLists';
import { getRaffleByIdEntriesRequestAction } from '../modules/api/index';

const getRaffleCategoryListResponseLogic = createLogic({
  type: [CATEGORY_LIST_RESPONSE],
  process({ getState, action }, dispatch, done) {
    try {
      if (getState().auth.user) {
        const ids = action.payload[0].raffles.map(r => r.EVENTID);
        if (ids.length) {
          dispatch(getRaffleByIdEntriesRequestAction({ id: ids }))
        }
      }

    } catch (error) {
      console.log(error);
    }

    done();
  },
});

export default getRaffleCategoryListResponseLogic;
