import { createLogic } from 'redux-logic';
import { RESTAPI } from 'rdx/modules/api/rest';
import { GET_WAYTYPES_VALIDATE_REQUEST, GET_WAYTYPES_VALIDATE_ERROR, GET_WAYTYPES_VALIDATE_RESPONSE } from '../modules/api/index';

const getWaytypesValidateRequestLogic = createLogic({
  type: GET_WAYTYPES_VALIDATE_REQUEST,
  latest: true,
  debounce: 1000,
  async process({ getState, action }, dispatch, done) {
    try {
      await RESTAPI.getWaytypesValidate(action.payload)
      dispatch({ type: GET_WAYTYPES_VALIDATE_RESPONSE, payload: action.payload })

    } catch (error) {
      dispatch({ type: GET_WAYTYPES_VALIDATE_ERROR, payload: { ...action.payload, errors: error.response.data } })
    }
    done();
  },
});

export default getWaytypesValidateRequestLogic;
