import { createAction, handleActions } from 'redux-actions';
import { PUT_CART_RESPONSE } from './../api';

export const modalAction = createAction('modals/SET_MODAL');
export const modalErrorAction = createAction('modals/ERROR');
export const modalLoadingAction = createAction('modals/LOADING');
export const modalClearAction = createAction('modals/CLEAR');

export const MODAL_SUPPORT = 'MODAL_SUPPORT';
export const MODAL_CONTACT_US = 'MODAL_CONTACT_US';
export const MODAL_NOTIFICATION = 'MODAL_NOTIFICATION';
export const MODAL_PARTNER_APPLY = 'MODAL_PARTNER_APPLY';
export const MODAL_ADD_PRODUCT = 'MODAL_ADD_PRODUCT';
export const MODAL_LOCALIZATION = 'MODAL_LOCALIZATION';
export const MODAL_CHECKOUT = 'MODAL_CHECKOUT';
export const MODAL_CROP_IMAGE = 'MODAL_CROP_IMAGE';
export const MODAL_LOGIN = 'MODAL_LOGIN';
export const MODAL_TOO_EARLY = 'MODAL_TOO_EARLY';
export const MODAL_TOO_LATE = 'MODAL_TOO_LATE';
export const MODAL_OWN_RAFFLE = 'MODAL_OWN_RAFFLE';
export const MODAL_CONNECT = 'MODAL_CONNECT';
export const MODAL_EMAILLOGIN_SETUP = 'MODAL_EMAILLOGIN_SETUP';
export const MODAL_PREVIEW = 'MODAL_PREVIEW';
export const MODAL_LOYALTY = 'MODAL_LOYALTY';
export const MODAL_ROLE = 'MODAL_ROLE';
export const MODAL_ROLE_CHANGE_STREAMER = 'MODAL_ROLE_CHANGE_STREAMER';
export const MODAL_ROLE_CHANGE_VIEWER = 'MODAL_ROLE_CHANGE_VIEWER';
export const MODAL_PUBLICPRIVATE = 'MODAL_PUBLICPRIVATE';
export const MODAL_PUBLICRULES = 'MODAL_PUBLICRULES';
export const MODAL_REPORT_GIVEAWAY = 'MODAL_REPORT_GIVEAWAY';
export const MODAL_POSTLAUNCH = 'MODAL_POSTLAUNCH';
export const MODAL_ACCOUNTDELETION = 'MODAL_ACCOUNTDELETION';



export const MODAL_WHEREAREYOUFROM = 'MODAL_WHEREAREYOUFROM';

export const showNotificationModal = config => {
  return modalAction({
    modal: MODAL_NOTIFICATION,
    data: config,
  });
}




const initialState = {
  modal: null,
  error: null,
  data: null,
  loading: false,
};

export const modalsReducer = handleActions(
  {
    [PUT_CART_RESPONSE]: (state, action) => ({
      ...state,
      loading: false,
    }),

    ['modals/SET_MODAL']: (state, action) => ({
      ...state,
      modal: action.payload.modal,
      data: action.payload.data,
      loading: action.payload.loading,
      error: action.payload.error,
    }),
    ['modals/LOADING']: (state, action) => ({
      ...state,
      loading: action.payload,
    }),

    ['modals/CLEAR']: (state, action) => ({
      ...state,
      modal: null,
      data: null,
      loading: null,
      error: null,
    }),
  },
  initialState,
);

export default modalsReducer;
