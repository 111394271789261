import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { updateOpenContestOnFinishedThunk } from 'rdx/modules/timers/thunks';
import { updateContestTimers } from '../helpers/giveaways';
import { SERVER_DATE_DIFFERENCE } from 'rdx/modules/api/response-interceptor';
var ContestWorker = require('../workers/giveaways.worker');

const getContests = state => state.raffles.raffles;
const getPreviews = state => state.raffles.preview;
const getEdit = state => state.creator.edit;

const reducedContestSelector = createSelector(
  [getContests, getPreviews, getEdit],
  (contests, previews, edit) => {
    const allContests = edit ? { ...previews, ...contests, [edit.EVENTID]: edit } : { ...previews, ...contests };
    return contests
      ? Object.keys(allContests).reduce((p, key) => {
        const { active_from, active_to } = allContests[key];
        return { ...p, [key]: { active_from, active_to } };
      }, {})
      : null;
  },
);

const mapStateToProps = (state, ownProps) => {
  return {
    contests: reducedContestSelector(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateTimers: data => {
      dispatch(updateOpenContestOnFinishedThunk(data));
      updateContestTimers(data);
    },
  };
};

class WorkersComponent extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (typeof window != 'undefined') {
      this.contestWorker = new ContestWorker();

      const callWorker = () => {
        this._timeout = setTimeout(() => {

          this.contestWorker
            .calculateTimers(this.props.contests, SERVER_DATE_DIFFERENCE)
            .then(this.handleContestWorkerMessage);
          callWorker();
        }, 1000);
      };

      callWorker();
    }
  }

  handleContestWorkerMessage = data => {
    const { updateTimers } = this.props;
    updateTimers(data);
  };

  componentWillUnmount() {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
  }

  render() {
    return null;
  }
}

const Workers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkersComponent);

export default Workers;
