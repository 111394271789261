import styled from 'styled-components';
import React from 'react';
import Item from './Item';

const Layout = styled.div`
  
  > *:first-child {
    margin-top: ${({ completed }) => completed ? '27px' : '0'};
  } 
`

const View = ({ coupons, completed }) => {
  return coupons ? (
    <Layout completed={completed}>
      {coupons.map(c => <Item key={c.COUPONINFOID} coupon={c} completed={completed} />)}
    </Layout>
  ) : null;
};

export default View;
