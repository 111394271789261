import React from 'react';
import styled from 'styled-components';
import { bigPaddingMixin } from 'styles';
import EmptyBox from 'common/EmptyBox';

const Layout = styled.div`

  align-items: center;
  padding-top: 116px;

  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.list {
    padding-top: 56px;
  }

  &.home {
    padding: 0;
  }

  &.logoOnly {
    padding-top: 146px;
  }
`;

const Content = styled.div`
  padding-top: 45px;
  padding-bottom: 3.75em;

  &.centered {
    align-items: center;
  }

  > * + * {
    padding-top: 3.4375em;
  }

  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  :not(.noPadding) {
    ${bigPaddingMixin};
  }

  &.stretched {
    width: 100%;
    padding-bottom: 0;
  }

  &.centered {
    align-items: center;
  }
`;

const getClassNamesFromProps = props => {
  const propsWithoutChildren = Object.keys(props).reduce((p, c) => {
    if (c != 'children') {
      p[c] = props[c];
    }
    return p;
  }, {});
  return Object.keys(propsWithoutChildren).join(' ');
};

export const PageLayout = ({ minWidth, ...props }) => {
  return (
    <Layout {...props} className={getClassNamesFromProps(props)}>
      {props.loading ? <EmptyBox dark noBorder loading transparent /> : <Content style={{ minWidth }} className={getClassNamesFromProps(props)}>
        {props.children}
      </Content>}
    </Layout>
  );
};
