import { createLogic } from 'redux-logic';
import { DELETE_SOCIALS_ENTITY_RESPONSE , getUsersSettingsRequestAction } from '../modules/api';


const deleteSocialsEntityResponseLogic = createLogic({
  type: DELETE_SOCIALS_ENTITY_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      dispatch(getUsersSettingsRequestAction())

    } catch (error) {
      console.log(error);
    }

    done();


  },
});

export default deleteSocialsEntityResponseLogic;