import moment from 'moment-timezone';
import { getTimeToDate, getRaffleTimeInfo } from './getRaffleTimeInfo';

export const TENSE_FUTURE = 'future';
export const TENSE_PAST = 'past';


export const time = {

  getMomentFromDateHourMinuteTZ: (day, t, timezone) => {
    return moment.tz(`${day} ${t}`, timezone);
  },
  getTimeToDate,
  getRaffleTimeInfo,
}


export default time;
