import React from 'react';
import styled, { css } from 'styled-components';
import Button from 'common/MaterialUI/Button'
import { darken } from 'polished'
import { PLATFORMS, PLATFORMID_YOUTUBE } from 'helpers/const';
import YoutubeLogo from 'common/YoutubeLogo';



const StyledButton = styled(({ platform: { icon, color, name }, ...other }) => name == PLATFORMS[PLATFORMID_YOUTUBE].name ? (<Button {...other}><YoutubeLogo/></Button>) : (
  <Button {...other}><span className={icon} /><span>{name}</span></Button>
))`
  
&.button-root {

    ${({ platform }) => platform ? css`background-color: ${({ platform }) => platform.buttoncolor ? platform.buttoncolor : platform.color};` : ''}
    color: white;
    font-size: 16px;
    box-shadow: inset 0 3px 0 0 rgba(255, 255, 255, 0.2);

    :hover {
      ${({ platform }) => platform ? css`background-color: ${({ platform }) => platform.buttoncolor ? darken(0.05, platform.buttoncolor) : darken(0.05, platform.color)};` : ''}
    }
    :active {
      ${({ platform }) => platform ? css`background-color: ${({ platform }) => platform.buttoncolor ? platform.buttoncolor : platform.color};` : ''}
    }
    :disabled {
      ${({ platform }) => platform ? css`background-color: ${({ platform }) => platform.buttoncolor ? platform.buttoncolor : platform.color};` : ''}
      opacity: .5;
      color: white;
    }
  
  }

  
`

export default StyledButton