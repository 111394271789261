import React from 'react';
import styled from 'styled-components';
import { colors, media } from 'styles';
import { Translation } from 'react-i18next';
import ImageArea from './ImageArea';
import TabArea from './TabArea';
import StartEnd from './StartEnd';
import { SmallImage } from './SmallImage';
import ByStreamer from './ByStreamer';
import Title from './Title';
import Description from './Description';
import Footer from './Footer';

const Layout = styled.div`
  background: ${colors.white};
  box-shadow: 0 4px 12px 0 rgba(29, 29, 29, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;

  justify-content: flex-start;
  border-radius: 6px;

  ${props =>
    props.isEmbed
      ? ``
      : `max-width: 300px;`}

  ${media.tablet`
max-width: 630px;
width: 630px;
`};
`;


const Byholder = styled.div`
  position: relative;
`

class RaffleMainBox extends React.PureComponent {
  render() {
    const {
      raffle,
      raffle: {
        host_info,
        eventdescription
      },
      ways, user_entries, preview
    } = this.props;

    return (
      <Translation>
        {t => (
          <Layout className="half" {...this.props}>
            <ImageArea preview={preview} raffle={raffle} ways={ways} user_entries={user_entries} />
            <Byholder><SmallImage src={host_info && host_info.pic ? host_info.pic : "/static/assets/images/platzhalter-avatar.jpg"} />
              <ByStreamer raffle={raffle} /></Byholder>
            <Title raffle={raffle} />

            <Description raffle={raffle} />
            <StartEnd
              followingDescription={eventdescription ? true : false}
              raffle={raffle}
            />
            <TabArea raffle={raffle} preview={preview} />
            <Footer raffle={raffle} />
          </Layout>
        )}
      </Translation>
    );
  }
}

export default RaffleMainBox;
