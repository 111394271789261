import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Scrollbars } from 'react-custom-scrollbars';
import styled, { css } from 'styled-components';
import { GridBoxMixin, addBoxClass , media } from 'styles';

import classNames from 'classnames';
import { H4ExtraBoldBlack } from 'styles/Kit/H4ExtraBold';
import { H5ExtraBoldBlack } from 'styles/Kit/H5ExtraBold';

const Layout = styled.div`
  border-radius: 6px;
  background-color: #ffffff;
  flex: 0 1 auto;
  width: 100%;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);

  :hover,
  :focus {
    box-shadow: 0 5px 10px 0 rgba(29, 29, 29, 0.1);
  }

  ${({ minHeight }) => minHeight && css`min-height:${minHeight}px;`}
  ${GridBoxMixin};
`;

const Header = styled.div`
border-top-left-radius: 6px;
border-top-right-radius: 6px;
  box-shadow: inset 0 -1px 0 0 #e1e2ef;
  background-color: #f8f9fb;

  padding: 16px 40px;
  position: relative;

  ${media.tabletMax`
    padding: 15px 20px;
  `};

  display: flex;
  justify-content: space-between;

  &.centered {
    justify-content: center;
  }

  &.tabs,
  &.right {
    ${media.tabletMax`
    padding-bottom: 42px;
  `};
  }
`;

const Title = styled.h2`
  ${H4ExtraBoldBlack}

  ${media.tabletMax`
  ${H5ExtraBoldBlack}
  `};
`;

const Notice = styled.h2`
  font-family: Nunito;
  font-size: 20px;
  text-transform: uppercase;
  color: #9c9c9c;
  white-space: nowrap;

  ${media.tabletMax`
    font-size: 16px;
  `};
`;

const Tabs = styled.div`
  position: absolute;
  left: 20px;

  ${media.tablet`
    left: auto;
    right: 40px;
  `} bottom: 0;
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  position: absolute;
  bottom: 15px;
  left: 20px;
  display: flex;
  align-items: center;

  ${media.tablet`
  left: auto;
  right: auto;
  bottom: auto;
  position: relative;
`};
`;

const Content = styled.div`
  margin: 33px 20px 20px;

  ${media.tablet`
    margin: 33px 40px 40px;
  `} font-family: Nunito;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.36;

  > * + * {
    margin-top: 10px;
  }

  display: flex;
  flex-direction: column;

  &.vcenter {
    justify-content: center;
  }

  &.nomargin {
    margin: 0;
  }
`;

const Footer = styled.div`
border-bottom-left-radius: 6px;
border-bottom-right-radius: 6px;
  box-shadow: inset 0 1px 0 0 #e1e2ef;
  background-color: #f8f9fb;

  padding: 16px 40px;
  position: relative;

  ${media.tabletMax`
    padding: 15px 20px;
  `};

  display: flex;
  justify-content: space-between;

  &.centered {
    justify-content: center;
  }

`;

class ContentContainer extends React.PureComponent {
  state = {
    height: 0,
  };

  handleResize = (width, height) => {
    this.setState({ height: Math.max(this.state.height, height) });
  };

  render() {

    const { vcenter, children, keepMaxHeight, maxHeight, nomargin } = this.props;
    const { height } = this.state;

    const content = <Content
      className={classNames({ vcenter, nomargin })}
      style={{ minHeight: height ? height : 'auto' }}
    >
      {' '}
      {keepMaxHeight && (
        <ReactResizeDetector handleHeight onResize={this.handleResize} />
      )}
      {children}
    </Content>

    return maxHeight ? <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={maxHeight}>{content}</Scrollbars> : content

  }
}

export const ContentBox = props => {
  return (
    <Layout minHeight={props.minHeight}
      onClick={props.onClick}
      onMouseUp={props.onMouseUp}
      id={props.id}
      className={`${addBoxClass(props)} ${
        props.className ? props.className : ''
        }`}
    >
      {(props.title || props.notice) && (
        <Header
          className={classNames({
            centered: props.centerTitle,
            tabs: props.tabs,
            right: props.right,
          })}
        >
          <Title>{props.title}</Title>
          {props.notice && <Notice>{props.notice}</Notice>}
          {props.tabs && <Tabs>{props.tabs}</Tabs>}
          {props.right && <Right>{props.right}</Right>}
        </Header>
      )}
      <ContentContainer
        vcenter={props.vcenter}
        keepMaxHeight={props.keepMaxHeight}
        maxHeight={props.maxHeight}
        nomargin={props.nomargin}
      >
        {props.children}
      </ContentContainer>
      {props.footer && <Footer>{props.footer()}</Footer>}
    </Layout>
  );
};

export default ContentBox;
