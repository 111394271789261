import React from 'react';
import Button from 'common/MaterialUI/Button'
import styled from 'styled-components';
import { Translation } from 'react-i18next';

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;



const View = ({ showOverview, error, isAuthed, onRequestClose }) => <Translation>{t => (
  <React.Fragment>
    {!error ? <p>
      <strong>{t("Modals/ConnectModal/EmailFlow/Verification/View.verification.success.title")}</strong>
      <br /><br />
      {t("Modals/ConnectModal/EmailFlow/Verification/View.verification.success.body")}
    </p> : <p>
        <strong>{t("Modals/ConnectModal/EmailFlow/Verification/View.verification.success.error")}</strong>
        <br /><br />
        {error}.
    </p>}


    <Controls>
      {!isAuthed ? <Button outline onClick={showOverview}><span className="icon-Ico16-ArrowLeft-Medium" /><span>{t("Modals/ConnectModal/EmailFlow/Login/View.back")}</span></Button> : <Button outline small
        onClick={onRequestClose}
      >{t('modals.checkout.ok')}</Button>}
    </Controls>

  </React.Fragment>)}</Translation>

export default View