import { createSelector } from "reselect";

const paymentUrl = state => state.creator.paymentUrl;
const paymentMode = state => state.creator.paymentMode;
const language = state => state.ui.language;
const paymentDefaultCountry = state => state.creator.paymentDefaultCountry;
const formCountry = state => state.form["creator-summary"] && state.form["creator-summary"].values && state.form["creator-summary"].values.country;

const paymentConfig = createSelector([language, paymentUrl, paymentMode, paymentDefaultCountry, formCountry], (language, paymentUrl, paymentMode, paymentDefaultCountry, formCountry) => {

  return {
    loading: !paymentUrl,
    paymentConfig: {
      approvalUrl: paymentUrl,
      mode: paymentMode,
      country: formCountry || paymentDefaultCountry,
      language
    }
  }
})
export default paymentConfig