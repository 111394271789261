import React, { useEffect, useState } from 'react';
import Box from './_Box';
import Button from 'common/MaterialUI/Button';
import Explanation from './_Explanation';
import Description from './_Description';
import utils from 'helpers/utils';
import styled from 'styled-components';
import { WAYTYPEID_FACEBOOK_VIEW_POST } from 'helpers/const/waytypes';
import { withBreakpoint } from 'hoc/withBreakpoint';
import { media } from 'styles';

const FrameLayout = styled.iframe`
  width: 100%;
  height: 100%; 
  border: none;
  overflow: auto;
  margin: 0 auto;
  position: absolute;

  max-width: 100%;

  ${media.tablet`
    max-width: 500px;
  `}
`

const FrameContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 300px;
  overflow: hidden;
  max-width: 100%;
  width: 100%;

  ${media.tabletMax`
    display: none;
  `}
`


const Facebook = ({ breakpoint, way, waytype, handleContinue, toggle, ...other }) => {

  console.log(breakpoint)

  const [url, setUrl] = useState('https://www.facebook.com/plugins/page.php');
  const [params, setParams] = useState('&adapt_container_width=true&tabs=timeline');

  useEffect(() => {

    if (way) {
      switch (way.WAYTYPEID) {
        case WAYTYPEID_FACEBOOK_VIEW_POST:
          setUrl('https://www.facebook.com/plugins/post.php')
          setParams('&show_text=true')
          break;

        default:
          setUrl('https://www.facebook.com/plugins/page.php')
          setParams('&adapt_container_width=true&tabs=timeline')
          break;
      }
    }


  }, [way])



  return <Box {...{ way, waytype, toggle, ...other }}
    controls={(complete, loading, t) => <React.Fragment>
      <Button outline small onClick={toggle(way)}>{t("cancel")}</Button>
      <Button secondary small disabled={complete || loading} onClick={handleContinue(way, null, toggle(way))}>{t("continue")}</Button>
    </React.Fragment>}
  ><Description way={way} />




    <FrameContainer><FrameLayout src={`${url}?href=${utils.socialLink(way)}${params}&width=${breakpoint == 'phone' ? 226 : 500}&height=300&appId`} frameBorder="0" allowTransparency="true" allow="encrypted-media" scrolling="yes"></FrameLayout></FrameContainer>


    <Explanation way={way} waytype={waytype} /></Box>
}


export default withBreakpoint(Facebook)