import React from 'react';
import propTypes from 'prop-types';

import styled from 'styled-components';
import { addFocusInListener, removeFocusInListener } from 'helpers/focus';
import { addMouseUpListener, removeMouseUpListener } from 'helpers/mouse';

const Layout = styled.div`
  position: relative;
  flex: 0 1 auto;
`;

class DropDownController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      typeof nextProps.open != 'undefined' &&
      nextProps.open != this.state.open
    ) {
      this.toggleOpen(nextProps.open);
    }
  }

  componentDidMount() {
    if (
      typeof this.props.open != 'undefined' &&
      this.props.open != this.state.open
    ) {
      this.toggleOpen(this.props.open);
    }
  }

  render() {
    const { children, className } = this.props;
    const { open } = this.state;

    return (
      <Layout
        className={`${className || ''} dropdown${open ? ' open' : ''}`}
        ref={node => (this.wrapper = node)}
        onClick={() => this.toggleOpen()}
      >
        {children}
      </Layout>
    );
  }

  getChildContext = () => {
    return {
      dropDownState: this.state,
      dropDownMethods: {
        toggleOpen: this.toggleOpen,
      },
    };
  };

  handleClickOutside = event => {
    const { open } = this.state;
    const { wrapper } = this;

    if (open && wrapper && !wrapper.contains(event.target)) {
      this.toggleOpen(false);
    }
  };

  toggleOpen = (value, callback) => {
    let changes = {
      open: typeof value != 'undefined' ? value : !this.state.open,
    };

    if (this.props.onBeforeChange) {
      this.props.onBeforeChange(changes);
    }

    this.setState(changes, () => {
      if (this.props.onChange) {
        this.props.onChange(changes);
      }

      if (this.state.open) {
        addMouseUpListener(this.handleClickOutside);
        addFocusInListener(this.handleClickOutside);
      } else {
        removeMouseUpListener(this.handleClickOutside);
        removeFocusInListener(this.handleClickOutside);
      }

      if (callback) {
        callback.apply(null, [this.state]);
      }
    });
  };

  componentWillUnmount() {
    try {
      removeMouseUpListener(this.handleClickOutside);
      removeFocusInListener(this.handleClickOutside);
    } catch (e) {
      //TODO: handle error!
    }
  }

  static childContextTypes = {
    dropDownState: propTypes.any.isRequired,
    dropDownMethods: propTypes.any.isRequired,
  };
}

export default DropDownController;
