import { createLogic } from 'redux-logic';
import { PUT_RAFFLES_FINALIZE_RESPONSE, getCartRequestAction } from '../modules/api';


const putRafflesFinalizeResponseLogic = createLogic({
  type: PUT_RAFFLES_FINALIZE_RESPONSE,

  async process({ getState, action }, dispatch, done) {
    try {
      dispatch(getCartRequestAction());
    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default putRafflesFinalizeResponseLogic;
