import React from 'react';
import styled, { css } from 'styled-components';
import { BodySmallRegularGreen, BodySmallRegularRed } from '../styles/Kit/BodySmallRegular';
import { red } from '../styles/Kit/colors';

const Layout = styled.div`
  

  display: flex;

  > span + span {
    margin-left: 5px;
  }

  
  
  ${({ nomargin }) => !nomargin && css`margin: 15px 0;`}
  

  ${({ warning }) => warning ? BodySmallRegularRed : BodySmallRegularGreen}


  ${({ warning }) => warning && css`[class^='icon-'],[class*=' icon-'] {
    :before {
      color: ${red};
    }
  }`}


  
`;


const Notification = ({ icon, children, ...props }) =>
  <Layout {...props}>{icon && <span className={icon} />}<span>{children}</span></Layout>


export default Notification;