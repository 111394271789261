import { createLogic } from 'redux-logic';
import { POST_BETA_APPLY_RESPONSE } from '../modules/api';
import { showNotificationModal } from 'rdx/modules/modals';

const postBetaApplyResponseLogic = createLogic({
  type: POST_BETA_APPLY_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      dispatch(
        showNotificationModal({
          title: 'modals.general.success_title',
          content: 'modals.general.success_body',
        }),
      );
    } catch (error) {
      console.log(error);
    }

    done();


  },
});

export default postBetaApplyResponseLogic;