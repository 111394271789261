import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { media } from 'styles';

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  50% {
    opacity: 1;
  }

  90% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
`;

const animationRule = css`${animation} ${props => props.duration}ms both`;


const Layout = styled.div.attrs(props => ({
  style: { animationPlayState: props.paused ? 'paused' : 'running' }
}))`
animation: ${animationRule},
  color: white;
  flex: 0 0 auto;

  font-family: Nunito;

  font-weight: 800;

  font-size: 11px;

  ${media.tablet`
font-size: 24px;
`};

  ${media.widescreen`
font-size: 40px;
`};

  &.paused {
    animation-play-state: paused;
  }
`;

const Shared = css`
  color: white;
  text-shadow: 0 2px 0 rgba(29, 29, 29, 0.8);
  position: absolute;
  left: 50%;
  top: 50%;
`;

const This = styled.div`
  ${Shared};
  font-size: 1em;
  transform: translate(-145%, -150%);
`;

const Contest = styled.div`
  ${Shared};
  font-size: 2em;
  transform: translate(-50%, -50%);
`;

const Is = styled.div`
  ${Shared};
  transform: translate(-325%, 70%);
  font-size: 0.7em;
`;

const Over = styled.div`
  ${Shared};
  transform: translate(-30%, 25%);
  font-size: 1.6em;
`;

export class ThisContestIsOver extends React.PureComponent {
  render() {
    const { duration, paused } = this.props;
    return (
      <Layout {...{ paused, duration }}>
        <This>THIS</This>
        <Contest>GIVEAWAY</Contest>
        <Is>IS</Is> <Over>OVER!</Over>
      </Layout>
    );
  }
}
