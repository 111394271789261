import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import Image from './Image';
import Link from 'redux-first-router-link';
import Title from './Title';
import numeral from 'numeral';
import Footer from './Footer';
import Price from './Price';
import Regions from '../Products/Regions';

const Layout = styled.div`
  background: white;
  
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);
  :hover {
    box-shadow: 0 4px 12px 0 rgba(29, 29, 29, 0.3);
  }
  position: relative;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  border-radius: 6px;
  cursor: pointer;

  width: 300px;
  max-width: 300px;
  height: 375px;
  max-height: 375px;
`;

const ProductLink = styled(Link)`
  position: absolute;
  width: 300px;
  height: 275px;
  left: 0;
  top: 0;
`;

class ProductPreview extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.swiping != 0) {
      return false;
    }
    return nextProps.product != this.props.product;
  }

  handleLinkEvent = event => {
    if (this.props.swiping || this.props.mouseUpDisabled) {
      event.preventDefault();
    }
  };

  render() {
    const { product } = this.props;
    const { handleLinkEvent } = this;

    return (
      <Translation>
        {t => (
          <Layout {...this.props}>
            <Image product={product} />
            <Title product={product} handleLinkEvent={handleLinkEvent} />
            <Footer><Regions product={product} /><Price>€{' '}{numeral(
              parseFloat(product.price),
            ).format('0.00')}</Price></Footer>
            <ProductLink
              draggable="false"
              to={`/create/shop/${product.PRODUCTID}`}
              onMouseUp={handleLinkEvent}
              onClick={handleLinkEvent}
            />
          </Layout>
        )}
      </Translation>
    );
  }
}
ProductPreview.displayName = 'ProductPreview';

export default ProductPreview;
