import { createLogic } from 'redux-logic';
import { RESTAPI } from '../modules/api/rest';
import {
  DYNAMIC_LIST_REQUEST,
  raffleDynamicListResponseAction,
} from 'rdx/modules/giveawayLists';


const getDynamicListRequestLogic = createLogic({
  type: DYNAMIC_LIST_REQUEST,

  async process({ getState, action }, dispatch, done) {
    try {
      const { own, id, filterPath, orderbyPath, participated, ...conf } = action.payload[0];

      let resp;

      if (own) {
        resp = await RESTAPI.getRafflesOwn(conf)
      } else {
        if (id) {
          resp = await RESTAPI.getRaffleById(action.payload[0])
        } else {
          if (filterPath || orderbyPath) {
            resp = await RESTAPI.getRafflelistFilterOrderby(action.payload[0])
          } else if (participated) {
            resp = await RESTAPI.getRafflelistParticipated(conf)
          } else {
            resp = await RESTAPI.getRafflelist(conf)
          }
        }
      }


      dispatch(raffleDynamicListResponseAction([resp, action.payload[1], ( action.payload[2] || {} )]));
    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default getDynamicListRequestLogic;
