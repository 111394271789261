import StreamersDetailContainer from 'components/Streamers/Detail';
import { ROUTE_TYPE_STREAMER } from '../helpers/const';

export const STREAMERS_ROUTES = {
  [ROUTE_TYPE_STREAMER]: {
    path: '/streamers/:USERID',
    title: 'Streamer profile',
    page: StreamersDetailContainer,
  },
};
