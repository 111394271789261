import { handleActions, combineActions } from 'redux-actions';

import { GET_PRIVATE_PRODUCT_RESPONSE, GET_PRIVATE_PRODUCT_ERROR, GET_PRIVATE_PRODUCT_REQUEST, GET_PRIVATE_PRODUCT_BY_EVENTID_RESPONSE } from 'rdx/modules/api';



const initialState = {
  data: {},
};

export const privateProductsReducer = handleActions(
  {

    [GET_PRIVATE_PRODUCT_REQUEST]: (state, action) => {
      return { ...state, loading: true }
    },

    [combineActions(GET_PRIVATE_PRODUCT_RESPONSE, GET_PRIVATE_PRODUCT_BY_EVENTID_RESPONSE)]: (state, action) => {
      const productsById = action.payload.private_products.reduce((p, c, i, a) => {
        p[c.PRIVATEPRODUCTID] = c;
        return p;
      }, {});

      return {
        ...state,
        data: productsById,
        loading: false,
      };
    },

    [GET_PRIVATE_PRODUCT_ERROR]: (state, action) => {
      return { ...state, loading: false }
    }
  },
  initialState,
);

export default privateProductsReducer;
