import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../styles';

const beforeColor = css`
  background: ${props => props.color};
`;

const Input = styled.div`
  input {
    padding: 0 5px;
    border-radius: 3px;
    background: #ffffff;
    font-family: Nunito;
    font-size: 14px;
    height: 34px;
    width: 100%;
    box-shadow: inset 0 2px 0 0 rgba(156, 156, 156, 0.3);
    border: solid 1px #1d1d1d;

    padding-left: 80px;
    ${media.widescreen`
    padding-left: 120px;
    `};
  }

  :before {
    font-family: 'icomoon' !important;
    content: ' ';
    position: absolute;

    width: 66px;
    height: 25px;

    left: 5px;
    top: 5px;

    ${beforeColor};
  }
`;

const Layout = styled.div`
  position: relative;
  .chrome-picker {
    width: 100% !important;
  }
`;

class ColorPicker extends React.PureComponent {
  handleChange = value => {
    const { onChange } = this.props;
    onChange(value ? value.replace(/[^#a-zA-Z0-9]/gi, '') : '');
  };

  render() {
    const { value, disabled } = this.props;

    return (
      <Layout>
        <Input color={value}>
          <input
            disabled={disabled}
            type="text"
            value={value}
            onChange={event => this.handleChange(event.target.value)}
          />
        </Input>
      </Layout>
    );
  }
}

export default ColorPicker;
