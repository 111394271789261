import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { compose } from 'redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from 'common/MaterialUI/Tab';

import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'redux-first-router-link';
import { withBreakpoint } from 'hoc/withBreakpoint';

const Wrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);
  }

  padding: 0 20px;


${media.widescreen`
padding: 0;

`}
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1290px;
  width: 100%;
  justify-content: center;
`;



const SubmenuQuicktour = ({ pathname, breakpoint }) => {

  const centered = ["widescreen", "desktop"].indexOf(breakpoint) != -1 ? true : undefined

  return <Translation>{t => (<Wrapper><InnerWrapper><Tabs variant={centered ? undefined : "scrollable"} centered={centered} value={pathname && pathname}
    indicatorColor="secondary"
    textColor="primary"

  >
    <Tab component={NavLink} label={t("Navigation/SubmenuQuicktour.welcome")} value={"/quicktour"} to={"/quicktour"} />
    <Tab component={NavLink} label={t("Navigation/SubmenuQuicktour.viewers")} value={"/quicktour/viewers"} to={"/quicktour/viewers"} />
    <Tab component={NavLink} label={t("Navigation/SubmenuQuicktour.streamers")} value={"/quicktour/streamers"} to={"/quicktour/streamers"} />
    <Tab component={NavLink} label={t("Navigation/SubmenuQuicktour.businesses")} value={"/quicktour/businesses"} to={"/quicktour/businesses"} />
  </Tabs></InnerWrapper></Wrapper>)}</Translation>
}


const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.location.pathname
  }
}

const enhance = compose(withBreakpoint, connect(mapStateToProps))



export default enhance(SubmenuQuicktour)