import { Translation } from 'react-i18next';
import Link from 'redux-first-router-link';
import styled from 'styled-components';
import { BodyRegularBlack } from 'styles/Kit/BodyRegular';
import { BodySmallRegularBlack } from 'styles/Kit/BodySmallRegular';

import React from 'react';
import { media } from '../../../../styles';

const By = styled.div`
  color: #303030 !important;
  display: inline;
`;

const ByStreamerHolder = styled.div`
  

  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  

	span {
		color: #303030 !important;
  }

  position: absolute;
  ${BodySmallRegularBlack}
  top: 10px;
  left: 85px;
  
  ${media.tablet`
    top: 20px;
    left: 148px;
    ${BodyRegularBlack}
  `}
  
`;

const ByStreamer = ({ raffle, handleLinkEvent }) => {
  if(!raffle || !raffle.host_info) {
    return null;
  }

  const { host_info: { USERID, username, website } } = raffle;
  return (
    <Translation>
      {(t) => (
        <ByStreamerHolder>
          <span>{t('by')} </span>
          <By>
            {website ? (
              <a href={website} draggable="false" target="_blank" rel="noopener noreferrer">
                {username}
              </a>
            ) : (
                <Link
                  onMouseUp={handleLinkEvent}
                  onClick={handleLinkEvent}
                  draggable="false"
                  to={`/streamers/${USERID}`}
                >
                  {username}
                </Link>
              )}
          </By>
        </ByStreamerHolder>
      )}
    </Translation>
  );
};

export default ByStreamer;
