import { css } from 'styled-components';
import { blue, red, green, grey, white, black } from './colors';

const H2ExtraBold = css`
  font-family: Nunito;
  font-size: 31px;
  font-weight: 800;
`;
export const H2ExtraBoldBlue = css`
  ${H2ExtraBold}
  color: ${blue};
`;

export const H2ExtraBoldRed = css`
  ${H2ExtraBold}
  color: ${red};
`;

export const H2ExtraBoldGreen = css`
  ${H2ExtraBold}
  color: ${green};
`;

export const H2ExtraBoldGrey = css`
  ${H2ExtraBold}
  color: ${grey};
`;

export const H2ExtraBoldWhite = css`
  ${H2ExtraBold}
  color: ${white};
`;

export const H2ExtraBoldBlack = css`
  ${H2ExtraBold}
  color: ${black};
`;
