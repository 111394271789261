import React from 'react';
//import FormControl from '@material-ui/core/FormControl';
import MenuItem from 'common/MaterialUI/MenuItem';
import Scrollbar from 'common/MaterialUI/Scrollbar';
import Popover from 'common/MaterialUI/Popover';
import PopoverHeader from 'common/MaterialUI/PopoverHeader';
import { Translation } from 'react-i18next';
import { MenuList } from '@material-ui/core';
import Button from 'common/MaterialUI/Button';
import styled from 'styled-components';
import { blue } from 'styles/Kit/colors';
import { media } from 'styles';
import classNames from 'classnames';


const ButtonHolder = styled.div`

  ${media.tabletMax`
    margin-top: 20px;  
    margin-bottom: 20px;
  `}

  margin-right: 20px;

  [class^='icon-'] {
    color: ${blue};
    margin-right: 5px;    
  }

  > button.disabled {
    opacity: .5;
    pointer-events: none;
  }
`


const withMenuControl = (Wrapped) => {
  return class Controller extends React.PureComponent {
    state = {
      anchorEl: null
    };

    handleClick = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    render() {
      const { handleClick, handleClose } = this;
      const { anchorEl } = this.state;
      return <Wrapped {...this.props} {...{ handleClick, handleClose, anchorEl }} />;
    }
  };
};
const languages = { English: 'en', German: 'de' };

const View = ({ disabled, language, anchorEl, handleClose, handleClick, handleChange }) => {
  return (
    <Translation>
      {(t) => (
        <div>

          <ButtonHolder>
            <Button className={classNames({ disabled })} outline2 onClick={(event) => !disabled && handleClick(event)}><span className="icon-Ico16-Globe" /><span>{language.toUpperCase()}</span></Button>
          </ButtonHolder>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <PopoverHeader>{t('site_language')}</PopoverHeader>
            <MenuList component={Scrollbar}>
              {Object.keys(languages).map((langName, i) => (
                <MenuItem
                  key={languages[langName]}
                  onClick={() => {
                    handleChange(languages[langName]);
                    handleClose();
                  }}
                  selected={language == languages[langName]}
                  value={languages[langName]}
                >
                  {langName}
                </MenuItem>
              ))}



            </MenuList>
          </Popover>
        </div>
      )}
    </Translation>
  );
};

export default withMenuControl(View);
