import styled, { css } from "styled-components";
import { media } from "../../styles";

export const BoxMarginMixin = css`
padding: 33px 20px 20px;

${media.tablet`
  padding: 33px 40px 40px;
`} 
`


export const BoxMargin = styled.div`
  ${BoxMarginMixin}
`

export const LoginMarginMixin = css`
padding: 0px 0px 20px;

${media.tablet`
  padding: 0px 0px 40px;
`} 
`


export const LoginMargin = styled.div`
  ${LoginMarginMixin}
`

export const CaptchaMarginMixin = css`
padding: 33px 20px 0px;

${media.tablet`
  padding: 33px 40px 0px;
`} 
`


export const CaptchaMargin = styled.div`
  ${CaptchaMarginMixin}
`
