import React from 'react';
import styled from 'styled-components';
import { BodyRegularBlack, BodyRegularBlue, BodyRegularGreen, BodyRegularRed } from '../../styles/Kit/BodyRegular';
import MenuItem from '@material-ui/core/MenuItem';

const StyledMenuItem = styled(({ warning, ...other }) => (
  <MenuItem {...other} classes={{ "root": "root", "selected": "selected" }} />
))`
    padding: 10px 20px !important;

  ${BodyRegularBlack}

  & + & {
    border-top: solid 1px #e1e2ef;
  }
  padding-right: 40px !important;
  
  &.selected {
    background: white !important;
    ${({ warning }) => warning ? BodyRegularRed : BodyRegularGreen}

    position: relative;
   

    :after {
      font-family: icomoon;
      content:  "${({ warning }) => warning ? '\\e913' : '\\e901'}";
      position: absolute;
      top: 50%;
      right: 20px;
      font-size: 16px;
      transform: translate(5px, -50%);
    }
  }

  :hover {
    background-color: #f8f9fb !important;
    ${BodyRegularBlue}
  }

`;



export default StyledMenuItem;