import { createLogic } from 'redux-logic';
import { RESTAPI } from 'rdx/modules/api/rest';
import { GET_IMAGES_LIST_REQUEST, getImagesListResponseAction } from 'rdx/modules/assets';

const getImagesListRequestLogic = createLogic({
  type: GET_IMAGES_LIST_REQUEST,

  async process({ getState, action }, dispatch, done) {
    try {
      const resp = await RESTAPI.getImages(action.payload[0]);
      dispatch(getImagesListResponseAction([resp, action.payload[1]]));
    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default getImagesListRequestLogic;
