import React from 'react';
import styled, { css } from 'styled-components';

const GheedIconBaseMixin = css`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 17px 17px;
  width: 17px;
  height: 17px;

`;

const GheedLogo = styled.div`
  background: url(/static/assets/images/gheed-logo-min.svg);
  ${GheedIconBaseMixin};
 
`;

const GheedIconContainer = styled.div`
  width: 25px;
  height: 25px;
  margin-left: 5px;

  display: flex;
  align-items: center;
`;

export const GheedIcon = () => {
  return <GheedIconContainer><GheedLogo /></GheedIconContainer>
};

export default GheedIcon;