import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { BodySmallRegularBlack } from 'styles/Kit/BodySmallRegular';
import { green } from 'styles/Kit/colors';

const Icon = styled.span`
margin-right: 10px;
    color: ${green};
`

const Layout = styled.div`
    ${BodySmallRegularBlack}
    text-align: center;
    padding: 10px;

`

const Hint = ({ way, waytype, noLink, displayName }) => {

  return <Layout ><Icon className="icon-Ico16-Tooltip-Attention" /><Trans
    i18nKey={`waytypes.${waytype.WAYTYPEID}.hint`}
  >This is a Warning.</ Trans></Layout >
}

export default Hint;