import { RAFFLE_STATE_FINISHED, RAFFLE_STATE_ACTIVE, RAFFLE_STATE_ANNOUNCED, TICK_TIMEOUT_MAP } from 'helpers/const';

export const TENSE_FUTURE = 'future';
export const TENSE_PAST = 'past';


const parseIsoDatetime = (dtstr) => {
  var dt = dtstr.split(/[: T-]/).map(parseFloat);
  const dat = new Date(dt[0], dt[1] - 1, dt[2], dt[3] || 0, dt[4] || 0, dt[5] || 0, 0);
  return new Date(dat.getTime() - dat.getTimezoneOffset() * 60000);
}


export const getTimeToDate = (dateStr, offset = 0) => {
  let local = new Date();
  let now = new Date(local.getTime() - offset);
  let date = parseIsoDatetime(dateStr);

  let ms = now <= date ? date.getTime() - now.getTime() : now.getTime() - date.getTime();
  var s1 = Math.floor(ms / 1000);
  var s2 = s1 % 60;
  var m1 = Math.floor(s1 / 60);
  var m2 = m1 % 60;
  var h1 = Math.floor(m1 / 60);
  var h2 = h1 % 24;
  var d = Math.floor(h1 / 24);

  const tense = now.getTime() <= date.getTime() ? TENSE_FUTURE : TENSE_PAST;

  const futureHour = h2 + 1 > 23 ? 0 : h2 + 1;
  const pastHour = h2 - 1 < 0 ? 23 : h2 - 1;

  const futureMinute = m2 + 1 > 59 ? 0 : m2 + 1;
  const pastMinute = m2 - 1 < 0 ? 59 : m2 - 1;

  const futureSecond = s2 + 1 > 59 ? 0 : s2 + 1;
  const pastSecond = s2 - 1 < 0 ? 59 : s2 - 1;

  const frame = d > 0 ? 'days' : h2 > 0 ? 'hours' : m2 > 0 ? 'minutes' : 'seconds';

  return {
    tense: tense,
    frame: frame,
    days: d,
    hours: h2,
    minutes: m2,
    seconds: s2,

    lastHour: tense == TENSE_FUTURE ? futureHour : pastHour,
    nextHour: tense == TENSE_FUTURE ? pastHour : futureHour,

    lastMinute: tense == TENSE_FUTURE ? futureMinute : pastMinute,
    nextMinute: tense == TENSE_FUTURE ? pastMinute : futureMinute,

    lastSecond: tense == TENSE_FUTURE ? futureSecond : pastSecond,
    nextSecond: tense == TENSE_FUTURE ? pastSecond : futureSecond,

    updated: now.getTime()
  };
}

export const getRaffleTimeInfo = (raffle, offset, servertime) => {
  const { active_from, active_to } = raffle;

  if (active_from && active_to) {

    const timeToStart = getTimeToDate(active_from, offset, servertime);
    const timeToEnd = getTimeToDate(active_to, offset, servertime);

    let data = null;
    let nextUpdate = false;

    // Trace wrong

    if (timeToStart.tense == TENSE_FUTURE) {
      nextUpdate = timeToStart.updated + TICK_TIMEOUT_MAP[timeToStart.frame];
      data = {
        time: timeToStart,
        mode: RAFFLE_STATE_ANNOUNCED
      };
    } else {
      if (timeToEnd.tense == TENSE_PAST) {
        data = {
          time: timeToEnd,
          mode: RAFFLE_STATE_FINISHED
        };
      } else {
        nextUpdate = timeToEnd.updated + TICK_TIMEOUT_MAP[timeToEnd.frame];
        data = {
          time: timeToEnd,
          mode: RAFFLE_STATE_ACTIVE
        };
      }
    }

    return {
      ...data,
      nextUpdate: nextUpdate,
      timeToStart,
      timeToEnd,
      active_from,
      active_to
    };
  }
}

