import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import { H6ExtraBoldGrey } from 'styles/Kit/H6ExtraBold';

const Layout = styled.div`
  ${H6ExtraBoldGrey}
`;

export const Finished = ({ time }) => (
  <Translation>{t => <Layout>{t('ga_finished')}</Layout>}</Translation>
);
