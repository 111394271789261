import { createLogic } from 'redux-logic';
import { ROUTE_TYPE_CREATE } from '../../helpers/const';
import { getRafflesRequestAction, PUT_PRIVATE_PRODUCT_BY_EVENTID_RESPONSE } from '../modules/api';

const putPrivateProductByEventIdResponseLogic = createLogic({
  type: [PUT_PRIVATE_PRODUCT_BY_EVENTID_RESPONSE],

  process({ getState, action }, dispatch, done) {

    try {
      const state = getState();
      const routeType = state.location.type;


      if (routeType == ROUTE_TYPE_CREATE) {
        dispatch(getRafflesRequestAction());
      }

    } catch (error) {
      console.log(error)
    }

    done();
  }
});

export default putPrivateProductByEventIdResponseLogic;
