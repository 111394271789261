import React from 'react';
import styled from 'styled-components';

const View = styled.span`

  top: calc(50% - 8px);
  right: 10px;
  position: absolute;
  pointer-events: none;

  color: #36a6fc;
`

let ArrowDropDown = (props) => (
  <View {...props} className="icon-Ico16-ArrowDown-Medium" />
);


export default ArrowDropDown;

