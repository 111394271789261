import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';

const templates = {
  de: require('i18n/translations/de/privpub.html'),
  en: require('i18n/translations/en/privpub.html'),
};

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;

const Content = styled.div`

  max-height: 50vh;
  overflow: auto;

  h4 {
    font-weight: 800;
    color: #9c9c9c;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  .green {
    color: #5bcb8f;
    font-weight: bold;
  }

  ul {
    list-style-type: disc;

    li {
      margin: 10px 15px;
    }
  }
`;

class PrivatePublicModal extends React.PureComponent {
  handleCallback = () => {
    const { data, onRequestClose } = this.props;

    if (data && data.callback) {
      data
        .callback()
        .then(() => onRequestClose())
        .catch(() => onRequestClose());
    } else {
      onRequestClose();
    }
  };

  render() {

    const { language, ...other } = this.props;

    return (
      <Modal {...other}>
        <Translation>
          {t => (
            <ContentBox bigmodal title={t("Modals/PrivatePublicModal.title")}>
              <Content dangerouslySetInnerHTML={{ __html: templates[language] }}>

              </Content>

              <Controls>
                <Button outline small
                  onClick={this.handleCallback}
                >{t('modals.coins.ok')}</Button>
              </Controls>
            </ContentBox>
          )}
        </Translation>
      </Modal>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    language: state.ui.language
  }
}

export default connect(mapStateToProps)(PrivatePublicModal);
