import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import { FillButtonPrimaryDefault, FillButtonPrimaryHover, FillButtonPrimaryActive } from '../../styles/Kit/Fills';

const Icon = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 3px;
    box-shadow: inset 0 2px 0 0 #eaedf3;
    background-color: white;
    border: solid 1px #202022;
`

const CheckedIcon = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 3px;
    box-shadow: inset 0 2px 0 0 #eaedf3;
    

    :before {
      font-family: icomoon;
      content: '\\e902';
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 16px;
      transform: translate(-50%, -50%);
    }

    ${FillButtonPrimaryDefault}
    :hover {
      ${FillButtonPrimaryHover};
    }
    :active {
      ${FillButtonPrimaryActive};
    }

    border: solid 1px #202022;
`




const StyledCheckbox = (props) => <Checkbox {...props} icon={<Icon />} checkedIcon={<CheckedIcon />} />

export default StyledCheckbox;
