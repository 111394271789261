import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';

export const Layout = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background-color: #ffffff;

  border: solid 2px #4ccbfd;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  input {
    background: transparent;
    border: none;
    width: 60px;
    margin-left: 13px;
    border: none;
    flex: 0 1 auto;

    font-family: Nunito;
    font-size: 16px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;

    text-align: right;
    color: #fed532;
    padding-right: 0;
    margin-right: 5px;
    overflow: visible;

    ${media.tablet`
      font-size: 20px;
      margin-left: -15px;
    `};
  }

  ${media.tablet`
    width: 180px;
    height: 60px;
    justify-content: center;
  `};
`;

export const Label = styled.h2`
  color: #fed532;
  font-size: 16px;
  flex: 0 1 auto;

  ${media.tablet`
    font-size: 20px;
  `};
`;

const Hidden = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: pre;
`;

export class SpendInput extends React.Component {
  componentDidUpdate() {
    this.sizeInput();
  }

  componentDidMount() {
    this.sizeInput();
  }

  sizeInput = () => {
    const { input, hidden } = this;

    if (input && hidden) {
      input.style.width = hidden.offsetWidth + 14 + 'px';
    }
  };

  handleFocusInput = () => {
    const { input } = this;

    if (input) {
      input.focus();
    }
  };

  render() {
    const { value, onChange } = this.props;
    return (
      <Layout onClick={this.handleFocusInput}>
        <Hidden ref={node => (this.hidden = node)}>{value}</Hidden>
        <input
          ref={node => (this.input = node)}
          type="text"
          {...{ value, onChange }}
        />
        <Label> Coins</Label>
      </Layout>
    );
  }
}
