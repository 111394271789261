import React from 'react';
import { connect } from 'react-redux';
import {
  modalClearAction, MODAL_NOTIFICATION,
  MODAL_CONNECT,
  MODAL_TOO_EARLY,
  MODAL_OWN_RAFFLE,
  MODAL_CHECKOUT,
  MODAL_CROP_IMAGE,
  MODAL_TOO_LATE,
  MODAL_PARTNER_APPLY,
  MODAL_CONTACT_US,
  MODAL_REPORT_GIVEAWAY,
  MODAL_PREVIEW,
  MODAL_SUPPORT,
  MODAL_LOYALTY,
  MODAL_ROLE,
  MODAL_ROLE_CHANGE_STREAMER,
  MODAL_PUBLICPRIVATE,
  MODAL_PUBLICRULES,
  MODAL_EMAILLOGIN_SETUP,
  MODAL_ROLE_CHANGE_VIEWER,
  MODAL_POSTLAUNCH,
  MODAL_ACCOUNTDELETION
} from 'rdx/modules/modals';

import TooEarly from 'components/Modals/TooEarly';
import OwnRaffle from 'components/Modals/OwnRaffle';

import TooLate from 'components/Modals/TooLate';
import CheckoutModal from 'components/Modals/CheckoutModal';
import PartnerApplyModal from 'components/Modals/PartnerApplyModal';
import NotificationModal from 'components/Modals/NotificationModal';
import ContactUsModal from 'components/Modals/ContactUsModal';
import CropImageModal from 'components/Modals/CropImageModal';
import ConnectModal from 'components/Modals/ConnectModal';
import PreviewModal from 'components/Modals/PreviewModal';
import SupportModal from 'components/Modals/SupportModal';

import LoyaltyCoinInfoModal from './LoyaltyCoinInfoModal';
import RoleModal from './RoleModal';
import ChangeRoleToStreamer from './ChangeRoleToStreamer';
import ChangeRoleToViewer from './ChangeRoleToViewer';

import PrivatePublicModal from './PrivatePublicModal';
import PublicRulesModal from './PublicRulesModal';
import ReportGiveawayModal from './ReportGiveawayModal';
import EmailLoginSetup from './EmailLoginSetup'
import PostLaunchModal from './PostLaunch/index';

import AccountDeletionModal from './AccountDeletionModal';


export class Modals extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  onRequestClose = event => {
    const { dispatch } = this.props;
    dispatch(modalClearAction());
  };

  render() {
    const { modal, data } = this.props;

    return (
      <div>
        <NotificationModal
          open={modal == MODAL_NOTIFICATION}
          onRequestClose={this.onRequestClose}
          data={modal == MODAL_NOTIFICATION ? data : null}
        />

        {modal == MODAL_CONTACT_US && (
          <ContactUsModal
            open={modal == MODAL_CONTACT_US}
            onRequestClose={this.onRequestClose}
          />
        )}

        {modal == MODAL_PARTNER_APPLY && (
          <PartnerApplyModal
            open={modal == MODAL_PARTNER_APPLY}
            onRequestClose={this.onRequestClose}
          />
        )}

        {modal == MODAL_SUPPORT && (
          <SupportModal
            open={modal == MODAL_SUPPORT}
            onRequestClose={this.onRequestClose}
          />
        )}

        <CheckoutModal
          open={modal == MODAL_CHECKOUT}
          onRequestClose={this.onRequestClose}
        />
        <CropImageModal
          open={modal == MODAL_CROP_IMAGE}
          {...data}
          onRequestClose={this.onRequestClose}
        />
        <ConnectModal
          open={modal == MODAL_CONNECT}
          onRequestClose={this.onRequestClose}
          data={modal == MODAL_CONNECT ? data : null}
        />
        <EmailLoginSetup
          open={modal == MODAL_EMAILLOGIN_SETUP}
          onRequestClose={this.onRequestClose}
          data={modal == MODAL_EMAILLOGIN_SETUP ? data : null}
        />
        <PreviewModal
          open={modal == MODAL_PREVIEW}
          onRequestClose={this.onRequestClose}
          data={modal == MODAL_PREVIEW ? data : null}
        />

        <TooEarly
          onRequestClose={this.onRequestClose}
          open={modal == MODAL_TOO_EARLY}
          {...data}
        />
        <TooLate
          onRequestClose={this.onRequestClose}
          open={modal == MODAL_TOO_LATE}
          {...data}
        />
        <OwnRaffle
          onRequestClose={this.onRequestClose}
          open={modal == MODAL_OWN_RAFFLE}
          {...data}
        />
        {modal == MODAL_LOYALTY && (
          <LoyaltyCoinInfoModal
            open={modal == MODAL_LOYALTY}
            onRequestClose={this.onRequestClose}
          />
        )}
        {modal == MODAL_ROLE && (
          <RoleModal
            open={modal == MODAL_ROLE}
            onRequestClose={this.onRequestClose}
          />
        )}

        {modal == MODAL_ROLE_CHANGE_STREAMER && (
          <ChangeRoleToStreamer
            open={modal == MODAL_ROLE_CHANGE_STREAMER}
            onRequestClose={this.onRequestClose}
          />
        )}

        {modal == MODAL_ROLE_CHANGE_VIEWER && (
          <ChangeRoleToViewer
            open={modal == MODAL_ROLE_CHANGE_VIEWER}
            onRequestClose={this.onRequestClose}
          />
        )}


        {modal == MODAL_PUBLICPRIVATE && (
          <PrivatePublicModal
            open={modal == MODAL_PUBLICPRIVATE}
            onRequestClose={this.onRequestClose}
          />
        )}

        {modal == MODAL_PUBLICRULES && (
          <PublicRulesModal
            open={modal == MODAL_PUBLICRULES}
            {...data}
            onRequestClose={this.onRequestClose}
          />
        )}

        {modal == MODAL_REPORT_GIVEAWAY && (
          <ReportGiveawayModal
            open={modal == MODAL_REPORT_GIVEAWAY}
            {...data}
            onRequestClose={this.onRequestClose}
          />
        )}

        {modal == MODAL_POSTLAUNCH && (
          <PostLaunchModal
            open={modal == MODAL_POSTLAUNCH}
            {...data}
            onRequestClose={this.onRequestClose}
          />
        )}

        {modal == MODAL_ACCOUNTDELETION && (
          <AccountDeletionModal
            open={modal == MODAL_ACCOUNTDELETION}
            {...data}
            onRequestClose={this.onRequestClose}
          />
        )}

      </div>
    );
  }
}

export default connect(state => ({
  modal: state.modals.modal,
  data: state.modals.data,
  loading: state.modals.loading,
  error: state.modals.error,
}))(Modals);
