import { scrollToDOMNode } from './scrollTo';

export const onSubmitFail = (errors, dispatch, submitError, form) => {
  form.validate(form.values, form);

  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input.error, textarea.error, select.error, div.validationError',
    );
    if (firstErrorNode) {
      scrollToDOMNode(firstErrorNode, () => {
        firstErrorNode.focus();
      });
    }
  }, 50);
};