import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import View from './View';

export const ui = (state) => state.ui;

const selectedState = createSelector([ ui ], (ui) => {
	return {
		language: ui.language
	};
});

const mapStateToProps = (state) => selectedState(state);

export default connect(mapStateToProps)(View);
