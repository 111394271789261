import React from 'react';
import styled from 'styled-components';
import { BodySmallRegularBlack } from 'styles/Kit/BodySmallRegular';
import { EVENTDESCRIPTIONTEXTTYPE_HTML } from 'helpers/const';


const Layout = styled.div`
    ${BodySmallRegularBlack}
    text-align: center;
    padding: 10px;
`

const Description = ({ way }) => {

  if (!way.description) {
    return null
  }

  return way.TEXTTYPEID == EVENTDESCRIPTIONTEXTTYPE_HTML ? <Layout dangerouslySetInnerHTML={{ __html: way.description }} /> : <Layout>{way.description}</Layout>
}

export default Description;