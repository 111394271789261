import moment from 'moment-timezone';
import userTimezone from '../selectors/userTimezone';
import userCountry from '../selectors/userCountry';
import { putUsersSettingsRequestAction } from '../modules/api';
import { DEFAULT_LOCATION, DEFAULT_TIMEZONE } from 'helpers/const';

const addRegionInfoToUser = (dispatch, getState) => {

  if (typeof window != 'undefined') {
    // check on missing region infos
    const state = getState();
    const userTZ = userTimezone(state);
    const userCntr = userCountry(state);

    const timezone = userTZ || moment.tz.guess() || DEFAULT_TIMEZONE;
    const country = userCntr || state.ui.country || DEFAULT_LOCATION;

    if (!userTZ || !userCntr) {
      dispatch(putUsersSettingsRequestAction({
        payload: {
          preferences: { timezone, location: country },
        }
      }));
    }
  }
}

export default addRegionInfoToUser;