import React, { useEffect } from 'react';
import Button from 'common/MaterialUI/Button'
import Grid from '@material-ui/core/Grid';
import InputField from 'common/InputField';
import styled from 'styled-components';
import { Translation, Trans } from 'react-i18next';
import { change } from 'redux-form';

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }

`;


const Blue = styled.span`
color: #36a6fc;
`

const GeneralError = styled.div`
  margin-top:30px;
  background: rgb(252, 82, 30);
  color: white;
  font-size: 14px;
  padding: 20px 40px;
  border-radius: 6px;
  align-self: center;
  flex: 1 1 auto;
  text-align: center;
  width: 100%;
`;


const View = ({ handleSubmit, submitting, submitSucceeded, showOverview, email, resendConfirmation, resending, visibleCaptcha, dispatch, captchaError }) => {

  useEffect(() => {
    if(visibleCaptcha) {
      dispatch(change('email-register', 'visibleCaptcha', visibleCaptcha));
    }
  }, [visibleCaptcha])

  return <Translation>{t => (<form onSubmit={handleSubmit}>


  {submitSucceeded ? <Trans i18nKey="Modals/ConnectModal/EmailFlow/Register/View.success" values={{ email }}><p><strong>Bitte bestätige deine E-Mail Adresse.</strong><br />Wir haben dir eine E-Mail an <Blue>EMAIL</Blue> geschickt. Bitte rufe den darin enthaltenen Aktivierungslink auf, um deine E-Mail zu bestätigen und die Registrierung abzuschließen.</p></Trans> : <Grid container spacing={3}>
    <Grid item xs={12}><InputField name="username" title={t("Modals/ConnectModal/EmailFlow/Register/View.username")} /></Grid>
    <Grid item xs={12}><InputField name="email" title={t("Modals/ConnectModal/EmailFlow/Login/View.email")} /></Grid>
    <Grid item xs={12}><InputField name="password" type="password" title={t("Modals/ConnectModal/EmailFlow/Login/View.password")} /></Grid>
    <Grid item xs={12}><InputField name="passwordconfirm" type="password" title={t("Modals/ConnectModal/EmailFlow/Register/View.passwordconfirm")} /></Grid>
  </Grid>}



  <Controls>
    <Button outline onClick={showOverview}><span className="icon-Ico16-ArrowLeft-Medium" /><span>{t("Modals/ConnectModal/EmailFlow/Login/View.back")}</span></Button>
    {!submitSucceeded ? <Button disabled={submitting} primary type="submit">{t("Modals/ConnectModal/EmailFlow/Register/View.submit")}</Button> : <Button disabled={resending} onClick={resendConfirmation} primary type="button">{t("Modals/ConnectModal/EmailFlow/Register/View.resend")}</Button>}
  </Controls>
  {captchaError && (<GeneralError className="error">
                {t("Modals/ConnectModal/EmailFlow/Register/View.captchaError")}
              </GeneralError>)}
  </form>)}</Translation>

}
export default View