import React from 'react';
import Box from './_Box';
import Button from 'common/MaterialUI/Button';
import Explanation from './_Explanation';
import Description from './_Description';
import { utils } from 'helpers/utils';
import styled from 'styled-components';
import { media } from 'styles';


const FrameLayout = styled.iframe`
  width: 100%;
  height: 100%; 
  border: none;
  overflow: auto;
  margin: 0 auto;
  position: absolute;

  
  max-width: 100%;

  ${media.tablet`
  max-width: 500px;
  `}
`

const FrameContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 463px;
  overflow: hidden;
  max-width: 100%;
  width: 100%;

  ${media.tabletMax`
    display: none;
  `}
`



const Instagram = ({ way, waytype, toggle, handleOpen, handleContinue, handleClose, ...other }) => {




  return <Box {...{ way, waytype, toggle, ...other }}
    controls={(complete, loading, t) => <React.Fragment>
      <Button outline small onClick={toggle(way)}>{t("cancel")}</Button>
      <Button secondary small disabled={complete || loading} onClick={handleContinue(way, null, toggle(way))}>{t("continue")}</Button>
    </React.Fragment>}
  ><Description way={way} />

    <FrameContainer><FrameLayout src={`${utils.socialLink(way)}/embed`} frameBorder="0" allowTransparency="true" allow="encrypted-media" scrolling="yes"></FrameLayout></FrameContainer>

    <Explanation way={way} waytype={waytype} /></Box >
}


export default Instagram