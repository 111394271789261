import { css } from 'styled-components';
import { blue, red, green, grey, white, black } from './colors';

const BodyRegular = css`
  font-family: Nunito;
  font-size: 16px;
  font-weight: normal;
`;
export const BodyRegularBlue = css`
  ${BodyRegular}
  color: ${blue};
`;

export const BodyRegularRed = css`
  ${BodyRegular}
  color: ${red};
`;

export const BodyRegularGreen = css`
  ${BodyRegular}
  color: ${green};
`;

export const BodyRegularGrey = css`
  ${BodyRegular}
  color: ${grey};
`;

export const BodyRegularWhite = css`
  ${BodyRegular}
  color: ${white};
`;

export const BodyRegularBlack = css`
  ${BodyRegular}
  color: ${black};
`;
