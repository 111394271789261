import React from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles';
import EmptyBox from '../../../../../common/EmptyBox';
import PrizeItem from './PrizeItem';

const Layout = styled.div`
  border-top: solid 2px #f0f0f0;
  background-color: #f8f9fb;
  flex: 1 0 auto;
  padding: 40px 0 20px;
`;

const Content = styled.div`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.36;
  margin: 0 40px;

  ${media.tabletMax`
  margin: 0 20px;
  `};
`;

const Wrapper = styled.div`
  padding-top: 0;
  padding-bottom: 35px;
`;

export default class PrizePool extends React.PureComponent {
  render() {
    const { prizes, loading, raffle, preview } = this.props;

    return (
      <Layout>
        <Wrapper>
          {loading ? (
            <EmptyBox noBorder small transparent loading />
          ) : (
              <React.Fragment>
                {prizes && (
                  <Content>
                    {prizes.map((prize, i) => (
                      <PrizeItem preview={preview} key={i} prize={prize} host_info={raffle && raffle.host_info} />
                    ))}
                  </Content>
                )}
              </React.Fragment>
            )}
        </Wrapper>
      </Layout>
    );
  }
}
