import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import { H4ExtraBoldGrey, H4ExtraBoldWhite } from '../../../../styles/Kit/H4ExtraBold';
import Link from 'redux-first-router-link';

const Layout = styled.div`
  display: flex;
  ${H4ExtraBoldWhite}
`

const Grey = styled.span`
  ${H4ExtraBoldGrey}

`


const View = ({ giveaway }) => {
  return (
    <Translation>{t =>
      <Layout><Grey>{t("Raffles/Creator/EditHeader/View.editing")}{'\u00A0'}</Grey><Link to={`/giveaways/${giveaway && giveaway.EVENTID}`}>{giveaway && giveaway.eventname}</Link></Layout>}
    </Translation>
  );
}

export default View;

