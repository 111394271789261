import styled from 'styled-components';
import React from 'react';
import { media } from '../../../../styles';

const Inside = styled.figure.attrs(props => ({
  style: {
    backgroundImage: `url(${props.src})`
  }
}))`
  background-repeat: no-repeat;
  background-position: 50% 50%;
  
  background-size: cover;
  border-radius: 3px;

  height: 50px;
  width: 50px;
  max-height: 50px;

  ${media.tablet`
    height: 88px;
    width: 88px;
    max-height: 88px;
  `}


  
`;

const Outside = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
	background-color: white;
	border-radius: 6px;

  
  position: absolute;

  width: 58px;
	height: 58px;
  left: 20px;
  top: -29px; 

  ${media.tablet`
    width: 100px;
    height: 100px;
    left: 35px;
    top: -50px; 
  `}
`;

export const SmallImage = ({ src }) => {
  return (
    <Outside>
      <Inside src={src} />
    </Outside>
  );
};

export default SmallImage;
