import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import { Translation } from 'react-i18next';
import { BodyRegularBlack } from '../../../styles/Kit/BodyRegular';
import { green, blue } from '../../../styles/Kit/colors';
import Button from 'common/MaterialUI/Button';

const Content = styled.div`
  text-align: center;


  
  p {
    margin-bottom: 20px;
  }

  > div {
    display: flex;
    justify-content: center;
  }
`;







const List = styled.ul`
  ${BodyRegularBlack}
  text-align: left;
  margin-bottom: 20px;

  [class^='icon-'],
  [class*=' icon-'] {
    color: ${({ streamer }) => streamer ? blue : green};
    margin-right: 8px;
  }
`


class RoleModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }




  render() {

    const { handleRoleSave } = this.props;


    return (
      <Modal {...this.props}>
        <Translation>
          {t => (
            <ContentBox modal title={t('modals.role.change_streamer')}>
              <Content>

                <List streamer>
                  <li><span className="icon-Ico16-Check-Medium" />{t('modals.role.create')}</li>
                  <li><span className="icon-Ico16-Check-Medium" />{t('modals.role.prizes')}</li>
                  <li><span className="icon-Ico16-Check-Medium" />{t('modals.role.partner')}</li>
                </List>
                <Button onClick={() => handleRoleSave()} primary={true} fullWidth>{t('modals.role.change_become_streamer')}</Button>
              </Content>
            </ContentBox>
          )}
        </Translation>
      </Modal>
    );
  }
}

export default RoleModal