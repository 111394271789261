import React from 'react';
import Button from 'common/MaterialUI/Button';
import { Translation } from 'react-i18next';
import styled from 'styled-components';
import { blue } from '../../../../styles/Kit/colors';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Items from './Items';
import classNames from 'classnames';


const Layout = styled.div`
  display: flex;
  white-space: nowrap;

  span {

    color: white;

    :before {
      color: white;
      font-size: 14px;
    }

    :first-child {
      font-weight: normal;
    }

    :last-child {
      color: ${blue};
      margin-left: 20px;
    }
  }

`

class View extends React.PureComponent {

  state = { open: false };

  render() {

    const {
      amount, products,
      total,
      loading,
      cart
    } = this.props;

    const { open } = this.state;

    return (
      <Translation>{t =>
        <React.Fragment>
          <Button type="button" onClick={() => this.setState({ 'open': !open })} outline2><Layout><span><span className={classNames("icon-Ico16-Cart", { loading })} /> {t("Raffles/Creator/MyOrder/View.my_order")} ({amount}) </span><span>€ {total}</span></Layout></Button>
          <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={() => this.setState({ 'open': false })}
            onOpen={() => this.setState({ 'open': true })}
          >
            <Items cart={cart} products={products} amount={amount} handleClose={() => this.setState({ 'open': false })} />
          </SwipeableDrawer>
        </React.Fragment>}</Translation>
    );
  }
}

export default View;