import React from 'react';
import styled, { css } from 'styled-components';


const logo = css`
  width: 100%;
  height: 50px;
`;

const ContentImg = styled.img`
  ${logo};
`;

const YoutubeLogo = () => (
    <ContentImg src="/static/assets/images/yt_logo_rgb_dark.svg"/>
);

export default YoutubeLogo;
