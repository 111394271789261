import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import Tooltip from './MaterialUI/Tooltip';
import Checkbox from './MaterialUI/Checkbox';
import classNames from 'classnames';

let lastError = '';

const Layout = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;

  > *:first-child {
    margin-right: 10px;
  }
  div {
    &.error {
      color: #fc521e;
    }
  }
`;

const withFocus = Wrapped =>
  class Wrapper extends React.PureComponent {
    state = {
      focus: false,
    };
    handleFocus = () => this.setState({ focus: true });
    handleBlur = () => this.setState({ focus: false });

    render() {
      const { focus } = this.state;
      const { handleFocus, handleBlur } = this;
      return (
        <Wrapped {...this.props} {...{ focus, handleFocus, handleBlur }} />
      );
    }
  };

const renderField = withFocus(
  ({ disabled, input, meta, focus, content, handleFocus, handleBlur, openInstantly }) => {
    lastError = meta.error || lastError;
    return (
      <Translation>
        {t => (
          <Layout className={classNames({ validationError: meta.error })}>
            <Checkbox
              id={input.name}
              checked={input.value}
              onChange={input.onChange}
              disabled={disabled}
              invalid={meta.touched && meta.invalid ? 'true' : undefined}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />


            <Tooltip warning disableHoverListener={true} placement="bottom-start" open={meta.touched && meta.invalid && lastError} title={lastError && <span>{t(lastError)}</span>}>

              <div
                className={
                  meta.submitFailed && meta.touched && meta.invalid
                    ? 'error'
                    : ''
                }
              >
                {content}
              </div>
            </Tooltip>
          </Layout>
        )}
      </Translation>
    );
  },
);

export class CheckboxField extends React.PureComponent {
  render() {
    const { name, children, openInstantly, onChange, disabled } = this.props;
    return (
      <Field
        onChange={onChange}
        openInstantly={openInstantly}
        content={children}
        name={name}
        component={renderField}
        disabled={disabled}
      />
    );
  }
}

export default CheckboxField;
