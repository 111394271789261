import styled from 'styled-components';
import React from 'react';
import SorryYouDidNotWin from './SorryYouDidNotWin';
import ClaimYourPrize from './ClaimYourPrize';
import DrawingInProgress from './DrawingInProgress';
import ClaimSuccessful from './ClaimSuccessful';

const Layout = styled.div``;

const ClaimBox = ({ raffle, user, prizeStats }) => {
  return prizeStats ? (
    <Layout>
      {prizeStats.won && (
        prizeStats.claimed ? <ClaimSuccessful {...{ raffle, user, claimed: prizeStats.claimed }} /> : <ClaimYourPrize {...{ raffle, user, claimed: prizeStats.claimed }} />
      )}
      {!prizeStats.won &&
        prizeStats.won_prizes < prizeStats.prize_count && (
          <DrawingInProgress {...prizeStats} />
        )}
      {prizeStats.participant &&
        !prizeStats.won &&
        prizeStats.won_prizes == prizeStats.prize_count && (
          <SorryYouDidNotWin />
        )}
    </Layout>
  ) : null;
};

export default ClaimBox;
