import React from 'react';
import styled, { css } from 'styled-components';
import { H6ExtraBoldRed, H6ExtraBoldGreen } from 'styles/Kit/H6ExtraBold';

const Layout = styled.div`
min-height: 85px;
align-items: center;
  text-align: center;
  :after {
    content: "${({ warning }) => warning ? '\\ea05' : '\\ea02'}";
    background: white;
    font-family: icomoon;
    font-size: 16px;
    
    position: absolute;
    right: -7px;
    top: -7px;
  }


  ${({ warning }) => warning ? css`${H6ExtraBoldRed}` : css`${H6ExtraBoldGreen}`}

  border-radius: 6px;
  border: solid 1px ${({ warning }) => warning ? '#fc521e' : '#5bcb8f'};
  position: relative;
  display: flex;
  justify-content: center;
  padding: 15px;

  > span + span {
    margin-left: 5px;
  }
 `;



const Rule = ({ warning, children, ...props }) =>
  <Layout {...props} warning={warning}><span>{children}</span></Layout>


export default Rule;