import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { connect } from 'react-redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from 'common/MaterialUI/Tab';

import { ROUTE_TYPE_GIVEAWAY, ROUTE_TYPE_GIVEAWAY_EDIT, RAFFLE_STATE_FINISHED } from '../../helpers/const';
import { push } from 'redux-first-router';
import ContestTimer from '../../common/ContestTimer';
import { Translation } from 'react-i18next';
import { MODAL_POSTLAUNCH, modalAction } from 'rdx/modules/modals';

const Wrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);
  }

  padding: 0 20px;


${media.widescreen`
padding: 0;

`}
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1290px;
  width: 100%;
  justify-content: center;
`;

const LabelWithIcon = styled(({ icon, label, className }) => <div className={className}><span className={icon} />{label}</div>)`
  
  display: flex;
  align-items: baseline;

  span {
    margin-right: 5px;
  }
`


const SubmenuGiveaway = ({ routeType, eventId, timer, openShareModal }) => timer && timer.mode != RAFFLE_STATE_FINISHED ? <Translation>{t => (<Wrapper><InnerWrapper><Tabs centered
  value={routeType ? routeType : ROUTE_TYPE_GIVEAWAY}
  indicatorColor="secondary"
  textColor="primary"
>
  <Tab label={t("Navigation/SubmenuGiveaway.giveaway")} value={ROUTE_TYPE_GIVEAWAY} onClick={() => push(`/giveaways/${eventId}`)} />
  <Tab label={<LabelWithIcon label={t("Navigation/SubmenuGiveaway.share")} icon="icon-Ico16-Link" />} onClick={openShareModal} />
  <Tab label={<LabelWithIcon label={t("Navigation/SubmenuGiveaway.edit")} icon="icon-Ico16-Edit" />} value={ROUTE_TYPE_GIVEAWAY_EDIT} onClick={() => push(`/giveaways/${eventId}/edit/prizes`)} />
</Tabs></InnerWrapper></Wrapper>)}</Translation> : null


const TimerWrapper = ({ event, ...props }) => event && <ContestTimer contest={event}>{(timer) => <SubmenuGiveaway {...props} timer={timer} />}
</ContestTimer>

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openShareModal: (EVENTID) => () => {
      dispatch(modalAction({
        modal: MODAL_POSTLAUNCH, data: { EVENTID }
      }))
    },
  }
}

const mapStateToProps = (state, ownProps) => {

  const eventId = state.location.payload && state.location.payload.EVENTID
  const event = eventId && state.raffles.raffles[eventId]

  return {
    eventId,
    event
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    openShareModal: dispatchProps.openShareModal(stateProps.eventId)
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TimerWrapper)
