import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% {
    transform: scale(.7) rotate(0deg);
  }

  100% {
    transform: scale(.7) rotate(359deg);
  }
`;

const animationRule = css`${loadingAnimation} 1s infinite`

export const Inner = styled.div.attrs(props => {


  return {
    style: {
      visibility: Object.prototype.hasOwnProperty.call(props, "visible")
        ? props.visible
          ? 'visible'
          : 'hidden'
        : 'visible',
      transform: props.small ? 'scale(.25)' : 'none',
    }
  }

})`
  mouse-events: none;
  min-width: 100px;
  min-height: 100px;
  background: url(/static/assets/images/loading.gif) no-repeat center;
  animation: ${animationRule};
`;

export const Layout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 100%;
`;

export const Loading = props => (
  <Layout>
    <Inner className="loading" {...props} />
  </Layout>
);

export default Loading;
