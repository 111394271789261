
import styled from 'styled-components';

export default styled.div`
  min-height: 250px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #E3E7EF;
  background-image: url('${({ url }) => url}');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;

`