import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { SliderContext } from '.';

const ArrowHolder = styled.div.attrs(props => {
  return {
    style: {
      marginTop: props.marginTop || 0
    }
  }
})`
  position: absolute;
  left: 50%;

  bottom: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  transform: translateX(-50%);

  max-width: 1434px;
`;

const Arrow = styled.a`
  pointer-events: all;
  z-index: 50;
  position: absolute;

  transition: opacity 300ms ease-out;

  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;
  color: #36a6fc;
  width: 45px;
  height: 85px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);

  :before {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &[disabled] {
    color: #f0f0f0;
  }

  :not(.active) {
    opacity: 0;
  }

  &.icon-Ico16-ArrowLeft-Big {
    left: -3px;
  }

  &.icon-Ico16-ArrowRight-Big {
    right: -3px;
  }
`;

class SliderArrows extends React.Component {
  render() {
    const {
      prevEnabled,
      nextEnabled,
      prev,
      next,
      active,
      marginTop,
    } = this.props;

    return prevEnabled || nextEnabled ? (
      <ArrowHolder marginTop={marginTop}>
        <Arrow
          className={classNames('sliderArrow icon-Ico16-ArrowLeft-Big', {
            active: active,
          })}
          onClick={prev}
          disabled={!prevEnabled}
        />
        <Arrow
          className={classNames('sliderArrow icon-Ico16-ArrowRight-Big', {
            active: active,
          })}
          onClick={next}
          disabled={!nextEnabled}
        />
      </ArrowHolder>
    ) : null;
  }
}

const BigArrows = ({ active, marginTop }) => {
  return (
    <SliderContext.Consumer>
      {sliderState => (
        <SliderArrows {...sliderState} marginTop={marginTop} active={active} />
      )}
    </SliderContext.Consumer>
  );
};
export default BigArrows;
