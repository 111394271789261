import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from 'common/MaterialUI/InputLabel';
import MenuItem from 'common/MaterialUI/MenuItem';
import Select from 'common/MaterialUI/Select';

import Scrollbar from 'common/MaterialUI/Scrollbar';
import Popover from 'common/MaterialUI/Popover';
import DropDownSection from 'common/MaterialUI/DropDownSection';
import PopoverHeader from 'common/MaterialUI/PopoverHeader';
import Radio from 'common/MaterialUI/Radio';
import { COUNTRY_LOCALES } from 'helpers/regions';
import { Translation } from 'react-i18next';
import { RadioGroup, MenuList, FormControlLabel } from '@material-ui/core';
import Switch from 'common/MaterialUI/Switch';
import styled from 'styled-components';
import { InfoButton } from 'common/InfoButton';
import classNames from 'classnames';


const withMenuControl = (Wrapped) => {
  return class Controller extends React.PureComponent {
    state = {
      anchorEl: null
    };

    handleClick = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    render() {
      const { handleClick, handleClose } = this;
      const { anchorEl } = this.state;
      return <Wrapped {...this.props} {...{ handleClick, handleClose, anchorEl }} />;
    }
  };
};

const SwitchHolder = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
`

const Deactivatable = styled.div`
&.disabled {
  opacity: .3;
  pointer-events: none;
}
`

const View = ({ language, anchorEl, handleClose, handleClick, region, regionMode, regionActive, handleChangeRegionSettings }) => {
  const valueEntry = COUNTRY_LOCALES[language].find(([code, name]) => code == region)
  return (
    <Translation>
      {(t) => (
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="prizeRegion">
            {t('prize_region')} <InfoButton inline title={t('prizes_some_region')} />
          </InputLabel>
          <Select
            displayEmpty={true}
            open={false}
            onOpen={handleClick}
            value={regionActive ? region : ""}
            inputProps={{
              name: 'prizeRegion',
              id: 'prizeRegion'
            }}
          >
            {regionActive ? <MenuItem value={valueEntry[0]}>{valueEntry[1]}</MenuItem> : <MenuItem value="">{t("Raffles/Category/Controls/Regions/View.everywhere")}</MenuItem>}
          </Select>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <SwitchHolder><Switch checked={regionActive} onChange={(event) =>
              handleChangeRegionSettings({ regionActive: event.target.checked })} /></SwitchHolder><PopoverHeader>{t('ga_regions_filter')}</PopoverHeader>

            <Deactivatable className={classNames({ disabled: !regionActive })}>
              <DropDownSection title={t('ga_regions_title')}>
                <RadioGroup
                  aria-label="Show giveaways"
                  name="prizeFilterType"
                  value={regionMode}
                  onChange={(event) =>
                    handleChangeRegionSettings({ region, regionMode: event.target.value })}
                >
                  <FormControlLabel value="all" control={<Radio />} label={t('ga_regions_all')} />
                  <FormControlLabel value="some" control={<Radio />} label={t('ga_regions_some')} />
                </RadioGroup>
              </DropDownSection>
              <MenuList component={Scrollbar} >
                {COUNTRY_LOCALES[language].map(([code, name]) => (
                  <MenuItem selected={valueEntry[0] == code}
                    key={code}
                    onClick={() => {
                      handleChangeRegionSettings({ region: code, regionMode });
                      handleClose();
                    }}
                    value={code}
                  >
                    {name}
                  </MenuItem>
                ))}
              </MenuList>
            </Deactivatable>
          </Popover>
        </FormControl>
      )}
    </Translation>
  );
};

export default withMenuControl(View);
