import React, { useState } from 'react';
import Modal from 'common/Modal';
import { ContentBox } from 'common/ContentBox';
import { Translation } from 'react-i18next';

import Setup from './Setup'
import { PLATFORMID_GHEED } from '../../../helpers/const';
import { connect } from 'react-redux';
import EditEmail from './EditEmail';
import EditPassword from './EditPassword';
import styled from 'styled-components';

import Tabs from '@material-ui/core/Tabs';
import Tab from 'common/MaterialUI/Tab';
import { BoxMarginMixin } from '../mixins';


const mapStateToProps = (state, ownProps) => {
  return {
    social: state.auth.user && state.auth.user.socials && state.auth.user.socials.find(s => s.PLATFORMID == PLATFORMID_GHEED)
  }
}

const Content = styled.div`
  ${BoxMarginMixin}

  border-top: solid 1px #e1e2ef;
  margin-top: -1px;

`

const Inner = connect(mapStateToProps)(({ onRequestClose, social }) => {

  const [tab, setTab] = useState("email");


  return <Translation>
    {t => (
      <ContentBox modal nomargin={social ? true : undefined} centerTitle title={t("Modals/EmailLoginSetup/index.title")}>
        {!social && <Setup onRequestClose={onRequestClose} />}

        {social && <Tabs variant="fullWidth" onChange={(event, newValue) => setTab(newValue)} value={tab}
          indicatorColor="secondary"
          textColor="primary"
        >
          <Tab label={t("Modals/EmailLoginSetup/index.editmail")} value={'email'} />
          <Tab label={t("Modals/EmailLoginSetup/index.editpw")} value={'pw'} />
        </Tabs>}
        {social && <Content>
          {tab == "email" ? <EditEmail onRequestClose={onRequestClose} /> : <EditPassword onRequestClose={onRequestClose} />}
        </Content>}

      </ContentBox>
    )}
  </Translation>
})


const ConnectModal = (props) => {

  return (
    <Modal {...props}>
      <Inner {...props} />
    </Modal>
  );
}







export default ConnectModal