import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from 'common/MaterialUI/MenuItem';

import Scrollbar from 'common/MaterialUI/Scrollbar';
import Popover from 'common/MaterialUI/Popover';
import DropDownSection from 'common/MaterialUI/DropDownSection';
import PopoverHeader from 'common/MaterialUI/PopoverHeader';
import { Translation } from 'react-i18next';
import Input from 'common/MaterialUI/Input';
import Button from 'common/MaterialUI/Button';
import { MenuList } from '@material-ui/core';
import { H6ExtraBoldBlueLink, BodySmallRegularBlueLink, BodySmallRegularBlackDiv } from '../../../../../styles/Kit/Atoms';
import styled from 'styled-components';
import Loading from './Loading';

const withMenuControl = (Wrapped) => {
  return class Controller extends React.PureComponent {
    state = {
      anchorEl: null,
    };

    handleClick = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    render() {
      const { handleClick, handleClose } = this;
      const { anchorEl } = this.state;
      return <Wrapped {...this.props} {...{ handleClick, handleClose, anchorEl }} />;
    }


  };
};

const TitleLink = styled(H6ExtraBoldBlueLink)`
  display: block;
  margin-bottom: -7px;
`


const View = ({ keyword, loading, results, handleSearch, anchorEl, handleClose, handleClick, }) => {
  return (
    <Translation>
      {(t) => (
        <FormControl fullWidth>
          <Button white nopadding onClick={handleClick}><span className="icon-Ico16-Search" /></Button>



          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <PopoverHeader>{t('ga_search')}</PopoverHeader>
            {loading && <Loading />}
            <DropDownSection>
              <Input autoFocus fullWidth type="text" value={keyword} onChange={handleSearch} />
            </DropDownSection>
            {results && results.length > 0 && <MenuList component={Scrollbar}>
              {results.map((giveaway) => (
                <MenuItem
                  key={giveaway.EVENTID}
                  value={giveaway.EVENTID}

                ><React.Fragment><BodySmallRegularBlackDiv>
                  <TitleLink to={`/giveaways/${giveaway.EVENTID}`}>{giveaway.eventname}</TitleLink>
                  {' '}by{' '}<BodySmallRegularBlueLink to={`/streamers/${giveaway.host_info.USERID}`}>{giveaway.host_info.username}</BodySmallRegularBlueLink></BodySmallRegularBlackDiv></React.Fragment></MenuItem>
              ))}
            </MenuList>}
          </Popover>
        </FormControl>
      )}
    </Translation>
  );
};

export default withMenuControl(View);
