import React from 'react';
import StepLabel from '@material-ui/core/StepLabel';
import Button from 'common/MaterialUI/Button';
import styled from 'styled-components';


const Layout = styled.span`
font-size: 14px !important;
padding-top: 2px;
`

const StyledStepIconComponent = (props) => {
  const { icon, completed, disabled } = props;
  return <Button nopadding primary small disabled={disabled ? true : undefined}>{completed ? <span className="icon-Ico16-Check-Small" /> : <Layout>{icon}</Layout>}</Button>
}

const StyledStepLabel = ({ children, ...props }) => (<StepLabel {...props} StepIconProps={{ disabled: props.disabled }} StepIconComponent={StyledStepIconComponent}>{children}</StepLabel>);

export default StyledStepLabel;