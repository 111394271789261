import View from './View';
import { connect } from "react-redux";
import { getRafflelistSearchRequestAction } from "rdx/modules/api"
import { giveawayListSettingsAction } from '../../../../../store/modules/giveawayLists';

const mapDispatchToProps = (dispatch) => {
  return {
    handleSearch: (event) => {

      dispatch(giveawayListSettingsAction({ searchKeyword: event.target.value }))
      if (event.target.value && event.target.value.length >= 3) {
        dispatch(getRafflelistSearchRequestAction({ page: 1, limit: 50, string: event.target.value }))
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    results: state.giveawayLists.searchResults,
    loading: state.giveawayLists.searchLoading,
    keyword: state.giveawayLists.searchKeyword
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(View);
