import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { Translation } from 'react-i18next';
import classNames from 'classnames';

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;

  &.disabled {
    opacity: .5;
    pointer-events:none;
  }

  width: 100%;
  height: 60px;

  flex: 0 1 auto;
  border-radius: 6px;

  flex-direction: row;
  color: white;

  :hover {
    box-shadow: 0 4px 12px 0 rgba(29, 29, 29, 0.3);
  }

  background: #36a6fc;
  background-repeat: no-repeat;

  ${media.tablet`
    padding: 0;
    flex-direction: column;
    width: 156px;
    height: 193px;
  `};
`;

const Title = styled.h3`
  color: #fed532;
  font-weight: 800;
`;

const Subline = styled.div`
  font-size: 11px;
  font-weight: 800;
`;

const CoinIcon = styled.div`
  background-image: url(/static/assets/images/spend-1.svg);
  background-repeat: no-repeat;
  background-position: center 2px;
  background-size: 40px 40px;

  width: 40px;
  height: 40px;

  flex: 0 1 auto;
  display: flex;
  justify-content: center;

  :after {
    display: flex;
    align-self: center;
    flex: 0 1 auto;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 0 #ff8432;
  }

  &._1 {
    :after {
      content: '1';
    }
  }

  &._10 {
    :after {
      content: '10';
    }
  }

  &._50 {
    :after {
      content: '50';
    }
  }

  ${media.tablet`

    

    width: 100%;
    height: 120px;
  
    &._1 {
      background-image: url(/static/assets/images/spend-1.svg);
      background-position: center 28px;
      background-size: auto auto;
    }

    &._10 {
      background-image: url(/static/assets/images/spend-10.svg);
      background-position: center 28px;
      background-size: auto auto;
    }

    &._50 {
      background-image: url(/static/assets/images/spend-50.svg);
      background-position: center 12px;
      background-size: auto auto;
    }

    :after {
      font-size: 24px;
      margin-top:5px;
    }
  
  `};
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
`;

export class SpendButton extends React.Component {
  render() {
    const { amount, bonus, onClick, disabled } = this.props;

    return (
      <Translation>{t => <Layout onClick={onClick} className={classNames({ disabled })}>
        <CoinIcon className={`_${amount}`} />
        <Text>
          <Title>
            {t('ga_spend_coins', { count: amount })}
          </Title>
          <Subline>{`= ${amount} ${bonus ? `(+${bonus})` : ''} ${
            amount != 1 ? 'Entries' : 'Entry'
            }`}</Subline>
        </Text>
      </Layout>}</Translation>
    );
  }
}
