import { MODAL_CONTACT_US, modalAction, MODAL_SUPPORT, MODAL_PARTNER_APPLY } from 'rdx/modules/modals';
import { connect } from 'react-redux';
import View from '././View';
import { uiChangeAttributeAction } from 'rdx/modules/ui';

const mapStateToProps = (state, ownProps) => {
  return {
    cookieNotice: state.ui.cookieNotice
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    support: () => {
      dispatch(modalAction({ modal: MODAL_SUPPORT }));
    },
    contact: () => {
      dispatch(modalAction({ modal: MODAL_CONTACT_US }));
    },
    applyAsPartner: () => {
      dispatch(modalAction({ modal: MODAL_PARTNER_APPLY }));
    },

    showCookieNotice: cookieNotice => () => {
      dispatch(uiChangeAttributeAction({ cookieNotice: !cookieNotice }));
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,

    showCookieNotice: dispatchProps.showCookieNotice(stateProps.cookieNotice),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(View);
