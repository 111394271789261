import React from 'react';
import Button from 'common/MaterialUI/Button'
import Grid from '@material-ui/core/Grid';
import InputField from 'common/InputField';
import styled from 'styled-components';
import { Translation, Trans } from 'react-i18next';

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;



const View = ({ handleSubmit, submitting, submitSucceeded, showOverview, dirty, email }) => <Translation>{t => (<form onSubmit={handleSubmit}>


  {submitSucceeded ? <Trans i18nKey="Modals/EmailLoginSetup/EditEmail/View.success" values={{ email }}><p><strong>Bitte bestätige deine neue E-Mail Adresse.</strong><br />Wir haben dir eine E-Mail an <a>EMAIL</a> geschickt. Bitte rufe den darin enthaltenen Aktivierungslink auf, um deine neue E-Mail zu bestätigen. Erst dann kannst du dich mit dieser einloggen.</p></Trans> :

    <Grid container spacing={3}>
      <Grid item xs={12}><InputField name="new_email" title={t("Modals/EmailLoginSetup/EditEmail/View.newmail")} /></Grid>
      <Grid item xs={12}><InputField disableValidState name="password" type="password" title={t("Modals/EmailLoginSetup/EditEmail/View.newpass")} /></Grid>
    </Grid>}





  <Controls>
    {!submitSucceeded && <Button disabled={!dirty || submitting} primary type="submit">{t("Modals/EmailLoginSetup/EditEmail/View.change")}</Button>}
  </Controls>
</form>)}</Translation>

export default View