import { createLogic } from 'redux-logic';
import { GET_RAFFLES_RESPONSE } from '../modules/api/index';
import fetchPrivateProducts from './_fetchPrivateProducts';

const getRafflesResponseLogic = createLogic({
  type: GET_RAFFLES_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      fetchPrivateProducts(dispatch, getState)
    } catch (error) {
      console.log(error);
    }


    done();
  },
});

export default getRafflesResponseLogic;
