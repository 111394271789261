import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';


export const Animated = posed.div({
  transition: { type: 'spring', stiffness: 500, damping: 15 }
});


const Layout = styled.div`
  width: 100%;

`


class Animation extends React.PureComponent {
  constructor(props){
    super(props);
    const { children, startbanner } = props;
    const childCount = React.Children.count(children)
    this.state = {
      active: startbanner < childCount ? startbanner : 0
    };
  }

  componentDidMount() {
    const { interval } = this.props;
    this.timeout = setTimeout(() => {
      this.updateSlide();
    }, interval);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  }

  updateSlide = () => {
    const { active } = this.state;

    const { children, interval } = this.props;
    const childCount = React.Children.count(children)

    const nextActive = active + 1 < childCount ? active + 1 : 0;
    this.setState({
      active: nextActive
    });

    this.timeout = setTimeout(() => {
      this.updateSlide();
    }, interval);
  }


  render() {
    const { children } = this.props;
    const { active } = this.state;

    return <Layout>
      <PoseGroup>{React.Children.toArray(children).filter((c, i) => active == i).map((child, i) => <Animated key={`banner-${i}`}>{child}</Animated>)}</PoseGroup>
    </Layout >


  }
}


export default Animation;


