import React from 'react';
import styled from 'styled-components';
import { BodySmallRegularBlack } from 'styles/Kit/BodySmallRegular';
import { EVENTDESCRIPTIONTEXTTYPE_HTML } from 'helpers/const';


const Layout = styled.div`
    ${BodySmallRegularBlack}
    text-align: center;
    padding: 10px;
`

const QuestionText = ({ way, meta }) => {
	
  if (!meta.question_text) {
    return null
  }

  return way.TEXTTYPEID == EVENTDESCRIPTIONTEXTTYPE_HTML ? <Layout dangerouslySetInnerHTML={{ __html: meta.question_text }} /> : <Layout><b>{meta.question_text}</b></Layout>
}

export default QuestionText;