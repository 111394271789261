import Loadable from 'react-loadable';
import { Loading } from 'common/Loading';

const LoadableComponent = Loadable({
  loader: () => import('./View.jsx'),
  loading: Loading,
});
export default LoadableComponent;


/* import View from './View'

export default View; */