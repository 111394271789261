import Link from 'redux-first-router-link';
import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';
import Image from './Image';
import { SmallImage } from './SmallImage';
import ByStreamer from './ByStreamer';
import { Title } from './Title';
import Footer from './Footer';
import Status from './Status';
import Competitors from './Competitors';
import Check from './Check';

const Layout = styled.div`
	background: white;
	min-width: 300px;
	box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05), 0 1px 1px 0 rgba(29, 29, 29, 0.1);
	:hover {
		box-shadow: 0 4px 12px 0 rgba(29, 29, 29, 0.3);
	}

	> :nth-child(2) {
		height: 50px;
	}

	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	border-radius: 6px;
	cursor: pointer;

	width: 300px;
	max-width: 300px;
	height: 375px;
	position: relative;

	opacity: 1;
	transition: opacity 0.5s ease-in-out;

	&.loading {
		opacity: 0.3;
	}
`;




//background: url(/static/assets/images/loading.gif) no-repeat center;
export const Loading = styled.div`
	mouse-events: none;
	width: 100%;
	flex: 1 0 auto;

	background-size: 30px 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const BoxLink = styled(Link)`
  position: absolute;
  width: 300px;
  height: 400px;
  left: 0;
  top: 0;
`;


class View extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.swiping != 0) {
      return false;
    }
    return nextProps.raffle != this.props.raffle;
  }

  handleLinkEvent = (event) => {
    if (this.props.swiping || this.props.mouseUpDisabled) {
      event.preventDefault();
    }
  };



  render() {
    const { raffle } = this.props;
    const { handleMouseUp, renderLoaded } = this;

    return (
      <Layout draggable="false" className={classNames({ loading: !raffle })} onClick={handleMouseUp}>
        {raffle && renderLoaded()}
      </Layout>
    );
  }

  renderLoaded = () => {
    const { raffle } = this.props;



    return (
      <React.Fragment>
        <Image pic={raffle.eventpic} />

        <SmallImage src={raffle.host_info.pic || "/static/assets/images/platzhalter-avatar.jpg"} />
        <ByStreamer raffle={raffle} handleLinkEvent={this.handleLinkEvent} />
        <Title raffle={raffle} handleLinkEvent={this.handleLinkEvent} />
        <Footer>
          <Status raffle={raffle} />
          <Competitors raffle={raffle} />
        </Footer>
        <Check raffle={raffle} />
        <BoxLink
          draggable="false"
          to={`/giveaways/${raffle.EVENTID}`}
          onMouseUp={this.handleLinkEvent}
          onClick={this.handleLinkEvent}
        />
      </React.Fragment>
    );
  };
}

export default View;
