import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import { Translation } from 'react-i18next';
import { media } from 'styles';
import { H5ExtraBoldBlack } from 'styles/Kit/H5ExtraBold';
import { H3ExtraBoldBlue, H3ExtraBoldGreen } from '../../../styles/Kit/H3ExtraBold';
import { BodyRegularBlack } from '../../../styles/Kit/BodyRegular';
import { green, blue } from '../../../styles/Kit/colors';
import Button from 'common/MaterialUI/Button';
import { PRIMARY_ROLETYPEID_VIEWER, PRIMARY_ROLETYPEID_STREAMER, PRIMARY_ROLETYPEID_STREAMER_UPGRADED, PRIMARY_ROLETYPEID_GHEED_PARTNER, PLATFORMS, PLATFORMID_TWITCH } from 'helpers/const';

const Content = styled.div`
  text-align: center;


  
  p {
    margin-bottom: 20px;
  }

  > div {
    display: flex;
    justify-content: center;
  }
`;


const Tab = styled.div`

  ${H5ExtraBoldBlack}

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  ${({ active }) => active ? `
    background-color: white;
    box-shadow: none;
  ` : `
    background-color: #f8f9fb;
    box-shadow: inset 0 -1px 0 0 #e1e2ef;
  `}

`

const Tabs = styled.div`
  display: flex;

  margin: -33px -20px 20px -20px;

  ${media.tablet`
    margin: -33px -40px 20px -40px;
  `}
`


const Icon = styled.div`
  background: url(${({ source }) => source});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 45px;
  height: 40px;
  margin-bottom: 5px;
`

const Role = styled.div`
  margin-top: -5px;
  ${({ streamer }) => streamer ? H3ExtraBoldBlue : H3ExtraBoldGreen}
`

const List = styled.ul`
  ${BodyRegularBlack}
  text-align: left;
  margin-bottom: 20px;

  [class^='icon-'],
  [class*=' icon-'] {
    color: ${({ streamer }) => streamer ? blue : green};
    margin-right: 8px;
  }
`


class RoleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { response: false, streamer: false };
  }


  handleCallback = event => {
    event.stopPropagation();

    const { onRequestClose, loginWithOAuth } = this.props;
    const { response } = this.state;

    if (response) {
      loginWithOAuth(PLATFORMS[PLATFORMID_TWITCH], response)
      this.setState({ response: false });
      onRequestClose();
    }
  };

  render() {

    const { role } = this.state;
    const { handleRoleSave } = this.props;

    const isStreamer = [PRIMARY_ROLETYPEID_STREAMER, PRIMARY_ROLETYPEID_STREAMER_UPGRADED, PRIMARY_ROLETYPEID_GHEED_PARTNER].indexOf(role) != -1;

    return (
      <Modal {...this.props}>
        <Translation>
          {t => (
            <ContentBox modal>

              <Tabs>
                <Tab active={!isStreamer} onClick={() => this.setState({ role: PRIMARY_ROLETYPEID_VIEWER })}>

                  <Icon source="/static/assets/images/hat-viewer.svg" />
                  {t('modals.role.join_as')} <Role>{t('viewer')}</Role>
                </Tab>
                <Tab active={isStreamer} onClick={() => this.setState({ role: PRIMARY_ROLETYPEID_STREAMER })}>

                  <Icon source="/static/assets/images/hat-streamer.svg" />
                  {t('modals.role.join_as')} <Role streamer>{t('streamer')}</Role>
                </Tab>
              </Tabs>
              <Content>
                {!isStreamer && <List>
                  <li><span className="icon-Ico16-Check-Medium" />{t('modals.role.browse_giveaways')}</li>
                  <li><span className="icon-Ico16-Check-Medium" />{t('modals.role.earn_coins')}</li>
                  <li><span className="icon-Ico16-Check-Medium" />{t('modals.role.cool_stuff')}</li>
                </List>}
                {isStreamer && <List streamer>
                  <li><span className="icon-Ico16-Check-Medium" />{t('modals.role.create')}</li>
                  <li><span className="icon-Ico16-Check-Medium" />{t('modals.role.prizes')}</li>
                  <li><span className="icon-Ico16-Check-Medium" />{t('modals.role.partner')}</li>
                </List>}
                <Button onClick={() => handleRoleSave(role)} primary={isStreamer} secondary={!isStreamer} fullWidth>{t('modals.role.join_as')} {t(isStreamer ? 'streamer' : 'viewer')}</Button>
              </Content>
            </ContentBox>
          )}
        </Translation>
      </Modal>
    );
  }
}

export default RoleModal