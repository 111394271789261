import React from 'react';
import Button from 'common/MaterialUI/Button'
import Grid from '@material-ui/core/Grid';
import InputField from 'common/InputField';
import styled from 'styled-components';
import { Translation, Trans } from 'react-i18next';

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;



const View = ({ handleSubmit, submitSucceeded, showOverview, submitting, email, resendConfirmation, resending }) => <Translation>{t => (<form onSubmit={handleSubmit}>


  {submitSucceeded ? <Trans i18nKey="Modals/EmailLoginSetup/Setup/View.setup" values={{ email }}><p><strong>Bitte bestätige deine E-Mail Adresse.</strong><br />Wir haben dir eine E-Mail an <a>EMAIL</a> geschickt. Bitte rufe den darin enthaltenen Aktivierungslink auf, um deine E-Mail zu bestätigen und die Registrierung abzuschließen.</p></Trans> :

    <Grid container spacing={3}>
      <Grid item xs={12}><InputField name="email" title={t("Modals/ConnectModal/EmailFlow/Login/View.email")} /></Grid>
      <Grid item xs={12}><InputField name="password" type="password" title={t("Modals/ConnectModal/EmailFlow/Login/View.password")} /></Grid>
      <Grid item xs={12}><InputField name="passwordconfirm" type="password" title={t("Modals/ConnectModal/EmailFlow/Register/View.passwordconfirm")} /></Grid>
    </Grid>}





  <Controls>
    {!submitSucceeded ? <Button primary disabled={submitting} type="submit">{t("Modals/EmailLoginSetup/Setup/View.submit")}</Button> : <Button disabled={resending} onClick={resendConfirmation} primary type="button">{t("Modals/ConnectModal/EmailFlow/Register/View.resend")}</Button>}
  </Controls>
</form>)}</Translation>

export default View