import React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styles';
import { RAFFLE_STATE_FINISHED, ASSET_VARIANT_ORIGINAL, UIELEMENT_EVENTBANNER } from 'helpers/const';
import TimeAndEntries from '../TimeAndEntries';
import ClaimBox from '../../ClaimBox';
import Drawing from '../../Drawing';
import ContestTimer from 'common/ContestTimer';
import { utils } from 'helpers/utils';
import Coupons from '../../Coupons';

// add retina support
const bgMixin = css`
  background-image: url(${({ pic }) => utils.resolveImageAsset(pic, ASSET_VARIANT_ORIGINAL)});
  
`

const Image = styled.figure`
  ${bgMixin}
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;

  flex: 1 0 auto;
  
  height: 208px;

  ${media.phone`
    height: 356px;
  `};

  ${media.tablet`
    height: 504px;
  `};
`;

class TimerRenderer extends React.PureComponent {
  render() {
    const { timer, raffle, prizeStats, ways, user_entries, preview } = this.props;

    const bannerUrl = raffle.EVENTURLS && raffle.EVENTURLS.find(({UIELEMENTID})=> UIELEMENTID == UIELEMENT_EVENTBANNER);

    return (
      <React.Fragment>
        <TimeAndEntries {...{ timer, raffle, ways, user_entries, preview }} />
        {timer &&
          timer.mode == RAFFLE_STATE_FINISHED && (
            <ClaimBox {...{ raffle, prizeStats }} />
          )}
        {timer &&
          timer.mode == RAFFLE_STATE_FINISHED && (
            <Coupons completed raffle={raffle} />
          )}
        {timer &&
          timer.mode == RAFFLE_STATE_FINISHED &&
          (prizeStats && prizeStats.won_prizes == prizeStats.prize_count) ? (
            <Drawing {...{ raffle }} />
          ) : (
            bannerUrl ? <a href={bannerUrl.path}><Image pic={raffle.eventpic} /></a> : <Image pic={raffle.eventpic} />
          )}
      </React.Fragment>
    );
  }
}

const ImageArea = ({ raffle, prizes, user, prizeStats, ways, user_entries, preview }) => (
  <ContestTimer contest={raffle}>{(timer) => <TimerRenderer {...{ raffle, prizes, user, prizeStats, ways, user_entries, preview, timer }} />}


  </ContestTimer>
);

export default ImageArea;
