import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { media } from '../styles';

const Page = styled.a`
  cursor: pointer;
  display: block;
  color: #36a6fc;
  font-family: Nunito;
  font-size: 11px;
  font-weight: 800;

  min-width: 18px;
  height: 18px;
  padding: 0 5px;
  border-radius: 25px;
  border: solid 1px #36a6fc;

  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    pointer-events: none;
    color: #9c9c9c;
    border-color: #9c9c9c;
    box-shadow: none;
  }
`;

const Layout = styled.div`
  display: flex;
  > * + * {
    margin-left: 5px;
  }

  ${media.tabletMax`
    justify-content: center;
  `};
`;

const Spacer = styled.div`
  color: #9c9c9c;
  :before {
    content: '...';
  }
`;

export const Pagination = ({ total, active, onChange }) => {
  if (!total || total <= 1) {
    return null;
  }

  const firstDisabled = active == 1;
  const lastDisabled = active == total;
  const previousDisabled = active == 1;
  const nextDisabled = active == total;
  const prev = active - 1;
  const next = active + 1;
  const first = 1;
  const last = total;

  return (
    <Layout>
      <Page
        onClick={() => onChange.apply(null, [prev])}
        className={classNames('icon-Ico16-ArrowLeft-Small', {
          disabled: previousDisabled,
        })}
      />

      {!firstDisabled &&
        prev != first && (
          <Page
            onClick={() => onChange.apply(null, [first])}
            className={classNames({ disabled: firstDisabled })}
          >
            {first}
          </Page>
        )}
      <Spacer />
      {!previousDisabled && (
        <Page
          onClick={() => onChange.apply(null, [prev])}
          className={classNames({ disabled: previousDisabled })}
        >
          {prev}
        </Page>
      )}
      <Page className={classNames('disabled')}>{active}</Page>
      {!nextDisabled && (
        <Page
          onClick={() => onChange.apply(null, [next])}
          className={classNames({ disabled: nextDisabled })}
        >
          {next}
        </Page>
      )}
      <Spacer />
      {!lastDisabled &&
        next != last && (
          <Page
            onClick={() => onChange.apply(null, [last])}
            className={classNames({ disabled: lastDisabled })}
          >
            {last}
          </Page>
        )}

      <Page
        onClick={() => onChange.apply(null, [next])}
        className={classNames('icon-Ico16-ArrowRight-Small', {
          disabled: nextDisabled,
        })}
      />
    </Layout>
  );
};
