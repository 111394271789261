import { createLogic } from 'redux-logic';
import { POST_BILLING_RESPONSE, getCartRequestAction } from '../modules/api';

const postBillingResponseLogic = createLogic({
  type: POST_BILLING_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      // load cart 
      const state = getState();
      if (state.location.payload && state.location.payload.mode == "summary") {
        dispatch(getCartRequestAction());
      }

    } catch (error) {
      console.log(error);
    }

    done();


  },
});

export default postBillingResponseLogic;