import styled from 'styled-components';
import React from 'react';
import { Translation } from 'react-i18next';
import { media } from 'styles';
import { NavLink } from 'redux-first-router-link';
import { BodySmallRegularBlue, BodySmallRegularGrey } from '../../../../../styles/Kit/BodySmallRegular';
import Tooltip from 'common/MaterialUI/Tooltip';
import LinkButton from 'common/LinkButton';

const Layout = styled.div`

  padding: 10px;

  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: inset 0 1px 0 0 #e1e2ef;

  background: white;

  ${media.tabletMax`
    flex-direction: column-reverse;
  `}

  display: flex;
  justify-content: ${({ isPrivate }) => isPrivate ? 'space-evenly' : 'center'};
  align-items: center;

`
const Links = styled.div`
  
  ${BodySmallRegularBlue}
  align-items: baseline;


  display: flex;
  flex-wrap: wrap;

  a, button {
    cursor: pointer;
  }

  a:not(:last-child), button:not(:last-child) {
    :after {
      content: " · ";
      color: #9697a1;
      margin: 0 5px;
    }
  }

  ${media.tabletMax`
    justify-content: center;
    margin-bottom: 5px;
  `}

`

const Private = styled.div`
${BodySmallRegularGrey}
white-space: nowrap;
margin-right: 10px;
`

const View = ({ showReportModal, isPrivate }) => <Translation>{t => (<Layout isPrivate={isPrivate}>
  {isPrivate && <Tooltip placement="top" title={t("Raffles/Detail/MainBox/Footer/View.tooltip")}><Private><span className="icon-Ico16-NoViews" /> {t("Raffles/Detail/MainBox/Footer/View.private")}</Private></Tooltip>}
  <Links>
    <NavLink to="/terms">{t('terms')}</NavLink>
    <NavLink to="/conditions">{t('conditions')}</NavLink>
    <LinkButton onClick={showReportModal}>{t("Raffles/Detail/MainBox/Footer/View.report")}</LinkButton>
  </Links>
</Layout>)}</Translation>

export default View;