import styled from 'styled-components';

const Circle = styled.div`
  position: absolute;
  position: absolute;
  right: 20px;
  top: 8px;
  background: url(/static/assets/images/loading.gif) no-repeat center;
  background-size: contain;
  width: 25px;
  height: 25px;
`;

export default Circle