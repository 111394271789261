import styled from 'styled-components';
import React from 'react';
import DropDownController from 'common/BaseDropDown';
import DropDownList from 'common/BaseDropDown/DropDownList';
import { IconButton } from 'common/IconButton';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Translation } from 'react-i18next';
import { helpers } from '../../../helpers';
import Notification from './Notification';
import { putNotificationsRequestAction } from 'rdx/modules/api';

const Layout = styled(DropDownController)`
  &.dropdown.open > *:first-child {
    color: white;
  }

  .dropdownlist.open {
    min-width: 300px;
    border-radius: 6px;
    background: white;
    margin-top: 8px;

    box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
      0 1px 1px 0 rgba(29, 29, 29, 0.1), 0 15px 25px 0 rgba(29, 29, 29, 0.3);
    right: -43px;

    :after {
      position: absolute;
      top: -5px;

      content: ' ';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #f0f0f0 transparent;
      right: 55px;
    }

    > a {
      display: block;
      font-size: 14px;
      cursor: pointer;
      padding: 2px 20px;

      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
    }
  }
`;

const Header = styled.h3`
  color: grey;
  display: flex;
  font-size: 14px;
  color: #5bcb8f;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin: 0;
  padding: 20px;
  border-bottom: solid 2px #f0f0f0;
  text-transform: uppercase;
  background-color: rgba(234, 237, 243, 0.25);
`;

const Content = styled.div``;

const Empty = styled.div`
  font-size: 14px;
  padding: 20px;

  a {
    font-weight: 800;
  }
`;

class Notifications extends React.PureComponent {

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleDropDownChange = state => {
    const { open } = state;
    const { updateNotifications, notifications } = this.props;

    if (open) {
      const newNotifications = notifications
        .filter(n => n.status == 1)
        .map(n => ({ ...n, status: 2 }));
      if (newNotifications.length) {
        this.timer = setTimeout(() => {
          updateNotifications(newNotifications);
        }, 3000);
      }
    }
  };

  render() {
    const { notifications } = this.props;

    return (
      <Translation>
        {t => (
          <Layout>
            <IconButton
              className={`icon-Ico16-Notification${
                notifications.length > 0 ? '' : ' disabled'
                }`}
              number={notifications.filter(n => n.status == 1).length}
            />
            <DropDownList
              onClick={helpers.stopPropagation}
              onChange={this.handleDropDownChange}
            >
              <Header>{t('notifications')}</Header>
              <Content>
                {notifications.length > 0 ? (
                  <Scrollbars autoHeight>
                    {notifications.map(n => (
                      <Notification key={n.notificationid} {...n} />
                    ))}
                  </Scrollbars>
                ) : (
                    <Empty>{t('notifications_empty')}</Empty>
                  )}
              </Content>
            </DropDownList>
          </Layout>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.notifications.notifications,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateNotifications: notifications => {
      dispatch(
        putNotificationsRequestAction({
          payload: {
            notifications: notifications,
          }
        }),
      )
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);
