import React from 'react';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { withStyles } from '@material-ui/styles';
import styled, { css } from 'styled-components';
import { media } from 'styles';
import { H6ExtraBoldBlack, H6ExtraBoldWhite } from 'styles/Kit/H6ExtraBold';
import theme from 'styles/theme';
import { MandatoryIcon } from './_MandatoryIcon';
import BoxTitle from './_BoxTitle';
import Tooltip from 'common/MaterialUI/Tooltip';
import utils from 'helpers/utils';
import { Translation, Trans } from 'react-i18next';
import moment from 'moment-timezone';
import { WAYTYPE_ICONS } from 'helpers/const';
import { connect } from 'react-redux';

export const PlatformIcon = styled.span`
  color: ${({ color, complete }) => (complete ? '#fff' : color)};
  &[class^='icon-'] {
    font-size: 22px;
  }
  flex: 0 0 40px;
  ${media.tabletMax`
    flex: 0 0 32px;
  `};
`;

const CompleteEntries = styled.div`
flex: 0 0 56px;
width: 56px;
height: 30px;

border-radius: 38px;

display: flex;
align-items: center;
justify-content: flex-end;
padding-right: 10px;

font-size: 16px;
color: #ffffff;

${media.tablet`
  flex: 0 0 67px;
  width: 67px;
  height: 36px;
`};

:after {
  display: flex;
  content: "+${props => props.amount}";
  flex: 1 0 auto;
  justify-content: center;

  font-family: Nunito;
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
  text-shadow: 0 1px 0 rgba(29, 29, 29, 0.3);
  position: absolute;
  transform: translateX(-25px);
  
}
`


export const PlusEntries = styled.div.attrs(props => ({ style: { background: props.color } }))`
flex: 0 0 56px;
width: 56px;
height: 30px;
position: relative;


  border-radius: 38px;
  box-shadow: inset 0 4px 0 0 rgba(255, 255, 255, 0.3);

  ${media.tablet`
    flex: 0 0 67px;
    width: 67px;
    height: 36px;
  `}

  display: flex;
  align-items: center;

  :after {
    display: flex;
    content: "+${props => props.amount}";
    flex: 1 0 auto;
    justify-content: center;

    font-family: Nunito;
    font-size: 16px;
    font-weight: 800;
    color: #ffffff;
    text-shadow: 0 1px 0 rgba(29, 29, 29, 0.3);
    
    
  }

  ${({ daily }) => daily && css`
  :before {
    content: "daily";
    color: white;
    position: absolute;
    font-size: 11px;
    bottom: 2px;
    width: 100%;
    text-align: center;
  }

  :after {
    transform: translateY(-5px);
  }
  
  `}
`;


const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    cursor: 'auto !important',
    '&$expanded': {



      minHeight: '56px',
    },
  },


  content: {
    margin: "10px 5px 10px 15px",
    [theme.breakpoints.down('xs')]: {
      margin: "10px 5px 10px 10px",
    },

    '&$expanded': {
      margin: "10px 5px 10px 15px",
      [theme.breakpoints.down('xs')]: {
        margin: "10px 5px 10px 10px",
      },
    },
    '& > :last-child': {
      paddingRight: 0,
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  expanded: {
    margin: 0
  }

})(MuiExpansionPanelSummary);





const Summary = styled.div`
${({ complete }) => (complete ? H6ExtraBoldWhite : H6ExtraBoldBlack)};
  display: flex;
  cursor: pointer;
  align-items: center;

  flex: 1 1 auto;

  ${media.tablet`
  font-size: 16px;
  `};
`;

const Title = styled.div`
  flex: 1 1 auto;
`

const ExpansionPanel = styled(MuiExpansionPanel)`
    &.root {
      border-radius: 6px;
      border: 1px solid #f0f0f0;
      box-shadow: none;
      background: ${({ color, complete }) => (complete ? color : '#fff')};
      color: ${({ complete }) => (complete ? '#f2f2f2' : '#202022')};  

      ${({ complete }) => !complete && css`
        :hover:not(.Mui-disabled) {
          border: 1px solid #36a6fc;
        }
      `};

      &.Mui-disabled {
          background-color: white;
      }
    }
    &.Mui-expanded {
      border: 1px solid #36a6fc !important;
    }
`

const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,
  }
})(MuiExpansionPanelDetails);

const Details = styled.div`
width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
`

const DetailContent = styled.div`
background-color: #f8f9fb;
  padding: 10px 15px;
  box-shadow: 0 -1px 0 0 #e1e2ef, 0 1px 0 0  #e1e2ef;
`

const Controls = styled.div`
  padding: 10px 15px;
  justify-content: center;
  display: flex;
  background: white;
  border-radius: 0px 0px 6px 6px;
  margin-top: 1px;
  
  > *:not(:first-child) {
    margin-left: 10px;
  }
`

const IndicatorOpen = styled.div`
  flex: 0 0 67px;
  width: 67px;
  height: 36px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #9697a1;
  border-radius: 16px;
  box-shadow: inset 0 2px 0 0 #f8f9fb;
  border: solid 1px #eaedf3;
  background-color: #eaedf3;


`

const Box = ({ way, display_way, waytype, platform, complete, loading, color, icon, title, titleParams, titleAdornments, children, toggle, controls, open, openMandatory, amount, timezone }) => {

  const { cooldown, entry_date, mandatory, earned_amount } = way

  const boxAmount = typeof amount != 'undefined' ? amount : way.amount;
  const boxColor = typeof color != 'undefined' ? color : platform.color;
  const boxIcon = icon || WAYTYPE_ICONS[way.WAYTYPEID] || platform.icon;
  const boxComplete = typeof complete != 'undefined' ? complete : (cooldown && entry_date ? utils.isOnDailyCooldown(entry_date) : earned_amount)
  const boxTitle = typeof title != 'undefined' ? title : BoxTitle(false, complete, way, display_way, waytype, titleParams)
  const boxDisabled = (openMandatory && !mandatory)

  const daily = cooldown;

  return <Translation>{t => {

    const tooltipCooldown = <Trans i18nKey="Raffles/Detail/MainBox/Entryways/Methods/_Box.cooldowndesc" values={{ timestamp: moment.utc(entry_date).add(24, 'hours').tz(timezone).format(t('date_cooldown')) }}>This entry offers a <b>daily bonus!</b> You can execute it again at (timestamp) to gain additional entries. </Trans>

    return <ExpansionPanel disabled={!boxComplete && boxDisabled} classes={{ 'root': 'root' }} complete={boxComplete} color={boxColor} expanded={open ? true : false}>
      <ExpansionPanelSummary onClick={toggle(way)}><Summary complete={boxComplete}>
        <PlatformIcon
          color={boxColor}
          className={boxIcon}
          complete={boxComplete}
        />
        <Title>{boxTitle}</Title>
        {titleAdornments && titleAdornments()}
        {mandatory && <MandatoryIcon title={t('ga_mandatory')} />}
        {boxComplete ? <CompleteEntries amount={boxAmount}>{cooldown && entry_date ? <Tooltip title={tooltipCooldown}><span className="icon-Ico20-Clock" /></Tooltip> : <span className="icon-Ico16-Check-Medium" />}</CompleteEntries> : (open ? <IndicatorOpen><span className="icon-Ico16-ArrowDown-Medium" /></IndicatorOpen> : <PlusEntries {...{ amount: boxAmount, loading, color: boxColor, daily }} />)}
      </Summary>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails><Details><DetailContent>{children}</DetailContent>{controls && <Controls>{controls(boxComplete, loading, t)}</Controls>}</Details></ExpansionPanelDetails>
    </ExpansionPanel>
  }}</Translation>

}


const mapStateToProps = (state, ownProps) => {
  return {
    timezone: state.auth.user && state.auth.user.preferences && state.auth.user.preferences.timezone ? state.auth.user.preferences.timezone : moment.tz.guess()
  }
}

export default connect(mapStateToProps)(Box)