import { compose } from 'redux';
import View from './View';
import withForm from 'hoc/withForm';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { RESTAPI } from 'rdx/modules/api/rest';
import { REGEX_PASSWORD } from 'helpers/const';

const validate = values => {
  const errors = {};



  if (!values.old_password) {
    errors.old_password = 'forms.validation_password';
  }

  if (!values.new_password) {
    errors.new_password = 'forms.validation_password';
  } else if (!REGEX_PASSWORD.test(values.new_password)) {
    errors.new_password = 'forms.validation_password_strength';
  }

  if (values.new_password != values.new_passwordconfirm) {
    errors.new_passwordconfirm = 'forms.validation_password_differs';
  }


  return errors;
};


const onSubmit = (values, dispatch) => {

  const subValues = { ...values, new_passwordconfirm: undefined }

  return RESTAPI.postEmailPwChange({ payload: subValues }).then((r) => {
    return Promise.resolve(r);
  }).catch(error => {

    throw new SubmissionError(error && error.response && error.response.data)

  })




};

export const onSubmitFail = (errors, dispatch, submitError, form) => {
  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input.error'
    );
    if (firstErrorNode) {
      firstErrorNode.focus();
    }
  }, 50);
};

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      old_password: null,
      new_password: null,
      new_passwordconfirm: null,
    },
  }
}


const enhance = compose(
  connect(mapStateToProps),
  withForm({
    form: 'email-pwedit',
    validate,
    onSubmit,
    onSubmitFail,
    destroyOnUnmount: true,
  }),
);



export default enhance(View);

