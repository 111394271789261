import { showNotificationModal } from "../modules/modals";
import { push } from 'redux-first-router';

const thunk = (dispatch, getState) => {

  const EVENTID = getState().location.payload.EVENTID;

  push(`/giveaways/${EVENTID}`)



  dispatch(
    showNotificationModal({
      title: 'modals.general.failed_title',
      htmlContent: 'modals.notifications.page_under_construction',
    }),
  );
};

export default thunk;