import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Layout from './Layout';
import { ROUTE_TYPE_GIVEAWAY_EMBED } from 'helpers/const';

const raffles = state => state.raffles.raffles;
const location = state => state.location;
const ways = state => state.raffles.entryways;
const user_entries = state => state.raffles.user_entries;

export const getRaffleDetails = createSelector(
  [raffles, location, ways, user_entries],
  (raffles, location, ways, user_entries) => {
    const openRaffleEventId = location.payload.EVENTID;

    const raffle = raffles
      ? raffles[openRaffleEventId]
        ? raffles[openRaffleEventId]
        : null
      : null;

    const isEmbed = location.type == ROUTE_TYPE_GIVEAWAY_EMBED ? true : false;

    return {
      raffle: raffle,
      ways: ways[openRaffleEventId],
      user_entries: user_entries[openRaffleEventId],
      isEmbed: isEmbed
    };
  },
);

const mapStateToProps = (state, ownProps) => {
  return {
    ...getRaffleDetails(state),
  };
};

const enhance = compose(connect(mapStateToProps));

export const RaffleDetailContainer = enhance(Layout);
export default RaffleDetailContainer;
