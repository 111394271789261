import React from 'react';
import styled from 'styled-components';
import Tooltip from 'common/MaterialUI/Tooltip';



const Button = styled.a`
  cursor: pointer;
  color: #36a6fc;
  display: inline-block;
  background: white;
  border-radius: 6px;
  pointer-events: all;
`;

const Wrapper = styled.div`
  
  display: ${({ inline }) => inline ? 'inline-block' : 'flex'};
  width: 25px;
  height: ${({ large }) => large ? '16px' : '13px'};

 
  position: relative;
  left: 5px;
  top: 0px;

  [class^='icon-'] {
    font-size: ${({ large }) => large ? '16px' : '13px'};
  }
  
`

export class InfoButton extends React.PureComponent {
  state = { active: false };

  handleMouseOver = () => {

    if (!this.props.title) {
      return;
    }


    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.setState({ active: true });
    }, 300);
  };

  handleMouseOut = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.setState({ active: false });
  };

  renderAsHtml = (title) => {
    return <div dangerouslySetInnerHTML={{ __html: title }} />
  }

  render() {
    const { onClick, title, placement, inline, large } = this.props;
    const { active } = this.state;

    return (
      <Tooltip disableHoverListener={title ? false : true} disableFocusListener title={title} placement={placement} open={title && active ? true : false}>
        <Wrapper inline={inline} large={large} onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          onClick={(event) =>
            onClick ? onClick(event) : this.setState({ active: !active })
          }><Button className="icon-Ico16-Tooltip-Questionmark">
          </Button></Wrapper>
      </Tooltip>
    );
  }
}

export default InfoButton;
