import React from 'react';
import styled from 'styled-components';
import Button from 'common/MaterialUI/Button';
import InputField from 'common/InputField';
import { compose } from 'redux';
import { withForm } from 'hoc/withForm';
import { connect } from 'react-redux';
import { media } from 'styles';
import { onSubmitFail } from 'helpers/forms';
import { Translation, Trans } from 'react-i18next';
import { COUNTRY_LOCALES, PROVINCES } from '../../helpers/regions';
import { putClaimPrizeRequestAction } from 'rdx/modules/api';
import MenuItem from 'common/MaterialUI/MenuItem';
import { REGEX_EMAIL } from 'helpers/const';

const Layout = styled.div`
  > div {
    border-top: solid 2px #f0f0f0;
    padding: 20px 40px;

    :first-child {
      background: white;

      font-size: 11px;

      ${media.tablet`
        font-size: 14px;
      `} > * + * {
        margin-top: 20px;
      }
    }

    :last-child {
      background-color: rgba(234, 237, 243, 0.25);
      display: flex;
      justify-content: flex-end;
    }
  }

  p {
    &.error {
      color: #ff8432;
    }
  }
`;

const ClaimFieldset = styled.fieldset`
  border: 0;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 10px;
  }

  > * + * {
    margin-top: 10px;
  }

  ${media.tablet`
    flex-direction: row;
    > * + * {
      margin-top: 0;
      margin-left: 20px;
    }
  `};
`;

const GeneralError = styled.div`
  background: rgb(252, 82, 30);
  color: white;
  font-size: 14px;
  padding: 20px 40px;
  border-radius: 6px;
  align-self: center;
  flex: 1 1 auto;
  text-align: center;
  width: 100%;
`;


const ClaimForm = props => {
  const { handleSubmit, physical, language, provinces, claimable } = props;

  return (
    <Translation>
      {t => (
        <form onSubmit={handleSubmit}>
          <ClaimFieldset disabled={!claimable}>
            {!claimable && (
             <GeneralError className="error">
               <b>{t('notice')}</b> <br/>
                <Trans i18nKey="claims.claim_over">
                  Unfortunately, you missed the 14-day grace period to claim your prize. See 
                  <a href="https://gheed.com/terms">terms</a>.
                </Trans>
             </GeneralError>
            )}
          <Layout>
            <div>
              <p>
                <Trans i18nKey="claims.to_receive">
                  To receive your prizes, we need the following information from
                  you. If you have any questions, just contact our
                  <a href="mailto:support@gheed.com">support</a>.
                </Trans>
              </p>
              <Fields>
                <InputField name="email" title={t('forms.your_email')} />
                <InputField
                  name="email_repeat"
                  title={t('forms.your_email_confirm')}
                />

              </Fields>

              {physical && (
                <React.Fragment>
                  <Fields>
		            <InputField name="phonenumber" title={t('forms.phonenumber')} />

                    <InputField
                      name="claiming_address.first_name"
                      title={`${t('forms.name')}*`}
                    />
                    <InputField
                      name="claiming_address.last_name"
                      title={`${t('forms.surname')}*`}
                    />
                  </Fields>

                  <Fields>
                    <InputField
                      name="claiming_address.address_1"
                      title={`${t('forms.street')}*`}
                    />
                  </Fields>
                  <Fields>
                    <InputField
                      name="claiming_address.city"
                      title={`${t('forms.city')}*`}
                    />
                    <InputField
                      name="claiming_address.postcode"
                      title={`${t('forms.postcode')}*`}
                    />
                  </Fields>
                  <Fields>

                    <InputField select
                      name="claiming_address.country"
                      title={`${t('forms.country')}*`}
                    >
                      <MenuItem key="empty" value="">
                        {t('forms.please_select')}
                      </MenuItem>
                      {COUNTRY_LOCALES[language].map(([alpha2, localName], index) => (
                        <MenuItem key={alpha2} value={alpha2}>
                          {localName}
                        </MenuItem>
                      ))}
                    </InputField>
                    {provinces ? (
                      <InputField select
                        name="claiming_address.state"
                        title={`${t('forms.state')}`}
                      >
                        <MenuItem key="empty" value="">{t('forms.please_select')}</MenuItem>
                        {Object.keys(provinces).map((alpha2, index) => (
                          <MenuItem key={alpha2} value={alpha2}>{provinces[alpha2]}</MenuItem>

                        ))}
                      </InputField>) : (
                        < InputField
                          onChange={props.onChange}
                          name="claiming_address.state"
                          title={t('forms.state')}
                          helperText={t('forms.optional')}
                        />
                      )}
                  </Fields>
                </React.Fragment>
              )}

            </div>
            <div>
              <Button primary type="submit" disabled={!claimable}>{t('forms.submit')}</Button>
            </div>
          </Layout>
        </ClaimFieldset>
        </form>
      )}
    </Translation>
  );
};

const validate = values => {
  const errors = {};

  if (!values.email || !REGEX_EMAIL.test(values.email)) {
    errors.email = 'forms.validation_email';
  }

  if (values.email != values.email_repeat) {
    errors.email_repeat = 'forms.validation_address_differs';
  }

  if (values.physical  && (!values.phonenumber || !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i.test(values.phonenumber))) {
    errors.phonenumber = 'forms.validation_phonenumber';
  }

  if (values.physical) {
    const required = [
      'address_1',
      'city',
      'country',
      'first_name',
      'last_name',
      'postcode',
    ];

    for (let index = 0; index < required.length; index++) {
      const element = required[index];

      if (
        !values.claiming_address ||
        !values.claiming_address[element] ||
        !values.claiming_address[element].length > 0
      ) {
        errors.claiming_address
          ? (errors.claiming_address[element] = 'forms.validation_fillfield')
          : (errors.claiming_address = {
            [element]: 'forms.validation_fillfield',
          });
      }
    }
  }


  return errors;
};

const onSubmit = (values, dispatch) => {
  dispatch(
    putClaimPrizeRequestAction({
      id: values.EVENTID, payload: {
        claiming_mail: values.email,
        claiming_phonenumber: values.phonenumber,
        terms_and_conditions: values.accept_terms,
        claiming_address: values.claiming_address,
      }
    }),
  )
};

export const mapStateToProps = (state, ownProps) => ({
  language: state.ui.language,
  provinces:
    state.form.claim &&
      state.form.claim.values &&
      state.form.claim.values.claiming_address && state.form.claim.values.claiming_address.country
      ? PROVINCES[state.form.claim.values.claiming_address.country]
      : null,
  initialValues: {
    EVENTID: state.location.payload.EVENTID,
    virtual: ownProps.virtual,
    physical: ownProps.physical,
    email: state.auth.user && state.auth.user.mail,
    email_repeat: null,
    phonenumber: null,
    accept_terms: false,
    claiming_address: {
      last_name:
        state.auth.user && state.auth.user.billing_address
          ? state.auth.user.billing_address.last_name
          : null,
      first_name:
        state.auth.user && state.auth.user.billing_address
          ? state.auth.user.billing_address.first_name
          : null,
      address_1:
        state.auth.user && state.auth.user.billing_address
          ? state.auth.user.billing_address.address_1
          : null,
      city:
        state.auth.user && state.auth.user.billing_address
          ? state.auth.user.billing_address.city
          : null,
      postcode:
        state.auth.user && state.auth.user.billing_address
          ? state.auth.user.billing_address.postcode
          : null,
      country:
        state.auth.user && state.auth.user.billing_address
          ? state.auth.user.billing_address.country
          : null,
    },
  },
});

const enhance = compose(
  connect(mapStateToProps),
  withForm({
    form: 'claim',
    validate,
    onSubmit,
    onSubmitFail,
    destroyOnUnmount: true
  })
);

export default enhance(ClaimForm);
