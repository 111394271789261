import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogicMiddleware } from 'redux-logic';
import rootLogic from './logic';
import i18nextReduxMiddleware from '../i18n/middleware';
import { axiosJWTMiddleWare } from './modules/api/response-middleware';
import { createRootReducerConfig } from './modules';
import { routesMap } from '../routes';
import { onBeforeRouteChange, onAfterRouteChange } from '../routes/events';
import cookieMiddleware from './cookieMiddleware';


const composeEnhancers =
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    process.env.NODE_ENV != 'production' // eslint-disable-line no-underscore-dangle
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // eslint-disable-line no-underscore-dangle
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;



export default function configureStore(state) {
  const { routeConfig, rootReducer } = createRootReducerConfig(
    routesMap,
    onBeforeRouteChange,
    onAfterRouteChange,
  );

  const logicmiddleware = createLogicMiddleware(rootLogic);

  const middlewares = applyMiddleware(
    routeConfig.middleware,
    thunk,
    logicmiddleware,
    axiosJWTMiddleWare,
    i18nextReduxMiddleware,
    cookieMiddleware,
  );

  const store = createStore(
    rootReducer,
    state,
    composeEnhancers(routeConfig.enhancer, middlewares),
  );

  if (module.hot) {
    module.hot.accept('./modules', () => {
      const { createRootReducerConfig } = require('./modules');

      const { rootReducer } = createRootReducerConfig(
        routesMap,
        onBeforeRouteChange,
        onAfterRouteChange,
      );

      store.replaceReducer(rootReducer);
    });

    module.hot.accept('./logic', () => {
      let newRootLogic = require('./logic');
      logicmiddleware.replaceLogic(newRootLogic);
    });
  }

  return store;
}
