import getDynamicListRequestLogic from './getDynamicListRequestLogic';
import getRaffleCategoryListRequestLogic from './getRaffleCategoryListRequestLogic';
import routeChange from './routeChange';

import {
  generateGetUsersSettingsRequestLogic,
  generateGetNotificationsRequestLogic,
  generateGetCategoriesRequestLogic,
  RESTLogics,
  generateGetRafflelistSearchRequestLogic,
  generateGetRafflesRequestLogic,
  generateGetCartRequestLogic,
  generateGetRaffleByIdEditRequestLogic,
} from '../modules/api/logics';
import getCategorySettingsLogic from './getCategorySettingsLogic';
import postEntryWaysEntityResponseLogic from './postEntryWaysEntityResponseLogic';
import getProductsByIdResponseLogic from './getProductsByIdResponseLogic';
import getUsersSettingsErrorLogic from './getUsersSettingsErrorLogic';
import loginRequestActionLogic from './loginRequestActionLogic';
import logoutRequestActionLogic from './logoutRequestActionLogic';
import loginWithOAuthLogic from './loginWithOAuthLogic';
import putRafflesResponseLogic from './putRafflesResponseLogic';
import putCouponResponse_deleteCouponResponse_getCartResponse_putCartResponseLogic from './putCouponResponse_deleteCouponResponse_getCartResponse_putCartResponseLogic';
import getWalletsListRequestLogic from './getWalletsListRequestLogic';
import getImagesListRequestLogic from './getImagesListRequestLogic';
import postImagesRequestWithFormValueLogic from './postImagesRequestWithFormValueLogic';
import getRaffleByIdEditResponseLogic from './getRaffleByIdEditResponseLogic';
import putRaffleByIdEditResponseLogic from './putRaffleByIdEditResponseLogic';
import getProductsSearchRequestLogic from './getProductsSearchRequestLogic';
import putRafflesFinalizeResponseLogic from './putRafflesFinalizeResponseLogic';
import getRaffleByIdEditErrorLogic from './getRaffleByIdEditErrorLogic';
import postContactResponseLogic from './postContactResponseLogic';
import postSupportResponseLogic from './postSupportResponseLogic';
import deleteSocialsEntityResponseLogic from './deleteSocialsEntityResponseLogic';
import errorNotificationsLogic from './errorNotificationsLogic';
import getRecentWinnersRequestLogic from './getRecentWinnersRequestLogic';
import authRoleChangeRequestLogic from './authRoleChangeRequestLogic';
import authRoleChangeResponseLogic from './authRoleChangeResponseLogic';
import storeFilteredListRequestLogic from './storeFilteredListRequestLogic';
import putUsersSettingsResponseLogic from './putUsersSettingsResponseLogic';
import getImagesProductsRequestWithFormValueLogic from './getImagesProductsRequestWithFormValueLogic';
import productCategoryListRequestLogic from './productCategoryListRequestLogic';
import postBetaApplyResponseLogic from './postBetaApplyResponseLogic';
import putCartErrorLogic from './putCartErrorLogic';
import getRaffleCategoryListResponseLogic from './getRaffleCategoryListResponseLogic';
import getRaffleByIdResponseLogic from './getRaffleByIdResponseLogic';
import getRafflesResponseLogic from './getRafflesResponseLogic';
import putPrivateProductByEventIdResponseLogic from './putPrivateProductByEventIdResponseLogic';
import getUsersSettingsResponseLogic from './getUsersSettingsResponseLogic';
import postRaffleByIdReportResponseLogic from './postRaffleByIdReportResponseLogic';
import putClaimPrizeResponseLogic from './putClaimPrizeResponseLogic';
import postBillingResponseLogic from './postBillingResponseLogic';
import getWaysByRaffleIdStatusResponseLogic from './getWaysByRaffleIdStatusResponseLogic';
import getWaytypesValidateRequestLogic from './getWaytypesValidateRequestLogic';

const rootLogic = [
  // REST Calls + Overrides
  ...Object.values({
    ...RESTLogics,
    getRafflesRequestLogic: generateGetRafflesRequestLogic({
      latest: true,
      debounce: 1000,
    }),
    getCartRequestLogic: generateGetCartRequestLogic({
      latest: true,
      debounce: 1000,
    }),
    getRaffleByIdEditRequestLogic: generateGetRaffleByIdEditRequestLogic({
      latest: true,
      debounce: 1000,
    }),
    getUsersSettingsRequestLogic: generateGetUsersSettingsRequestLogic({
      latest: true,
    }),
    getNotificationsRequestLogic: generateGetNotificationsRequestLogic({
      latest: true,
      debounce: 1000,
    }),
    getCategoriesRequestLogic: generateGetCategoriesRequestLogic({
      latest: true,
      debounce: 1000,
    }),
    getRafflelistSearchRequestLogic: generateGetRafflelistSearchRequestLogic({
      latest: true,
      debounce: 1000,
    }),
    getWaytypesValidateRequestLogic

  }),

  authRoleChangeRequestLogic,
  authRoleChangeResponseLogic,
  deleteSocialsEntityResponseLogic,
  errorNotificationsLogic,
  getCategorySettingsLogic,
  getDynamicListRequestLogic,
  getImagesListRequestLogic,
  getImagesProductsRequestWithFormValueLogic,
  getProductsByIdResponseLogic,
  getProductsSearchRequestLogic,
  getRaffleByIdEditErrorLogic,
  getRaffleByIdEditResponseLogic,
  getRaffleByIdResponseLogic,
  getRaffleCategoryListRequestLogic,
  getRaffleCategoryListResponseLogic,
  getRafflesResponseLogic,
  getRecentWinnersRequestLogic,
  getUsersSettingsErrorLogic,
  getUsersSettingsResponseLogic,
  getWalletsListRequestLogic,
  loginRequestActionLogic,
  loginWithOAuthLogic,
  logoutRequestActionLogic,
  postBetaApplyResponseLogic,
  postBillingResponseLogic,
  postContactResponseLogic,
  postEntryWaysEntityResponseLogic,
  postImagesRequestWithFormValueLogic,
  postRaffleByIdReportResponseLogic,
  postSupportResponseLogic,
  productCategoryListRequestLogic,
  putCartErrorLogic,
  putClaimPrizeResponseLogic,
  putCouponResponse_deleteCouponResponse_getCartResponse_putCartResponseLogic,
  putPrivateProductByEventIdResponseLogic,
  putRaffleByIdEditResponseLogic,
  putRafflesFinalizeResponseLogic,
  putRafflesResponseLogic,
  putUsersSettingsResponseLogic,
  routeChange,
  storeFilteredListRequestLogic,
  getWaysByRaffleIdStatusResponseLogic,
];

export default rootLogic;
