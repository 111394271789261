import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Style = styled.div`
  height: 36px;
  width: 100%;
  flex: 1 0 100%;
  background-color: rgba(54, 166, 252, 0.6);
  position: relative;

  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;

  a {
    color: white;
    cursor: pointer;

    + a {
      margin-left: 10px;
    }

    :hover {
      :before {
        text-shadow: 1px 1px #1d1d1d;
      }
    }
  }
`;

const Progress = styled.div.attrs(props => ({
  style: {
    width: `${props.value || 0}%`,
  }
}))`
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #2083fb;
  flex: 0 0 auto;
  transition: width 0.25s ease-out;
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 10px;
  flex: 1 0 auto;

  
`;

const FullscreenToggle = styled.a`
  position: absolute;
  right: 10px;
`

const FakeLink = styled.div`
color: white;
cursor: pointer;

& + & {
  margin-left: 10px;
}

:hover {
  :before {
    text-shadow: 1px 1px #1d1d1d;
  }
}
`

export class ControlBar extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      toggleFullScreen,
      togglePause,
      next,
      previous,
      progress,
      paused,
    } = this.props;

    return (
      <Style>
        <Progress value={progress} />
        <Controls>
          <FakeLink className="icon-Ico20-SlidePrev" onClick={previous} />
          <FakeLink
            className={classNames({
              'icon-Ico20-SlidePause': !paused,
              'icon-Ico20-SlidePlay': paused,
            })}
            onClick={togglePause}
          />
          <FakeLink className="icon-Ico20-SlideNext" onClick={next} />
        </Controls>
        <FullscreenToggle className="icon-Ico20-Fullscreen" onClick={toggleFullScreen} />
      </Style>
    );
  }

  loop() { }
}
