import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import React from 'react';
import View from './View';
import { MASONRYITEM_TYPE_CONTEST } from '../../../helpers/const';
import { raffleDynamicListRequestAction } from '../../../store/modules/giveawayLists';

const streamerId = state => state.location.payload.USERID;
const list = state => state.giveawayLists.streamer;
const users = state => state.stats.users;

export const getMemoized = createSelector(
  [streamerId, list, users],
  (streamerId, list, users) => {

    return {
      streamerId,
      user: users[streamerId],
      list,
      loaded: list && list.loaded,
      loading: list && list.loading,
      raffles: list && list.data ? list.data.map(raffle => ({
        type: MASONRYITEM_TYPE_CONTEST,
        data: raffle,
        id: `r${raffle.EVENTID}`
      }))
        : [],
    };
  }
);

const mapStateToProps = (state, ownProps) => {
  return {
    ...getMemoized(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleScrolledToBottom: (streamerId, list) => () => {

      if (list && list.current_page == list.total_pages) {
        return;
      }

      return dispatch(
        raffleDynamicListRequestAction([{
          filter: `OWNERID eq ${streamerId}`,
          orderby: 'active_to desc',
          page: list ? list.current_page + 1 : 1,
          limit: 12,
        }, 'streamer', { isInfinite: true }])

      );
    }
  };
};

class Container extends React.PureComponent {

  render() {
    return <View {...this.props} />;
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    handleScrolledToBottom: dispatchProps.handleScrolledToBottom(stateProps.streamerId, stateProps.list),
  };
};

const StreamersDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Container);
export default StreamersDetailContainer;
