import React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import styled from 'styled-components';
import ProductColumn from '../ProductColumn';
import Quantity from '../Quantity';
import Regions from '../Regions';
import Button from 'common/MaterialUI/Button';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Store from '../../../helpers/store';
import { withStyles } from '@material-ui/core/styles';
import { media } from '../../../styles';
import Details from './Details';
import { SHOP_ID_PRIVATE, SHOP_ID_WOOCOMMERCE } from 'helpers/const';
const Footer = styled.div`
  display: flex;
  margin: 10px;

  > * {

    :not(:first-child) {
      margin-left: 5px;
    }

    ${media.tabletMax`

    :last-child {
      margin-left: auto;
    }

    `}

  }


    

  
  

`

const Summary = styled.div`
  display: flex;
  flex: 1 1 auto;

  > * {
    :first-child {
      margin: 10px;
    }
  }

  ${media.tabletMax`
    flex-direction: column;

    > *:last-child {
      padding: 10px 10px 0;
      width: 100%;
      border-top: solid 1px #e1e2ef;
    }

    
  `}
`


const ExpansionPanel = withStyles({
  root: {
    border: '1px solid #e1e2ef',
    borderRadius: '3px',
    boxShadow: 'none',
    '& + &': {
      marginTop: '5px'
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
    border: 'solid 1px #36a6fc'
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    cursor: 'auto !important'
  },





  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
    '& > :last-child': {
      paddingRight: 0,
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  expanded: {
    margin: 0
  }

})(MuiExpansionPanelSummary);


const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,

  }
})(MuiExpansionPanelDetails);






class ExpandableProductBox extends React.PureComponent {

  state = {
    expanded: false
  }

  render() {
    const { product, variation, amount, host_info } = this.props
    const { expanded } = this.state;
    return <Translation>{t => (<ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary>
        <Summary>
          <ProductColumn product={product} variation={variation} />
          <Footer><Quantity quantity={amount} /><Regions product={product} variation={variation} /><Button small nopadding outline onClick={() => this.setState({ expanded: !expanded })}><span className={`icon-Ico16-Arrow${expanded ? 'Up' : 'Down'}-Small`} /></Button></Footer>
        </Summary>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails><Details product={product} variation={variation} host_info={host_info} /></ExpansionPanelDetails>
    </ExpansionPanel>)}</Translation>
  }
}

const mapStateToProps = (state, ownProps) => {

  const { shopvariation_id, product, variation, preview } = ownProps;

  if(product) {
    const {shop_id, shopproduct_id} = product

    let displayProduct = product ? product : shop_id == SHOP_ID_WOOCOMMERCE ? state.products.products && state.products.products[shopproduct_id] : state.privateProducts.data[shopproduct_id];
    const getVariation = variation ? variation : shop_id == SHOP_ID_WOOCOMMERCE && shopvariation_id && product && product.variations.find(v => v.PRODUCTID == shopvariation_id)



    if (preview && shop_id == SHOP_ID_PRIVATE) {
      
      const previews = Store.get("preview-privateproducts");
      
      const previewProduct = previews && previews.find(p => p.PRIVATEPRODUCTID == displayProduct.PRIVATEPRODUCTID)
      if (previewProduct) {
        displayProduct = { ...displayProduct, ...previewProduct }
      }
    }




    return {
      product: displayProduct,
      variation: getVariation
    }
  }

  
}

export default connect(mapStateToProps)(ExpandableProductBox)
