import { createLogic } from 'redux-logic';
import { GET_CART_RESPONSE, PUT_CART_RESPONSE, DELETE_COUPON_RESPONSE, getPaymentRequestAction, PUT_COUPON_RESPONSE, getProductsByIdRequestAction , getRafflesRequestAction } from 'rdx/modules/api';
import { ROUTE_TYPE_CREATE } from '../../helpers/const';


const putCouponResponse_deleteCouponResponse_getCartResponse_putCartResponseLogic = createLogic({
  type: [PUT_COUPON_RESPONSE, DELETE_COUPON_RESPONSE, GET_CART_RESPONSE, PUT_CART_RESPONSE],

  process({ getState, action }, dispatch, done) {

    try {
      const state = getState();
      const routeType = state.location.type;

      // load products with variations if cart has products or products could been added , also reload detail product (to update reservations on cart delete)
      if (action.payload && action.payload.line_items) {
        const productIds = action.payload.line_items.map(li => li.product.PRODUCTID);
        if (productIds.length) {
          dispatch(
            getProductsByIdRequestAction({
              id: productIds,
            }),
          );
        }
      }

      if (routeType == ROUTE_TYPE_CREATE) {

        dispatch(getRafflesRequestAction());

        // load payment if necessary
        if (state.location.payload && state.location.payload.mode == "summary" && action.payload && parseFloat(action.payload.total) != 0) {
          dispatch(getPaymentRequestAction());
        }
      }



    } catch (error) {
      console.log(error)
    }

    done();
  }
});

export default putCouponResponse_deleteCouponResponse_getCartResponse_putCartResponseLogic;
