import "babel-polyfill";
import 'rdx/modules/api/request-interceptor'
import { COUNTRY_GUESS } from 'rdx/modules/api/response-interceptor'

import numeral from 'i18n/numeral';
import App from 'components/App';
import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';

import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';


import {
  initialState,
} from './store/initialState';
import configureStore from './store';
import theme from './styles/theme';
import { LOCALES } from './helpers/const';
import { getUsersRegionsGuessPromise, getUsersSettingsPromise } from './store/modules/api/logics';
import { addCookiesToInitialState } from './store/cookieMiddleware';
import { JWT } from './helpers/jwt';

const getClientLocale = () => {
  let lang;

  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0].substr(0, 2).toLowerCase();
  }

  if (!lang && navigator.language) {
    lang = navigator.language.substr(0, 2).toLowerCase();
  }

  if (!lang && navigator.browserLanguage) {
    lang = navigator.browserLanguage.substr(0, 2).toLowerCase();
  }

  return lang && LOCALES.indexOf(lang) != -1 ? lang : LOCALES[0];
};

(async () => {

  let user = null;

  const accessToken = JWT.getAccessToken()
  const refreshToken = JWT.getRefreshToken()

  if (accessToken || refreshToken) {
    user = await getUsersSettingsPromise();
  } else {
    await getUsersRegionsGuessPromise()
  }

  // COUNTRY_GUESS is available after first REST call

  const initialStateWithLocale = initialState(getClientLocale(), COUNTRY_GUESS, user);

  let preloadedState =
    typeof window !== 'undefined'
      ? window.PRELOADED_STATE
        ? window.PRELOADED_STATE
        : initialStateWithLocale
      : initialStateWithLocale;

  // add cookie values
  preloadedState = addCookiesToInitialState(preloadedState)

  // sync numeral locale before render
  if (preloadedState && preloadedState.ui && numeral.locale() != preloadedState.ui.language) {
    numeral.locale(preloadedState.ui.language);
  }

  const store = configureStore(preloadedState);

  render(<I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </I18nextProvider>,
    document.getElementById('root')
  );

})()


if (module.hot) {
  module.hot.accept('components/App', window.main);
}