import { H6ExtraBoldBlue } from './H6ExtraBold';
import { BodySmallRegularBlue , BodySmallRegularBlack } from './BodySmallRegular';
import Link from 'redux-first-router-link';
import styled from 'styled-components';


export const H6ExtraBoldBlueLink = styled(Link)`
  ${H6ExtraBoldBlue}
`

export const BodySmallRegularBlueLink = styled(Link)`
  ${BodySmallRegularBlue}
`

export const BodySmallRegularBlackSpan = styled.span`
  ${BodySmallRegularBlack}
`

export const BodySmallRegularBlackDiv = styled.div`
  ${BodySmallRegularBlack}
`

