import { createLogic } from 'redux-logic';
import { STORE_FILTERED_LIST_REQUEST, storeFilteredListResponseAction } from '../modules/products';
import { RESTAPI } from '../modules/api/rest';


const storeFilteredListRequestLogic = createLogic({
  type: [STORE_FILTERED_LIST_REQUEST],

  async process({ getState, action }, dispatch, done) {
    try {

      const resp = await RESTAPI.getProducts(action.payload[0])
      dispatch(storeFilteredListResponseAction({ [action.payload[1]]: resp }))
    } catch (error) {
      console.log(error)
    }
    done();
  },
});

export default storeFilteredListRequestLogic;