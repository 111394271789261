import styled from 'styled-components';
import React from 'react';
import { media } from 'styles';
import RaffleSlider from 'common/RaffleSlider';
import EmptyBox from 'common/EmptyBox';
import SliderWrapper from '../../../common/RaffleSlider/SliderWrapper';
import { getEventBackground } from '../../../helpers/giveaways';
import RaffleMainBox from './MainBox';
import {
  EVENT_BACKGROUND_TEMPLATE_FIXED,
  EVENT_BACKGROUND_TEMPLATE_SCROLL,
} from './styles';
import Meta from './Meta';
import { Translation } from 'react-i18next';
//import CalendarBanner from '../../../common/CalendarBanner/View';
//import { runtimeConfig } from '../../../runtimeConfig';

const Layout = styled.div`
  ${props =>
    props.backgroundAttachment == 'scroll'
      ? EVENT_BACKGROUND_TEMPLATE_SCROLL
      : EVENT_BACKGROUND_TEMPLATE_FIXED} display: flex;
  flex: 1 0 auto;
  flex-direction: column;
`;

const RaffleArea = styled.div`
  display: flex;
  flex-direction: column;

  align-items: stretch;
  flex: 1 0 auto;

  padding: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  flex: 1 1 auto;
  padding: 180px 20px 74px;

  ${media.tablet`
padding: 180px 40px 74px;
`};
`;

const SliderLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  position: relative;
  z-index: 59;
  align-self: stretch;
  margin-top: 60px;
  padding-top: 20px;
  background: rgba(234, 237, 243, 1);
  min-height: auto;
`;

const Wrapper = ({ children }) => (
  <Translation>{t => <SliderWrapper to={`/giveaways`} title={t('more_popular_giveaways')}>
    {children}
  </SliderWrapper>}</Translation>
);

const EmptyHolder = styled.div`
  transform: translateY(80px);
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

class RaffleDetailComponent extends React.PureComponent {
  render() {
    const {
      raffle,
      prizes,
      prizeStats,
      user,
      ways,
      user_entries,
      openMandatory,
      handleSocialConnect,
      handlePostEntrywaysEntity,
      authenticated,
      login,
      isEmbed,
    } = this.props;

    const backgroundInfo = getEventBackground(raffle) || {};

    const RaffleContent = () => (
       <React.Fragment>
        {raffle ? (
          <RaffleMainBox
                sixthirty
                {...{
                  raffle,
                  prizes,
                  ways,
                  user_entries,
                  user,
                  prizeStats,
                  socials: user ? user.socials : [],
                  authenticated,
                  login,
                  handleSocialConnect,
                  handlePostEntrywaysEntity,
                  openMandatory,
                  isEmbed
                }}
              />
          ) : (
          <EmptyHolder>
                  <EmptyBox transparent dark noBorder loading="true" />
          </EmptyHolder>
          )}
         </React.Fragment>
    );

    return (
   <React.Fragment>
      {isEmbed ? (
	    <React.Fragment>
	    <Meta raffle={raffle} />
          <RaffleContent />
        </React.Fragment>
        ) : (
      <Layout {...backgroundInfo}>
        <Meta raffle={raffle} />
        <RaffleArea>
          <Content>
            <RaffleContent />
          </Content>
          {raffle && !raffle.disable_crosspromo && <SliderLayout>
            <RaffleSlider
              wrapper={Wrapper}
              list="contest"
              config={{
                orderbyPath: 'mostpopular',
                filterPath: 'active',
                limit: 20,
                mode: 'preview',
                featuredIncluded: true
              }}
            />
          </SliderLayout>}

        </RaffleArea>
      </Layout>
)}
 </React.Fragment>
    );
  }
}

export default RaffleDetailComponent;
