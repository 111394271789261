import React from 'react';
import { Field } from 'redux-form';
import TextField from 'common/MaterialUI/TextField';
import { Translation } from 'react-i18next';
import styled from 'styled-components';

const Layout = styled.div`
  position: relative;
  width: 100%;
`;

let lastError = '';


class Renderer extends React.PureComponent {
  render() {
    const {
      maxlength,
      title,
      input,
      meta,
      placeholder,
      disabled,
      icon,
      helperText,
      multiline,
      rows,
      rowsMax,
      type,
      select,
      children,
      touched,
      large,
      SelectProps,
      InputProps,
      stateless,
      valid,
      error,
      disableTooltip,
      disableValidState,
      disableInvalidState,
      nomargin
    } = this.props;
    lastError = meta.error || lastError;

    return (
      <Layout><Translation>
        {t => <TextField disableTooltip={disableTooltip} InputProps={InputProps} SelectProps={SelectProps} type={type} select={select} large={large} label={title}
          multiline={multiline}
          nomargin={nomargin}
          rows={rows}
          rowsMax={rowsMax}
          maxlength={maxlength}
          id={input.name}
          placeholder={placeholder}
          disabled={disabled}
          icon={icon}
          onBlur={input.onBlur}
          onChange={input.onChange}
          onDragStart={input.onDragStart}
          onDrop={input.onDrop}
          onFocus={input.onFocus}
          name={input.name}
          value={input.value}
          active={meta.active}
          helperText={helperText}
          valid={!stateless && !disableValidState ? valid || ((touched || meta.touched) && meta.valid) : undefined}
          error={!stateless && !disableInvalidState ? error || (meta.touched && meta.invalid && t(lastError)) : undefined}
        >{children}</TextField>}

      </Translation></Layout>
    );
  }
}

const FieldHolder = props => <Field {...props} component={Renderer} />;

export default FieldHolder;
