import React from 'react';
import styled from 'styled-components';
import numeral from 'i18n/numeral';
import { Translation } from 'react-i18next';
import { H6ExtraBoldBlack } from 'styles/Kit/H6ExtraBold';

const Layout = styled.div`
  ${H6ExtraBoldBlack}
  white-space: nowrap;
  .high {
    color: #ff782c;
  }
`;

const Competitors = ({ raffle }) => {
  const { participants } = raffle;
  return (
    <Translation>
      {t => (
        <Layout className={participants >= 5000 ? 'high' : ''}>
          {t('ga_competitors', {
            count: participants,
            formattedCount: numeral(participants).format('0'),
          })}
        </Layout>
      )}
    </Translation>
  );
};

export default Competitors;