import React from 'react';
import { InfoButton } from 'common/InfoButton';
import { connect } from 'react-redux';
import { modalAction, MODAL_LOYALTY } from '../store/modules/modals';

const CoinsInfoButton = ({ modal }) => (
  <InfoButton onClick={modal} />
);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    modal: (event) => {
      event.stopPropagation();
      dispatch(
        modalAction({
          modal: MODAL_LOYALTY,
        }),
      );
    },
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(CoinsInfoButton);
