import React from 'react';
import styled from 'styled-components';

import { media } from 'styles';
import Link from 'redux-first-router-link';

const Layout = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;

const ContestTitle = styled(Link)`
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1d1d1d;
  font-family: Nunito;
  font-weight: 800;

  &.orange {
    color: #ff8432;
  }

  &.green {
    color: #5bcb8f;
  }

  &.blue {
    color: #4ccbfd;
  }

  font-size: 14px;
  padding-right: 10px;

  ${media.desktopMax`
  font-size: 11px;
  `};
`;

const Thumb = styled.div.attrs(props => { return { style: { backgroundImage: `url(${props.url})` } } })`
  width: 35px;
  height: 28px;
  border-radius: 2px;

  margin-right: 8px;
  padding-left: 15px;
  background-clip: padding-box;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  flex: 0 0 auto;

  ${media.tabletMax`
    display: none;
  `};
`;

export const Title = ({ wallet }) => {
  const pic = wallet.currency.streamer.pic
    ? wallet.currency.streamer.pic
    : `http://via.placeholder.com/260x208`;

  return (
    <Layout>
      <Thumb url={pic} />
      <ContestTitle to={`/streamers/${wallet.currency.streamer.USERID}`}>
        {wallet.currency.streamer.username}
      </ContestTitle>
    </Layout>
  );
};
