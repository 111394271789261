
import View from './View';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  return {
    coupons: state.coupons.coupons[ownProps.raffle.EVENTID]
  }
}


export default connect(mapStateToProps)(View);