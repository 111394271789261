import { connect } from 'react-redux';
import View from './View';
import { postUserDeletePromise, getUsersSettingsPromise } from 'rdx/modules/api/logics';



const mapDispatchToProps = (dispatch, ownProps) => {

  return {
	  handleUserDelete: () => {
		  dispatch(async (dispatch) => {
	        return await postUserDeletePromise({}, dispatch).then(() => getUsersSettingsPromise({}, dispatch));
	      })
	  },
  };

};

export default connect(
  null,
  mapDispatchToProps,
)(View);
