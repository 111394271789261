import styled from 'styled-components';
import React from 'react';

import { LaunchIn } from 'common/RafflePreview/View/LaunchIn';
import { TimeLeft } from 'common/RafflePreview/View/TimeLeft';
import { Finished } from 'common/RafflePreview/View/Finished';
import ContestTimer from 'common/ContestTimer';
import { RAFFLE_STATE_ANNOUNCED, RAFFLE_STATE_ACTIVE, RAFFLE_STATE_FINISHED } from '../../../helpers/const';

export const Loading = styled.div`
	mouse-events: none;
	width: 100%;
	flex: 1 1 auto;

	background-size: 30px 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const TopArea = ({ timer }) => {
  if (!timer) {
    return <Loading />;
  }

  if (timer.mode == RAFFLE_STATE_ANNOUNCED) {
    return <LaunchIn time={timer.time} />;
  }

  if (timer.mode == RAFFLE_STATE_ACTIVE) {
    return <TimeLeft time={timer.time} />;
  }

  if (timer.mode == RAFFLE_STATE_FINISHED) {
    return <Finished time={timer.time} />;
  }
};

const Status = ({ raffle }) => (
  <ContestTimer contest={raffle}>{(timer) => <TopArea contest={raffle} timer={timer} />}

  </ContestTimer>
);



export default Status;
