import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Translation } from 'react-i18next';
import { BodySmallRegularGrey } from 'styles/Kit/BodySmallRegular';
import { media } from '../../../../styles';


const Layout = styled.div`

    margin: 0 40px 20px 40px;
    padding: 5px 15px;
    border-radius: 15px;
    background-color: #ffffff;
    border: solid 1px #e1e2ef;

    display: flex;
    justify-content: space-between;

    ${BodySmallRegularGrey}

    ${media.tabletMax`
      margin: 0 20px 20px 24px;
      flex-direction: column;
      justify-content: flex-start;
    `}

`;

const StartEnd = ({ raffle, timezone, followingDescription }) => {

  if (!raffle || !timezone) {
    return null;
  }


  return <Translation>
    {t => (
      <Layout className={classNames(followingDescription)}>
        <span>
          <b>{t('ga_launch')} </b>
          {raffle && raffle.active_from ? moment
            .utc(raffle.active_from)
            .tz(timezone)
            .format(t('date_short')) : '[no time set]'}
        </span>

        <span>
          <b>{t('ga_end')} </b>
          {raffle && raffle.active_to ? moment
            .utc(raffle.active_to)
            .tz(timezone)
            .format(t('date_short')) : '[no time set]'}
        </span>
      </Layout>
    )}
  </Translation>
}

const mapStateToProps = (state, ownProps) => {
  return {
    timezone: state.auth.user && state.auth.user.preferences && state.auth.user.preferences.timezone ? state.auth.user.preferences.timezone : moment.tz.guess(),
  };
};

export default connect(mapStateToProps)(StartEnd);
