import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoutRequestAction, loginRequestAction } from 'rdx/modules/auth';
import isAuthed from '../store/selectors/isAuthed';
import isStreamer from '../store/selectors/isStreamer';

export const withAuth = WrappedComponent => {
  class Wrapped extends Component {
    constructor(props) {
      super(props);
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    state => ({
      user: state.auth.user,
      isStreamer: isStreamer(state),
      authenticated: isAuthed(state),
    }),
    { login: loginRequestAction, logout: logoutRequestAction },
  )(Wrapped);
};

export default withAuth;
