import React from 'react';
import styled from 'styled-components';
import { scrollToTop } from 'helpers/scrollTo';
import Button from 'common/MaterialUI/Button';

const Layout = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const ScrollToTopButton = () => (
  <Layout>
    <Button outline small nopadding
      onClick={scrollToTop}
    ><span className="icon-Ico16-ArrowUp-Medium" /></Button>
  </Layout>
);

export default ScrollToTopButton;
