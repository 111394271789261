import React from 'react';
import Modal from 'common/Modal';
import styled from 'styled-components';
import { ContentBox } from 'common/ContentBox';
import Button from 'common/MaterialUI/Button';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import { Translation } from 'react-i18next';

const Content = styled.div`
  text-align: center;
`;


const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  > * {
    flex: 0 1 auto;

    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;

export const Spacer = styled.div`
  width: 50px;
  height: 2px;
  background: #f0f0f0;
  margin: 10px;
`;



const getCropConfig = (image, ratio) => {

  const aspect = ratio || (140 / 110);

  const crop = makeAspectCrop({
    x: 0,
    y: 0, aspect, width: image.width
  })

  if (crop.height > image.height) {
    crop.width = crop.width * (image.height / crop.height)
    crop.height = image.height
  }

  if (crop.width > image.width) {
    crop.height = crop.height * (image.width / crop.width)
    crop.width = image.width
  }


  const result = {
    unit: '%',
    aspect: crop.aspect,
    x: crop.x / image.width * 100,
    y: crop.y / image.height * 100,
    width: crop.width / image.width * 100,
    height: crop.height / image.height * 100,
  };



  return result

}

class CropImageModal extends React.PureComponent {
  state = {
    crop: {
      unit: "px",
      x: 0,
      y: 0,
    }
  };

  handleCrop = (crop, percCrop) => {
    this.setState({ crop: percCrop });
  };

  handleImageLoaded = image => {

    const { ratio } = this.props;
    this.image = image;

    this.setState({
      crop: getCropConfig(this.image, ratio)
    });

    return false
  };

  handleReset = () => {

    const { ratio } = this.props;

    if (this.image) {
      this.setState({
        crop: getCropConfig(this.image, ratio)
      });
    }
  };

  handleSave = () => {
    const { crop } = this.state;
    const { onRequestClose, callback } = this.props;

    callback(crop);
    onRequestClose();
  };

  render() {
    const { src } = this.props;
    const { crop } = this.state;

    return (
      <Modal {...this.props}>
        <Translation>
          {t => (
            <ContentBox
              bigmodal
              centerTitle
              title={t('modals.crop.title')}
              onClick={this.handleCropClick}
              onMouseUp={this.handleCropClick}
            >
              <Content>
                {src && (
                  <ReactCrop
                    onChange={this.handleCrop}
                    onImageLoaded={this.handleImageLoaded}
                    src={src}
                    crop={crop}
                  />
                )}
              </Content>
              <Controls>
                <Button outline
                  onClick={this.handleReset}
                >{t('reset')}</Button>
                <Button primary onClick={this.handleSave} >{t('save')}</Button>
              </Controls>
            </ContentBox>
          )}
        </Translation>
      </Modal>
    );
  }
}

export default CropImageModal;
