import { createLogic } from 'redux-logic';
import {
  GET_RAFFLE_BY_ID_RESPONSE, getRaffleByIdEntriesRequestAction
} from '../modules/api';


const getRaffleByIdResponseLogic = createLogic({
  type: GET_RAFFLE_BY_ID_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      if (getState().auth.user) {
        const ids = action.payload.raffles.map(r => r.EVENTID);
        if (ids.length) {
          dispatch(getRaffleByIdEntriesRequestAction({ id: ids }))
        }
      }
    } catch (error) {
      console.log(error);
    }

    done();
  },
});

export default getRaffleByIdResponseLogic;
