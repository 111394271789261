import { createLogic } from 'redux-logic';
import { PUT_RAFFLE_BY_ID_EDIT_RESPONSE } from 'rdx/modules/api';
import { push } from 'redux-first-router';
import fetchPrivateProducts from './_fetchPrivateProducts';
import { ROUTE_TYPE_GIVEAWAY_EDIT, CREATOR_PATHS_EDIT, CREATOR_MODE_MAP } from '../../helpers/const';

const putRaffleByIdEditResponseLogic = createLogic({
  type: PUT_RAFFLE_BY_ID_EDIT_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {

      const state = getState();
      const routeType = state.location.type;


      if (routeType == ROUTE_TYPE_GIVEAWAY_EDIT && state.location.payload.EVENTID && CREATOR_MODE_MAP[state.location.payload.mode] > 0) {

        const nextStep = CREATOR_PATHS_EDIT[CREATOR_MODE_MAP[state.location.payload.mode] + 1];
        push(nextStep ? nextStep.replace('<id>', state.location.payload.EVENTID) : `/giveaways/${state.location.payload.EVENTID}`)
      }



      fetchPrivateProducts(dispatch, getState)
    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default putRaffleByIdEditResponseLogic;
