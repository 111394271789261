import { connect } from 'react-redux';
import View from './View';
import numeral from 'numeral';

const mapStateToProps = (state, ownProps) => {
  return {
    cart: state.creator.cart,
    loading: state.rest.putRaffles.sending || state.rest.putCart.sending,
    amount: state.creator.cart && state.creator.cart.line_items ? state.creator.cart.line_items.reduce((p, c) => { return p + c.quantity }, 0) : 0,
    total: numeral(state.creator.cart ? parseFloat(state.creator.cart.total) : 0).format('0.00'),
    products: state.products.products,
  };
};


export default connect(mapStateToProps)(View);