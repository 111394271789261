import React from 'react';
import Tooltip from 'common/MaterialUI/Tooltip';

import styled from 'styled-components';

const Link = styled.a`
  background: transparent;
  cursor: pointer;
`;



class TooltipLink extends React.PureComponent {
  state = { active: false };



  render() {
    const { children, linkText, placement } = this.props;
    const { active } = this.state;

    return (
      <Tooltip disableHoverListener={true} disableFocusListener title={children} placement={placement} open={children && active}>
        <Link
          onMouseOver={() => this.setState({ active: true })}
          onMouseOut={() => this.setState({ active: false })}
          onClick={() => this.setState({ active: !active })}
        >
          {linkText}
        </Link>
      </Tooltip>
    );
  }
}

export default TooltipLink;
