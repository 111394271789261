import React from 'react';
import styled from 'styled-components';
import numeral from 'i18n/numeral';
import { media } from 'styles';
import { Translation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import Button from 'common/MaterialUI/Button';
import Link from 'redux-first-router-link';

const Layout = styled.div`
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 1px 0 rgba(29, 29, 29, 0.3);

  width: 300px;
  background: url(/static/assets/images/creator/Giveaway-Claim-Banner-Mobile.png) no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 145px 20px 20px 20px;

  ${media.tablet`
    width: 630px;
  height: 175px;
  background: url(/static/assets/images/creator/Giveaway-Winner-Banner.png)  no-repeat;
  background-size: contain;
  background-position: center top;
  padding: 20px 145px;
  `} 
  
  p {
    font-size: 14px;
  }

  h2 {
    font-size: 20px;
    word-break: break-word;
  }

  > * {
    margin: 8px;
  }
`;


const View = ({ user, raffle, rank }) => {
  const formattedRank = numeral(rank).format('0o');
  return (
    <Translation>
      {t => (
        <Layout>
          <h2>{t("Raffles/Detail/ClaimBox/ClaimYourPrize.congrats", { name: user.username })}</h2>
          <p>
            <Trans i18nKey="Raffles/Detail/ClaimBox/ClaimYourPrize.description" values={{ rank: formattedRank }}>You&apos;ve made <b>{formattedRank && formattedRank}</b> place. Claim your prize(s) now.</Trans>
          </p>
          <Button outline component={Link} to={`/giveaways/${raffle && raffle.EVENTID}/claim`}>{t("Raffles/Detail/ClaimBox/ClaimYourPrize.claimprize")}</Button>
        </Layout>
      )}
    </Translation>
  );
};


const mapStateToProps = (state, ownProps) => {

  const winners = state.raffles.winners[ownProps.raffle.EVENTID];
  const userid = state.auth.user && state.auth.user.USERID
  const winner = winners && winners.find(w => w.winnerid == userid)
  const rank = winner && winner.rank


  return {
    rank
  }
}

export default connect(mapStateToProps)(View)