import React, { useState }  from 'react';
import Box from './_Box';
import Explanation from './_Explanation';
import Description from './_Description';
import QuestionText from './_QuestionText';
import TextField from 'common/MaterialUI/TextField';
import Button from 'common/MaterialUI/Button';
//import styled, { css } from 'styled-components';


const SecretCode = ({ way, waytype, handleContinue, handlePostEntrywaysEntity, toggle, ...other }) => {

  const [focus, setFocus] = useState(null);
  const [input, setInput] = useState([]);


  const handleSetInput = tempInput => {
    setInput([tempInput])
  };

  const handleContinueClick = (event) => {
    let hasAnswers = false;
    for (let index = 0; index < input.length; index++){
      if(input[index] !== null && input[index].trim() !==''){
        hasAnswers = true;
      }
    }
    if (hasAnswers) {
      handleContinue(way, null, toggle(way), undefined, JSON.stringify({'answers':input}))(event)
    }
  };

  let meta = {};

  try {
    meta = JSON.parse(way.BENEFICIARY);
  } catch (error) {
    console.log(error)
  }

  return <Box {...{ way, waytype, toggle, ...other }}
    icon={"icon-Ico34-LockClosed"}
    color={"#4ccbfd"}
    controls={(complete, loading, t) => <React.Fragment>
      <Button outline small onClick={toggle(way)}>{t("cancel")}</Button>
      <Button secondary small disabled={complete || loading || way.disabled} onClick={handleContinueClick}>{t("continue")}</Button>
    </React.Fragment>}

  ><QuestionText way={way} meta={meta} /><Description way={way} />
      <TextField 
        onFocus={() => setFocus('input')}
        active={focus == 'input'}
        onBlur={event => handleSetInput(event.target.value)}/>
   <Explanation way={way} waytype={waytype} noLink /></Box>
}


export default SecretCode