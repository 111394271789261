import React from 'react';
import styled from 'styled-components';
import ApplyPromo from './ApplyPromo';
import { Translation } from 'react-i18next';
import CouponRow from './CouponRow'
import numeral from 'i18n/numeral';
import { OrderItem } from '../Summary/OrderItem';

import { H4ExtraBoldBlack } from 'styles/Kit/H4ExtraBold';
import { H3ExtraBoldBlue } from 'styles/Kit/H3ExtraBold';
import { BodyRegularGrey } from '../../../../styles/Kit/BodyRegular';

const Layout = styled.div`
  padding: 40px;
`

const Close = styled.a`
  color: #36a6fc;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
`


const Head = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
border-bottom: solid 1px #e1e2ef;
padding-bottom: 20px;
`

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  margin-bottom: 10px;

  border-bottom: solid 2px #f0f0f0;

  font-family: Nunito;

  .vat {
    ${BodyRegularGrey}
  }

  .totalLabel {
    ${H4ExtraBoldBlack}    
  }

  
  .total {
    ${H3ExtraBoldBlue} 
  }
`;


const PubListing = styled.div`
  padding: 20px 0;
  border-bottom: solid 2px #f0f0f0;

`

const Items = ({ cart, products, amount, handleClose }) => {

  const listingItems = cart && cart.line_items && cart.line_items.filter(item => item.product && item.product.categories && item.product.categories.find(c => c.slug == "promotion"))
  const regularItems = cart && cart.line_items && cart.line_items.filter(item => item.product && item.product.categories && item.product.categories.find(c => c.slug != "promotion"))

  return <Translation>{t => <Layout>
    <Head><div><span className="icon-Ico16-Cart" /> {t("Raffles/Creator/MyOrder/Items.my_order")} ({amount})</div>
      <Close onClick={handleClose}>Close <span className="icon-Ico16-X-Medium" /></Close>
    </Head>
    {products && regularItems.map((item, i) => {
      const product = products[item.product.PRODUCTID]
      const variation = product && item.variation_id ? product.variations.find(v => v.PRODUCTID == item.variation_id) : undefined
      return <OrderItem
        item={item}
        product={product}
        variation={variation}
        key={item.id}
      />
    })}
    {listingItems && listingItems.length && <PubListing>{t("Raffles/Creator/MyOrder/Items.promotionitems")}</PubListing>}
    {products && listingItems.map((item, i) => {
      const product = products[item.product.PRODUCTID]
      const variation = product && item.variation_id ? product.variations.find(v => v.PRODUCTID == item.variation_id) : undefined
      return <OrderItem
        item={item}
        product={product}
        variation={variation}
        key={item.id}
      />
    })}
    <BottomRow>
      <div>
        <span className="totalLabel">{t('cart_totalprice')}</span>
        <br />
        <span className="vat">{t('cart_vat_inc', {
          vat: numeral(
            parseFloat(cart ? cart.total_tax : 0)).format('0.00')
        })}</span>
      </div>
      <div className="total">€ {numeral(
        parseFloat(cart ? cart.total : 0)).format('0.00')}</div>
    </BottomRow>

    {cart &&
      cart.coupon_lines.length > 0 && (
        <CouponRow coupons={cart.coupon_lines} />
      )}
    <ApplyPromo />

  </Layout >}</Translation>

}

export default Items;