import React from 'react';
import styled from 'styled-components';
import FullScreen from 'react-full-screen';
import { media } from 'styles';
import classNames from 'classnames';
import Stars from './Stars';
import { ControlBar } from './ControlBar';

const Layout = styled.div`
  background: radial-gradient(circle, #77c1f8 30%, #308af2 100%);
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  flex: 1 0 auto;

  height: 208px;

  ${media.tablet`
    height: 504px;
  `};

  &.full {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

const DURATION = 3000;
const DURATION_LONG = 5000;

export class DrawingController extends React.Component {
  constructor(props) {
    super();
    this.state = {
      fullscreen: false,
      paused: 0,
      page: 0,
      progress: 0,
      lastChecked: new Date().getTime(),
      duration: DURATION,
      remaining: DURATION,
    };
  }

  toggleFullScreen = () => {
    const { fullscreen } = this.state;
    this.setState({ fullscreen: !fullscreen });
  };

  next = () => {
    const { page } = this.state;
    const { children } = this.props;

    const nextPage =
      page < React.Children.toArray(children).length - 1 ? page + 1 : 0;

    if (nextPage != page) {
      this.setState({
        page: nextPage,
        ...this.getTiming(nextPage),
      });
    }
  };

  previous = () => {
    const { page } = this.state;
    const nextPage = page - 1 > 0 ? page - 1 : 0;

    if (nextPage != page) {
      this.setState({
        page: nextPage,
        ...this.getTiming(nextPage),
      });
    }
  };

  togglePause = () => {
    const { paused } = this.state;
    this.setState({ paused: !paused });
  };

  updateProgress = () => {
    const { duration, paused, remaining, lastChecked } = this.state;
    const checkTime = new Date().getTime();
    const difference = Math.max(0, checkTime - lastChecked);
    const newRemaining = paused
      ? remaining
      : Math.max(0, remaining - difference);
    const progress = parseInt(100 * (1 - newRemaining / duration));

    if (!newRemaining) {
      this.next();
    } else {
      this.setState({
        remaining: newRemaining,
        progress,
        lastChecked: checkTime,
      });
    }

    this.frameId = requestAnimationFrame(this.updateProgress);
  };

  getDuration = page => {
    return page > 1 &&
      page < React.Children.toArray(this.props.children).length - 1
      ? DURATION_LONG
      : DURATION;
  };

  getTiming = page => {
    return {
      lastChecked: new Date().getTime(),
      duration: this.getDuration(page),
      remaining: this.getDuration(page),
    };
  };

  componentDidMount() {
    this.setState(this.getTiming());
    this.frameId = requestAnimationFrame(this.updateProgress);
  }
  componentWillUnmount() {
    if (this.frameId) {
      cancelAnimationFrame(this.frameId);
    }
  }

  render() {
    const { toggleFullScreen, togglePause, next, previous } = this;
    const { fullscreen, progress, page, paused } = this.state;

    const { children } = this.props;

    const childArray = React.Children.toArray(children);

    const activeChildWithProps = childArray
      .filter((c, i) => i == page)
      .map(child =>
        React.cloneElement(child, {
          paused: paused,
          next: next,
          previous: previous,
          duration: this.getDuration(page),
          fullscreen: fullscreen,
        }),
      );

    return (
      <FullScreen
        enabled={fullscreen}
        onChange={fullscreen => this.setState({ fullscreen })}
      >
        <Layout className={classNames({ full: fullscreen })}>
          {activeChildWithProps}
          <Stars disabled={page == childArray.length - 1} />
          <ControlBar
            {...{
              next,
              previous,
              togglePause,
              toggleFullScreen,
              progress,
              paused,
            }}
          />
        </Layout>
      </FullScreen>
    );
  }
}
