import { PUT_USERS_SETTINGS_RESPONSE, getNotificationsRequestAction } from '../modules/api';
import { createLogic } from 'redux-logic';
import addRegionInfoToUser from './_addRegionInfoToUser';

const putUsersSettingsResponseLogic = createLogic({
  type: [PUT_USERS_SETTINGS_RESPONSE],

  process({ getState, action }, dispatch, done) {
    try {
      const state = getState();
      if (state.auth.user) {
        dispatch(getNotificationsRequestAction({ language: state.ui.language }));
      }

      addRegionInfoToUser(dispatch, getState)


    } catch (error) {
      console.log(error)
    }
    done();
  },
});

export default putUsersSettingsResponseLogic;