import { css } from 'styled-components';
import { blue, red, green, grey, white, black } from './colors';

const H5ExtraBold = css`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 800;
`;
export const H5ExtraBoldBlue = css`
  ${H5ExtraBold}
  color: ${blue};
`;

export const H5ExtraBoldRed = css`
  ${H5ExtraBold}
  color: ${red};
`;

export const H5ExtraBoldGreen = css`
  ${H5ExtraBold}
  color: ${green};
`;

export const H5ExtraBoldGrey = css`
  ${H5ExtraBold}
  color: ${grey};
`;

export const H5ExtraBoldWhite = css`
  ${H5ExtraBold}
  color: ${white};
`;

export const H5ExtraBoldBlack = css`
  ${H5ExtraBold}
  color: ${black};
`;
