import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Loading } from './Loading';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: solid 2px #f0f0f0;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  min-height: 155px;

  background-color: #ffffff;
  background-image: url(/static/assets/images/splash.svg);
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px 40px;

  &.noBorder {
    border: none;
    padding: 0 40px;
  }

  &.dark {
    background-image: url(/static/assets/images/splash-dark.png);
    background-size: 191px 155px;
    background-color: transparent;
  }

  &.transparent {
    background-color: transparent;
  }

  &.small {
    transform: scale(0.75);
  }

  text-align: center;
`;

class EmptyBox extends React.PureComponent {
  render() {
    const {
      children,
      loading,
      noBorder,
      dark,
      disabled,
      transparent,
      small,
    } = this.props;
    return disabled ? null : (
      <Layout className={classNames({ noBorder, dark, transparent, small })}>
        {loading ? <Loading /> : children}
      </Layout>
    );
  }
}

export default EmptyBox;
