import React from 'react';
import { Trans } from 'react-i18next';
import styled, { css } from 'styled-components';
import { BodySmallRegularBlack } from 'styles/Kit/BodySmallRegular';
import i18n from 'i18n';
import { utils } from 'helpers/utils';

const Link = styled.a`
    color: ${({ complete }) => complete ? '#fff' : '#36a6fc'};
    font-weight: bold;
    text-overflow: ellipsis;
    flex: 1 1 auto;
    border-bottom: solid 1px transparent;
    cursor: pointer;
  
    :hover {
      border-bottom: solid 1px ${({ complete }) => complete ? '#fff' : '#36a6fc'};
    }

    ${({ noLink }) => noLink && css`
      pointer-events: none;
      color: black;  
    `}

    ${({ noLink }) => !noLink && css`

      :after {
        content: "\\e91e";
        font-family: icomoon;
        margin-left: 3px;
        font-size: 10px;
        padding: 0 2px 0 1px;
      }
    `}


  `;


const Layout = styled.div`
    ${BodySmallRegularBlack}
    text-align: center;
    padding: 10px;

`

const Explanation = ({ way, waytype, noLink, displayName }) => {

  return <Layout ><Trans
    i18nKey={`waytypes.${way.WAYTYPEID}.explanation`}
    values={{
      displayName: displayName || waytype.entrylabel || way.beneficiary_display_name,
      entries: i18n.t('entry', { count: way.amount })
    }}
  >You will receive <b>5 entries</b>, if you follow <Link noLink={noLink}
    onClick={event => event.stopPropagation()}
    href={utils.socialLink(way)}
    target="_blank"
    rel="noopener noreferrer"
  >x</Link>on y</ Trans></Layout >
}

export default Explanation;