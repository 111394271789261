import React from 'react';
import Box from './_Box';
import Button from 'common/MaterialUI/Button';
import Explanation from './_Explanation';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Description from './_Description';
import { Translation } from 'react-i18next';

const GiveawayInputLink = styled.div`
  width: 100%;
  min-height: 34px;
  border-radius: 3px;
  box-shadow: inset 0 2px 0 0 #eaedf3;
  border: solid 1px rgba(150, 151, 161, 0.5);
  background-color: #fff;
  padding: 6px;

  text-align: center;

  a:first-child {
    font-weight: bold;
  }
`

const EnterOtherGiveaway = ({ giveaway, toggle, way, waytype, handlePostEntrywaysEntity, handleContinue, ...other }) => {
  return <Translation>{t => (<Box {...{ way, waytype, toggle, ...other }} icon={'icon-Ico16-Star'} color={'#fc521e'} controls={(complete, loading) => <React.Fragment>
    <Button outline small onClick={toggle(way)}>{t("cancel")}</Button>
    <Button secondary small disabled={complete || loading} onClick={handleContinue(way, null, toggle(way))}>{t("continue")}</Button>
  </React.Fragment>}
  ><Description way={way} /><Explanation way={way} waytype={waytype} />{giveaway && <GiveawayInputLink><a href={`/giveaways/${giveaway.EVENTID}`} target="_blank"
    rel="noopener noreferrer">{giveaway.eventname}</a> {t("by")} <b>{giveaway.host_info.username}</b></GiveawayInputLink>}</Box>)}</Translation>
}

const mapStateToProps = (state, ownProps) => {

  const { way } = ownProps;
  return {
    giveaway: way && state.raffles.raffles[way.BENEFICIARY]
  }
}




export default connect(mapStateToProps)(EnterOtherGiveaway)