import { connect } from 'react-redux';
import { compose } from 'redux';
import View from './View';
import { withForm } from 'hoc/withForm';
import { onSubmitFail } from 'helpers/forms';
import { postVerificationGeneratePromise } from 'rdx/modules/api/logics';
import { change } from 'redux-form';
import utils from 'helpers/utils';
import { showNotificationModal } from 'rdx/modules/modals';


const alreadySent = [];

const validate = values => {
  const errors = {};
  if (!values._verified) {
    errors.email = "forms.validation_verified_email"
  }
  return errors;
};

const onSubmit = ({ _verified, contact_mail, ...other }, dispatch) => {
  dispatch((dispatch, getState) => {

    return postVerificationGeneratePromise({ payload: { mail: contact_mail, path: getState().location.pathname } }, dispatch).then(() => {
      alreadySent.push(contact_mail)
      dispatch(change("contactEmail", "_verified", false))

    })
  })
};

const mapStateToProps = (state, ownProps) => {

  const queryParams = typeof window != 'undefined' ? utils.getObjectFromQueryString(location.search) : {}
  const currentContactMail = state.form.contactEmail && state.form.contactEmail.values && state.form.contactEmail.values.contact_mail;
  const verified = state.auth.user.contact_mail && currentContactMail == state.auth.user.contact_mail

  return {
    queryParams,
    loading: state.auth.loading,
    language: state.ui.language,
    alreadySent: alreadySent.indexOf(currentContactMail) != -1,
    verified,
    empty: !currentContactMail,
    contact_mail: state.auth.user.contact_mail,
    initialValues: {
      contact_mail: state.auth.user.contact_mail,
      _verified: verified,
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showNotification: (config) => {
      dispatch(showNotificationModal({
        title: 'modals.notifications.oh_snap',
        translate: false,
        ...config
      }))
    },
  }
}


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withForm({
    form: 'contactEmail',
    validate,
    onSubmit,
    onSubmitFail,
    destroyOnUnmount: true,
    asyncBlurFields: [],
    asyncChangeFields: [],
    keepDirtyOnReinitialize: true
  }),
);




export default enhance(View);