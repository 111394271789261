import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';

const StyledStepConnector = styled.div`
  border-top: dotted 2px #e1e2ef;
  min-width: 38px;
  height: 1px;    
  transform: translateY(100%);
`


const StepperStyle = {
  root: {
    padding: '24px 0',
  }
}


const StyledStepLabel = ({ children, ...props }) => (<Stepper {...props} connector={<StyledStepConnector />}>{children}</Stepper>);

export default withStyles(StepperStyle)(StyledStepLabel);