import { connect } from 'react-redux';
import { MODAL_PARTNER_APPLY, modalAction } from 'rdx/modules/modals';
import View from './View';
import { loginRequestAction } from 'rdx/modules/auth';
import isAuthed from '../../store/selectors/isAuthed';

const mapStateToProps = (state, ownProps) => {
  return {
    authed: isAuthed(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    login: () => dispatch(loginRequestAction()),
    openBetaSignup: () => {
      dispatch(modalAction({ modal: MODAL_PARTNER_APPLY }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);
