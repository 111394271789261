import {
  GET_USERS_SETTINGS_RESPONSE,
  PUT_USERS_SETTINGS_RESPONSE,
} from "rdx/modules/api";
import { combineActions, createAction, handleActions } from "redux-actions";
import { LOCALES } from "../../../helpers/const";

export const UI_CHANCE_ATTR = "ui/CHANGE_ATTR";
export const uiChangeAttributeAction = createAction(UI_CHANCE_ATTR);
export const UI_CHANGED_ROUTE = "ui/CHANGED_ROUTE";
export const uiChangeRouteAction = createAction(UI_CHANGED_ROUTE);
export const UI_INIT_STICKY_BOTTOM_ANCHOR = "ui/INIT_STICKY_BOTTOM_ANCHOR";
export const uiInitStickyBottomAnchor = createAction(
  UI_INIT_STICKY_BOTTOM_ANCHOR
);
export const UI_INIT_AD_SIDERAIL_LEFT = "ui/INIT_AD_SIDERAIL_LEFT";
export const uiInitAdSiderailLeft = createAction(
  UI_INIT_AD_SIDERAIL_LEFT
);
export const UI_INIT_AD_SIDERAIL_RIGHT = "ui/INIT_AD_SIDERAIL_RIGHT";
export const uiInitAdSiderailRight = createAction(
  UI_INIT_AD_SIDERAIL_RIGHT
);

export const initialState = {
  giveawayActiveTab: 1,
  language: LOCALES[0],
  disableAnalytics: false,
  cookieNotice: true,
  country: null,
  countryGuessed: false,
  cacheInfo: true,
  sendingForm: false,
  stickyBottomAnchor: {},
  adSiderailLeft: {},
  adSiderailRight: {},
};

const reloadNitropayAd = (nitropayAd) => {
  if (
    nitropayAd &&
    typeof nitropayAd.onNavigate === "function"
  ) {
    try {
      nitropayAd.onNavigate();
    } catch (e) {
      console.log(e);
      console.log(nitropayAd);
    }
  }
  return nitropayAd;
};

const uiReducer = handleActions(
  {
    [UI_CHANGED_ROUTE]: (state) => ({
      ...state,
      stickyBottomAnchor: reloadNitropayAd(state.stickyBottomAnchor),
      // adSiderailLeft: reloadNitropayAd(state.adSiderailLeft),
      // adSiderailRight: reloadNitropayAd(state.adSiderailRight),
    }),
    ["@@redux-form/START_SUBMIT"]: (state, action) => ({
      ...state,
      sendingForm: true,
    }),
    ["@@redux-form/STOP_SUBMIT"]: (state, action) => ({
      ...state,
      sendingForm: false,
    }),
    [UI_CHANCE_ATTR]: (state, action) => ({ ...state, ...action.payload }),
    [combineActions(
      GET_USERS_SETTINGS_RESPONSE,
      PUT_USERS_SETTINGS_RESPONSE
    )]: (state, action) => ({
      ...state,
      language:
        action.payload.preferences.ui_language || state.language || LOCALES[0],
    }),
    [UI_INIT_STICKY_BOTTOM_ANCHOR]: (state) => ({
      ...state,
      stickyBottomAnchor: window.nitroAds.createAd("sticky-bottom-anchor", {
        refreshTime: 30,
        format: "anchor",
        anchor: "bottom",
        anchorPersistClose: false,
        mediaQuery: "(min-width: 0px)",
      }),
    }),
    // [UI_INIT_AD_SIDERAIL_LEFT]: (state) => ({
    //   ...state,
    //   adSiderailLeft: window.nitroAds.createAd("ad-siderail-left", {
    //     refreshTime: 30,
    //     format: "rail",
    //     rail: "left",

    //     railOffsetTop: 71,
    //     railOffsetBottom: 0,
    //     railCollisionWhitelist: ["*"],
    //     sizes: [
    //       [
    //         "160",
    //         "600"
    //       ],
    //       [
    //         "160",
    //         "600"
    //       ]
    //     ],
    //     mediaQuery: "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px)",
    //   }),
    // }),
    // [UI_INIT_AD_SIDERAIL_RIGHT]: (state) => ({
    //   ...state,
    //   adSiderailLeft: window.nitroAds.createAd("ad-siderail-right", {
    //     refreshTime: 30,
    //     format: "rail",
    //     rail: "right",
    //     railOffsetTop: 71,
    //     railOffsetBottom: 0,
    //     railCollisionWhitelist: ["*"],
    //     sizes: [
    //       [
    //         "160",
    //         "600"
    //       ],
    //       [
    //         "160",
    //         "600"
    //       ]
    //     ],
    //     mediaQuery: "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px)",
    //   }),
    // }),
  },
  initialState
);

export default uiReducer;
