import React from 'react';
import styled, { css } from 'styled-components';
import { Swipeable } from 'react-swipeable';
import classNames from 'classnames';
import { utils } from '../../helpers/utils';
import { SliderContext } from '.';

const SliderWrapper = styled.div.attrs(props => {
  return {
    style: {
      padding: `${props.margin}px`,
      margin: `0 -${props.margin}px`,
      width: `calc(100% + ${props.margin}px)`,
    }
  }
})`
  flex: 1 0 auto;

  display: block;
  overflow: hidden;
  position: relative;
  height: 420px;

  > div:not(:first-child) {
    opacity: 0;
    transform: translateY(100%);
  }
  :hover {
    > div:not(:first-child) {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const SlidesLayout = styled.div.attrs(props => {
  return {
    style: {
      transform: `translateX(${props.current * -100}%) translateX(${
        props.current > 0 ? -props.current * (props.itemSpacing * 2) : 0
        }px) translateX(${-props.swiping}px)`,
      transition: props.swiping == 0 ? `all .4s ease-out` : '',
    }
  }
})`
  display: flex;
  width: 100%;
  position: relative;
`;

const childMargin = css`
  > * {
    margin: 0 ${props => props.itemSpacing}px;
  }
`;

const Slide = styled.div.attrs(props => {
  return {
    style: {
      marginLeft: -props.itemSpacing,
      left: `calc(${props.index * 100}% + ${
        props.index > 0 ? props.index * (props.itemSpacing * 2) : 0
        }px)`,
    }
  }
})`
  position: absolute;
  display: flex;

  ${childMargin};
`;

class Swiped extends React.PureComponent {
  state = {
    isFirefox:
      typeof navigator != 'undefined'
        ? navigator.userAgent.toLowerCase().indexOf('firefox') > -1
        : false,
  };

  render() {
    const {
      current,
      swiping,
      itemSpacing,
      chunkedChildren,
      mouseUpDisabled,
    } = this.props;
    const { isFirefox } = this.state;

    return (
      <SlidesLayout
        draggable={isFirefox ? true : undefined}
        {...{ current, swiping, itemSpacing }}
      >
        {chunkedChildren.map(
          (chunk, i) =>
            i <= current + 1 && i >= current - 1 ? (
              <Slide key={i} index={i} {...{ itemSpacing }}>
                {chunk.map(element => {
                  return React.cloneElement(element, {
                    swiping: swiping != 0,
                    mouseUpDisabled,
                  });
                })}
              </Slide>
            ) : null,
        )}
      </SlidesLayout>
    );
  }
}

class InnerItems extends React.PureComponent {
  state = { pages: 0, itemsPerPage: 0, chunkedChildren: [], totalWidth: 0 };

  static getDerivedStateFromProps(props, prevState) {
    if (props.updateSlides) {
      props.updateSlides(prevState.pages);
    }
    return prevState;
  }

  componentDidMount() {
    this.frameId = requestAnimationFrame(this.updatePagesFromAvailableWidth);
  }

  componentWillUnmount() {
    if (this.frameId) {
      cancelAnimationFrame(this.frameId);
    }
  }

  updatePagesFromAvailableWidth = () => {
    const { slidesLayout } = this;
    if (!slidesLayout) {
      return;
    }

    const { children, itemWidth, itemSpacing } = this.props;

    const containerWidth =
      this.slidesLayout.getBoundingClientRect().width + itemSpacing * 2;

    const itemsPerPage = Math.max(
      Math.floor(containerWidth / (itemWidth + itemSpacing * 2)),
      1,
    );

    const itemCount = React.Children.count(children);
    const pages = Math.ceil(itemCount / itemsPerPage);

    const chunkedChildren = utils.chunk(
      React.Children.toArray(children),
      itemsPerPage,
    );
    this.setState({
      pages,
      itemsPerPage,
      chunkedChildren,
    });

    this.frameId = requestAnimationFrame(this.updatePagesFromAvailableWidth);
  };

  render() {
    const {
      onMouseOver,
      onMouseOut,
      onSwiping,
      onSwiped,
      slides,
      itemSpacing,
      margin,
    } = this.props;

    const { chunkedChildren } = this.state;
    const { current, swiping, mouseUpDisabled, controls } = this.props;

    return (
      <SliderWrapper
        className={classNames('slider', {
          active: slides > 0,
        })}
        {...{ onMouseOver, onMouseOut, margin }}
        ref={comp => (this.slidesLayout = comp)}
      >
        <Swipeable onSwiping={onSwiping} onSwiped={onSwiped} trackMouse={true}>
          <Swiped
            {...{
              chunkedChildren,
              current,
              swiping,
              mouseUpDisabled,
              itemSpacing,
            }}
          />
        </Swipeable>
        {controls && controls}
      </SliderWrapper>
    );
  }
}

class Items extends React.PureComponent {
  static defaultProps = {
    itemWidth: 300,
    itemSpacing: 15,
    margin: 10,
  };

  render() {
    const { children } = this.props;
    return (
      <SliderContext.Consumer>
        {sliderState => {
          return (
            <InnerItems {...sliderState} {...this.props}>
              {children}
            </InnerItems>
          );
        }}
      </SliderContext.Consumer>
    );
  }
}

export default Items;
