import styled from 'styled-components';
import React from 'react';
import { media } from 'styles';
import EmptyBox from 'common/EmptyBox';
import { getEventBackground } from 'helpers/giveaways';
import RaffleMainBox from '../Detail/MainBox';
import Meta from '../Detail/Meta';
import Store from '../../../helpers/store';

import {
  EVENT_BACKGROUND_TEMPLATE_FIXED,
  EVENT_BACKGROUND_TEMPLATE_SCROLL,
} from '../Detail/styles';

const Layout = styled.div`
  ${props =>
    props.backgroundAttachment == 'scroll'
      ? EVENT_BACKGROUND_TEMPLATE_SCROLL
      : EVENT_BACKGROUND_TEMPLATE_FIXED} display: flex;
  flex: 1 0 auto;
  flex-direction: column;
`;

const RaffleArea = styled.div`
  display: flex;
  flex-direction: column;

  align-items: stretch;
  flex: 1 0 auto;

  padding: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  flex: 1 1 auto;
  padding: 180px 20px 74px;

  ${media.tablet`
padding: 180px 40px 74px;
`};
`;

const EmptyHolder = styled.div`
  transform: translateY(80px);
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

class PreviewComponent extends React.PureComponent {

  state = {
    raffle: null
  }

  handleUpdate = () => {

    const raffle = Store.get("preview-raffle");

    if (raffle) {
      this.props.refreshProducts(raffle)
    }

    this.setState({ raffle })
    this.timer = setTimeout(() => {
      this.handleUpdate();
    }, 2000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }


  componentDidMount() {
    this.handleUpdate();
  }

  render() {
    const {
      raffle,

    } = this.state;

    const backgroundInfo = getEventBackground(raffle) || {};

    return (
      <Layout {...backgroundInfo}>
        <Meta raffle={raffle} />
        <RaffleArea>
          <Content>
            {raffle ? (
              <RaffleMainBox
                preview
                sixthirty
                {...{
                  raffle,
                }}
              />
            ) : (
                <EmptyHolder>
                  <EmptyBox transparent dark noBorder loading="true" />
                </EmptyHolder>
              )}
          </Content>
        </RaffleArea>
      </Layout>
    );
  }
}




export default PreviewComponent;
