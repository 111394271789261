import React from 'react';
import styled from 'styled-components';
import { PLATFORMS, PLATFORMID_GHEED } from 'helpers/const';
import { GheedIcon } from 'common/GheedIcon';

const Icon = styled.div.attrs(props => ({
  style: {
    color: props.color,
  }
}))`
  width: 25px;
  height: 25px;
  margin-left: 5px;
    

    display: flex;
    align-items: center;
`;



export const PlatformIcon = props => {
  if (!('platformid' in props)) { return <GheedIcon /> }

  const { icon, color } = props.platformid ? (props.platformid == PLATFORMID_GHEED ? { color: '', icon: 'icon-Ico16-Messages' } : PLATFORMS[props.platformid] ) : { color: '#ffffff', icon: '' };


  return <Icon className={icon} color={color} />
};

export default PlatformIcon;