import moment from 'moment-timezone';
import numeral from 'i18n/numeral';
import {
  RAFFLE_STATE_ACTIVE,
  RAFFLE_STATE_ANNOUNCED,
  RAFFLE_STATE_FINISHED,
} from 'helpers/const';

export const helpers = {
  stopPropagation(event) {
    if (event) {
      event.stopPropagation();
    }
  },
  getGiveawayStarsByValue(value) {
    if (value > 150) {
      return 3;
    }

    if (value > 50) {
      return 2;
    }

    return 1;
  },
  getTimerColorClasses(timer) {
    if (!timer) {
      return {};
    }

    return {
      orange: timer.mode == RAFFLE_STATE_ACTIVE && timer.time.days == 0,
      green: timer.mode == RAFFLE_STATE_ACTIVE && timer.time.days > 0,
      blue: timer.mode == RAFFLE_STATE_ANNOUNCED,
      finished: timer.mode == RAFFLE_STATE_FINISHED,
    };
  },

  getTimezoneOffset() {
    return new Date().getTimezoneOffset();
  },

  getFormattedTimezone() {
    let split = new Date().toString().split(' ');
    return split[split.length - 2] + ' ' + split[split.length - 1];
  },

  weekdaysComparator(a, b, locale = 'en') {
    const weekDayOrder = {
      de: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      en: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
    };

    return weekDayOrder[locale].indexOf(a) > weekDayOrder[locale].indexOf(b);
  },

  getTimeStampISOByLabel(label) {
    if (label == 'lastMonth') {
      return moment()
        .subtract(1, 'M')
        .utc()
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    }

    if (label == 'lastThreeMonths') {
      return moment()
        .subtract(3, 'M')
        .utc()
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    }

    return null;
  },

  getFormattedNumberOrDuration: (value, dataAttr, renderer = null) => {
    switch (dataAttr) {
      case 'total_calculated_length':
      case 'avg_calculated_length':
      case 'avg_time_watched':
      case 'total_time_watched':
        return helpers.renderFormattedHoursMinutesSeconds(value, renderer);

      case 'avg_followers_per_hour':
        return numeral(value).format('0.1');

      default:
        return numeral(value).format('0,0');
    }
  },

  getRawNumberOrHours: (value, dataAttr) => {
    switch (dataAttr) {
      case 'total_calculated_length':
      case 'avg_calculated_length':
      case 'avg_time_watched':
      case 'total_time_watched':
        return value / 60 / 60;

      default:
        return value;
    }
  },

  renderFormattedHoursMinutesSeconds: (value, render, unit = 'seconds') => {
    render = !render ? d => `${d.h}:${d.m}:${d.s}` : render;
    const mom = moment.duration(value, unit);
    const h = Math.floor(mom.asHours());
    const rh = Math.round(mom.asHours());
    const m = Math.round(mom.asMinutes() - h * 60);
    return render(m < 60 ? { h: h, m: m, rh: rh } : { h: rh, m: 0, rh: rh });
  },

  getFormattedHoursFromSeconds: seconds => {
    return moment.duration(seconds, 'seconds').humanize();
  },

  getFormattedDateTime: stamp => {
    return moment(stamp).format('MMM Do YYYY, HH:mm a');
  },

  getMomentFromWeekOfYearKey: key => {
    const yw = key.split('-W');
    return moment()
      .year(yw[0])
      .week(yw[1]);
  },

  getFormattedSessionId: id => {
    return '#' + moment(id).format('YYYY-MM-DD');
  },

  getSessionToSessionLabel: (startId, endId) => {
    return (
      'ID ' +
      helpers.getFormattedSessionId(startId) +
      ' - ID ' +
      helpers.getFormattedSessionId(endId)
    );
  },

  renderDistanceToAvg: (val, avg, renderAbove, renderBelow) => {
    return val >= avg ? renderAbove(val - avg) : renderBelow(avg - val);
  },

  renderCWLabel: (
    value,
    render = d => `${d.start.format('ddd Do')} - ${d.end.format('ddd Do')}`,
  ) => {
    let data = {};

    data.moment =
      value.indexOf('-W') != -1
        ? helpers.getMomentFromWeekOfYearKey(value)
        : moment(value);
    data.start = moment(data.moment).startOf('week');
    data.end = moment(data.moment).endOf('week');
    return render ? render(data) : '';
  },

  getWeekToWeekLabel: (start, end, cw = 'CW') => {
    if (!start || !end) {
      return '...';
    }

    let s = helpers.renderCWLabel(
      start,
      d => `${cw} ${d.moment.format('W')} (${d.start.format('l')})`,
    );
    let e = helpers.renderCWLabel(
      end,
      d => `${cw} ${d.moment.format('W')} (${d.end.format('l')})`,
    );
    return `${s} - ${e}`;
  },

  getMonthLabel: value => {
    return moment(value).format('MMM YYYY');
  },

  getMonthToMonthLabel: (start, end) => {
    return helpers.getMonthLabel(start) + ' - ' + helpers.getMonthLabel(end);
  },

  compareSessionsByUTC: (a, b) => {
    return new Date(b.session) - new Date(a.session);
  },

  getLanguageCode(str) {
    return str.indexOf('-') != -1 ? str.substr(0, 2) : str;
  },

  renderLanguageItem(language, transl) {
    return `${transl} ${
      language.length && language != 'all' ? `(${language})` : ''
    }`;
  },
  stringToBoolean(string) {
    switch (string.toLowerCase().trim()) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return string;
    }
  },
};
