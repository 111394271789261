import axios from 'axios';
import { RESTAPI } from 'rdx/modules/api/rest';
import { JWT } from '../../../helpers/jwt';
import { push } from 'redux-first-router';

let isRefreshing = false;
let refreshSubscribers = [];

const handleLogout = error => {

  JWT.removeAccessToken()
  JWT.removeRefreshToken()
  push('/');
  if (typeof window != 'undefined') {
    window.location.reload();
  }

  return Promise.reject(error);
};


// Add Access Tokens
axios.interceptors.request.use(config => {

  const accessToken = JWT.getAccessToken()

  if (accessToken && !config.headers['Access-Token']) {
    config.headers = { ...config.headers, 'Access-Token': accessToken };
  }


  const refreshToken = JWT.getRefreshToken()

  if (!accessToken && refreshToken && config.url.indexOf("/jwt/refresh") == -1) {
    const originalRequest = config;
    if (!isRefreshing) {
      isRefreshing = true;

      RESTAPI.getJwtRefresh({ accessToken: refreshToken }).then((response) => {

        while (refreshSubscribers.length) {
          const cb = refreshSubscribers.shift();
          cb.resolve(response.access_token);
        }
        isRefreshing = false;
      }).catch((rError) => {
        while (refreshSubscribers.length) {
          const cb = refreshSubscribers.shift();
          cb.reject(rError);
        }
        isRefreshing = false;
        return handleLogout(rError)
      })
    }

    const retryOriginal = new Promise((resolve, reject) => {
      refreshSubscribers.push({
        resolve: token => {
          originalRequest.headers['Access-Token'] = token;
          return resolve((originalRequest));
        }, reject: (error) => reject(error)
      });
    });
    return retryOriginal;
  }

  return Promise.resolve(config)

})

