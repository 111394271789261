import React from 'react';
import styled from 'styled-components';
import { Input } from '@material-ui/core';
import { FillInputOutlineDefault } from '../../styles/Kit/Fills';

const StyledButton = styled(({ ...other }) => (
  <Input {...other} disableUnderline={true} classes={{ "root": "root" }} />
))`

  ${FillInputOutlineDefault}
  border-radius: 3px;
  height: 42px;
  max-height: 42px;
  padding:  0 10px ;
 `;



export default StyledButton;