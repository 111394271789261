import React from 'react';
import styled from 'styled-components';
import { BodySmallRegularBlue } from '../styles/Kit/BodySmallRegular';

const Layout = styled.a`
  ${BodySmallRegularBlue}
  cursor: pointer;
  white-space: nowrap;

  [class^='icon-'],
  [class*=' icon-'] {
    transform: translateY(1px);
    display: inline-block;
  }

  user-select: none;
`;

const InfoLink = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  return <Layout ref={ref} {...other}>{children} <span className="icon-Ico16-Tooltip-Questionmark" /></Layout>
})
InfoLink.displayName = "InfoLink"

export default InfoLink;