import React from 'react';
import { Translation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import language from '../../../store/selectors/language';

import Button from 'common/MaterialUI/Button';
import styled from 'styled-components'
import FullService from './FullService';
import LaunchUnlimited from './LaunchUnlimited';
import { MODAL_CONTACT_US, modalAction } from '../../../store/modules/modals';
import { scrollToQuery } from '../../../helpers/scrollTo';
import { mediaMixin } from '../../../styles';
import Link from 'redux-first-router-link';


const ButtonHolder = styled.div`
display: flex;
justify-content: center;

> *:first-child {
  margin-right: 10px;
}

${mediaMixin}

`

const GreenIcon = styled.span`
  color: #41a967;
`


const Businesses = ({ language }) => <Translation>{t => (<React.Fragment>

  <div className="ccc-quote-wrapper">
    <div className="ccc-quote-container">
      <h3 className="ccc-h3">{t("QuickTour/Businesses.ourpartnersandclients")}</h3>
      <div className="ccc-quote-row">
        <div className="ccc-quote-box ccc-contentbox">
          <a href="https://gheed.com/nvidia-adventskalender" target="_blank" rel="noopener noreferrer" className="ccc-quote-client"><img src="/static/assets/images/testimonials/client1-nvidia.png" alt=" " /></a>
        </div>
        <div className="ccc-quote-box ccc-contentbox">
          <a href="https://gheed.com/giveaways/3l36-" target="_blank" rel="noopener noreferrer" className="ccc-quote-client"><img src="/static/assets/images/testimonials/client2-amazon.png" alt=" " /></a>
        </div>
        <div className="ccc-quote-box ccc-contentbox">
          <a href="https://gheed.com/giveaways/netgear" target="_blank" rel="noopener noreferrer" className="ccc-quote-client"><img src="/static/assets/images/testimonials/client3-netgear.png" alt=" " /></a>
        </div>
      </div>
      <div className="ccc-quote-row">
        <div className="ccc-quote-box ccc-contentbox">
          <a href="https://gheed.com/hi-tech" target="_blank" rel="noopener noreferrer" className="ccc-quote-client"><img src="/static/assets/images/testimonials/client4-hitech.png" alt=" " /></a>
        </div>
        <div className="ccc-quote-box ccc-contentbox">
          <a href="https://gheed.com/giveaways/steelseries" target="_blank" rel="noopener noreferrer" className="ccc-quote-client"><img src="/static/assets/images/testimonials/client5-steelseries.png" alt=" " /></a>
        </div>
        <div className="ccc-quote-box ccc-contentbox">
          <a href="https://gheed.com/giveaways/11dk-" target="_blank" rel="noopener noreferrer" className="ccc-quote-client"><img src="/static/assets/images/testimonials/client6-corsair.png" alt=" " /></a>
        </div>
      </div>
      <div className="ccc-quote-row">
        <div className="ccc-quote-box ccc-contentbox">
          <a href="https://gheed.com/giveaways/jopV-" target="_blank" rel="noopener noreferrer" className="ccc-quote-client"><img src="/static/assets/images/testimonials/client7-roccat.png" alt=" " /></a>
        </div>
        <div className="ccc-quote-box ccc-contentbox">
          <a href="https://gheed.com/giveaways/g-430e" target="_blank" rel="noopener noreferrer" className="ccc-quote-client"><img src="/static/assets/images/testimonials/client8-mifcom.png" alt=" " /></a>
        </div>
        <div className="ccc-quote-box ccc-contentbox">
          <a href="https://gheed.com/giveaways/g-xAEJ" target="_blank" rel="noopener noreferrer" className="ccc-quote-client"><img src="/static/assets/images/testimonials/client9-palit.png" alt=" " /></a>
        </div>
      </div>
    </div>
  </div>

  <div className="ccc-introbox-wrapper">
    <div className="ccc-introbox-container">
      <img className="ccc-hide-on-desktop" src="/static/assets/images/quicktour/Quicktour-04-Business-Section1-mobile.png" alt=" " />
      <h2 className="ccc-h2">{t("QuickTour/Businesses.bringyourbrand")}</h2>
      <h4 className="ccc-h4">{t("QuickTour/Businesses.reachour")}</h4>
      <ButtonHolder from-tablet><Button large white onClick={() => scrollToQuery('#moreeffective')}>{t("QuickTour/Businesses.learnmore")}</Button> <Button primary large onClick={() => scrollToQuery('#services')}>{t("QuickTour/Businesses.ourservices")}</Button></ButtonHolder>
      <img className="ccc-hide-on-mobile" src="/static/assets/images/quicktour/Quicktour-04-Business-Section1.png" alt=" " />
    </div>
  </div>

  <div className="ccc-contentbox-wrapper ccc-contentbox-whitebg ccc-contentbox-imageright" id="moreeffective">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Businesses.moreeffective")}</h6>
          <h3 className="ccc-h3">{t("QuickTour/Businesses.whygiveaways")}</h3>
          <p className="ccc-p">{t("QuickTour/Businesses.giveawaysdistinctadvantages")}</p>
          <p className="ccc-list ccc-p"><GreenIcon className="icon-Ico16-ArrowRight-Medium" /> {t("QuickTour/Businesses.highinteraction")}<br />
            <GreenIcon className="icon-Ico16-ArrowRight-Medium" /> {t("QuickTour/Businesses.morereach")}<br />
            <GreenIcon className="icon-Ico16-ArrowRight-Medium" /> {t("QuickTour/Businesses.highlevelauthenticity")}</p>
          <hr />
        </article>
      </div>
      <div className="ccc-contentbox-column-image">
        <video playsInline autoPlay loop muted>
          <source src="/static/assets/images/quicktour/Quicktour-04-Business-Section2.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>

  <div className="ccc-contentbox-wrapper ccc-contentbox-imageleft">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-image">
        <img src={`/static/assets/images/quicktour/Quicktour-04-Business-Section3-${language}.png`} alt=" " />
      </div>
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Businesses.flexible")}</h6>
          <h3 className="ccc-h3">{t("QuickTour/Businesses.gheedgiveawayssupport")}</h3>
          <p className="ccc-p">{t("QuickTour/Businesses.youdecidewhich")}</p>
          <hr />
        </article>
      </div>
    </div>
  </div>

  <div className="ccc-contentbox-wrapper ccc-contentbox-whitebg ccc-contentbox-imageright">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Businesses.communitysharing")}</h6>
          <h3 className="ccc-h3">{t("QuickTour/Businesses.morereachcrosspromotion")}</h3>
          <p className="ccc-p">{t("QuickTour/Businesses.withgheedgiveawaysrespectivestreamers")}</p>
          <hr />
        </article>
      </div>
      <div className="ccc-contentbox-column-image">
        <img src="/static/assets/images/quicktour/Quicktour-04-Business-Section4.gif" alt=" " />
      </div>
    </div>
  </div>

  <div className="ccc-contentbox-wrapper ccc-contentbox-imageleft">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-image">
        <img src="/static/assets/images/quicktour/Quicktour-04-Business-Section6.png" alt=" " />
      </div>
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Businesses.reachnewcommunities")}</h6>
          <h3 className="ccc-h3">{t("QuickTour/Businesses.unleashmicroinfluencers")}</h3>
          <p className="ccc-p">{t("QuickTour/Businesses.withourfullservicegiveawaycampaigns")}</p>
          <hr />
        </article>
      </div>
    </div>
  </div>

  <div id="services" className="ccc-services-wrapper">
    <div className="ccc-services-container">
      <h3 className="ccc-h3">{t("QuickTour/Businesses.usegheedinavariety")}</h3>
      <FullService />
      <LaunchUnlimited />
    </div>
  </div>


  <div className="ccc-cta-wrapper">
    <div className="ccc-cta-container">
      <h2 className="ccc-h2">{t("QuickTour/Businesses/index.starttoday")}</h2>
      <h4 className="ccc-h4">{t("QuickTour/Businesses/index.benefit")}</h4>
      <ButtonHolder>
        <Button component={Link} to={"/contact"} white>{t("QuickTour/Businesses/index.sendinquiry")}</Button>
      </ButtonHolder>
    </div>
  </div>
</React.Fragment>)}</Translation>


const mapStateToProps = (state, ownProps) => {
  return {
    language: language(state)
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleContact: () => {
      dispatch(modalAction({ modal: MODAL_CONTACT_US }));
    },
  }
}



const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(Businesses)
