import throttle from 'lodash/throttle';

export const getViewport = () => {
  if (typeof window != 'undefined') {
    var windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    var windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    return { width: windowWidth, height: windowHeight };
  }
  return { width: 1920, height: 1080 };
};

const resizeListeners = [];
const onResize = throttle(() => {
  resizeListeners.forEach(listener => {
    listener(getViewport());
  });
}, 200);

if (typeof window != 'undefined') {
  window.addEventListener('resize', onResize, { passive: true });
}

export const addResizeListener = listener => {
  resizeListeners.push(listener);
  listener(getViewport());
};

export const removeResizeListener = listener => {
  let i = resizeListeners.indexOf(listener);
  if (i != -1) {
    resizeListeners.splice(i, 1);
  }
};
