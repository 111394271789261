import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { utils } from 'helpers/utils';

const rotation = keyframes`
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }

  50% {
    opacity: 1;
    
  }

  100% {
    opacity: 0;
    transform: rotate(359deg);
  }
`;

const animationRule = css`${rotation} ${props => props.duration}ms infinite ease-out`;


const Outer = styled.div.attrs(props => ({style: {
    transform: `scale(${props.scale}) translate(${props.x}px,${props.y}px)`,
  }}))`
  position: absolute;
`;

const Inner = styled.div`
  animation: ${animationRule};
  color: #ffe835;
  position: absolute;
`;

class Star extends React.PureComponent {
  componentDidMount() {
    this.timer = setTimeout(() => {
      this.props.handleComplete(this.props.starId);
    }, this.props.duration);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  render() {
    return (
      <Outer {...this.props}>
        <Inner {...this.props} className="icon-Ico16-StarSmall" />
      </Outer>
    );
  }
}

let counter = 1;

class Stars extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { stars: [] };
  }

  addSomeStars() {
    if (this.props.disabled) {
      return;
    }

    const starsToAdd = utils.randomInt(0, 3);
    const { stars } = this.state;

    const newStars = [];
    const expires = new Date().getTime();

    for (let index = 0; index < starsToAdd; index++) {
      const duration = utils.randomInt(1000, 2500);
      const delay = utils.randomInt(0, 500);

      newStars.push({
        id: counter,
        x: utils.randomInt(-400, 400),
        y: utils.randomInt(-400, 400),
        scale: utils.randomInt(50, 90) / 100,
        duration: duration,
        delay: delay,
        expires: expires + duration,
      });
      counter++;
    }

    this.setState({
      stars: [...stars, ...newStars],
    });
  }

  handleComplete = id => {
    this.setState({
      stars: [...this.state.stars].filter(s => s.id != id),
    });
  };

  componentDidMount() {
    this.addSomeStars();

    this.interv = setInterval(() => {
      this.addSomeStars();
    }, 500);
  }

  componentWillUnmount() {
    if (this.interv) {
      clearInterval(this.interv);
    }
  }

  render() {
    const { stars } = this.state;

    return (
      <React.Fragment>
        {stars.map((s, i) => (
          <Star
            duration={s.duration}
            x={s.x}
            y={s.y}
            scale={s.scale}
            starId={s.id}
            key={s.id}
            handleComplete={this.handleComplete}
          />
        ))}
      </React.Fragment>
    );
  }
}

export default Stars;
