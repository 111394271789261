import React from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles';
import { utils } from '../../../../../helpers/utils';
import { Pagination } from '../../../../../common/Pagination';
import Winner from './Winner';
import { Translation } from 'react-i18next';

const Layout = styled.div`
  border-top: solid 2px #f0f0f0;
  background-color: rgba(234, 237, 243, 0.25);
  flex: 1 0 auto;
  padding: 40px 0 20px;
`;

const Content = styled.div`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.36;
  margin: 0 40px;

  ${media.tabletMax`
  margin: 0 20px;
  `};

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Wrapper = styled.div`
  padding-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  margin-bottom: 20px;

  th:first-child,
  td:first-child {
    width: 80%;
  }

  th:last-child,
  td:last-child {
    text-align: center;
  }

  th {
    padding-bottom: 5px;
    border-bottom: solid 2px #f0f0f0;
    font-family: Nunito;
    font-size: 11px;
    font-weight: bold;
    color: #9c9c9c;
    text-transform: uppercase;
    white-space: nowrap;
  }

  td {
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: solid 2px #f0f0f0;

    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export default class View extends React.PureComponent {
    static getDerivedStateFromProps(props, state) {
        return {
            chunkedWinners: props.winners ? utils.chunk(props.winners, 10) : [],
            page: state ? state.page : 0,
        };
    }

    render() {
        const { chunkedWinners, page } = this.state;

        return (
            <Translation>{t => <Layout>
                <Wrapper>
                    {chunkedWinners &&
                        chunkedWinners.length > 0 && (
                            <Content>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className="first sc-krvtoX gjaqat">
                                                <span className="sc-fYiAbW iQSoUp">{t('ga_winners_user')}</span>
                                            </th>
                                            <th className="sc-krvtoX gjaqat">
                                                <span className="sc-fYiAbW iQSoUp">{t('ga_winners_rank')}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chunkedWinners[page].map(winner => (
                                            <Winner key={winner.rank} winner={winner} />
                                        ))}
                                    </tbody>
                                </Table>
                                <Pagination
                                    total={chunkedWinners.length}
                                    active={page + 1}
                                    onChange={page => this.setState({ page: page - 1 })}
                                />
                            </Content>
                        )}
                </Wrapper>
            </Layout>}</Translation>
        );
    }
}
