import React from 'react';
import styled, { css } from 'styled-components';
import numeral from 'i18n/numeral';
import PlatformIcon from 'common/PlatformIcon';

const Layout = styled.tr``;

const RankBaseMixin = css`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
  width: 30px;
  height: 30px;

  :before {
    display: flex;
    justify-content: center;
    padding-top: 6px;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 1px 0 rgba(29, 29, 29, 0.3);
  }
`;

const RankFirst = styled.div`
  background: url(/static/assets/images/ico-pokal-gold.svg);
  
  ${RankBaseMixin};
  
  :before {
    content '1';
    margin-top: -5px;

  }
`;

const RankSecond = styled.div`
  background: url(/static/assets/images/ico-pokal-silber.svg);
  
  ${RankBaseMixin};
  
  :before {
    content '2';
    margin-top: -5px;
  }
`;

const RankThird = styled.div`
  background: url(/static/assets/images/ico-pokal-bronze.svg);
  
  ${RankBaseMixin};
  
  :before {
    content '3';
    margin-top: -5px;
  }
`;

const RankRangeLayout = styled.div`
  color: #4ccbfd;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;

  h3 {
    word-wrap: break-word;
    white-space: pre;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;


const RankRange = ({ first, last }) => {
  return (
    <RankRangeLayout>
      <span>
        {numeral(first).format('0o')}
        {first != last && `-${numeral(last).format('0o')}`}
      </span>{' '}
    </RankRangeLayout>
  );
};

const Winner = ({ winner }) => {
  const { rank, winnername, primary_platformid, has_custom_username } = winner;

  return (
    <Layout>
      <td>
        <Flex>
          {has_custom_username ? <PlatformIcon /> : <PlatformIcon platformid={primary_platformid} />}
          <h3>{winnername}</h3>
        </Flex>
      </td>
      <td>
        <FlexCenter>
          {rank == 1 && <RankFirst />}
          {rank == 2 && <RankSecond />}
          {rank == 3 && <RankThird />}

          {rank > 3 && <RankRange first={rank} last={rank} />}
        </FlexCenter>
      </td>
    </Layout>
  );
};

export default Winner;
