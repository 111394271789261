import React from 'react';
import styled from 'styled-components';
import RaffleSlider from 'common/RaffleSlider';

import SliderWrapper from 'common/RaffleSlider/SliderWrapper';
import { Translation } from 'react-i18next';
import { EnteredGiveaways } from '../EnteredGiveaways';
import { MyLoyaltyCoins } from '../MyLoyaltyCoins';
import { PageLayout } from '../../../common/PageLayout';
import CampaignBanner from 'components/Campaign/Banner';



const TopLayout = styled.div`
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  :last-child {
    padding-bottom: 46px;
  }
`;

const Wrapper = ({ children }) => (
  <Translation>
    {t => (
      <SliderWrapper
        background={'#e1e2ef'}
        to={`/giveaways`}
        title={t('dashboard_exciting_new')}
      >
        {children}
      </SliderWrapper>
    )}
  </Translation>
);

const View = ({ raffles, user, loading, pathname, showCoins }) => {

  if (!user || !user.USERID) {
    return <PageLayout />
  }
  return (
    <PageLayout centered stretched noPadding>
      <TopLayout>
        <CampaignBanner placement="dashboard" marginBottom={50} />
        <EnteredGiveaways />
        {showCoins && <MyLoyaltyCoins />}
      </TopLayout>

      <RaffleSlider
        list="viewerDashboard"
        wrapper={Wrapper}
        config={{
          orderbyPath: 'mostpopular',
          filterPath: 'active',
          limit: 20,
          mode: 'preview',
          featuredIncluded: true
        }}
      />
    </PageLayout>
  );
};

export default View;
