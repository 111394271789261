import { H5ExtraBoldBlack } from '../../styles/Kit/H5ExtraBold';
import { BoxHeader } from '../../styles/Kit/Fills';
import styled from 'styled-components';

const PopoverHeader = styled.div`

  min-width: 320px;

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 10px 20px;

  ${BoxHeader}
  ${H5ExtraBoldBlack}

`

export default PopoverHeader;

