import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from 'common/MaterialUI/Tab';

import { ROUTE_TYPE_DASHBOARD_STREAMER, ROUTE_TYPE_DASHBOARD_VIEWER } from '../../helpers/const';
import { push } from 'redux-first-router';
import { Translation } from 'react-i18next';

const Wrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);
  }

  padding: 0 20px;


${media.widescreen`
padding: 0;

`}
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1290px;
  width: 100%;
  justify-content: center;
`;



const SubmenuDashboard = ({ routeType }) => <Translation>{t => (<Wrapper><InnerWrapper><Tabs centered
  value={routeType ? routeType : ROUTE_TYPE_DASHBOARD_VIEWER}
  indicatorColor="secondary"
  textColor="primary"
  onChange={(event, value) => push(value == ROUTE_TYPE_DASHBOARD_STREAMER ? '/dashboard/streamer' : '/dashboard/viewer')}
>
  <Tab label={t("Navigation/SubmenuDashboard.streamer")} value={ROUTE_TYPE_DASHBOARD_STREAMER} />
  <Tab label={t("Navigation/SubmenuDashboard.viewer")} value={ROUTE_TYPE_DASHBOARD_VIEWER} />
</Tabs></InnerWrapper></Wrapper>)}</Translation>


export default SubmenuDashboard