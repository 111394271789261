import React from 'react';
import { connect } from 'react-redux';
import { raffleDynamicListRequestAction } from '../../store/modules/giveawayLists';
import View from './View';

class RaffleSlider extends React.PureComponent {
  componentDidMount() {
    const { load } = this.props;
    load();
  }

  render() {
    const { items } = this.props;

    return <View {...{ raffles: items, ...this.props }} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const openEventId = state.location ? state.location.payload.EVENTID : null;
  const list = state.giveawayLists[ownProps.list]
    ? state.giveawayLists[ownProps.list].data
    : null;

  return {
    items:
      openEventId && list
        ? list.filter(raffle => raffle.EVENTID != openEventId)
        : list,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (page, limit) => {
      return dispatch(
        raffleDynamicListRequestAction([ownProps.config, ownProps.list]),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RaffleSlider);
