import Home from 'components/Home';
import Contact from 'components/Contact';

import Conditions from 'components/Conditions';
import Partnership from 'components/Partnership/module';
import Imprint from 'components/Imprint';
import Privacy from 'components/Privacy';
import Settings from 'components/Settings/module';
import Guide from 'components/Guide';
import Terms from 'components/Terms';
import Dashboard from 'components/Dashboard/route';

import QuickTour from 'components/QuickTour/route';
import { STREAMERS_ROUTES } from './streamers';
import { RaffleRoutes } from './raffles';
import CampaignRoutes from './campaigns';
import { ROUTE_TYPE_HOME, ROUTE_TYPE_IMPRINT, ROUTE_TYPE_PRIVACY, ROUTE_TYPE_TERMS, ROUTE_TYPE_CONDITIONS, ROUTE_TYPE_SETTINGS, ROUTE_TYPE_GUIDE, ROUTE_TYPE_SETTINGS_SETUP , ROUTE_TYPE_PARTNERSHIP, ROUTE_TYPE_CONTACT } from 'helpers/const';
import CreatorRoutes from './creator';
import Setup from 'components/Settings/Setup';

import Kit from 'components/Kit/route';



export const NAVIGATION_CONFIG = {
  [ROUTE_TYPE_HOME]: {
    path: '/',
    title: 'A giveaway a day keeps the doctor away!',
    page: Home,
  },
  [ROUTE_TYPE_CONTACT]: {
    path: '/contact',
    title: 'Contact us!',
    page: Contact,
  },
  [ROUTE_TYPE_IMPRINT]: {
    path: '/imprint',
    title: 'Imprint',
    page: Imprint,
  },
  [ROUTE_TYPE_PRIVACY]: {
    path: '/privacy',
    title: 'Privacy Policy',
    page: Privacy,
  },
  [ROUTE_TYPE_TERMS]: {
    path: '/terms',
    title: 'Terms of Service',
    page: Terms,
  },
  [ROUTE_TYPE_CONDITIONS]: {
    path: '/conditions',
    title: 'Participation Conditions',
    page: Conditions,
  },
  [ROUTE_TYPE_SETTINGS]: {
    path: '/settings',
    title: 'Account Settings',
    page: Settings,
  },
  [ROUTE_TYPE_SETTINGS_SETUP]: {
    path: '/settings/setup',
    title: 'Streamer setup',
    page: Setup,

  },
  [ROUTE_TYPE_GUIDE]: {
    path: '/guide',
    title: 'Alpha Guide',
    page: Guide,

  },
  [ROUTE_TYPE_PARTNERSHIP]: {
    path: '/partnership',
    title: 'Partnership',
    page: Partnership,
  },
  ...CreatorRoutes,
  ...RaffleRoutes,
  ...STREAMERS_ROUTES,
  ...CampaignRoutes,
  ...Dashboard,
  ...QuickTour,
  ...(process.env.NODE_ENV != 'production' ? Kit : {}), // Component Kit for Development
};


export const getSideNavigationByRoute = route => {
  return NAVIGATION_CONFIG[route]
    ? NAVIGATION_CONFIG[route].sideNavigation
    : null;
};

export const getSubNavigationByRoute = route => {

  return NAVIGATION_CONFIG[route]
    ? NAVIGATION_CONFIG[route].subNavigation
    : null;
};

export const getMainNavigationLogoOnly = route => {
  return NAVIGATION_CONFIG[route]
    ? NAVIGATION_CONFIG[route].logoOnlyNav
    : false;
};

export const routesMap = Object.keys(NAVIGATION_CONFIG).reduce((p, c, i, a) => {
  return {
    ...p,
    [c]: {
      path: NAVIGATION_CONFIG[c].path,
      confirmLeave: NAVIGATION_CONFIG[c].confirmLeave
    },
  };
}, {});


if (module.hot) {
  module.hot.accept();
}
