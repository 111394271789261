import styled from 'styled-components';
import React from 'react';
import DropDownController from 'common/BaseDropDown';
import DropDownList from 'common/BaseDropDown/DropDownList';
import { NavLink } from 'redux-first-router-link';
import { Translation } from 'react-i18next';
import { helpers } from '../../../helpers';
import { IconButton } from '../../../common/IconButton';
import { media } from 'styles';

const Layout = styled(DropDownController)`

  ${media.tabletMax`
    display: none;
  `}

  &.account.dropdown.open > *:first-child {
    color: white;
  }

  &.account .dropdownlist.open {
    min-width: 210px;
    border-radius: 6px;
    background: white;
    margin-top: 8px;
    right: 1px;
    box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
      0 1px 1px 0 rgba(29, 29, 29, 0.1), 0 15px 25px 0 rgba(29, 29, 29, 0.3);

    :after {
      position: absolute;
      top: -5px;
      right: 12px;
      content: ' ';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent white transparent;
    }

    a {
      display: block;
      font-size: 14px;
      cursor: pointer;
      padding: 2px 20px;

      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
    }
  }
`;

const HelpSection = styled.div`
  display: flex;
  flex-direction: column;

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin: 0;

  border-top: solid 2px #f0f0f0;

  background-color: rgba(234, 237, 243, 0.25);
  margin-top: 20px;
  padding: 20px;

  h3 {
    display: block;
    font-size: 14px;
    color: #5bcb8f;
    text-transform: uppercase;
  }

  a {
    padding: 10px 0 !important;
  }
`;

const Image = styled.div.attrs(props => {
  return {
    style: {
      backgroundImage: `url(${props.url})`,
    }
  }
})`
  background-size: cover;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  border-radius: 3px;
  margin-right: 10px;
`;

const Header = styled.div`
  color: grey;
  display: flex;
  font-size: 14px;
  color: #5bcb8f;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin: 0;
  padding: 20px;
  border-bottom: solid 2px #f0f0f0;
  background-color: rgba(234, 237, 243, 0.25);
  margin-bottom: 20px;

  align-items: center;
`;

const Button = styled.a.attrs(props => ({
  style: {
    backgroundImage: `url(${props.img})`,
  }
}))`
  background-color: #1d1d1d;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: solid 1px #36a6fc;

  position: relative;
  width: 34px;
  height: 34px;
  border-radius: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const Account = props => {
  const { logout, user, isStreamer } = props;

  return (
    <Translation>
      {t => <Layout className="account">
        {user && user.pic ? (
          <Button img={user.pic} />
        ) : (
            <IconButton className="icon-Ico16-Account" />
          )}

        <DropDownList
          onClick={event => {
            if (event.target.nodeName.toLowerCase() != 'a') {
              helpers.stopPropagation(event);
            }
          }}
        >
          <Header>
            {user && user.pic && <Image url={user.pic} />}
            <h2>{user && user.username}</h2>
          </Header>
          {user && isStreamer && (
            <NavLink to={`/streamers/${user.USERID}`}>
              {t('public_profile')}
            </NavLink>
          )}
          <NavLink to="/settings">{t('title_settings')}</NavLink>
          <a onClick={logout}>{t('logout')}</a>

          <HelpSection>
            <h3>{t('need_some_help')}</h3>{' '}
            <NavLink to="/contact">{t('customer_support')}</NavLink>
          </HelpSection>
        </DropDownList>
      </Layout>}
    </Translation>
  );
};
