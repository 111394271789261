import styled from 'styled-components';
import React from 'react';
import moment from 'moment-timezone';

const Layout = styled.div`
  display: flex;
  padding: 20px 15px;
  max-width: 300px;

  & + & {
    border-top: solid 2px #f0f0f0;
  }

  cursor: pointer;

  border-left: solid 5px white;

  &.new {
    border-left: solid 5px #36a6fc;
  }
`;

const Image = styled.div.attrs(props => ({style: {
  backgroundImage: props.url ? `url(${props.url})` : 'none',
}}))`
  background-size: cover;
  background-repeat: no-repeat;
  width: 30px;
  min-width: 30px;
  height: 24px;
  border-radius: 3px;
  margin-right: 8px;
`;

const Text = styled.div`
  font-size: 11px;
  font-weight: 300;
  max-width: 230px;

  b {
    color: '#36A6FC';
  }
`;

const Date = styled.div`
  color: #9c9c9c;
  font-size: 11px;
  font-weight: 300;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const STATES = ['', 'new', 'seen', 'read'];

export const Notification = props => {
  const { asset, html, link, status, timestamp } = props;

  return (
    <Layout
      className={STATES[status]}
      onClick={() => {
        if (typeof location != 'undefined') {
          location.replace(link);
        }
      }}
    >
      {asset && <Image url={asset} />}
      <Right>
        <Text dangerouslySetInnerHTML={{ __html: html }} />
        <Date>{moment.utc(timestamp).fromNow()}</Date>
      </Right>
    </Layout>
  );
};

export default Notification;
