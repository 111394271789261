import React from 'react';
import propTypes from 'prop-types';
import {
  addContestTimerListener,
  removeContestTimerListener,
} from '../helpers/giveaways';
import {
  RAFFLE_STATE_FINISHED,
  RAFFLE_STATE_ANNOUNCED,
  RAFFLE_STATE_ACTIVE,
} from 'helpers/const';

class ContestTimer extends React.Component {
  static contextTypes = {
    sliderState: propTypes.any,
    sliderMethods: propTypes.any,
  };

  static propTypes = {
    children: propTypes.func.isRequired,
  };


  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextContext && nextContext.sliderState) {
      if (nextContext.sliderState.swiping != 0) {
        return false;
      }
    }

    let hasOtherChanges = false;
    Object.keys(nextProps).forEach((key, index) => {
      if (key != 'timer') {
        if (nextProps[key] != this.props[key]) {
          hasOtherChanges = true;
        }
      }
    });

    if (hasOtherChanges) {
      return true;
    }

    if (
      nextProps.onlyState &&
      this.state.timer &&
      nextState.timer.mode == this.state.timer.mode
    ) {
      return false;
    }

    if (
      [RAFFLE_STATE_ACTIVE, RAFFLE_STATE_ANNOUNCED].indexOf(
        nextState.timer.mode,
      ) != -1 &&
      this.state.timer &&
      [RAFFLE_STATE_ACTIVE, RAFFLE_STATE_ANNOUNCED].indexOf(
        this.state.timer.mode,
      ) != -1 &&
      this.state.timer.time.days > 0 &&
      this.state.timer.time.days == nextState.timer.time.days
    ) {
      return false;
    }

    if (
      nextState.timer.mode == RAFFLE_STATE_FINISHED &&
      this.state.timer &&
      this.state.timer.mode == RAFFLE_STATE_FINISHED
    ) {
      return false;
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.state = { timer: false, unmounted: false };
  }

  componentDidMount() {
    const { contest } = this.props;

    if (contest) {
      addContestTimerListener(contest.EVENTID, this.handleTimerUpdate);
    }


  }

  componentWillUnmount() {
    const { contest } = this.props;
    this.setState({ unmounted: true });

    if (contest) {
      removeContestTimerListener(contest.EVENTID, this.handleTimerUpdate);
    }

  }

  handleTimerUpdate = timer => {
    if (!this.state.unmounted) {
      this.setState({ timer: timer });
    }
  };

  render() {
    const { children } = this.props;

    return children(this.state.timer);
  }
}
export default ContestTimer;
