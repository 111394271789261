export const runtimeConfig =
  typeof window !== 'undefined' ? {
    // client
    SERVER_FULLADDRESS: window.env.SERVER_FULLADDRESS,
    PROXY_TARGET: window.env.PROXY_TARGET,
    API_DIR: window.env.API_DIR,
    RECAPTCHA_SITE_KEY: window.env.RECAPTCHA_SITE_KEY,
    RECAPTCHA_VISIBLE_SITE_KEY: window.env.RECAPTCHA_VISIBLE_SITE_KEY,
    GA_TRACKING_ID: window.env.GA_TRACKING_ID,
    SERVER_TIMESTAMP: window.env.SERVER_TIMESTAMP,
    S3: window.env.S3,
    CAMPAIGNS: window.env.CAMPAIGNS
  } : {
      // server
      SERVER_FULLADDRESS: process.env.SERVER_FULLADDRESS,
      PROXY_TARGET: process.env.PROXY_TARGET,
      API_DIR: process.env.API_DIR,
      RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
      RECAPTCHA_VISIBLE_SITE_KEY: process.env.RECAPTCHA_VISIBLE_SITE_KEY,
      GA_TRACKING_ID: process.env.GA_TRACKING_ID,
      S3: process.env.S3
    };
