import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'redux-first-router-link';

const logo = css`
  background: url(/static/assets/images/gheed-logo-04-04.svg) no-repeat;
  background-size: contain;
  width: 151px;
  height: 30px;
  position: absolute;
  cursor: pointer;
`;

const InnerA = styled.a`
  ${logo};
`;
const InnerLink = styled(Link)`
  ${logo};
`;

const Outer = styled.div`
width: 151px;
height: 30px;
  position: relative;
  overflow: visible;
`;

const Logo = ({ onClick, to }) => (
  <Outer>
    {to ? (
      <InnerLink title="gheed.com" to={to} onClick={onClick} />
    ) : (
        <InnerA title="gheed.com" onClick={onClick} />
      )}
  </Outer>
);

export default Logo;
