import React from 'react';
import styled from 'styled-components';
import numeral from 'i18n/numeral';
import { Translation } from 'react-i18next';
import { H6ExtraBoldBlack, H6ExtraBoldRed, H6ExtraBoldGreen } from 'styles/Kit/H6ExtraBold';


const Layout = styled.div`
  ${H6ExtraBoldBlack}
`;

const Orange = styled.div`
${H6ExtraBoldRed}
`;
const Green = styled.div`
${H6ExtraBoldGreen}

`;

export const TimeLeft = ({ time }) => (
  <Translation>
    {t => (
      <Layout>
        {time.frame == 'days' ? (
          <Green>{t('ga_days_left', { count: time.days })}</Green>
        ) : (
            <Orange>
              {`${numeral(time.hours).format('00')}:${numeral(
                time.minutes,
              ).format('00')}:${numeral(time.seconds).format('00')}`}{' '}
            </Orange>
          )}
      </Layout>
    )}
  </Translation>
);
