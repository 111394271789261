import { css } from 'styled-components';
import { blue, red, green, grey, white, black } from './colors';

const BodySmallRegular = css`
  font-family: Nunito;
  font-size: 13px;
  font-weight: normal;
`;
export const BodySmallRegularBlue = css`
  ${BodySmallRegular}
  color: ${blue};
`;

export const BodySmallRegularRed = css`
  ${BodySmallRegular}
  color: ${red};
`;

export const BodySmallRegularGreen = css`
  ${BodySmallRegular}
  color: ${green};
`;

export const BodySmallRegularGrey = css`
  ${BodySmallRegular}
  color: ${grey};
`;

export const BodySmallRegularWhite = css`
  ${BodySmallRegular}
  color: ${white};
`;

export const BodySmallRegularBlack = css`
  ${BodySmallRegular}
  color: ${black};
`;
