import styled, { css } from 'styled-components';
import { utils } from '../../../helpers/utils';
import { ASSET_VARIANT_MEDIUM, ASSET_VARIANT_BIG } from '../../../helpers/const';
import { media } from 'styles';


// add retina support
const bgMixin = css`
  background-image: url(${({ pic }) => utils.resolveImageAsset(pic, ASSET_VARIANT_MEDIUM)});
  ${media.retina`
    background-image: url(${({ pic }) => utils.resolveImageAsset(pic, ASSET_VARIANT_BIG)});
  `}
`

const Image = styled.figure`
  ${bgMixin}
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-top-left-radius: 6px;
	border-top-right-radius: 6px;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 240px;
  min-height: 240px;
`;

export default Image;
