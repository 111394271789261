import { createLogic } from 'redux-logic';
import { AUTH_LOGIN_REQUEST } from 'rdx/modules/auth';
import { RESTAPI } from 'rdx/modules/api/rest';
import { modalAction, MODAL_CONNECT } from 'rdx/modules/modals';
import { JWT } from '../../helpers/jwt';

const loginRequestActionLogic = createLogic({
  type: AUTH_LOGIN_REQUEST,
  async process({ getState, action }, dispatch, done) {
    try {

      const accessToken = JWT.getAccessToken()
      let gheedUser = null;

      if (accessToken) {
        try {
          const userWithSettings = await RESTAPI.getUsersSettings(
            { accessToken }
          );
          gheedUser = userWithSettings;
        } catch (error) {
          gheedUser = null;
        }
      }

      if (!gheedUser) {
        dispatch(
          modalAction({
            modal: MODAL_CONNECT,
          }),
        );
      }

    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default loginRequestActionLogic;