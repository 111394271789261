import React from 'react';
import styled from 'styled-components';
import { Trans, Translation } from 'react-i18next';
import TooltipLink from '../../common/TooltipLink';
import i18n from '../../i18n';

const Layout = styled.div`
  
`;

const RegionLearnMore = () => (
  <Translation>
    {t => (
      <TooltipLink linkText={t('learnmore')}>
        <Layout>
          <Trans i18nKey="ga_region_tooltip" i18n={i18n}><h4>Regional restricted prizes</h4>If you win a prize that wont be shipped to your country, you will
            only be able to receive the prize by paying the shipping costs by
            yourself (<a href="mailto:hello@gheed.com">hello@gheed.com</a>
            ). Otherwise you wont be able to receive your prize.
          </Trans>
        </Layout>
      </TooltipLink>
    )}
  </Translation>
);

export default RegionLearnMore;
