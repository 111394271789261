import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { push } from 'redux-first-router';
import Scrollbar from 'common/MaterialUI/Scrollbar';
import Popover from 'common/MaterialUI/Popover';
import { Translation } from 'react-i18next';
import { MenuList } from '@material-ui/core';
import styled from 'styled-components';
import MenuItem from 'common/MaterialUI/MenuItem';


const withMenuControl = (Wrapped) => {
  return class Controller extends React.PureComponent {
    state = {
      anchorEl: null,
    };

    handleClick = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    render() {
      const { handleClick, handleClose } = this;
      const { anchorEl } = this.state;
      return <Wrapped {...this.props} {...{ handleClick, handleClose, anchorEl }} />;
    }


  };
};

const DotButton = styled.div`
  
  

  flex: 0 1 auto;
  background: transparent;
  height: 36px;
  width: 20px;
  display: flex;
  align-items: center;
  color: #36a6fc;
  margin-left: 20px;
  cursor: pointer;

  :after {
    content: '...';
    font-family: Nunito;
    font-size: 24px;
    font-weight: 800;
    position: absolute;
    top: -3px;
  }
`;

const Wrapper = styled.div`
position: absolute;
left: ${({ visWidth }) => visWidth}px;
`

const View = ({ visWidth, items, anchorEl, handleClose, handleClick, }) => {
  return (
    <Wrapper visWidth={visWidth} >
      <Translation>
        {(t) => (
          <FormControl>
            <DotButton onClick={handleClick} />
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >


              <MenuList component={Scrollbar}>
                {items.map(item => <MenuItem key={item.to} onClick={() => {
                  push(item.to);
                  handleClose();
                }}>{t(item.label)}</MenuItem>)}
              </MenuList>
            </Popover>
          </FormControl>
        )}
      </Translation></Wrapper>
  );
};

export default withMenuControl(View);
