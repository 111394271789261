import { createLogic } from 'redux-logic';
import { AUTH_ROLE_CHANGE_REQUEST, authRoleChangeResponseAction } from '../modules/auth';
import { putUsersSettingsResponseAction , getJwtRefreshRequestAction } from '../modules/api';
import { RESTAPI } from '../modules/api/rest';

import { JWT } from '../../helpers/jwt';


const authRoleChangeRequestLogic = createLogic({
  type: [AUTH_ROLE_CHANGE_REQUEST],

  async process({ getState, action }, dispatch, done) {
    try {
      const resp = await RESTAPI.putUsersSettings({ payload: { primary_roletypeid: action.payload } });
      dispatch(putUsersSettingsResponseAction(resp))
      
      const refreshToken = JWT.getRefreshToken()
      dispatch(getJwtRefreshRequestAction({ accessToken: refreshToken }))
      dispatch(authRoleChangeResponseAction(resp))


    } catch (error) {
      console.log(error)
    }
    done();
  },
});

export default authRoleChangeRequestLogic;