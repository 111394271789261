const settings =
  'scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no';

const getPopupOffset = ({ width, height }) => {
  const wLeft = window.screenLeft ? window.screenLeft : window.screenX;
  const wTop = window.screenTop ? window.screenTop : window.screenY;

  const left = wLeft + window.innerWidth / 2 - width / 2;
  const top = wTop + window.innerHeight / 2 - height / 2;

  return { top, left };
};

const getPopupSize = provider => {
  switch (provider) {
    case 'paypal':
      return { width: 768, height: 750 };

    case 'youtube':
      return { width: 700, height: 750 };

    case 'twitch':
      return { width: 700, height: 750 };

    case 'facebook':
      return { width: 580, height: 400 };

    case 'google':
      return { width: 452, height: 633 };

    case 'github':
      return { width: 1020, height: 618 };

    case 'linkedin':
      return { width: 527, height: 582 };

    case 'twitter':
      return { width: 495, height: 645 };

    case 'live':
      return { width: 500, height: 560 };

    case 'yahoo':
      return { width: 559, height: 519 };

    default:
      return { width: 1020, height: 618 };
  }
};

const getPopupDimensions = provider => {
  const { width, height } = getPopupSize(provider);
  const { top, left } = getPopupOffset({ width, height });

  return `width=${width},height=${height},top=${top},left=${left}`;
};

export const openPopup = (provider, url, name) => {
  //if(provider == 'twitter') { return window.open(url, "_blank"); }
  //else { return window.open(url, name, `${settings},${getPopupDimensions(provider)}`); }
  return window.open(url, name, `${settings},${getPopupDimensions(provider)}`);
};

export default openPopup;
