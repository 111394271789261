import React from 'react';
import styled from 'styled-components';
import { BodySmallRegularBlack } from '../../styles/Kit/BodySmallRegular';

const View = styled.div`
	border-bottom: solid 1px #e1e2ef;
  padding: 20px;
`;
const Title = styled.p`${BodySmallRegularBlack};`;

const DropDownSection = ({ title, children, ...other }) => {
    return (
        <View {...other}>
            {title && <Title>{title}</Title>}
            {children}
        </View>
    );
};

export default DropDownSection;
