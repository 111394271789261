import { WAYTYPE_GHEED_COINS, WAYTYPEID_GHEED_COMPLETE_ALL_OTHER_ENTRYWAYS, WAYTYPEID_OTHER_NEWSLETTER_VISIT, WAYTYPEID_OTHER_DOWNLOAD, WAYTYPEID_OTHER_CUSTOM_LINK_LABEL, WAYTYPEID_OTHER_VISIT, WAYTYPEID_GHEED_ENTER_ANOTHER_GIVEAWAY } from './waytypes';

export * from './platforms';
export * from './waytypes';

export const WAYTYPE_ICONS = {
  [WAYTYPEID_GHEED_COMPLETE_ALL_OTHER_ENTRYWAYS]: "icon-Ico16-Star",
  [WAYTYPEID_GHEED_ENTER_ANOTHER_GIVEAWAY]: "icon-Ico16-Star",
  [WAYTYPEID_OTHER_NEWSLETTER_VISIT]: "icon-Ico16-Messages",
  [WAYTYPEID_OTHER_DOWNLOAD]: "icon-Ico34-Download",
  [WAYTYPEID_OTHER_CUSTOM_LINK_LABEL]: "icon-Ico16-Link",
  [WAYTYPEID_OTHER_VISIT]: "icon-Ico16-Login",
  [WAYTYPE_GHEED_COINS]: "icon-Ico16-Login"
}

export const NORMAL_LISTING_FEE = 1;
export const BUSINESS_LISTING_FEE = 5;

export const DEFAULT_TIMEZONE = "Europe/Berlin";
export const DEFAULT_LOCATION = "DE"

export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ :;\>@!#\$%&'\(\)\*\+,-\.\/\[\\\]\^_`{\|}~])[A-Za-z\d :;\>@!#\$%&'\(\)\*\+,-\.\/\[\\\]\^_`{\|}~]{8,}$/i //eslint-disable-line no-useless-escape

export const REGEX_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z-]{2,24}$/i

export const REGION_DEFINITION_EU = 'EU!:AD&AL&BA&BY&MD&MC&ME&RU&SM&NO&LI&CH&RS&UA&VA'
export const REGION_DEFINITION_DACH = 'DE,AT,CH'

export const ACCESSTOKEN_VALID_DAYS = 1
export const REFRESHTOKEN_VALID_DAYS = 30;

export const REDUX_COOKIES = {
  'ui.disableAnalytics': { name: 'noga', expires: 365, path: '/' },
  'ui.cookieNotice': { name: 'ck', expires: 365, path: '/' },
  'ui.language': { name: 'lang', expires: 365, path: '/' },
  'productLists.filtered.region': { name: 'prg', expires: 365, path: '/' },
  'giveawayLists.region': { name: 'grg', expires: 365, path: '/' },
  'giveawayLists.regionActive': { name: 'grga', expires: 365, path: '/' },
  'giveawayLists.regionMode': { name: 'grgm', expires: 365, path: '/' },
};

export const META_DESCRIPTION = "GHEED is a giveaway community platform for influencers and their audience. Win cool prizes and support your favorite influencers while doing it!";

export const MAX_UPLOAD_SIZE = 4718592;

export const REGION_SEPARATOR = ",";
export const REGION_NEGATION_SEPARATOR = ":";
export const REGION_EXCEPTION_SEPARATOR = "&";

export const FINALIZE_STATUS_INIT_ERROR = 0;
export const FINALIZE_STATUS_INVALID = 1;
export const FINALIZE_STATUS_PAYMENT_REQUIRED = 2;
export const FINALIZE_STATUS_PAYMENT_INVALID = 4;
export const FINALIZE_STATUS_COMPLETE = 3;


export const EVENTTYPEID_PUBLIC = 338;
export const EVENTTYPEID_PRIVATE = 339;

export const UIELEMENT_EVENTBANNER = 1; 

export const PRIMARY_ROLETYPEID_VIEWER = 2;
export const PRIMARY_ROLETYPEID_STREAMER = 4;
export const PRIMARY_ROLETYPEID_STREAMER_UPGRADED = 5;
export const PRIMARY_ROLETYPEID_GHEED_PARTNER = 6;

export const HOST_CONTACTTYPEID_EMAIL = 1;
export const HOST_CONTACTTYPEID_DISCORD = 2;
export const HOST_CONTACTTYPEID_SKYPE = 3;

export const SHOP_ID_WOOCOMMERCE = 2;
export const SHOP_ID_PRIVATE = 1;

export const TIME_INPUT_PATTERN = '([0-2][0-3]|[0-1][0-9]):[0-5][0-9]$';

export const TIME_TO_NOTIFICATIONS_UPDATE = 5 * 60 * 1000;
export const TIME_TO_IDLE = 1 * 60 * 1000

export const CREATOR_MODE_MAP = {
  'prizes': 0,
  'shop': 0,
  'custom': 0,
  'details': 1,
  'entries': 2,
  'promotion': 3,
  'summary': 4,
}

export const CREATOR_PATHS = ['/create/prizes', '/create/details', '/create/entries', '/create/promotion', '/create/summary']
export const CREATOR_PATHS_EDIT = ['/giveaways/<id>/edit/prizes', '/giveaways/<id>/edit/details', '/giveaways/<id>/edit/entries', '', '']


export const ROUTE_PREFIX = '@@route/';
export const ROUTE_TYPE_HOME = `${ROUTE_PREFIX}home`
export const ROUTE_TYPE_IMPRINT = `${ROUTE_PREFIX}imprint`
export const ROUTE_TYPE_PRIVACY = `${ROUTE_PREFIX}privacy`
export const ROUTE_TYPE_TERMS = `${ROUTE_PREFIX}terms`

export const ROUTE_TYPE_CONTACT = `${ROUTE_PREFIX}contact`
export const ROUTE_TYPE_CONDITIONS = `${ROUTE_PREFIX}conditions`
export const ROUTE_TYPE_SETTINGS = `${ROUTE_PREFIX}settings`
export const ROUTE_TYPE_SETTINGS_SETUP = `${ROUTE_PREFIX}settings/setup`
export const ROUTE_TYPE_GUIDE = `${ROUTE_PREFIX}guide`
export const ROUTE_TYPE_PRODUCTS = `${ROUTE_PREFIX}products`
export const ROUTE_TYPE_CART = `${ROUTE_PREFIX}products/cart`
export const ROUTE_TYPE_PRODUCT = `${ROUTE_PREFIX}products/product`
export const ROUTE_TYPE_CREATE = `${ROUTE_PREFIX}giveaways/create`
export const ROUTE_TYPE_CREATE_ENTRIES = `${ROUTE_PREFIX}giveaways/create/entries`
export const ROUTE_TYPE_CREATE_CHECKOUT = `${ROUTE_PREFIX}giveaways/create/checkout`
export const ROUTE_TYPE_GIVEAWAYS = `${ROUTE_PREFIX}giveaways`
export const ROUTE_TYPE_GIVEAWAY = `${ROUTE_PREFIX}contest`
export const ROUTE_TYPE_GIVEAWAY_PREVIEW = `${ROUTE_PREFIX}giveaways/preview`
export const ROUTE_TYPE_CLAIM = `${ROUTE_PREFIX}claim`
export const ROUTE_TYPE_STREAMER = `${ROUTE_PREFIX}broadcaster`
export const ROUTE_TYPE_DASHBOARD_STREAMER = `${ROUTE_PREFIX}dashboard/streamer`
export const ROUTE_TYPE_DASHBOARD = `${ROUTE_PREFIX}dashboard`
export const ROUTE_TYPE_DASHBOARD_VIEWER = `${ROUTE_PREFIX}dashboard/viewer`
export const ROUTE_TYPE_GIVEAWAY_EDIT = `${ROUTE_PREFIX}contest/edit`
export const ROUTE_TYPE_GIVEAWAY_EMBED = `${ROUTE_PREFIX}contest/embed`
export const ROUTE_TYPE_GIVEAWAY_STATS = `${ROUTE_PREFIX}contest/details`
export const ROUTE_TYPE_QUICKTOUR = `${ROUTE_PREFIX}quicktour`
export const ROUTE_TYPE_BUSINESSES = `${ROUTE_PREFIX}quicktour/businesses`
export const ROUTE_TYPE_DEV_KIT = `${ROUTE_PREFIX}kit`
export const ROUTE_TYPE_DEV_BLOGPOST = `${ROUTE_PREFIX}blogpost`
export const ROUTE_TYPE_PARTNERSHIP = `${ROUTE_PREFIX}partnership`


export const IDENTIFIER_CALENDAR_HITECH = 'hi-tech';
export const PATH_CALENDAR_HITECH = `/hi-tech`;
export const ROUTE_TYPE_CALENDAR_HITECH = `${ROUTE_PREFIX}hi-tech`

export const IDENTIFIER_CALENDAR_NVIDIA = 'nvidia';
export const PATH_CALENDAR_NVIDIA = `/nvidia-adventskalender`;
export const ROUTE_TYPE_CALENDAR_NVIDIA = `${ROUTE_PREFIX}nvidia-adventskalender`

export const IDENTIFIER_CALENDAR_SHLOROX = 'shlorox';
export const PATH_CALENDAR_SHLOROX = `/shlorox`;
export const ROUTE_TYPE_CALENDAR_SHLOROX = `${ROUTE_PREFIX}shlorox`

export const IDENTIFIER_CALENDAR_STAIY = 'staiy';
export const PATH_CALENDAR_STAIY = `/staiy`;
export const ROUTE_TYPE_CALENDAR_STAIY = `${ROUTE_PREFIX}staiy`

export const ROUTE_TYPES_CREATOR = [ROUTE_TYPE_CREATE, ROUTE_TYPE_CREATE_ENTRIES, ROUTE_TYPE_CREATE_CHECKOUT]

export const ROUTES_STREAMERONLY = [
  ROUTE_TYPE_CREATE,
  ROUTE_TYPE_CREATE_ENTRIES,
  ROUTE_TYPE_CREATE_CHECKOUT,
  ROUTE_TYPE_GIVEAWAY_EDIT,
  ROUTE_TYPE_DASHBOARD_STREAMER,
  ROUTE_TYPE_PRODUCTS,
  ROUTE_TYPE_PRODUCT,
]

export const ASSET_VARIANT_ORIGINAL = 'original';
export const ASSET_VARIANT_SMALL = '260x208';
export const ASSET_VARIANT_MEDIUM = '520x416';
export const ASSET_VARIANT_BIG = '640x480';

export const LOCALES = ['en', 'de'];

export const EVENTDESCRIPTIONTEXTTYPE_PLAIN = 1;
export const EVENTDESCRIPTIONTEXTTYPE_HTML = 2;

export const EVENTBACKGROUND_META_MAP = {
  c: 'backgroundColor',
  a: 'backgroundAttachment',
  p: 'backgroundPosition',
  r: 'backgroundRepeat',
  s: 'backgroundSize',
};

export const EVENTBACKGROUND_META_DEFAULT = {
  c: '#E3E7EF',
  a: 'fixed',
  p: 'center top',
  r: 'no-repeat',
  s: 'cover',
};

export const IMAGEASSETTYPE_BANNER = 1;
export const IMAGEASSETTYPE_BACKGROUND = 2;
export const IMAGEASSETTYPE_AVATAR = 3;
export const IMAGEASSETTYPE_PRIVATEPRODUCT = 4;
export const IMAGEASSETTYPE_PUBPROFILE_BANNER = 5;

export const WALLET_STATUS_DEFAULT = 1;
export const WALLET_STATUS_FAVORITE = 2;
export const WALLET_STATUS_HIDDEN = 3;
export const WALLET_STATUS_DELETED = 4;

export const RAFFLE_STATE_ANNOUNCED = 'announced';
export const RAFFLE_STATE_ACTIVE = 'active';
export const RAFFLE_STATE_FINISHED = 'finished';

export const COIN_INTERVAL = 60;

export const AXIOS_CANCELED = 'AXIOS_CANCELED';

export const INFINITE_SCROLL_LIMIT = 12;
export const INFINITE_SCOLL_MORE_BUTTON_LIMIT = 86;

export const PLEASE_SELECT = 'Please Select ...';
export const MAX_ITEMS = 99;
export const PRODUCTCATEGORY_SEPARATOR = ';';

export const MASONRYITEM_TYPE_CONTEST = 'contest';
export const MASONRYITEM_TYPE_BANNER = 'banner';
export const MASONRYITEM_TYPE_PRODUCT = 'product';

export const SECRET_CODE_MAXLENGTH = 500;

export const TICK_TIMEOUT_MAP = {
  days: 24 * 60 * 60 * 1000,
  hours: 1000,
  minutes: 1000,
  seconds: 1000,
};


export const SOCIAL_SIMPLE_WAYTYPES = [5, 17, 18, 22, 23, 26];



export const LinksPublic = {
  beta: 'Beta Sign Up',
  login: 'Alpha Login',
};

export const LinksViewer = {
  viewerdashboard: 'navigation.viewerdashboard',
  raffles: 'navigation.raffles',
  mycoins: 'navigation.mycoins',
  tour: 'navigation.tour',
  about: 'navigation.about',
  news: 'navigation.news',
};

export const LinksStreamer = {
  dashboard: 'navigation.dashboard',
  profile: 'navigation.profile',
  store: 'navigation.store',
  raffles: 'navigation.raffles',
  viewerdashboard: 'navigation.viewerdashboard',
  mycoins: 'navigation.mycoins',
  tour: 'navigation.tour',
  about: 'navigation.about',
  news: 'navigation.news',
};

export const MobileAccountLinksStreamer = {
  notifications: 'navigation.notifications',
  messages: 'navigation.messages',
  'store/cart': 'navigation.cart',
  settings: 'navigation.settings',
  support: 'navigation.support',
  logout: 'navigation.logout',
};

export const MobileAccountLinksViewer = {
  notifications: 'navigation.notifications',
  messages: 'navigation.messages',
  settings: 'navigation.settings',
  support: 'navigation.support',
  logout: 'navigation.logout',
};

export const AccountLinks = {
  settings: 'navigation.settings',
  support: 'navigation.support',
  logout: 'navigation.logout',
};

