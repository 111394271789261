import { handleActions, combineActions } from 'redux-actions';
import {

  GET_RAFFLELIST_RESPONSE,
  GET_RAFFLE_BY_ID_RESPONSE,
  GET_RAFFLELIST_REQUEST,
  GET_RAFFLE_BY_ID_REQUEST,
  GET_PRIZES_BY_RAFFLE_ID_RESPONSE,
  GET_WAYS_BY_RAFFLE_ID_RESPONSE,
  GET_DRAW_STATUS_BY_RAFFLE_ID_RESPONSE,
  GET_WINNERS_BY_RAFFLE_ID_RESPONSE,
  POST_ENTRYWAYS_ENTITY_REQUEST,
  POST_ENTRYWAYS_ENTITY_RESPONSE,
  POST_ENTRYWAYS_ENTITY_ERROR,
  GET_RAFFLE_BY_ID_ENTRIES_RESPONSE,
  GET_WAYS_BY_RAFFLE_ID_STATUS_RESPONSE,
} from '../api';
import { ROUTE_TYPE_CREATE_CHECKOUT } from '../../../helpers/const';

import {
  DYNAMIC_LIST_RESPONSE,
  CATEGORY_LIST_RESPONSE,
} from '../giveawayLists';

export const RAFFLES_INFO = 'raffles/RAFFLES_INFO';

const initialState = {
  raffles: {},
  preview: {},
  prizes: {},
  prizeStats: {},
  winners: {},
  entryways: {},
  user_entries: {},
  processingEntryway: false,
  loading: false,
  loadingById: true,
};

export const rafflesReducer = handleActions(
  {

    [ROUTE_TYPE_CREATE_CHECKOUT]: (state, action) => {
      return { ...state, paymentUrl: null }
    },
    [POST_ENTRYWAYS_ENTITY_REQUEST]: (state, action) => {
      return { ...state, processingEntryway: action.payload.id }
    },

    [combineActions(POST_ENTRYWAYS_ENTITY_RESPONSE, POST_ENTRYWAYS_ENTITY_ERROR)]: (state, action) => {
      return { ...state, processingEntryway: false }
    },



    [combineActions(DYNAMIC_LIST_RESPONSE, CATEGORY_LIST_RESPONSE)]: (
      state,
      action,
    ) => {
      const rafflesById = action.payload[0].raffles.reduce((p, c, i, a) => {
        p[c.EVENTID] = c;
        return p;
      }, {});

      return {
        ...state,
        preview: state.preview
          ? { ...state.preview, ...rafflesById }
          : rafflesById,
      };
    },




    [GET_RAFFLE_BY_ID_REQUEST]: (state, action) => {
      return {
        ...state,
        loadingById: true,
      };
    },

    [GET_WINNERS_BY_RAFFLE_ID_RESPONSE]: (state, action) => {
      const winnersById = action.payload.eventwinners.reduce((p, c, i, a) => {
        if (c.eventid) {
          p[c.eventid] = c.winners;
        }
        return p;
      }, {});

      return {
        ...state,
        loadingById: false,
        winners: state.winners
          ? { ...state.winners, ...winnersById }
          : winnersById,
      };
    },

    [GET_PRIZES_BY_RAFFLE_ID_RESPONSE]: (state, action) => {
      const prizesById = action.payload.eventprizes.reduce((p, c, i, a) => {
        if (c.eventid) {
          p[c.eventid] = c.prizes;
        }
        return p;
      }, {});

      return {
        ...state,
        loadingById: false,
        prizes: state.prizes ? { ...state.prizes, ...prizesById } : prizesById,
      };
    },

    [GET_DRAW_STATUS_BY_RAFFLE_ID_RESPONSE]: (state, action) => {
      const { eventid } = action.payload;

      return {
        ...state,
        prizeStats: { ...state.prizeStats, [eventid]: action.payload },
      };
    },

    [combineActions(GET_WAYS_BY_RAFFLE_ID_RESPONSE, GET_WAYS_BY_RAFFLE_ID_STATUS_RESPONSE)]: (state, action) => {
      const waysById = action.payload.eventways.reduce((p, c, i, a) => {
        p[c.eventid] = c.ways;
        return p;
      }, {});

      return {
        ...state,
        loadingById: false,
        entryways: state.entryways
          ? { ...state.entryways, ...waysById }
          : waysById,
      };
    },

    [GET_RAFFLE_BY_ID_RESPONSE]: (state, action) => {
      const rafflesById = action.payload.raffles.reduce((p, c, i, a) => {
        p[c.EVENTID] = c;
        return p;
      }, {});

      return {
        ...state,
        loadingById: false,
        raffles: state.raffles
          ? { ...state.raffles, ...rafflesById }
          : rafflesById,
      };
    },

    [GET_RAFFLELIST_REQUEST]: (state, action) => {
      return {
        ...state,
        loading: true,
        nextPage: state.nextPage + 1,
      };
    },
    [GET_RAFFLELIST_RESPONSE]: (state, action) => {
      if (action.payload.mode == 'id_only') {
        return { ...state, loading: false };
      }

      const rafflesById = action.payload.raffles.reduce((p, c, i, a) => {
        p[c.EVENTID] = c;
        return p;
      }, {});

      const prop = action.payload.mode == 'preview' ? 'preview' : 'raffles';

      return {
        ...state,
        [prop]: { ...state[prop], ...rafflesById },
        loading: false,
        totalPages: action.payload.total_pages,
      };
    },




    [GET_RAFFLE_BY_ID_ENTRIES_RESPONSE]: (state, action) => {
      const result = action.payload.raffles.reduce((p, c) => ({ ...p, [c.EVENTID]: c.user_entries }), {})
      return { ...state, user_entries: { ...state.user_entries, ...result } }
    }
  },
  initialState,
);

export default rafflesReducer;
