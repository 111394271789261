import cldrTerritories_de from 'cldr-localenames-modern/main/de/territories.json';
import cldrTerritories_en from 'cldr-localenames-modern/main/en/territories.json';
import i18n, { CONTINENT_LOCALE_MAP } from '../i18n';
import { LOCALES , REGION_NEGATION_SEPARATOR, REGION_EXCEPTION_SEPARATOR } from './const';


const REGION_TREE = require('./regions.json');

const wc_countries = [
  'AF',
  'AX',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'PW',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'VG',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'CI',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'KP',
  'NO',
  'OM',
  'PK',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'SX',
  'PM',
  'VC',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'KR',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'VI',
  'UY',
  'UZ',
  'VU',
  'VA',
  'VE',
  'VN',
  'WF',
  'EH',
  'WS',
  'YE',
  'ZM',
  'ZW',
];

const wc_provinces = {};

wc_provinces['AO'] = {
  BGO: 'Bengo',
  BLU: 'Benguela',
  BIE: 'Bié',
  CAB: 'Cabinda',
  CNN: 'Cunene',
  HUA: 'Huambo',
  HUI: 'Huíla',
  CCU: 'Kuando Kubango',
  CNO: 'Kwanza-Norte',
  CUS: 'Kwanza-Sul',
  LUA: 'Luanda',
  LNO: 'Lunda-Norte',
  LSU: 'Lunda-Sul',
  MAL: 'Malanje',
  MOX: 'Moxico',
  NAM: 'Namibe',
  UIG: 'Uíge',
  ZAI: 'Zaire',
};

wc_provinces['AR'] = {
  C: 'Ciudad Autónoma de Buenos Aires',
  B: 'Buenos Aires',
  K: 'Catamarca',
  H: 'Chaco',
  U: 'Chubut',
  X: 'Córdoba',
  W: 'Corrientes',
  E: 'Entre Ríos',
  P: 'Formosa',
  Y: 'Jujuy',
  L: 'La Pampa',
  F: 'La Rioja',
  M: 'Mendoza',
  N: 'Misiones',
  Q: 'Neuquén',
  R: 'Río Negro',
  A: 'Salta',
  J: 'San Juan',
  D: 'San Luis',
  Z: 'Santa Cruz',
  S: 'Santa Fe',
  G: 'Santiago del Estero',
  V: 'Tierra del Fuego',
  T: 'Tucumán',
};

wc_provinces['AU'] = {
  ACT: 'Australian Capital Territory',
  NSW: 'New South Wales',
  NT: 'Northern Territory',
  QLD: 'Queensland',
  SA: 'South Australia',
  TAS: 'Tasmania',
  VIC: 'Victoria',
  WA: 'Western Australia',
};

wc_provinces['BD'] = {
  'BD-05': 'Bagerhat',
  'BD-01': 'Bandarban',
  'BD-02': 'Barguna',
  'BD-06': 'Barishal',
  'BD-07': 'Bhola',
  'BD-03': 'Bogura',
  'BD-04': 'Brahmanbaria',
  'BD-09': 'Chandpur',
  'BD-10': 'Chattogram',
  'BD-12': 'Chuadanga',
  'BD-11': "Cox's Bazar",
  'BD-08': 'Cumilla',
  'BD-13': 'Dhaka',
  'BD-14': 'Dinajpur',
  'BD-15': 'Faridpur ',
  'BD-16': 'Feni',
  'BD-19': 'Gaibandha',
  'BD-18': 'Gazipur',
  'BD-17': 'Gopalganj',
  'BD-20': 'Habiganj',
  'BD-21': 'Jamalpur',
  'BD-22': 'Jashore',
  'BD-25': 'Jhalokati',
  'BD-23': 'Jhenaidah',
  'BD-24': 'Joypurhat',
  'BD-29': 'Khagrachhari',
  'BD-27': 'Khulna',
  'BD-26': 'Kishoreganj',
  'BD-28': 'Kurigram',
  'BD-30': 'Kushtia',
  'BD-31': 'Lakshmipur',
  'BD-32': 'Lalmonirhat',
  'BD-36': 'Madaripur',
  'BD-37': 'Magura',
  'BD-33': 'Manikganj ',
  'BD-39': 'Meherpur',
  'BD-38': 'Moulvibazar',
  'BD-35': 'Munshiganj',
  'BD-34': 'Mymensingh',
  'BD-48': 'Naogaon',
  'BD-43': 'Narail',
  'BD-40': 'Narayanganj',
  'BD-42': 'Narsingdi',
  'BD-44': 'Natore',
  'BD-45': 'Nawabganj',
  'BD-41': 'Netrakona',
  'BD-46': 'Nilphamari',
  'BD-47': 'Noakhali',
  'BD-49': 'Pabna',
  'BD-52': 'Panchagarh',
  'BD-51': 'Patuakhali',
  'BD-50': 'Pirojpur',
  'BD-53': 'Rajbari',
  'BD-54': 'Rajshahi',
  'BD-56': 'Rangamati',
  'BD-55': 'Rangpur',
  'BD-58': 'Satkhira',
  'BD-62': 'Shariatpur',
  'BD-57': 'Sherpur',
  'BD-59': 'Sirajganj',
  'BD-61': 'Sunamganj',
  'BD-60': 'Sylhet',
  'BD-63': 'Tangail',
  'BD-64': 'Thakurgaon',
};

wc_provinces['BG'] = {
  'BG-01': 'Blagoevgrad',
  'BG-02': 'Burgas',
  'BG-08': 'Dobrich',
  'BG-07': 'Gabrovo',
  'BG-26': 'Haskovo',
  'BG-09': 'Kardzhali',
  'BG-10': 'Kyustendil',
  'BG-11': 'Lovech',
  'BG-12': 'Montana',
  'BG-13': 'Pazardzhik',
  'BG-14': 'Pernik',
  'BG-15': 'Pleven',
  'BG-16': 'Plovdiv',
  'BG-17': 'Razgrad',
  'BG-18': 'Ruse',
  'BG-27': 'Shumen',
  'BG-19': 'Silistra',
  'BG-20': 'Sliven',
  'BG-21': 'Smolyan',
  'BG-23': 'Sofia',
  'BG-22': 'Sofia-Grad',
  'BG-24': 'Stara Zagora',
  'BG-25': 'Targovishte',
  'BG-03': 'Varna',
  'BG-04': 'Veliko Tarnovo',
  'BG-05': 'Vidin',
  'BG-06': 'Vratsa',
  'BG-28': 'Yambol',
};

wc_provinces['BO'] = {
  B: 'Chuquisaca',
  H: 'Beni',
  C: 'Cochabamba',
  L: 'La Paz',
  O: 'Oruro',
  N: 'Pando',
  P: 'Potosí',
  S: 'Santa Cruz',
  T: 'Tarija',
};

wc_provinces['BR'] = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

wc_provinces['CA'] = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon Territory',
};

wc_provinces['CH'] = {
  AG: 'Aargau',
  AR: 'Appenzell Ausserrhoden',
  AI: 'Appenzell Innerrhoden',
  BL: 'Basel-Landschaft',
  BS: 'Basel-Stadt',
  BE: 'Bern',
  FR: 'Fribourg',
  GE: 'Geneva',
  GL: 'Glarus',
  GR: 'Graubünden',
  JU: 'Jura',
  LU: 'Luzern',
  NE: 'Neuchâtel',
  NW: 'Nidwalden',
  OW: 'Obwalden',
  SH: 'Schaffhausen',
  SZ: 'Schwyz',
  SO: 'Solothurn',
  SG: 'St. Gallen',
  TG: 'Thurgau',
  TI: 'Ticino',
  UR: 'Uri',
  VS: 'Valais',
  VD: 'Vaud',
  ZG: 'Zug',
  ZH: 'Zürich',
};

wc_provinces['CN'] = {
  CN1: 'Yunnan / 云南',
  CN2: 'Beijing / 北京',
  CN3: 'Tianjin / 天津',
  CN4: 'Hebei / 河北',
  CN5: 'Shanxi / 山西',
  CN6: 'Inner Mongolia / 內蒙古',
  CN7: 'Liaoning / 辽宁',
  CN8: 'Jilin / 吉林',
  CN9: 'Heilongjiang / 黑龙江',
  CN10: 'Shanghai / 上海',
  CN11: 'Jiangsu / 江苏',
  CN12: 'Zhejiang / 浙江',
  CN13: 'Anhui / 安徽',
  CN14: 'Fujian / 福建',
  CN15: 'Jiangxi / 江西',
  CN16: 'Shandong / 山东',
  CN17: 'Henan / 河南',
  CN18: 'Hubei / 湖北',
  CN19: 'Hunan / 湖南',
  CN20: 'Guangdong / 广东',
  CN21: 'Guangxi Zhuang / 广西壮族',
  CN22: 'Hainan / 海南',
  CN23: 'Chongqing / 重庆',
  CN24: 'Sichuan / 四川',
  CN25: 'Guizhou / 贵州',
  CN26: 'Shaanxi / 陕西',
  CN27: 'Gansu / 甘肃',
  CN28: 'Qinghai / 青海',
  CN29: 'Ningxia Hui / 宁夏',
  CN30: 'Macau / 澳门',
  CN31: 'Tibet / 西藏',
  CN32: 'Xinjiang / 新疆',
};

wc_provinces['ES'] = {
  C: 'A Coruña',
  VI: 'Araba/Álava',
  AB: 'Albacete',
  A: 'Alicante',
  AL: 'Almería',
  O: 'Asturias',
  AV: 'Ávila',
  BA: 'Badajoz',
  PM: 'Baleares',
  B: 'Barcelona',
  BU: 'Burgos',
  CC: 'Cáceres',
  CA: 'Cádiz',
  S: 'Cantabria',
  CS: 'Castellón',
  CE: 'Ceuta',
  CR: 'Ciudad Real',
  CO: 'Córdoba',
  CU: 'Cuenca',
  GI: 'Girona',
  GR: 'Granada',
  GU: 'Guadalajara',
  SS: 'Gipuzkoa',
  H: 'Huelva',
  HU: 'Huesca',
  J: 'Jaén',
  LO: 'La Rioja',
  GC: 'Las Palmas',
  LE: 'León',
  L: 'Lleida',
  LU: 'Lugo',
  M: 'Madrid',
  MA: 'Málaga',
  ML: 'Melilla',
  MU: 'Murcia',
  NA: 'Navarra',
  OR: 'Ourense',
  P: 'Palencia',
  PO: 'Pontevedra',
  SA: 'Salamanca',
  TF: 'Santa Cruz de Tenerife',
  SG: 'Segovia',
  SE: 'Sevilla',
  SO: 'Soria',
  T: 'Tarragona',
  TE: 'Teruel',
  TO: 'Toledo',
  V: 'Valencia',
  VA: 'Valladolid',
  BI: 'Bizkaia',
  ZA: 'Zamora',
  Z: 'Zaragoza',
};

wc_provinces['GR'] = {
  I: 'Αττική',
  A: 'Ανατολική Μακεδονία και Θράκη',
  B: 'Κεντρική Μακεδονία',
  C: 'Δυτική Μακεδονία',
  D: 'Ήπειρος',
  E: 'Θεσσαλία',
  F: 'Ιόνιοι Νήσοι',
  G: 'Δυτική Ελλάδα',
  H: 'Στερεά Ελλάδα',
  J: 'Πελοπόννησος',
  K: 'Βόρειο Αιγαίο',
  L: 'Νότιο Αιγαίο',
  M: 'Κρήτη',
};

wc_provinces['HK'] = {
  'HONG KONG': 'Hong Kong Island',
  KOWLOON: 'Kowloon',
  'NEW TERRITORIES': 'New Territories',
};

wc_provinces['HU'] = {
  BK: 'Bács-Kiskun',
  BE: 'Békés',
  BA: 'Baranya',
  BZ: 'Borsod-Abaúj-Zemplén',
  BU: 'Budapest',
  CS: 'Csongrád',
  FE: 'Fejér',
  GS: 'Győr-Moson-Sopron',
  HB: 'Hajdú-Bihar',
  HE: 'Heves',
  JN: 'Jász-Nagykun-Szolnok',
  KE: 'Komárom-Esztergom',
  NO: 'Nógrád',
  PE: 'Pest',
  SO: 'Somogy',
  SZ: 'Szabolcs-Szatmár-Bereg',
  TO: 'Tolna',
  VA: 'Vas',
  VE: 'Veszprém',
  ZA: 'Zala',
};

wc_provinces['ID'] = {
  AC: 'Daerah Istimewa Aceh',
  SU: 'Sumatera Utara',
  SB: 'Sumatera Barat',
  RI: 'Riau',
  KR: 'Kepulauan Riau',
  JA: 'Jambi',
  SS: 'Sumatera Selatan',
  BB: 'Bangka Belitung',
  BE: 'Bengkulu',
  LA: 'Lampung',
  JK: 'DKI Jakarta',
  JB: 'Jawa Barat',
  BT: 'Banten',
  JT: 'Jawa Tengah',
  JI: 'Jawa Timur',
  YO: 'Daerah Istimewa Yogyakarta',
  BA: 'Bali',
  NB: 'Nusa Tenggara Barat',
  NT: 'Nusa Tenggara Timur',
  KB: 'Kalimantan Barat',
  KT: 'Kalimantan Tengah',
  KI: 'Kalimantan Timur',
  KS: 'Kalimantan Selatan',
  KU: 'Kalimantan Utara',
  SA: 'Sulawesi Utara',
  ST: 'Sulawesi Tengah',
  SG: 'Sulawesi Tenggara',
  SR: 'Sulawesi Barat',
  SN: 'Sulawesi Selatan',
  GO: 'Gorontalo',
  MA: 'Maluku',
  MU: 'Maluku Utara',
  PA: 'Papua',
  PB: 'Papua Barat',
};

wc_provinces['IE'] = {
  CW: 'Carlow',
  CN: 'Cavan',
  CE: 'Clare',
  CO: 'Cork',
  DL: 'Donegal',
  D: 'Dublin',
  G: 'Galway',
  KY: 'Kerry',
  KE: 'Kildare',
  KK: 'Kilkenny',
  LS: 'Laois',
  LM: 'Leitrim',
  LK: 'Limerick',
  LD: 'Longford',
  LH: 'Louth',
  MO: 'Mayo',
  MH: 'Meath',
  MN: 'Monaghan',
  OY: 'Offaly',
  RN: 'Roscommon',
  SO: 'Sligo',
  TA: 'Tipperary',
  WD: 'Waterford',
  WH: 'Westmeath',
  WX: 'Wexford',
  WW: 'Wicklow',
};

wc_provinces['IN'] = {
  AP: 'Andhra Pradesh',
  AR: 'Arunachal Pradesh',
  AS: 'Assam',
  BR: 'Bihar',
  CT: 'Chhattisgarh',
  GA: 'Goa',
  GJ: 'Gujarat',
  HR: 'Haryana',
  HP: 'Himachal Pradesh',
  JK: 'Jammu and Kashmir',
  JH: 'Jharkhand',
  KA: 'Karnataka',
  KL: 'Kerala',
  MP: 'Madhya Pradesh',
  MH: 'Maharashtra',
  MN: 'Manipur',
  ML: 'Meghalaya',
  MZ: 'Mizoram',
  NL: 'Nagaland',
  OR: 'Orissa',
  PB: 'Punjab',
  RJ: 'Rajasthan',
  SK: 'Sikkim',
  TN: 'Tamil Nadu',
  TS: 'Telangana',
  TR: 'Tripura',
  UK: 'Uttarakhand',
  UP: 'Uttar Pradesh',
  WB: 'West Bengal',
  AN: 'Andaman and Nicobar Islands',
  CH: 'Chandigarh',
  DN: 'Dadra and Nagar Haveli',
  DD: 'Daman and Diu',
  DL: 'Delhi',
  LD: 'Lakshadeep',
  PY: 'Pondicherry (Puducherry)',
};

wc_provinces['IR'] = {
  KHZ: 'Khuzestan  (خوزستان)',
  THR: 'Tehran  (تهران)',
  ILM: 'Ilaam (ایلام)',
  BHR: 'Bushehr (بوشهر)',
  ADL: 'Ardabil (اردبیل)',
  ESF: 'Isfahan (اصفهان)',
  YZD: 'Yazd (یزد)',
  KRH: 'Kermanshah (کرمانشاه)',
  KRN: 'Kerman (کرمان)',
  HDN: 'Hamadan (همدان)',
  GZN: 'Ghazvin (قزوین)',
  ZJN: 'Zanjan (زنجان)',
  LRS: 'Luristan (لرستان)',
  ABZ: 'Alborz (البرز)',
  EAZ: 'East Azarbaijan (آذربایجان شرقی)',
  WAZ: 'West Azarbaijan (آذربایجان غربی)',
  CHB: 'Chaharmahal and Bakhtiari (چهارمحال و بختیاری)',
  SKH: 'South Khorasan (خراسان جنوبی)',
  RKH: 'Razavi Khorasan (خراسان رضوی)',
  NKH: 'North Khorasan (خراسان جنوبی)',
  SMN: 'Semnan (سمنان)',
  FRS: 'Fars (فارس)',
  QHM: 'Qom (قم)',
  KRD: 'Kurdistan / کردستان)',
  KBD: 'Kohgiluyeh and BoyerAhmad (کهگیلوییه و بویراحمد)',
  GLS: 'Golestan (گلستان)',
  GIL: 'Gilan (گیلان)',
  MZN: 'Mazandaran (مازندران)',
  MKZ: 'Markazi (مرکزی)',
  HRZ: 'Hormozgan (هرمزگان)',
  SBN: 'Sistan and Baluchestan (سیستان و بلوچستان)',
};

wc_provinces['IT'] = {
  AG: 'Agrigento',
  AL: 'Alessandria',
  AN: 'Ancona',
  AO: 'Aosta',
  AR: 'Arezzo',
  AP: 'Ascoli Piceno',
  AT: 'Asti',
  AV: 'Avellino',
  BA: 'Bari',
  BT: 'Barletta-Andria-Trani',
  BL: 'Belluno',
  BN: 'Benevento',
  BG: 'Bergamo',
  BI: 'Biella',
  BO: 'Bologna',
  BZ: 'Bolzano',
  BS: 'Brescia',
  BR: 'Brindisi',
  CA: 'Cagliari',
  CL: 'Caltanissetta',
  CB: 'Campobasso',
  CE: 'Caserta',
  CT: 'Catania',
  CZ: 'Catanzaro',
  CH: 'Chieti',
  CO: 'Como',
  CS: 'Cosenza',
  CR: 'Cremona',
  KR: 'Crotone',
  CN: 'Cuneo',
  EN: 'Enna',
  FM: 'Fermo',
  FE: 'Ferrara',
  FI: 'Firenze',
  FG: 'Foggia',
  FC: 'Forlì-Cesena',
  FR: 'Frosinone',
  GE: 'Genova',
  GO: 'Gorizia',
  GR: 'Grosseto',
  IM: 'Imperia',
  IS: 'Isernia',
  SP: 'La Spezia',
  AQ: "L'Aquila",
  LT: 'Latina',
  LE: 'Lecce',
  LC: 'Lecco',
  LI: 'Livorno',
  LO: 'Lodi',
  LU: 'Lucca',
  MC: 'Macerata',
  MN: 'Mantova',
  MS: 'Massa-Carrara',
  MT: 'Matera',
  ME: 'Messina',
  MI: 'Milano',
  MO: 'Modena',
  MB: 'Monza e della Brianza',
  NA: 'Napoli',
  NO: 'Novara',
  NU: 'Nuoro',
  OR: 'Oristano',
  PD: 'Padova',
  PA: 'Palermo',
  PR: 'Parma',
  PV: 'Pavia',
  PG: 'Perugia',
  PU: 'Pesaro e Urbino',
  PE: 'Pescara',
  PC: 'Piacenza',
  PI: 'Pisa',
  PT: 'Pistoia',
  PN: 'Pordenone',
  PZ: 'Potenza',
  PO: 'Prato',
  RG: 'Ragusa',
  RA: 'Ravenna',
  RC: 'Reggio Calabria',
  RE: 'Reggio Emilia',
  RI: 'Rieti',
  RN: 'Rimini',
  RM: 'Roma',
  RO: 'Rovigo',
  SA: 'Salerno',
  SS: 'Sassari',
  SV: 'Savona',
  SI: 'Siena',
  SR: 'Siracusa',
  SO: 'Sondrio',
  SU: 'Sud Sardegna',
  TA: 'Taranto',
  TE: 'Teramo',
  TR: 'Terni',
  TO: 'Torino',
  TP: 'Trapani',
  TN: 'Trento',
  TV: 'Treviso',
  TS: 'Trieste',
  UD: 'Udine',
  VA: 'Varese',
  VE: 'Venezia',
  VB: 'Verbano-Cusio-Ossola',
  VC: 'Vercelli',
  VR: 'Verona',
  VV: 'Vibo Valentia',
  VI: 'Vicenza',
  VT: 'Viterbo',
};

wc_provinces['JP'] = {
  JP01: 'Hokkaido',
  JP02: 'Aomori',
  JP03: 'Iwate',
  JP04: 'Miyagi',
  JP05: 'Akita',
  JP06: 'Yamagata',
  JP07: 'Fukushima',
  JP08: 'Ibaraki',
  JP09: 'Tochigi',
  JP10: 'Gunma',
  JP11: 'Saitama',
  JP12: 'Chiba',
  JP13: 'Tokyo',
  JP14: 'Kanagawa',
  JP15: 'Niigata',
  JP16: 'Toyama',
  JP17: 'Ishikawa',
  JP18: 'Fukui',
  JP19: 'Yamanashi',
  JP20: 'Nagano',
  JP21: 'Gifu',
  JP22: 'Shizuoka',
  JP23: 'Aichi',
  JP24: 'Mie',
  JP25: 'Shiga',
  JP26: 'Kyoto',
  JP27: 'Osaka',
  JP28: 'Hyogo',
  JP29: 'Nara',
  JP30: 'Wakayama',
  JP31: 'Tottori',
  JP32: 'Shimane',
  JP33: 'Okayama',
  JP34: 'Hiroshima',
  JP35: 'Yamaguchi',
  JP36: 'Tokushima',
  JP37: 'Kagawa',
  JP38: 'Ehime',
  JP39: 'Kochi',
  JP40: 'Fukuoka',
  JP41: 'Saga',
  JP42: 'Nagasaki',
  JP43: 'Kumamoto',
  JP44: 'Oita',
  JP45: 'Miyazaki',
  JP46: 'Kagoshima',
  JP47: 'Okinawa',
};

wc_provinces['LR'] = {
  BM: 'Bomi',
  BN: 'Bong',
  GA: 'Gbarpolu',
  GB: 'Grand Bassa',
  GC: 'Grand Cape Mount',
  GG: 'Grand Gedeh',
  GK: 'Grand Kru',
  LO: 'Lofa',
  MA: 'Margibi',
  MY: 'Maryland',
  MO: 'Montserrado',
  NM: 'Nimba',
  RV: 'Rivercess',
  RG: 'River Gee',
  SN: 'Sinoe',
};

wc_provinces['MD'] = {
  C: 'Chișinău',
  BL: 'Bălți',
  AN: 'Anenii Noi',
  BS: 'Basarabeasca',
  BR: 'Briceni',
  CH: 'Cahul',
  CT: 'Cantemir',
  CL: 'Călărași',
  CS: 'Căușeni',
  CM: 'Cimișlia',
  CR: 'Criuleni',
  DN: 'Dondușeni',
  DR: 'Drochia',
  DB: 'Dubăsari',
  ED: 'Edineț',
  FL: 'Fălești',
  FR: 'Florești',
  GE: 'UTA Găgăuzia',
  GL: 'Glodeni',
  HN: 'Hîncești',
  IL: 'Ialoveni',
  LV: 'Leova',
  NS: 'Nisporeni',
  OC: 'Ocnița',
  OR: 'Orhei',
  RZ: 'Rezina',
  RS: 'Rîșcani',
  SG: 'Sîngerei',
  SR: 'Soroca',
  ST: 'Strășeni',
  SD: 'Șoldănești',
  SV: 'Ștefan Vodă',
  TR: 'Taraclia',
  TL: 'Telenești',
  UN: 'Ungheni',
};

wc_provinces['MX'] = {
  DF: 'Ciudad de México',
  JA: 'Jalisco',
  NL: 'Nuevo León',
  AG: 'Aguascalientes',
  BC: 'Baja California',
  BS: 'Baja California Sur',
  CM: 'Campeche',
  CS: 'Chiapas',
  CH: 'Chihuahua',
  CO: 'Coahuila',
  CL: 'Colima',
  DG: 'Durango',
  GT: 'Guanajuato',
  GR: 'Guerrero',
  HG: 'Hidalgo',
  MX: 'Estado de México',
  MI: 'Michoacán',
  MO: 'Morelos',
  NA: 'Nayarit',
  OA: 'Oaxaca',
  PU: 'Puebla',
  QT: 'Querétaro',
  QR: 'Quintana Roo',
  SL: 'San Luis Potosí',
  SI: 'Sinaloa',
  SO: 'Sonora',
  TB: 'Tabasco',
  TM: 'Tamaulipas',
  TL: 'Tlaxcala',
  VE: 'Veracruz',
  YU: 'Yucatán',
  ZA: 'Zacatecas',
};

wc_provinces['MY'] = {
  JHR: 'Johor',
  KDH: 'Kedah',
  KTN: 'Kelantan',
  LBN: 'Labuan',
  MLK: 'Malacca (Melaka)',
  NSN: 'Negeri Sembilan',
  PHG: 'Pahang',
  PNG: 'Penang (Pulau Pinang)',
  PRK: 'Perak',
  PLS: 'Perlis',
  SBH: 'Sabah',
  SWK: 'Sarawak',
  SGR: 'Selangor',
  TRG: 'Terengganu',
  PJY: 'Putrajaya',
  KUL: 'Kuala Lumpur',
};

wc_provinces['NG'] = {
  AB: 'Abia',
  FC: 'Abuja',
  AD: 'Adamawa',
  AK: 'Akwa Ibom',
  AN: 'Anambra',
  BA: 'Bauchi',
  BY: 'Bayelsa',
  BE: 'Benue',
  BO: 'Borno',
  CR: 'Cross River',
  DE: 'Delta',
  EB: 'Ebonyi',
  ED: 'Edo',
  EK: 'Ekiti',
  EN: 'Enugu',
  GO: 'Gombe',
  IM: 'Imo',
  JI: 'Jigawa',
  KD: 'Kaduna',
  KN: 'Kano',
  KT: 'Katsina',
  KE: 'Kebbi',
  KO: 'Kogi',
  KW: 'Kwara',
  LA: 'Lagos',
  NA: 'Nasarawa',
  NI: 'Niger',
  OG: 'Ogun',
  ON: 'Ondo',
  OS: 'Osun',
  OY: 'Oyo',
  PL: 'Plateau',
  RI: 'Rivers',
  SO: 'Sokoto',
  TA: 'Taraba',
  YO: 'Yobe',
  ZA: 'Zamfara',
};

wc_provinces['NP'] = {
  BAG: 'Bagmati',
  BHE: 'Bheri',
  DHA: 'Dhaulagiri',
  GAN: 'Gandaki',
  JAN: 'Janakpur',
  KAR: 'Karnali',
  KOS: 'Koshi',
  LUM: 'Lumbini',
  MAH: 'Mahakali',
  MEC: 'Mechi',
  NAR: 'Narayani',
  RAP: 'Rapti',
  SAG: 'Sagarmatha',
  SET: 'Seti',
};

wc_provinces['NZ'] = {
  NL: 'Northland',
  AK: 'Auckland',
  WA: 'Waikato',
  BP: 'Bay of Plenty',
  TK: 'Taranaki',
  GI: 'Gisborne',
  HB: 'Hawke’s Bay',
  MW: 'Manawatu-Wanganui',
  WE: 'Wellington',
  NS: 'Nelson',
  MB: 'Marlborough',
  TM: 'Tasman',
  WC: 'West Coast',
  CT: 'Canterbury',
  OT: 'Otago',
  SL: 'Southland',
};

wc_provinces['PE'] = {
  CAL: 'El Callao',
  LMA: 'Municipalidad Metropolitana de Lima',
  AMA: 'Amazonas',
  ANC: 'Ancash',
  APU: 'Apurímac',
  ARE: 'Arequipa',
  AYA: 'Ayacucho',
  CAJ: 'Cajamarca',
  CUS: 'Cusco',
  HUV: 'Huancavelica',
  HUC: 'Huánuco',
  ICA: 'Ica',
  JUN: 'Junín',
  LAL: 'La Libertad',
  LAM: 'Lambayeque',
  LIM: 'Lima',
  LOR: 'Loreto',
  MDD: 'Madre de Dios',
  MOQ: 'Moquegua',
  PAS: 'Pasco',
  PIU: 'Piura',
  PUN: 'Puno',
  SAM: 'San Martín',
  TAC: 'Tacna',
  TUM: 'Tumbes',
  UCA: 'Ucayali',
};

wc_provinces['PH'] = {
  ABR: 'Abra',
  AGN: 'Agusan del Norte',
  AGS: 'Agusan del Sur',
  AKL: 'Aklan',
  ALB: 'Albay',
  ANT: 'Antique',
  APA: 'Apayao',
  AUR: 'Aurora',
  BAS: 'Basilan',
  BAN: 'Bataan',
  BTN: 'Batanes',
  BTG: 'Batangas',
  BEN: 'Benguet',
  BIL: 'Biliran',
  BOH: 'Bohol',
  BUK: 'Bukidnon',
  BUL: 'Bulacan',
  CAG: 'Cagayan',
  CAN: 'Camarines Norte',
  CAS: 'Camarines Sur',
  CAM: 'Camiguin',
  CAP: 'Capiz',
  CAT: 'Catanduanes',
  CAV: 'Cavite',
  CEB: 'Cebu',
  COM: 'Compostela Valley',
  NCO: 'Cotabato',
  DAV: 'Davao del Norte',
  DAS: 'Davao del Sur',
  DAC: 'Davao Occidental', // TODO: Needs to be updated when ISO code is assigned.
  DAO: 'Davao Oriental',
  DIN: 'Dinagat Islands',
  EAS: 'Eastern Samar',
  GUI: 'Guimaras',
  IFU: 'Ifugao',
  ILN: 'Ilocos Norte',
  ILS: 'Ilocos Sur',
  ILI: 'Iloilo',
  ISA: 'Isabela',
  KAL: 'Kalinga',
  LUN: 'La Union',
  LAG: 'Laguna',
  LAN: 'Lanao del Norte',
  LAS: 'Lanao del Sur',
  LEY: 'Leyte',
  MAG: 'Maguindanao',
  MAD: 'Marinduque',
  MAS: 'Masbate',
  MSC: 'Misamis Occidental',
  MSR: 'Misamis Oriental',
  MOU: 'Mountain Province',
  NEC: 'Negros Occidental',
  NER: 'Negros Oriental',
  NSA: 'Northern Samar',
  NUE: 'Nueva Ecija',
  NUV: 'Nueva Vizcaya',
  MDC: 'Occidental Mindoro',
  MDR: 'Oriental Mindoro',
  PLW: 'Palawan',
  PAM: 'Pampanga',
  PAN: 'Pangasinan',
  QUE: 'Quezon',
  QUI: 'Quirino',
  RIZ: 'Rizal',
  ROM: 'Romblon',
  WSA: 'Samar',
  SAR: 'Sarangani',
  SIQ: 'Siquijor',
  SOR: 'Sorsogon',
  SCO: 'South Cotabato',
  SLE: 'Southern Leyte',
  SUK: 'Sultan Kudarat',
  SLU: 'Sulu',
  SUN: 'Surigao del Norte',
  SUR: 'Surigao del Sur',
  TAR: 'Tarlac',
  TAW: 'Tawi-Tawi',
  ZMB: 'Zambales',
  ZAN: 'Zamboanga del Norte',
  ZAS: 'Zamboanga del Sur',
  ZSI: 'Zamboanga Sibugay',
  '00': 'Metro Manila',
};

wc_provinces['PK'] = {
  JK: 'Azad Kashmir',
  BA: 'Balochistan',
  TA: 'FATA',
  GB: 'Gilgit Baltistan',
  IS: 'Islamabad Capital Territory',
  KP: 'Khyber Pakhtunkhwa',
  PB: 'Punjab',
  SD: 'Sindh',
};

wc_provinces['RO'] = {
  AB: 'Alba',
  AR: 'Arad',
  AG: 'Argeș',
  BC: 'Bacău',
  BH: 'Bihor',
  BN: 'Bistrița-Năsăud',
  BT: 'Botoșani',
  BR: 'Brăila',
  BV: 'Brașov',
  B: 'București',
  BZ: 'Buzău',
  CL: 'Călărași',
  CS: 'Caraș-Severin',
  CJ: 'Cluj',
  CT: 'Constanța',
  CV: 'Covasna',
  DB: 'Dâmbovița',
  DJ: 'Dolj',
  GL: 'Galați',
  GR: 'Giurgiu',
  GJ: 'Gorj',
  HR: 'Harghita',
  HD: 'Hunedoara',
  IL: 'Ialomița',
  IS: 'Iași',
  IF: 'Ilfov',
  MM: 'Maramureș',
  MH: 'Mehedinți',
  MS: 'Mureș',
  NT: 'Neamț',
  OT: 'Olt',
  PH: 'Prahova',
  SJ: 'Sălaj',
  SM: 'Satu Mare',
  SB: 'Sibiu',
  SV: 'Suceava',
  TR: 'Teleorman',
  TM: 'Timiș',
  TL: 'Tulcea',
  VL: 'Vâlcea',
  VS: 'Vaslui',
  VN: 'Vrancea',
};

wc_provinces['TH'] = {
  'TH-37': 'Amnat Charoen',
  'TH-15': 'Ang Thong',
  'TH-14': 'Ayutthaya',
  'TH-10': 'Bangkok',
  'TH-38': 'Bueng Kan',
  'TH-31': 'Buri Ram',
  'TH-24': 'Chachoengsao',
  'TH-18': 'Chai Nat',
  'TH-36': 'Chaiyaphum',
  'TH-22': 'Chanthaburi',
  'TH-50': 'Chiang Mai',
  'TH-57': 'Chiang Rai',
  'TH-20': 'Chonburi',
  'TH-86': 'Chumphon',
  'TH-46': 'Kalasin',
  'TH-62': 'Kamphaeng Phet',
  'TH-71': 'Kanchanaburi',
  'TH-40': 'Khon Kaen',
  'TH-81': 'Krabi',
  'TH-52': 'Lampang',
  'TH-51': 'Lamphun',
  'TH-42': 'Loei',
  'TH-16': 'Lopburi',
  'TH-58': 'Mae Hong Son',
  'TH-44': 'Maha Sarakham',
  'TH-49': 'Mukdahan',
  'TH-26': 'Nakhon Nayok',
  'TH-73': 'Nakhon Pathom',
  'TH-48': 'Nakhon Phanom',
  'TH-30': 'Nakhon Ratchasima',
  'TH-60': 'Nakhon Sawan',
  'TH-80': 'Nakhon Si Thammarat',
  'TH-55': 'Nan',
  'TH-96': 'Narathiwat',
  'TH-39': 'Nong Bua Lam Phu',
  'TH-43': 'Nong Khai',
  'TH-12': 'Nonthaburi',
  'TH-13': 'Pathum Thani',
  'TH-94': 'Pattani',
  'TH-82': 'Phang Nga',
  'TH-93': 'Phatthalung',
  'TH-56': 'Phayao',
  'TH-67': 'Phetchabun',
  'TH-76': 'Phetchaburi',
  'TH-66': 'Phichit',
  'TH-65': 'Phitsanulok',
  'TH-54': 'Phrae',
  'TH-83': 'Phuket',
  'TH-25': 'Prachin Buri',
  'TH-77': 'Prachuap Khiri Khan',
  'TH-85': 'Ranong',
  'TH-70': 'Ratchaburi',
  'TH-21': 'Rayong',
  'TH-45': 'Roi Et',
  'TH-27': 'Sa Kaeo',
  'TH-47': 'Sakon Nakhon',
  'TH-11': 'Samut Prakan',
  'TH-74': 'Samut Sakhon',
  'TH-75': 'Samut Songkhram',
  'TH-19': 'Saraburi',
  'TH-91': 'Satun',
  'TH-17': 'Sing Buri',
  'TH-33': 'Sisaket',
  'TH-90': 'Songkhla',
  'TH-64': 'Sukhothai',
  'TH-72': 'Suphan Buri',
  'TH-84': 'Surat Thani',
  'TH-32': 'Surin',
  'TH-63': 'Tak',
  'TH-92': 'Trang',
  'TH-23': 'Trat',
  'TH-34': 'Ubon Ratchathani',
  'TH-41': 'Udon Thani',
  'TH-61': 'Uthai Thani',
  'TH-53': 'Uttaradit',
  'TH-95': 'Yala',
  'TH-35': 'Yasothon',
};

wc_provinces['TR'] = {
  TR01: 'Adana',
  TR02: 'Adıyaman',
  TR03: 'Afyon',
  TR04: 'Ağrı',
  TR05: 'Amasya',
  TR06: 'Ankara',
  TR07: 'Antalya',
  TR08: 'Artvin',
  TR09: 'Aydın',
  TR10: 'Balıkesir',
  TR11: 'Bilecik',
  TR12: 'Bingöl',
  TR13: 'Bitlis',
  TR14: 'Bolu',
  TR15: 'Burdur',
  TR16: 'Bursa',
  TR17: 'Çanakkale',
  TR18: 'Çankırı',
  TR19: 'Çorum',
  TR20: 'Denizli',
  TR21: 'Diyarbakır',
  TR22: 'Edirne',
  TR23: 'Elazığ',
  TR24: 'Erzincan',
  TR25: 'Erzurum',
  TR26: 'Eskişehir',
  TR27: 'Gaziantep',
  TR28: 'Giresun',
  TR29: 'Gümüşhane',
  TR30: 'Hakkari',
  TR31: 'Hatay',
  TR32: 'Isparta',
  TR33: 'İçel',
  TR34: 'İstanbul',
  TR35: 'İzmir',
  TR36: 'Kars',
  TR37: 'Kastamonu',
  TR38: 'Kayseri',
  TR39: 'Kırklareli',
  TR40: 'Kırşehir',
  TR41: 'Kocaeli',
  TR42: 'Konya',
  TR43: 'Kütahya',
  TR44: 'Malatya',
  TR45: 'Manisa',
  TR46: 'Kahramanmaraş',
  TR47: 'Mardin',
  TR48: 'Muğla',
  TR49: 'Muş',
  TR50: 'Nevşehir',
  TR51: 'Niğde',
  TR52: 'Ordu',
  TR53: 'Rize',
  TR54: 'Sakarya',
  TR55: 'Samsun',
  TR56: 'Siirt',
  TR57: 'Sinop',
  TR58: 'Sivas',
  TR59: 'Tekirdağ',
  TR60: 'Tokat',
  TR61: 'Trabzon',
  TR62: 'Tunceli',
  TR63: 'Şanlıurfa',
  TR64: 'Uşak',
  TR65: 'Van',
  TR66: 'Yozgat',
  TR67: 'Zonguldak',
  TR68: 'Aksaray',
  TR69: 'Bayburt',
  TR70: 'Karaman',
  TR71: 'Kırıkkale',
  TR72: 'Batman',
  TR73: 'Şırnak',
  TR74: 'Bartın',
  TR75: 'Ardahan',
  TR76: 'Iğdır',
  TR77: 'Yalova',
  TR78: 'Karabük',
  TR79: 'Kilis',
  TR80: 'Osmaniye',
  TR81: 'Düzce',
};

wc_provinces['TZ'] = {
  TZ01: 'Arusha',
  TZ02: 'Dar es Salaam',
  TZ03: 'Dodoma',
  TZ04: 'Iringa',
  TZ05: 'Kagera',
  TZ06: 'Pemba North',
  TZ07: 'Zanzibar North',
  TZ08: 'Kigoma',
  TZ09: 'Kilimanjaro',
  TZ10: 'Pemba South',
  TZ11: 'Zanzibar South',
  TZ12: 'Lindi',
  TZ13: 'Mara',
  TZ14: 'Mbeya',
  TZ15: 'Zanzibar West',
  TZ16: 'Morogoro',
  TZ17: 'Mtwara',
  TZ18: 'Mwanza',
  TZ19: 'Coast',
  TZ20: 'Rukwa',
  TZ21: 'Ruvuma',
  TZ22: 'Shinyanga',
  TZ23: 'Singida',
  TZ24: 'Tabora',
  TZ25: 'Tanga',
  TZ26: 'Manyara',
  TZ27: 'Geita',
  TZ28: 'Katavi',
  TZ29: 'Njombe',
  TZ30: 'Simiyu',
};

wc_provinces['US'] = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  AA: 'Armed Forces (AA)',
  AE: 'Armed Forces (AE)',
  AP: 'Armed Forces (AP)',
};

wc_provinces['ZA'] = {
  EC: 'Eastern Cape',
  FS: 'Free State',
  GP: 'Gauteng',
  KZN: 'KwaZulu-Natal',
  LP: 'Limpopo',
  MP: 'Mpumalanga',
  NC: 'Northern Cape',
  NW: 'North West',
  WC: 'Western Cape',
};

const wc_continents = ['AF!', 'NA!', 'OC!', 'AN!', 'AS!', 'EU!', 'SA!'];


export const findRegionParents = (search) => {

  const loop = (path, group) => {
    const keys = Object.keys(group);
    for (let index = 0; index < keys.length; index++) {

      const found = search == keys[index] ? path : loop([...path, keys[index]], group[keys[index]])
      if (found) {
        return found;
      }
    }
    return false
  }

  return loop([], REGION_TREE)
}

export const containsRegion = (search, region) => {
  const parents = findRegionParents(search)
  return [...parents].indexOf(region) != -1
}

export const COUNTRIES = wc_countries;
export const PROVINCES = wc_provinces;

export const CONTINENTS = wc_continents;


const territories_de =
  cldrTerritories_de.main.de.localeDisplayNames.territories;
const territories_en =
  cldrTerritories_en.main.en.localeDisplayNames.territories;


const comp = (a, b) => {
  if (a[1].toLowerCase() < b[1].toLowerCase()) { return -1 };
  if (a[1].toLowerCase() > b[1].toLowerCase()) { return 1 };
  return 0;
}



const countriesDE = Object.entries(territories_de).filter(t => COUNTRIES.indexOf(t[0]) != -1)
countriesDE.sort(comp)

const countriesEN = Object.entries(territories_en).filter(t => COUNTRIES.indexOf(t[0]) != -1)
countriesEN.sort(comp)

const continentsDE = Object.entries(CONTINENT_LOCALE_MAP).map(([wc, uni]) => [wc, territories_de[uni]])
continentsDE.sort(comp)

const continentsEN = Object.entries(CONTINENT_LOCALE_MAP).map(([wc, uni]) => [wc, territories_en[uni]])
continentsEN.sort(comp)

export const COUNTRY_LOCALES = {
  de: countriesDE,
  en: countriesEN,
};

export const CONTINENT_LOCALES = {
  de: continentsDE,
  en: continentsEN,
};

export const CONTINENT_AND_COUNTRY_LOCALES = { de: [['WW!', 'Weltweit'], ...continentsDE, ...countriesDE], en: [['WW!', 'Worldwide'], ...continentsEN, ...countriesEN] }

const getCountryTranslation = (key) => {
  const lang = LOCALES.indexOf(i18n.language) != -1 ? i18n.language : LOCALES[0];
  const loc = CONTINENT_AND_COUNTRY_LOCALES[lang] ? CONTINENT_AND_COUNTRY_LOCALES[lang].find(c => c[0] == key) : null
  return loc ? loc[1] : ''
}


export const parseRegion = region => {
  return region
    ? region.split(',').reduce((prev, curr) => {
      const add = [];
      const parts = curr.split(REGION_NEGATION_SEPARATOR);

      const main = getCountryTranslation(parts[0]);
      const exceptions = parts[1] && parts[1].split(REGION_EXCEPTION_SEPARATOR).map(c => getCountryTranslation(c));

      add.push(`${main}${exceptions ? ` ${i18n.t('prize_region_except', {
        regions: exceptions.join(` ${i18n.t('and')} `),
      })}`
        : ''
        }`)


      return [...prev, ...add];
    }, [])
    : [];
};
