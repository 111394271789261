import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { media } from 'styles';
import { deleteCouponRequestAction } from 'rdx/modules/api';
import { Translation } from 'react-i18next';
import Button from 'common/MaterialUI/Button'
import { H6ExtraBoldGrey } from '../../../../styles/Kit/H6ExtraBold';

const Layout = styled.div`
  display: flex;
  padding: 15px 0;
  margin-bottom: 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #f0f0f0;

  ${media.tabletMax`
    flex-direction: column;
    align-items: flex-start;
  `};
`;

const Title = styled.div`
  color: #9c9c9c;
  ${H6ExtraBoldGrey}
  white-space: nowrap;
`;

const List = styled.div`
  > a {
    border-radius: 25px;
    box-shadow: inset 0 1px 0 0 #cdcdcd;
    white-space: nowrap;
    flex: 0 1 auto;

    display: flex;
    font-weight: 800;
    font-size: 11px;

    justify-content: center;

    align-items: center;
    padding: 2px 12px;
    cursor: pointer;
    color: white;
    background: #36a6fc;
    text-shadow: 0 1px 0 rgba(29, 29, 29, 0.2);
    box-shadow: inset 0 2px 0 0 #4ccbfd;

    [class^='icon-'],
    [class*=' icon-'] {
      margin-left: 5px;
      color: white;
      overflow: visible;
    }

    margin: 5px;
  }
  margin: 10px -5px -5px -5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  align-self: center;

  ${media.tablet`
  justify-content: flex-end;
  margin: -5px;
  `};
`;

const CouponRow = ({ coupons, deleteCoupon }) => {
  return (
    <Translation>{t => (<Layout>
      <Title>{t("Raffles/Creator/MyOrder/CouponRow.active_coupons")}</Title>
      <List>
        {coupons.map((coupon, i) => (
          <Button small outline key={i} onClick={() => deleteCoupon(coupon.id)}>
            <span>{coupon.code}</span>
            <span className="icon-Ico16-X-Small" />
          </Button>
        ))}
      </List>
    </Layout>)}</Translation>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteCoupon: couponId => dispatch(deleteCouponRequestAction({ couponId })),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(CouponRow);
