import { createLogic } from 'redux-logic';
import { RESTAPI } from 'rdx/modules/api/rest';
import { PRODUCTS_SEARCH_REQUEST, getProductsSearchResponseAction } from '../modules/products';

const getProductsSearchRequestLogic = createLogic({
  type: PRODUCTS_SEARCH_REQUEST,
  latest: true,
  debounce: 1000,
  async process({ getState, action }, dispatch, done) {
    try {
      const resp = await RESTAPI.getProducts(action.payload)
      dispatch(getProductsSearchResponseAction(resp));
    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default getProductsSearchRequestLogic;
