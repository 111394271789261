import { runtimeConfig } from '../runtimeConfig';

let ReactGA = null; // ReactGA doesnt seem to work in SSR since 2.5.4

if (typeof window != 'undefined') {
  ReactGA = require('react-ga');
}

if (ReactGA) {
  ReactGA.initialize(runtimeConfig.GA_TRACKING_ID, {
    debug: false // process.env.NODE_ENV != 'production',
  });
}

export const Analytics = {
  pageview: title => {
    if (ReactGA) {
      ReactGA.pageview(title);
    }
  },
  event: (category, action) => {
    if (ReactGA) {
      ReactGA.event({
        category: category,
        action: action,
      });
    }
  },
};
