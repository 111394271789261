import { createLogic } from 'redux-logic';
import {
  POST_ENTRYWAYS_ENTITY_RESPONSE,
  getRaffleByIdRequestAction,
  getWaysByRaffleIdRequestAction,
  getWaysByRaffleIdStatusRequestAction,
  getRaffleByIdCouponsStatusRequestAction
} from '../modules/api';

const postEntryWaysEntityResponseLogic = createLogic({
  type: POST_ENTRYWAYS_ENTITY_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      const state = getState();
      const raffleId = state.location.payload && state.location.payload.EVENTID;
      if (raffleId) {
        dispatch(getRaffleByIdRequestAction({ id: raffleId }));
        dispatch(getRaffleByIdCouponsStatusRequestAction({ id: raffleId }))

        dispatch(
          state.auth.user ? getWaysByRaffleIdStatusRequestAction({
            id: raffleId,
          }) : getWaysByRaffleIdRequestAction({
            id: raffleId,
          }),
        );
      }

    } catch (error) {
      console.log(error);
    }
    done();
  },
});

export default postEntryWaysEntityResponseLogic;
