import React from 'react';
import { Translation, Trans } from 'react-i18next';
import Link from 'redux-first-router-link';
import Button from 'common/MaterialUI/Button';
import styled from 'styled-components'

const ButtonHolder = styled.div`
display: flex;
justify-content: center;
`



const Viewers = () => <Translation>{t => (<React.Fragment>
  <div className="ccc-introbox-wrapper">
    <div className="ccc-introbox-container">
      <img className="ccc-hide-on-desktop" src="/static/assets/images/quicktour/Quicktour-02-Viewer-Section1-mobile.png" alt=" " />
      <h2 className="ccc-h2">{t("QuickTour/Viewers.countless")}</h2>
      <h4 className="ccc-h4">{t("QuickTour/Viewers.wincool")}</h4>
      <img className="ccc-hide-on-mobile" src="/static/assets/images/quicktour/Quicktour-02-Viewer-Section1.png" alt=" " />
    </div>
  </div>

  <div className="ccc-contentbox-wrapper ccc-contentbox-whitebg ccc-contentbox-imageright">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Viewers.allinone")}</h6>
          <h3 className="ccc-h3">{t("QuickTour/Viewers.nevermiss")}</h3>
          <p className="ccc-p">{t("QuickTour/Viewers.everygiveaway")}</p>
          <hr />
        </article>
      </div>
      <div className="ccc-contentbox-column-image">
        <video playsInline autoPlay loop muted>
          <source src="/static/assets/images/quicktour/Quicktour-02-Viewer-Section2.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>

  <div className="ccc-contentbox-wrapper ccc-contentbox-imageleft">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-image">
        <img src="/static/assets/images/quicktour/Quicktour-02-Viewer-Section3-en.gif" alt=" " />
      </div>
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Viewers.variousentry")}</h6>
          <h3 className="ccc-h3">{t("QuickTour/Viewers.showyoursupport")}</h3>
          <p className="ccc-p">{t("QuickTour/Viewers.gheedgiveawayssupport")}</p>
          <hr />
        </article>
      </div>
    </div>
  </div>

  <div className="ccc-contentbox-wrapper ccc-contentbox-whitebg ccc-contentbox-imageright">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Viewers.communityfocus")}</h6>
          <h3 className="ccc-h3"><Trans i18nKey="QuickTour/Viewers.earnloyaltycoins">Earn <span className="ccc-orange">Loyalty Coins</span> by actively watching your favorite streamers</Trans></h3>
          <p className="ccc-p">{t("QuickTour/Viewers.everyfullhour")}</p>
          <hr />
        </article>
      </div>
      <div className="ccc-contentbox-column-image">
        <video playsInline autoPlay loop muted>
          <source src="/static/assets/images/quicktour/Quicktour-02-Viewer-Section4.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>

  <div className="ccc-contentbox-wrapper ccc-contentbox-imageleft">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-image">
        <img src="/static/assets/images/quicktour/Quicktour-02-Viewer-Section5.png" alt=" " />
      </div>
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Viewers.regionalfilters")}</h6>
          <h3 className="ccc-h3">{t("QuickTour/Viewers.globally")}</h3>
          <p className="ccc-p">{t("QuickTour/Viewers.withourregion")}</p>
          <hr />
        </article>
      </div>
    </div>
  </div>

  <div className="ccc-cta-wrapper">
    <div className="ccc-cta-container">
      <h2 className="ccc-h2">{t("QuickTour/Viewers.readysetgo")}</h2>
      <h4 className="ccc-h4"><Trans i18nKey="QuickTour/Viewers.itstime">It’s time to enter your very first GHEED giveaway. Any questions? You’ll find more detailed information about GHEED and it’s features in our <Link to="/contact">support area</Link>.</Trans></h4>
      <ButtonHolder><Button component={Link} white to="/giveaways">{t("QuickTour/Viewers.viewgiveaways")}</Button></ButtonHolder>
    </div>
  </div>
</React.Fragment>)}</Translation>

export default Viewers