import { handleActions } from 'redux-actions';
import { GET_BLOGPOST_BY_SLUG_RESPONSE, GET_CSS_RESPONSE } from '../api/index';

const initialState = {
  data: {},
  styles: null
};

export const blogPostsReducer = handleActions(
  {
    [GET_BLOGPOST_BY_SLUG_RESPONSE]: (state, action) => ({
      ...state,
      data: { ...state.data, [action.payload.slug]: action.payload.content },
    }),
    [GET_CSS_RESPONSE]: (state, action) => ({
      ...state,
      styles: action.payload.url
    }),
  },
  initialState,
);

export default blogPostsReducer;
