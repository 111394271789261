import { TIME_TO_IDLE } from 'helpers/const';

const idleListeners = [];
let idle = false;
let timer;
let lastActivity = new Date().getTime();

const restartCountdown = () => {

  if (timer) {
    clearTimeout(timer)
  }

  timer = setTimeout(() => {
    idle = true;
    idleListeners.forEach(listener => {
      listener({ idle });
    });
  }, TIME_TO_IDLE)
}

const handleActivity = event => {
  if (idle) {
    idle = false;
    idleListeners.forEach(listener => {
      listener({ idle, idleTime: new Date().getTime() - lastActivity });
    });
  }
  lastActivity = new Date().getTime();
  restartCountdown();
};

if (typeof window != 'undefined') {
  document.addEventListener('mousemove', handleActivity, { passive: true });
  document.addEventListener('keydown', handleActivity, { passive: true });
}

export const addIdleStateListener = listener => {
  idleListeners.push(listener);
};

export const removeIdleStateListener = listener => {
  let i = idleListeners.indexOf(listener);
  if (i != -1) {
    idleListeners.splice(i, 1);
  }
};


if (typeof window != 'undefined') {
  restartCountdown();
}