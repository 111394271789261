import { createLogic } from 'redux-logic';
import { GET_WAYS_BY_RAFFLE_ID_STATUS_RESPONSE, getRaffleByIdRequestAction } from '../modules/api/index';
import { WAYTYPEID_GHEED_ENTER_ANOTHER_GIVEAWAY } from 'helpers/const';

const getWaysByRaffleIdStatusResponseLogic = createLogic({
  type: GET_WAYS_BY_RAFFLE_ID_STATUS_RESPONSE,

  process({ getState, action }, dispatch, done) {
    try {
      let giveawaysToLoad = [];

      if (action.payload.eventways && action.payload.eventways.length) {
        action.payload.eventways.forEach(obj => {
          giveawaysToLoad = giveawaysToLoad.concat(obj.ways.filter(w => w.WAYTYPEID == WAYTYPEID_GHEED_ENTER_ANOTHER_GIVEAWAY).map(w => w.BENEFICIARY))
        });
      }

      if (giveawaysToLoad.length) {
        dispatch(getRaffleByIdRequestAction({ id: giveawaysToLoad }))
      }

    } catch (error) {
      console.log(error);
    }


    done();
  },
});

export default getWaysByRaffleIdStatusResponseLogic;
