import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import RegionLearnMore from '../Raffles/RegionLearnMore';

const Layout = styled.div`
  font-size: 11px;
  color: #fc521e;

  display: flex;
  align-items: center;

  > span:first-child {
    margin-right: 5px;
  }
`;

export const RegionNote = ({ prizes }) => {
  const hasRegionProducts = prizes.filter(prz => prz.product.region).length > 0;

  return hasRegionProducts ? (
    <Layout>
      <span className="icon-Ico16-Globe" />
      <div>
        <Trans i18nKey="ga_region_note">
          <b>NOTE:</b> Some prizes in this giveaway can only be shipped to
          certain countries.
        </Trans>{' '}
        <RegionLearnMore />
      </div>
    </Layout>
  ) : null;
};

export default RegionNote;
