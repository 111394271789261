import { handleActions, createAction, combineActions } from 'redux-actions';

export const GET_IMAGES_LIST_REQUEST = 'stats/GET_IMAGES_LIST_REQUEST';
export const GET_IMAGES_LIST_RESPONSE = 'stats/GET_IMAGES_LIST_RESPONSE';
export const GET_IMAGES_LIST_ERROR = 'stats/GET_IMAGES_LIST_ERROR';

export const POST_IMAGES_WITHFORM_REQUEST = 'stats/POST_IMAGES_WITHFORM_REQUEST';
export const POST_IMAGES_WITHFORM_ERROR = 'stats/POST_IMAGES_WITHFORM_ERROR';
export const POST_IMAGES_WITHFORM_RESPONSE = 'stats/POST_IMAGES_WITHFORM_RESPONSE';
export const GET_IMAGES_PRODUCTS_WITHFORM_REQUEST = 'stats/GET_IMAGES_PRODUCTS_WITHFORM_REQUEST';
export const GET_IMAGES_PRODUCTS_WITHFORM_ERROR = 'stats/GET_IMAGES_PRODUCTS_WITHFORM_ERROR';
export const GET_IMAGES_PRODUCTS_WITHFORM_RESPONSE = 'stats/GET_IMAGES_PRODUCTS_WITHFORM_RESPONSE';

export const getImagesListRequestAction = createAction(GET_IMAGES_LIST_REQUEST);
export const getImagesListResponseAction = createAction(GET_IMAGES_LIST_RESPONSE);
export const getImagesListErrorAction = createAction(GET_IMAGES_LIST_ERROR);

export const postImagesRequestWithFormValue = createAction(POST_IMAGES_WITHFORM_REQUEST);
export const getImagesProductsRequestWithFormValue = createAction(GET_IMAGES_PRODUCTS_WITHFORM_REQUEST);
export const postImagesResponseWithFormValue = createAction(POST_IMAGES_WITHFORM_RESPONSE);
export const postImagesErrorWithFormValue = createAction(POST_IMAGES_WITHFORM_ERROR);
export const getImagesProductsResponseWithFormValue = createAction(GET_IMAGES_PRODUCTS_WITHFORM_RESPONSE);
export const getImagesProductsErrorWithFormValue = createAction(GET_IMAGES_PRODUCTS_WITHFORM_ERROR);


export const IMAGES_LIST_BANNERS = "myBanners";
export const IMAGES_LIST_BANNERTEMPLATES = "bannerTemplates";
export const IMAGES_LIST_BACKGROUNDS = "myBackgrounds";
export const IMAGES_LIST_BACKGROUNDTEMPLATES = "backgroundTemplates";

const initialState = {
  users: {},
  coinleaders: {},
  uploading: false,

  [IMAGES_LIST_BANNERS]: {
    images: [],
    loading: false,
    loaded: false,
  },

  [IMAGES_LIST_BACKGROUNDS]: {
    images: [],
    loading: false,
    loaded: false,
  },

  [IMAGES_LIST_BANNERTEMPLATES]: {
    images: [],
    loading: false,
    loaded: false,
  },

  [IMAGES_LIST_BACKGROUNDTEMPLATES]: {
    images: [],
    loading: false,
    loaded: false,
  }

};

export const assetsReducer = handleActions(
  {
    [combineActions(POST_IMAGES_WITHFORM_REQUEST, GET_IMAGES_PRODUCTS_WITHFORM_REQUEST)]: (state, action) => {
      return { ...state, uploading: action.payload && action.payload.length && action.payload.length == 3 ? action.payload[2] : true }
    },

    [combineActions(POST_IMAGES_WITHFORM_ERROR, GET_IMAGES_PRODUCTS_WITHFORM_ERROR)]: (state, action) => {
      return { ...state, uploading: false }
    },

    [combineActions(POST_IMAGES_WITHFORM_RESPONSE, GET_IMAGES_PRODUCTS_WITHFORM_RESPONSE)]: (state, action) => {
      return { ...state, uploading: false }
    },

    [GET_IMAGES_LIST_REQUEST]: (state, action) => {
      return {
        ...state,
        [action.payload[1]]: {
          ...(state[action.payload[1]] || []),
          loading: true,
        },
      };
    },

    [GET_IMAGES_LIST_RESPONSE]: (state, action) => {

      const images = action.payload[0].eventpics.filter(
        p => p.uuid && p.variants.length > 0,
      );

      images.reverse();

      return {
        ...state,
        [action.payload[1]]: {
          ...(state[action.payload[1]] || []),
          loading: false,
          loaded: true,
          total_pages: action.payload[0].total_pages,
          current_page: action.payload[0].current_page,
          images
        },
      };
    },
  },
  initialState,
);

export default assetsReducer;
