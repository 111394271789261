import styled from 'styled-components';
import React from 'react';
import DropDownController from 'common/BaseDropDown';
import DropDownList from 'common/BaseDropDown/DropDownList';
import { IconButton } from 'common/IconButton';
import propTypes from 'prop-types';
import { NavLink } from 'redux-first-router-link';
import { Translation } from 'react-i18next';
import { helpers } from 'helpers';
import { ROUTE_TYPE_DASHBOARD_STREAMER, ROUTE_TYPE_DASHBOARD_VIEWER } from 'helpers/const';
import { media } from 'styles/index';

const Layout = styled((props) => <DropDownController {...props} />)`

${media.tablet`
  display: none;
`}


  &.burger.dropdown.open > *:first-child {
    color: white;
  }

  &.burger .dropdownlist.open {
    z-index: 100;
    min-width: 300px;
    top: 66px;
    right: 0px;
    left: 0px;
    box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
      0 1px 1px 0 rgba(29, 29, 29, 0.1), 0 15px 25px 0 rgba(29, 29, 29, 0.3);

    position: fixed;

    > a {
      display: block;
      font-size: 14px;
      cursor: pointer;
      padding: 2px 0;
    
      font-family: Nunito;
      font-size: 14px;
      font-weight: 800;
      text-transform: uppercase;
    
      &.green {
        color: #41a967;
      }
      
      &.grey {
        color: #808080;
        pointer-events: none;
      }
    
      &.active {
        color: white;
      }
    
      cursor: pointer;
      :hover {
        color: #4ccbfd;
      }
    }
  }
`;


const LinkSection = styled.div`

  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  background: #1c1c1c;
  border-top: solid 2px #3a3a3a;

  > a {
    display: block;
    font-size: 14px;
    cursor: pointer;
    padding: 2px 0;

    font-family: Nunito;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;

    &.green {
      color: #41a967;
    }

    &.grey {
      color: #808080;
      pointer-events: none;
    }

    &.active {
      color: white;
    }

    cursor: pointer;
    :hover {
      color: #4ccbfd;
    }
  }
`;

const Link = styled(NavLink)`
  &.green {
    color: #41a967;
  }

  &.grey {
    color: #808080;
    pointer-events: none;
  }

  span {
    margin-right: 0;
  }
`;

class BurgerButton extends React.PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      dropDownMethods: { toggleOpen },
      dropDownState: { open },
    } = this.context;

    if (nextProps.scrolling && open) {
      toggleOpen(false);
    }
  }

  render() {
    const {
      dropDownState: { open },
    } = this.context;
    return (
      <IconButton
        className={`${open ? 'icon-Ico16-X-Medium' : 'icon-Ico16-Menu'}`}
      />
    );
  }

  static contextTypes = {
    dropDownState: propTypes.any.isRequired,
    dropDownMethods: propTypes.any.isRequired,
  };
}


class Burger extends React.PureComponent {

  render() {
    const { authenticated, isStreamer, user, logout, scrolling, routeType } = this.props;
    return <Translation>
      {t => (<Layout className="burger">
        <BurgerButton scrolling={scrolling} />
        <DropDownList
          onClick={event => {
            if (event.target.nodeName.toLowerCase() != 'a') {
              helpers.stopPropagation(event);
            }
          }}
        >
          {!authenticated && (
            <LinkSection>
              <Link
                to="/giveaways"
              >
                {t('browse_giveaways')}
              </Link>
              <Link to="/quicktour">{t('quick_tour')}</Link>
            </LinkSection>
          )}

          {authenticated && isStreamer && (
            <LinkSection>
              <Link to="/dashboard/streamer" isActive={() => [ROUTE_TYPE_DASHBOARD_STREAMER, ROUTE_TYPE_DASHBOARD_VIEWER].indexOf(routeType) != -1}>{t('dashboard')}</Link>
              <Link to="/giveaways">{t('browse_giveaways')}</Link>
              <Link to="/quicktour">{t('quick_tour')}</Link>
              <Link className="grey" to="/create/prizes">
                <span className="icon-Ico16-Plus-Medium" />{' '}
                {t('new_giveaway')}
              </Link>
            </LinkSection>
          )}

          {authenticated &&
            !isStreamer && (
              <LinkSection>
                <Link to="/dashboard/viewer" isActive={() => [ROUTE_TYPE_DASHBOARD_STREAMER, ROUTE_TYPE_DASHBOARD_VIEWER].indexOf(routeType) != -1}>{t('dashboard')}</Link>
                <Link
                  to="/giveaways"
                >
                  {t('browse_giveaways')}
                </Link>
                <Link to="/quicktour">{t('quick_tour')}</Link>
              </LinkSection>
            )}



          {authenticated && (
            <LinkSection>
              {isStreamer && (
                <Link to={`/streamers/${user.USERID}`}>
                  {t('public_profile')}
                </Link>
              )}
              <Link to="/settings">{t('title_settings')}</Link>
              <a onClick={logout}>{t('logout')}</a>
            </LinkSection>
          )}
          {authenticated && (
            <LinkSection>
              <Link to="/contact">{t('customer_support')}</Link>
            </LinkSection>
          )}
        </DropDownList>
      </Layout>
      )}
    </Translation>
  }


};

export default Burger;
