import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';

const Layout = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0 20px;

  ${media.tablet`
  padding: 0;
  justify-content: center;
  min-height: 66px;
    flex-direction: column;

    & + & {
      border-left: solid 2px #f0f0f0;
    }
  `};
`;

const Title = styled.div`
  font-size: 13px;
  white-space: nowrap;

  font-weight: bold;
  color: #9c9c9c;
  text-align: left;

  ${media.tablet`
      display: inline;
      text-align: center;
    `};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 38%;

  ${media.tabletMax`
  justify-content: flex-end;
    `};
`;

export const Label = ({ title, children }) => (
  <Layout>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </Layout>
);
