import React from 'react';
import Html from 'common/Html';
import Section from 'common/Section';
import { PageLayout } from 'common/PageLayout';
import { ContentBox } from 'common/ContentBox';
import { AnchorNavigation } from 'common/AnchorNavigation';
import { AnchorLink } from 'common/AnchorNavigation/AnchorLink';
import styled from 'styled-components';
import { media } from 'styles';

const GheedConceptImg = styled.div`
  background: url(/static/assets/images/gheed-concept-small.png) no-repeat;
  width: 260px;
  height: 212px;
  margin: 20px 0;

  ${media.tablet`
    background: url(/static/assets/images/gheed-concept-medium.png) no-repeat;
  width: 550px;
  height: 200px;

  `};

  ${media.widescreen`
background: url(/static/assets/images/gheed-concept-big.png) no-repeat;
width: 880px;
height: 200px;

`};
`;

const Guide = props => (
  <PageLayout>
    <Section>
      <AnchorNavigation title="Table of Contents">
        <AnchorLink offset={-180} target="introduction">
          Introduction
        </AnchorLink>
        <AnchorLink offset={-180} target="whatisgheed">
          What is GHEED?
        </AnchorLink>
        <AnchorLink offset={-180} target="ourkeyfeatures">
          Our key features
        </AnchorLink>
        <AnchorLink offset={-180} target="ouralphatest">
          Our alpha test
        </AnchorLink>
        <AnchorLink offset={-180} target="howtotest">
          How to test
        </AnchorLink>
        <AnchorLink offset={-180} target="rewards">
          Rewards
        </AnchorLink>
      </AnchorNavigation>
      <ContentBox withsidenav title="Gheed Alpha Guide" id="introduction">
        <Html>
          <h1>Thank you for joining our alpha test</h1>
          <p>
            Before you start using the site, please take the time to read
            through this short introduction about our product, how and what to
            test and where to give feedback. Furthermore please{' '}
            <a
              href="https://discord.gg/CCTuaCQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              join our Discord server
            </a>
            . There you
            {'’'}
            ll get updates on the alpha test and it
            {'’'}s the easiest way to send us your feedback.
          </p>
          <h2 id="whatisgheed">What is GHEED?</h2>
          <p>
            GHEED is a giveaway community platform for Twitch streamers and
            their audiences. It allows Streamers to launch their own giveaways
            with cool prizes for their viewers. These prizes are provided by our
            sponsors. GHEED comes with a lot of unique and amazing features,
            tailor-made for Twitch streamers and their needs.
          </p>
          <GheedConceptImg />
          <p>
            GHEED giveaways are designed to support its hosts. You decide how
            your viewers can enter your giveaways by choosing entry methods like
            following or subscribing your Twitch channel or other social
            profiles. To reward loyal viewers and engage watching your stream,
            we created a coin reward system. By watching a streamer
            {'’'}s channel you
            {'’'}
            ll be earning loyalty coins which can be spent on giveaways. Just
            like entry tickets - the more coins you spent on a giveaway the
            higher are your chances of winning it.
          </p>
          <h2 id="ourkeyfeatures">Key features</h2>

          <p>
            <b>Exposure</b>
            <br />
            <br /> We understand that exposure is a helpful boost for streamers
            creating and growing a community regardless of whether you‘re an
            established streamer or new to the plattform. With GHEED you can
            list and promote every giveaway on our platform. Your giveaways will
            attract new viewers and help your channel grow. No matter if
            short-term or long-term giveaway with small or big prizepool: every
            giveaway will have its place on GHEED.
          </p>
          <p>
            <b>No Effort</b>
            <br />
            <br />
            All you have to do is choosing your prizes, settings up your
            giveaway and launching it - we handle the rest. There
            {'’'}s no managing effort on your side such as taking care that
            winners receive their prizes. Of course you will always be informed
            about the current status of that process.
          </p>
          <p>
            <b>Tailor-Made</b>
            <br />
            <br /> We want your channel to grow, so we designed every feature to
            support that goal. That also means being able to launch a new
            giveaways in just a few clicks. And the best part: you
            {'’'}
            re only paying for the prizes you want to put in your giveaways.
            Using GHEED is and will always be completely free. No matter if you
            {'’'}
            re a streamer, viewer or if you just stopped by to loot a little
            here and there.
          </p>
        </Html>
      </ContentBox>
      <ContentBox withsidenav>
        <Html>
          <h1 id="ouralphatest">Our Alpha Test</h1>
          <p>
            The goal of this alpha phase is to test certain processes like
            entering or creating a giveaway in terms of usability, functionality
            and accessability. We won
            {'’'}t use real data yet, so you can only create fake giveaways with
            fake prices. Also your viewers won
            {'’'}t have access to your site yet. It
            {'’'}s an internal test for us to see, if our product works and if
            you can use it without problems.
          </p>
          <h2 id="howtotest">So... what do I do now?</h2>
          <p>
            If you head over to our discord server, you
            {'’'}
            ll find the {'"'}
            alpha test
            {'"'} category. In its channel {'"'}
            current-tasks
            {'"'} we guide you by giving you some basic tasks to perform on our
            page. For example
            {'"'}
            Browse the list of active giveaways and join some of them
            {'"'} or
            {'"'}
            Launch your own giveaway
            {'"'}. These tasks will be updated regularly. For every task you
            {'’'}
            ll find a survey in the {'"'}
            feedback
            {'"'} channel, where you can tell us, if you were able to complete
            the task, how long it took and if you
            {'’'}
            ve encountered any problems performing it.
          </p>
          <p>
            And don
            {'’'}t forget: We
            {'’'}
            re not testing you, we
            {'’'}
            re testing our design. So, nothing you say or do is wrong. If you
            don
            {'’'}t like something or think it
            {'’'}s silly, just say it. You won
            {'’'}t be hurting our feelings ;-)
          </p>
          <h2 id="rewards">What do you get for testing our product?</h2>
          <p>
            During our upcoming beta test we will open the login for viewers and
            launch the first real giveaways. Among every alpha tester, who
            actively provided feedback by filling out our surveys or
            participated in our discord, we will give away a <b>100€ voucher</b>{' '}
            for Amazon, Steam or another platform of your choice .
          </p>
          <h2 className="green">
            Ok, that
            {'’'}s about it. You can now start to break our site ;-) Or head
            over to discord and have a look at your current tasks.
          </h2>
        </Html>
      </ContentBox>
    </Section>
  </PageLayout>
);

export default Guide;
