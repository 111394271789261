import { css } from 'styled-components';

export const BoxHeader = css`
  background-color: #f8f9fb;
  box-shadow: inset 0 -1px 0 0 #e1e2ef;
`;

export const FillDropShadow = css`
  box-shadow: 0 2px 4px 2px rgba(32, 32, 34, 0.05),
    0 1px 1px 0 rgba(32, 32, 34, 0.1);
  background-color: #ffffff;
  border: solid 1px #ffffff;
`;

export const FillDropShadowActive = css`
  box-shadow: 0 2px 4px 2px rgba(32, 32, 34, 0.05),
    0 1px 1px 0 rgba(32, 32, 34, 0.1);
  background-color: #ffffff;
  border: solid 1px #4ccbfd;
`;

export const FillDropShadowDisabled = css`
  box-shadow: 0 2px 4px 2px rgba(32, 32, 34, 0.05),
    0 1px 1px 0 rgba(32, 32, 34, 0.1);
  background-color: #f8f9fb;
  border: solid 1px #f8f9fb;
`;

export const FillButtonWhiteDefault = css`
    box-shadow: 0 2px 4px 2px rgba(32, 32, 34, 0.05), 0 1px 1px 0 rgba(32, 32, 34, 0.1);
    background-color: #ffffff;
    border: solid 1px #ffffff;
`;

export const FillButtonWhiteHover = css`
  box-shadow: 0 2px 4px 2px rgba(32, 32, 34, 0.05), 0 1px 1px 0 rgba(32, 32, 34, 0.1);
  background-color: #f8f9fb;
  border: solid 1px #4ccbfd;
`;


export const FillButtonWhiteActive = css`
  box-shadow: 0 2px 4px 2px rgba(32, 32, 34, 0.05), 0 1px 1px 0 rgba(32, 32, 34, 0.1);
  background-color: #ffffff;
  border: solid 1px #4ccbfd;
`;


export const FillInputOutlineDefault = css`
  box-shadow: inset 0 2px 0 0 #eaedf3;
  background-color: #ffffff;
  border: solid 1px #9697a1;
`;

export const FillInputOutlineActive = css`
  box-shadow: inset 0 2px 0 0 #eaedf3;
  background-color: #ffffff;
  border: solid 1px #36a6fc;
`;


export const FillButtonPrimaryDefault = css`
  box-shadow: inset 0 2px 0 0 #4ccbfd;
  background-color: #36a6fc;
  border: solid 1px #36a6fc;
`;

export const FillButtonPrimaryHover = css`
  box-shadow: inset 0 2px 0 0 #36a6fc;
  background-color: #2083fb;
  border: solid 1px #2083fb;
`;

export const FillButtonPrimaryActive = css`
  box-shadow: inset 0 -2px 0 0 #4ccbfd;
  background-color: #36a6fc;
  border: solid 1px #36a6fc;
`;

export const FillButtonSecondaryDefault = css`
  box-shadow: inset 0 2px 0 0 #5bcb8f;
  background-color: #41a967;
  border: solid 1px #41a967;
`;

export const FillButtonSecondaryHover = css`
  box-shadow: inset 0 2px 0 0 #41a967;
  background-color: #27873e;
  border: solid 1px #27873e;
`;

export const FillButtonSecondaryActive = css`
  box-shadow: inset 0 -2px 0 0 #5bcb8f;
  background-color: #41a967;
  border: solid 1px #41a967;
`;

export const FillButtonTertiaryDefault = css`
  box-shadow: inset 0 2px 0 0 #ff8661;
  background-color: #fc521e;
  border: solid 1px #fc521e;
`;

export const FillButtonTertiaryHover = css`
  box-shadow: inset 0 2px 0 0 #fc521e;
  background-color: #F52C0D;
  border: solid 1px #F52C0D;
`;

export const FillButtonTertiaryActive = css`
  box-shadow: inset 0 -2px 0 0 #ff8661;
  background-color: #fc521e;
  border: solid 1px #fc521e;
`;

export const FillButtonOutlineDefault = css`
  box-shadow: inset 0 2px 0 0 #fff;
  background: #f8f9fb;
  border: solid 1px #e1e2ef;
`;

export const FillButtonOutlineHover = css`
  box-shadow: inset 0 2px 0 0 #fff;
  background: #f8f9fb;
  border: solid 1px #4ccbfd;
`;

export const FillButtonOutlineActive = css`
  box-shadow: inset 0 2px 0 0 #fff;
  background: #f8f9fb;
  border: solid 1px #e1e2ef;
`;


export const FillButtonOutline2Default = css`
  background: transparent;
  box-shadow: inset 0 2px 0 0 rgba(76, 203, 253, 0.3);
  border: solid 1px #36a6fc;
`;

export const FillButtonOutline2Hover = css`
  background: transparent;
  box-shadow: inset 0 2px 0 0 rgba(76, 203, 253, 0.3);
  border: solid 1px #4ccbfd;
`;

export const FillButtonOutline2Active = css`
  background: transparent;
  box-shadow: inset 0 -2px 0 0 rgba(76, 203, 253, 0.3);
  border: solid 1px #36a6fc;
`;