import styled from 'styled-components';
import React from 'react';
import { H5ExtraBoldWhite } from '../../../../styles/Kit/H5ExtraBold';
import { BodyRegularBlack } from 'styles/Kit/BodyRegular';
import { BodySmallRegularBlack } from 'styles/Kit/BodySmallRegular';
import { H6ExtraBoldWhite } from '../../../../styles/Kit/H6ExtraBold';
import { media } from 'styles/index';
import Code from './Code';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';



const Layout = styled.div`
  
  border: solid 1px #e1e2ef;
  border-radius: 4px;

  margin: 0 20px 27px 20px;
  ${media.tablet`
  margin: 0 40px 27px 40px;
  `}
  
`;
const Header = styled.h3`
  background: ${({ unlocked }) => unlocked ? `#4ccbfd` : `#5bcb8f`};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 11px 16px;
  
  display: flex;
  justify-content: space-between;

  ${H6ExtraBoldWhite}

  ${media.tablet`
    ${H5ExtraBoldWhite}
  `}

  .icon-Ico34-LockOpen,.icon-Ico34-LockClosed {
    font-size: 20px;
  } 
`

const Body = styled.h3`
  flex: 1 1 auto;
  text-align: ${({ small }) => small ? 'left' : 'center'};
  margin-bottom: ${({ small }) => small ? '5px' : 'auto'};  
  

  ${BodySmallRegularBlack}
  ${media.tablet`
    ${({ small }) => small ? BodySmallRegularBlack : BodyRegularBlack}
  `}
`


const Image = styled.figure`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size:contain;
  border-radius: 3px;
  margin-right: 5px;
  width: 80px;
  height: 55px;
  flex: 0 0 auto;
`;


const BodyWrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
`

const ImageAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`

const Content = styled.div`
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`


const Item = ({ coupon, couponstatus, completed }) => {
  const unlocked = couponstatus;
  return (!completed && coupon) || (completed && coupon && couponstatus) ? (
    <Translation>{t => <Layout>
      <Header unlocked={unlocked}>{t(completed ? 'ga_coupon_dontforget' : unlocked ? `ga_coupon_unlocked` : `ga_coupon_locked`)} <span className={unlocked ? 'icon-Ico34-LockOpen' : 'icon-Ico34-LockClosed'} /></Header>
      <Content>
        <ImageAreaWrapper>
          <Image src={coupon.logolink} />
          <BodyWrapper>
            <Body small={unlocked} dangerouslySetInnerHTML={{ __html: unlocked ? coupon.descriptiontext : coupon.teasertext }} />
            {unlocked && <Code from-tablet couponstatus={couponstatus} />}
          </BodyWrapper>
        </ImageAreaWrapper>
        {unlocked && <Code until-tablet couponstatus={couponstatus} />}
      </Content>
    </Layout>}</Translation>
  ) : null
};


const mapStateToProps = (state, ownProps) => {
  return {
    couponstatus: state.coupons.couponstatus[ownProps.coupon.COUPONINFOID]
  }
}

export default connect(mapStateToProps)(Item);
