import React, {useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { uiInitStickyBottomAnchor } from "../store/modules/ui";
import { uiInitAdSiderailLeft } from "../store/modules/ui";
import { uiInitAdSiderailRight } from "../store/modules/ui";


function AdHandler() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.nitroAds && typeof window.nitroAds.createAd === 'function') {
      dispatch(uiInitStickyBottomAnchor());
      dispatch(uiInitAdSiderailLeft());
      dispatch(uiInitAdSiderailRight());
    }
    }, []);

  return (
    <>
    </>
  );
}

export default AdHandler;