import React from 'react';
import { WAYTYPEID_GHEED_SECRET_CODE, WAYTYPEID_OTHER_DOWNLOAD, WAYTYPEID_FACEBOOK_LIKE, WAYTYPEID_FACEBOOK_VISIT, WAYTYPEID_FACEBOOK_VIEW_POST, WAYTYPEID_GHEED_ENTER_ANOTHER_GIVEAWAY, WAYTYPEID_INSTAGRAM_VIEW_POST, WAYTYPES, PLATFORMS, WAYTYPEID_OTHER_VISIT, WAYTYPEID_GHEED_COINS, 
  WAYTYPEID_TWITCH_FOLLOW, 
  WAYTYPE_TWITCH_FOLLOW_TITLE,
  WAYTYPEID_TWITCH_SUBSCRIBE,
  WAYTYPE_TWITTER_FOLLOW_TITLE,
  WAYTYPEID_TWITTER_FOLLOW,
  WAYTYPE_TWITTER_RETWEET_TITLE,
  WAYTYPEID_TWITTER_RETWEET,
  WAYTYPE_TWITTER_VIEW_TWEET_TITLE,
  WAYTYPEID_TWITTER_VIEW_TWEET } from 'helpers/const';
import TwitterMasked from './TwitterMasked';
import TwitchMasked from './TwitchMasked';
import Twitch from './Twitch';
import Simple from './Simple';
import SpendCoins from './SpendCoins';
import Instagram from './Instagram';
import EnterOtherGiveaway from './EnterOtherGiveaway';
import Facebook from './Facebook';
import DownloadApp from './DownloadApp';
import SecretCode from './SecretCode';

const getMethodClass = (way) => {

  if (way.WAYTYPEID == WAYTYPEID_GHEED_SECRET_CODE) {
    return SecretCode;
  }

  if (way.WAYTYPEID == WAYTYPEID_OTHER_DOWNLOAD) {
    return DownloadApp;
  }

  if (way.WAYTYPEID == WAYTYPEID_GHEED_ENTER_ANOTHER_GIVEAWAY) {
    return EnterOtherGiveaway;
  }

  if (way.WAYTYPEID == WAYTYPEID_OTHER_VISIT && [WAYTYPE_TWITTER_FOLLOW_TITLE, WAYTYPE_TWITTER_RETWEET_TITLE, WAYTYPE_TWITTER_VIEW_TWEET_TITLE].indexOf(way.title) != -1) {
    return TwitterMasked;
  }

  if (way.WAYTYPEID == WAYTYPEID_OTHER_VISIT && [WAYTYPE_TWITCH_FOLLOW_TITLE].indexOf(way.title) != -1) {
    return TwitchMasked;
  }

  if ([WAYTYPEID_TWITCH_FOLLOW, WAYTYPEID_TWITCH_SUBSCRIBE].indexOf(way.WAYTYPEID) != -1) {
    return Twitch;
  }

  if (way.WAYTYPEID == WAYTYPEID_GHEED_COINS) {
    return SpendCoins;
  }

  if ([WAYTYPEID_INSTAGRAM_VIEW_POST].indexOf(way.WAYTYPEID) != -1) {
    return Instagram;
  }


  if ([WAYTYPEID_FACEBOOK_VIEW_POST, WAYTYPEID_FACEBOOK_LIKE, WAYTYPEID_FACEBOOK_VISIT].indexOf(way.WAYTYPEID) != -1) {
    return Facebook;
  }

  return Simple;
}

const getDisplayWay = (way) => {
  let displayWay = JSON.parse(JSON.stringify(way));

  if(way.WAYTYPEID == WAYTYPEID_OTHER_VISIT) {
    if(way.title == WAYTYPE_TWITTER_FOLLOW_TITLE) {
      displayWay.WAYTYPEID = WAYTYPEID_TWITTER_FOLLOW;
      displayWay.social_link = "https://twitter.com/{{beneficiary_display_name}}";
      displayWay.beneficiary_display_name = displayWay.beneficiary_display_name.replace('https://twitter.com/', '')
    }

    if([WAYTYPE_TWITTER_RETWEET_TITLE, WAYTYPE_TWITTER_VIEW_TWEET_TITLE].includes(way.title)) {
      if(way.title == WAYTYPE_TWITTER_RETWEET_TITLE) { 
        displayWay.WAYTYPEID = WAYTYPEID_TWITTER_RETWEET;
      }
      if(way.title == WAYTYPE_TWITTER_VIEW_TWEET_TITLE) {
        displayWay.WAYTYPEID = WAYTYPEID_TWITTER_VIEW_TWEET;
      }
      let twitterUrl = way.beneficiary_display_name;
      const urlParts = twitterUrl.replace('https://twitter.com/', '').split('/status/');
      
      displayWay.social_link = "https://twitter.com/{{BENEFICIARY}}/status/{{BENEFICIARY}}";
      displayWay.beneficiary_display_name = urlParts[0];
      displayWay.BENEFICIARY = urlParts[1];
    }

    if(way.title == WAYTYPE_TWITCH_FOLLOW_TITLE) {
      displayWay.WAYTYPEID = WAYTYPEID_TWITCH_FOLLOW;
      displayWay.social_link = "https://www.twitch.tv/{{beneficiary_display_name}}";
      displayWay.beneficiary_display_name = displayWay.beneficiary_display_name.replace('https://www.twitch.tv/', '')
    }

  }
  return displayWay;
}

const Methods = ({
  way,
  ...other
}) => {

  const WayClass = getMethodClass(way)
  const waytype = WAYTYPES[way.WAYTYPEID];
  const platform = waytype ? PLATFORMS[waytype.PLATFORMID] : null;
  const display_way = getDisplayWay(way);

  return <WayClass way={way} display_way={display_way} platform={platform} waytype={waytype} {...other} />

}

export default Methods