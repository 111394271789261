
import View from './View';
import { SHOP_ID_WOOCOMMERCE, SHOP_ID_PRIVATE } from 'helpers/const';
import { getProductsByIdRequestAction, getPrivateProductByEventidRequestAction } from 'rdx/modules/api';
import { connect } from 'react-redux';
import { utils } from 'helpers/utils';


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    refreshProducts: (products, privateProducts) => (raffle) => {
      if (raffle) {
        const shopproducts = utils.unique(raffle.prizes.filter(p => p.product.shop_id == SHOP_ID_WOOCOMMERCE && (!products || !products[p.product.shopproduct_id])).map(p => p.product.shopproduct_id));
        const privProducts = utils.unique(raffle.prizes.filter(p => p.product.shop_id == SHOP_ID_PRIVATE && (!privateProducts || !privateProducts[p.product.shopproduct_id])));

        shopproducts && shopproducts.length && dispatch(getProductsByIdRequestAction({ id: shopproducts }))
        privProducts && privProducts.length && dispatch(getPrivateProductByEventidRequestAction({ id: raffle.EVENTID }))
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.products.products,
    privateProducts: state.privateProducts.data
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {

  const { products, privateProducts } = stateProps
  const { refreshProducts } = dispatchProps

  return {

    refreshProducts: refreshProducts(products, privateProducts)
  }
}



export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View);