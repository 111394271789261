import { Translation } from 'react-i18next';
import styled from 'styled-components';
import React from 'react';
import { addResizeListener, removeResizeListener } from 'helpers/resize';
import DropDown from './DropDown';
import { NavLink } from 'redux-first-router-link';
import { H5ExtraBoldBlue } from '../../../../styles/Kit/H5ExtraBold';
import { media } from 'styles/index';

const Layout = styled.div`
  min-width: 0;
  position: relative;
  flex: 1 1 0%;
  display: flex;

  .drop {
    margin-left: 1.25em;
  }

  margin-right: 40px;

  ${media.tabletMax`
    display: none;
  `}

`;

const Bar = styled.div`
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  min-width: 90px;
  flex: 0 1 auto;

  > a {
    height: 36px;
    align-items: center;
    display: flex;
    flex: 0 1 auto;
    white-space: nowrap;
    color: #36a6fc;

    ${H5ExtraBoldBlue}

    + a {
      margin-left: 20px;
    }

    &.active {
      color: white;
    }

    cursor: pointer;
    :hover {
      color: #4ccbfd;
    }
  }
`;

const Link = styled(NavLink)`

  &.green {
    color: #41a967;
  }

  span {
    margin-right: 3px;

    :before {
      vertical-align: text-top;
    }
  }
`;




export class RugMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { dropped: 0, visWidth: 0 };
  }

  checkSize = () => {
    const { bar } = this;
    let newVisWidth = 0;
    if (bar) {
      const barBounds = bar.getBoundingClientRect();
      let newDropped = 0;

      bar.childNodes.forEach(node => {
        const nodeRect = node.getBoundingClientRect();

        const isDrop = nodeRect.top != barBounds.top
        newDropped = isDrop ? newDropped + 1 : newDropped;
        newVisWidth = !isDrop ? Math.max(newVisWidth, nodeRect.left - node.parentNode.getBoundingClientRect().left + nodeRect.width) : newVisWidth;
      });

      if (newDropped != this.state.dropped || newVisWidth != this.state.visWidth) {
        this.setState({ dropped: newDropped, visWidth: newVisWidth });
      }

    }
  };

  componentDidMount() {
    addResizeListener(this.checkSize);
  }

  componentWillUnmount() {
    removeResizeListener(this.checkSize);
  }



  render() {
    const { links, routeType } = this.props;
    const { dropped, visWidth } = this.state;
    return (
      <Translation>{t => <Layout {...this.props}>
        <Bar ref={n => (this.bar = n)}>{links.map(l => <Link className={l.classes} key={l.to} to={l.to} isActive={l.isActive ? l.isActive(routeType) : undefined}>{t(l.label)}</Link>)}</Bar>
        {dropped > 0 && (<DropDown visWidth={visWidth} items={links.filter((l, i) => i >= links.length - dropped)} />
        )}
      </Layout>}</Translation>
    );
  }
}

export default RugMenu;
