import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { media } from 'styles';
import DropDownList from 'common/BaseDropDown/DropDownList';
import DropDownController from 'common/BaseDropDown';
import { Translation } from 'react-i18next';

const Layout = styled.th`
  :not(:last-child) {
    padding-right: 10px;
  }

  ${media.desktop`
    .dropdown {
      display: none;
    }
  `};

  .dropdownlist {
    padding: 10px;
    top: 22px;
    right: 0;
    width: 100%;

    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
      0 1px 1px 0 rgba(29, 29, 29, 0.1), 0 15px 25px 0 rgba(29, 29, 29, 0.3);

    a {
      display: block;
      font-weight: normal;
      cursor: pointer;

      + a {
        margin-top: 5px;
      }
    }
  }

  :last-child {
    text-align: right;
  }

  ${media.desktopMax`
  :not(.first) {
    > span {
      display: none;
    }
  }
  `};

  padding-bottom: 5px;
  border-bottom: solid 2px #f0f0f0;
`;

const Header = styled.span`
  font-family: Nunito;
  font-size: 11px;
  font-weight: bold;
  color: #9c9c9c;
  text-transform: uppercase;
  white-space: nowrap;
`;

const Button = styled.a`
  font-weight: bold;
  font-size: 11px;

  color: #36a6fc;
  width: 100%;
  height: 20px;
  border-radius: 12.5px;
  padding: 0 10px;
  background-color: #f8f9fb;
  white-space: nowrap;
  cursor: pointer;
  min-width: 122px;
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: center;

  [class^='icon-'],
  [class*=' icon-'] {
    vertical-align: middle;
  }
`;

class CollapsingTh extends React.PureComponent {
  renderLinks(t) {
    const { column, columns, handleChangeActiveColumn } = this.props;
    const result = [];

    columns.forEach((c, i) => {
      if (c != column && i > 0) {
        result.push(
          <a onClick={() => handleChangeActiveColumn(i)} key={'d_' + c}>
            {t(c)}
          </a>,
        );
      }
    });

    return result;
  }

  render() {
    const { column, active, index } = this.props;
    return (
      <Translation>
        {t => (
          <Layout className={classNames({ active, first: index == 0 })}>
            <Header>{t(column)}</Header>
            {index != 0 && (
              <DropDownController>
                <Button>
                  {t(column)} <span className="icon-Ico16-ArrowDown-Small" />
                </Button>
                <DropDownList>{this.renderLinks(t)}</DropDownList>
              </DropDownController>
            )}
          </Layout>
        )}
      </Translation>
    );
  }
}
export default CollapsingTh;
