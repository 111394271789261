import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;

  text-align: center;
  a {
    cursor: pointer;
  }

  background: #5bcb8f;

  width: 300px;
  padding: 15px 20px;

  z-index: 71;
  position: fixed;
  bottom: 80px;
  left: 40px;

  border-radius: 6px;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1), 0 15px 25px 0 rgba(29, 29, 29, 0.3);
`;

const TextStyle = styled.div`
  font-size: 11px;
  text-align: left;

  a {
    color: #fff15a;
  }
`;

const Close = styled.div`
  position: absolute;
  right: -13px;
  top: -13px;
  cursor: pointer;
  z-index: 2;

  width: 34px;
  height: 34px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);
  border-radius: 17px;

  [class^='icon-Ico16-'],
  [class*=' icon-Ico16-'] {
    position: absolute;
    top: 9px;
    left: 9px;
    color: #36a6fc;
    cursor: pointer;
  }
`;

const FakeLink = styled.span`
cursor: pointer;
color: #fff15a;
`

export class CookieSettings extends React.PureComponent {
  componentDidMount() {
    const { cookieNotice, changeVisibilitySetting } = this.props;
    if (cookieNotice == null) {
      changeVisibilitySetting(true);
    }
  }

  render() {
    const {
      disableAnalytics,
      cookieNotice,
      changeDisableAnalyticsSetting,
      changeVisibilitySetting,
    } = this.props;

    return cookieNotice ? (
      <Translation>{t => <Layout>
        <TextStyle>
          {t('cookies_notice')}{' '}
          {!disableAnalytics && (
            <span>
              <FakeLink onClick={() => changeDisableAnalyticsSetting(true)}>
                {t('cookies_click')}
              </FakeLink>{' '}
              {t('cookies_opt_out')}
            </span>
          )}
          {disableAnalytics && (
            <span>
              <FakeLink onClick={() => changeDisableAnalyticsSetting(false)}>
                {t('cookies_click')}
              </FakeLink>{' '}
              {t('cookies_opt_in')}
            </span>
          )}
        </TextStyle>

        <Close onClick={() => changeVisibilitySetting(false)}>
          <span className="icon-Ico16-X-Medium" />
        </Close>
      </Layout>}</Translation>
    ) : null;
  }
}
