import React from 'react';
import styled from 'styled-components';
import { Switch } from '@material-ui/core';
import { FillButtonPrimaryDefault, FillButtonPrimaryHover, FillButtonPrimaryActive } from '../../styles/Kit/Fills';
import classNames from 'classnames';


const Icon = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 3px;
    box-shadow: inset 0 2px 0 0 #eaedf3;
    transform: translate(1px,3px);

    :before {
      font-family: icomoon;
      content: '\\e914';
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 16px;
      transform: translate(-8px, -50%);
    }

    background-color: #7C7C7C;
    :hover {
      background-color: #7C7C7C;
    }
    :active {
      background-color: #7C7C7C;
    }

    &.disabled {
      opacity: 0.4;
    }

    border: solid 1px #202022;
`


const CheckedIcon = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 3px;
    box-shadow: inset 0 2px 0 0 #eaedf3;
    transform: translate(1px,3px);

    :before {
      font-family: icomoon;
      content: '\\e902';
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 16px;
      transform: translate(-50%, -50%);
    }

    ${FillButtonPrimaryDefault}
    :hover {
      ${FillButtonPrimaryHover};
    }
    :active {
      ${FillButtonPrimaryActive};
    }

    &.disabled {
      opacity: 0.4;
    }

    border: solid 1px #202022;
`


const Styled = styled(({ white, ...other }) => (
  <Switch color="primary" {...other} classes={{ "icon": "icon", "track": "track" }} checkedIcon={<CheckedIcon className={classNames({ disabled: other.disabled })} />} icon={<Icon className={classNames({ disabled: other.disabled })} />} />
))`
  min-width: 34px !important;
  padding: 8px 10px !important;

  .icon {
    transform: translateX(-10px);
  }

  .track {
    background-color: white !important;
    height: 16px;
    width: 32px;
    box-shadow: inset 0 2px 0 0 #eaedf3;
    border-radius: 3px;
    border: solid 1px #9697a1;
    opacity: 1 !important;
    transform: translateY(4px);
  }


  `;



export default Styled;