import { compose } from 'redux';
import View from './View';
import withForm from 'hoc/withForm';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { RESTAPI } from 'rdx/modules/api/rest';
import { REGEX_EMAIL, REGEX_PASSWORD } from 'helpers/const';
import { postEmailVerifyPromise } from 'rdx/modules/api/logics';

const validate = values => {
  const errors = {};



  if (!values.username) {
    errors.username = 'forms.validation_name';
  }

  if (!values.email || !REGEX_EMAIL.test(values.email)) {
    errors.email = 'forms.validation_email';
  }


  if (!values.password) {
    errors.password = 'forms.validation_password';
  } else if (!REGEX_PASSWORD.test(values.password)) {
    errors.password = 'forms.validation_password_strength';
  }


  if (values.password != values.passwordconfirm) {
    errors.passwordconfirm = 'forms.validation_password_differs';
  }


  return errors;
};



const onSubmit = (values, dispatch) => {

  const subValues = { ...values, passwordconfirm: undefined }

  return RESTAPI.postEmailRegister({ payload: subValues }).then((r) => {
    return Promise.resolve(r);
  }).catch(error => {

    throw new SubmissionError(error && error.response && error.response.data)

  })
};

export const onSubmitFail = (errors, dispatch, submitError, form) => {
  setTimeout(() => {
    const firstErrorNode = document.querySelector(
      'input.error'
    );
    if (firstErrorNode) {
      firstErrorNode.focus();
    }
  }, 50);
};



const mapStateToProps = (state, ownProps) => {
  return {
    resending: state.rest.postEmailVerify.sending,
    email: state.form["email-register"] && state.form["email-register"].values && state.form["email-register"].values.email,
    captchaError: state.form['email-register'] && state.form['email-register'].submitErrors && state.form['email-register'].submitErrors.visibleCaptcha == "Invalid captcha response",
    initialValues: {
      visibleCaptcha: ownProps.visibleCaptcha,
      email: null,
      username: state.auth.user && state.auth.user.username,
      password: null,
      passwordconfirm: null,
    },
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resendConfirmation: (email) => () => {
      postEmailVerifyPromise({ payload: { email } }, dispatch)
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    resendConfirmation: dispatchProps.resendConfirmation(stateProps.email),
    ...ownProps
  }
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withForm({
    form: 'email-register',
    captcha: 'emailregister',
    validate,
    onSubmit,
    onSubmitFail,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false
  }),
);



export default enhance(View);

