import React from 'react';
import styled from 'styled-components';
import { withBreakpoint } from 'hoc/withBreakpoint';
import CollapsingTh from './CollapsingTh';
import CollapsingTd from './CollapsingTd';

const Layout = styled.div`
  width: 100%;

  table {
    width: 100%;
    table-layout: fixed;
  }
`;

export const CollapsingTableContext = React.createContext({});

class CollapsingTableRow extends React.Component {
  render() {
    const { validateCol, children } = this.props;
    return (
      <tr style={this.props.style} className={this.props.className}>
        {React.Children.toArray(children).filter((c, i) => validateCol(i))}
      </tr>
    );
  }
}

class Table extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { active: 1 };
    this.bodyRef = React.createRef();
  }

  handleChangeActiveColumn = index => {
    this.setState({ active: index });
  };

  validateCol = i => {
    const { active } = this.state;
    const { breakpoint } = this.props;
    const isMobile = breakpoint == 'phone' || breakpoint == 'tablet';
    return isMobile ? i == 0 || i == active : true;
  };

  render() {
    const { handleChangeActiveColumn, validateCol } = this;
    const { columns, breakpoint, children } = this.props;
    const { active } = this.state;

    const isMobile = breakpoint == 'phone' || breakpoint == 'tablet';
    const visibleColumns = isMobile
      ? columns.filter((c, i) => i == active || i == 0)
      : columns;

    return (
      <Layout>
        <CollapsingTableContext.Provider value={{ validateCol }}>
          <table>
            <thead>
              <tr>
                {visibleColumns.map((column, index) => (
                  <CollapsingTh
                    key={column}
                    {...{
                      columns,
                      column,
                      index,
                      handleChangeActiveColumn,
                    }}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              <CollapsingTableContext.Consumer>
                {({ validateCol }) => {
                  return React.Children.map(children, element => {
                    return React.cloneElement(element, {
                      validateCol: validateCol,
                      blabla: 5,
                    });
                  });
                }}
              </CollapsingTableContext.Consumer>
            </tbody>
          </table>
        </CollapsingTableContext.Provider>
      </Layout>
    );
  }
}

export const CollapsingTable = {
  Table: withBreakpoint(Table),
  Row: CollapsingTableRow,
  Cell: CollapsingTd,
};
export default CollapsingTable;
