import React from 'react';
import { ContentBox } from 'common/ContentBox';
import styled from 'styled-components';
import CollapsingTable from 'common/CollapsingTable';
import classNames from 'classnames';
import CoinsInfoButton from 'common/CoinsInfoButton';
import { Translation, Trans } from 'react-i18next';
import { media } from '../../../styles';
import Checkbox from 'common/MaterialUI/Checkbox';
import {
  WALLET_STATUS_HIDDEN,
  WALLET_STATUS_DEFAULT,
  WALLET_STATUS_FAVORITE,
} from 'helpers/const';
import { Pagination } from '../../../common/Pagination';
import ActionButtonWithToolTip from '../ActionButtonWithToolTip';
import EmptyBox from '../../../common/EmptyBox';
import { Title } from './Title';
import { Empty } from './Empty';
import { NextCoin } from './NextCoin';
import { Coins } from './Coins';

const Layout = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  table {
    th:last-child,
    td:last-child {
      > * {
        justify-content: flex-end;
      }
    }
  }

  tr.hidden {
    opacity: 0.4;
  }

  ${media.widescreenMax`
table {
  th:first-child,
  td:first-child {
    width: 40%;
  }
}
`};

  ${media.widescreen`
  table {
    th:nth-child(2),
    td:nth-child(2) {
      width: 55%;
    }
  }
  `};
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  > *:not(:first-child) {
    margin-left: 5px;
  }
`;

const TabCheckBox = styled(Checkbox)`
  > label > div > span {
    text-transform: none;
    font-size: 11px;
    font-weight: 300;

    color: #1d1d1d;
    ${media.tablet`
    font-size: 14px;
    `};
  }
`;

const Boxes = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 10px;
  }
`;

const ButtonArea = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: flex-end;

  ${media.tabletMax`
    flex-direction: column-reverse;
    justify-content: center;

    > * + * {
      margin-bottom: 20px;
    }
  `};

  a {
    display: flex;
    justify-content: center;
  }
`;

const Note = styled.p`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

export class View extends React.PureComponent {
  state = {
    showHidden: false,
  };

  componentDidMount() {
    this.handleLoad(1);
  }

  componentWillUnmount() {

  }

  handleLoad = page => {
    const { load, limit } = this.props;
    const { showHidden } = this.state;

    const include = showHidden
      ? null
      : [WALLET_STATUS_DEFAULT, WALLET_STATUS_FAVORITE];

    load(parseInt(page), limit, include)

    /* this.setState({
          wallets: response[0].wallets,
          disableHidden:
            !response[1].wallets || response[1].wallets.length == 0,
          showHidden:
            !showHidden && response[0].wallets
              ? response[0].wallets.filter(
                w => w.walletstatusid != WALLET_STATUS_HIDDEN,
              ).length == 0
                ? true
                : false
              : showHidden,
          total_pages: response[0].total_pages,
          current_page: response[0].current_page,
          loading: false,
          loaded: true,
        }); */
  };

  handleCheckBoxChange = () => {
    const { showHidden } = this.state;

    this.setState({ showHidden: !showHidden }, () => {
      this.handleLoad(1);
    });
  };

  renderCheckboxes = t => {
    const { showHidden } = this.state;
    const { disableHidden } = this.props;

    return disableHidden ? null : (
      <Boxes>
        {t('dashboard_my_coins_show_hidden')}<TabCheckBox
          checked={showHidden}
          onChange={this.handleCheckBoxChange} />
      </Boxes>
    );
  };

  handleWalletUpdate = (id, status) => {
    const { updateWalletStatus } = this.props;
    const newWallets = [...this.props.wallets];
    const index = newWallets.findIndex(ow => ow.walletid == id);
    newWallets[index].walletstatusid = status;

    updateWalletStatus(id, status)
    setTimeout(() => {
      this.handleLoad(1)
    }, 250);
  };

  render() {

    const { renderCheckboxes, handleWalletUpdate } = this;
    const {
      wallets,
      total_pages,
      current_page,
      loading,
      loaded,
    } = this.props;
    const { showHidden } = this.state;
    const { handleLoad } = this;

    const columns = [
      'broadcaster',
      'dashboard_my_coins_title_my_coins',
      'dashboard_my_coins_title_next',
      'actions',
    ];

    const filteredWallets = wallets
      ? wallets.filter(
        w => (showHidden ? true : w.walletstatusid != WALLET_STATUS_HIDDEN),
      )
      : null;
    return (
      <Translation>
        {t => (
          <ContentBox
            keepMaxHeight
            vcenter={loading}
            title={t('dashboard_my_coins')}
            right={renderCheckboxes(t)}
          >
            <Layout>
              {loaded && (!filteredWallets || filteredWallets.length == 0) ? (
                <Empty />
              ) : loading ? (
                <EmptyBox noBorder loading />
              ) : (
                    <React.Fragment>
                      <Note>
                        <span>
                          <Trans i18nKey="dashboard_my_coins_note">
                            <b>NOTE</b>: Youll earn loyalty coins by watching your
                            favorite streamers on Twitch.
                      </Trans>
                        </span>
                        <CoinsInfoButton />
                      </Note>
                      <CollapsingTable.Table {...{ columns }}>
                        {filteredWallets.map(wallet => (
                          <CollapsingTable.Row
                            className={classNames({
                              hidden: wallet.walletstatusid == WALLET_STATUS_HIDDEN,
                            })}
                            key={wallet.walletid}
                          >
                            <CollapsingTable.Cell>
                              <Title {...{ wallet }} />
                            </CollapsingTable.Cell>

                            <CollapsingTable.Cell>
                              <Coins balance={wallet.balance} />
                            </CollapsingTable.Cell>
                            <CollapsingTable.Cell>
                              <NextCoin balance={wallet.balance} />
                            </CollapsingTable.Cell>

                            <CollapsingTable.Cell>
                              <Actions>
                                <ActionButtonWithToolTip
                                  active={wallet.walletstatusid == WALLET_STATUS_FAVORITE}
                                  icon="icon-Ico16-Heart"
                                  content={t('favorite')}
                                  onClick={event => {
                                    handleWalletUpdate(
                                      wallet.walletid,
                                      wallet.walletstatusid ==
                                        WALLET_STATUS_FAVORITE
                                        ? WALLET_STATUS_DEFAULT
                                        : WALLET_STATUS_FAVORITE,
                                    );
                                  }}
                                />

                                <ActionButtonWithToolTip
                                  active={wallet.walletstatusid == WALLET_STATUS_HIDDEN}
                                  icon="icon-Ico16-Views"
                                  content={t('hidden')}
                                  onClick={event => {
                                    handleWalletUpdate(
                                      wallet.walletid,
                                      wallet.walletstatusid == WALLET_STATUS_HIDDEN
                                        ? WALLET_STATUS_DEFAULT
                                        : WALLET_STATUS_HIDDEN,
                                    );
                                  }}
                                />
                              </Actions>
                            </CollapsingTable.Cell>
                          </CollapsingTable.Row>
                        ))}
                      </CollapsingTable.Table>
                    </React.Fragment>
                  )}
              {loaded &&
                filteredWallets &&
                filteredWallets.length > 0 && (
                  <ButtonArea>
                    <Pagination
                      total={total_pages}
                      active={current_page}
                      onChange={handleLoad}
                    />
                  </ButtonArea>
                )}
            </Layout>
          </ContentBox>
        )}
      </Translation>
    );
  }
}

export default View;
