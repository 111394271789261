import { initialState as authState } from './modules/auth';
import { initialState as uiState } from './modules/ui';
import { initialState as giveawayListsState } from './modules/giveawayLists';
import { initialState as productListsState } from './modules/productLists';
import { initialState as productsState } from './modules/products';

export const initialState = (lang, region, user) => {
  return {
    auth: { ...authState, user: user },
    ui: {
      ...uiState,
      language: lang,
      region,
    },
    giveawayLists: { ...giveawayListsState, region },
    productLists: { ...productListsState, region },
    products: { ...productsState, region }
  }
};