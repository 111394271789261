import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { media } from 'styles';
import { Scrollbars } from 'react-custom-scrollbars';
import Button from 'common/MaterialUI/Button';
import DropDownController from '../BaseDropDown';
import DropDownList from '../BaseDropDown/DropDownList';
import { AnchorController } from './AnchorController';

const Layout = styled.div`
  display: flex;
`;

const Desktop = styled.div`
  position: fixed;
  transform: translateX(660px);

  display: none;

  ${media.desktop`
  display: flex;
`};

  ${media.widescreen`
      transform: translateX(990px);
    `};

  width: 300px;
  box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
    0 1px 1px 0 rgba(29, 29, 29, 0.1);
  border-radius: 6px;
  background-color: #ffffff;

  flex-direction: column;

  a {
    position: relative;
    padding: 15px 10px 15px 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #1d1d1d;

    :hover {
      color: #36a6fc;
    }

    &.active {
      color: #36a6fc;
      background-color: rgba(234, 237, 243, 0.25);
    }

    & + a {
      border-top: solid 2px #f0f0f0;
    }

    span {
      position: absolute;
      left: 15px;
    }
  }
`;

const Mobile = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 20px;

  ${media.desktop`
      display: none;
    `};

  .dropdownlist.open {
    right: 0;
    top: 22px;
    background: white;
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 2px 4px 2px rgba(29, 29, 29, 0.05),
      0 1px 1px 0 rgba(29, 29, 29, 0.1);

    a {
      display: flex;
      font-size: 11px;
      padding: 3px 0;
      color: #1d1d1d;

      :hover,
      &.active {
        color: #36a6fc;
      }

      span {
        display: none;
      }
    }
  }
`;

class AButton extends React.Component {
  render() {
    const {
      dropDownState: { open },
    } = this.context;
    const { label } = this.props;

    return (
      <Button outline
        small
      ><span className={open ? 'icon-Ico16-ArrowUp-Small' : 'icon-Ico16-ArrowDown-Small'} /><span>{label}</span></Button>
    );
  }

  static contextTypes = {
    dropDownState: propTypes.any.isRequired,
  };
}

export class AnchorNavigation extends React.PureComponent {
  render() {
    const { children, title } = this.props;

    return (
      <Layout>
        <AnchorController offset={165}>
          <Desktop onClick={this.handleAnchorClick}>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={500}>
              {children}
            </Scrollbars>
          </Desktop>
          <Mobile>
            <DropDownController>
              <AButton label={title} />
              <DropDownList>{children}</DropDownList>
            </DropDownController>
          </Mobile>
        </AnchorController>
      </Layout>
    );
  }
}

export default AnchorNavigation;
